import React, { useMemo } from "react";
import { useHtmlClassService } from "../../../layout/_core/MetronicLayout";
import { NavLink } from "react-router-dom";
// import SVG from "react-inlinesvg";
// import { useLocation } from "react-router";
// import { toAbsoluteUrl, checkIsActive } from "../../../_helpers";
import { menuAction } from "../../../../app/redux/menu/actions";
import store from "../../../../app/redux/store";

export function AsideMenuList({ isActive, submenu }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true),
    };
  }, [uiService]);

  // const location = useLocation();
  // const getMenuItemActive = (url, hasSubmenu = false) => {
  //   return checkIsActive(location, url)
  //     ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
  //     : "";
  // };

  return (
    <div className={`tab-pane fade ${isActive && "show active"}`}>
      <div className="aside-menu-wrapper flex-column-fluid px-10 py-5">
        {/* begin::Menu Container */}
        <div
          id="kt_aside_menu"
          data-menu-vertical="1"
          className={`aside-menu  min-h-lg-800px ${layoutProps.asideClassesFromConfig}`}
          {...layoutProps.asideMenuAttr}
        >
          {/* <AsideMenuList layoutProps={layoutProps} /> */}
          <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {submenu.map((object, i) => {
              let isActive =
                object.path === store.getState().menu.active.menu
                  ? "menu-item-active menu-item-open"
                  : "";
              let isActiveIcon =
                object.path === store.getState().menu.active.menu
                  ? "text-primary"
                  : "";
              return (
                <li
                  className={`menu-item ${isActive}}`}
                  aria-haspopup="true"
                  key={i}
                >
                  <NavLink
                    className="menu-link"
                    to={object.path}
                    onClick={() => {
                      document.body.classList.add("aside-minimize");
                      store.dispatch({
                        type: menuAction.setMenu,
                        payload: { menu: object.path },
                      });
                    }}
                  >
                    <span className="svg-icon menu-icon">
                      <i className={`${object.icon} ${isActiveIcon}`}></i>
                    </span>
                    <span className="menu-text">{object.nama}</span>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
        {/* end::Menu Container */}
      </div>
    </div>
  );
}

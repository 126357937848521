import moment from "moment";
import { getCoverageArea, simpleFetch, simpleFetchGetData } from "./api";

export class kasirLaporanAPI {
  // Get Data Laporan Kas 
  async getAllKas(form) {
    const { start_date, end_date, id_perusahaan } = form;
    let cabang = (id_perusahaan === "") ? getCoverageArea() : id_perusahaan;
    let get = `&start_date=${moment(start_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&id_perusahaan=${cabang}`;
    let res = await simpleFetchGetData({
      url: "retail/kasir/laporan?tipe=laporan-kas" + get,
      method: "GET",
    });
    return res;
  }

  // Get Data Laporan Bank Collection 
  async getAllBankCollection(form) {
    const { start_date, end_date, id_perusahaan, id_bank } = form;
    let cabang = (id_perusahaan === "") ? getCoverageArea() : id_perusahaan;
    let get = `&start_date=${moment(start_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&id_perusahaan=${cabang}&id_bank=${id_bank}`;
    let res = await simpleFetchGetData({
      url: "retail/kasir/laporan?tipe=laporan-bank-collection" + get,
      method: "GET",
    });
    return res;
  }

  // Get Data Laporan Bank Operasional List
  async getAllBankOperasional(form) {
    const { start_date, end_date, id_perusahaan, id_bank } = form;
    let cabang = (id_perusahaan === "") ? getCoverageArea() : id_perusahaan;
    let get = `&start_date=${moment(start_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&id_perusahaan=${cabang}&id_bank=${id_bank}`;
    let res = await simpleFetchGetData({
      url: "retail/kasir/laporan?tipe=laporan-bank-operasional" + get,
      method: "GET",
    });
    return res;
  }

  // Get Coverage Area
  async getCoverage() {
    let res = await simpleFetch({
      url: "retail/select/perusahaan?id_coverage=" + getCoverageArea(),
      method: "GET",
    });
    return res;
  }

  // Get Bank Collection
  async getBankCollection(id_perusahaan) {
    let cabang = (id_perusahaan === "") ? getCoverageArea() : id_perusahaan;
    let res = await simpleFetchGetData({
      url: "retail/select/bank?alias=bank-collection&id_perusahaan=" + cabang,
      method: "GET",
    });
    return res;
  }

  // Get Bank Operasional
  async getBankOperasional(id_perusahaan) {
    let cabang = (id_perusahaan === "") ? getCoverageArea() : id_perusahaan;
    let res = await simpleFetchGetData({
      url: "retail/select/bank?alias=bank-operasional&id_perusahaan=" + cabang,
      method: "GET",
    });
    return res;
  }
}

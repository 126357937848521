/* eslint-disable eqeqeq */
import * as Yup from "yup";
import { GudangAPI } from "../../api/gudang";
export default class GudangModel {
  constructor(
    data = {
      id: 0, nama: "",
      id_parent: 0,
      toPerusahaan:"",
      id_perusahaan: "",
      toParent: { nama: "" } },
  ) {
    this.id = data.id;
    this.nama = data.nama;
    this.id_perusahaan = data.id_perusahaan;
    this.id_parent = data.id_parent ?? 0;
    this.nama_parent = data.toParent == null ? "" : data.toParent.nama;
    this.cabang = data.toPerusahaan != null ? `${data.toPerusahaan.singkat}  ${data.toPerusahaan.lokasi}` : "";

  }

  async getEditable(params = { pageName: "", isForm: false }) {
    let options_gudang = [];
    let options_coverage = [];
    if (params.isForm) {
      await new GudangAPI().getAllParent().then((values) => {
        values.data.map(
          (data, i) =>
            (options_gudang = [
              ...options_gudang,
              { id: data.id, text: data.nama, value: data.id },
            ])
        );
      });

      await new GudangAPI().getCoverage().then((values1) => {
        values1.data.map(
          (data, i) =>
            (options_coverage = [
              ...options_coverage,
              { id: data.id, text: data.text, value: data.id },
            ])
        );

        console.log(options_coverage);
      });
    }

    return [
      {
        validation: Yup.string(),
        dataField: "id_parent",
        label: "Nama Gudang",
        options: options_gudang,
        type: "select",
        onChange: () => {},
        value: this.id_parent != 0 ? this.nama_parent : this.nama,
      },
      {
        validation: Yup.string(),
        dataField: "id_perusahaan",
        label: "Cabang",
        options: options_coverage,
        type: "select",
        onChange: () => {},
        value: this.cabang,
      },
      {
        validation: Yup.string().required("Nama harus diisi"),
        dataField: "nama",
        label: "Sub Gudang",
        type: "text",
        value: this.id_parent != 0 ? this.nama : " - ",
      },
    ];
  }
}

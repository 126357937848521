import akun from "./akun/reducer";
import auth from "./auth/reducer";
import { combineReducers } from "redux";
//** Start: Akuntansi */
import jurnal_umum from "./akuntansi/jurnal-umum/reducer";
//** Start: Akuntansi */
//* -------------------------------------------------------------------------------------------------------------------- */
//** Start: Kasir */
import kasir_kas from "./kasir/kas/reducer";
import kasir_bank_collection from "./kasir/bank_collection/reducer";
import kasir_bank_operasional from "./kasir/bank_operasional/reducer";
//** End: Kasir */
import kontak from "./kontak/reducer";
import loading from "./loading/reducer";
import menu from "./menu/reducer";
import users from "./users/reducer";
import message from "./message/reducer";
import pembelian from "./pembelian/reducer";
import penerimaan from "./penerimaan/reducer";
import penjualan from "./penjualan/reducer";
import { persistReducer } from "redux-persist";
import pesanan_penjualan from "./pesanan_penjualan/reducer";
import storage from "redux-persist/lib/storage";
import userLogin from "./user_login/reducer";
// StokTransfer
import transferStok from "./stok-transfer/reducer";
import notification from "./notification/reducer";
import panelNotification from "./panel-notification/reducer";

export const reducers = combineReducers( {
  auth: auth,
  menu: menu,
  users: users,
  message: message,
  pembelian: pembelian,
  penerimaan: penerimaan,
  pesanan_penjualan: pesanan_penjualan,
  penjualan: penjualan,
  loading: loading,
  //** Start: Akuntansi */
  jurnal_umum: jurnal_umum,
  //** Start: Kasir */
  kasir_kas: kasir_kas,
  kasir_bank_collection: kasir_bank_collection,
  kasir_bank_operasional: kasir_bank_operasional,
  //** End: Kasir */
  akun: akun,
  userLogin: userLogin,
  kontak: kontak,
  // transferstok
  transfer_stok: transferStok,
  notification: notification,
  panelNotification: panelNotification,
} );

const persistConfig = {
  key: "root",
  storage,
  whitelist: [ "auth", "menu", "users" ],
};

export default persistReducer( persistConfig, reducers );

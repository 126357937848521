import { separatorHarga } from "../../../../services/separator-harga";

export default class SaldoAwalAkunModel {
  constructor(
    data = {
      id: 0,
      kode_akun: "",
      nama_akun: "",
      jenis_akun: "",
      nama_cabang: "",
      saldo_awal: 0,
    }
  ) {
    const { id, kode_akun, nama_akun, jenis_akun, nama_cabang, saldo_awal } = data;
    this['id'] = id;
    this['kode_akun'] = kode_akun;
    this['nama_akun'] = nama_akun;
    this['jenis_akun'] = jenis_akun;
    this['nama_cabang'] = nama_cabang;
    this['saldo_awal'] = saldo_awal;
  }
  async getdata() {
    const { kode_akun, nama_akun, jenis_akun, nama_cabang, saldo_awal } = this;
    return [
      {
        id: "kode_akun",
        title: "Kode Akun",
        label: kode_akun,
        center: true,
        sortable: true,
        minWidth: "10%",
      },
      {
        id: "nama_akun",
        title: "Akun",
        label: nama_akun,
        sortable: true,
        minWidth: "23%",
      },
      {
        id: "jenis_akun",
        title: "Jenis Akun",
        label: jenis_akun,
        center: true,
        sortable: true,
        minWidth: "12%",
      },
      {
        id: "cabang",
        title: "Cabang",
        label: nama_cabang,
        center: true,
        sortable: true,
        minWidth: "15%",
      },
      {
        id: "saldo_awal",
        title: "Saldo Awal",
        label: separatorHarga(saldo_awal.toString(), "Rp. "),
        right: true,
        sortable: false,
        minWidth: "10%",
      },
    ];
  }
}

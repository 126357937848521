import * as Yup from "yup";
import PesananPenjualanDetailModel from "../pesanan-penjualan-detail-model/PesananPenjualanDetailModel";
import { CustomerAPI } from "../../api/customer";
import { separatorHarga, hapusSeparator } from "../../services/separator-harga";
import store from "../../redux/store";
import moment from "moment";
import { CustomerPembelianAPI } from "../../api/cust-pembelian";

export default class PesananPenjualanModel {
  constructor(
    data = {
      master: {
        id: 0,
        id_perusahaan: store.getState().auth.id_perusahaan,
        kode: "",
        no_transaksi: "",
        tanggal: moment(new Date()).format("YYYY-MM-DD"),
        jatuh_tempo: "",
        qty_total: 0,
        hpp_total: 0,
        dpp_total: 0,
        diskon_total: 0,
        subtotal: 0,
        pajak_total: 0,
        dp: 0,
        total: 0,
        tempTotal: 0,
        status_bayar: 0,
        status_invoice: 0,
        id_user: store.getState().auth.id,
        id_sales: 0,
        id_customer: 0,
        id_kontak: 0,
        deskripsi: "",
        is_approved: 0,
        approved_spv_by: 0,
        approved_manager_by: 0,
        approved_director_by: 0,
        approved_admin_by: 0,
        status: 0,
        jenis_pembayaran: 0,
        diskon: 0,
        diskon_invoice: 0,
        create_at: "",
        update_at: "",
        nama_sales: "",
        status_reject: 0,
        status_batal: 0,
        nama_organisasi: "",
        sisa_limit: 0,
        toCustomer: {
          nama: "",
        },
        toOrganisasi: {
          nama: "",
          nilai_limit: 0,
          nilai_limit_terpakai: 0,
          nilai_limit_tersisa: 0,
          nilai_top: 0,
        },
      },
      detail: [],
    }
  ) {
    this.master = {
      id: data.master.id,
      id_perusahaan: data.master.id_perusahaan,
      kode: data.master.kode,
      no_transaksi: data.master.no_transaksi,
      tanggal: data.master.tanggal,
      jatuh_tempo: data.master.jatuh_tempo,
      qty_total: data.master.qty_total,
      hpp_total: separatorHarga(data.master.hpp_total.toString(), "Rp."),
      dpp_total: separatorHarga(data.master.dpp_total.toString(), "Rp."),
      diskon_total: separatorHarga(data.master.diskon_total.toString(), "Rp."),
      subtotal: separatorHarga(data.master.subtotal.toString(), "Rp."),
      pajak_total: separatorHarga(data.master.pajak_total.toString(), "Rp."),
      dp: data.master.dp ? data.master.dp : 0,
      total: separatorHarga(data.master.total.toString(), "Rp."),
      tempTotal: data.master.total,
      status_bayar: data.master.status_bayar,
      status_invoice: data.master.status_invoice,
      id_user: data.master.id_user,
      id_sales: data.master.id_sales,
      id_customer: data.master.id_customer,
      nama_customer: data.master.toCustomer.nama,
      nama_sales: data.master.nama_sales,
      nama_organisasi: data.master.nama_organisasi,
      sisaLimit: data.master.sisa_limit,
      id_kontak: data.master.id_kontak,
      deskripsi: data.master.deskripsi,
      is_approved: data.master.is_approved,
      approved_spv_by: data.master.approved_spv_by,
      approved_manager_by: data.master.approved_manager_by,
      approved_director_by: data.master.approved_director_by,
      approved_admin_by: data.master.approved_admin_by,
      status: data.master.status,
      status_reject: data.master.status_reject,
      status_batal: data.master.status_batal,
      jenis_pembayaran: data.master.jenis_pembayaran,
      create_at: data.master.create_at,
      update_at: data.master.update_at,
      diskon_invoice: separatorHarga(data.master.diskon_invoice.toString(), "Rp.")
    };
    this.detail = [];
    this.pay_method = data.master.jenis_pembayaran === 1 ? "Cash" : "Credit"
    this.diskon_opsi = data.master.diskon_opsi;
    this.subtotal_temp = data.master.subtotal_temp;
    this.nilai_limit_tersisa = "Customer Umum";
    this.nama_organisasi = "Tanpa Organisasi";
    this.initDetails(data.detail);
  }

  initDetails(detail) {
    if (detail.length > 0)
      detail.forEach((item) => {
        let diskon_persen = Math.round((item.diskon / item.hpp_total) * 100).toFixed(2);
        let diskon_item = separatorHarga((Math.round(item.diskon / item.qty)).toString())
        let pajak_persen = (item.pajak / item.subtotal) * 100;
        let harga_ppn = separatorHarga((Math.round(item.dpp * 1.11)).toString())
        this.detail = [
          ...this.detail,
          new PesananPenjualanDetailModel({
            ...item,
            id_pesanan_penjualan: this.master.id,
            diskon_persen: diskon_persen,
            diskon_item: diskon_item,
            pajak_persen: pajak_persen,
            harga_ppn: harga_ppn
          }),
        ];
      });
  }

  nulldata = {
    master: {
      id: 0,
      id_perusahaan: store.getState().auth.id_perusahaan,
      kode: "",
      no_transaksi: "",
      tanggal: "",
      jatuh_tempo: "",
      qty_total: 0,
      hpp_total: 0,
      dpp_total: 0,
      diskon_total: 0,
      diskon_invoice: 0,
      subtotal: 0,
      pajak_total: 0,
      total: 0,
      status_bayar: 0,
      status_invoice: 0,
      id_user: store.getState().auth.id,
      id_sales: 0,
      id_customer: 0,
      id_kontak: 0,
      deskripsi: "",
      is_approved: 0,
      approved_spv_by: 0,
      approved_manager_by: 0,
      approved_director_by: 0,
      status: 0,
      create_at: "",
      update_at: "",
      jenis_pembayaran: "",
      toCustomer: {
        nama: "",
      },
    },
    detail: [],
  };

  reset() {
    this(this.nulldata);
  }

  hitungTotalan() {
    let qty_total = 0;
    let hpp_total = 0;
    let dpp_total = 0;
    let diskon_total = 0;
    let subtotal = 0;
    // let pajak_total = 0;
    // let total = 0;
    if (this.detail.length > 0) {
      this.detail.forEach((item) => {
        if (item.status !== 0) {
          qty_total = parseInt(qty_total) + parseInt(item.qty);
          hpp_total =
            parseInt(hpp_total) + parseInt(hapusSeparator(item.hpp_total));
          dpp_total =
            parseInt(dpp_total) + parseInt(hapusSeparator(item.dpp_total));
          diskon_total =
            parseInt(diskon_total) + parseInt(hapusSeparator(item.diskon));
          // subtotal =
          //   hpp_total - this.diskon;
          subtotal =
            parseInt(subtotal) + parseInt(hapusSeparator(item.subtotal));
          // pajak_total =
          //   parseInt(pajak_total) + parseInt(hapusSeparator(item.pajak));
          // total = parseInt(total) + parseInt(hapusSeparator(item.total));
        }
      });
    }
    this.master.qty_total = qty_total;
    this.master.hpp_total = separatorHarga(hpp_total.toString(), "Rp.");
    this.master.dpp_total = separatorHarga(dpp_total.toString(), "Rp.");
    // this.master.diskon_total = separatorHarga(diskon_total.toString());
    this.diskon_temp = separatorHarga(diskon_total.toString());
    this.master.subtotal_temp = separatorHarga(subtotal.toString());
    // this.master.pajak_total = separatorHarga(pajak_total.toString());
    // this.master.total = separatorHarga(total.toString());
  }

  removeSeparator() {
    this.master.hpp_total = parseInt(hapusSeparator(this.master.hpp_total));
    this.master.dpp_total = parseInt(hapusSeparator(this.master.dpp_total));
    this.master.diskon_total = parseInt(
      hapusSeparator(this.master.diskon_total)
    );
    this.master.diskon_invoice = parseInt(hapusSeparator(this.master.diskon_invoice))
    this.master.pajak_total = parseInt(hapusSeparator(this.master.pajak_total));
    this.master.subtotal = parseInt(hapusSeparator(this.master.subtotal));
    this.master.total = parseInt(hapusSeparator(this.master.total));
  }

  removeAllDetailSeparator() {
    this.detail.forEach((item) => item.removeSeparator());
  }

  handleDetail = {
    set: async (item) => {
      item.id_pesanan_penjualan = this.master.id;
      item.id_customer = this.master.id_customer;
      item.id_sales = this.master.id_sales;
      this.detail = [...this.detail, item];
      this.hitungTotalan();
      this.diskon_inv();
      this.hitungTotal();
    },
    del: async (dataModel) => {
      this.detail.map((val, i) => {
        return val.id === dataModel.id && val.id_produk === dataModel.id_produk ?
          this.detail[i].status = 0 : ""
      });
      this.hitungTotalan();
      this.diskon_inv();
      this.hitungTotal()
    },
    reset: async () => {
      this.detail = [];
      this.hitungTotalan();
    },
    edit: async (item) => {
      this.hitungTotalan();
      this.diskon_inv();
      this.hitungTotal();
    },
    getSingle: async (id) => this.detail.find((e) => e.id === id),
  };

  hitungTotal() {
    let subtotal =
      parseInt(hapusSeparator(this.master.subtotal_temp)) -
      parseInt(hapusSeparator(this.master.diskon_invoice));
    this.master.subtotal = separatorHarga(subtotal.toString(), "Rp.");
    let total_pajak = Math.ceil(subtotal * (11 / 100))
    this.master.pajak_total = separatorHarga(
      total_pajak.toString(),
      "Rp."
    );
    let pajak_total = parseInt(hapusSeparator(this.master.pajak_total));
    this.master.total = separatorHarga(
      (subtotal + pajak_total).toString(),
      "Rp."
    );
    this.master.tempTotal = (subtotal + pajak_total);
    let diskon_total =
      parseInt(hapusSeparator(this.diskon_temp)) +
      parseInt(hapusSeparator(this.master.diskon_invoice));
    this.master.diskon_total = separatorHarga(diskon_total.toString(), "Rp.");
  }

  diskon_inv() {
    let subtot = parseInt(hapusSeparator(this.master.hpp_total));
    let diskonPersen = parseInt(hapusSeparator(this.master.diskon_invoice));
    let persen = diskonPersen / 100;
    let hasilPersen = Math.ceil(subtot * persen)
    let diskon_invoice =
      this.diskon_opsi === 2
        ? this.master.diskon_invoice
        : separatorHarga(((hasilPersen)).toString());
    this.master.diskon_invoice = separatorHarga(diskon_invoice.toString());
  }

  jatuh_tempo() {
    let target = new Date(this.master.tanggal);
    let val = this.valDate;
    this.master.jatuh_tempo = moment(target)
      .add(val, "days")
      .format("YYYY-MM-DD");
  }

  async getEditable(params = { pageName: "", isForm: false }) {
    let pembayaran_opsi = [
      { id: 1, text: "Cash", label: "Cash", value: 1 },
      { id: 2, text: "Kredit", label: "Kredit", value: 2 },
    ];

    let diskon_options = [
      { value: 1, text: "Persen", label: "Persen", id: 1 },
      { value: 2, text: "Rupiah", label: "Rupiah", id: 2 },
    ];

    let customer_options = [];
    if (params.isForm) {
      let datas = await new CustomerAPI().getAll();
      let filtered = datas.data.filter((fil) =>
        fil.toOrganisasi === null
          ? fil.id_sales === store.getState().auth.id
          : fil.toOrganisasi.is_approved === 3
            ? fil.id_sales === store.getState().auth.id
            : ""
      );
      filtered.map((data, i) => {
        return customer_options = [
          ...customer_options,
          { id: data.id, label: data.nama, text: data.nama, value: data.id },
        ];
      });
    }

    // console.log('cek res cust',res)
    //   res.data.map(
    //     (data, i) =>
    //       (customer_options = [
    //         ...customer_options,
    //         { id: data.id, text: data.nama, value: data.id },
    //       ])
    //   )

    const getCustomerTop = async (id) => {
      return await new CustomerPembelianAPI().getSingle(id).then((res) => {
        let data = res.data;
        console.log('Customer', data);
        let sisaLimit = data.toOrganisasi ? data.toOrganisasi.nilai_limit_tersisa : 0
        this.master.id_sales = data.id_sales;
        let namaSales = data.nama_sales;
        let namaOrganisasi = data.toOrganisasi ? data.toOrganisasi.nama : 0
        let salesName =
          data.nama_sales === null ? "Belum Ada Nama Sales" : namaSales;
        let topOrganisasi = data.toOrganisasi;
        let top = data.toOrganisasi === null ? 0 : topOrganisasi.nilai_top;
        return { top, salesName, sisaLimit, namaOrganisasi };
      });
    };

    return [
      {
        validation: Yup.string(),
        dataField: params.isForm ? "id_customer" : "nama_customer",
        label: "Customer",
        type: "select2",
        placeholder: "--- Select Customer ---",
        isClearable: false,
        options: customer_options,
        value: this.master.nama_customer,
        onChange: (e, setFieldValue) => {
          this.master.id_customer = e ? e.value : "";
          getCustomerTop(e.value).then((val) => {
            console.log('cekVal', val);
            this.valDate = val.top;
            this.nama_organisasi = val.namaOrganisasi !== 0 ? val.namaOrganisasi : "Tanpa Organisasi"
            this.nilai_limit_tersisa = val.sisaLimit !== 0 ? val.sisaLimit : "Customer Umum"
            this.jatuh_tempo();
            setFieldValue("jatuh_tempo", this.master.jatuh_tempo);
            setFieldValue("nama_sales", (this.master.nama_sales = val.salesName));
          });
        },
      },
      {
        dataField: "nama_organisasi",
        label: "Organisasi",
        type: "disabled-text",
        value: this.master.nama_organisasi,
        // invisibleOnTable: true,
      },
      {
        validation: Yup.string().required("Tanggal harus diisi"),
        dataField: "tanggal",
        label: "Tanggal",
        type: "date",
        value: this.master.tanggal,
        onChange: (e) => {
          this.master.tanggal = e.target.value;
          this.jatuh_tempo();
        },
      },
      {
        validation: Yup.string().required("Jatuh tempo harus diisi"),
        dataField: "jatuh_tempo",
        label: "Jatuh Tempo",
        type: "disabled-text",
        value: this.master.jatuh_tempo,
        onChange: (e) => {
          this.master.jatuh_tempo = e.target.value;
        },
        invisibleOnTable: true,
      },
      {
        validation: Yup.string(),
        dataField: "no_transaksi",
        label: "No Transaksi",
        type: "disabled-text",
        value: this.master.no_transaksi,
        onChange: (e) => (this.master.no_transaksi = e.target.value),
      },
      {
        validation: Yup.number()
          .required("Jenis Pembayaran harus diisi")
          .oneOf([1, 2]),
        dataField: "jenis_pembayaran",
        label: "Jenis Pembayaran",
        type: "select",
        options: pembayaran_opsi,
        // value: params.isForm ? this.master.jenis_pembayaran : this.pay_method,
        value: this.master.jenis_pembayaran,
        onChange: (e) => {
          this.master.jenis_pembayaran = e.target.value;
        },
      },
      {
        validation: Yup.string().required("QTY harus diisi"),
        dataField: "qty_total",
        label: "QTY Total",
        type: "disabled-text",
        value: this.master.qty_total,
        onChange: (e) => (this.master.qty_total = e.target.value),
      },
      {
        dataField: "diskon_opsi",
        label: "Diskon By",
        onChange: (e, setFieldValue) => {
          this.diskon_opsi = parseInt(e.target.value);
          this.diskon_inv();
          this.hitungTotal();
        },
        type: "select",
        options: diskon_options,
        value: this.diskon_opsi,
        invisibleOnTable: true,
      },
      {
        validation: Yup.string(),
        dataField: "diskon_invoice",
        label: "Diskon",
        type: "currency",
        value: this.master.diskon_invoice,
        onChange: (e, setFieldValue) => {
          this.master.diskon_invoice = e.target.value;
          this.hitungTotalan();
          this.diskon_inv();
          this.hitungTotal();
          setFieldValue("qty_total", this.master.qty_total);
          setFieldValue("hpp_total", this.master.hpp_total);
          setFieldValue("subtotal", this.master.subtotal);
          setFieldValue("diskon_total", this.master.diskon_total);
          setFieldValue("pajak_total", this.master.pajak_total);
          setFieldValue("total", this.master.total);
        },
        invisibleOnTable: true,
      },
      {
        validation: Yup.string(),
        dataField: "hpp_total",
        label: "Total Harga",
        type: "disabled-text",
        value: this.master.hpp_total,
        onChange: (e) => {
          this.master.hpp_total = e.target.value;
        },
        invisibleOnTable: true,
      },
      {
        validation: Yup.string(),
        dataField: "diskon_total",
        label: "Total Diskon",
        type: "disabled-text",
        value: this.master.diskon_total,
        onChange: (e) => (this.master.diskon_total = e.target.value),
        invisibleOnTable: true,
      },
      {
        validation: Yup.string(),
        dataField: "subtotal",
        label: "Sub Total",
        type: "disabled-text",
        value: this.master.subtotal,
        onChange: (e) => {
        },
        invisibleOnTable: true,
      },
      {
        validation: Yup.string(),
        dataField: "pajak_total",
        label: "Total Pajak",
        type: "disabled-text",
        value: this.master.pajak_total,
        onChange: (e) => (this.master.pajak_total = e.target.value),
        invisibleOnTable: true,
      },
      {
        validation: Yup.string(),
        dataField: "total",
        label: "Total",
        type: "disabled-text",
        value: this.master.total,
        onChange: (e) => {
          this.master.total = e.target.value
          this.master.tempTotal = e.target.value
        },
      },
      {
        validation: Yup.string(),
        dataField: "deskripsi",
        label: "Deskripsi",
        type: "text",
        value: this.master.deskripsi,
        onChange: (e) => (this.master.deskripsi = e.target.value),
        invisibleOnTable: true,
      },
      {
        dataField: "nama_sales",
        label: "Nama Sales",
        type: "disabled-text",
        value: this.master.nama_sales,
        onChange: (e) => {
          this.master.nama_sales = e.target.value;
        },
      },
    ];
  }
}

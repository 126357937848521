import * as Yup from "yup";
import { CardBody, CardFooter, CardHeader, CardHeaderToolbar } from "../../../../../../_metronic/_partials/controls";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { hapusSeparator, separatorHarga } from "../../../../../services/separator-harga";
import { AnimatedCard } from "../../../../../components/animated-card/animatedCard";
import DataTableModel from "../../../../../models/datatable-model/DataTableModel";
import { FormsFields } from "../../../../../components/forms-fields/FormsFields";
import { ModalForms } from "../../../../../components/modal-forms/modalForms";
import { ReactDatatable, ReactDatatableDetails } from "../../../../../components/react-datatable/reactDatatable";
import SimpleAlert from "../../../../../components/alert/alert";
import { Input, Typography } from "@material-ui/core";
import { dispatcher } from "../../../../../redux/dispatcher";
import moment from "moment";
import pengeluaranBankOperasionalDetailModel from "../../../../../models/kasir/bank-operasional/pengeluaranBankOperasionalDetailModel";
import { kasirBankOperasionalAPI } from "../../../../../api/kasir-bank-operasional";
import { getIdPerusahaan } from "../../../../../api/api";
import ButtonActions from "../../../../../components/button-actions/buttonActions";
import ButtonLoad from "../../../../../components/button-loading/buttonLoad";
import { PengeluaranOperasionalSupplierModel } from "../../../../../models/kasir/bank-operasional/pengeluaranBankOperasionalModel";

const getDifference = (arrayA, arrayB) => {
  return arrayA.filter(object1 => {
    return !arrayB.some(object2 => {
      return object1.kode === object2.kode
    });
  });
};
export default class FormPengeluaranBankOperasional extends Component {
  constructor(props) {
    super(props);
    this['pageName'] = "Form Pengeluaran Bank Operasional";
    this['apiPengeluaranBankOperasional'] = new kasirBankOperasionalAPI();
    this['initialData'] = dispatcher['kasir_bank_operasional'].getPengeluaranBankOperasional();
    this['initialDetailData'] = new pengeluaranBankOperasionalDetailModel();
    this['option_transaksi_ref'] = [];
    this['option_no_invoice'] = [];
    this['option_no_pesanan_penjualan'] = [];
    this['option_nama_supplier'] = [];
    this['option_jenis_pengeluaran'] = [];
    this['option_bank'] = [];
    this['searchParameter'] = ["kode", "tanggal", "nama_sales"];
    this['state'] = {
      submitForm: false,
      loading: false,
      no_transaksi: "",
      total_detail: "",
      ceklist_detail: false,
      toggledClearRows: false,
      rowSelectCritera: false,
      handleChange: true,
      showHideButton: false,
      showHideButtonBalance: false,
      submitDisabled: false,
      data_select_rows: [],
      dataTable: [],
      totalInputGroupHutangSupplier: 0,
      modal: {
        show: false,
        initialDetailData: this['initialDetailData'],
        isEdit: true,
      },
      modal2: {
        show: false,
        title: "",
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
    this['datatable'] = [{ id: "", no: "", name: "" }];
    this['columntable'] = [{ id: "", title: "" }];
    this['data_table_hutang_supplier'] = [];
    this['column_table_hutang_supplier'] = [];
    this['data_table_supplier'] = [];
    this['column_table_supplier'] = [];
  }
  //* ----------------------------------------------------------------------------- */
  //** Start: Component */
  async componentDidMount() {
    await this.LoadDetail();
    //** Start: Load Options Jenis Pengeluaran */
    this['apiPengeluaranBankOperasional'].getJenisTransaksiBankOperasional().then((res) => {
      this['option_jenis_pengeluaran'] = res['data'].map((val) => {
        return {
          value: val['id'],
          label: val['text'],
        };
      });
    });
    //** End: Load Options Jenis Pengeluaran */
    //* ----------------------------------------------------------------------------- */
    //** Start: Load Options Bank */
    this['apiPengeluaranBankOperasional'].getBank().then((res) => {
      this['option_bank'] = res['data'].map((val) => {
        return {
          value: val['id'],
          label: val['text'],
          no_rekening: val['no_rekening'],
        };
      });
    });
    //** End: Load Options Bank */
    //** Begin: Load Options Jenis Pengeluaran */
    this['apiPengeluaranBankOperasional'].getTransaksiRefPengeluaran(3).then((res) => {
      this['option_transaksi_ref'] = res['data'].map((val) => {
        return {
          value: val['id'],
          label: val['text'],
          alias: val['alias']
        };
      });
    });
    //** End: Load Options Jenis Pengeluaran */
  }
  //** End: Component */  //* ----------------------------------------------------------------------------- */
  //** Start: Load Data Options Select
  // No. Penjualan (No Transaksi Penjualan)
  async loadDataPesananPenjualan(id) {
    this['apiPengeluaranBankOperasional'].getNoTransaksiPenjualan(id).then((res) => {
      this['no_transaksi_penjualan'] = res['data'].map((val) => {
        return {
          value: val['id'],
          label: val['text'],
          id_kontak: val['id_kontak'],
          nama_customer: val['nama_customer'],
          alamat_customer: val['alamat_customer'],
          tanggal_pesanan_penjualan: val['tanggal_pesanan_penjualan'],
          nama_sales: val['nama_sales'],
          tersisa: val['tersisa'],
          total_pesanan_penjualan: val['total'],
          no_transaksi_penjualan: val['no_transaksi_penjualan'],
        };
      });
    });
  }
  // No. Pembelian (No Transaksi Pembelian)
  async LoadDataPembelian(id) {
    this['apiPengeluaranBankOperasional'].getNoTransaksiPembelian(id).then((res) => {
      this['option_transaksi_pembelian'] = res['data'].map((val) => {
        return {
          value: val['id'],
          label: val['text'],
          id_kontak: val['id_kontak'],
          nama_supplier: val['nama_supplier'],
          alamat_supplier: val['alamat_supplier'],
          tanggal_invoice_pembelian: val['tanggal_pesanan_penjualan'],
          nama_sales: val['nama_sales'],
          tersisa: val['tersisa'],
          total_invoice_pembelian: val['total'],
        };
      });
    });
  }

  // Nama Vendor
  async LoadDataNamaVendor(id) {
    this['apiPengeluaranBankOperasional'].getNamaSupplier(id).then((res) => {
      this['option_nama_supplier'] = res['data'].map((val) => {
        return {
          value: val['id'],
          label: val['text'],
        };
      });
    });
  }
  //** End: Load Data Options Select */
  //* ----------------------------------------------------------------------------- */
  //** Start: Sending Data */
  async sendingData(values, setSubmitting) {
    const { transaksi_ref_alias, id_kontak, no_transaksi_ref, no_transaksi_penjualan, tersisa, total } = values;
    let dataSend = {};
    let data = {
      id_transaksi_jenis: parseInt(values['id_transaksi_jenis']),
      id_bank: parseInt(values['id_bank']),
      id_transaksi_ref: parseInt(values['id_transaksi_ref']),
      tanggal: moment(values['tanggal']).format("YYYY-MM-DD"),
      kontak_ref: values['kontak_ref'],
      deskripsi: values['deskripsi'],
      id_user: parseInt(values['id_user']),
      id_perusahaan: getIdPerusahaan(),
    };
    if (transaksi_ref_alias === "pengembalian-batal-pesanan-penjualan") {
      if (parseInt(hapusSeparator(total)) > parseInt(tersisa)) {
        dispatcher.snackbar.show("Gagal", "Tersisa tidak boleh lebih besar dari jumlah!", "danger");
        this.setState({ submitForm: false });
        setSubmitting(false);
        return true;
      } else {
        dataSend = {
          ...data,
          no_transaksi_penjualan: no_transaksi_penjualan,
          no_transaksi_ref: no_transaksi_ref,
          id_kontak: id_kontak,
          total: parseInt(hapusSeparator(total))
        }
      }
    } else if (transaksi_ref_alias === "hutang-supplier") {
      let table_invoice = this['state']['dataTable'];
      if (table_invoice.length > 0) {
        dataSend = {
          ...data,
          total: this['state']['totalInputGroupHutangSupplier'],
          detail_invoice: table_invoice,
        }
      } else {
        dispatcher['snackbar'].show("Gagal", "Data detail Invoice tidak boleh kosong!", "danger");
        this.setState({ submitForm: false });
        setSubmitting(false);
        return true;
      }
    } else if (transaksi_ref_alias === "pengeluaran-operasional" || transaksi_ref_alias === "piutang-pemegang-saham") {
      let data_details = this['initialData']['detail'];
      if (data_details.length > 0) {
        dataSend = {
          ...data,
          total: this['state']['total_detail'],
          data_detail: data_details,
        }
      } else {
        dispatcher['snackbar'].show("Gagal", "Data detail tidak boleh kosong!", "danger");
        this.setState({ submitForm: false });
        setSubmitting(false);
        return true;
      }
    } else {
      dataSend = {
        ...data,
        total: parseInt(hapusSeparator(total))
      }
    }
    await this['apiPengeluaranBankOperasional'].setPengeluaranBankOperasional(dataSend).then((res) => {
      this.setState(res['data']); // set state No Transaksi
      if (res['status']) {
        dispatcher['snackbar'].show("Berhasil", res.message, "primary");
        this.setState({ submitForm: true });
        setSubmitting(true);
        this.LoadDetail();
      } else {
        dispatcher['snackbar'].show("Gagal", res.message, "danger");
        this.setState({ submitForm: false });
        setSubmitting(false);
      }
    });
  }
  //** End: Sending Data */
  //* ----------------------------------------------------------------------------- */
  //** Start: Load Detail Table */
  async LoadDetail() {
    let data = this['initialData']['detail'];
    this.setState({
      total_detail: data.reduce((total, value) => {
        const jumlah = typeof value['jumlah'] === "string" ? parseInt(hapusSeparator(value['jumlah'])) : value['jumlah'];
        if (value['ceklist']) {
          return (total - jumlah);
        } else {
          return (total + jumlah);
        }
      }, 0),
    });
    this['DataTableModel'] = new DataTableModel({ readOnly: this['state']['submitForm'] });
    data.map((item, i) => {
      if (item['status'] === 1) {
        this['DataTableModel'].add({
          id: i,
          no: i + 1,
          dataModel: item,
          actions:
            (!this['state']['submitForm']) ? (
              <>
                <ButtonActions
                  label={"edit"}
                  onClick={async () =>
                    await this['handleModal'].edit(item)
                  }
                />
                <ButtonActions
                  label={"delete"}
                  onClick={async () =>
                    this['handleAlert'].show(item)
                  }
                />
              </>
            ) : (null),
        });
      }
      return item;
    });
    this['columntable'] = await this['DataTableModel'].getColumn();
    this['datatable'] = await this['DataTableModel'].getDatas();
    this.setState({ ...this['state'] });
  }
  //** End: Load Detail Table */
  //* ----------------------------------------------------------------------------- */
  //** Start: Load Detail Table Hutang Supplier */
  async LoadDetailHutangSupplier(data) {
    this.setState({ loading: true });
    this.setState({
      dataTable: data,
      totalInputGroupHutangSupplier: 0,
      pending: false,
    });
  }
  //** End: Load Detail Table Hutang Supplier */
  //* ----------------------------------------------------------------------------- */
  //** Start: Handle Modal/Alert */]
  handleModal = {
    loadOptionAkunDetails: async () => {
      this['apiPengeluaranBankOperasional'].getAkunPengeluaran().then((res) => {
        // Load Data option Akun
        this['option_akun'] = res['data'].map((val) => {
          return {
            value: val['id'],
            label: val['text'],
            kode_akun: val['kode_akun'],
            nama_akun: val['nama_akun'],
          };
        });
      });
    },
    tambah: async () => {
      await this['handleModal'].loadOptionAkunDetails();
      this.setState({
        modal: {
          show: true,
          title: "Tambah Dana",
          isEdit: false,
          initialDetailData: new pengeluaranBankOperasionalDetailModel()
        }
      });
    },
    edit: async (dataFromModel) => {
      await this['handleModal'].loadOptionAkunDetails();
      this.setState({
        modal: {
          show: true,
          title: "Edit Dana",
          isEdit: true,
          initialDetailData: new pengeluaranBankOperasionalDetailModel({
            ...dataFromModel,
          })
        }
      });
    },
    hide: () => {
      this.setState({
        modal: {
          show: false,
          title: "",
          isEdit: false,
          initialDetailData: new pengeluaranBankOperasionalDetailModel()
        }
      });
    }
  };

  handleModalNoInvoice = {
    // Modal Data Supplier
    LoadNoInvoice: async (is_supplier) => {
      this.setState({ loading: true });
      let data = await this['apiPengeluaranBankOperasional'].getDataSupplier({ is_supplier: is_supplier });
      let datas = getDifference(data['data'], this.state.data_select_rows);
      this['DataTableModel'] = new DataTableModel({ readOnly: true });
      datas.map((item, i) => {
        let dataModel = new PengeluaranOperasionalSupplierModel(item);
        this['DataTableModel'].add({
          id: item['id'],
          no: i + 1,
          dataModel: dataModel,
          actions: null,
        });
        return item;
      });
      this['data_table_hutang_supplier'] = await this['DataTableModel'].getDatas();
      this['column_table_hutang_supplier'] = await this['DataTableModel'].getColumn();
      this.setState({ loading: false });
    },
    addDataSupplier: async (is_supplier, nama_supplier_text) => {
      if (!is_supplier && !nama_supplier_text) {
        dispatcher.snackbar.show("Gagal", "Nama Supplier tidak boleh kosong!", "danger");
        return true
      }
      await this['handleModalNoInvoice'].LoadNoInvoice(is_supplier);
      this.setState({
        modal2: {
          show: true,
          title: `Tambah Data Supplier : ${nama_supplier_text}`,
          isEdit: false,
        }
      });
    },
    hideDataSupplier: () => {
      this.setState({
        modal2: {
          show: false,
          title: "",
          isEdit: false,
        },
      });
    },
  };
  handleAlert = {
    // Show Alert
    show: (dataModel) => {
      this.setState({
        alert: {
          activeId: dataModel,
          show: true
        }
      });
    },
    // Hide Alert
    hide: () => {
      this.setState({
        alert: {
          activeId: null,
          show: false
        }
      });
    },
    // Confirmed Alert
    confirmed: async () => {
      await this['initialData']['handleDetail'].del(this['state']['alert']['activeId']);
      this['handleAlert'].hide();
      await this.LoadDetail();
    },
  };
  //** End: Handle Modal/Alert */
  //* ----------------------------------------------------------------------------- */
  render() {
    const { no_transaksi, loading, modal, modal2, alert, submitForm, submitDisabled, toggledClearRows, total_detail, dataTable, totalInputGroupHutangSupplier } = this['state'];
    const rowSelectCritera = (row) => {
      this.setState({
        rowSelectCritera: false,
      });
    };

    const handleInputChange = (rowData, name, value) => {
      const tmpData = [...dataTable];
      const tmpRow = tmpData.find((person) => person.id === rowData.id);
      tmpRow[name] = value;
      const totalInputGroupHutangSupplier = tmpData.reduce((total, val) => {
        let jumlah = typeof val['jumlah'] === "string" ? parseInt(hapusSeparator(val['jumlah'])) : val['jumlah'];
        return (total + jumlah);
      }, 0);
      this.setState({
        dataTable: tmpData,
        totalInputGroupHutangSupplier: totalInputGroupHutangSupplier,
      });
    };

    // Column Detail Input Hutang Supplier
    const columns = [
      {
        name: "ID",
        selector: (row) => row.id,
        center: true,
        minWidth: "10%",
        omit: true
      }, {
        name: "No. Invoice",
        selector: (row) => row.kode,
        center: true,
        sortable: false,
        minWidth: "12%",
      }, {
        name: "Tanggal",
        selector: row => row['tanggal'],
        center: true,
        sortable: false,
        minWidth: "12%",
      }, {
        name: "Tersisa",
        selector: row => row['tersisa'],
        right: true,
        sortable: false,
        minWidth: "22%",
      }, {
        name: "Jumlah",
        selector: row => row['jumlah'],
        cell: (row) => {
          return (
            <React.Fragment>
              <div className={"col-lg-12"} style={{ marginTop: "5px", marginBottom: "5px" }}>
                <Input
                  placeholder={"Rp. 0"}
                  component={Input}
                  style={{ resize: "none" }}
                  value={(row['jumlah'] < 0)
                    ? "Rp. -" + separatorHarga(row['jumlah'].toString())
                    : separatorHarga(row['jumlah'].toString(), 'Rp. ')}
                  className="form-control"
                  onChange={(e) => {
                    if (parseInt(hapusSeparator(row['tersisa'])) < parseInt(hapusSeparator(e['target']['value']))) {
                      this.setState({
                        submitDisabled: true,
                      });
                      return dispatcher.snackbar.show("Gagal", "Tersisa tidak boleh lebih besar dari jumlah!", "danger");
                    }
                    this.setState({
                      submitDisabled: false,
                    });
                    handleInputChange(row, "jumlah", e['target']['value']);
                  }}
                />
              </div>
            </React.Fragment>
          )
        },
        center: true,
        sortable: false,
        minWidth: "20%",
      }];
    return (
      <React.Fragment>
        {/* Form */}
        <Formik
          enableReinitialize={true}
          initialValues={this['initialData']['transaksi']}
          validationSchema={Yup.object().shape({
            id_transaksi_jenis: Yup.string().required("Jenis Pengeluaran harus diisi"),
            id_transaksi_ref: Yup.string().required("Transaksi Ref harus diisi"),
            id_bank: Yup.string().nullable(true).required("Bank harus diisi"),
            no_transaksi_ref: Yup.string().when("transaksi_ref_alias", (transaksi_ref_alias) => {
              if (transaksi_ref_alias === "pengembalian-batal-pesanan-penjualan") {
                return Yup.string().required("Nomor Transaksi harus diisi");
              }
            }),
            nama_supplier: Yup.string().when("transaksi_ref_alias", (transaksi_ref_alias) => {
              if (transaksi_ref_alias === "hutang-supplier") {
                return Yup.string().required("Nama Supplier harus diisi");
              }
            }),
            tanggal: Yup.mixed().required(),
            kontak_ref: Yup.mixed().required(),
            deskripsi: Yup.string().max(150, "Maximum 150 String").required("Keterangan harus diisi"),
            total: Yup.string().when('transaksi_ref_alias', (transaksi_ref_alias) => {
              if (transaksi_ref_alias === "pengembalian-batal-pesanan-penjualan") {
                return Yup.string().required();
              }
            }),
          })}
          onSubmit={(values, { setSubmitting }) => {
            this.sendingData(values, setSubmitting);
          }}
        >
          {({ handleSubmit, isSubmitting, touched, errors, setFieldValue, setFieldTouched, values }) => (
            <AnimatedCard>
              <CardHeader title={this['pageName']}>
                <CardHeaderToolbar>
                  {/* Button Cetak Pengeluaran */}
                  {submitForm ? (
                    <React.Fragment>
                      {/* Button Cetak Bukti Pengeluaran */}
                      <button
                        type={"button"}
                        className={"btn btn-outline-success mx-1 btn-sm"}
                        onClick={async () => {
                          dispatcher['kasir_bank_operasional'].cetakBuktiPengeluaran(no_transaksi);
                        }}
                      ><i className={"fa fa-envelope-open-text"}></i> Bukti Pengeluaran
                      </button>
                      {/* Button Transaksi Baru */}
                      <button
                        type={"button"}
                        className={"btn btn-outline-primary mx-1 btn-sm"}
                        onClick={async () => {
                          window['location'].reload();
                        }}
                      ><i className={"fa fa-plus"}></i> Transaksi Baru
                      </button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {/* Button Simpan */}
                      <ButtonLoad
                        label={"Simpan"}
                        pending={isSubmitting}
                        disabled={isSubmitting || submitDisabled}
                        classNameIcon={"fas fa-save"}
                        className={"btn btn-primary mx-1"}
                        onClick={async () => handleSubmit()}
                      />
                    </React.Fragment>
                  )}
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <Form className={"form form-label-right"}>
                  <div className={"form-group row"}>
                    <div className={"col-lg-6"}>
                      <div className={"form-group row"}>
                        {/* Jenis Pengeluaran */}
                        <FormsFields
                          id={"id_transaksi_jenis"}
                          type={"select2"}
                          label={"Jenis Pengeluaran"}
                          className={"col-lg-12"}
                          isDisabled={true}
                          options={this['option_jenis_pengeluaran']}
                          paramsFormik={{
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          }}
                        />
                        {/* Nama Bank */}
                        <FormsFields
                          id={"id_bank"}
                          type={"select2"}
                          label={"Bank"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          isClearable={true}
                          isSearchable={true}
                          isDisabled={submitForm}
                          options={this['option_bank']}
                          onChange={(e) => {
                            setFieldValue("no_rekening", e ? e['no_rekening'] : "");
                          }}
                          paramsFormik={{
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          }}
                        />
                        {/* No. Rekening */}
                        {values['id_bank'] !== "" ? (
                          <FormsFields
                            id={"no_rekening"}
                            type={"disabled-text"}
                            label={"No. Rekening"}
                            className={"col-lg-12"}
                            style={{ marginTop: "20px" }}
                            value={values['no_rekening']}
                          />
                        ) : null}
                        {/* Transaksi Ref */}
                        <FormsFields
                          id={"id_transaksi_ref"}
                          type={"select2"}
                          label={"Transaksi Ref"}
                          style={{ marginTop: "20px" }}
                          className={"col-lg-12"}
                          isClearable={true}
                          isSearchable={true}
                          isDisabled={submitForm}
                          options={this['option_transaksi_ref']}
                          onChange={(e) => {
                            setFieldValue("transaksi_ref_alias", e ? e['alias'] : "");
                            this['initialData']['handleDetail'].reset();
                            if (e) {
                              if (e['alias'] === "pengembalian-batal-pesanan-penjualan") {
                                this.loadDataPesananPenjualan(e ? e['value'] : "");
                                // Clear Form 
                                setFieldValue("no_transaksi_penjualan", "");
                                setFieldValue("no_transaksi_ref", "");
                                setFieldValue("nama_customer", "");
                                setFieldValue("alamat_customer", "");
                                setFieldValue("tersisa", "");
                                setFieldValue("tanggal_pesanan_penjualan", "");
                                setFieldValue("nama_sales", "");
                                setFieldValue("total_pesanan_penjualan", "");
                                setFieldValue("total", "");
                              } else if (e['alias'] === "hutang-supplier") {
                                this.LoadDataNamaVendor(e ? e['value'] : "");
                                // // Clear Form 
                                // setFieldValue("no_transaksi_ref", "");
                                // setFieldValue("nama_supplier", "");
                                // setFieldValue("alamat_supplier", "");
                                // setFieldValue("tersisa", "");
                                // setFieldValue("tanggal_invoice_pembelian", "");
                                // setFieldValue("nama_sales", "");
                                // setFieldValue("total_invoice_pembelian", "");
                                // setFieldValue("total", "");
                              } else if (e['alias'] === "pengeluaran-operasional" || e['alias'] === "piutang-pemegang-saham") {
                                // Clear Table Details
                                this.LoadDetail();
                                setFieldValue("total", 0);
                              }
                              else {
                                // Clear Table Details
                                this.LoadDetail();
                                setFieldValue("total", "");
                              }
                            } else {
                              this.LoadDetail();
                              setFieldValue("total", "");
                            }
                          }}
                          paramsFormik={{
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          }}
                        />
                        {/* Show/Hide Pengembalian Batal Pesanan Penjualan */}
                        {(values['transaksi_ref_alias'] === "pengembalian-batal-pesanan-penjualan") ? (
                          <>
                            {/* No. Transaksi {Pesanan Penjualan} */}
                            <FormsFields
                              id={"no_transaksi_ref"}
                              type={"select2"}
                              label={"No. Transaksi"}
                              style={{ marginTop: "20px" }}
                              className={"col-lg-12"}
                              isClearable={true}
                              isSearchable={true}
                              isDisabled={submitForm}
                              options={this['no_transaksi_penjualan']}
                              onChange={(e) => {
                                setFieldValue("no_transaksi_penjualan", e ? e['no_transaksi_penjualan'] : "");
                                setFieldValue("id_kontak", e ? e['id_kontak'] : "");
                                setFieldValue("nama_customer", e ? e['nama_customer'] : "");
                                setFieldValue("alamat_customer", e ? e['alamat_customer'] : "");
                                setFieldValue("tanggal_pesanan_penjualan", e ? e['tanggal_pesanan_penjualan'] : "");
                                setFieldValue("nama_sales", e ? e['nama_sales'] : "");
                                setFieldValue("tersisa", e ? e['tersisa'] : "");
                                setFieldValue("total_pesanan_penjualan", e ? e['total_pesanan_penjualan'] : "");
                                setFieldValue("total", "");
                              }}
                              paramsFormik={{
                                setFieldValue,
                                setFieldTouched,
                                touched,
                                errors,
                                values
                              }}
                            />
                            {/* Nama Customer */}
                            <FormsFields
                              id={"nama_customer"}
                              type={"disabled-text"}
                              label={"Nama Customer"}
                              className={"col-lg-12"}
                              style={{ marginTop: "20px" }}
                              value={values['nama_customer']}
                            />
                            {/* Alamat Customer */}
                            <FormsFields
                              id={"alamat_customer"}
                              type={"disabled-textarea"}
                              label={"Alamat Customer"}
                              className={"col-lg-12"}
                              style={{ marginTop: "20px" }}
                              rows={2}
                              value={values['alamat_customer']}
                            />
                            {/* Pembayaran Tersisa */}
                            <FormsFields
                              id={"tersisa"}
                              type={"disabled-currency"}
                              label={"Tersisa"}
                              className={"col-lg-12"}
                              style={{ marginTop: "20px" }}
                              value={values['tersisa']}
                            />
                          </>
                        ) : (values['transaksi_ref_alias'] === "hutang-supplier") ? (
                          <React.Fragment>
                            {/* Nama Supplier */}
                            <FormsFields
                              id={"nama_supplier"}
                              type={"select2"}
                              label={"Nama Supplier"}
                              style={{ marginTop: "20px" }}
                              className={"col-lg-12"}
                              isClearable={true}
                              isSearchable={true}
                              isDisabled={submitForm}
                              options={this['option_nama_supplier']}
                              onChange={async (e) => {
                                setFieldValue("nama_supplier_text", e ? e['label'] : "");
                                await this.LoadDetailHutangSupplier([]);
                              }}
                              paramsFormik={{
                                setFieldValue,
                                setFieldTouched,
                                touched,
                                errors,
                                values
                              }}
                            />
                          </React.Fragment>
                        ) : null}
                      </div>
                    </div>
                    <div className={"col-lg-6"}>
                      <div className={"form-group row"}>
                        {/* Tanggal Transaksi */}
                        <FormsFields
                          id={"tanggal"}
                          type={"DatePicker"}
                          label={"Tanggal Transaksi"}
                          className={"col-lg-12"}
                          disabled={isSubmitting}
                          paramsFormik={{
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          }}
                        />
                        {/* Dibayar Kepada */}
                        <FormsFields
                          id={"kontak_ref"}
                          type={"text"}
                          label={"Dibayar Kepada"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          disabled={isSubmitting}
                          paramsFormik={{
                            setFieldValue,
                            values
                          }}
                        />
                        {/* Keterangan */}
                        <FormsFields
                          id={"deskripsi"}
                          type={"textarea"}
                          label={"Keterangan"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          disabled={isSubmitting}
                          rows={3}
                          paramsFormik={{
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          }}
                        />
                        {/* Show/Hide Form */}
                        {values['transaksi_ref_alias'] === "pengembalian-batal-pesanan-penjualan" ? (
                          <>
                            {/* Tanggal Pesanan Penjualan */}
                            <FormsFields
                              id={'tanggal_pesanan_penjualan'}
                              type={"disabled-DatePicker"}
                              label={"Tanggal Pesanan Penjualan"}
                              className={"col-lg-12"}
                              style={{ marginTop: "20px" }}
                              value={values['tanggal_pesanan_penjualan']}
                            />
                            {/* Nama Sales */}
                            <FormsFields
                              id={"nama_sales"}
                              type={"disabled-text"}
                              label={"Nama Sales"}
                              className={"col-lg-12"}
                              style={{ marginTop: "20px" }}
                              value={values['nama_sales']}
                            />
                            {/* Pembayaran Total */}
                            <FormsFields
                              id={"total_pesanan_penjualan"}
                              type={"disabled-currency"}
                              label={"Total"}
                              className={"col-lg-12"}
                              style={{ marginTop: "20px" }}
                              value={values['total_pesanan_penjualan']}
                            />
                            {/* Jumlah */}
                            <FormsFields
                              id={"total"}
                              type={"currency"}
                              label={"Jumlah"}
                              className={"col-lg-12"}
                              style={{ marginTop: "20px" }}
                              disabled={isSubmitting}
                              onChange={(e) => {
                                const { transaksi_ref_alias, no_transaksi_ref, tersisa } = values;
                                if (transaksi_ref_alias === "pengembalian-batal-pesanan-penjualan" && no_transaksi_ref === "") {
                                  dispatcher['snackbar'].show("Peringatan",
                                    "No. Transaksi Invoice Penjualan tidak boleh kosong...!",
                                    "warning");
                                  return false;
                                } else {
                                  if (parseInt(hapusSeparator(e['target']['value'])) > parseInt(tersisa)) {
                                    dispatcher['snackbar'].show("Peringatan",
                                      "Jumlah terbayar tidak boleh lebih dari biaya tersisa!",
                                      "warning"
                                    );
                                    return false;
                                  }
                                }
                              }}
                              paramsFormik={{
                                setFieldValue,
                                values
                              }}
                            />
                          </>
                        ) : values['transaksi_ref_alias'] === "hutang-supplier" ? (
                          <>
                            {/* Tanggal Invoice */}
                            {/* <FormsFields
                              id={ 'tanggal_invoice_pembelian' }
                              type={ "disabled-DatePicker" }
                              label={ "Tanggal Invoice" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              value={ values[ 'tanggal_invoice_pembelian' ] }
                            /> */}
                            {/* Nama Sales */}
                            {/* <FormsFields
                              id={ 'nama_sales' }
                              type={ "disabled-text" }
                              label={ "Nama Sales" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              value={ values[ 'nama_sales' ] }
                            /> */}
                            {/* Pembayaran Tersisa */}
                            {/* <FormsFields
                              id={ "total_invoice_pembelian" }
                              type={ "disabled-currency" }
                              label={ "Total" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              value={ values[ 'total_invoice_pembelian' ] }
                            /> */}
                            {/* Jumlah */}
                            {/* <FormsFields
                              id={ "total" }
                              type={ "currency" }
                              label={ "Jumlah" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              disabled={ isSubmitting }
                              onChange={ ( e ) =>
                              {
                                const { transaksi_ref_alias, no_transaksi_ref, tersisa } = values;
                                if ( transaksi_ref_alias === "hutang-supplier" && no_transaksi_ref === "" )
                                {
                                  dispatcher[ 'snackbar' ].show( "Peringatan",
                                    "No. Transaksi Invoice Pembelian tidak boleh kosong...!",
                                    "warning" );
                                  return false;
                                } else
                                {
                                  if ( parseInt( hapusSeparator( e[ 'target' ][ 'value' ] ) ) > parseInt( tersisa ) )
                                  {
                                    dispatcher[ 'snackbar' ].show( "Peringatan",
                                      "Jumlah terbayar tidak boleh lebih dari biaya tersisa!",
                                      "warning"
                                    );
                                    return false;
                                  }
                                }
                              } }
                              paramsFormik={ {
                                setFieldValue,
                                values
                              } }
                            /> */}
                          </>
                        ) : values['transaksi_ref_alias'] === "pengeluaran-operasional" || values['transaksi_ref_alias'] === "piutang-pemegang-saham" ? (
                          <>
                            {/* Total */}
                            <FormsFields
                              id={"total"}
                              type={"disabled-currency"}
                              label={"Total"}
                              className={"col-lg-12"}
                              style={{ marginTop: "20px" }}
                              value={total_detail}
                            />
                          </>
                        ) : (
                          <>
                            {/* Default Jumlah */}
                            <FormsFields
                              id={"total"}
                              type={"currency"}
                              label={"Jumlah"}
                              className={"col-lg-12"}
                              style={{ marginTop: "20px" }}
                              disabled={isSubmitting}
                              paramsFormik={{
                                setFieldValue,
                                values
                              }}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
              </CardBody>
              {/* Detail Dana */}
              {(values['transaksi_ref_alias'] === "pengeluaran-operasional" || values['transaksi_ref_alias'] === "piutang-pemegang-saham") ? (
                <React.Fragment>
                  <CardFooter>
                    <span className={"d-flex align-items-center justify-content-between mt-4 mb-4"}>
                      <Typography variant={"h6"}>Detail</Typography>
                      {(!submitForm) ? (
                        <>
                          <button
                            type={"button"}
                            className={"btn btn-outline-primary btn-sm"}
                            onClick={async () => {
                              await this['handleModal'].tambah();
                            }}
                          >
                            <i className={"fa fa-plus"}></i>
                            {"Tambah Dana"}
                          </button>
                        </>
                      ) : ""}
                    </span>
                    <ReactDatatableDetails
                      title={""}
                      data={this['datatable']}
                      columns={this['columntable']}
                    />
                  </CardFooter>
                </React.Fragment>
              ) : ""}

              {(values['transaksi_ref_alias'] === "hutang-supplier") ? (
                <React.Fragment>
                  <CardFooter>
                    <span className={"d-flex align-items-center justify-content-between mt-4 mb-4"}>
                      <Typography variant={"h6"}>{values['nama_supplier_text']}</Typography>
                      {(!submitForm) ? (
                        <>
                          <button
                            type={"button"}
                            className={"btn btn-outline-primary btn-sm"}
                            onClick={async () => {
                              await this['handleModalNoInvoice'].addDataSupplier(values['nama_supplier'], values['nama_supplier_text']);
                            }}
                          >
                            <i className={"fa fa-plus"}></i>
                            {"Tambah Data"}
                          </button>
                        </>
                      ) : ""}
                    </span>
                    <ReactDatatableDetails
                      title={``}
                      fixedHeaderScrollHeight={"550px"}
                      columns={columns}
                      data={dataTable}
                    />
                    <div style={{ float: "right" }}>
                      <FormsFields
                        id={'totalInputGroupHutangSupplier'}
                        type={"disabled-currency-group"}
                        labelGroup={"Total :"}
                        className={"col-lg-12"}
                        style={{ marginBottom: "15px" }}
                        value={totalInputGroupHutangSupplier}
                      />
                    </div>
                    {/* <ReactDatatableDetails
                      title={""}
                      data={this['data_table_supplier']}
                      columns={this['column_table_supplier']}
                    /> */}
                  </CardFooter>
                </React.Fragment>
              ) : ""}
            </AnimatedCard>
          )}
        </Formik>
        {/* End: Formik Form */}
        {/* ----------------------------------------------------------------------------- */}
        {/* Start: Modal Detail Form */}
        <Formik
          enableReinitialize={true}
          initialValues={modal['initialDetailData']}
          validationSchema={Yup.object().shape({
            id_akun: Yup.string()
              .ensure()
              .required("Akun harus diisi"),
            deskripsi: Yup.string()
              .max(150, "Maximum 150 String")
              .required("Keterangan harus diisi"),
            jumlah: Yup.string().required(),
          })}
          onSubmit={async (values, { resetForm }) => {
            if (modal['isEdit']) {
              await this['initialData']['handleDetail'].edit(values);
            } else {
              await this['initialData']['handleDetail'].set(values);
            }
            await this.LoadDetail();
            resetForm();
            this['handleModal'].hide();
          }}
        >
          {({ handleSubmit, setFieldValue, setFieldTouched, touched, errors, values }) => (
            <ModalForms
              show={modal['show']}
              title={modal['title']}
              onHide={this['handleModal']['hide']}
              onConfirm={handleSubmit}
              content={
                <div className={"form-group row"}>
                  {/* Akun */}
                  <FormsFields
                    id={"id_akun"}
                    type={"select2"}
                    label={"Akun"}
                    className={"col-lg-12"}
                    isClearable={false}
                    isSearchable={true}
                    options={this['option_akun']}
                    onChange={(e) => {
                      setFieldValue("kode_akun", e ? e['kode_akun'] : "");
                      setFieldValue("nama_akun", e ? e['nama_akun'] : "");
                    }}
                    paramsFormik={{
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    }}
                  />
                  {/* Keterangan */}
                  <FormsFields
                    id={"deskripsi"}
                    type={"textarea"}
                    label={"Keterangan"}
                    className={"col-lg-12"}
                    style={{ marginTop: "20px" }}
                    rows={2}
                    paramsFormik={{
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    }}
                  />
                  {/* Jumlah */}
                  <FormsFields
                    id={"jumlah"}
                    type={"currency"}
                    label={"Jumlah"}
                    className={"col-lg-12"}
                    style={{ marginTop: "20px" }}
                    value={values['jumlah']}
                    paramsFormik={{
                      setFieldValue
                    }}
                  />
                  {/* Negatif */}
                  <FormsFields
                    id={"ceklist"}
                    type={"checkbox"}
                    label={"Negatif"}
                    indeterminate={true}
                    className={"col-lg-12"}
                    style={{ marginTop: "5px" }}
                    paramsFormik={{
                      setFieldValue,
                      values
                    }}
                  />
                  {/* Upload Gambar */}
                  <FormsFields
                    id={"upload_gambar_bop_bank_operasional"}
                    type={"image"}
                    label={"Upload Gambar"}
                    className={"col-lg-12"}
                    style={{ marginTop: "20px" }}
                    paramsFormik={{
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    }}
                  />
                </div>
              }
            />
          )}
        </Formik>
        {/* End: Modal Detail Form */}
        {/* ----------------------------------------------------------------------------- */}
        {/* Start: Modal Detail No Transaksi Invoice By Supplier */}
        <Formik
          enableReinitialize={false}
          initialValues={{
            data_select_rows: []
          }}
          validationSchema={Yup.object().shape({
            data_select_rows: Yup.string().required("Data invoice tidak boleh kosong!"),
          })}
          onSubmit={async (values) => {
            this.LoadDetailHutangSupplier(values['data_select_rows'])
            this['handleModalNoInvoice'].hideDataSupplier();
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <ModalForms
              show={modal2['show']}
              title={modal2['title']}
              onHide={this['handleModalNoInvoice']['hideDataSupplier']}
              onConfirm={handleSubmit}
              size={'xl'}
              content={
                <React.Fragment>
                  <ReactDatatable
                    title={""}
                    data={this['data_table_hutang_supplier']}
                    columns={this['column_table_hutang_supplier']}
                    searchParameter={this['searchParameter']}
                    progressPending={loading}
                    selectableRows={true}
                    selectableRowSelected={this['state']['rowSelectCritera'] ? rowSelectCritera : null}
                    onSelectedRowsChange={({ selectedRows }) => {
                      setFieldValue("data_select_rows", selectedRows);
                    }}
                    clearSelectedRows={toggledClearRows}
                  />
                </React.Fragment>
              }
            />
          )}
        </Formik>
        {/* End: Modal Detail Form */}
        {/* ----------------------------------------------------------------------------- */}
        {/* Start: Alert */}
        <SimpleAlert
          title={"Hapus Data"}
          content={"Anda yakin hapus data ini...?"}
          show={alert['show']}
          onHide={() => this['handleAlert'].hide()}
          onConfirmed={this['handleAlert']['confirmed']}
        />
        {/* End: Alert */}
      </React.Fragment>
    );
  }
}
import { Button, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";

export default function SimpleAlert2({
  show,
  title,
  content,
  onConfirm,
  variant,
  confirmButton,
}) {
  const [showAlert, setShow] = useState(show);

  useEffect(() => {
    setShow(show);
  }, [show]);

  return (
    <>
      <Modal
        show={showAlert}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Batal
          </Button>
          <Button
            variant={variant ?? "primary"}
            onClick={() => {
              setShow(false);
              if (onConfirm != null) onConfirm();
            }}
          >
            {confirmButton ?? "Simpan Perubahan"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

import moment from "moment";
import { separatorHarga } from "../../../services/separator-harga";
export default class bukuBesarDetailModel {
  constructor(
    data = {
      id: 0,
      tanggal: 0,
      no_transaksi: "",
      deskripsi: "",
      cabang: "",
      bank: "",
      nama_akun: "",
      kode_akun: 0,
      debet: 0,
      kredit: 0,
      id_perusahaan: "",
    }
  ) {
    const { id, tanggal, no_transaksi, deskripsi, cabang, bank, nama_akun, kode_akun, debet, kredit, id_perusahaan } = data;
    this['id'] = id;
    this['tanggal'] = tanggal;
    this['no_transaksi'] = no_transaksi;
    this['deskripsi'] = deskripsi;
    this['cabang'] = cabang;
    this['bank'] = bank;
    this['nama_akun'] = nama_akun;
    this['kode_akun'] = kode_akun;
    this['debet'] = debet;
    this['kredit'] = kredit;
    this['id_perusahaan'] = id_perusahaan;
  }
  async getdata() {
    const { tanggal, no_transaksi, deskripsi, cabang, bank, nama_akun, kode_akun, debet, kredit } = this;
    return [
      {
        id: "tanggal",
        title: "Tanggal",
        label: moment(tanggal).format("DD-MM-YYYY"),
        center: true,
        sortable: true,
        minWidth: "12%",
      }, {
        id: "no_transaksi",
        title: "No. Transaksi",
        label: no_transaksi,
        center: true,
        sortable: true,
        minWidth: "26%",
      }, {
        id: "deskripsi",
        title: "Keterangan",
        label: deskripsi,
        sortable: true,
        minWidth: "60%",
      }, {
        id: "cabang",
        title: "Cabang",
        label: cabang,
        center: true,
        sortable: true,
        minWidth: "20%",
      }, {
        id: "bank",
        title: "Bank",
        label: bank,
        sortable: true,
        minWidth: "35%",
        omit: bank === "" ? true : false
      }, {
        id: "nama_akun",
        title: "Nama Akun",
        label: nama_akun,
        sortable: true,
        minWidth: "25%",
      }, {
        id: "kode_akun",
        title: "Kode Akun",
        label: kode_akun,
        center: true,
        sortable: true,
        minWidth: "18%",
      }, {
        id: "debet",
        title: "Debet",
        label: debet < 0 ? `Rp. -${separatorHarga(debet.toString())}` : separatorHarga(debet.toString(), "Rp. "),
        right: true,
        sortable: false,
        minWidth: "13%",
      }, {
        id: "kredit",
        title: "Kredit",
        label: kredit < 0 ? `Rp. -${separatorHarga(kredit.toString())}` : separatorHarga(kredit.toString(), "Rp. "),
        right: true,
        sortable: false,
        minWidth: "13%",
      },
    ];
  }
}

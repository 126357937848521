import { Typography } from "@material-ui/core";

import * as Yup from "yup";

import { Form, Formik } from "formik";

import React, { Component } from "react";

import moment from "moment";

import { kasirKasAPI } from "../../../../../api/kasir-kas";
import { dispatcher } from "../../../../../redux/dispatcher";
import { hapusSeparator } from "../../../../../services/separator-harga";
import SimpleAlert from "../../../../../components/alert/alert";
import { AnimatedCard } from "../../../../../components/animated-card/animatedCard";
import ButtonActions from "../../../../../components/button-actions/buttonActions";
import ButtonLoad from "../../../../../components/button-loading/buttonLoad";
import { FormsFields } from "../../../../../components/forms-fields/FormsFields";
import { ModalForms } from "../../../../../components/modal-forms/modalForms";
import { ReactDatatableDetails } from "../../../../../components/react-datatable/reactDatatable";
import DataTableModel from "../../../../../models/datatable-model/DataTableModel";
import { CardBody, CardFooter, CardHeader, CardHeaderToolbar } from "../../../../../../_metronic/_partials/controls";
import penerimaanKasDetailModel from "../../../../../models/kasir/kas/penerimaanKasDetailModel";

export default class FormPenerimaanKas extends Component
{
  constructor( props )
  {
    super( props );
    this[ 'pageName' ] = "Form Penerimaan Kas";
    this[ 'apiPenerimaanKas' ] = new kasirKasAPI();
    this[ 'initialData' ] = dispatcher[ 'kasir_kas' ].getPenerimaanKas();
    this[ 'initialDetailData' ] = new penerimaanKasDetailModel();
    this[ 'option_jenis_penerimaan' ] = [];
    this[ 'option_transaksi_ref' ] = [];
    this[ 'option_transaksi_dp_penjualan' ] = [];
    this[ 'option_transaksi_ar_penjualan' ] = [];
    this[ 'option_kolektor' ] = [];
    this[ 'state' ] = {
      submitForm: false,
      no_transaksi: "",
      total_detail: 0,
      modal: {
        show: false,
        initialDetailData: this[ 'initialDetailData' ],
        isEdit: true,
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
    this[ 'datatable' ] = [ { id: "", no: "", name: "" } ];
    this[ 'columntable' ] = [ { id: "", title: "" } ];
  }
  //* ----------------------------------------------------------------------------- */
  //** Begin:: Component */
  async componentDidMount ()
  {
    //** Begin:: Load Options Jenis Penerimaan */
    await this[ 'apiPenerimaanKas' ].getJenisTransaksi( "kas" ).then( ( res ) =>
    {
      this[ 'option_jenis_penerimaan' ] = res[ 'data' ].map( ( val ) =>
      {
        return {
          value: val[ 'id' ],
          label: val[ 'text' ],
        };
      } );
    } );
    //** End: Load Options Jenis Penerimaan */
    //* ----------------------------------------------------------------------------- */
    //** Begin:: Load Options Transaksi Ref */
    // default ID = 1 (KAS)
    await this[ 'apiPenerimaanKas' ].getTransaksiRefPenerimaan( 1 ).then( ( res ) =>
    {
      this[ 'option_transaksi_ref' ] = res[ 'data' ].map( ( val ) =>
      {
        return {
          value: val[ 'id' ],
          label: val[ 'text' ],
          alias: val[ 'alias' ]
        };
      } );
    } );
    //** End: Load Options Transaksi Ref */

    //** Begin:: Select Kolektor */
    await this[ 'apiPenerimaanKas' ].getKolektor().then( ( res ) =>
    {
      this[ 'option_kolektor' ] = res[ 'data' ].map( ( val ) =>
      {
        return {
          value: val[ 'id' ],
          label: val[ 'text' ],
          alias: val[ 'alias' ]
        };
      } );
    } );
    //** End:: Select Kolektor */
  }
  //** End: Component */
  //* ----------------------------------------------------------------------------- */
  //** Begin:: Load Data Options Select
  // No. Penjualan (No Transaksi DP Penjualan)
  async loadDataDPPesananPenjualan ( id )
  {
    this[ 'apiPenerimaanKas' ].getNoTransaksiDPPenjualan( id ).then( ( res ) =>
    {
      this[ 'option_transaksi_dp_penjualan' ] = res[ 'data' ].map( ( val ) =>
      {
        return {
          value: val[ 'id' ],
          label: val[ 'text' ],
          id_kontak: val[ 'id_kontak' ],
          nama_customer: val[ 'nama_customer' ],
          alamat_customer: val[ 'alamat_customer' ],
          nama_sales: val[ 'nama_sales' ],
          tanggal_dp: val[ 'tanggal_dp' ],
          tersisa_dp: val[ 'tersisa_dp' ],
          total_dp: val[ 'total_dp' ],
          no_transaksi_dp: val[ 'no_transaksi_dp' ],
        };
      } );
    } );
  }
  // No. Pembelian (No Transaksi AR Penjualan)
  async LoadDataARPemjualan ( id )
  {
    this[ 'apiPenerimaanKas' ].getNoTransaksiARPejualan( id ).then( ( res ) =>
    {
      this[ 'option_transaksi_ar_penjualan' ] = res[ 'data' ].map( ( val ) =>
      {
        return {
          value: val[ 'id' ],
          label: val[ 'text' ],
          id_kontak: val[ 'id_kontak' ],
          nama_customer: val[ 'nama_customer' ],
          alamat_customer: val[ 'alamat_customer' ],
          nama_sales: val[ 'nama_sales' ],
          tanggal_ar: val[ 'tanggal_ar' ],
          tersisa_ar: val[ 'tersisa_ar' ],
          total_ar: val[ 'total_ar' ],
        };
      } );
    } );
  }
  //** End: Load Data Options Select */
  //* ----------------------------------------------------------------------------- */
  //** Begin:: Sending Data */
  async sendingData ( values, setSubmitting )
  {
    const { alias_transaksi_ref, id_kontak, no_transaksi_ref, no_transaksi_dp, tersisa, total } = values;
    let dataSend = {};
    let data = {
      id_transaksi_jenis: parseInt( values[ 'id_transaksi_jenis' ] ),
      id_transaksi_ref: parseInt( values[ 'id_transaksi_ref' ] ),
      tanggal: moment( values[ 'tanggal' ] ).format( "YYYY-MM-DD" ),
      kontak_ref: values[ 'kontak_ref' ],
      deskripsi: values[ 'deskripsi' ],
      id_kolektor: values[ 'id_kolektor' ],
      id_user: parseInt( values[ 'id_user' ] ),
    };
    if ( alias_transaksi_ref === "penerimaan-dp-pesanan-penjualan" )
    {
      if ( parseInt( hapusSeparator( total ) ) > parseInt( tersisa ) )
      {
        dispatcher.snackbar.show( "Gagal", "Tersisa tidak boleh lebih besar dari jumlah!", "danger" );
        this.setState( { submitForm: false } );
        setSubmitting( false );
        return true;
      } else
      {
        dataSend = {
          ...data,
          no_transaksi_dp: no_transaksi_dp,
          no_transaksi_ref: no_transaksi_ref,
          id_kontak: id_kontak,
          total: parseInt( hapusSeparator( total ) )
        }
      }
    } else if ( alias_transaksi_ref === "penerimaan-ar-penjualan" )
    {
      if ( parseInt( hapusSeparator( total ) ) > parseInt( tersisa ) )
      {
        dispatcher.snackbar.show( "Gagal", "Tersisa tidak boleh lebih besar dari jumlah!", "danger" );
        this.setState( { submitForm: false } );
        setSubmitting( false );
        return true;
      } else
      {
        dataSend = {
          ...data,
          no_transaksi_ref: no_transaksi_ref,
          id_kontak: id_kontak,
          total: parseInt( hapusSeparator( total ) )
        }
      }
    } else if ( alias_transaksi_ref === "penerimaan-lain-lain" )
    {
      let data_details = this[ 'initialData' ][ 'detail' ];
      if ( data_details.length > 0 )
      {
        dataSend = {
          ...data,
          total: this[ 'state' ][ 'total_detail' ],
          data_detail: data_details,
        }
      } else
      {
        dispatcher[ 'snackbar' ].show( "Gagal", "Data detail tidak boleh kosong!", "danger" );
        this.setState( { submitForm: false } );
        setSubmitting( false );
        return true;
      }
    } else
    {
      dataSend = {
        ...data,
        total: parseInt( hapusSeparator( total ) )
      }
    }
    await this[ 'apiPenerimaanKas' ].setPenerimaanKas( dataSend ).then( ( res ) =>
    {
      this.setState( res[ 'data' ] ); // set state No Transaksi
      if ( res[ 'status' ] )
      {
        dispatcher[ 'snackbar' ].show( "Berhasil", res.message, "primary" );
        this.setState( { submitForm: true } );
        setSubmitting( true );
        this.LoadDetail();
      } else
      {
        dispatcher[ 'snackbar' ].show( "Gagal", res.message, "danger" );
        this.setState( { submitForm: false } );
        setSubmitting( false );
      }
    } );
  }
  //** End: Sending Data */
  //* ----------------------------------------------------------------------------- */
  //** Begin:: Load Detail Table */
  async LoadDetail ()
  {
    let data = this[ 'initialData' ][ 'detail' ];
    this.setState( {
      total_detail: data.reduce( ( total, value ) =>
      {
        const jumlah = typeof value[ 'jumlah' ] === "string" ? parseInt( hapusSeparator( value[ 'jumlah' ] ) ) : value[ 'jumlah' ];
        if ( value[ 'ceklist' ] )
        {
          return ( total - jumlah );
        } else
        {
          return ( total + jumlah );
        }
      }, 0 ),
    } );
    this[ 'DataTableModel' ] = new DataTableModel( { readOnly: this[ 'state' ][ 'submitForm' ] } );
    data.map( ( item, i ) =>
    {
      if ( item[ 'status' ] === 1 )
      {
        this[ 'DataTableModel' ].add( {
          id: i,
          no: i + 1,
          dataModel: item,
          actions:
            ( !this[ 'state' ][ 'submitForm' ] ) ? (
              <>
                <ButtonActions
                  label={ "edit" }
                  onClick={ async () =>
                    await this[ 'handleModal' ].edit( item )
                  }
                />
                <ButtonActions
                  label={ "delete" }
                  onClick={ async () =>
                    this[ 'handleAlert' ].show( item )
                  }
                />
              </>
            ) : ( null ),
        } );
      }
      return item;
    } );
    this[ 'columntable' ] = await this[ 'DataTableModel' ].getColumn();
    this[ 'datatable' ] = await this[ 'DataTableModel' ].getDatas();
    this.setState( { ...this[ 'state' ] } );
  }
  //** End: Load Detail Table */
  //* ----------------------------------------------------------------------------- */
  //** Begin:: Handle Modal/Alert */
  handleModal = {
    loadOptionAkunDetails: async () =>
    {
      this[ 'apiPenerimaanKas' ].getAkunPenerimaan().then( ( res ) =>
      {
        // Load Data option Akun
        this[ 'option_akun' ] = res[ 'data' ].map( ( val ) =>
        {
          return {
            value: val[ 'id' ],
            label: val[ 'text' ],
            kode_akun: val[ 'kode_akun' ],
            nama_akun: val[ 'nama_akun' ],
          };
        } );
      } );
    },
    tambah: async () =>
    {
      await this[ 'handleModal' ].loadOptionAkunDetails();
      this.setState( {
        modal: {
          show: true,
          title: "Tambah Dana",
          isEdit: false,
          initialDetailData: new penerimaanKasDetailModel()
        }
      } );
    },
    edit: async ( dataFromModel ) =>
    {
      await this[ 'handleModal' ].loadOptionAkunDetails();
      this.setState( {
        modal: {
          show: true,
          title: "Edit Dana",
          isEdit: true,
          initialDetailData: new penerimaanKasDetailModel( {
            ...dataFromModel,
          } )
        }
      } );
    },
    hide: () =>
    {
      this.setState( {
        modal: {
          show: false,
          title: "",
          isEdit: false,
          initialDetailData: new penerimaanKasDetailModel()
        }
      } );
    },
  };
  handleAlert = {
    // Show Alert
    show: ( dataModel ) =>
    {
      this.setState( {
        alert: {
          activeId: dataModel,
          show: true
        }
      } );
    },
    // Hide Alert
    hide: () =>
    {
      this.setState( {
        alert: {
          activeId: null,
          show: false
        }
      } );
    },
    // Confirmed Alert
    confirmed: async () =>
    {
      await this[ 'initialData' ][ 'handleDetail' ].del( this[ 'state' ][ 'alert' ][ 'activeId' ] );
      this[ 'handleAlert' ].hide();
      await this.LoadDetail();
    },
  };
  //** End: Handle Modal/Alert */
  //* ----------------------------------------------------------------------------- */
  render ()
  {
    const { no_transaksi, modal, alert, submitForm, total_detail } = this[ 'state' ];
    return (
      <>
        {/* Begin:: Formik Form */ }
        <Formik
          enableReinitialize={ true }
          initialValues={ this[ 'initialData' ][ 'transaksi' ] }
          validationSchema={ Yup.object().shape( {
            id_transaksi_ref: Yup.string().required( "Transaksi Ref harus diisi" ),
            tanggal: Yup.string().nullable().required(),
            kontak_ref: Yup.string().required(),
            deskripsi: Yup.string()
              .max( 150, "Maximum 150 String" )
              .required( "Keterangan harus diisi" ),
            no_transaksi_ref: Yup.string().when( "alias_transaksi_ref", ( alias_transaksi_ref ) =>
            {
              if ( alias_transaksi_ref === "penerimaan-dp-pesanan-penjualan" || alias_transaksi_ref === "penerimaan-ar-penjualan" )
              {
                return Yup.string().required( "Nomor Transaksi harus diisi" );
              }
            } ),
            total: Yup.string().when( "alias_transaksi_ref", ( alias_transaksi_ref ) =>
            {
              if ( alias_transaksi_ref !== "penerimaan-lain-lain" )
              {
                return Yup.string().required();
              }
            } ),
          } ) }
          onSubmit={ ( values, { setSubmitting } ) =>
          {
            this.sendingData( values, setSubmitting );
          } }
        >
          { ( { handleSubmit, isSubmitting, touched, errors, setFieldValue, setFieldTouched, values } ) => (
            <AnimatedCard>
              <CardHeader title={ this[ 'pageName' ] }>
                <CardHeaderToolbar>
                  { submitForm ? (
                    <>
                      {/* Button Cetak Tanda Terima */ }
                      <button
                        type={ "button" }
                        className={ "btn btn-outline-success mx-1 btn-sm" }
                        onClick={ async () =>
                        {
                          dispatcher[ 'kasir_kas' ].cetakTandaTerima( no_transaksi );
                        } }
                      ><i className={ "fa fa-envelope-open-text" }></i> Cetak Tanda Terima
                      </button>
                      {/* Button Cetak Bukti Penerimaan */ }
                      <button
                        type={ "button" }
                        className={ "btn btn-outline-success mx-1 btn-sm" }
                        onClick={ async () =>
                        {
                          dispatcher[ 'kasir_kas' ].cetakBuktiPenerimaan( no_transaksi );
                        } }
                      ><i className={ "fa fa-envelope-open-text" }></i> Bukti Penerimaan
                      </button>
                      {/* Button Transaksi Baru */ }
                      <button
                        type={ "button" }
                        className={ "btn btn-outline-primary mx-1 btn-sm" }
                        onClick={ async () =>
                        {
                          window[ 'location' ].reload();
                        } }
                      ><i className={ "fa fa-plus" }></i> Transaksi Baru
                      </button>
                    </>
                  ) : (
                    <>
                      {/* Button Simpan */ }
                      <ButtonLoad
                        label={ "Simpan" }
                        pending={ isSubmitting }
                        disabled={ isSubmitting }
                        classNameIcon={ "fas fa-save" }
                        className={ "btn btn-primary mx-1" }
                        onClick={ async () => handleSubmit() }
                      />
                    </>
                  ) }
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <Form className={ "form form-label-right" }>
                  <div className={ "form-group row" }>
                    <div className={ "col-lg-6" }>
                      <div className={ "form-group row" }>
                        {/* Jenis Penerimaan */ }
                        <FormsFields
                          id={ "id_transaksi_jenis" }
                          type={ "select2" }
                          label={ "Jenis Penerimaan" }
                          className={ "col-lg-12" }
                          isDisabled={ true }
                          options={ this[ 'option_jenis_penerimaan' ] }
                          paramsFormik={ {
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          } }
                        />
                        {/* Transaksi Ref */ }
                        <FormsFields
                          id={ "id_transaksi_ref" }
                          type={ "select2" }
                          label={ "Transaksi Ref" }
                          style={ { marginTop: "20px" } }
                          className={ "col-lg-12" }
                          isClearable={ true }
                          isSearchable={ true }
                          isDisabled={ submitForm }
                          options={ this[ 'option_transaksi_ref' ] }
                          onChange={ ( e ) =>
                          {
                            setFieldValue( "alias_transaksi_ref", e ? e[ 'alias' ] : "" );
                            this[ 'initialData' ][ 'handleDetail' ].reset();
                            if ( e )
                            {
                              if ( e[ 'alias' ] === "penerimaan-dp-pesanan-penjualan" )
                              {
                                this.loadDataDPPesananPenjualan( e ? e[ 'value' ] : "" );
                                // Clear Form 
                                setFieldValue( "no_transaksi_ref", "" );
                                setFieldValue( "nama_customer", "" );
                                setFieldValue( "alamat_customer", "" );
                                setFieldValue( "nama_sales", "" );
                                setFieldValue( "tanggal_dp", "" );
                                setFieldValue( "tersisa", "" );
                                setFieldValue( "total_dp", "" );
                                setFieldValue( "total", "" );
                                setFieldValue( "no_transaksi_dp", "" );
                              } else if ( e[ 'alias' ] === "penerimaan-ar-penjualan" )
                              {
                                this.LoadDataARPemjualan( e ? e[ 'value' ] : "" );
                                // Clear Form 
                                setFieldValue( "no_transaksi_ref", "" );
                                setFieldValue( "nama_customer", "" );
                                setFieldValue( "alamat_customer", "" );
                                setFieldValue( "nama_sales", "" );
                                setFieldValue( "tanggal_ar", "" );
                                setFieldValue( "tersisa", "" );
                                setFieldValue( "totaal_ar", "" );
                                setFieldValue( "total", "" );
                              } else if ( e[ 'alias' ] === "penerimaan-lain-lain" )
                              {
                                // Clear Table Details
                                this.LoadDetail();
                                setFieldValue( "total", 0 );
                              } else
                              {
                                setFieldValue( "total", "" );
                              }
                            } else
                            {
                              this.LoadDetail();
                              setFieldValue( "total", "" );
                            }
                          } }
                          paramsFormik={ {
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          } }
                        />
                        {/* Begin:: Show/Hide No Transaksi DP/AR Penjualan */ }
                        { ( values[ 'alias_transaksi_ref' ] === "penerimaan-dp-pesanan-penjualan" ) ? (
                          <>
                            {/* No. Transaksi DP */ }
                            <FormsFields
                              id={ "no_transaksi_ref" }
                              type={ "select2" }
                              label={ "No. Transaksi" }
                              style={ { marginTop: "20px" } }
                              className={ "col-lg-12" }
                              isClearable={ true }
                              isSearchable={ true }
                              isDisabled={ submitForm }
                              options={ this[ 'option_transaksi_dp_penjualan' ] }
                              onChange={ ( e ) =>
                              {
                                setFieldValue( "id_kontak", e ? e[ 'id_kontak' ] : "" );
                                setFieldValue( "nama_customer", e ? e[ 'nama_customer' ] : "" );
                                setFieldValue( "alamat_customer", e ? e[ 'alamat_customer' ] : "" );
                                setFieldValue( "nama_sales", e ? e[ 'nama_sales' ] : "" );
                                setFieldValue( "tanggal_dp", e ? e[ 'tanggal_dp' ] : "" );
                                setFieldValue( "tersisa", e ? e[ 'tersisa_dp' ] : "" );
                                setFieldValue( "total_dp", e ? e[ 'total_dp' ] : "" );
                                setFieldValue( "total", "" );
                                setFieldValue( "no_transaksi_dp", e ? e[ 'no_transaksi_dp' ] : "" );
                              } }
                              paramsFormik={ {
                                setFieldValue,
                                setFieldTouched,
                                touched,
                                errors,
                                values
                              } }
                            />
                            {/* Nama Customer */ }
                            <FormsFields
                              id={ "nama_customer" }
                              type={ "disabled-text" }
                              label={ "Nama Customer" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              value={ values[ 'nama_customer' ] }
                            />
                            {/* Alamat Customer */ }
                            <FormsFields
                              id={ "alamat_customer" }
                              type={ "disabled-textarea" }
                              label={ "Alamat Customer" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              rows={ 2 }
                              value={ values[ 'alamat_customer' ] }
                            />
                            {/* Pembayaran Tersisa */ }
                            <FormsFields
                              id={ "tersisa" }
                              type={ "disabled-currency" }
                              label={ "Tersisa" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              value={ values[ 'tersisa' ] }
                            />
                          </>
                        ) : ( values[ 'alias_transaksi_ref' ] === "penerimaan-ar-penjualan" ) ? (
                          <>
                            {/* No. Transaksi AR */ }
                            <FormsFields
                              id={ "no_transaksi_ref" }
                              type={ "select2" }
                              label={ "No. Transaksi" }
                              style={ { marginTop: "20px" } }
                              className={ "col-lg-12" }
                              isClearable={ true }
                              isSearchable={ true }
                              isDisabled={ submitForm }
                              options={ this[ 'option_transaksi_ar_penjualan' ] }
                              onChange={ ( e ) =>
                              {
                                setFieldValue( "id_kontak", e ? e[ 'id_kontak' ] : "" );
                                setFieldValue( "nama_customer", e ? e[ 'nama_customer' ] : "" );
                                setFieldValue( "alamat_customer", e ? e[ 'alamat_customer' ] : "" );
                                setFieldValue( "nama_sales", e ? e[ 'nama_sales' ] : "" );
                                setFieldValue( "tanggal_ar", e ? e[ 'tanggal_ar' ] : "" );
                                setFieldValue( "tersisa", e ? e[ 'tersisa_ar' ] : "" );
                                setFieldValue( "total_ar", e ? e[ 'total_ar' ] : "" );
                                setFieldValue( "total", "" );
                              } }
                              paramsFormik={ {
                                setFieldValue,
                                setFieldTouched,
                                touched,
                                errors,
                                values
                              } }
                            />
                            {/* Nama Customer */ }
                            <FormsFields
                              id={ "nama_customer" }
                              type={ "disabled-text" }
                              label={ "Nama Customer" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              value={ values[ 'nama_customer' ] }
                            />
                            {/* Alamat Customer */ }
                            <FormsFields
                              id={ "alamat_customer" }
                              type={ "disabled-textarea" }
                              label={ "Alamat Customer" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              rows={ 2 }
                              value={ values[ 'alamat_customer' ] }
                            />
                            {/* Pembayaran Tersisa */ }
                            <FormsFields
                              id={ "tersisa" }
                              type={ "disabled-currency" }
                              label={ "Tersisa" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              value={ values[ 'tersisa' ] }
                            />
                          </>
                        ) : null }
                        {/* End : Option Select DP/AR Penjualan */ }
                        {/* --------------------------------------------------------------------------------------------- */ }
                      </div>
                    </div>
                    <div className={ "col-lg-6" }>
                      <div className={ "form-group row" }>
                        {/* Tanggal Transaksi */ }
                        <FormsFields
                          id={ "tanggal" }
                          type={ "DatePicker" }
                          label={ "Tanggal Transaksi" }
                          className={ "col-lg-12" }
                          disabled={ isSubmitting }
                          paramsFormik={ {
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          } }
                        />
                        {/* Diterima Dari */ }
                        <FormsFields
                          id={ "kontak_ref" }
                          type={ "text" }
                          label={ "Diterima Dari" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          disabled={ isSubmitting }
                          paramsFormik={ {
                            setFieldValue,
                            values
                          } }
                        />
                        {/* Keterangan */ }
                        <FormsFields
                          id={ "deskripsi" }
                          type={ "textarea" }
                          label={ "Keterangan" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          disabled={ isSubmitting }
                          rows={ 3 }
                          paramsFormik={ {
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          } }
                        />
                        {/* Kolektor */ }
                        <FormsFields
                          id={ "id_kolektor" }
                          type={ "select2" }
                          label={ "Nama Kolektor" }
                          className={ "col-lg-12" }
                          isClearable={ false }
                          isSearchable={ true }
                          options={ this[ 'option_kolektor' ] }
                          style={ { marginTop: "20px" } }
                          paramsFormik={ {
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          } }
                        />
                        {/* Show/Hide Form */ }
                        { ( values[ 'alias_transaksi_ref' ] === "penerimaan-dp-pesanan-penjualan" ) ? (
                          <>
                            {/* Tanggal Pesanan Penjualan */ }
                            <FormsFields
                              id={ "tanggal_dp" }
                              type={ "disabled-DatePicker" }
                              label={ "Tanggal Pesanan Penjualan" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              value={ values[ 'tanggal_dp' ] }
                            />
                            {/* Nama Sales */ }
                            <FormsFields
                              id={ "nama_sales" }
                              type={ "disabled-text" }
                              label={ "Nama Sales" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              value={ values[ 'nama_sales' ] }
                            />
                            {/* Pembayaran Total */ }
                            <FormsFields
                              id={ "total_dp" }
                              type={ "disabled-currency" }
                              label={ "Total" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              value={ values[ 'total_dp' ] }
                            />
                            {/* Jumlah */ }
                            <FormsFields
                              id={ "total" }
                              type={ "currency" }
                              label={ "Jumlah" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              disabled={ isSubmitting }
                              onChange={ ( e ) =>
                              {
                                const { alias_transaksi_ref, no_transaksi_ref, tersisa } = values;
                                if ( alias_transaksi_ref === "penerimaan-dp-pesanan-penjualan" && no_transaksi_ref === "" )
                                {
                                  dispatcher[ 'snackbar' ].show( "Peringatan",
                                    "No. Transaksi Ref tidak boleh kosong...!",
                                    "warning" );
                                  return false;
                                } else
                                {
                                  if ( parseInt( hapusSeparator( e[ 'target' ][ 'value' ] ) ) > parseInt( tersisa ) )
                                  {
                                    dispatcher[ 'snackbar' ].show( "Peringatan",
                                      "Jumlah terbayar tidak boleh lebih dari biaya tersisa!",
                                      "warning"
                                    );
                                    return false;
                                  }
                                }
                              } }
                              paramsFormik={ {
                                setFieldValue,
                                values
                              } }
                            />
                          </>
                        ) : ( values[ 'alias_transaksi_ref' ] === "penerimaan-ar-penjualan" ) ? (
                          <>
                            {/* Tanggal Penjualan AR */ }
                            <FormsFields
                              id={ "tanggal_ar" }
                              type={ "disabled-DatePicker" }
                              label={ "Tanggal Invoice" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              value={ values[ 'tanggal_ar' ] }
                            />
                            {/* Nama Sales */ }
                            <FormsFields
                              id={ "nama_sales" }
                              type={ "disabled-text" }
                              label={ "Nama Sales" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              value={ values[ 'nama_sales' ] }
                            />
                            {/* Pembayaran Total */ }
                            <FormsFields
                              id={ "total_ar" }
                              type={ "disabled-currency" }
                              label={ "Total" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              value={ values[ 'total_ar' ] }
                            />
                            {/* Jumlah */ }
                            <FormsFields
                              id={ "total" }
                              type={ "currency" }
                              label={ "Jumlah" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              disabled={ isSubmitting }
                              onChange={ ( e ) =>
                              {
                                const { alias_transaksi_ref, no_transaksi_ref, tersisa } = values;
                                if ( alias_transaksi_ref === "penerimaan-ar-penjualan" && no_transaksi_ref === "" )
                                {
                                  dispatcher[ 'snackbar' ].show( "Peringatan",
                                    "No. Transaksi Ref tidak boleh kosong...!",
                                    "warning" );
                                  return false;
                                } else
                                {
                                  if ( parseInt( hapusSeparator( e[ 'target' ][ 'value' ] ) ) > parseInt( tersisa ) )
                                  {
                                    dispatcher[ 'snackbar' ].show( "Peringatan",
                                      "Jumlah terbayar tidak boleh lebih dari biaya tersisa!",
                                      "warning"
                                    );
                                    return false;
                                  }
                                }
                              } }
                              paramsFormik={ {
                                setFieldValue,
                                values
                              } }
                            />
                          </>
                        ) : ( values[ 'alias_transaksi_ref' ] === "penerimaan-lain-lain" ) ? (
                          <>
                            {/* Total */ }
                            <FormsFields
                              id={ "total" }
                              type={ "disabled-currency" }
                              label={ "Total" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              value={ total_detail }
                            />
                          </>
                        ) : (
                          <>
                            {/* Default Jumlah */ }
                            <FormsFields
                              id={ "total" }
                              type={ "currency" }
                              label={ "Jumlah" }
                              className={ "col-lg-12" }
                              style={ { marginTop: "20px" } }
                              disabled={ isSubmitting }
                              paramsFormik={ {
                                setFieldValue,
                                values
                              } }
                            />
                          </>
                        ) }
                      </div>
                    </div>
                  </div>
                </Form>
              </CardBody>
              { ( values[ 'alias_transaksi_ref' ] === "penerimaan-lain-lain" ) ? (
                <>
                  <CardFooter>
                    {/* Begin:: Detail Dana */ }
                    <span className={ "d-flex align-items-center justify-content-between mt-4 mb-4" }>
                      <Typography variant={ "h6" }>Detail</Typography>
                      { ( !submitForm ) ? (
                        <>
                          <button
                            type={ "button" }
                            className={ "btn btn-outline-primary btn-sm" }
                            onClick={ async () =>
                            {
                              await this[ 'handleModal' ].tambah();
                            } }
                          ><i className={ "fa fa-plus" }></i> { "Tambah Dana" }
                          </button>
                        </>
                      ) : "" }
                    </span>
                    <ReactDatatableDetails
                      title={ "" }
                      data={ this[ 'datatable' ] }
                      columns={ this[ 'columntable' ] }
                    />
                    {/* End: Detail Dana */ }
                  </CardFooter>
                </>
              ) : "" }
            </AnimatedCard>
          ) }
        </Formik>
        {/* End: Formik Form */ }
        {/* ----------------------------------------------------------------------------- */ }
        {/* Begin:: Modal Detail Form */ }
        <Formik
          enableReinitialize={ true }
          initialValues={ modal[ 'initialDetailData' ] }
          validationSchema={ Yup.object().shape( {
            id_akun: Yup.string()
              .ensure()
              .required( "Akun harus diisi" ),
            deskripsi: Yup.string()
              .max( 150, "Maximum 150 String" )
              .required( "Keterangan harus diisi" ),
            jumlah: Yup.string().required(),
          } ) }
          onSubmit={ async ( values, { resetForm } ) =>
          {
            if ( modal[ 'isEdit' ] )
            {
              await this[ 'initialData' ][ 'handleDetail' ].edit( values );
            } else
            {
              await this[ 'initialData' ][ 'handleDetail' ].set( values );
            }
            await this.LoadDetail();
            resetForm();
            this[ 'handleModal' ].hide();
          } }
        >
          { ( { handleSubmit, setFieldValue, setFieldTouched, touched, errors, values } ) => (
            <ModalForms
              show={ modal[ 'show' ] }
              title={ modal[ 'title' ] }
              onHide={ this[ 'handleModal' ][ 'hide' ] }
              onConfirm={ handleSubmit }
              content={
                <div className={ "form-group row" }>
                  {/* Akun */ }
                  <FormsFields
                    id={ "id_akun" }
                    type={ "select2" }
                    label={ "Akun" }
                    className={ "col-lg-12" }
                    isClearable={ false }
                    isSearchable={ true }
                    options={ this[ 'option_akun' ] }
                    onChange={ ( e ) =>
                    {
                      setFieldValue( "kode_akun", e ? e[ 'kode_akun' ] : "" );
                      setFieldValue( "nama_akun", e ? e[ 'nama_akun' ] : "" );
                    } }
                    paramsFormik={ {
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    } }
                  />
                  {/* Keterangan */ }
                  <FormsFields
                    id={ "deskripsi" }
                    type={ "textarea" }
                    label={ "Keterangan" }
                    className={ "col-lg-12" }
                    style={ { marginTop: "20px" } }
                    rows={ 2 }
                    paramsFormik={ {
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    } }
                  />
                  {/* Jumlah */ }
                  <FormsFields
                    id={ "jumlah" }
                    type={ "currency" }
                    label={ "Jumlah" }
                    className={ "col-lg-12" }
                    style={ { marginTop: "20px" } }
                    value={ values[ 'jumlah' ] }
                    paramsFormik={ {
                      setFieldValue
                    } }
                  />
                  {/* Negatif */ }
                  <FormsFields
                    id={ "ceklist" }
                    type={ "checkbox" }
                    label={ "Negatif" }
                    indeterminate={ true }
                    className={ "col-lg-12" }
                    style={ { marginTop: "5px" } }
                    paramsFormik={ {
                      setFieldValue,
                      values
                    } }
                  />
                </div>
              }
            />
          ) }
        </Formik>
        {/* End: Modal Detail Form */ }
        {/* ----------------------------------------------------------------------------- */ }
        {/* Begin:: Alert */ }
        <SimpleAlert
          title={ "Hapus Data" }
          content={ "Anda yakin hapus data ini...?" }
          show={ alert[ 'show' ] }
          onHide={ () => this[ 'handleAlert' ].hide() }
          onConfirmed={ this[ 'handleAlert' ][ 'confirmed' ] }
        />
        {/* End: Alert */ }
      </>
    );
  }
}

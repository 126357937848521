import { separatorHarga } from "../../../services/separator-harga";

export default class jurnalUmumDetailModel {
  constructor(
    data = {
      id: 0,
      id_akun: "",
      posisi: "",
      kode_akun: "",
      nama_akun: "",
      cabang: "",
      id_bank: "",
      bank: "",
      deskripsi: "",
      jumlah: "",
      tax: "",
      id_perusahaan: "",
      status: 1
    }
  ) {
    const { id, posisi, id_akun, kode_akun, nama_akun, cabang, id_bank, bank, deskripsi, jumlah, tax, id_perusahaan, status } = data;
    this['id'] = id;
    this['posisi'] = posisi;
    this['id_akun'] = id_akun;
    this['kode_akun'] = kode_akun;
    this['nama_akun'] = nama_akun;
    this['cabang'] = cabang;
    this['id_bank'] = id_bank;
    this['bank'] = bank;
    this['deskripsi'] = deskripsi;
    this['jumlah'] = jumlah;
    this['tax'] = tax;
    this['id_perusahaan'] = id_perusahaan;
    this['status'] = status;
  }
  async getdata() {
    const { posisi, kode_akun, nama_akun, cabang, bank, deskripsi, jumlah, tax } = this;
    let saldo_debet = 0;
    let saldo_kredit = 0;
    if (posisi === "debet" || posisi === "D") {
      saldo_debet = jumlah;
    } else {
      saldo_kredit = jumlah;
    }
    return [
      {
        id: "kode_akun",
        title: "Kode Akun",
        label: kode_akun,
        center: true,
        minWidth: "10%",
      }, {
        id: "nama_akun",
        title: "Nama Akun",
        label: nama_akun,
        center: true,
        minWidth: "20%",
      }, {
        id: "id_perusahaan",
        title: "Cabang",
        label: cabang,
        center: true,
        sortable: true,
        minWidth: "20%",
      }, {
        id: "bank",
        title: "Bank",
        label: bank,
        center: true,
        sortable: true,
        minWidth: "25%",
      }, {
        id: "deskripsi",
        title: "Keterangan",
        label: deskripsi,
        sortable: true,
        minWidth: "30%",
      }, {
        id: "debet",
        title: "Debet",
        label: separatorHarga(saldo_debet.toString(), "Rp. "),
        right: true,
        minWidth: "15%",
      }, {
        id: "kredit",
        title: "Kredit",
        label: separatorHarga(saldo_kredit.toString(), "Rp. "),
        right: true,
        minWidth: "15%",
      }, {
        id: "tax",
        title: "Tax",
        label: separatorHarga(tax.toString(), "Rp. "),
        right: true,
        minWidth: "15%",
      }];
  }
}

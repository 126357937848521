import store from "../../redux/store";
import { separatorHarga } from "../../services/separator-harga";
import moment from "moment";

export default class stokAwalModel {
  constructor(
    data = {
      id: 0,
      id_produk: "",
      id_gudang: "",
      id_perusahaan: store.getState().auth['id_perusahaan'],
      id_user: store.getState().auth['id'],
      nama_produk: "",
      produk_satuan: "",
      nama_gudang: "",
      ammount: 0,
      qty: "",
      nilai_persediaan: "",
      hpp: 0,
      tanggal: "",
    },
  ) {
    const { id, id_produk, id_gudang, id_perusahaan, id_user, nama_produk, produk_satuan, nama_gudang, qty, nilai_persediaan, ammount, hpp, tanggal } = data;
    this['id'] = id;
    this['id_produk'] = id_produk;
    this['id_gudang'] = id_gudang;
    this['id_perusahaan'] = id_perusahaan;
    this['id_user'] = id_user;
    this['nama_produk'] = nama_produk;
    this['produk_satuan'] = produk_satuan;
    this['nama_gudang'] = nama_gudang;
    this['qty'] = qty;
    this['nilai_persediaan'] = nilai_persediaan;
    this['ammount'] = ammount;
    this['hpp'] = hpp;
    this['tanggal'] = tanggal;
  }

  async getdata() {
    const { tanggal, nama_produk, produk_satuan, nama_gudang, qty, hpp, nilai_persediaan } = this;
    return [{
      id: "tanggal",
      title: "Tanggal Input",
      label: moment(tanggal).format("DD-MM-YYYY"),
      center: true,
      sortable: true,
      minWidth: "15%",
    }, {
      id: "nama_produk",
      title: "Nama Barang",
      label: nama_produk,
      sortable: true,
      minWidth: "30%",
    }, {
      id: "produk_satuan",
      title: "Produk Satuan",
      label: produk_satuan,
      center: true,
      sortable: true,
      minWidth: "15%",
    }, {
      id: "nama_gudang",
      title: "Nama Gudang",
      label: nama_gudang,
      sortable: true,
      minWidth: "25%",
    }, {
      id: "qty",
      title: "Jumlah Stok",
      label: qty,
      center: true,
      sortable: true,
      minWidth: "12%",
    }, {
      id: "hpp",
      title: "Nilai Hpp",
      label: separatorHarga(hpp.toString(), "Rp. "),
      center: true,
      sortable: true,
      minWidth: "13%",
    }, {
      id: "nilai_persediaan",
      title: "Nilai Persediaan",
      label: separatorHarga(nilai_persediaan.toString(), "Rp. "),
      center: true,
      sortable: true,
      minWidth: "15%",
    }];
  }
}

import React, { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Form from "./form";
import List from "./list";
import Detail from "./detail"
import StokRequestModel from "../../../models/stok-req-model/StokRequestModel";
import { dispatcher } from "../../../redux/dispatcher";

export default function StokTransferReq() {
  const [dataFromModel, setDataFromModel] = useState(new StokRequestModel());
  const history = useHistory();

  return (
    <Switch>
      <Route path="/transfer-stok/new">
        <Form history={history} dataFromModel={new StokRequestModel()} isEdit={false}/>
      </Route>
      <Route path="/transfer-stok/edit">
        <Form history={history} dataFromModel={dataFromModel} isEdit={true}/>
      </Route>
      <Route path="/transfer-stok/list">
        <List setDataFromModel={setDataFromModel} />
      </Route>
      <Route path="/transfer-stok/detail">
        <Detail history={history} dataFromModel={dataFromModel} />
      </Route>
      <Redirect from="/transfer-stok/" to="/transfer-stok/list" />
    </Switch>
  )
  }
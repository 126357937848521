import React, { useEffect, useMemo, useState } from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Fields } from "../../../components/fields/Fields";
import {Typography} from "@material-ui/core";
import { dispatcher } from "../../../redux/dispatcher";
import { InboundDetail } from "./inbound_detail";
import { Alert } from "react-bootstrap";
import { PenerimaanAPI } from "../../../api/penerimaan";
import PenerimaanPembelianModel from "../../../models/penerimaan-pembelian-model/PenerimaanPembelianModel";
// import { ButtonValidasi } from "./button_validasi";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import { ProdukAPI } from "../../../api/produk";
import { Modal, Button } from 'react-bootstrap';
import UbahHarga from "./changePrice";
import _ from "lodash";

export default function Inbound({ history, detail }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [initialData, setInitialData] = useState(dispatcher.penerimaan.get());
  const [fields, setFields] = useState([]);
  const [yup, setYup] = useState(Yup.object().shape({}));
  const [showInfo, setShowInfo] = useState(true);
  const invoice = initialData.master.kode === "" ? false : true;
  const [idProduk, setIdProduk] = useState([])
  const [filter, setFilter] = useState([])

  const loadFields = async () => {
    let fieldsLoaded = [];
    let yup = {};
    await initialData
      .getEditable({ pageName: "", isForm: true })
      .then((values) => {
        values.map((item, i) => {
          fieldsLoaded = [...fieldsLoaded, item];
          yup[item.dataField] = item.validation;
        });
      });
    setFields(fieldsLoaded);
    setYup(Yup.object().shape(yup));
  };

  const saveTransaksi = async (values) => {
    let send = {
      id: initialData.master.id,
      kode: values.kode,
      tanggal: initialData.master.tanggal,
      status_invoice: 2,
    };
    await new PenerimaanAPI().edit(send).then((val) => {
      if (val.status)
        dispatcher.snackbar.show(
          "Berhasil",
          "No invoice berhasil disimpan",
          "primary"
        );
      else
        dispatcher.snackbar.show(
          "Gagal",
          "No invoice gagal disimpan",
          "danger"
        );
    });
  };

  const getList = useMemo(() => {
    return dispatcher.penerimaan.get();
  }, [dispatcher.penerimaan.get()]);

  useEffect(() => {
    setInitialData(getList);
    loadFields();
    initialData.detail.map((data) => {
      return idProduk.push(data.id_produk)
    })
    console.log('iniDetail', initialData.detail);
    const fetch = async () => {
      await new ProdukAPI().getAll().then((res) => {
        let temp = res.data.filter((val) => idProduk.includes(val.id))
        let merged = _.merge(_.keyBy(initialData.detail, 'id_produk'), _.keyBy(temp, 'id'))
        let values = _.values(merged);
        let tempFil = values.filter((val) => val.dpp !== val.harga_beli);
        return [setFilter(tempFil)]
      })
    }

    fetch()

    initialData.removeSeparator()
    initialData.removeAllDetailSeparator()

  }, [getList]);

  return (
    <>
      {/* {produk.filter((val) => setFilProduk(idProduk.includes(val.id)))} */}
      {showInfo && (
        <Alert variant="primary" onClose={() => setShowInfo(false)} dismissible>
          <i className="fa fa-info-circle text-white ml-2 mr-4"></i>
          <span>
            Lengkapi nomor transaksi dan tanggal penerimaan pembelian terlebih
            dahulu sebelum melakukan Inbound stock.
          </span>
        </Alert>
      )}

      <Formik
        enableReinitialize={true}
        initialValues={initialData.master}
        validationSchema={yup}
        onSubmit={async (values, { resetForm }) => {
          if (values.kode !== null || values.kode !== "") {
            await saveTransaksi(values);
            // await new PenerimaanAPI()
            // .getSingle(initialData.master.id)
            // .then((response) => {
            //   dispatcher.penerimaan.set(
            //       new PenerimaanPembelianModel(response.data)
            //     );
            //   });
            history.goBack()
          }
          console.log('cekValuesOnSubmit', values);
        }}
      >
        {({ handleSubmit, setFieldValue, validateForm, values }) => (
          <AnimatedCard>
            <CardHeader title="Inbound">
              <CardHeaderToolbar>
                <button
                  type="button"
                  className="btn btn-secondary mx-1"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-arrow-left"></i>
                  Kembali
                </button>
                {/* nonaktifkan */}
                {/* <ButtonValidasi dataModel={initialData} /> */}
                {invoice === false && (
                  <button
                    type="button"
                    className="btn btn-primary mx-1"
                    onClick={() => {
                      // fil();
                      console.log('filterr', filter);
                      filter.length > 0 ?
                        handleShow() : 
                        handleSubmit()    
                    }}
                  >
                    <i className="fa fa-save"></i>
                    Simpan No. Transaksi
                  </button>
                )}
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              <Form className="form form-label-right">
                <div className="row">
                  {fields.length > 0 &&
                    fields.map((field, i) => (
                      // console.log(field)
                      ['nama_supplier', 'tanggal', 'jatuh_tempo', 'kode', 'no_transaksi'].includes(field.dataField)
                        ?
                        <div key={i} className="form-group col-lg-6">
                          <Fields
                            {...field}
                            setFieldValue={setFieldValue}
                          />
                        </div> : ""
                    ))}
                </div>
                <span className="d-flex align-items-center justify-content-between mt-4">
                  <Typography variant="h6">Detail Item</Typography>
                </span>
                <InboundDetail
                  data={initialData}
                  validateForm={validateForm}
                  handleSubmit={handleSubmit}
                />
                <div className="d-flex row" style={{ marginLeft: "40%", marginTop: "20px" }}>
                  {
                    fields.map((field, i) => (
                      !['nama_supplier', 'tanggal', 'jatuh_tempo', 'kode', 'no_transaksi'].includes(field.dataField)
                        ?
                        <div key={i} className="form-group col-md-4 justify-content-around">
                          <Fields
                            {...field}
                            // type="disabled-text"
                            setFieldValue={setFieldValue}
                          />
                        </div> : ""
                    ))
                  }
                </div>
              </Form>
            </CardBody>
            <Modal size="lg" show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Ubah Harga Master</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* {console.warn('inModal', detailC,filProduk)} */}
                {/* <UbahHarga detail={detailC} produk={filProduk}/> */}
                <UbahHarga data={filter} />
                {/* {console.log('cek detailBody',detail)} */}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" 
                onClick={() => {
                  handleSubmit()
                  handleClose()
                }}
                >
                  Simpan
                </Button>
              </Modal.Footer>
            </Modal>
          </AnimatedCard>
        )}
      </Formik>
    </>
  );
}

const initialValues = {
  active: null,
  list: [],
};

export const panelNotificationAction = {
  list: "LIST_PANEL_NOTIFICATION",
  active: "ACTIVE_PANEL_NOTIFICATION",
  resetActive: "RESET_ACTIVE_PANEL_NOTIFICATION",
};

const reducer = (state = initialValues, action) => {
  let states;
  switch (action.type) {
    case panelNotificationAction.list:
      states = state;
      states.list = action.payload;
      return (state = states);
    case panelNotificationAction.active:
      states = state;
      states.active = action.payload;
      return (state = states);
    case panelNotificationAction.resetActive:
      states = state;
      states.active = null;
      return (state = states);
    default:
      return state;
  }
};

export default reducer;

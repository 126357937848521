import { getIdPerusahaan, simpleFetch } from "./api";

export class SupplierAPI {
  async getAll() {
    let res = await simpleFetch({
      url: "retail/kontak/supplier?id_perusahaan=" + getIdPerusahaan(),
      method: "GET",
    });
    return res;
  }

  async getFull(id) {
    let res = await simpleFetch({
      url: "retail/kontak/supplier?id_perusahaan=" + id,
      method: "GET",
    });
    return res;
  }
  async getSemua() {
    let res = await simpleFetch({
      url: "retail/kontak/allsupplier",
      method: "GET",
    });
    return res;
  }

  async getSingle(id) {
    let res = await simpleFetch({
      url: "retail/kontak/supplier?id=" + id,
      method: "GET",
    });
    return res;
  }

  async set(values) {
    values.removeSeparator();
    let res = await simpleFetch({
      url: "retail/kontak/supplier",
      method: "POST",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values,
      },
    });
    return res;
  }

  async edit(values) {
    values.removeSeparator();
    let res = await simpleFetch({
      url: "retail/kontak",
      method: "PUT",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values,
      },
    });
    return res;
  }

  async delete(id) {
    let res = await simpleFetch({
      url: "retail/kontak",
      method: "DELETE",
      body: {
        id: id,
      },
    });
    return res;
  }
}

import React, { useState } from "react";
import { dispatcher } from "../../../redux/dispatcher";
import { Button } from "react-bootstrap";
import store from "../../../redux/store";
import { PesananPembelianAPI } from "../../../api/pesanan-pembelian";
import { Link } from "react-router-dom";
import SimpleAlert2 from "../../../components/alert/alert2";

export function ButtonEditable({ dataModel }) {
  let role = store.getState().auth.alias_role;
  const [show, setShow] = useState(false);

  return (
    <>
      {dataModel.master.is_approved === 0 && ["admin"].includes(role) && (
        <>
          <Link
            className="mx-1 btn btn-outline-primary btn-sm"
            to="/pembelian-pesanan-pembelian/edit"
            onClick={() => {
              dispatcher.pesanan_pembelian.set(dataModel);
            }}
          >
            Edit
          </Link>
          <Button
            size="sm"
            className="mx-1"
            variant="outline-danger"
            onClick={() => {
              setShow(true);
            }}
          >
            Hapus
          </Button>
        </>
      )}
      <SimpleAlert2
        show={show}
        title="Hapus Data Ini?"
        content="Anda yakin ingin menghapus data ini?"
        variant="danger"
        confirmButton="Hapus"
        onConfirm={async () => {
          let forSend = { master: { status: 0, id: dataModel.master.id } };
          await new PesananPembelianAPI().edit(forSend);
          dispatcher.pesanan_pembelian.list();
        }}
      />
    </>
  );
}

const initialValues = {
    active: null,
    list: [],
  };
  
  export const notificationAction = {
    list: "LIST_NOTIFICATION",
    active: "ACTIVE_NOTIFICATION",
    resetActive: "RESET_ACTIVE_NOTIFICATION",
  };
  
  const reducer = (state = initialValues, action) => {
    let states;
    switch (action.type) {
      case notificationAction.list:
        states = state;
        states.list = action.payload;
        return (state = states);
      case notificationAction.active:
        states = state;
        states.active = action.payload;
        return (state = states);
      case notificationAction.resetActive:
        states = state;
        states.active = null;
        return (state = states);
      default:
        return state;
    }
  };
  
  export default reducer;
  
import React, { useState }  from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import List from "./list";
import Detail from "./detail";
import ReturPembelianModel from "../../../models/retur-pembelian-model/ReturPembelianModel";

export default function ListApprovalReturPembelian() {
  const [dataFromModel, setDataFromModel] = useState(new ReturPembelianModel())
  const [data, setDataDetail] = useState([])
  const history = useHistory();
  return (
    <Switch>
      <Route path="/approval-retur-pembelian/list">
        <List setDataFromModel={setDataFromModel} setDataDetail={setDataDetail}/>
      </Route>

     <Route path="/approval-retur-pembelian/detail">
        <Detail history={history} dataFromModel={dataFromModel} data={data}/>
      </Route>

      <Redirect from="/approval-retur-pembelian/" to="/approval-retur-pembelian/list" />
    </Switch>
  );
}

/* eslint-disable react/react-in-jsx-scope */
import moment from "moment";
import pengeluaranKasDetailModel from "./pengeluaranKasDetailModel";
import { separatorHarga } from "../../../services/separator-harga";
export default class pengeluaranKasApprovalModel {
  constructor(
    data = {
      transaksi: {
        id: 0,
        no_transaksi: "",
        no_transaksi_bku: "",
        cabang: "",
        kontak_ref: "",
        tanggal: "",
        tanggal_approved: "",
        is_approved: "",
        approval_accounting_by: "",
        approval_administrasi_by: "",
        akun: "",
        total: "",
        deskripsi: "",
        status: 0,
      },
      detail: [],
    }
  ) {
    const { id, no_transaksi, no_transaksi_bku, tanggal, tanggal_approved, is_approved, approval_accounting_by, approval_administrasi_by, cabang, kontak_ref, akun, total, deskripsi, status } = data['transaksi'];
    this['transaksi'] = {
      id: id,
      no_transaksi: no_transaksi,
      no_transaksi_bku: no_transaksi_bku,
      cabang: cabang,
      kontak_ref: kontak_ref,
      tanggal: tanggal,
      tanggal_approved: tanggal_approved,
      is_approved: is_approved,
      approval_accounting_by: approval_accounting_by,
      approval_administrasi_by: approval_administrasi_by,
      akun: akun,
      total: total,
      deskripsi: deskripsi,
      status: status,
    };
    this['detail'] = [];
    this.initDetails(data['detail']);
  }

  initDetails(detail) {
    if (detail.length > 0) {
      detail.forEach((item) => {
        this['detail'] = [
          ...this['detail'],
          new pengeluaranKasDetailModel({
            ...item,
          }),
        ];
      });
    }
  }

  async getdata() {
    const { no_transaksi, no_transaksi_bku, cabang, kontak_ref, tanggal, akun, total, deskripsi } = this['transaksi'];
    return [{
      id: "no_transaksi",
      title: "No. Transaksi",
      label: no_transaksi,
      center: true,
      sortable: true,
      minWidth: "25%",
    }, {
      id: "no_bku",
      title: "No. Bukti BKU",
      label: no_transaksi_bku,
      center: true,
      sortable: true,
      minWidth: "25%",
    }, {
      id: "id_perusahaan",
      title: "Cabang",
      label: cabang,
      center: true,
      sortable: true,
      minWidth: "20%",
    }, {
      id: "kontak_ref",
      title: "Kepada",
      label: kontak_ref,
      sortable: true,
      minWidth: "30%",
    }, {
      id: "tanggal",
      title: "Tanggal",
      label: moment(tanggal).format("DD-MM-YYYY"),
      center: true,
      sortable: true,
      minWidth: "17%",
    }, {
      id: "akun",
      title: "Akun",
      label: akun,
      center: true,
      sortable: true,
      minWidth: "15%",
    }, {
      id: "total",
      title: "Total",
      label: total < 0 ? `Rp. -${separatorHarga(total.toString())}` : separatorHarga(total.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "deskripsi",
      title: "Keterangan",
      label: deskripsi,
      sortable: true,
      minWidth: "60%",
    }
  ];
  }
}

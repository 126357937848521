import { menuAction } from "./actions";

const intialValues = {
  active: {
    parentMenu: null,
    menu: null,
  },
  menus: [],
};

const reducerMenu = (state = intialValues, action) => {
  switch (action.type) {
    case menuAction.setMenu:
      return (state = {
        ...state,
        active: {
          ...state.active,
          menu: action.payload.menu,
        },
      });
    case menuAction.setParentMenu:
      return (state = {
        ...state,
        active: {
          ...state.active,
          parentMenu: action.payload.parentMenu,
        },
      });
    case menuAction.setMenus:
      return (state = {
        ...state,
        menus: action.payload,
      });
    case menuAction.reset:
      return (state = intialValues);
    default:
      return state;
  }
};

export default reducerMenu;

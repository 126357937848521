/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";

import objectPath from "object-path";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import SVG from "react-inlinesvg";

import { Brand } from "../brand/Brand";
import { toAbsoluteUrl } from "../../../_helpers";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { dispatcher } from "../../../../app/redux/dispatcher";
import store from "../../../../app/redux/store";
import { KTUtil } from "./../../../_assets/js/components/util";
import { authAction } from "../../../../app/redux/auth/actions";
import { menuAction } from "../../../../app/redux/menu/actions";

import { AsideMenuList } from "./AsideMenuList";

export function Aside ( props )
{
   const uiService = useHtmlClassService();
   const layoutProps = useMemo( () =>
   {
      return {
         asideClassesFromConfig: uiService.getClasses( "aside", true ),
         asideSecondaryDisplay: objectPath.get(
            uiService.config,
            "aside.secondary.display"
         ),
         asideSelfMinimizeToggle: objectPath.get(
            uiService.config,
            "aside.self.minimize.toggle"
         ),
         extrasSearchDisplay: objectPath.get(
            uiService.config,
            "extras.search.display"
         ),
         extrasNotificationsDisplay: objectPath.get(
            uiService.config,
            "extras.notifications.display"
         ),
         extrasQuickActionsDisplay: objectPath.get(
            uiService.config,
            "extras.quick-actions.display"
         ),
         extrasQuickPanelDisplay: objectPath.get(
            uiService.config,
            "extras.quick-panel.display"
         ),
         extrasLanguagesDisplay: objectPath.get(
            uiService.config,
            "extras.languages.display"
         ),
         extrasUserDisplay: objectPath.get(
            uiService.config,
            "extras.user.display"
         ),
      };
   }, [ uiService ] );

   let MenuList = props[ 'menu' ][ 'menus' ];
   let menuFilter = MenuList.filter( ( item ) => item[ 'kode' ] !== "SYcQPlfroCzA3rrK" );
   // let menuFilter = MenuList.filter( ( item ) => item[ 'kode' ] !== "SYcQPlfroCzA3rrK" && item[ 'kode' ] !== "M9ORkweT0JwshcvM" && item[ 'kode' ] !== "SEvnnxiOXwvLjE5n" );
   let tabs = {};
   menuFilter.map( ( object, i ) =>
   {
      let tab = {};
      tab[ `tabId${ i }` ] = `kt_aside_tab_${ i }`;
      return tabs = { ...tabs, ...tab };
   } );

   const [ activeTab, setActiveTab ] = useState(
      store.getState().menu.active.parentMenu ?? "kt_aside_tab_0"
   );
   const handleTabChange = async ( id ) =>
   {
      document.body.classList.remove( "aside-minimize" );
      await store.dispatch( {
         type: menuAction.setParentMenu,
         payload: { parentMenu: id },
      } );
      setActiveTab( id );
      const asideWorkspace = KTUtil.find(
         document.getElementById( "kt_aside" ),
         ".aside-secondary .aside-workspace"
      );
      if ( asideWorkspace )
      {
         KTUtil.scrollUpdate( asideWorkspace );
      }
   };
   const [ countAll, setCountAll ] = useState( 0 );
   const fetchData = async () =>
   {
      await dispatcher[ 'panelNotification' ].list();
      let data = dispatcher[ 'panelNotification' ].getList();
      dispatcher[ 'panelNotification' ].set( data );
      setCountAll( data[ 'allCount' ] );
   }
   useEffect( () =>
   {
      fetchData();
   }, [] );
   return (
      <>
         {/* begin::Aside */ }
         <div
            id="kt_aside"
            className={ `aside aside-left d-flex ${ layoutProps.asideClassesFromConfig }` }
         >
            {/* begin::Primary */ }
            <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
               <Brand />
               {/* begin::Nav Wrapper */ }
               <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull">
                  {/* begin::Nav */ }
                  <ul className="list-unstyled flex-column" role="tablist">
                     {/* begin::Item */ }

                     { menuFilter.map( ( object, i ) =>
                     {
                        return (
                           <li
                              className="nav-item mb-3"
                              data-toggle="tooltip"
                              data-placement="rigth"
                              data-container="body"
                              data-boundary="window"
                              title={ object.nama }
                              key={ i }
                           >
                              <OverlayTrigger
                                 placement="right"
                                 overlay={
                                    <Tooltip id={ object.nama }>{ object.nama }</Tooltip>
                                 }
                              >
                                 <a
                                    href="#"
                                    className={ `nav-link btn btn-icon btn-clean btn-lg ${ activeTab ===
                                       `kt_aside_tab_${ i }` && "active" }` }
                                    data-toggle="tab"
                                    data-target={ `#kt_aside_tab_${ i }` }
                                    role="tab"
                                    onClick={ () => handleTabChange( `kt_aside_tab_${ i }` ) }
                                 ><span className="svg-icon svg-icon-lg">
                                       <i className={ object.icon }></i>
                                    </span>
                                 </a>
                              </OverlayTrigger>
                           </li>
                        );
                     } ) }
                     {/* Logout */ }
                  </ul>
                  {/* end::Nav */ }
               </div>
               {/* end::Nav Wrapper */ }

               {/* begin::Footer */ }
               <div className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
                  {/* begin::Aside Toggle */ }
                  { layoutProps.asideSecondaryDisplay &&
                     layoutProps.asideSelfMinimizeToggle && (
                        <>
                           <OverlayTrigger
                              placement="right"
                              overlay={ <Tooltip id="toggle-aside">Toggle Aside</Tooltip> }
                           >
                              <span
                                 className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
                                 id="kt_aside_toggle"
                              >
                                 <i className="ki ki-bold-arrow-back icon-sm" />
                              </span>
                           </OverlayTrigger>
                        </>
                     ) }
                  {/* end::Aside Toggle */ }
                  {/* begin::Notifications */ }
                  <OverlayTrigger
                     placement="right"
                     overlay={
                        <Tooltip id="toggle-notifications">Notifications</Tooltip>
                     }
                  >
                     <a
                        href="#"
                        className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
                        id="kt_quick_notifications_toggle"
                        data-placement="right"
                        data-container="body"
                        data-boundary="window"
                     // onClick={async () => {
                     //    await dispatcher['notification'].list();
                     //    let data = dispatcher['notification'].getList();
                     //    dispatcher['notification'].set(data);
                     //    setCountAll(data['allCount']);
                     // }}
                     >
                        <span className="svg-icon svg-icon-lg">
                           <SVG src={ toAbsoluteUrl( "/media/svg/icons/General/Notifications1.svg" ) } />
                        </span>
                        <span className={ "label label-sm label-light-danger label-rounded font-weight-bolder position-absolute top-0 right-0 mt-1 mr-1" }>{ countAll }</span>
                     </a>
                  </OverlayTrigger>
                  {/* end::Notifications */ }
                  {/* begin::Logout*/ }
                  <OverlayTrigger
                     placement="right"
                     overlay={ <Tooltip id="Logout">Logout</Tooltip> }
                  >
                     <a href="#"
                        className={ `nav-link btn btn-icon btn-clean btn-lg` }
                        data-toggle="tab"
                        // data-target={`#kt_aside_tab_${i}`}
                        role="tab"
                        onClick={ () =>
                        {
                           store.dispatch( { type: authAction.delUser } );
                           store.dispatch( { type: menuAction.reset } );
                        } }
                     >
                        <span className="svg-icon svg-icon-lg">
                           <i className="fa fa-sign-out-alt"></i>
                        </span>
                     </a>
                  </OverlayTrigger>
                  {/* end::Logout */ }
               </div>
               {/* end::Footer */ }
            </div>
            {/* end::Primary */ }

            { layoutProps.asideSecondaryDisplay && (
               <>
                  {/* begin::Secondary */ }
                  <div className="aside-secondary d-flex flex-row-fluid">
                     {/* begin::Workspace */ }
                     <div className="aside-workspace scroll scroll-push my-2">
                        <div className="tab-content">
                           { MenuList.map( ( menu, i ) =>
                           {
                              let tab = `kt_aside_tab_${ i }`;
                              return (
                                 <AsideMenuList
                                    isActive={ activeTab === tab }
                                    key={ i }
                                    submenu={ menu[ "child" ] }
                                 ></AsideMenuList>
                              );
                           } ) }
                        </div>
                     </div>
                     {/* end::Workspace */ }
                  </div>
                  {/* end::Secondary */ }
               </>
            ) }
         </div>
         {/* end::Aside */ }
      </>
   );
}

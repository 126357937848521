import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

export function SimpleTable(params) {
  const paginationOptions = {
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "25", value: 25 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
    ],
  };

  // const { SearchBar } = Search;

  return (
    <ToolkitProvider
      keyField="id"
      data={params.data}
      columns={params.columns}
      placeholder="Search..."
      search
    >
      {(props) => {
        return (
          <div>
            <span className="d-flex justify-content-end align-items-center">
              {/* <i className="fa fa-search mb-2 mx-2"></i> */}
              {/* <SearchBar
                className="mb-0"
                {...props.searchProps}
                style={{ width: 200, height: 32 }}
              /> */}
            </span>
            <hr />
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              classes="table table-head-custom table-vertical-center text-center overflow-hidden"
              keyField="id"
              pagination={paginationFactory(paginationOptions)}
              {...props.baseProps}
            ></BootstrapTable>
          </div>
        );
      }}
    </ToolkitProvider>
  );
}

export function TableWithoutPagination(params) {
  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center overflow-hidden"
        keyField="id"
        data={params.data}
        columns={params.columns}
      ></BootstrapTable>
    </>
  );
}

export function TableWithSearch({ dt, col, srtd }) {
  const data = dt
  const columns = col

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];
  const sorted = srtd ? srtd : defaultSorted;

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  });

  const { SearchBar, ClearSearchButton } = Search;

  return (
      <ToolkitProvider
        bootstrap4
        keyField='id'
        data={data}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <div className='d-flex justify-content-end'>
                <SearchBar {...props.searchProps} />
                <div>
                <ClearSearchButton {...props.searchProps} />
                </div>
              </div>
              <hr />
              <BootstrapTable
                classes="table table-head-custom table-vertical-center text-center overflow-hidden"
                defaultSorted={sorted}
                bordered={false}
                pagination={pagination}
                {...props.baseProps}
              />
            </div>
          )
        }
      </ToolkitProvider>
  );
}

export function TableWithSelect({ dt, col, srtd, slc }) {

  console.log(dt, col);
  const data = dt

  const columns = col

  const defaultSorted = [{
    dataField: 'id',
    order: 'asc'
  }];
  const sorted = srtd ? srtd : defaultSorted;

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  });

  const { SearchBar, ClearSearchButton } = Search;

  return (
      <ToolkitProvider
        bootstrap4
        keyField='id'
        data={data}
        columns={columns}
        search
      >
        {
          props => (
            <div>
              <div className='d-flex justify-content-end'>
                <SearchBar {...props.searchProps} />
                <div>
                <ClearSearchButton {...props.searchProps} />
                </div>
              </div>
              <hr />
              <BootstrapTable
                classes="table table-head-custom table-vertical-center text-center overflow-hidden"
                defaultSorted={sorted}
                bordered={false}
                pagination={pagination}
                selectRow={slc}
                {...props.baseProps}
              />
            </div>
          )
        }
      </ToolkitProvider>
  );
}

// export default TableWithSearch;

import React from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TableWithoutPagination } from "../../../components/table/Table";
import { Modal, Button } from "react-bootstrap";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Fields } from "../../../components/fields/Fields";
import {Typography} from "@material-ui/core";
import { PesananPenjualanAPI } from "../../../api/pesanan-penjualan";
import PesananPenjualanDetailModel from "../../../models/pesanan-penjualan-detail-model/PesananPenjualanDetailModel";
import { dispatcher } from "../../../redux/dispatcher";
import store from "../../../redux/store";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";

export default class FormEdit extends React.Component {
  constructor(props) {
    super(props);
    this.initialData = dispatcher.pesanan_penjualan.get();
    this.initialDetailData = new PesananPenjualanDetailModel();
    this.api = new PesananPenjualanAPI();
    this.simpleTableModel = new SimpleTableModel();
    this.pageName = props.isEdit
      ? "Edit Pesanan Penjualan"
      : "Tambah Pesanan Penjualan";
    this.isEdit = props.isEdit;
    this.state = {
      modal: {
        show: false,
        initialDetailData: this.initialDetailData,
        isEdit: true,
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
    this.columntable = [{ dataField: "", text: "" }];
    this.datatable = [{ id: 0, nama: "" }];
    this.fields = [];
    this.validation = Yup.object().shape({});
    this.detailFields = [];
    this.detailValidation = Yup.object().shape({});
    this.role = store.getState().auth.alias_role;
  }

  async componentDidMount() {
    await this.loadFields();
    await this.loadDetail();
    let yup = {};
    await this.initialData.getEditable().then((values) =>
      values.map((item) => {
        yup[item.dataField] = item.validation;
      })
    );
    this.validation = Yup.object().shape(yup);

    yup = {};
    await this.state.modal.initialDetailData.getEditable().then((values) =>
      values.map((item) => {
        yup[item.dataField] = item.validation;
      })
    );
    this.detailValidation = Yup.object().shape(yup);
    this.setState({ ...this.state });
  }

  async loadFields() {
    this.fields = [];
    await this.initialData
      .getEditable({ pageName: this.pageName, isForm: true })
      .then((values) => {
        values.map((item, i) => {
          return (this.fields = [...this.fields, item]);
        });
      });
    this.setState({ ...this.state });
  }

  async loadDetail() {
    let datas = this.initialData.detail;
    this.simpleTableModel = new SimpleTableModel();
    datas.map((item, i) => {
      if (item.status != 0)
        this.simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: item,
          actions: (
            <span>
              <Button
                size="sm"
                className="mx-1"
                variant="outline-primary"
                onClick={async () => await this.handleModal.edit(item)}
              >
                Edit
              </Button>
                <Button
                  size="sm"
                  className="mx-1"
                  variant="outline-danger"
                  onClick={() => {
                    this.handleAlert.show(item);
                  }}
                >
                  Hapus
                </Button>
            </span>
          ),
        });
    });

    this.datatable = await this.simpleTableModel.getDatatables();
    this.columntable = await this.simpleTableModel.getColumnHeaders();
    this.setState({ ...this.state });
  }

  handleModal = {
    loadDetailFields: async () => {
      this.detailFields = [];
      await this.state.modal.initialDetailData
        .getEditable({ pageName: this.pageName, isForm: true })
        .then((values) => {
          values.map((item, i) => {
            return (this.detailFields = [...this.detailFields, { ...item }]);
          });
        });
    },
    tambah: async () => {
      let state = { ...this.state };
      state.modal.initialDetailData = new PesananPenjualanDetailModel();
      if (this.initialData.master.id_customer === 0) {
        dispatcher.snackbar.show(
          "Warning",
          "Pilih customer terlebih dahulu",
          "warning"
        );
        return null;
      }
      state.modal.show = true;
      state.modal.title = `Item Baru`;
      state.modal.isEdit = false;
      await this.handleModal.loadDetailFields();
      this.setState({ ...state });
    },
    edit: async (dataFromModel) => {
      let state = { ...this.state };
      state.modal.initialDetailData = new PesananPenjualanDetailModel({
        ...dataFromModel.nulldata,
        ...dataFromModel,
      });
      state.modal.show = true;
      state.modal.title = `Edit Item`;
      state.modal.isEdit = true;
      await this.handleModal.loadDetailFields();
      this.setState({ ...state });
    },
    hide: () => {
      let modal = { ...this.state.modal };
      modal.show = false;
      this.setState({ modal });
    },
  };

  handleAlert = {
    show: (dataModel) => {
      let alert = { ...this.state.alert };
      alert.show = true;
      alert.activeId = dataModel;
      this.setState({ alert: alert });
    },
    hide: () => {
      let alert = { ...this.state.alert };
      alert.show = false;
      alert.activeId = null;
      this.setState({ alert: alert });
    },
    confirmed: async () => {
      await this.initialData.handleDetail.del(this.state.alert.activeId);
      this.handleAlert.hide();
      this.loadDetail();
    },
  };

  render() {
    return (
      <>
        <Modal
          show={this.state.alert.show}
          onHide={() => this.handleAlert.hide()}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Hapus Data Ini?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Anda yakin ingin menghapus data ini?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleAlert.hide()}>
              Batal
            </Button>
            <Button
              variant="warning"
              onClick={() => this.handleAlert.confirmed()}
            >
              Hapus
            </Button>
          </Modal.Footer>
        </Modal>

        <Formik
          enableReinitialize={true}
          initialValues={this.initialData.master}
          validationSchema={this.validation}
          onSubmit={async (values, { resetForm }) => {
            this.initialData.removeSeparator();
            this.initialData.removeAllDetailSeparator();
            if (this.props.isEdit) {
              let masterApprove = (this.initialData.master.is_approved = 0)
              if ("is_approved" in this.initialData.master){
                this.initialData = {
                  ...this.initialData,
                  masterApprove
                }
              }
              await this.api.edit(this.initialData);
            } else if (!this.state.modal.isEdit) {
              await this.api.set(this.initialData);
            }
            this.props.history.goBack();
          }}
        >
          {({ handleSubmit, setFieldValue, resetForm }) => (
            <AnimatedCard>
              <CardHeader title={this.pageName}>
                <CardHeaderToolbar>
                  <button
                    type="button"
                    className="btn btn-secondary mx-1"
                    onClick={() => this.props.history.goBack()}
                  >
                    <i className="fa fa-arrow-left"></i>
                    Kembali
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary mx-1"
                    onClick={() => handleSubmit()}
                  >
                    <i className="fa fa-save"></i>
                    Simpan
                  </button>
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <Form className="form form-label-right">
                  <div className="row">
                    {this.fields.map((field, i) => (
                      <div key={i} className="form-group col-lg-6">
                        {/* {["admin"].includes(this.role) && ( */}
                          <Fields {...field} setFieldValue={setFieldValue} />
                        {/* )} */}
                        {/* {["supervisor", "sales-manager"].includes(
                          this.role
                        ) && (
                          <Fields
                            {...field}
                            type="disabled-text"
                            setFieldValue={setFieldValue}
                          />
                        )} */}
                      </div>
                    ))}
                  </div>
                </Form>
                <span className="d-flex align-items-center justify-content-between mt-4">
                  <Typography variant="h6">Detail Item</Typography>
                  {/* {["admin"].includes(this.role) && ( */}
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      onClick={async () => await this.handleModal.tambah()}
                    >
                      <i className="fa fa-plus"></i>
                      Tambah Item
                    </button>
                  {/* )} */}
                </span>
                <TableWithoutPagination
                  data={this.datatable}
                  columns={this.columntable}
                />
              </CardBody>
            </AnimatedCard>
          )}
        </Formik>

        <Formik
          enableReinitialize={true}
          initialValues={this.state.modal.initialDetailData}
          validationSchema={this.detailValidation}
          onSubmit={async (values, { resetForm }) => {
            if (this.state.modal.isEdit) {
              await this.initialData.handleDetail.edit(
                this.state.modal.initialDetailData
              );
            } else {
              await this.initialData.handleDetail.set(
                this.state.modal.initialDetailData
              );
            }
            await this.loadDetail();
            resetForm();
            this.handleModal.hide();
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Modal
              show={this.state.modal.show}
              onHide={() => this.handleModal.hide()}
              aria-labelledby="contained-modal-title-vcenter"
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modal.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  {this.detailFields.map((field, i) => {
                    return (
                      <div key={i} className="form-group col-lg-6">
                        <Fields {...field} setFieldValue={setFieldValue} />
                      </div>
                    );
                  })}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={this.handleModal.hide}
                  className="btn btn-light btn-elevate"
                >
                  Batal
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  Simpan
                </button>
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      </>
    );
  }
}

import { getCoverageArea, getIdPerusahaan, simpleFetch, simpleFetchGetData } from "./api";
export class BankAPI {
  // Get All Data List
  async getList() {
    let id_perusahaan = (getCoverageArea().length > 0) ? getCoverageArea() : getIdPerusahaan();
    let res = await simpleFetchGetData({
      url: "retail/bank?tipe=getList&id_perusahaan=" + id_perusahaan,
      method: "GET",
    });
    return res;
  }
  // Get Jenis Bank
  async getJenisBank() {
    let res = await simpleFetch({
      url: "retail/select/jenis_bank",
      method: "GET",
    });
    return res;
  }
  // Get Coverage Area
  async getCoverage() {
    let res = await simpleFetch({
      url: "retail/select/perusahaan?id_coverage=" + getCoverageArea(),
      method: "GET",
    });
    return res;
  }
  // Insert data
  async set(values) {
    let res = await simpleFetch({
      url: "retail/bank",
      method: "POST",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values,
      },
    });
    return res;
  }
  // Update data
  async edit(values) {
    let res = await simpleFetch({
      url: "retail/bank",
      method: "PUT",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values,
      },
    });
    return res;
  }
  // Delete Data
  async delete(id) {
    let res = await simpleFetch({
      url: "retail/bank",
      method: "DELETE",
      body: id,
    });
    return res;
  }






  async getTransaksiJenis() {
    let res = await simpleFetch({
      url: "retail/transaksi/jenis",
      method: "GET",
    });
    return res;
  }

  async getSingle(id) {
    let res = await simpleFetch({
      url: "retail/bank?id=" + id,
      method: "GET",
    });
    return res;
  }

}

import { CardBody, CardFooter, CardHeader } from '../../../../_metronic/_partials/controls';
import React, { Component } from 'react';
import { AnimatedCard } from '../../../components/animated-card/animatedCard';
import DataTableModel from '../../../models/datatable-model/DataTableModel';
import { Formik } from 'formik';
import { FormsFields } from '../../../components/forms-fields/FormsFields';
import { akuntansiAPI } from '../../../api/akuntansi';
import { separatorHarga } from '../../../services/separator-harga';
import * as Yup from "yup";
import { getIdPerusahaan } from '../../../api/api';
import { ReactDatatableFooter } from '../../../components/react-datatable-footer/reactDatatableFooter';
import cekSelisihModel from '../../../models/akuntansi/cek-selisih/CekSelisih';
import ButtonLoad from '../../../components/button-loading/buttonLoad';

export default class CekSelisih extends Component {
   constructor(props) {
      super(props);
      this['pageName'] = "Cek Selisih";
      this['searchParameter'] = ["tanggal", "no_transaksi", "jurnal", "deskripsi", "debet", "kredit"];
      this['apiCekSelisih'] = new akuntansiAPI();
      this['options_cabang'] = [];
      this['state'] = {
         pending: false,
         handleChange: true,
         data: 0,
         initialValues: {
            start_date: new Date("01-01-2020"),
            end_date: new Date(),
            id_perusahaan: getIdPerusahaan(),
         },
         footerCekSelisih: {
            deskripsi: "Total",
            debet: 0,
            kredit: 0,
            selisih: 0,
         }
      }
      this['columntable'] = [{ id: "", title: "" }];
      this['datatable'] = [{ id: "", no: "", name: "" }];
   }
   //* ----------------------------------------------------------------------------- */
   //** Start: Component */
   async componentDidMount() {
      // Get Cabang
      this['apiCekSelisih'].getCoverage().then((values) => {
         this['options_cabang'] = values['data'].map((data) => ({
            label: data['text'],
            value: data['id'],
         }));
      });
      await this.LoadData(this['state']['initialValues']);
   }
   //** End: Component */
   //* ----------------------------------------------------------------------------- */
   //** Start: Load Data Buku Besar */
   async LoadData(form) {
      this.setState({ pending: true });
      let data = await this['apiCekSelisih'].getAllCekSelisih(form);
      this.setState({ data: data['data'] });
      this['DataTableModel'] = new DataTableModel({ readOnly: true });
      data['data'].map((item, i) => {
         let dataModel = new cekSelisihModel(item);
         if (item['status'] !== 3) {
            this['DataTableModel'].add({
               id: item['id'],
               no: i + 1,
               dataModel: dataModel,
               actions: null
            });
         }
         return item;
      });
      const total = (val) => {
         if (val === 'debet') {
            return data['data'].reduce((total, value) => {
               const debet = parseInt(value['debet']);
               return parseFloat(total + debet);
            }, 0);
         } else {
            return data['data'].reduce((total, value) => {
               const kredit = parseInt(value['kredit']);
               return parseFloat(total + kredit);
            }, 0);
         }
      };
      this['columntable'] = await this['DataTableModel'].getColumn();
      this['datatable'] = await this['DataTableModel'].getDatas();
      let selisih = parseInt(total("debet") - total("kredit"));
      this.setState({
         ...this['state'],
         pending: false,
         footerCekSelisih: {
            deskripsi: "Total",
            debet: total("debet") < 0 ? `Rp. -${separatorHarga(total("debet").toString())}` : separatorHarga(total("debet").toString(), "Rp. "),
            kredit: total("kredit") < 0 ? `Rp. -${separatorHarga(total("kredit").toString())}` : separatorHarga(total("kredit").toString(), "Rp. "),
            selisih: selisih < 0 ? `Rp. -${separatorHarga(selisih.toString())}` : separatorHarga(selisih.toString(), "Rp. "),
         }
      });
   }
   //** End: Load Data List */
   //* ----------------------------------------------------------------------------- */
   render() {
      const { pending, initialValues, footerCekSelisih } = this['state'];
      return (
         <>
            {/* Start: Formik Cek Selesih */}
            <Formik
               enableReinitialize={true}
               initialValues={initialValues}
               validationSchema={Yup.object().shape({
                  start_date: Yup.string().nullable().required(),
               })}
               onSubmit={(values) => {
                  this.LoadData(values);
               }}
            >
               {({ setFieldValue, setFieldTouched, touched, errors, values, handleSubmit }) => (
                  <AnimatedCard>
                     <CardHeader title={this.pageName}></CardHeader>
                     <CardBody style={{ marginTop: "-20px" }}>
                        <div className={"form-group row"}>
                           {/* Forms Start Date  */}
                           <FormsFields
                              id={"start_date"}
                              type={"DatePicker"}
                              label={"Tanggal Mulai"}
                              className={"col-lg-4"}
                              style={{ marginTop: "20px" }}
                              paramsFormik={{
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              }}
                           />
                           {/* Forms End Date  */}
                           <FormsFields
                              id={"end_date"}
                              type={"DatePicker"}
                              label={"Tanggal Akhir"}
                              className={"col-lg-4"}
                              style={{ marginTop: "20px" }}
                              paramsFormik={{
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              }}
                           />
                           {/* Forms Select Cabang */}
                           <FormsFields
                              id={"id_perusahaan"}
                              type={"select2"}
                              label={"Cabang"}
                              style={{ marginTop: "20px" }}
                              className={"col-lg-4"}
                              isClearable={true}
                              isSearchable={true}
                              options={this['options_cabang']}
                              paramsFormik={{
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              }}
                           />
                        </div>
                        {/* Button */}
                        <div style={{ display: "flex", justifyContent: "center" }} >
                           {/* Button Lihat Data */}
                           <ButtonLoad
                              label={"Lihat Data"}
                              pending={pending}
                              classNameIcon={"fa fa-filter"}
                              className={"btn btn-outline-success"}
                              onClick={async () => handleSubmit()}
                           />
                        </div>
                     </CardBody>
                     <CardFooter>
                        <ReactDatatableFooter
                           title={""}
                           columns={this['columntable']}
                           data={this['datatable']}
                           searchParameter={this['searchParameter']}
                           progressPending={pending}
                           footer={footerCekSelisih}
                        />
                     </CardFooter>
                  </AnimatedCard>
               )}
            </Formik>
            {/* End: Formik Jurnal Umum */}
            {/* ----------------------------------------------------------------------------- */}
         </>
      );
   }
}
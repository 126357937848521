import React, { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import List from "./list";
import Detail from "./detail"
import KartuPiutangModel from "../../../models/kartu-piutang/KartuPiutangModel";

export default function DaftarKartuPiutang() {
    const [dataFromModel, setDataFromModel] = useState(new KartuPiutangModel());
    const history = useHistory();

    return (
        <Switch>
            <Route path="/kartu-piutang/list">
                <List setDataFromModel={setDataFromModel} />
            </Route>
            <Route path="/kartu-piutang/detail">
                <Detail history={history} data={dataFromModel} />
            </Route>
            <Redirect from="/kartu-piutang/" to="/kartu-piutang/list" />
        </Switch>
    )
}
import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import List from "./list";
import Detail from "./detail";
import InvoicePenjualanModel from "../../../models/invoice-penjualan-model/InvoicePenjualanModel";

export default function InvoicePenjualan() {
  const [dataFromModel, setDataFromModel] = useState(new InvoicePenjualanModel());

  return (
    <Switch>
      {/* <Route path="/tambah-invoice-pembelian/new">
        <Form dataFromModel={new InvoicePembelianModel()} />
      </Route> */}
      <Route path="/tambah-penjualan/tambah">
        <Detail dataFromModel={dataFromModel} isEdit={true} />
      </Route>
      <Route path="/tambah-penjualan/list">
        <List setDataFromModel={setDataFromModel} />
      </Route>
      <Redirect from="/tambah-penjualan/" to="/tambah-penjualan/list" />
    </Switch>
  );
}

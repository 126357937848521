import { separatorHarga } from "../../../services/separator-harga";
import moment from "moment";
import pengeluaranBankCollectionDetailModel from "./pengeluaranBankCollectionDetailModel";
export default class pengeluaranBankCollectionApprovalModel
{
  constructor(
    data = {
      transaksi: {
        id: 0,
        no_transaksi: "",
        no_transaksi_bku: "",
        cabang: "",
        kontak_ref: "",
        tanggal: "",
        tanggal_approved: "",
        akun: "",
        nama_bank: "",
        no_rekening: "",
        total: "",
        deskripsi: "",
        status: 0,
      },
      detail: [],
    }
  )
  {
    const { id, no_transaksi, no_transaksi_bku, tanggal, tanggal_approved, cabang, kontak_ref, akun, nama_bank, no_rekening, total, deskripsi, status } = data[ 'transaksi' ];
    this[ 'transaksi' ] = {
      id: id,
      no_transaksi: no_transaksi,
      no_transaksi_bku: no_transaksi_bku,
      cabang: cabang,
      kontak_ref: kontak_ref,
      tanggal: tanggal,
      tanggal_approved: tanggal_approved,
      akun: akun,
      nama_bank: nama_bank,
      no_rekening: no_rekening,
      total: total,
      deskripsi: deskripsi,
      status: status,
    };
    this[ 'detail' ] = [];
    this.initDetails( data[ 'detail' ] );
  }

  initDetails ( detail )
  {
    if ( detail.length > 0 )
    {
      detail.forEach( ( item ) =>
      {
        this[ 'detail' ] = [
          ...this[ 'detail' ],
          new pengeluaranBankCollectionDetailModel( {
            ...item,
          } ),
        ];
      } );
    }
  }
  async getdata ()
  {
    const { no_transaksi, no_transaksi_bku, cabang, kontak_ref, tanggal, akun, nama_bank, no_rekening, total, deskripsi } = this[ 'transaksi' ];
    return [ {
      id: "no_transaksi",
      title: "No. Transaksi",
      label: no_transaksi,
      center: true,
      sortable: true,
      minWidth: "25%",
    },
    {
      id: "no_bku",
      title: "No. Bukti BKU",
      label: no_transaksi_bku,
      center: true,
      sortable: true,
      minWidth: "25%",
    }, {
      id: "id_perusahaan",
      title: "Cabang",
      label: cabang,
      center: true,
      sortable: true,
      minWidth: "20%",
    }, {
      id: "kontak_ref",
      title: "Kepada",
      label: kontak_ref,
      sortable: true,
      minWidth: "30%",
    }, {
      id: "tanggal",
      title: "Tanggal",
      label: moment( tanggal ).format( "DD-MM-YYYY" ),
      center: true,
      sortable: true,
      minWidth: "17%",
    }, {
      id: "akun",
      title: "Akun",
      label: akun,
      center: true,
      sortable: true,
      minWidth: "15%",
    }, {
      id: "nama_bank",
      title: "Nama Bank",
      label: nama_bank,
      center: true,
      sortable: true,
      minWidth: "25%",
    }, {
      id: "no_rekening",
      title: "No. Rekening",
      label: no_rekening,
      center: true,
      sortable: true,
      minWidth: "20%",
    }, {
      id: "total",
      title: "Total",
      label: total < 0 ? `Rp. -${ separatorHarga( total.toString() ) }` : separatorHarga( total.toString(), "Rp. " ),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "deskripsi",
      title: "Keterangan",
      label: deskripsi,
      sortable: true,
      minWidth: "60%",
    }];
  }
}

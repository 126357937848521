import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import FormPenerimaanBankCollection from "./form";
import { dispatcher } from "../../../../../redux/dispatcher";
export default function IndexFormPenerimaanBankCollection ()
{
  dispatcher[ 'kasir_bank_collection' ].resetActivePenerimaanBankCollection();
  const active = dispatcher[ 'kasir_bank_collection' ].getPenerimaanBankCollection();
  return (
    <Switch>
      <Route path="/kasir/tambah-penerimaan-bank-collection/form">
        <FormPenerimaanBankCollection />
      </Route>
      { active[ 'transaksi' ][ 'id' ] === 0 && (
        <Redirect to="/kasir/tambah-penerimaan-bank-collection/form" />
      ) }
      <Redirect to="/kasir/tambah-penerimaan-bank-collection/form" />
    </Switch>
  );
}

import { dispatcher } from "../redux/dispatcher";

import { simpleFetch } from "./api";

export class KaryawanAPI {
  async getAll() {
    let res = await simpleFetch({
      url: "retail/user/karyawan",
      method: "GET",
    });
    return res;
  }

  async changePassword(values) {
    try {
      await simpleFetch({
        url: "retail/user/password",
        method: "POST",
        body: {
          id: values.id,
          password: values.password,
          new_password: values.new_password
        },
      }).then((res) =>
        dispatcher.snackbar.show(
          "Berhasil",
          `${res.message}`,
          "primary"
        )
      )
    } catch (error) {
      dispatcher.snackbar.show(
        "Error",
        `${error.message}`,
        "danger"
    )
    }
  }

  async getSingle(id) {
    let res = await simpleFetch({
      url: "retail/user/karyawan?id=" + id,
      method: "GET",
    });
    return res;
  }
}

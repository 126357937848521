import * as Yup from "yup";
import { ProdukAPI } from "../../api/produk";
import { separatorHarga, hapusSeparator } from "../../services/separator-harga";
import store from "../../redux/store";
import { dispatcher } from "../../redux/dispatcher";

export default class StokKeluarDetailModel {
  constructor(
    data = {
      id: 0,
      id_penjualan: 0,
      id_perusahaan: store.getState().auth.id_perusahaan,
      id_produk: 0,
      kode: "",
      kode_barang: "",
      tanggal: "",
      tanggal_invoice: "",
      qty: 0,
      hpp: 0,
      hpp_total: 0,
      dpp: 0,
      dpp_total: 0,
      diskon: 0,
      diskon_persen: 0,
      diskon_opsi: 0,
      subtotal: 0,
      pajak: 0,
      pajak_persen: 11,
      total: 0,
      id_user: store.getState().auth.id,
      id_customer: 0,
      id_sales: 0,
      id_kontak: 0,
      id_gudang: 0,
      deskripsi: "",
      status: 1,
      created_at: "",
      update_at: "",
      toProduk: {
        nama: "",
        kode: "",
        persediaan_total: 0,
      },
    }
  ) {
    this.id = data.id;
    this.id_penjualan = data.id_penjualan;
    this.id_perusahaan = data.id_perusahaan;
    this.id_produk = data.id_produk;
    this.nama_produk = data.toProduk.nama;
    this.kode = data.kode;
    this.kode_barang = data.toProduk.kode;
    this.tanggal = data.tanggal;
    this.tanggal_invoice = data.tanggal_invoice;
    this.qty = data.qty;
    this.hpp = separatorHarga(data.hpp.toString());
    this.hpp_total = separatorHarga(data.hpp_total.toString());
    this.dpp = separatorHarga(data.dpp.toString());
    this.dpp_total = separatorHarga(data.dpp_total.toString());
    this.diskon = separatorHarga(data.diskon.toString());
    this.diskon_persen = data.diskon_persen;
    this.subtotal = separatorHarga(data.subtotal.toString());
    this.pajak = separatorHarga(data.pajak.toString());
    this.pajak_persen = data.pajak_persen;
    this.total = separatorHarga(data.total.toString());
    this.persediaan_total = data.toProduk.persediaan_total;
    this.id_user = data.id_user;
    this.id_customer = data.id_customer;
    this.id_sales = data.id_sales;
    this.id_kontak = data.id_kontak;
    this.id_gudang = data.id_gudang;
    this.deskripsi = data.deskripsi;
    this.status = data.status;
    this.created_at = data.created_at;
    this.update_at = data.update_at;
    this.diskon_opsi = data.diskon_opsi;
    this.options = [
      { value: 1, text: "Persen", id: 1 },
      { value: 2, text: "Rupiah", id: 2 },
    ];
  }

  nulldata = {
    id: 0,
    id_perusahaan: store.getState().auth.id_perusahaan,
    id_produk: 0,
    nama_produk: "",
    kode: "",
    tanggal: "",
    qty: 0,
    hpp: 0,
    hpp_total: 0,
    dpp: 0,
    dpp_total: 0,
    diskon: 0,
    diskon_persen: 0,
    subtotal: 0,
    pajak: 0,
    pajak_persen: 11,
    total: 0,
    id_user: store.getState().auth.id,
    id_customer: 0,
    id_sales: store.getState().auth.id,
    id_kontak: 0,
    id_gudang: 0,
    deskripsi: "",
    status: 1,
    created_at: "",
    update_at: "",
    toProduk: {
      nama: "",
      kode:"",
      persediaan_total: 0,
    },
  };

  async getEditable(params = { pageName: "", isForm: false }) {

    const getProdukDetail = async (id) => {
      return await new ProdukAPI().getSingle(id).then((res) => {
        return res.data;
      });
    };

    let produk_options = [];
    if (params.isForm) {
      await new ProdukAPI()
        .getAll()
        .then((response) =>
          response.data.map(
            (data, i) =>
              (produk_options = [
                ...produk_options,
                { id: data.id, kode: data.kode,text: data.nama, value: parseInt(data.id) },
              ])
          )
        );



      // await getProdukDetail(this.id_produk).then((val) => {
      //   this.persediaan_total = val.persediaan_total;
      // });
    }

    return [
      {
        validation: Yup.string(),
        dataField: "kode_barang",
        label: "Kode Produk",
        type: "disabled-text",
        onChange: (e, setFieldValue) => {
          getProdukDetail(this.id_produk).then((val) => {
            this.kode_barang = val.kode;
            console.log('cek kode barang', this.kode_barang);
          });
        },
        value: this.kode_barang,
      },
      {
        validation: Yup.number()
          .typeError("")
          .required("Produk harus diisi"),
        dataField: "id_produk",
        label: "Produk",
        type: "select",
        options: produk_options,
        onChange: async (e, setFieldValue) => {
          if (e.target.value != 0)
            getProdukDetail(e.target.value).then((val) => {
              this.nama_produk =
                e.target.options[e.target.options.selectedIndex].text;
              this.persediaan_total = val.persediaan_total;
            });
          return (this.id_produk = parseInt(e.target.value));
        },
        value: this.nama_produk,
      },
      {
        validation: Yup.number()
          .integer()
          .positive()
          .typeError("QTY tidak valid")
          .required("QTY harus diisi"),
        dataField: "qty",
        label: "QTY",
        type: "number",
        value: this.qty,
        onChange: (e, setFieldValue) => {
          this.qty = parseInt(e.target.value);
          this.hitungTotalan.all(setFieldValue);
          if (this.qty > this.persediaan_total) {
            dispatcher.snackbar.show(
              "Perhatian",
              "QTY lebih besar dari persediaan",
              "warning"
            );
          }
        },
      },
      {
        validation: Yup.string(),
        dataField: "persediaan_total",
        label: "Stok Tersedia",
        type: "disabled-text",
        onChange: (e, setFieldValue) => {},
        value: this.persediaan_total,
        invisibleOnTable: true,
      },
      {
        validation: Yup.string(),
        dataField: "deskripsi",
        label: "Deskripsi",
        type: "textarea",
        value: this.deskripsi,
        onChange: (e) => (this.deskripsi = e.target.value),
        invisibleOnTable: true,
      },
    ];
  }
}

// Master
// Data Customers
import { AkunAPI } from "../api/akun";
// Master Akun
import AkunModel from "../models/akun-model/AkunModel";
import CustomerSalesModel from "../models/kontak/customer-sales/CustomerSalesModel";
import DataCustomerModel from "../models/kontak/data-customer/DataCustomerModel";
import InvoicePembelianModel from "../models/invoice-pembelian-model/InvoicePembelianModel";
import { OrganisasiAPI } from "../api/organisasi";
import OrganisasiModel from "../models/kontak/organisasi/OrganisasiModel";
// Organisasi
import OrganisasiModel_ from "../models/organisasi-model/OrganisasiModel";
import { PenerimaanAPI } from "../api/penerimaan";
import PenerimaanModel from "../models/penerimaan-pembelian-model/PenerimaanPembelianModel";
import { PenjualanAPI } from "../api/penjualan";
import PenjualanModel from "../models/penjualan-model/PenjualanModel";
import { PesananPembelianAPI } from "../api/pesanan-pembelian";
import PesananPembelianModel from "../models/pesanan-pembelian-model/PesananPembelianModel";
import { PesananPenjualanAPI } from "../api/pesanan-penjualan";
import PesananPenjualanModel from "../models/pesanan-penjualan-model/PesananPenjualanModel";
import StokKeluarModel from "../models/stok-keluar-model/StokKeluarModel";
import StokMasukModel from "../models/stok-masuk-model/StokMasukModel";
import { UserAPI } from "../api/user";
// Master User Login
import UserModel from "../models/user-model/User";
import { akunAction } from "./akun/reducer";
//** Start: Akuntansi */
// Jurnal Umum */
import { akuntansiAPI } from "../api/akuntansi";
//** End: Akuntansi */
//* -------------------------------------------------------------------------------------------------------------------- */
//** Start: Kasir */
// Kas */
import { kasirKasAPI } from "../api/kasir-kas";
import { kasirKasAction } from "./kasir/kas/reducer";
// Bank Collection */
import { kasirBankCollectionAPI } from "../api/kasir-bank-collection";
import { kasirBankCollectionAction } from "./kasir/bank_collection/reducer";
// Bank Operasional */
import { kasirBankOperasionalAPI } from "../api/kasir-bank-operasional";
import { kasirBankOperasionalAction } from "./kasir/bank_operasional/reducer";
import { jurnalUmumAction } from "./akuntansi/jurnal-umum/reducer";
//** End: Kasir */
// Start: Master
import { kontakAPI } from "../api/kontak";
import { kontakAction } from "./kontak/reducer";
// End: Master
import { loadingAction } from "./loading/reducer";
import { messageAction } from "./message/actions";
import { organisasiAction } from "./organisasi/reducer";
import { pembelianAction } from "./pembelian/reducer";
import { penerimaanAction } from "./penerimaan/reducer";
// Kasir -> Penerimaan Bank Collection
import penerimaanBankCollectionModel from "../models/kasir/bank-collection/penerimaanBankCollectionModel";
// Kasir -> Penerimaan Bank Operasional
import penerimaanBankOperasionalModel from "../models/kasir/bank-operasional/penerimaanBankOperasionalModel";
import penerimaanKasModel from "../models/kasir/kas/penerimaanKasModel";
// Kasir -> Pengeluaran Bank Collection
import pengeluaranBankCollectionModel from "../models/kasir/bank-collection/pengeluaranBankCollectionModel";
// Kasir -> Pengeluaran Bank Operasional
import {pengeluaranBankOperasionalModel} from "../models/kasir/bank-operasional/pengeluaranBankOperasionalModel";
// Kasir -> Pengeluaran Kas
import pengeluaranKasModel from "../models/kasir/kas/pengeluaranKasModel";
import { penjualanAction } from "./penjualan/reducer";
import { pesananPenjualanAction } from "./pesanan_penjualan/reducer";
import store from "./store";
import { userLoginAction } from "./user_login/reducer";
// StokTransfer;
import { transferStokAction } from "./stok-transfer/reducer";
import { StokTransferAPI } from "../api/stok-transfer";
import DaftarRequestModel from "../models/stok-req-model/DaftarRequestModel";
import StokRequestModel from "../models/stok-req-model/StokRequestModel";
import jurnalUmumModel from "../models/akuntansi/jurnal-umum/JurnalUmumModel";
import { notificationAPI } from "../api/notifications";
import { notificationAction } from "./notification/reducer";
import NotificationModel from "../models/notification/NotificationModel";
import PesananPenjualanBarangModel from "../models/transaksi/pesanan-penjualan/pesananPenjualanModel";
import { panelNotificationAction } from "./panel-notification/reducer";
//* -------------------------------------------------------------------------------------------------------------------- */
//** Start: Master
// Kontak (Customer/Supplier/Organisasi) */
const kontak = {
  // Start: Data Customer
  listDataCustomer: async () => {
    let list = await new kontakAPI().getDataCustomerAll();
    store.dispatch({ type: kontakAction['list'], payload: list['data'] });
    return list;
  },
  getListDataCustomer: () => store.getState().kontak['list'],
  getDataCustomer: () => {
    let active = store.getState().kontak['active'];
    return active ?? new DataCustomerModel();
  },
  setDataCustomer: (active) => store.dispatch({ type: kontakAction['active'], payload: active }),
  resetActiveDataCustomer: () => {
    store.dispatch({
      type: kontakAction['active'],
      payload: new DataCustomerModel(),
    });
  },
  // End: Data Customer
  // Start: Organisasi
  listOrganisasi: async (form) => {
    let list = await new kontakAPI().getOrganisasiAll(form);
    store.dispatch({ type: kontakAction['list'], payload: list['data'] });
    return list;
  },
  getListOrganisasi: () => store.getState().kontak['list'],
  getOrganisasi: () => {
    let active = store.getState().kontak['active'];
    return active ?? new OrganisasiModel();
  },
  setOrganisasi: (active) => store.dispatch({ type: kontakAction['active'], payload: active }),
  resetActiveOrganisasi: () => {
    store.dispatch({
      type: kontakAction['active'],
      payload: new OrganisasiModel(),
    });
  },
  // End: Organisasi
  // Start: Customer Sales
  listCustomerSales: async () => {
    let id_sales = store.getState().auth['id'];
    let list = await new kontakAPI().getCustomerSalesAll(id_sales);
    store.dispatch({ type: kontakAction['list'], payload: list['data'] });
    return list;
  },
  getListCustomerSales: () => store.getState().kontak['list'],
  getCustomerSales: () => {
    let active = store.getState().kontak['active'];
    return active ?? new CustomerSalesModel();
  },
  setCustomerSales: (active) => store.dispatch({ type: kontakAction['active'], payload: active }),
  resetActiveCustomerSales: () => {
    store.dispatch({
      type: kontakAction['active'],
      payload: new CustomerSalesModel(),
    });
  },
  // End: Customer Sales
};
//** End: Master */
//* -------------------------------------------------------------------------------------------------------------------- */
// Bank Operasional */
const akuntansi = {
  //** Start: Penerimaan */
  listJurnalUmum: async () => {
    let list = await new akuntansiAPI().getAllListJurnalUmum();
    store.dispatch({
      type: jurnalUmumAction['list'],
      payload: list['data'],
    });
    return list;
  },
  getListJurnalUmum: () => store.getState().jurnal_umum['list'],
  getJurnalUmum: () => {
    let active = store.getState().jurnal_umum['active'];
    return active ?? new jurnalUmumModel();
  },
  setJurnalUmum: (active) =>
    store.dispatch({
      type: jurnalUmumAction['active'],
      payload: active,
    }),
  resetActiveJurnalUmum: () => {
    store.dispatch({
      type: jurnalUmumAction['active'],
      payload: new jurnalUmumModel(),
    });
  },
  // cetakTandaTerima: async (values) => await new akuntansiAPI().printPdfTandaTerima(values),
  // cetakBuktiPenerimaan: async (values) => await new akuntansiAPI().printPdfBuktiPenerimaan(values),
};
//** End: Master */
//* -------------------------------------------------------------------------------------------------------------------- */
//** Start: Kasir
// Kas */
const kasir_kas = {
  //** Start: Penerimaan */
  listPenerimaanKas: async (form) => {
    let list = await new kasirKasAPI().getAllListPenerimaanKas(form);
    store.dispatch({
      type: kasirKasAction['list'],
      payload: list['data'],
    });
    return list;
  },
  getListPenerimaanKas: () => store.getState().kasir_kas['list'],
  getPenerimaanKas: () => {
    let active = store.getState().kasir_kas['active'];
    return active ?? new penerimaanKasModel();
  },
  setPenerimaanKas: (active) =>
    store.dispatch({
      type: kasirKasAction['active'],
      payload: active,
    }),
  resetActivePenerimaanKas: () => {
    store.dispatch({
      type: kasirKasAction['active'],
      payload: new penerimaanKasModel(),
    });
  },
  cetakTandaTerima: async (values) => await new kasirKasAPI().printPdfTandaTerima(values),
  cetakBuktiPenerimaan: async (values) => await new kasirKasAPI().printPdfBuktiPenerimaan(values),
  //** End: Penerimaan */
  //* -------------------------------------------------------------------------------------------------------------------- */
  //** Start: Pengeluaran */
  listPengeluaranKas: async (form) => {
    let list = await new kasirKasAPI().getAllListPengeluaranKas(form);
    store.dispatch({
      type: kasirKasAction['list'],
      payload: list['data'],
    });
    return list;
  },
  getListPengeluaranKas: () => store.getState().kasir_kas['list'],
  getPengeluaranKas: () => {
    let active = store.getState().kasir_kas['active'];
    return active ?? new pengeluaranKasModel();
  },
  setPengeluaranKas: (active) =>
    store.dispatch({
      type: kasirKasAction['active'],
      payload: active,
    }),
  resetActivePengeluaranKas: () => {
    store.dispatch({
      type: kasirKasAction['active'],
      payload: new pengeluaranKasModel(),
    });
  },
  cetakBuktiPengeluaran: async (values) => await new kasirKasAPI().printPdfBuktiPengeluaran(values),
  //** End: Pengeluaran */
};
//* -------------------------------------------------------------------------------------------------------------------- */
// Bank Collection */
const kasir_bank_collection = {
  //** Start: Penerimaan */
  listPenerimaanBankCollection: async (form) => {
    let list = await new kasirBankCollectionAPI().getAllListPenerimaanBankCollection(form);
    store.dispatch({
      type: kasirBankCollectionAction['list'],
      payload: list['data'],
    });
    return list;
  },
  getListPenerimaanBankCollection: () => store.getState().kasir_bank_collection['list'],
  getPenerimaanBankCollection: () => {
    let active = store.getState().kasir_bank_collection['active'];
    return active ?? new penerimaanBankCollectionModel();
  },
  setPenerimaanBankCollection: (active) =>
    store.dispatch({
      type: kasirBankCollectionAction['active'],
      payload: active,
    }),
  resetActivePenerimaanBankCollection: () => {
    store.dispatch({
      type: kasirBankCollectionAction['active'],
      payload: new penerimaanBankCollectionModel(),
    });
  },
  cetakTandaTerima: async (values) => await new kasirBankCollectionAPI().printPdfTandaTerima(values),
  cetakBuktiPenerimaan: async (values) => await new kasirBankCollectionAPI().printPdfBuktiPenerimaan(values),
  //** End: Penerimaan */
  //* -------------------------------------------------------------------------------------------------------------------- */
  //** Start: Pengeluaran */
  listPengeluaranBankCollection: async (form) => {
    let list = await new kasirBankCollectionAPI().getAllListPengeluaranBankCollection(form);
    store.dispatch({
      type: kasirBankCollectionAction['list'],
      payload: list['data'],
    });
    return list;
  },
  getListPengeluaranBankCollection: () => store.getState().kasir_bank_collection['list'],
  getPengeluaranBankCollection: () => {
    let active = store.getState().kasir_bank_collection['active'];
    return active ?? new pengeluaranBankCollectionModel();
  },
  setPengeluaranBankCollection: (active) =>
    store.dispatch({
      type: kasirBankCollectionAction['active'],
      payload: active,
    }),
  resetActivePengeluaranBankCollection: () => {
    store.dispatch({
      type: kasirBankCollectionAction['active'],
      payload: new pengeluaranBankCollectionModel(),
    });
  },
  cetakBuktiPengeluaran: async (values) => await new kasirBankCollectionAPI().printPdfBuktiPengeluaran(values),
  //** End: Pengeluaran */
};
//* -------------------------------------------------------------------------------------------------------------------- */
// Bank Operasional */
const kasir_bank_operasional = {
  //** Start: Penerimaan */
  listPenerimaanBankOperasional: async (form) => {
    let list = await new kasirBankOperasionalAPI().getAllListPenerimaanBankOperasional(form);
    store.dispatch({
      type: kasirBankOperasionalAction['list'],
      payload: list['data'],
    });
    return list;
  },
  getListPenerimaanBankOperasional: () => store.getState().kasir_bank_operasional['list'],
  getPenerimaanBankOperasional: () => {
    let active = store.getState().kasir_bank_operasional['active'];
    return active ?? new penerimaanBankOperasionalModel();
  },
  setPenerimaanBankOperasional: (active) =>
    store.dispatch({
      type: kasirBankOperasionalAction['active'],
      payload: active,
    }),
  resetActivePenerimaanBankOperasional: () => {
    store.dispatch({
      type: kasirBankOperasionalAction['active'],
      payload: new penerimaanBankOperasionalModel(),
    });
  },
  cetakTandaTerima: async (values) => await new kasirBankOperasionalAPI().printPdfTandaTerima(values),
  cetakBuktiPenerimaan: async (values) => await new kasirBankOperasionalAPI().printPdfBuktiPenerimaan(values),
  //** End: Penerimaan */
  //* -------------------------------------------------------------------------------------------------------------------- */
  //** Start: Pengeluaran */
  listPengeluaranBankOperasional: async (form) => {
    let list = await new kasirBankOperasionalAPI().getAllListPengeluaranBankOperasional(form);
    store.dispatch({
      type: kasirBankOperasionalAction['list'],
      payload: list['data'],
    });
    return list;
  },
  getListPengeluaranBankOperasional: () => store.getState().kasir_bank_operasional['list'],
  getPengeluaranBankOperasional: () => {
    let active = store.getState().kasir_bank_operasional['active'];
    return active ?? new pengeluaranBankOperasionalModel();
  },
  setPengeluaranBankOperasional: (active) =>
    store.dispatch({
      type: kasirBankOperasionalAction['active'],
      payload: active,
    }),
  resetActivePengeluaranBankOperasional: () => {
    store.dispatch({
      type: kasirBankOperasionalAction['active'],
      payload: new pengeluaranBankOperasionalModel(),
    });
  },
  cetakBuktiPengeluaran: async (values) => await new kasirBankOperasionalAPI().printPdfBuktiPengeluaran(values),
  //** End: Pengeluaran */
};
//** End: Master */
//* -------------------------------------------------------------------------------------------------------------------- */
// Organisasi
const organisasi = {
  list: async () => {
    let list = await new OrganisasiAPI().getAll();
    store.dispatch({ type: organisasiAction.list, payload: list.data });
    return list;
  },
  getList: () => store.getState().organisasi.list,
  all: async () => {
    let list = await new OrganisasiAPI().getAll();
    return store.dispatch({ type: organisasiAction.list, payload: list });
  },
  get: () => {
    let active = store.getState().organisasi.active;
    return active ?? new OrganisasiModel_();
  },
  set: (active) =>
    store.dispatch({ type: organisasiAction.active, payload: active }),
  resetActive: () => store.dispatch({ type: organisasiAction.resetActive }),
  send: async () => {
    await new OrganisasiAPI().set(store.getState().organisasi.active);
  },
};

const invoice_pembelian = {
  list: async () => {
    let list = await new PesananPembelianAPI().getAll();
    store.dispatch({ type: pembelianAction.list, payload: list.data });
    return list;
  },
  getList: () => store.getState().pembelian.list,
  all: async () => {
    let list = await new PesananPembelianAPI().getAll();
    return store.dispatch({ type: pembelianAction.list, payload: list });
  },
  get: () => {
    let active = store.getState().pembelian.active;
    return active ?? new InvoicePembelianModel();
  },
  set: (active) =>
    store.dispatch({ type: pembelianAction.active, payload: active }),
  resetActive: () => store.dispatch({ type: pembelianAction.resetActive }),
  send: async () => {
    await new PesananPembelianAPI().set(store.getState().pembelian.active);
  },
};

const pesanan_pembelian = {
  list: async () => {
    let list = await new PesananPembelianAPI().getAll();
    store.dispatch({ type: pembelianAction.list, payload: list.data });
    return list;
  },
  getList: () => store.getState().pembelian.list,
  all: async () => {
    let list = await new PesananPembelianAPI().getAll();
    return store.dispatch({ type: pembelianAction.list, payload: list });
  },
  get: () => {
    let active = store.getState().pembelian.active;
    return active ?? new PesananPembelianModel();
  },
  set: (active) =>
    store.dispatch({ type: pembelianAction.active, payload: active }),
  resetActive: () => store.dispatch({ type: pembelianAction.resetActive }),
  send: async () => {
    await new PesananPembelianAPI().set(store.getState().pembelian.active);
  },
};

const penerimaan = {
  list: async () => {
    await new PenerimaanAPI().getAll().then((response) => {
      store.dispatch({ type: penerimaanAction.list, payload: response.data });
    });
  },
  single: async (id) => {
    await new PenerimaanAPI().getSingle(id).then((response) => {
      // const data = new PenerimaanModel(response.data);
      const data = new StokMasukModel(response.data);
      store.dispatch({ type: penerimaanAction.active, payload: data });
    });
  },
  getList: () => store.getState().penerimaan.list,
  get: () => {
    let active = store.getState().penerimaan.active;
    return active ?? new PenerimaanModel();
  },
  set: (active) =>
    store.dispatch({ type: penerimaanAction.active, payload: active }),
  validate: async (values) => await new PenerimaanAPI().validate(values),
  invoice: async (values) => await new PenerimaanAPI().invoice(values),
  validasiInvoice: async (values) => await new PenerimaanAPI().validasiInvoice(values),
  resetActive: () => store.dispatch({ type: penerimaanAction.resetActive }),
};

const pesanan_penjualan = {
  list: async () => {
    let list = await new PesananPenjualanAPI().getAll();
    store.dispatch({ type: pesananPenjualanAction.list, payload: list.data });
    return list;
  },
  getList: () => store.getState().pesanan_penjualan.list,
  get: () => {
    let active = store.getState().pesanan_penjualan.active;
    return active ?? new PesananPenjualanModel();
  },
  set: (active) =>
    store.dispatch({ type: pesananPenjualanAction.active, payload: active }),
  resetActive: () =>
    store.dispatch({ type: pesananPenjualanAction.resetActive }),
  send: async () => {
    await new PesananPenjualanAPI().set(
      store.getState().pesanan_penjualan.active
    );
  },
};

const penjualan = {
  list: async () => {
    let list = await new PenjualanAPI().getAll();
    store.dispatch({ type: penjualanAction.list, payload: list.data });
    return list;
  },
  getList: () => store.getState().penjualan.list,
  single: async (id) => {
    await new PenjualanAPI().getSingle(id).then((response) => {
      // const data = new PenjualanModel(response.data);
      const data = new StokKeluarModel(response.data);
      store.dispatch({ type: penjualanAction.active, payload: data });
    });
  },
  get: () => {
    let active = store.getState().penjualan.active;
    return active ?? new PenjualanModel();
  },
  set: (active) =>
    store.dispatch({ type: penjualanAction.active, payload: active }),
  resetActive: () => store.dispatch({ type: penjualanAction.resetActive }),
  validate: async (values) => await new PenjualanAPI().detail(values),
  invoice: async (values) => await new PenjualanAPI().invoice(values),
  send: async () => {
    await new PenjualanAPI().set(store.getState().penjualan.active);
  },
};

const stokTransfer = {
  listKirim: async () => {
    let list = new StokTransferAPI().getAllByRef();
    console.warn(list);
    store.dispatch({ type: transferStokAction.list, payload: list });
    return list;
  },
  getListKirim: () => store.getState().transfer_stok.list,
  single: async (id) => {
    await new StokTransferAPI().getSingle(id).then((response) => {
      // const data = new PenjualanModel(response.data);
      const data = new DaftarRequestModel(response.data);
      store.dispatch({ type: transferStokAction.active, payload: data });
    });
  },
  getKirim: () => {
    let active = store.getState().transfer_stok.active;
    return active ?? new DaftarRequestModel();
  },
  setKirim: (active) =>
    store.dispatch({ type: transferStokAction.active, payload: active }),
  resetActiveKirim: () => store.dispatch({ type: transferStokAction.resetActive }),
  // validate: async (values) => await new PenjualanAPI().detail(values),
  // invoice: async (values) => await new PenjualanAPI().invoice(values),
  // send: async () => {
  //   await new PenjualanAPI().set(store.getState().penjualan.active);
  // },
};
// Helper Alert & Loading
const snackbar = {
  show: (title, message, variant) =>
    store.dispatch({
      type: messageAction.setMessage,
      payload: {
        message: message,
        title: title,
        show: true,
        variant: variant,
      },
    }),
};
const loading = {
  show: () => store.dispatch({ type: loadingAction.show }),
  hide: () => store.dispatch({ type: loadingAction.hide }),
};
// Akun
const akun = {
  list: async () => {
    let list = await new AkunAPI().getAll();
    store.dispatch({
      type: akunAction.list,
      payload: list.data,
    });
    return list;
  },
  getList: () => store.getState().akun.list,
  get: () => {
    let active = store.getState().akunAction.active;
    return active ?? new AkunModel();
  },
};

const userLogin = {
  list: async () => {
    let list = await new UserAPI().getAll();
    store.dispatch({
      type: userLoginAction.list,
      payload: list.data,
    });
    return list;
  },
  getList: () => store.getState().userLogin.list,
  get: () => {
    let active = store.getState().userLoginAction.active;
    return active ?? new UserModel();
  },
};

// Notification
const notification = {
  //* -------------------------------------------------------------------------------------------------------------------- */
  // Approval Customer Awal
  listApprovalCustomerAwal: async (form) => {
    let list = await new notificationAPI().getAllApprovalCustomer("awal");
    store.dispatch({
      type: notificationAction['list'],
      payload: list['data'],
    });
    return list;
  },
  getListApprovalCustomerAwal: () => store.getState().notification['list'],
  getApprovalCustomerAwal: () => {
    let active = store.getState().notification['active'];
    return active ?? new OrganisasiModel();
  },
  setApprovalCustomerAwal: (active) =>
    store.dispatch({
      type: notificationAction['active'],
      payload: active,
    }),
  resetActiveApprovalCustomerAwal: () => {
    store.dispatch({
      type: notificationAction['active'],
      payload: new OrganisasiModel(),
    });
  },
  //* -------------------------------------------------------------------------------------------------------------------- */
  // Approval Customer Akhir
  listApprovalCustomerAkhir: async (form) => {
    let list = await new notificationAPI().getAllApprovalCustomer("akhir");
    store.dispatch({
      type: notificationAction['list'],
      payload: list['data'],
    });
    return list;
  },
  getListApprovalCustomerAkhir: () => store.getState().notification['list'],
  getApprovalCustomerAkhir: () => {
    let active = store.getState().notification['active'];
    return active ?? new OrganisasiModel();
  },
  setApprovalCustomerAkhir: (active) =>
    store.dispatch({
      type: notificationAction['active'],
      payload: active,
    }),
  resetActiveApprovalCustomerAkhir: () => {
    store.dispatch({
      type: notificationAction['active'],
      payload: new OrganisasiModel(),
    });
  },
  //* -------------------------------------------------------------------------------------------------------------------- */
  // Approval  Pesanan Barang
  listApprovalPesananPenjualan: async (form) => {
    let list = await new notificationAPI().getAllApprovalPesananPenjualan(form);
    store.dispatch({
      type: notificationAction['list'],
      payload: list['data'],
    });
    return list;
  },
  getListApprovalPesananPenjualan: () => store.getState().notification['list'],
  getApprovalPesananPenjualan: () => {
    let active = store.getState().notification['active'];
    return active ?? new PesananPenjualanBarangModel();
  },
  setApprovalPesananPenjualan: (active) =>
    store.dispatch({
      type: notificationAction['active'],
      payload: active,
    }),
  resetActiveApprovalPesananPenjualan: () => {
    store.dispatch({
      type: notificationAction['active'],
      payload: new PesananPenjualanBarangModel(),
    });
  },
  //* -------------------------------------------------------------------------------------------------------------------- */
};

// Notification
const panelNotification = {
  //* -------------------------------------------------------------------------------------------------------------------- */
  // Notifications Count
  list: async () => {
    let list = await new notificationAPI().getCountNotif();
    store.dispatch({
      type: panelNotificationAction['list'],
      payload: list['data'],
    });
    return list;
  },
  getList: () => store.getState().panelNotification['list'],
  set: (active) =>
    store.dispatch({
      type: panelNotificationAction['active'],
      payload: active,
    }),
  resetActive: () => {
    store.dispatch({
      type: panelNotificationAction['active'],
      payload: new NotificationModel(),
    });
  },
  get: () => {
    let active = store.getState().panelNotification['list'];
    return active ?? new NotificationModel();
  },
  //* -------------------------------------------------------------------------------------------------------------------- */
};

export const dispatcher = {
  kontak: kontak,
  stokTransfer: stokTransfer,
  akuntansi: akuntansi,
  kasir_kas: kasir_kas,
  kasir_bank_collection: kasir_bank_collection,
  kasir_bank_operasional: kasir_bank_operasional,
  organisasi: organisasi,
  pesanan_pembelian: pesanan_pembelian,
  penerimaan: penerimaan,
  pesanan_penjualan: pesanan_penjualan,
  penjualan: penjualan,
  snackbar: snackbar,
  loading: loading,
  akun: akun,
  userLogin: userLogin,
  notification: notification,
  panelNotification: panelNotification,
};

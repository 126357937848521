/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { CardBody, CardFooter, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls';
import { AnimatedCard } from '../../../components/animated-card/animatedCard';
import { Button, Modal } from 'react-bootstrap';
import { useHistory, useLocation } from "react-router-dom";
import Select2 from "react-select";
import { StokAPI } from '../../../api/stok';
import store from '../../../redux/store';
import moment from 'moment';
import { TableWithoutPagination } from '../../../components/table/Table';
import { dispatcher } from '../../../redux/dispatcher';
import { StokOpnameAPI } from '../../../api/stok-opname';

function FormDetail() {
  let dtMaster = useLocation()
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [dtDb, setdtDb] = useState([]);
  const hide = dtMaster.state?.form ? false : true
  const columns = [
    { dataField: 'id', text: 'Id', sort: true, hidden: true },
    { dataField: 'nama', text: 'Nama Produk', sort: true },
    { dataField: 'satuan', text: 'Satuan', sort: true },
    { dataField: 'masuk', text: 'Stok Masuk', sort: true },
    { dataField: 'keluar', text: 'Stok Keluar', sort: true },
    { dataField: 'total', text: 'Total Stok', sort: true },
    { dataField: 'fisik', text: 'Stok Fisik', sort: true },
    { dataField: 'selisih', text: 'Selisih', sort: false },
    { dataField: 'actions', text: 'Action', sort: false, hidden: hide }
  ];
  const handleClose = () => {
    setDetail({
      id_produk: 0,
      id: 0,
      nama: "",
      masuk: 0,
      keluar: 0,
      alokasi: 0,
      total: 0,
      fisik: 0,
      selisih: 0,
      status_validasi: 0,
      satuan: "",
    })
    setItem()
    setRefresh(!refresh)
    setShow(false)
  };
  const [data, setData] = useState([]);
  const [opsi, setOpsi] = useState([]);
  let cekValidasi = data.some(dt => dt.status_validasi === 0)
  const [detail, setDetail] = useState({
    id_produk: 0,
    id: 0,
    nama: "",
    masuk: 0,
    keluar: 0,
    alokasi: 0,
    total: 0,
    fisik: 0,
    selisih: 0,
    status_validasi: 0,
    satuan: "",
    actions: "",
  });
  const [slcItem, setItem] = useState()
  const [cabang] = useState(store.getState().auth.id_perusahaan);
  const [date, setDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const totalStokAktual = Object.values(data).reduce((t, { fisik }) => t + fisik, 0)
  const totalStok = Object.values(data).reduce((t, { total }) => t + total, 0)
  const totalSelisih = Object.values(data).reduce((t, { selisih }) => t + selisih, 0)

  const detailData = async (val) => {
    setDetail({
      id_produk: val.value,
      id: val.value,
      nama: val.text,
      masuk: val.masuk,
      keluar: val.keluar,
      alokasi: val.alokasi,
      total: val.total,
      satuan: val.satuan,
      fisik: val.fisik,
      status_validasi: val.status_validasi,
      selisih: val.total - val.fisik,
    })
  }

  function onAddData(e) {
    const index = data.findIndex(object => object.id === e.id);
    console.log(index); // 👉️ -1

    if (index === -1) {
      setRefresh(!refresh)
      setData(data => [...data, detail])
      handleClose()
    } else {
      dispatcher.snackbar.show("Error", "Duplikat Data, Silahkan pilih item lain", "danger");
    }
  }

  function onDeleteData(val) {
    const newArr = data.filter(object => {
      return object.id !== val;
    });
    setData(newArr)
    setRefresh(!refresh)
  }

  const { masuk, keluar, alokasi, total, satuan, fisik, selisih } = detail

  const fetchDetail = async (data) => {
    await new StokOpnameAPI().getDetail(data).then((res) => {
      let ops = []
      console.log("Is Form", res.data);
      console.log("Res Data length", res.data.length);
      if (res.data.length > 0 && dtMaster.state?.form) {
        res.data.map((v, i) => {
          let opt = {
            ...v,
            id: v.id_produk,
            actions: <Button
              disabled={v.status_validasi === 1 ? true : false}
              onClick={() => {
                onDeleteData(v.id_produk)
              }}><i className="fa fa-trash"></i></Button>
          }
          return ops = [...ops, opt]
        });
      } else {
        res.data.map((v, i) => {
          let opt = {
            ...v,
            id: v.id_produk,
          }
          return ops = [...ops, opt]
        });
      }

      if (res.data.length === 0) {
        return ops = [...ops]
      }
      // console.log("Opsii", ops);
      setdtDb(ops)
      setRefresh(!refresh)
      return setData(ops)
    })
  }

  const fetchData = async (dt) => {
    try {
      await new StokAPI().laporanStok(dt).then((res) => {
        const newArr = res.data.filter(({ id_produk: id1 }) => !data.some(({ id_produk: id2 }) => id2 === id1));
        console.log("Datass", newArr);
        let ops = []
        newArr.map((v) => {
          let opt = {
            label: v.nama_produk,
            text: v.nama_produk,
            value: v.id_produk,
            id_produk: v.id_produk,
            satuan: v.satuan,
            ready: (v.masuk - v.keluar) - v.alokasi,
            total: (v.masuk - v.keluar) + v.alokasi,
            alokasi: v.alokasi,
            masuk: v.masuk,
            keluar: v.keluar,
            validasi: 0,
            fisik: 0,
            selisih: 0
          }
          return ops = [...ops, opt]
        });
        setOpsi(ops)
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData({ cabang, date })

    if (data.length === 0) {
      fetchDetail(dtMaster.state?.id_opname).then((res) => {
        console.log("Res FETCHHHHHH", data);
      })
    }

    if (data.length > 0 && dtMaster.state?.form) {
      let dt = []
      console.log("Dataaa", data);
      data.map((val) => {
        console.log("Val", val);
        let newDt = {
          id_produk: val.id_produk,
          id: val.id,
          nama: val.nama,
          masuk: val.masuk,
          keluar: val.keluar,
          alokasi: val.alokasi,
          total: val.total,
          satuan: val.satuan,
          fisik: val.fisik,
          selisih: val.selisih,
          status_validasi: val.status_validasi,
          actions: <Button
            disabled={val.status_validasi === 1 ? true : false}
            onClick={() => {
              onDeleteData(val.id)
            }}><i className="fa fa-trash"></i></Button>
        }
        return dt = [...dt, newDt]
      })
      setData(dt)
    }
  }, [refresh])


  return (
    <>
      <AnimatedCard>
        <CardHeader title={"Stok Opname Detail"}>
          <CardHeaderToolbar>
            <Button
              type="button"
              className="btn btn-secondary mx-1"
              onClick={() => history.goBack()}
            >
              <i className="fa fa-arrow-left"></i>
              Kembali
            </Button>
            {dtMaster.state?.form === true ?
              <Button
                onClick={() => {
                  setShow(true)
                }}
              >
                Add Item
              </Button> : ""
            }
          </CardHeaderToolbar>
        </CardHeader>
        {/* Modal */}
        <Modal show={show} onHide={handleClose} style={{ textAlign: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Item</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <div className="row-xl-4" style={{ marginTop: "0px", marginBottom: "10px" }}>
              <label > Pilih Item </label>
              <Select2
                name={"opsi"}
                placeholder={"--- Pilih Item ---"}
                size="sm"
                options={opsi}
                isClearable={false}
                value={
                  opsi ? opsi.find((option) => option.value === slcItem) : ""
                }
                onChange={(e) => {
                  console.log("Value Select", e ? e : "Kosong");
                  setItem({
                    slcItem: e ? e.value : "",
                  });
                  detailData(e)
                }}
              />
            </div>
            <div className='d-flex'>
              <div className='col-lg-6 mr-auto'>
                <label>Stok Masuk</label>
                <input className="form-control" type="number" value={masuk} disabled></input>
                <label>Stok Keluar</label>
                <input className="form-control" type="number" value={keluar} disabled></input>
                <label>Stok Alokasi</label>
                <input className="form-control" type="number" value={alokasi} disabled></input>
              </div>
              <div className='col-lg-6'>
                <label>Stok Aktual</label>
                <input className="form-control" type="number" value={total} disabled></input>
                <label>Stok Fisik</label>
                <input className="form-control" type="number" value={fisik}
                  onChange={(e) => {
                    setDetail({
                      ...detail,
                      fisik: parseInt(e.target.value),
                      selisih: total - parseInt(e.target.value)
                    })
                    // console.log("E on change",e.target.value);
                  }}
                ></input>
                <label>Selisih</label>
                <input className="form-control" type="number" value={selisih} disabled></input>
                <label>Satuan</label>
                <input className="form-control" type="text" value={satuan} disabled></input>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={() => {
              onAddData(detail)
            }}>
              Add
            </Button>
          </Modal.Footer>
          {/* {console.log("Cek detail",detail)} */}
        </Modal>
        <CardBody>
          {data.length > 0 ?
            <TableWithoutPagination data={data} columns={columns} />
            : <h3>Empty Data</h3>}
          <div className='d-flex' style={{ marginTop: '15px' }}>
            <div className='col-lg-6'>
              <label>Total Stok </label>
              <input className="form-control" type="number" value={totalStokAktual} disabled></input>
              <label>Total Selisih </label>
              <input className="form-control" type="number" value={totalSelisih} disabled></input>
            </div>
            <div className='col-lg-6'>
              <label>Total Stok Fisik</label>
              <input className="form-control" type="number" value={totalStok} disabled></input>
            </div>
          </div>
        </CardBody>
        {data.length > 0 && dtMaster.state?.form === true ?
          <CardFooter>
            <div className="d-flex justify-content-end">
              {cekValidasi ? "" :
                <Button
                  className='mr-4'
                  type='button'
                  onClick={async () => {
                    let master = {
                      id_opname: dtMaster.state?.id_opname,
                      no_transaksi: dtMaster.state?.no_transaksi,
                      id_perusahaan: cabang,
                      id_user: store.getState().auth.id,
                      tanggal: dtMaster.state?.tanggal
                    }
                    // let details = data
                    await new StokOpnameAPI().closeOpname({ master }).then((res) => {
                      if (res.status === true) {
                        dispatcher.snackbar.show("Berhasil", "Data Berhasil Disimpan", "primary");
                        setTimeout(() => {
                          history.goBack()
                        }, 1000)
                      }
                    })
                  }}
                >
                  <i className="fa fa-window-close"></i>
                  Close Opname
                </Button>
              }
              <Button
                type='button'
                onClick={async () => {
                  let master = {
                    id_opname: dtMaster.state?.id_opname,
                    no_transaksi: dtMaster.state?.no_transaksi,
                    id_perusahaan: cabang,
                    tanggal: dtMaster.state?.tanggal
                  }
                  // let details = data
                  const results = data.filter(({ id_produk: id1, selisih: slsh1 }) => !dtDb.some(({ id_produk: id2, selisih: slsh2 }) => id2 === id1 && slsh2 === slsh1));
                  const results2 = dtDb.filter(({ id_produk: id1, selisih: slsh1 }) => !data.some(({ id_produk: id2, selisih: slsh2 }) => id2 === id1 && slsh2 === slsh1));
                  console.log(results, results2);
                  if (results.length > 0 || results2.length > 0) {
                    await new StokOpnameAPI().setDetail({ master, data }).then((res) => {
                      if (res.status === true) {
                        dispatcher.snackbar.show("Berhasil", "Data Berhasil Disimpan", "primary");
                        setTimeout(() => {
                          history.goBack()
                        }, 1000)
                      }
                    })
                  } else {
                    dispatcher.snackbar.show("Error", "Tidak Ada Perubahan Data", "danger");
                  }
                }}
              >
                <i className="fa fa-save"></i>
                Simpan
              </Button>
            </div>
          </CardFooter>
          : ""}
      </AnimatedCard>
    </>
  )
}

export default FormDetail
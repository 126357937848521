import { getIdPerusahaan, simpleFetch } from "./api";

export class KontakJenisAPI {
  async getAll() {
    let res = await simpleFetch({
      url: "retail/kontak/jenis?id_perusahaan=" + getIdPerusahaan(),
      method: "GET",
    });
    return res;
  }

  async getSingle(id) {
    let res = await simpleFetch({
      url: "retail/kontak/jenis?id=" + id,
      method: "GET",
    });
    return res;
  }

  async set(values) {
    let res = await simpleFetch({
      url: "retail/kontak/jenis",
      method: "POST",
      body: {
        id_perusahaan: getIdPerusahaan(),
        nama: values.nama,
      },
    });
    return res;
  }

  async edit(values) {
    let res = await simpleFetch({
      url: "retail/kontak/jenis",
      method: "PUT",
      body: {
        id: values.id,
        id_perusahaan: getIdPerusahaan(),
        nama: values.nama,
      },
    });
    return res;
  }

  async delete(id) {
    let res = await simpleFetch({
      url: "retail/kontak/jenis",
      method: "DELETE",
      body: {
        id: id,
      },
    });
    return res;
  }
}

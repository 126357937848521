import { getCoverageArea, simpleFetch } from "./api";
export class BagKeluarAPI {
  async getBagKeluar(values) {
    console.log('VALUES API', values);
    let params = "id_perusahaan=";
    // if (values.length !== 1) {
    // params += values.join("&id_perusahaan=");
    // } else {
    params += values;
    // }
    let res = await simpleFetch({
      url: "retail/stok/obag?" + params,
      method: "GET",
    });
    return res;
  }

  async set(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/stok/obag",
        method: "POST",
        body: {
          master: {
            ...values,
          }
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async setDetail(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/stok/obag-detail",
        method: "POST",
        body: {
          master: {
            ...values.master
          },
          detail: values.data
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async getDetail(values) {
    let res;
    try {
      res = await simpleFetch({
        url: `retail/stok/obag-detail?id_bag_keluar=${values}`,
        method: "GET",
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async getAllDetail(val) {
    let res;
    try {
      res = await simpleFetch({
        url: `retail/stok/all-obag-detail?id_perusahaan=${val}`,
        method: "GET",
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async closeBagKeluar(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/stok/close-obag",
        method: "POST",
        body: {
          master: {
            ...values.master
          },
          user: values.user
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async vldtDtlBagKeluar(values) {
    console.log("Api Val",values);
    let res;
    try {
      res = await simpleFetch({
        url: "retail/stok/validasi-obag-detail",
        method: "POST",
        body: {
          master: {
            ...values.arr
          },
          user: values.user
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }
  async getCoverage() {
    let res = await simpleFetch({
      url: "retail/select/perusahaan?id_coverage=" + getCoverageArea(),
      method: "GET",
    });
    return res;
  }
}

import { separatorHarga } from "../../../services/separator-harga";
import store from "../../../redux/store";

export default class penerimaanBankOperasionalDetailModel {
  constructor(
    data = {
      id: 0,
      id_akun: "",
      kode_akun: "",
      nama_akun: "",
      deskripsi: "",
      jumlah: "",
      ceklist: false,
      id_perusahaan: store.getState()['auth']['id_perusahaan'],
      status: 1
    }
  ) {
    const { id, id_akun, kode_akun, nama_akun, deskripsi, jumlah, ceklist, id_perusahaan, status } = data;
    this['id'] = id;
    this['id_akun'] = id_akun;
    this['kode_akun'] = kode_akun;
    this['nama_akun'] = nama_akun;
    this['deskripsi'] = deskripsi;
    this['jumlah'] = jumlah;
    this['ceklist'] = ceklist;
    this['id_perusahaan'] = id_perusahaan;
    this['status'] = status;
  }
  async getdata() {
    const { kode_akun, nama_akun, deskripsi, jumlah, ceklist } = this;
    return [
      {
        id: "kode_akun",
        title: "Kode Akun",
        label: kode_akun,
        center: true,
        minWidth: "15%",
      }, {
        id: "nama_akun",
        title: "Nama Akun",
        label: nama_akun,
        center: true,
        minWidth: "20%",
      }, {
        id: "deskripsi",
        title: "Keterangan",
        label: deskripsi,
        minWidth: "60%",
      }, {
        id: "jumlah",
        title: "Jumlah",
        label: (ceklist || jumlah < 0) ? "Rp. -" + separatorHarga(jumlah.toString()) : separatorHarga(jumlah.toString(), "Rp. "),
        right: true,
        minWidth: "15%",
      }
    ];
  }
}
const initialValues = {
  active: null,
  list: [],
};

export const pesananPenjualanAction = {
  list: "LIST_PESANAN_PENJUALAN",
  active: "ACTIVE_PESANAN_PENJUALAN",
  resetActive: "RESET_ACTIVE_PESANAN_PENJUALAN",
};

const reducer = (state = initialValues, action) => {
  let states;
  switch (action.type) {
    case pesananPenjualanAction.list:
      states = state;
      states.list = action.payload;
      return (state = states);
    case pesananPenjualanAction.active:
      states = state;
      states.active = action.payload;
      return (state = states);
    case pesananPenjualanAction.resetActive:
      states = state;
      states.active = null;
      return (state = states);
    default:
      return state;
  }
};

export default reducer;

import { getIdPerusahaan, simpleFetch, getCoverageArea } from "./api";
import store from "../redux/store";
import { dispatcher } from "../redux/dispatcher";

export class ProdukAPI {
  async getAll() {
    let coverage = store.getState().auth.coverage;
    let params = "id_perusahaan=";
    if (coverage.length > 0) {
      params += coverage.join("&id_perusahaan=");
    } else {
      params += getIdPerusahaan();
    }
    let res = await simpleFetch({
      url: "retail/produk?" + params,
      method: "GET",
    });
    return res;
  }

  async getSingle(id) {
    let res = await simpleFetch({
      url: "retail/produk?id=" + id + "&id_perusahaan=" + getIdPerusahaan(),
      method: "GET",
    });
    return res;
  }

  async getGudangProduk(id) {
    let res = await simpleFetch({
      url: "retail/produk/gudang?id=" + id,
      method: "GET",
    });
    return res;
  }
  async getAllProdukGudang() {
    let res = await simpleFetch({
      url: "retail/produk/gudang_master?id_perusahaan=" + getIdPerusahaan(),
      method: "GET",
    });
    return res;
  }

  async set(values) {
    values.removeSeparator();
    try {
      await simpleFetch({
        url: "retail/produk",
        method: "POST",
        body: {
          id_user: store.getState().auth.id,
          // id_perusahaan: getIdPerusahaan(),
          ...values,
        },
      }).then((res) => {
        console.log("Response API",res);
        dispatcher.snackbar.show("Berhasil", `${res.message}`, "primary")
      }
      );
    } catch (error) {
      console.log("Response Error",error)
      dispatcher.snackbar.show("Error", `${error.message}`, "danger");
    }
  }

  async edit(values) {
    values.removeSeparator();
    let res = await simpleFetch({
      url: "retail/produk",
      method: "PUT",
      body: {
        id_user: store.getState().auth.id,
        // id_perusahaan: getIdPerusahaan(),
        ...values,
      },
    });
    return res;
  }

  async delete(id) {
    let res = await simpleFetch({
      url: "retail/produk",
      method: "DELETE",
      body: {
        id: id,
      },
    });
    return res;
  }

  async getCoverage() {
    let res = await simpleFetch({
      url: "retail/select/perusahaan?id_coverage=" + getCoverageArea(),
      method: "GET",
    });
    return res;
  }
}

import { CardHeader, CardBody, CardFooter } from "../../../../../../_metronic/_partials/controls";
import React, { Component } from "react";
import { AnimatedCard } from "../../../../../components/animated-card/animatedCard";
import DataTableModel from "../../../../../models/datatable-model/DataTableModel";
import { Formik } from "formik";
import { FormsFields } from "../../../../../components/forms-fields/FormsFields";
import { ReactDatatable } from "../../../../../components/react-datatable/reactDatatable";
import { dispatcher } from "../../../../../redux/dispatcher";
import { export_excel } from "../../../../../components/export-excel/export_excel";
import { getIdPerusahaan } from "../../../../../api/api";
import { kasirKasAPI } from "../../../../../api/kasir-kas";
import pengeluaranKasModel from "../../../../../models/kasir/kas/pengeluaranKasModel";
import SimpleAlert from "../../../../../components/alert/alert";
import ButtonActions from "../../../../../components/button-actions/buttonActions";
import ButtonLoad from "../../../../../components/button-loading/buttonLoad";
import moment from "moment";

export default class ListPengeluaranKas extends Component {
  constructor(props) {
    super(props);
    this['pageName'] = "List Pengeluaran Kas";
    this['apiPengeluaranKas'] = new kasirKasAPI();
    this['searchParameter'] = ["no_transaksi", "no_bku", "id_perusahaan", "kontak_ref", "tanggal", "akun", "total", "deskripsi"];
    this['export_excel'] = new export_excel();
    this['newDateMonth'] = (new Date().getMonth() + 1).toString();
    this['month'] = this['newDateMonth'].length > 1 ? this['newDateMonth'] : "0" + this['newDateMonth'];
    this['options_cabang'] = [];
    this['state'] = {
      pending: true,
      initialValues: {
        begin_date: new Date(this['month'] + "-01-" + new Date().getFullYear()),
        end_date: new Date(),
        id_perusahaan: getIdPerusahaan(),
        no_transaksi: "",
        kepada: "",
      },
      data: [],
      alert: {
        activeId: null,
        show: false,
      },
    };
    this['datatable'] = [{ id: "", no: "", name: "" }];
    this['columntable'] = [{ id: "", title: "" }];
  }
  //* ----------------------------------------------------------------------------- */
  //** Start: Component */
  async componentDidMount() {
    await this.LoadData(this['state']['initialValues']);
    // Get Cabang Coverage
    this['apiPengeluaranKas'].getCoverage().then((values) => {
      this['options_cabang'] = values['data'].map((data) => ({
        label: data['text'],
        value: data['id'],
      }));
    });
  }
  //** End: Component */
  //* ----------------------------------------------------------------------------- */
  //** Start: Load Data List */
  async LoadData(form) {
    this.setState({ pending: true });
    await dispatcher['kasir_kas'].listPengeluaranKas(form);
    let data = dispatcher['kasir_kas'].getListPengeluaranKas();
    this.setState({ data: data });
    this['DataTableModel'] = new DataTableModel();
    data.map((item, i) => {
      let dataModel = new pengeluaranKasModel(item);
      if (item['transaksi']['status'] === 1)
        this['DataTableModel'].add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: (
            <>
              <ButtonActions
                label={"detailsLink"}
                linkTo={"/kasir/daftar-pengeluaran-kas/detail"}
                onClick={() => {
                  dispatcher['kasir_kas'].setPengeluaranKas(dataModel);
                }}
              />
              {item['transaksi']['is_approved'] === 0 && (
                <ButtonActions
                  label={"delete"}
                  onClick={async () => {
                    this['handleAlert'].show(item['transaksi']['id']);
                  }}
                />
              )}
            </>
          ),
        });
      return item;
    });
    this['columntable'] = await this['DataTableModel'].getColumn();
    this['datatable'] = await this['DataTableModel'].getDatas();
    this.setState({ ...this['state'], pending: false });
  }
  //** End: Load Detail Table */
  //* ----------------------------------------------------------------------------- */
  //** Start: Handle Alert */
  handleAlert = {
    show: (id) => {
      let alert = { ...this['state'] };
      alert = {
        alert: {
          show: true,
          activeId: id
        }
      }
      this.setState({ ...alert });
    },
    hide: () => {
      let alert = { ...this['state'] };
      alert = {
        alert: {
          show: false,
          activeId: null
        }
      }
      this.setState({ ...alert });
    },
    confirmed: async () => {
      await this['apiPengeluaranKas'].delete(this['state']['alert']['activeId']).then((res) => {
        if (res['status']) {
          dispatcher['snackbar'].show("Berhasil", res.message, "primary");
          this['handleAlert'].hide();
          this.LoadData(this['state']['initialValues']);
        } else {
          return dispatcher['snackbar'].show("Gagal", res.message, "danger");
        }
      });
    },
  };
  //** End: Handle Alert */
  //* ----------------------------------------------------------------------------- */
  render() {
    const { pending, initialValues, data, alert } = this['state'];
    return (
      <>
        {/* Start: Formik List */}
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            this.LoadData(values);
          }}
        >
          {({ setFieldValue, setFieldTouched, touched, errors, values, handleSubmit }) => (
            <AnimatedCard>
              <CardHeader title={this['pageName']}></CardHeader>
              <CardBody style={{ marginTop: "-20px" }}>
                <div className={"form-group row"}>
                  {/* Forms Start Date  */}
                  <FormsFields
                    id={"begin_date"}
                    type={"DatePicker"}
                    label={"Tanggal Mulai"}
                    className={"col-lg-4"}
                    style={{ marginTop: "20px" }}
                    paramsFormik={{
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    }}
                  />
                  {/* Forms End Date  */}
                  <FormsFields
                    id={"end_date"}
                    type={"DatePicker"}
                    label={"Tanggal Akhir"}
                    className={"col-lg-4"}
                    style={{ marginTop: "20px" }}
                    paramsFormik={{
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    }}
                  />
                  {/* Forms Select Cabang */}
                  <FormsFields
                    id={"id_perusahaan"}
                    type={"select2"}
                    label={"Cabang"}
                    style={{ marginTop: "20px" }}
                    className={"col-lg-4"}
                    isClearable={true}
                    isSearchable={true}
                    options={this['options_cabang']}
                    paramsFormik={{
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    }}
                  />
                  {/* No. Transaksi/No. BKU */}
                  <FormsFields
                    id={"no_transaksi"}
                    type={"text"}
                    label={"No. Transaksi/No. BKU"}
                    className={"col-lg-4"}
                    style={{ marginTop: "20px" }}
                    paramsFormik={{
                      setFieldValue,
                      values
                    }}
                  />

                  {/* Kepada */}
                  <FormsFields
                    id={"kepada"}
                    type={"text"}
                    label={"Kepada"}
                    className={"col-lg-4"}
                    style={{ marginTop: "20px" }}
                    paramsFormik={{
                      setFieldValue,
                      values
                    }}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div>
                    {/* Button Lihat Data */}
                    <ButtonLoad
                      label={"Lihat Data"}
                      pending={pending}
                      classNameIcon={"fa fa-filter"}
                      className={"btn btn-outline-success"}
                      onClick={async () => handleSubmit()}
                    />
                    {/* Button Export Excel */}
                    <ButtonLoad
                      label={"Export .xls"}
                      pending={pending}
                      classNameIcon={"far fa-file-excel"}
                      className={"btn btn-outline-primary"}
                      disabled={data.length === 0}
                      onClick={async () => {
                        let startDate = moment(values['begin_date']).format("L");
                        let endDate = moment(values['end_date']).format("L");
                        let times = moment().format('h.mm.ss a');
                        const dateFilter = (startDate === endDate) ? startDate : startDate + " - " + endDate;

                        let record = [];
                        data.map((val, i) => {
                          const { no_transaksi, no_transaksi_bku, tanggal_approved, tanggal, cabang, kontak_ref, akun, total, deskripsi } = val['transaksi'];
                          record.push({
                            no_transaksi: no_transaksi,
                            tanggal: tanggal ? moment(tanggal).format("DD-MM-YYYY") : "",
                            no_transaksi_bku: no_transaksi_bku,
                            tanggal_approved: tanggal_approved ? moment(tanggal_approved).format("DD-MM-YYYY") : "",
                            cabang: cabang,
                            kontak_ref: kontak_ref,
                            akun: akun,
                            total: total,
                            deskripsi: deskripsi,
                          });
                          return val;
                        });

                        let datas = {
                          headerTitle: "PENGELUARAN KAS",
                          title1: "",
                          title2: "",
                          headerField: ["NO. TRANSAKSI", "TANGGAL", "NO. BUKTI BKU", "TANGGAL APPROVED", "CABANG", "KEPADA", "NAMA AKUN", "TOTAL", "KETERANGAN"],
                          headerField2: [],
                          recordData: record,
                          recordData2: [],
                          totalSaldoAwal: false,
                          totalSaldoAkhir: false,
                          footerSubTotal: [],
                          footerSubTotal1: [],
                          columnWidth: [35, 17, 35, 23, 23, 40, 20, 20, 80],
                          centerAlign: ["A", "B", "C", "D", "E", "G"],
                          wrapText: ["F", "I"],
                          numberFormatRp: ["H"],
                          fileName: `Kasir Pengeluaran Kas (${dateFilter} - ${times})`
                        }
                        this['export_excel'].saveAsExcel(datas);
                      }}
                    />
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <ReactDatatable
                  title={""}
                  columns={this['columntable']}
                  data={this['datatable']}
                  searchParameter={this['searchParameter']}
                  progressPending={pending}
                />
              </CardFooter>
            </AnimatedCard>
          )}
        </Formik>
        {/* End: Formik List */}
        {/* Start: Alert Hapus */}
        <SimpleAlert
          title={"Hapus Data"}
          content={"Anda yakin hapus data ini...?"}
          show={alert['show']}
          onHide={() => this['handleAlert'].hide()}
          onConfirmed={this['handleAlert']['confirmed']}
        />
        {/* End: Alert Hapus */}
      </>
    );
  }
}

import { Spinner } from "react-bootstrap";

import React from "react";

export default function ButtonLoad({
  label,
  pending,
  classNameIcon,
  className,
  onClick,
  disabled,
}) {
  return (
    <>
      <button
        type={"button"}
        className={`${className} mx-1 btn-sm`}
        disabled={disabled ?? false}
        onClick={onClick}
      >
        <span>
          {(!pending) ?
            (<>
              <i className={classNameIcon}></i> {" " + label}
            </>) : (<>
              <Spinner
                as={"span"}
                animation={"border"}
                size={"sm"}
                role={"status"}
                aria-hidden={"true"}
              /> {" Loading..."}
            </>)
          }
        </span>
      </button>
    </>
  );
}

import { separatorHarga } from "../../../services/separator-harga";
import DetailHistoryPembelianCustomerModel from "./detailHistoryPembelianCustomerModel";

export default class PesananPenjualanDetailModel {
  constructor(
    data = {
      detail: {
        id: 0,
        nama_produk: "",
        nilai_satuan: "",
        dpp: 0,
        harga_ppn: 0,
        qty: 0,
        hpp_total: 0,
        diskon_item: 0,
        diskon: 0,
        diskon_persen: "",
        subtotal: 0,
        pajak: 0,
        harga_margin: 0,
        total: 0,
      },
      historyPembelian: []
    }
  ) {
    const { id, nama_produk, nilai_satuan, dpp, harga_ppn, qty, hpp_total, diskon_item, diskon, diskon_persen, subtotal, pajak, harga_margin, total } = data['detail'];
    this['detail'] = {
      id: id,
      nama_produk: nama_produk,
      nilai_satuan: nilai_satuan,
      dpp: dpp,
      harga_ppn: harga_ppn,
      qty: qty,
      hpp_total: hpp_total,
      diskon_item: diskon_item,
      diskon: diskon,
      diskon_persen: diskon_persen,
      subtotal: subtotal,
      pajak: pajak,
      harga_margin: harga_margin,
      total: total,
    };
    this['historyPembelian'] = [];
    this.initDetails(data['historyPembelian']);
  }

  initDetails(detail) {
    if (detail.length > 0)
      detail.forEach((item) => {
        this['historyPembelian'] = [
          ...this['historyPembelian'],
          new DetailHistoryPembelianCustomerModel({
            ...item
          }),
        ];
      });
  }

  async getdata() {
    const { nama_produk, nilai_satuan, dpp, harga_ppn, qty, hpp_total, diskon_item, diskon, diskon_persen, subtotal } = this['detail'];
    return [{
      id: "nama_produk",
      title: "Nama Produk",
      label: nama_produk,
      sortable: true,
      minWidth: "25%",
    }, {
      id: "nilai_satuan",
      title: "Nilai Satuan",
      label: nilai_satuan,
      center: true,
      sortable: true,
      minWidth: "15%",
    }, {
      id: "dpp",
      title: "Harga Non PPN",
      label: dpp < 0 ? `Rp. -${separatorHarga(dpp.toString())}` : separatorHarga(dpp.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "harga_ppn",
      title: "Harga + PPN",
      label: harga_ppn < 0 ? `Rp. -${separatorHarga(harga_ppn.toString())}` : separatorHarga(harga_ppn.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "qty",
      title: "QTY",
      label: qty,
      center: true,
      sortable: true,
      minWidth: "10%",
    }, {
      id: "hpp_total",
      title: "Harga Total",
      label: hpp_total < 0 ? `Rp. -${separatorHarga(hpp_total.toString())}` : separatorHarga(hpp_total.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "diskon_item",
      title: "Diskon/Item",
      label: diskon_item < 0 ? `Rp. -${separatorHarga(diskon_item.toString())}` : separatorHarga(diskon_item.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "diskon",
      title: "Diskon",
      label: diskon < 0 ? `Rp. -${separatorHarga(diskon.toString())}` : separatorHarga(diskon.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "diskon_persen",
      title: "Diskon %",
      label: diskon_persen,
      center: true,
      sortable: true,
      minWidth: "10%",
    }, {
      id: "subtotal",
      title: "Sub Total",
      label: subtotal < 0 ? `Rp. -${separatorHarga(subtotal.toString())}` : separatorHarga(subtotal.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }];
  }
}

import store from "../app/redux/store";
import { messageAction } from "../app/redux/message/actions";

export async function ResponseException(e) {
  if (!e.status) {
    store.dispatch({
      type: messageAction.setMessage,
      payload: { variant: "danger", title: "Error", message: e.message },
    });
  }

  return e;
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { CardBody, CardFooter, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls';
import { AnimatedCard } from '../../../components/animated-card/animatedCard';
import { Button } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import store from '../../../redux/store';
import { TableWithSelect } from '../../../components/table/Table';
import { dispatcher } from '../../../redux/dispatcher';
import { BagKeluarAPI } from '../../../api/bag-keluar';

function ApprovalBagKeluar() {
    const history = useHistory();
    const idPerusahaan = store.getState().auth.id_perusahaan;
    const [data, setData] = useState([]);
    const [arr, setArr] = useState([]);
    const user = store.getState().auth.id;
    const [refresh, setRefresh] = useState(false);
    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            if (isSelect) {
                setArr(arr => [...arr, row])
            }
            if (!isSelect) {
                const newArr = arr.filter(object => {
                    return object.id !== row.id;
                });
                setArr(newArr)
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            if (isSelect) {
                setArr(rows)
            }
            if (!isSelect) {
                setArr([])
            }
            console.log("Rows ID", rows.id);
        }
    };
    const columns = [
        { dataField: 'id', text: 'Id', sort: true, hidden: true },
        { dataField: 'nama', text: 'Nama Produk', sort: true },
        { dataField: 'no_transaksi', text: 'No Transaksi', sort: true },
        { dataField: 'satuan', text: 'Satuan', sort: true },
        { dataField: 'total', text: 'Total Stok', sort: true },
        { dataField: 'fisik', text: 'Stok Fisik', sort: true },
        { dataField: 'selisih', text: 'Selisih', sort: false },
        { dataField: 'alokasi', text: 'Total Alokasi', sort: true },
        { dataField: 'masuk', text: 'Stok Masuk', sort: true },
        { dataField: 'keluar', text: 'Stok Keluar', sort: true },
    ];

    const fetchData = async (val) => {
        try {
            await new BagKeluarAPI().getAllDetail(val).then((res) => {
                console.log("Data", res.data);
                let dt = []
                res.data.map((val, i) => {
                    let tempDt = {
                        id_produk: val.id_produk,
                        id_gudang: val.id_gudang,
                        id_perusahaan: val.id_perusahaan,
                        id_bag_keluar: val.id_bag_keluar,
                        id: val.id,
                        nama: val.nama,
                        no_transaksi: val.no_transaksi,
                        masuk: val.masuk,
                        keluar: val.keluar,
                        alokasi: val.alokasi,
                        total: val.total,
                        satuan: val.satuan,
                        fisik: val.fisik,
                        harga_beli: val.harga_beli,
                        selisih: val.selisih,
                        status_validasi: val.status_validasi,
                    }
                    return dt = [...dt, tempDt]
                });
                setData(dt)
            });
        } catch (error) {
            console.error(error);
        }
    };
    useEffect(() => {
        fetchData(idPerusahaan)
    }, [refresh])


    return (
        <>
            <AnimatedCard>
                <CardHeader title={"Validasi BAG Keluar"}>
                    <CardHeaderToolbar>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    {data.length > 0 ?
                        <TableWithSelect dt={data} col={columns} slc={selectRow} />
                        : <h3>Belum Ada Data</h3>}
                </CardBody>
                {arr.length > 0 ?
                    <CardFooter>
                        <div className="d-flex justify-content-end">
                            <Button
                                type='button'
                                onClick={async () => {
                                    await new BagKeluarAPI().vldtDtlBagKeluar({arr,user}).then((res) => {
                                        if (res.status === true) {
                                            dispatcher.snackbar.show("Berhasil", "Data Berhasil Disimpan", "primary");
                                            setArr([])
                                            setTimeout(() => {
                                                setRefresh(!refresh)
                                            }, 1000)
                                        }
                                    })
                                }}
                            >
                                <i className="fa fa-save"></i>
                                Simpan
                            </Button>
                        </div>
                    </CardFooter>
                    : ""}
            </AnimatedCard>
        </>
    )
}

export default ApprovalBagKeluar
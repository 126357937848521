import { getIdPerusahaan, simpleFetch, FormDataFetch } from "./api";

export class CustomerAPI {
  async getAll() {
    let res = await simpleFetch({
      url: "retail/kontak/customer?id_perusahaan=" + getIdPerusahaan(),
      method: "GET",
    });
    return res;
  }

  async getByIdPerusahaan(val) {
    let res = await simpleFetch({
      url: "retail/kontak/customer?id_perusahaan=" + val,
      method: "GET",
    });
    return res;
  }

  async getSingle(id) {
    let res = await simpleFetch({
      url: "retail/kontak/customer?id=" + id,
      method: "GET",
    });
    return res;
  }

  async set(values) {
    // const fd = new FormData();
    // for (const key in values) {
    //   fd.append(key, values[key]);
    // }
    // fd.append("id_perusahaan", getIdPerusahaan());
    let res = await simpleFetch({
      url: "retail/kontak/customer",
      method: "POST",
      body: values,
    });
    return res;
  }

  async edit(values) {
    let res = await simpleFetch({
      url: "retail/kontak",
      method: "PUT",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values,
      },
    });
    return res;
  }

  async delete(id) {
    let res = await simpleFetch({
      url: "retail/kontak",
      method: "DELETE",
      body: {
        id: id,
      },
    });
    return res;
  }
}

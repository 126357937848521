import { CardBody, CardFooter, CardHeader, CardHeaderToolbar } from "../../../../../_metronic/_partials/controls";
import React, { Component } from "react";

import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import { Button } from "react-bootstrap";
import DataTableModel from "../../../../models/datatable-model/DataTableModel";
import { Formik } from "formik";
import { FormsFields } from "../../../../components/forms-fields/FormsFields";
import { Link } from "react-router-dom";
import OrganisasiModel from "../../../../models/kontak/organisasi/OrganisasiModel";
import { ReactDatatable } from "../../../../components/react-datatable/reactDatatable";
import SimpleAlert from "../../../../components/alert/alert";
import { dispatcher } from "../../../../redux/dispatcher";
import { kontakAPI } from "../../../../api/kontak";
import store from "../../../../redux/store";

export default class ListOrganisasi extends Component {
  constructor(props) {
    super(props);
    this['pageName'] = "Organisasi";
    this['searchParameter'] = ["kode", "nama", "alamat", "telepon", "nama_bank", "nama_rekening", "no_rekening", "npwp", "nilai_top"];
    this['apiOrganisasi'] = new kontakAPI();
    this['initialData'] = new OrganisasiModel();
    this['state'] = {
      pending: true,
      role: store.getState().auth.role,
      initialValues: {
        status_approved_filter: 1,
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
    this['datatable'] = [{ id: "", no: "", name: "" }];
    this['columntable'] = [{ id: "", title: "" }];
  }

  async componentDidMount() {
    await this.LoadData(this['state']['initialValues']);
  }

  async LoadData(form) {
    this.setState({ pending: true });
    await dispatcher['kontak'].listOrganisasi(form);
    let data = dispatcher['kontak'].getListOrganisasi();
    this['DataTableModel'] = new DataTableModel();
    data.map((item, i) => {
      let dataModel = new OrganisasiModel(item);
      if (item['status'] === 1) {
        this['DataTableModel'].add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: (
            <>
              {this.state.role === 9 && item['is_approved'] >= 2 ? (
                <Link
                  className={"mx-1 btn btn-outline-success btn-sm"}
                  to={"/master-organisasi/edit-form"}
                  onClick={async () => dispatcher['kontak'].setOrganisasi(dataModel)}
                > Edit
                </Link>
              ) : null }
              {/* Info */}
              {item['is_approved'] >= 2 ? (
                <Button
                  size={"sm"}
                  style={{ cursor: 'default' }}
                  className={"mx-1"}
                  variant={"primary"}
                  disabled>
                  <i className={"fas fa-user-check"}></i>
                  Sudah Approve
                </Button>
              ) : (
                <>
                  {/* Edit Form */}
                  <Link
                    className={"mx-1 btn btn-outline-success btn-sm"}
                    to={"/master-organisasi/edit-form"}
                    onClick={async () => dispatcher['kontak'].setOrganisasi(dataModel)}
                  > Edit
                  </Link>
                  {/* Delete */}
                  <Button
                    size={"sm"}
                    className={"mx-1"}
                    variant={"outline-danger"}
                    onClick={async () => {
                      this['handleAlert'].show(item['id']);
                      // this.LoadData();
                    }}
                  > Hapus
                  </Button>
                </>
              )}
            </>
          ),
        });
      }
      return item;
    });
    this['columntable'] = await this['DataTableModel'].getColumn();
    this['datatable'] = await this['DataTableModel'].getDatas();
    this.setState({ ...this['state'], pending: false });
  }

  // Handle Alert
  handleAlert = {
    show: (id) => {
      let alert = { ...this['state'] };
      alert = {
        alert: {
          show: true,
          activeId: id
        }
      }
      this.setState({ ...alert });
    },
    hide: () => {
      let alert = { ...this['state'] };
      alert = {
        alert: {
          show: false,
          activeId: null
        }
      }
      this.setState({ ...alert });
    },
    confirmed: async () => {
      await this['apiOrganisasi'].deleteOrganisasi(this['state']['alert']['activeId']).then((res) => {
        if (res['status']) {
          dispatcher['snackbar'].show("Berhasil", res.message, "primary");
          this['handleAlert'].hide();
          this.LoadData(this['state']['initialValues']);
        } else {
          return dispatcher['snackbar'].show("Gagal", res.message, "danger");
        }
      });
    },
  };

  render() {
    const { pending, initialValues, alert } = this['state'];
    return (
      <>
        {/* List */}
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            this.LoadData(values);
          }}
        >
          {({ setFieldValue, setFieldTouched, touched, errors, values, handleSubmit, handleChange }) => (
            <AnimatedCard>
              <CardHeader title={this['pageName']}>
                <CardHeaderToolbar>
                  <Link
                    className={"btn btn-primary"}
                    to={"/master-organisasi/add-form"}
                    onClick={() => {
                      dispatcher['kontak'].resetActiveOrganisasi()
                    }}
                  > <i className={"fa fa-plus"}></i> Tambah
                  </Link>
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody style={{ marginTop: "-20px" }}>
                <div className={"form-group row"}>
                  {/* Forms Status Filter */}
                  <FormsFields
                    id={"status_approved_filter"}
                    type={"select2"}
                    label={"Status Approve"}
                    style={{ marginTop: "20px" }}
                    className={"col-lg-4"}
                    isClearable={false}
                    isSearchable={false}
                    options={[
                      { value: 1, label: "Belum Approve" },
                      { value: 2, label: "Sudah Approve Awal" },
                      { value: 3, label: "Sudah Approve Akhir" },
                    ]}
                    onChange={(e) => {
                      handleSubmit();
                    }}
                    paramsFormik={{
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    }}
                  />
                </div>
              </CardBody>
              <CardFooter>
                <ReactDatatable
                  title={""}
                  columns={this['columntable']}
                  data={this['datatable']}
                  searchParameter={this['searchParameter']}
                  progressPending={pending}
                />
              </CardFooter>
            </AnimatedCard>
          )}
        </Formik>
        {/* Alert Hapus */}
        <SimpleAlert
          title={"Hapus Data"}
          content={"Anda yakin hapus data ini...?"}
          show={alert['show']}
          onHide={() => this['handleAlert'].hide()}
          onConfirmed={this['handleAlert']['confirmed']}
        />
      </>
    );
  }
}

import { usersAction } from "./actions";
import UsersModel from "../../models/auth-model/UsersModel";

const reducerUsers = ( state = new UsersModel( {} ), action) => {
  switch (action.type) {
    case usersAction.setUsers:
      return ( state = new UsersModel( action.payload ) );
    case usersAction.reset:
      return ( state = new UsersModel( {} ) );
    default:
      return state;
  }
};

export default reducerUsers;

import React, { useEffect, useState } from "react";

import ModalImage from "react-modal-image";

import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export function ImageUploader(params) {
  const getFieldCSSClasses = (touched, errors) => {
    const classes = [""];
    if (touched && errors) {
      classes.push("is-invalid");
    }
    if (touched && !errors) {
      classes.push("is-valid");
    }
    return classes.join(" ");
  };
  const [pic, setPic] = useState("");
  const [picUrl, setPicUrl] = useState("");
  useEffect(() => {
    if (params.paramsFormik['values'][params['id']]) {
      // For Link BackEnd Load Images
      setPicUrl(toAbsoluteUrl(params.paramsFormik['values'][params['id']]));
      // For Object URL Load Images
      if (params.paramsFormik['values'][params['id']]['name']) {
        setPicUrl(toAbsoluteUrl(URL.createObjectURL(params.paramsFormik['values'][params['id']])));
      }
    }
  }, [params]);


  const getPic = () => {
    if (!pic) {
      return `${toAbsoluteUrl("/media/users/imagesUpload.jpg")}`;
    }
    return `${pic}`;
  };
  const getPicUrl = () => {
    if (!picUrl) {
      return `${toAbsoluteUrl("/media/users/imagesUpload.jpg")}`;
    }
    return `${picUrl}`;
  };
  const removePic = () => {
    setPic("");
    setPicUrl("");
    params.paramsFormik['setFieldValue'](params['id'], null);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="row">
        <div className="col-md-auto">
          <div
            className="image-input image-input-outline"
            id="kt_profile_avatar"
            style={{
              backgroundColor: "#eee",
              // backgroundImage: `url(${toAbsoluteUrl(
              //   "/media/users/blank.png"
              // )}`,
            }}
          >
            {!pic ? (
              <>
                <ModalImage
                  className="image-input-wrapper"
                  small={`${getPicUrl()}`}
                  large={`${getPicUrl()}`}
                  showRotate={true}
                />
              </>
            ) : (
              <>
                {!picUrl ? (
                  <div
                    className="image-input-wrapper"
                    style={{
                      backgroundImage: `url(${getPic()})`
                    }}
                  />
                ) : (
                  <ModalImage
                    className="image-input-wrapper"
                    small={`${getPic()}`}
                    large={`${getPic()}`}
                    showRotate={true}
                  />
                )}
              </>
            )
            }
            <label
              className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
              data-action="change"
              data-toggle="tooltip"
              title=""
              data-original-title="Change avatar"
            >
              <i className="fa fa-pen icon-sm text-muted"></i>
              <input
                type="file"
                name={params.id}
                className={getFieldCSSClasses(params.paramsFormik['touched'][params.id], params.paramsFormik['errors'][params.id])}
                onBlur={() => {
                  params.paramsFormik['setFieldTouched']([params.id], true);
                }}
                multiple
                accept=".png, .jpg, .jpeg"
                onChange={(event) => {
                  if (event.target.files.length !== 0) {
                    setPic(URL.createObjectURL(event.target.files[0]));
                    setPicUrl(URL.createObjectURL(event.target.files[0]));
                    params.paramsFormik['setFieldValue'](params['id'], event.target.files[0]);
                  }
                }}
              />
              <input type="hidden" name="profile_avatar_remove" />
            </label>
            <span
              className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
              data-action="cancel"
              data-toggle="tooltip"
              title=""
              data-original-title="Cancel avatar"
            >
              <i className="ki ki-bold-close icon-xs text-muted"></i>
            </span>
            <span
              onClick={removePic}
              className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
              data-action="remove"
              data-toggle="tooltip"
              title=""
              data-original-title="Remove avatar"
            >
              <i className="ki ki-bold-close icon-xs text-muted"></i>
            </span>
          </div>
        </div>
        <div className="col-md-6">
          <span className="form-text text-muted">Upload</span>
          <label>{params.label}</label>
          <span className="form-text text-muted">
            Allowed file types: png, jpg, jpeg.
          </span>
          {params['paramsFormik']['errors'][params.id] && params['paramsFormik']['touched'][params.id] ? (
            <div className="form-text" style={{ color: "#F64E60" }}>
              {params['paramsFormik']['errors'][params.id].toString()}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

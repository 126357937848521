import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import FormOrganisasi from "../organisasi/form";
import ListOrganisasi from "../organisasi/list";
import React from "react";
import { dispatcher } from "../../../../redux/dispatcher";

export default function Organisasi() {
  const history = useHistory();
  const active = dispatcher['kontak'].getOrganisasi();
  return (
    <Switch>
      <Route path={"/master-organisasi/list"}>
        <ListOrganisasi />
      </Route>
      <Route path={"/master-organisasi/add-form"}>
        <FormOrganisasi history={history} isEdit={false} />
      </Route>
      {active['id'] === 0 && (
        <Redirect to={"/master-organisasi/list"} />
      )}
      <Route path={"/master-organisasi/edit-form"}>
        <FormOrganisasi history={history} isEdit={true} />
      </Route>
      <Redirect to="/master-organisasi/list" />
    </Switch>
  );
}

const initialValues = false;

export const loadingAction = {
  show: "SHOW_LOADING_DIALOG",
  hide: "HIDE_LOADING_DIALOG",
};

const reducerLoading = (state = initialValues, action) => {
  switch (action.type) {
    case loadingAction.show:
      return (state = true);
    case loadingAction.hide:
      return (state = false);
    default:
      return state;
  }
};

export default reducerLoading;

class ReactDataTableModel {
  constructor(data) {
    this['id'] = data['id'];
    this['no'] = data['no'];
    this['dataModel'] = data['dataModel'];
    this['actions'] = data['actions'];
  }
  async getData(readOnly) {
    let object = {};
    object["id"] = this['id'];
    object["no"] = this['no'];
    if (!readOnly) object["actions"] = this['actions'];
    await this['dataModel'].getdata().then((values) =>
      values.map((item) => {
        const { id, label } = item;
        return object[id] = label;
      })
    );
    return object;
  }

  async getColumn(readOnly) {
    let outp = [];
    await this['dataModel'].getdata().then((values) =>
      values.map((item) => {
        const { id, title, format, grow, width, minWidth, right, center, compact, ignoreRowClick, button, hide, omit, sortable, sortField, sortFunction, reorder, conditionalCellStyles } = item;
        outp = [
          ...outp,
          {
            id: id,
            name: title,
            selector: row => row[id],
            format: format,
            cell: row => row[id],
            grow: grow,
            width: width,
            minWidth: minWidth,
            maxWidth: "100%",
            right: right,
            center: center,
            compact: compact,
            ignoreRowClick: ignoreRowClick,
            button: button,
            wrap: true,
            allowOverflow: false,
            hide: hide,
            omit: omit,
            sortable: sortable,
            sortField: sortField,
            sortFunction: sortFunction,
            reorder: reorder,
            conditionalCellStyles: conditionalCellStyles,
          },
        ];
        return item;
      })
    );
    return readOnly ? [{
      id: "no",
      name: "No.",
      selector: row => row['no'],
      center: true,
      defaultSortAsc: true,
      sortable: true,
      maxWidth: '5px'
    }, ...outp] : [{
      id: "no",
      name: "No.",
      selector: row => row['no'],
      center: true,
      defaultSortAsc: true,
      sortable: true,
      maxWidth: '5px'
    }, ...outp, {
      id: "actions",
      name: "Actions",
      minWidth: "19%",
      maxWidth: "100%",
      center: true,
      defaultSortAsc: false,
      sortable: false,
      cell: row => row['actions']
    }];
  }
}

export default class DataTableModel {
  constructor(props) {
    this['datas'] = [];
    this['readOnly'] =
      props == null || props['readOnly'] == null ? false : props['readOnly'];
  }

  add(newData) {
    this['datas'] = [...this['datas'], new ReactDataTableModel(newData)];
  }

  async getDatas() {
    let outp = [];
    for (let i = 0; i < this['datas'].length; i++) {
      outp = [
        ...outp,
        { id: i, ...(await this['datas'][i].getData(this['readOnly'])) },
      ];
    }
    return outp;
  }

  async getColumn() {
    if (this['datas'].length <= 0)
      return [
        {
          id: "no",
          name: "",
        },
        {
          id: "actions",
          name: "",
        },
      ];
    else return await this['datas'][0].getColumn(this['readOnly']);
  }
}

import React, { useEffect } from 'react'
import { CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import { AnimatedCard } from '../../../components/animated-card/animatedCard'
import Chart from "react-apexcharts";
import { useState } from 'react';
import { Table } from 'react-bootstrap';
import { separatorHarga } from '../../../services/separator-harga';
import store from '../../../redux/store';
import Select2 from "react-select";
import { LaporanGrafik } from '../../../api/laporan-grafik';
import { Button } from 'react-bootstrap';
import { ReactDatatable } from '../../../components/react-datatable/reactDatatable';
import { saveAsExcel } from '../helperExportExcel';


function GrafikPembelianCabang() {
  const title = 'LaporanPembelianCabang';
  const searchParameter = ['name']
  const [bulan] = useState(['January', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'])
  
  const [options] = useState({
    yaxis: {
      labels: {
        formatter: function (value) {
          return "Rp." + separatorHarga(value.toString());
        }
      },
    },
    xaxis: {
      categories: bulan
    }
  })

  const ksa = [20, 46, 52, 69];
  const kpp = [68, 28, 70, 71];
  const ksb = [108, 105, 110, 109];

  const [pt] = useState([{
    value: ksa,
    label: "KSA"
  }, {
    value: kpp,
    label: "KPP"
  }, {
    value: ksb,
    label: "KSB"
  }])

  const [selectedPT, setSelectedPT] = useState(ksa.includes(store.getState().auth.id_perusahaan) ? ksa : kpp.includes(store.getState().auth.id_perusahaan) ? kpp : ksb);

  const [cabang, setCabang] = useState([0]);


  const [opsi, setOptions] = useState([
  ]);

  let opsiCabang = []

  const coverage = async () => {
    await new LaporanGrafik().getCoverage().then((res) => {
      let filter = res.data.filter((data) => selectedPT.includes(data.id))
      opsiCabang = filter.map((data) => (
        {
          label: data.text,
          text: data.text,
          value: data.id,
        }));
    });
    setOptions(opsiCabang);
  };

  const columns = [
    {
      name: 'Nama',
      selector: row => row.name,
      sortable: true,
      minWidth: '12%'
    }
  ];

  const [data, setData] = useState([])

  bulan.map((data, i) => {
    columns.push(
      {
        name: data,
        selector: row => "Rp." + separatorHarga(row.pembelian[i].toString()),
        sortable: true,
        minWidth: '12%'
      })
  })

  const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>
    <i className={"far fa-file-excel"}></i> {" Export .xls"}
  </Button>
  const actionsMemo = <Export onExport={() => saveAsExcel(data, title)} />

  const [series, setSeries] = useState([])

  const fetchData = async (data) => {
    try {
      await new LaporanGrafik().pembelianCabang(data).then((res) => res.data.map((val) => {
        // console.log(val);
        // let o = val.reduce((m, { nama_perusahaan: n, total_pembelian: v }) => ({ ...m, [n]: [...m[n] || [], v].flat(1) }), {})
        // let output = Object.entries(o).map(([n, v]) => ({ nama_perusahaan: n, total_pembelian: v }))
        // console.warn('Outpur new', output);
        const map = new Map(val.map(({ nama_perusahaan, count, total_pembelian }) => [nama_perusahaan, { nama_perusahaan, count: [], total_pembelian: [], }]));
        for (let { nama_perusahaan, count, total_pembelian } of val) {
          map.get(nama_perusahaan).count.push(...[count].flat())
          map.get(nama_perusahaan).total_pembelian.push(...[total_pembelian].flat())
        }
        let temp = []
        temp.push([...map.values()])
        temp.map((val) => {
          for (const item of val) {
            setSeries(series => [...series, {
              name: item.nama_perusahaan,
              data: item.total_pembelian
            }])
            // setPenjualan(penjualan => [...penjualan, {
            //   name: item.nama_perusahaan,
            //   penjualan: item.total_pembelian
            // }])
            setData(data => [...data, {
              id: Math.random(),
              name: item.nama_perusahaan,
              pembelian: item.total_pembelian,
            }])
          }
        })
      }));
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    // if (dataSeries.length === 0)
    // {
    if (cabang.includes(0)) {
      fetchData(selectedPT);
      // setIdPerusahaan(selectedPT)
    } else {
      fetchData(cabang);
      // setIdPerusahaan(cabang)
    }
    coverage();
    // }
  }, [cabang, selectedPT])
  return (
    <>
      <AnimatedCard>
        <CardBody>
          <div className="d-flex justify-content-start">
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <label> Pilih PT </label>
              <Select2
                name={"PT"}
                size="sm"
                options={pt}
                isClearable={false}
                value={
                  pt
                    ? pt.find(
                      (option) => option.value === selectedPT
                    )
                    : ""
                }
                onChange={(e) => {
                  setSelectedPT(e ? e.value : []);
                  setCabang([0])
                  setSeries([]);
                  setData([]);
                  // setPenjualan([]);
                }}
              />
            </div>
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <label> Pilih Perusahaan </label>
              <Select2
                name={"Cabang"}
                placeholder={opsi.text}
                size="sm"
                options={opsi}
                isClearable={true}
                value={
                  opsi
                    ? opsi.find(
                      (option) => option.value === cabang
                    )
                    : ""
                }
                onChange={(e) => {
                  setCabang(e ? [e.value] : [0]);
                  setSeries([]);
                  setData([]);
                  // setPenjualan([]);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="mixed-chart">
              {
                series.length > 0 ?
                  <Chart
                    options={options}
                    series={series}
                    type={'line'}
                    width="1200"
                    height={450}
                    animations={{
                      enabled: true,
                      easing: 'easeinout',
                      speed: 800,
                      animateGradually: {
                        enabled: true,
                        delay: 150
                      },
                      dynamicAnimation: {
                        enabled: true,
                        speed: 350
                      }
                    }}
                  />
                  :
                  ""
              }
            </div>
          </div>
          <ReactDatatable
            columns={columns}
            data={data}
            dense
            pagination
            searchParameter={searchParameter}
            actions={actionsMemo}
          />
          {/* <Table bordered style={{ marginTop: "5%"}} responsive>
            <thead>
              <tr>
                <th>Nama</th>
                {bulan.map((val, index) => (
                  <th key={index}>{val}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {penjualan.map((val, i) => (
                <tr key={val.name}>
                  <td>{val.name}</td>
                  {val.penjualan.map((val, index) => (
                    <td key={`${val.name} ${Math.random()}`}>Rp. {separatorHarga(val.toString())}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table> */}
        </CardBody>
      </AnimatedCard>
    </>
  )
}

export default GrafikPembelianCabang
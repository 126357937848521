import { ArrowLeftOutlined, ArrowRightOutlined } from "@material-ui/icons";
import { Checkbox, FormControlLabel, IconButton, Select, makeStyles, Radio, RadioGroup } from "@material-ui/core";
import { Field, useField } from "formik";
import { formatNpwp, separatorHarga } from "../../services/separator-harga";
import { getMonth, getYear } from "date-fns";

import DatePicker from "react-datepicker";
import { ImageUploader } from "../image-uploader/imageUploader";
import { Input } from "../../../_metronic/_partials/controls";
import React from "react";
import Select2 from "react-select";
import moment from "moment";
import { range } from "lodash";
import SearchBar from "material-ui-search-bar";
import { Form, InputGroup } from "react-bootstrap";

export function FormsFields(params) {
  params = {
    ...{ onRequestSearch: () => { } },
    ...{ onCancelSearch: () => { } },
    ...{ onChange: () => { } },
    ...{ info: "" },
    ...params,
    ...{ name: params.id },
  };
  const [field] = useField(params);
  const getFieldCSSClasses = (touched, errors) => {
    const classes = [""];
    if (touched && errors) {
      classes.push("is-invalid");
    }
    if (touched && !errors) {
      classes.push("is-valid");
    }
    return classes.join(" ");
  };
  // DatePicker data
  const years = range(2021, getYear(new Date()) + 2, 1);
  // Indonesia
  const bulan = [
    'Januari',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember'
  ];

  const useStyles = makeStyles(theme => ({
    margin: {
      margin: theme.spacing(1),
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
  }));
  const classes = useStyles();
  // DatePicker Disable Day
  // const isWeekday = (date) => {
  //   const day = getDay(date);
  //   return day !== 0;
  // };

  const { type, className, style, label, id, isClearable, isLoading, disabled, isDisabled, isSearchable, isMulti, paramsFormik, info, options, value, rows, startDate, endDate, minDate, maxDate, selectsRange, showYearPicker, tabIndex, color, indeterminate, onRequestSearch, arialLabel, row, labelGroup, yearItemNumber, showMonthYearPicker, min, max, inputStyle } = params;
  switch (type) {
    case "text":
      return (
        <div
          className={className}
          style={style}
        >
          <Field
            id={id}
            name={id}
            label={label}
            type={"text"}
            placeholder={label + "..."}
            component={Input}
            autoComplete={"off"}
            disabled={disabled}
            value={value ?? paramsFormik['values'][id]}
            onChange={(val) => {
              params.onChange(val, paramsFormik['setFieldValue']);
              field.onChange(val);
            }}
          />
        </div>
      );
    case "disabled-text":
      return (
        <div
          className={className}
          style={style}
        >
          <Field
            id={id}
            name={id}
            label={label}
            type={"text"}
            placeholder={label + "..."}
            component={Input}
            autoComplete={"off"}
            disabled={true}
            value={value}
          />
        </div>
      );
    case "DatePicker":
      return (
        <div
          className={params.className}
          style={params.style}>
          {<label>{label}</label>}
          <DatePicker
            id={id}
            name={id}
            className={"form-control " + getFieldCSSClasses(paramsFormik['touched'][id], paramsFormik['errors'][id])}
            onBlur={() => {
              paramsFormik['setFieldTouched']([id], true);
            }}
            style={{ width: "100%" }}
            placeholderText={showYearPicker ? "YYYY" : showMonthYearPicker ? "MM/YYYY" : "DD-MM-YYYY"}
            disabled={disabled}
            dateFormat={showYearPicker ? "yyyy" : showMonthYearPicker ? "MM/yyyy" : "dd-MM-yyyy"}
            selectsStart
            startDate={startDate}
            selectsEnd
            autoComplete={"off"}
            endDate={endDate}
            minDate={minDate}
            maxDate={maxDate}
            selectsRange={selectsRange}
            isClearable={!disabled ?? true}
            peekNextMonth
            // filterDate={isWeekday}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            useShortMonthInDropdown
            showYearPicker={showYearPicker}
            yearItemNumber={yearItemNumber}
            showMonthYearPicker={showMonthYearPicker}
            // showFullMonthYearPicker={showMonthYearPicker}
            showFourColumnMonthYearPicker={showMonthYearPicker}
            tabIndex={tabIndex}
            closeOnScroll={true}
            // excludeDateIntervals={[{ start: subDays(new Date(), 5), end: addDays(new Date(), 5) }]}
            selected={field['value'] || null}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <IconButton aria-label="Delete" onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className={classes.margin} size="small">
                  <ArrowLeftOutlined fontSize="large" />
                </IconButton>
                <Select
                  style={{ marginLeft: 3, marginRight: 3 }}
                  native
                  value={getYear(date)}
                  onChange={({ target: { value } }) => changeYear(value)}
                >
                  {years.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
                {!showMonthYearPicker &&
                  (<>
                    <Select
                      style={{ marginRight: 3 }}
                      native
                      value={bulan[getMonth(date)]}
                      onChange={({ target: { value } }) =>
                        changeMonth(bulan.indexOf(value))
                      }>
                      {bulan.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  </>)
                }
                <IconButton aria-label="Delete" onClick={increaseMonth} disabled={nextMonthButtonDisabled} className={classes.margin} size="small">
                  <ArrowRightOutlined fontSize="large" />
                </IconButton>
              </div>
            )}
            onChange={(val) => {
              params.onChange(val, paramsFormik['setFieldValue']([id], val ? val : null));
              field.onChange(id);
            }}
          />
        </div >
      );
    case "disabled-DatePicker":
      return (
        <div
          className={className}
          style={style}
        >
          <Field
            id={id}
            name={id}
            label={label}
            type={"text"}
            placeholder={label + "..."}
            component={Input}
            autoComplete={"off"}
            disabled={true}
            value={value !== "" ? moment(value).format("DD-MM-YYYY") : ""}
          />
        </div>
      );
    case "textarea":
      return (
        <div
          className={className}
          style={style}
        >
          <label>{label}</label>
          <Field
            id={id}
            name={id}
            as={"textarea"}
            placeholder={label + "..."}
            className={"form-control" + getFieldCSSClasses(paramsFormik['touched'][id], paramsFormik['errors'][id])}
            onBlur={() => {
              paramsFormik['setFieldTouched']([id], true);
            }}
            label={label}
            autoComplete={"off"}
            disabled={disabled}
            rows={rows}
            style={{ resize: "none" }}
            onChange={(val) => {
              params.onChange(val, paramsFormik['setFieldValue']);
              field.onChange(val);
            }}
          />
          {paramsFormik['errors'][id] && (
            <div className="invalid-feedback">
              {paramsFormik['errors'][id]}
            </div>
          )}
        </div>
      );
    case "disabled-textarea":
      return (
        <div
          className={className}
          style={style}
        >
          <label>{label}</label>
          <Field
            id={id}
            name={id}
            as={"textarea"}
            placeholder={label + "..."}
            className={"form-control"}
            label={label}
            autoComplete={"off"}
            disabled={true}
            rows={rows}
            value={value}
            style={{ resize: "none" }}
          />
        </div>
      );
    case "number":
      return (
        <div
          className={className}
          style={style}
        >
          <Field
            id={id}
            name={id}
            label={label}
            type={"number"}
            placeholder={label + "..."}
            component={Input}
            autoComplete={"off"}
            disabled={disabled}
            min={min ?? ""}
            max={max ?? ""}
            value={value ?? paramsFormik['values'][id]}
            onChange={(val) => {
              params.onChange(val, paramsFormik['setFieldValue']);
              field.onChange(val);
            }}
          />
        </div>
      );
    case "currency":
      return (
        <div
          className={className}
          style={style}
        >
          <Field
            id={id}
            name={id}
            label={label}
            type={"text"}
            placeholder={"Rp. 0"}
            // placeholder={label + "..."}
            component={Input}
            autoComplete={"off"}
            disabled={disabled}
            value={((value !== undefined) ?
              (
                (value < 0)
                  ? "Rp. -" + separatorHarga(value.toString())
                  : separatorHarga(value.toString(), 'Rp. ')
              ) : value) ?? ((paramsFormik['values'][id] < 0) ? "Rp. -" + separatorHarga(paramsFormik['values'][id].toString())
                : separatorHarga(paramsFormik['values'][id].toString(), 'Rp. ')
              )
            }
            onChange={(val) => {
              val['target']['value'] = separatorHarga(val['target']['value'], 'Rp. ');
              params.onChange(val, paramsFormik['setFieldValue']([id], val['target']['value']));
              field.onChange(val);
            }}
          />
        </div>
      );
    case "disabled-currency":
      return (
        <div
          className={className}
          style={style}
        >
          <Field
            id={id}
            name={id}
            label={label}
            type={"text"}
            placeholder={"Rp. 0"}
            component={Input}
            autoComplete={"off"}
            disabled={true}
            style={inputStyle}
            value={((value < 0) ? "Rp. -" + separatorHarga(value.toString()) : separatorHarga(value.toString(), "Rp. "))}
          />
        </div>
      );
    case "disabled-currency-group":
      return (
        <div
          className={className}
        >
          <Form.Group style={style}>
            {label ?? <Form.Label>{label}</Form.Label>}
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend">{labelGroup}</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                id={id}
                name={id}
                type={"text"}
                placeholder={"Rp. 0"}
                aria-describedby="inputGroupPrepend"
                disabled={true}
                value={((value < 0) ? "Rp. -" + separatorHarga(value.toString()) : separatorHarga(value.toString(), "Rp. "))}
              />
            </InputGroup>
          </Form.Group>
        </div>
      );
    case "npwp":
      return (
        <div
          className={className}
          style={style}
        >
          <Field
            id={id}
            name={id}
            label={label}
            type={"text"}
            maxLength={20}
            placeholder={label + "..."}
            component={Input}
            autoComplete={"off"}
            disabled={disabled}
            value={((value !== undefined) ? (formatNpwp(value)) : value) ?? (
              formatNpwp(paramsFormik['values'][id])
            )
            }
            onChange={(val) => {
              params.onChange(val, paramsFormik['setFieldValue']([id], formatNpwp(val['target']['value'])));
              field.onChange(val);
            }}
          />
          {paramsFormik['errors'][id] && paramsFormik['touched'][id] ? (
            <div className="invalid-feedback">
              {paramsFormik['errors'][id].toString()}
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    case "password":
      return (
        <Field
          id={params.id}
          name={params.id}
          component={Input}
          type="password"
          placeholder={params.label}
          label={params.label}
          autoComplete="off"
        />
      );
    case "checkbox":
      return (
        <div
          className={className}
          style={style}
        >
          <FormControlLabel
            control={
              <Checkbox
                id={id}
                name={id}
                color={color ?? "primary"}
                onChange={(val) => {
                  params.onChange(val, paramsFormik['setFieldValue']);
                  field.onChange(val);
                }}
                checked={paramsFormik['values'][id]}
                indeterminate={indeterminate ?? false}
              />
            }
            label={label}
            labelPlacement={"end"}
          />
        </div>
      );
    case "radio":
      return (
        <div
          className={className}
          style={style}
        >
          <RadioGroup
            aria-label={arialLabel ?? ""}
            name={id}
            className={classes.group + " "}
            onChange={(val) => {
              params.onChange(val, paramsFormik['setFieldValue']);
              field.onChange(val);
            }}
            row={row ?? true}
          >
            {options.map((val) => (
              <FormControlLabel
                disabled={disabled ?? false}
                key={val}
                value={val.toLowerCase()}
                control={<Radio color="primary" />}
                label={val}
                labelPlacement="end"
                checked={val.toLowerCase() === paramsFormik['values'][id]}
              />
            ))}
          </RadioGroup>
          {paramsFormik['errors'][id] ? (
            <span style={{
              color: "#F64E60",
              fontSize: "0.9rem",
              fontWeight: "400",
              fontFamily: "Poppins, Helvetica, 'sans-serif'"
            }}>
              {paramsFormik['errors'][id].toString()}
            </span>
          ) : (
            <></>
          )}
        </div>
      );
    case "select2":
      return (
        <div className={className}
          style={style}>
          <label>{label}</label>
          <Select2
            name={id}
            label={label}
            placeholder={"--- Select " + label + " ---"}
            isClearable={isClearable}
            isLoading={isLoading}
            isDisabled={isDisabled}
            isSearchable={isSearchable}
            isMulti={isMulti}
            cacheOptions
            onBlur={() => {
              paramsFormik['setFieldTouched']([id], true);
            }}
            className={getFieldCSSClasses(paramsFormik['touched'][id], paramsFormik['errors'][id])}
            info={info}
            styles={{
              menu: (provided, state) => ({
                ...provided,
                borderBottom: '1px dotted pink',
                paddingRight: 4,
                paddingLeft: 4,
                zIndex: '99999'
                // width: state.selectProps.width,
                // color: state.selectProps.menuColor,
              })
            }}
            options={options}
            value={
              isMulti ? (options ? options.filter(obj => paramsFormik['values'][id] ? paramsFormik['values'][id].includes(obj.value) : "") : "")
                : (options ? options.find((val) => val['value'] === paramsFormik['values'][id]) : "") || ""
            }
            onChange={(val) => {
              params.onChange(val, paramsFormik['setFieldValue']([id], isMulti ? Array.isArray(val) ? val.map((x) => x.value) : [] : val ? val['value'] : ""));
              field.onChange(id);
            }}
          />
          {paramsFormik['errors'][id] && paramsFormik['touched'][id] ? (
            <div className="invalid-feedback">
              {paramsFormik['errors'][id].toString()}
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    case "search-bar":
      return (
        <div className={className}
          style={style}>
          <label>{label}</label>
          <SearchBar
            id={id}
            name={id}
            label={label}
            style={{
              margin: '0 auto',
              border: "1px solid #e4e6ef",
              borderRadius: "0.42rem",
            }}
            cancelOnEscape={true}
            onCancelSearch={() => {
              paramsFormik['setFieldValue']([id], "")
            }}
            onRequestSearch={onRequestSearch}
            placeholder={"Searching " + label + "..."}
            onChange={(val) => {
              paramsFormik['setFieldValue']([id], val ? val : "")
            }}
          />
        </div>
      );
    case "searchBarFilter":
      return (
        <div className={className}
          style={style}>
          <label>{label}</label>
          <SearchBar
            id={id}
            name={id}
            label={label}
            style={{
              margin: '0 auto',
              border: "1px solid #e4e6ef",
              borderRadius: "0.42rem",
            }}
            cancelOnEscape={false}
            onCancelSearch={(val) => {
              params.onCancelSearch(val, paramsFormik['setFieldValue']([id], ""));
            }}
            onRequestSearch={(val) => {
              params.onRequestSearch(val);
            }}
            value={value ?? paramsFormik['values'][id]}
            placeholder={"Searching " + label + "..."}
            onChange={(val) => {
              params.onChange(val, paramsFormik['setFieldValue']([id], val));
              field.onChange(id);
            }}
          />
        </div>
      );
    case "image":
      return (
        <div
          className={className}
          style={style}
        >
          <ImageUploader {...params} />
        </div>);
    case "hidden":
      return (
        <Field
          id={params.id}
          name={params.dataField}
          component={Input}
          type="hidden"
          placeholder={params.label}
          autoComplete="off"
          onChange={(val) => {
            params.onChange(val, params.setFieldValue);
            field.onChange(val);
          }}
        />
      );
    default:
      break;
  }
}

import XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";

// Get Sheet Data Export To Excel
function getSheetData(data, header) {
  var fields = Object.keys(data[0]);
  var sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
}

// Export To Excell
function excelAllStok(data, title) {
  let dataList = [];
  data.map((data, i) => {
    dataList.push({
      tanggal: data.tanggal,
      nama: data.nama,
      kode: data.kode,
      noTransaksi: data.no_transaksi,
      satuan: data.satuan,
      qty: data.qty,
      gudang: data.gudang,
      status: data.status,
    })
    return data;
  });
  let header = [
    'Tanggal',
    'Nama Barang',
    'Kode Barang',
    'No Transaksi',
    'Satuan',
    'Qty',
    'Lokasi',
    'Status',
  ];
  let dataLength = data.length + 1;
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);
    const sheetData = getSheetData(dataList, header);
    const totalColumns = sheetData[0].length;
    sheet1.cell("A1").value(sheetData);
    const range = sheet1.usedRange();
    const endColumn = String.fromCharCode(64 + totalColumns);
    sheet1.row(1).style("bold", true).height(25);
    sheet1.range("A1:" + endColumn + "1").style({
      fill: "BFBFBF",
      horizontalAlignment: "center",
      verticalAlignment: "center",
    });
    // Styls Center Align
    let centerAlign = ["A", "B", "C", "D", "E","F","G","H"];
    for (let i = 0; i < centerAlign.length; i++) {
      sheet1.range(centerAlign[i] + "2:" + centerAlign[i] + dataLength).style({
        horizontalAlignment: "center"
      });

    }

    range.style("border", true);
    sheet1.column("A").width(20);
    sheet1.column("B").width(40);
    sheet1.column("C").width(35);
    sheet1.column("D").width(40);
    sheet1.column("E").width(20);
    sheet1.column("F").width(20);
    sheet1.column("G").width(40);
    sheet1.column("H").width(20);
    return workbook.outputAsync().then((res) => {
      saveAs(res, "(" + title + ").xlsx");
    });
  });
}

export { excelAllStok, getSheetData }
import { simpleFetch} from "./api";

export class CustomerPembelianAPI {

  async getSingle(id) {
    let res = await simpleFetch({
      url: "retail/kontak/get/customer?id=" + id,
      method: "GET",
    });
    return res;
  }
}

import * as Yup from "yup";
import { CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls";
import { AkunAPI } from "../../../api/akun";
import AkunModel from "../../../models/akun-model/AkunModel";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import DataTableModel from "../../../models/datatable-model/DataTableModel";
import { Formik } from "formik";
import { FormsFields } from "../../../components/forms-fields/FormsFields";
import { ModalForms } from "../../../components/modal-forms/modalForms";
import React from "react";
import { ReactDatatable } from "../../../components/react-datatable/reactDatatable";
import SimpleAlert from "../../../components/alert/alert";
import { dispatcher } from "../../../redux/dispatcher";
import ButtonActions from "../../../components/button-actions/buttonActions";

export default class Akun extends React.Component {
  constructor(props) {
    super(props);
    this['pageName'] = "Akun";
    this['searchParameter'] = ["id_kode_parent", "nama_perusahaan", "kode_akun", "nama_akun", "jenis_akun", "nama_akun_akses"];
    this['apiAkun'] = new AkunAPI();
    this['initialData'] = new AkunModel();
    this['state'] = {
      pending: true,
      modal: {
        show: false,
        initialData: this['initialData'],
        isEdit: false,
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
    this['datatable'] = [{ id: "", no: "", name: "" }];
    this['columntable'] = [{ id: "", title: "" }];
  }
  async componentDidMount() {
    await this.LoadData();
  }
  async LoadData() {
    this.setState({ pending: true });
    let data = await this['apiAkun'].getList();
    this['DataTableModel'] = new DataTableModel();
    data['data'].map((item, i) => {
      let dataModel = new AkunModel(item);
      if (item['status'] === 1) {
        this['DataTableModel'].add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: (
            <>
              <ButtonActions
                label={"edit"}
                onClick={async () =>
                  await this['handleModal'].edit(dataModel)}
              />
              <ButtonActions
                label={"delete"}
                onClick={async () => {
                  this['handleAlert'].show(item['id']);
                }}
              />
            </>
          ),
        });
      }
      return item;
    });
    this['columntable'] = await this['DataTableModel'].getColumn();
    this['datatable'] = await this['DataTableModel'].getDatas();
    this.setState({ ...this['state'], pending: false });
  }
  handleModal = {
    loadOptionKodeParent: async () => {
      this['apiAkun'].getAkunParent().then((res) => {
        this['option_kode_parent'] = res['data'].map((val) => {
          return {
            value: val['id'],
            label: val['text'],
          };
        });
      });
    },
    loadOptionPerusahaan: async () => {
      this['apiAkun'].getAllPerusahaan().then((res) => {
        this['option_perusahaan'] = res['data'].map((val) => {
          return {
            value: val['id'],
            label: val['text'],
          };
        });
      });
    },
    loadOptionJenisAkun: async () => {
      this['apiAkun'].getJenisAkun().then((res) => {
        this['option_jenis_akun'] = res['data'].map((val) => {
          return {
            value: val['id'],
            label: val['text'],
          };
        });
      });
    },
    loadOptionAkunAkses: async () => {
      this['apiAkun'].getAksesAkun().then((res) => {
        this['option_akun_akses'] = res['data'].map((val) => {
          return {
            value: val['id'],
            label: val['text'],
          };
        });
      });
    },
    tambah: async () => {
      let state = { ...this['state'] };
      state = {
        modal: {
          show: true,
          title: `Tambah ${this['pageName']}`,
          isEdit: false,
          initialData: this['initialData'],
        }
      }
      this.setState({ ...state });
      await this['handleModal'].loadOptionKodeParent();
      await this['handleModal'].loadOptionPerusahaan();
      await this['handleModal'].loadOptionJenisAkun();
      await this['handleModal'].loadOptionAkunAkses();
    },
    edit: async (dataFromModel) => {
      let state = { ...this['state'] };
      state = {
        modal: {
          show: true,
          title: `Edit ${this['pageName']}`,
          isEdit: true,
          initialData: dataFromModel
        }
      }
      this.setState({ ...state });
      await this['handleModal'].loadOptionKodeParent();
      await this['handleModal'].loadOptionPerusahaan();
      await this['handleModal'].loadOptionJenisAkun();
      await this['handleModal'].loadOptionAkunAkses();
    },
    hide: () => {
      let state = { ...this['state'] };
      state = {
        modal: {
          show: false,
          initialData: this['initialData']
        }
      }
      this.setState({ ...state });
    },
  };
  handleAlert = {
    show: (id) => {
      let alert = { ...this['state'] };
      alert = {
        alert: {
          show: true,
          activeId: id
        }
      }
      this.setState({ ...alert });
    },
    hide: () => {
      let alert = { ...this['state'] };
      alert = {
        alert: {
          show: false,
          activeId: null
        }
      }
      this.setState({ ...alert });
    },
    confirmed: async () => {
      await this['apiAkun'].delete(this['state']['alert']['activeId']).then((res) => {
        if (res['status']) {
          dispatcher['snackbar'].show("Berhasil", res.message, "primary");
          this['handleAlert'].hide();
          this.LoadData();
        } else {
          return dispatcher['snackbar'].show("Gagal", res.message, "danger");
        }
      });
    },
  };
  render() {
    const { pending, modal, alert } = this['state'];
    return (
      <>
        {/* Views Table */}
        <AnimatedCard>
          <CardHeader title={this['pageName']}>
            <CardHeaderToolbar>
              <button
                type={"button"}
                className={"btn btn-primary btn-sm"}
                onClick={() => this['handleModal'].tambah()}
              > <i className={"fa fa-plus"}></i> Tambah
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <ReactDatatable
              title={""}
              columns={this['columntable']}
              data={this['datatable']}
              searchParameter={this['searchParameter']}
              progressPending={pending}
            />
          </CardBody>
        </AnimatedCard>
        {/* Modal Tambah/Edit */}
        <Formik
          enableReinitialize={true}
          initialValues={modal['initialData']}
          validationSchema={Yup.object().shape({
            kode_akun: Yup.string().required(),
            nama_akun: Yup.string().required(),
            id_akun_jenis: Yup.string().nullable(true).required("Jenis Akun harus diisi"),
          })}
          onSubmit={async (values, { resetForm }) => {
            let dataSend = {
              id_parent: parseInt(values['id_parent']),
              id_perusahaan: values['id_perusahaan'],
              id_akun_jenis: parseInt(values['id_akun_jenis']),
              id_akun_akses: values['id_akun_akses'],
              kode: values['kode_akun'],
              nama: values['nama_akun'],
              control_parent: values['control_parent'],
            };
            if (modal['isEdit']) {
              // Update Data
              dataSend = {
                id: parseInt(values['id']),
                ...dataSend
              }
              await this['apiAkun'].edit(dataSend).then((res) => {
                if (res['status']) {
                  dispatcher['snackbar'].show("Berhasil", res.message, "primary");
                  this['handleModal'].hide();
                  this.LoadData();
                } else {
                  return dispatcher['snackbar'].show("Gagal", res.message, "danger");
                }
              });
            } else {
              // Insert Data
              await this['apiAkun'].set(dataSend).then((res) => {
                if (res['status']) {
                  dispatcher['snackbar'].show("Berhasil", res.message, "primary");
                  this['handleModal'].hide();
                  this.LoadData();
                } else {
                  return dispatcher['snackbar'].show("Gagal", res.message, "danger");
                }
              });
            }
          }}
        >
          {({ handleSubmit, setFieldValue, setFieldTouched, touched, errors, values }) => (
            <ModalForms
              show={modal['show']}
              title={modal['title']}
              onHide={this['handleModal']['hide']}
              onConfirm={handleSubmit}
              size={"lg"}
              content={
                <div className={"form-group row"}>
                  <div className={"col-lg-6"}>
                    {/* Forms Kode Parent */}
                    <FormsFields
                      id={"id_parent"}
                      type="select2"
                      label={"Kode Parent"}
                      className={"col-lg-12"}
                      isClearable={true}
                      isSearchable={true}
                      options={this['option_kode_parent']}
                      paramsFormik={{
                        setFieldValue,
                        setFieldTouched,
                        touched,
                        errors,
                        values
                      }}
                    />
                    {/* Forms Kode Akun */}
                    <FormsFields
                      id={"kode_akun"}
                      type={"number"}
                      label={"Kode Akun"}
                      className={"col-lg-12"}
                      style={{ marginTop: "20px" }}
                      paramsFormik={{
                        setFieldValue,
                        values
                      }}
                    />
                    {/* Forms Nama Akun */}
                    <FormsFields
                      id={"nama_akun"}
                      type="text"
                      label={"Nama Akun"}
                      className={"col-lg-12"}
                      style={{ marginTop: "20px" }}
                      paramsFormik={{
                        setFieldValue,
                        values
                      }}
                    />
                    {/* Forms Select Jenis Akun */}
                    <FormsFields
                      id={"id_akun_jenis"}
                      type="select2"
                      label={"Jenis Akun"}
                      style={{ marginTop: "20px" }}
                      className={"col-lg-12"}
                      isClearable={true}
                      isSearchable={true}
                      options={this['option_jenis_akun']}
                      paramsFormik={{
                        setFieldValue,
                        setFieldTouched,
                        touched,
                        errors,
                        values
                      }}
                    />
                  </div>
                  <div className={"col-lg-6"}>
                    {/* Forms Perusahaan */}
                    <FormsFields
                      id={"id_perusahaan"}
                      type="select2"
                      label={"Perusahaan"}
                      className={"col-lg-12"}
                      isMulti={true}
                      isClearable={true}
                      isSearchable={true}
                      options={this['option_perusahaan']}
                      paramsFormik={{
                        setFieldValue,
                        setFieldTouched,
                        touched,
                        errors,
                        values
                      }}
                    />
                    {/* Forms Select Akun Akses */}
                    <FormsFields
                      id={"id_akun_akses"}
                      type="select2"
                      label={"Akun Akses"}
                      className={"col-lg-12"}
                      style={{ marginTop: "20px" }}
                      isMulti={true}
                      isClearable={true}
                      isSearchable={true}
                      options={this['option_akun_akses']}
                      paramsFormik={{
                        setFieldValue,
                        setFieldTouched,
                        touched,
                        errors,
                        values
                      }}
                    />
                    {/* Forms Select Parent Control */}
                    <FormsFields
                      id={"control_parent"}
                      type="select2"
                      label={"Parent Laba Rugi"}
                      style={{ marginTop: "20px" }}
                      className={"col-lg-12"}
                      isClearable={true}
                      isSearchable={true}
                      options={[{
                        value: "penjualan",
                        label: "Penjualan",
                      }, {
                        value: "return-penjualan",
                        label: "Return Penjualan",
                      }, {
                        value: "discount",
                        label: "Discount",
                      }, {
                        value: "harga-pokok",
                        label: "Harga Pokok",
                      }, {
                        value: "pendapatan-lain-lain",
                        label: "Pendapatan Lain-Lain",
                      }]}
                      paramsFormik={{
                        setFieldValue,
                        setFieldTouched,
                        touched,
                        errors,
                        values
                      }}
                    />
                  </div>
                </div>
              }
            />
          )}
        </Formik>
        {/* Alert Hapus */}
        <SimpleAlert
          title={"Hapus Data"}
          content={"Anda yakin hapus data ini...?"}
          show={alert['show']}
          onHide={() => this['handleAlert'].hide()}
          onConfirmed={this['handleAlert']['confirmed']}
        />
      </>
    );
  }
}

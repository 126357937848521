import * as Yup from "yup";
import PesananPembelianDetailModel from "../pesanan-pembelian-detail-model/PesananPembelianDetailModel";
import { SupplierAPI } from "../../api/supplier";
import { separatorHarga, hapusSeparator } from "../../services/separator-harga";
import store from "../../redux/store";
import moment from "moment";

export default class PesananPembelianModel {
  constructor(
    data = {
      master: {
        id: 0,
        id_perusahaan: store.getState().auth.id_perusahaan,
        kode: "",
        no_transaksi: "",
        tanggal: moment(new Date()).format("YYYY-MM-DD"),
        jatuh_tempo: "",
        qty_total: 0,
        hpp_total: 0,
        dpp_total: 0,
        diskon_total: 0,
        subtotal: 0,
        pajak_total: 0,
        total: 0,
        id_user: store.getState().auth.id,
        id_pemasok: 0,
        id_kontak: 0,
        deskripsi: "",
        is_approved: 0,
        approved_spv_by: 0,
        approved_manager_by: 0,
        approved_director_by: 0,
        status: 0,
        create_at: "",
        update_at: "",
        valDate: 0,
        status_invoice:0,
        checked: 1,
        status_batal: 0,
        toSupplier: {
          nama: "",
        },
      },
      detail: [],
    }
  ) {
    this.master = {
      id: data.master.id,
      id_perusahaan: data.master.id_perusahaan,
      kode: data.master.kode,
      no_transaksi: data.master.no_transaksi,
      tanggal: data.master.tanggal,
      jatuh_tempo: data.master.jatuh_tempo,
      qty_total: data.master.qty_total,
      hpp_total: separatorHarga(data.master.hpp_total.toString()),
      dpp_total: separatorHarga(data.master.dpp_total.toString()),
      diskon_total: separatorHarga(data.master.diskon_total.toString()),
      subtotal: separatorHarga(data.master.subtotal.toString()),
      pajak_total: separatorHarga(data.master.pajak_total.toString()),
      total: separatorHarga(data.master.total.toString()),
      id_user: data.master.id_user,
      id_pemasok: data.master.id_pemasok,
      nama_supplier: data.master.toSupplier.nama,
      id_kontak: data.master.id_kontak,
      deskripsi: data.master.deskripsi,
      is_approved: data.master.is_approved,
      approved_spv_by: data.master.approved_spv_by,
      approved_manager_by: data.master.approved_manager_by,
      approved_director_by: data.master.approved_director_by,
      status: data.master.status,
      status_batal: data.master.status_batal,
      create_at: data.master.create_at,
      status_invoice: data.master.status_invoice,
      update_at: data.master.update_at,
      valDate: data.master.valDate,
      checked: data.master.checked
    };
    this.detail = [];
    this.checked = 1;
    this.initDetails(data.detail);
  }

  initDetails(detail) {
    if (detail.length > 0)
      detail.forEach((item) => {
        let diskon_persen = (item.diskon / item.hpp_total) * 100;
        let pajak_persen = (item.pajak / item.subtotal) * 100;
        this.detail = [
          ...this.detail,
          new PesananPembelianDetailModel({
            ...item,
            id_pembelian: this.master.id,
            diskon_persen: diskon_persen,
            pajak_persen: pajak_persen,
          }),
        ];
      });
  }

  nulldata = {
    master: {
      id: 0,
      id_perusahaan: store.getState().auth.id_perusahaan,
      kode: "",
      no_transaksi: "",
      tanggal: "",
      jatuh_tempo: "",
      qty_total: 0,
      hpp_total: 0,
      dpp_total: 0,
      diskon_total: 0,
      subtotal: 0,
      pajak_total: 0,
      total: 0,
      id_user: store.getState().auth.id,
      id_pemasok: 0,
      id_kontak: 0,
      deskripsi: "",
      is_approved: 0,
      approved_spv_by: 0,
      approved_manager_by: 0,
      approved_director_by: 0,
      status: 0,
      create_at: "",
      update_at: "",
      status_invoice: 0,
      toSupplier: {
        nama: "",
      },
    },
    detail: [],
    checked: 1,
  };

  reset() {
    this(this.nulldata);
  }

  hitungTotalan() {
    let qty_total = 0;
    let hpp_total = 0;
    let dpp_total = 0;
    let diskon_total = 0;
    let subtotal = 0;
    let pajak_total = 0;
    let total = 0;
    if (this.detail.length > 0) {
      this.detail.forEach((item) => {
        if (item.status !== 0) {
          qty_total = parseInt(qty_total) + parseInt(item.qty);
          hpp_total =
            parseInt(hpp_total) + parseInt(hapusSeparator(item.hpp_total));
          dpp_total =
            parseInt(dpp_total) + parseInt(hapusSeparator(item.dpp_total));
          diskon_total =
            parseInt(diskon_total) + parseInt(hapusSeparator(item.diskon));
          subtotal =
            parseInt(subtotal) + parseInt(hapusSeparator(item.subtotal));
          pajak_total =
            parseInt(pajak_total) + parseInt(hapusSeparator(item.pajak));
          total = parseInt(total) + parseInt(hapusSeparator(item.total));
        }
      });
    }
    this.master.qty_total = qty_total;
    this.master.hpp_total = separatorHarga(hpp_total.toString());
    this.master.dpp_total = separatorHarga(dpp_total.toString());
    this.master.diskon_total = separatorHarga(diskon_total.toString());
    this.master.subtotal = separatorHarga(subtotal.toString());
    this.master.pajak_total = separatorHarga(pajak_total.toString());
    this.master.total = separatorHarga(total.toString());
  }

  jatuh_tempo() {
    let target = new Date(this.master.tanggal)
    let val = this.valDate;
    // console.log('cek val', val);
    // console.log('target jatuh tempo', target);
    this.master.jatuh_tempo = moment(target).add(val, 'days').format("YYYY-MM-DD")
    // console.log('cek berubah', this.master.jatuh_tempo);
  }


  removeSeparator() {
    this.master.hpp_total = parseInt(hapusSeparator(this.master.hpp_total));
    this.master.dpp_total = parseInt(hapusSeparator(this.master.dpp_total));
    this.master.diskon_total = parseInt(
      hapusSeparator(this.master.diskon_total)
    );
    this.master.pajak_total = parseInt(hapusSeparator(this.master.pajak_total));
    this.master.subtotal = parseInt(hapusSeparator(this.master.subtotal));
    this.master.total = parseInt(hapusSeparator(this.master.total));
  }

  removeAllDetailSeparator() {
    this.detail.forEach((item) => item.removeSeparator());
  }

  handleDetail = {
    set: async (item) => {
      item.id_pembelian = this.master.id;
      item.id_pemasok = this.master.id_pemasok;
      this.detail = [...this.detail, item];
      this.hitungTotalan();
    },
    del: async (dataModel) => {
      this.detail.map((val, i) => {
        if (val.id === dataModel.id && val.id_produk === dataModel.id_produk) {
          this.detail[i].status = 0;
        }
      });
      this.hitungTotalan();
    },
    reset: async () => {
      this.detail = [];
      this.hitungTotalan();
    },
    edit: async (item) => {
      // this.detail[this.detail.map((e) => e.id).indexOf(item.id)] = item;
      this.hitungTotalan();
    },
    getSingle: async (id) => this.detail.find((e) => e.id === id),
  };

  async getEditable(params = { pageName: "", isForm: false }) {
    let options_supplier = [];
    if (params.isForm) {
      let ksa = [20, 46, 52, 69]
      let kpp = [68, 28, 70, 71]
      let ksb = [105,108,109,110]

      let dataSplr = await new SupplierAPI().getSemua()
      let filter = dataSplr.data.filter((fil) =>
        ksa.includes(fil.id_perusahaan) && ksa.includes(this.master.id_perusahaan) ?
          ksa.includes(fil.id_perusahaan) :
          kpp.includes(fil.id_perusahaan) && kpp.includes(this.master.id_perusahaan) ?
            kpp.includes(fil.id_perusahaan) :
            ksb.includes(fil.id_perusahaan) && ksb.includes(this.master.id_perusahaan) ?
              ksb.includes(fil.id_perusahaan) : "");
      filter.map((data) => {
        return options_supplier = [
          ...options_supplier,
          { id: data.id, text: data.nama, label: data.nama, value: data.id },
        ]
      })
    }

    const getSupplierTop = async (id) => {
      return await new SupplierAPI()
        .getSingle(id)
        .then((res) => res.data.nilai_top);
    };

    return [
      {
        validation: Yup.string().required("Tanggal harus diisi"),
        dataField: "tanggal",
        label: "Tanggal",
        type: "date",
        value: this.master.tanggal,
        onChange: (e) => {
          this.master.tanggal = e.target.value
          this.jatuh_tempo();
          // let target = new Date(this.master.tanggal)
          // let tempo = moment(target).add(5, 'days').format("YYYY-MM-DD")
          // console.log('cek target', tempo);
        },
      },
      {
        validation: Yup.string().required("Jatuh tempo harus diisi"),
        dataField: "jatuh_tempo",
        label: "Jatuh Tempo",
        type: "disabled-text",
        value: this.master.jatuh_tempo,
        onChange: (e) => {
          this.jatuh_tempo();
          this.master.jatuh_tempo = e.target.value
          // this.hitungJatuhTempo()
        },
        invisibleOnTable: true,
      },
      {
        validation: Yup.string().required("Supplier harus diisi"),
        dataField: params.isForm ? "id_pemasok" : "nama_supplier",
        label: "Supplier",
        type: "select2",
        options: options_supplier,
        value: this.master.nama_supplier,
        onChange: (e, setFieldValue) => {
          this.master.id_pemasok = e ? e.value : ""
          getSupplierTop(e.value).then((val) => {
            this.valDate = val;
            this.jatuh_tempo();
            // console.log(this.valDate);
            // let target = new Date(this.master.tanggal)
            // this.master.jatuh_tempo = moment(target).add(val, 'days').format("YYYY-MM-DD")
            // this.master.jatuh_tempo = moment().add(val, 'days').calendar();
          })
          // console.log('Cek TOP',getSupplierTop(e.target.value));
        },
      },
      {
        validation: Yup.string(),
        dataField: "no_transaksi",
        label: "No. Transaksi",
        type: "disabled-text",
        value: this.master.no_transaksi,
        onChange: (e) => { },
      },
      {
        validation: Yup.string().required("QTY harus diisi"),
        dataField: "qty_total",
        label: "QTY Total",
        type: "disabled-text",
        value: this.master.qty_total,
        onChange: (e) => (this.master.qty_total = e.target.value),
      },
      {
        validation: null,
        dataField: "hpp_total",
        label: "Total Harga",
        type: "disabled-text",
        value: this.master.hpp_total,
        onChange: (e) => (this.master.hpp_total = e.target.value),
      },
      {
        validation: null,
        dataField: "diskon_total",
        label: "Total Diskon",
        type: "disabled-text",
        value: this.master.diskon_total,
        onChange: (e) => (this.master.diskon_total = e.target.value),
        invisibleOnTable: true,
      },
      {
        validation: null,
        dataField: "subtotal",
        label: "Sub Total",
        type: "disabled-text",
        value: this.master.subtotal,
        onChange: (e) => (this.master.subtotal = e.target.value),
        invisibleOnTable: true,
      },
      {
        validation: null,
        dataField: "pajak_total",
        label: "Total Pajak",
        type: "disabled-text",
        value: this.master.pajak_total,
        onChange: (e) => (this.master.pajak_total = e.target.value),
        invisibleOnTable: true,
      },
      {
        validation: null,
        dataField: "total",
        label: "Total",
        type: "disabled-text",
        value: this.master.total,
        onChange: (e) => (this.master.total = e.target.value),
      },
    ];
  }
}

import * as Yup from "yup";
import store from "../../redux/store";
import StokRequestDetailModel from "./StokRequestDetailModel";

export default class DaftarRequestModel {
  constructor(
    data = {
      master: {
        id: 0,
        id_perusahaan: store.getState().auth.id_perusahaan,
        kode: "",
        no_transaksi: "",
        tanggal: "",
        qty_total: 0,
        id_user: store.getState().auth.id,
        deskripsi: "",
        is_approved: 0,
        status_transfer: "",
        cabang_ref: 0,
        approved_spv_by: 0,
        approved_manager_by: 0,
        approved_director_by: 0,
        status: 0,
        create_at: "",
        update_at: "",
        status_invoice: 0,
        nama_cabang_ref: "",
        nama_cabang_tujuan: "",
        status_jurnal: "",
        status_validasi: 0,
      },
      detail: [],
    }
  ) {
    this.master = {
      id: data.master.id,
      id_perusahaan: data.master.id_perusahaan,
      cabang_ref: data.master.cabang_ref,
      kode: data.master.kode,
      no_transaksi: data.master.no_transaksi,
      tanggal: data.master.tanggal,
      qty_total: data.master.qty_total,
      id_user: data.master.id_user,
      deskripsi: data.master.deskripsi,
      is_approved: data.master.is_approved,
      status_transfer: data.master.is_approved === 2 ? "Sudah Approve" : "Menunggu Approval",
      approved_spv_by: data.master.approved_spv_by,
      approved_manager_by: data.master.approved_manager_by,
      approved_director_by: data.master.approved_director_by,
      nama_cabang_ref: data.master.nama_cabang_ref,
      nama_cabang_tujuan: data.master.nama_cabang_tujuan,
      status: data.master.status,
      create_at: data.master.create_at,
      status_jurnal: data.master.status_jurnal,
      status_validasi: data.master.status_validasi,
      update_at: data.master.update_at,
    };
    this.status = (this.master.is_approved === 2) ? "Sudah Approve" : (this.master.status_validasi === 1) ? "Dalam Pengiriman" : "Menunggu Approval"
    this.detail = [];
    this.initDetails(data.detail);
  }

  initDetails(detail) {
    if (detail.length > 0)
      detail.forEach((item) => {
        this.detail = [
          ...this.detail,
          new StokRequestDetailModel({
            ...item,
            id_stok_transfer: this.master.id,
          }),
        ];
      });
  }

  nulldata = {
    master: {
      id: 0,
      id_perusahaan: store.getState().auth.id_perusahaan,
      kode: "",
      no_transaksi: "",
      tanggal: "",
      qty_total: 0,
      id_user: store.getState().auth.id,
      deskripsi: "",
      is_approved: 0,
      approved_spv_by: 0,
      approved_manager_by: 0,
      approved_director_by: 0,
      status: 0,
      nama_cabang_ref: "",
      create_at: "",
      update_at: "",
      toSupplier: {
        nama: "",
      },
    },
    detail: [],
  };

  reset() {
    this(this.nulldata);
  }

  hitungTotalan() {
    let qty_total = 0;
    if (this.detail.length > 0) {
      this.detail.forEach((item) => {
        if (item.status !== 0) {
          qty_total = parseInt(qty_total) + parseInt(item.qty);
        }
      });
    }
    this.master.qty_total = qty_total;
  }


  handleDetail = {
    set: async (item) => {
      item.id_stok_transfer = this.master.id;
      this.detail = [...this.detail, item];
      this.hitungTotalan();
    },
    del: async (dataModel) => {
      this.detail.map((val, i) => {
        return val.id === dataModel.id && val.id_produk === dataModel.id_produk ?
            this.detail[i].status = 0 : ""
        });
      this.hitungTotalan();
    },
    reset: async () => {
      this.detail = [];
      this.hitungTotalan();
    },
    edit: async (item) => {
      // this.detail[this.detail.map((e) => e.id).indexOf(item.id)] = item;
      this.hitungTotalan();
    },
    getSingle: async (id) => this.detail.find((e) => e.id === id),
  };

  
  async getEditable(params = { pageName: "", isForm: false }) {
    console.log('auth',store.getState().auth);
    let options_cabang = []
    if (params.isForm) {
      [20,46,52,69].includes(this.master.id_perusahaan) ?
      options_cabang = [
        { id: 20, label: 'KSA - MAKASSAR', value: 20 },
        { id: 46, label: 'KSA - KENDARI', value: 46 },
        { id: 52, label: 'KSA - MANADO', value: 52 },
        { id: 69, label: 'KSA - HEAD OFFICE', value: 69 }
      ] : [68,28,70,71].includes(this.master.id_perusahaan) ? 
      options_cabang = [
        { id: 68, label: 'KPP - HEAD OFFICE', value: 68 },
        { id: 28, label: 'KPP - MAKASSAR', value: 28 },
        { id: 70, label: 'KPP - MANADO', value: 70 },
        { id: 71, label: 'KPP - KENDARI', value: 71 }
      ] : 
      options_cabang = [
        { id: 108, label: 'KSB - HEAD OFFICE', value: 108 },
        { id: 105, label: 'KSB - MAKASSAR', value: 105 },
        { id: 110, label: 'KSB - MANADO', value: 110 },
        { id: 109, label: 'KSB - KENDARI', value: 109 }
      ]
    }


    return [
      {
        validation: Yup.string().required("Tanggal harus diisi"),
        dataField: "tanggal",
        label: "Tanggal",
        type: "date",
        value: this.master.tanggal,
        onChange: (e) => {
          this.master.tanggal = e.target.value
        },
      },
      {
        validation: Yup.string(),
        dataField: "no_transaksi",
        label: "No. Transaksi",
        type: "disabled-text",
        value: this.master.no_transaksi,
        onChange: (e) => { },
      },
      {
        validation: Yup.string(),
        dataField: "nama_cabang_tujuan",
        label: "Cabang Tujuan",
        value: this.master.nama_cabang_tujuan,
      },
      {
        validation: Yup.string().required("QTY harus diisi"),
        dataField: "qty_total",
        label: "QTY Total",
        type: "disabled-text",
        value: this.master.qty_total,
        onChange: (e) => (this.master.qty_total = e.target.value),
      },
      {
        dataField: "status_transfer",
        label: "Status Request",
        type: "disabled-text",
        value: params.isEdit ? "Test" : this.status,
      },
      {
        validation: Yup.string(),
        dataField: "deskripsi",
        label: "Deskripsi",
        type: "text",
        value: this.master.deskripsi,
        onChange: (e) => (this.master.deskripsi = e.target.value),
        invisibleOnTable: true,
      },
    ];
  }
}

import * as Yup from "yup";
import { separatorHarga} from "../../services/separator-harga";
import moment from "moment";
import { SupplierAPI } from "../../api/supplier";
import StokMasukDetailModel from "./StokMasukDetailModel";

export default class StokMasukModel {
  constructor(
    data = {
      master: {
        id: 0,
        id_pembelian: 0,
        id_perusahaan: 0,
        kode: "",
        no_transaksi: "",
        tanggal: "",
        jatuh_tempo: 0,
        qty_total: 0,
        hpp_total: 0,
        dpp_total: 0,
        diskon_total: 0,
        subtotal: 0,
        pajak_total: 0,
        total: 0,
        terbayar: 0,
        tersisa: 0,
        status_bayar: 0,
        status_invoice: 0,
        status_validasi: 0,
        id_user: 0,
        id_pemasok: 0,
        id_kontak: 0,
        deskripsi: "",
        status: 0,
        created_at: 0,
        updated_at: 0,
      },
      detail: [],
    }
  ) {
    this.master = {
      id: data.master.id,
      id_pembelian: data.master.id_pembelian,
      id_perusahaan: data.master.id_perusahaan,
      kode: data.master.kode,
      no_transaksi: data.master.no_transaksi,
      tanggal: data.master.tanggal,
      jatuh_tempo: data.master.jatuh_tempo,
      qty_total: data.master.qty_total,
      hpp_total: separatorHarga(data.master.hpp_total.toString()),
      dpp_total: separatorHarga(data.master.dpp_total.toString()),
      diskon_total: separatorHarga(data.master.diskon_total.toString()),
      subtotal: separatorHarga(data.master.subtotal.toString()),
      pajak_total: separatorHarga(data.master.pajak_total.toString()),
      total: separatorHarga(data.master.total.toString()),
      terbayar: separatorHarga(data.master.terbayar.toString()),
      tersisa: separatorHarga(data.master.tersisa.toString()),
      status_bayar: data.master.status_bayar,
      status_invoice: data.master.status_invoice,
      status_validasi: data.master.status_validasi,
      id_user: data.master.id_user,
      id_pemasok: data.master.id_pemasok,
      id_kontak: data.master.id_kontak,
      deskripsi: data.master.deskripsi,
      status: data.master.status,
      created_at: data.master.created_at,
      updated_at: data.update_at,
    };
    this.detail = [];
    this.initDetails(data.detail);
  }

  initDetails(detail) {
    if (detail.length > 0)
      detail.forEach((item) => {
        let diskon_persen = (item.diskon / item.hpp_total) * 100;
        let pajak_persen = (item.pajak / item.subtotal) * 100;
        this.detail = [
          ...this.detail,
          new StokMasukDetailModel({
            ...item,
            id_penerimaan_pembelian: this.master.id,
            diskon_persen: diskon_persen,
            pajak_persen: pajak_persen,
          }),
        ];
      });
  }

  nulldata = {
    master: {
      id: 0,
      id_pembelian: 0,
      id_perusahaan: 0,
      kode: "",
      no_transaksi: "",
      tanggal: "",
      jatuh_tempo: 0,
      qty_total: 0,
      hpp_total: 0,
      dpp_total: 0,
      diskon_total: 0,
      subtotal: 0,
      pajak_total: 0,
      total: 0,
      terbayar: 0,
      tersisa: 0,
      status_bayar: 0,
      status_invoice: 0,
      status_validasi: 0,
      id_user: 0,
      id_pemasok: 0,
      id_kontak: 0,
      deskripsi: "",
      status: 0,
      created_at: 0,
      updated_at: 0,
    },
    detail: [],
  };

  reset() {
    this(this.nulldata);
  }

  async getEditable(params = { pageName: "", isForm: false }) {
    const getSupplierTop = async (id) => {
      return await new SupplierAPI()
        .getSingle(id)
        .then((res) => res.data.nilai_top);
    };

    return [
      {
        validation: Yup.string().required("Tanggal harus diisi"),
        dataField: "tanggal",
        label: "Tanggal Invoice",
        type: this.master.kode === "" ? "date" : "disabled-text",
        value: this.master.tanggal,
        onChange: (e) => {
          this.master.tanggal = e.target.value
          getSupplierTop(this.master.id_pemasok).then((val) => {
            this.valDate = val;
            this.jatuh_tempo();
            console.log('cek id pemasok',this.master.id_pemasok);
          })
        },
      },
      {
        validation: Yup.string().required("Jatuh tempo harus diisi"),
        dataField: "jatuh_tempo",
        label: "Jatuh Tempo",
        type: "disabled-text",
        value: this.master.jatuh_tempo,
        onChange: (e) => (this.master.jatuh_tempo = e.target.value),
        invisibleOnTable: true,
      },
      {
        validation: Yup.string().required("No Invoice harus diisi"),
        dataField: "kode",
        label: "No Invoice",
        type: this.master.kode === "" ? "text" : "disabled-text",
        value: this.master.kode === "" ? "(belum input no invoice)" : this.master.kode,
        onChange: (e) => {
          this.master.kode = e.target.value;
        },
      },
      {
        validation: Yup.string(),
        dataField: "no_transaksi",
        label: "No Transaksi",
        type: "disabled-text",
        value: this.master.no_transaksi,
        onChange: (e) => {},
      },
      {
        validation: Yup.string().required("QTY harus diisi"),
        dataField: "qty_total",
        label: "QTY Total",
        type: "disabled-text",
        value: this.master.qty_total,
        onChange: (e) => (this.master.qty_total = e.target.value),
      },
      {
        validation: Yup.string(),
        dataField: "deskripsi",
        label: "Deskripsi",
        type: "disabled-text",
        value: this.master.deskripsi,
        onChange: (e) => (this.master.deskripsi = e.target.value),
        invisibleOnTable: true,
      },
    ];
  }
}

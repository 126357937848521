import moment from "moment";
import { separatorHarga } from "../../../../services/separator-harga";

export default class listTransaksiBankOperasionalModel {
  constructor(
    data = {
      no_voucher: "",
      admin: "",
      tanggal: "",
      bank: "",
      keterangan: "",
      debet: 0,
      kredit: 0,
      saldo: 0
    }
  ) {
    const { no_voucher, admin, tanggal, bank, keterangan, debet, kredit, saldo } = data;
    this['no_voucher'] = no_voucher;
    this['admin'] = admin;
    this['tanggal'] = tanggal;
    this['bank'] = bank;
    this['keterangan'] = keterangan;
    this['debet'] = debet;
    this['kredit'] = kredit;
    this['saldo'] = saldo;
  }

  async getdata() {
    const { no_voucher, admin, tanggal, bank, keterangan, debet, kredit, saldo } = this;
    return [
      {
        id: "no_voucher",
        title: "No Voucher",
        label: no_voucher,
        center: true,
        sortable: true,
        minWidth: "25%",
      }, {
        id: "admin",
        title: "Admin",
        label: admin,
        minWidth: "20%",
        sortable: false,
      }, {
        id: "tanggal",
        title: "Tanggal",
        label: moment(tanggal).format("DD-MM-YYYY"),
        center: true,
        sortable: true,
        minWidth: "17%",
      }, {
        id: "bank",
        title: "Nama Bank",
        label: bank,
        sortable: true,
        minWidth: "30%",
      }, {
        id: "keterangan",
        title: "Keterangan",
        label: keterangan,
        sortable: true,
        minWidth: "60%",
      }, {
        id: "debet",
        title: "Debet",
        label: debet < 0 ? `Rp. -${separatorHarga(debet.toString())}` : separatorHarga(debet.toString(), "Rp. "),
        right: true,
        sortable: false,
        minWidth: "15%",
      }, {
        id: "kredit",
        title: "Kredit",
        label: kredit < 0 ? `Rp. -${separatorHarga(kredit.toString())}` : separatorHarga(kredit.toString(), "Rp. "),
        right: true,
        sortable: false,
        minWidth: "15%",
      }, {
        id: "saldo",
        title: "Saldo",
        label: saldo < 0 ? `Rp. -${separatorHarga(saldo.toString())}` : separatorHarga(saldo.toString(), "Rp. "),
        right: true,
        sortable: false,
        minWidth: "15%",
      }];
  }
}

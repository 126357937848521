import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import MutasiPengeluaranBankOperasional from "./mutasi";
import DetailPengeluaranBankOperasional from "../list/detail";
import React from "react";
import { dispatcher } from "../../../../../redux/dispatcher";

export default function IndexPengeluaranBankOperasional ()
{
  const history = useHistory();
  const active = dispatcher[ 'kasir_bank_operasional' ].getPengeluaranBankOperasional();
  return (
    <Switch>
      <Route path={ "/kasir/mutasi-pengeluaran-bank-operasional/mutasi" }>
        <MutasiPengeluaranBankOperasional />
      </Route>
      { active[ 'transaksi' ][ 'id' ] === 0 && (
        <Redirect to={ "/kasir/mutasi-pengeluaran-bank-operasional/mutasi" } />
      ) }
      <Route path="/kasir/mutasi-pengeluaran-bank-operasional/detail">
        <DetailPengeluaranBankOperasional history={ history } />
      </Route>
      <Redirect to={ "/kasir/mutasi-pengeluaran-bank-operasional/mutasi" } />
    </Switch>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Link } from "react-router-dom";
import { dispatcher } from "../../../redux/dispatcher";
import SimpleAlert from "../../../components/alert/alert";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import { PesananPenjualanAPI } from "../../../api/pesanan-penjualan";
import InvoicePenjualanModel from "../../../models/invoice-penjualan-model/InvoicePenjualanModel";
import moment from "moment";
import Select2 from "react-select";
import store from "../../../redux/store";

export default function List(params) {
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const simpleTableModel = new SimpleTableModel();
  const [startDate, setStartDate] = useState(moment(new Date()).startOf('month').format("YYYY-MM-DD"))
  const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [search, setSearch] = useState("")
  const [cabang, setCabang] = useState(store.getState().auth.id_perusahaan);
  const [options, setOptions] = useState([]);

  const coverage = async () => {
    let opsi = [];
    await new PesananPenjualanAPI().getCoverage().then((res) => {
      opsi = res.data.map((data) => ({
        label: data.text,
        text: data.text,
        value: data.id,
      }));
    });
    setOptions(opsi);
  };

  const init = async () => {
    let datas = await new PesananPenjualanAPI().indexForInvoice(cabang);
    let searchParams = ['nama_pelanggan','nama_organisasi','nama_sales']
    let filtered = datas.data.filter(
      (filter) => {
        let dateFormat = moment(filter.master.created_at).format("YYYY-MM-DD");
      let dateFilter = dateFormat >= startDate && dateFormat <= endDate;
      // let cabangSelected = filter.master.id_perusahaan === cabang;
      return searchParams.some((item) => {
        return (
          dateFilter &&
          // cabangSelected &&
          filter.master[item]
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())
        );
      });
      }
    );
    filtered.map((item, i) => {
      let dataModel = new InvoicePenjualanModel(item);
      simpleTableModel.add({
        id: i,
        no: (i += 1),
        dataModel: dataModel,
        actions: (
          <span>
            <Link
              className="mx-1 btn btn-outline-success btn-sm"
              to="/tambah-penjualan/tambah"
              onClick={() => {
                params.setDataFromModel(dataModel);
                // console.log('DataModel On Click',dataModel);
                dispatcher.pesanan_penjualan.set(dataModel);
              }}
            >
              Buat Invoice
            </Link>
          </span>
        ),
      });
      return null;
    });
    setColumntable(await simpleTableModel.getColumnHeaders());
    setDatatable(await simpleTableModel.getDatatables());
  };

  const [id4delete, setId4delete] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const handleAlert = {
    show: () => {
      setShowAlert(true);
    },
    hide: () => {
      setShowAlert(false);
    },
    confirmed: async () => {
      await new PesananPenjualanAPI.delete(id4delete);
      init();
      handleAlert.hide();
    },
  };

  useEffect(() => {
    init();
    coverage();
  }, [startDate, endDate, search, cabang]);

  return (
    <>
      <SimpleAlert
        title="Hapus Data"
        content="Anda yakin hapus data ini?"
        show={showAlert}
        onConfirmed={handleAlert.confirmed}
        onHide={handleAlert.hide}
      />
      <AnimatedCard>
        <CardHeader title="Tambah Invoice Penjualan">
          <CardHeaderToolbar></CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Mulai </label>
              <input
                name={"tanggal_awal"}
                type={"date"}
                value={startDate}
                className={"form-control"}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  init();
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Akhir </label>
              <input
                name={"tanggal_akhir"}
                type={"date"}
                value={endDate}
                className={"form-control"}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  init();
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Pilih Perusahaan </label>
              <Select2
                name={"Cabang"}
                placeholder={" --- Pilih Perusahaan --- "}
                size="sm"
                options={options}
                isClearable={false}
                value={
                  options
                    ? options.find(
                      (data) => data.value === cabang
                    )
                    : ""
                }
                onChange={(e) => {
                  setCabang(e ? e.value : []);
                  // setFieldValue("cabang", e ? e.value : "");
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <input
                type={"text"}
                className={"form-control"}
                placeholder="Cari Data ..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  init();
                  // console.log(search);
                }}
              />
            </div>
          </div>
          <SimpleTable data={datatable} columns={columntable} />
        </CardBody>
      </AnimatedCard>
    </>
  );
}

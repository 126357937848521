import { simpleFetch } from "./api";
import store from "../redux/store";
import { menuAction } from "../redux/menu/actions";

export async function getAllRoles() {
  let roles = await simpleFetch({
    url: "retail/role",
    method: "GET",
  });
  return roles;
}

export async function getAllMenu() {
  let menus = await simpleFetch({
    url: "retail/role/menu",
    method: "GET",
  });
  return menus;
}

export async function getAllMenuSingleRole(idRole) {
  let menus = await simpleFetch({
    url: "retail/role/menu/checked?id_roles=" + idRole,
    method: "GET",
  });
  return menus;
}

export async function setRoleMenu(values) {
  let menus = await simpleFetch({
    url: "retail/role/menu",
    method: "POST",
    body: {
      id_roles: values.idRole,
      checked: values.checked,
    },
  });
  return menus;
}

export async function setRole(values) {
  let outp = await simpleFetch({
    url: "retail/role",
    method: "POST",
    body: {
      nama: values.nama,
    },
  });
  return outp;
}

export async function getSingleRole(idRole) {
  let outp = await simpleFetch({
    url: "retail/role?id=" + idRole,
    method: "GET",
  });
  return outp;
}

export async function editRole(values) {
  let outp = await simpleFetch({
    url: "retail/role",
    method: "PUT",
    body: {
      id: values.id,
      nama: values.nama,
    },
  });
  return outp;
}

export async function delRole(idRole) {
  let outp = await simpleFetch({
    url: "retail/role",
    method: "DELETE",
    body: {
      id: idRole,
    },
  });
  return outp;
}

// import { getIdPerusahaan, simpleFetch } from "./api";
// export class KonfigurasiRoleAPI {
//   async getAll() {
//     let res = await simpleFetch({
//       url: "retail/role",
//       method: "GET",
//     });
//     return res;
//   }

//   async getSingle(id) {
//     let res = await simpleFetch({
//       url: "retail/role?id=" + id,
//       method: "GET",
//     });
//     return res;
//   }

//   async set(values) {
//     let res = await simpleFetch({
//       url: "retail/role",
//       method: "POST",
//       body: {
//         ...values,
//       },
//     });
//     return res;
//   }

//   async edit(values) {
//     let res = await simpleFetch({
//       url: "retail/role",
//       method: "PUT",
//       body: {
//         id_perusahaan: getIdPerusahaan(),
//         ...values,
//       },
//     });
//     return res;
//   }

//   async delete(id) {
//     let res = await simpleFetch({
//       url: "retail/role",
//       method: "DELETE",
//       body: {
//         id: id,
//       },
//     });
//     return res;
//   }
// }

// export class RoleMenuAPI {
//   async getAll(id) {
//     let res = await simpleFetch({
//       url: "retail/role/menu/checked?id_roles=" + id,
//       method: "GET",
//     });
//     return res;
//   }

//   async set(values) {
//     let res = await simpleFetch({
//       url: "retail/role",
//       method: "POST",
//       body: {
//         ...values,
//       },
//     });
//     return res;
//   }
// }

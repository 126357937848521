const initialValues = {
  active: null,
  list: [],
};

export const penjualanAction = {
  list: "LIST_PENJUALAN",
  active: "ACTIVE_PENJUALAN",
  resetActive: "RESET_ACTIVE_PENJUALAN",
};

const reducer = (state = initialValues, action) => {
  let states;
  switch (action.type) {
    case penjualanAction.list:
      states = state;
      states.list = action.payload;
      return (state = states);
    case penjualanAction.active:
      states = state;
      states.active = action.payload;
      return (state = states);
    case penjualanAction.resetActive:
      states = state;
      states.active = null;
      return (state = states);
    default:
      return state;
  }
};

export default reducer;

/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import {
    CardBody,
} from "../../../../../_metronic/_partials/controls";
import { ReactDatatable } from "../../../../components/react-datatable/reactDatatable";
import { Button } from "react-bootstrap";
import { useState } from "react";
import store from "../../../../redux/store";
import { LaporanGrafik } from "../../../../api/laporan-grafik";
import { useEffect } from "react";
import Select2 from "react-select";
import moment from "moment";
import { StokAPI } from "../../../../api/stok";
import { Link } from "react-router-dom";
import { excelStok } from "./exportExcelStok";

function LaporanAllStok() {
    const columns = [
        {
            name: "Nama Barang",
            selector: (row) => row.nama,
            sortable: true,
            minWidth: "25%",
        },
        {
            name: "Kode Barang",
            selector: (row) => row.kode,
            sortable: true,
            minWidth: "20%",
        },
        {
            name: "Satuan",
            selector: (row) => row.satuan,
            sortable: true,
            minWidth: "10%",
        },
        // {
        //     name: <div style={{ textAlign: "center" }}>Stok Awal</div>,
        //     selector: (row) => row.ready,
        //     sortable: true,
        //     minWidth: "10%",
        //     // width: "fit-content",
        // },
        {
            name: <div style={{ textAlign: "center" }}>Stok Awal</div>,
            selector: (row) => row.awal,
            sortable: true,
            minWidth: "10%",
        },
        // {
        //     name: <div style={{ textAlign: "center" }}>Stok Alokasi</div>,
        //     selector: (row) => row.alokasi,
        //     sortable: true,
        //     minWidth: "10%",
        // },
        {
            name: <div style={{ textAlign: "center" }}>Stok Masuk</div>,
            selector: (row) => row.masuk,
            sortable: true,
            minWidth: "10%",
        },
        {
            name: <div style={{ textAlign: "center" }}>Stok Keluar</div>,
            selector: (row) => row.keluar,
            sortable: true,
            minWidth: "10%",
        },
        {
            name: <div style={{ textAlign: "center" }}>Stok Akhir</div>,
            selector: (row) => row.akhir,
            sortable: true,
            minWidth: "10%",
        },
        {
            name: <div style={{ textAlign: "center" }}>Aksi</div>,
            cell: (row) => <Link
                className="mx-1 btn btn-outline-success btn-sm"
                title="Detail Data"
                to={{
                    pathname: "/stok-gudang/detail",
                    state: {
                        id_produk: row.id,
                        kode: row.kode,
                        nama: row.nama,
                        ready: row.awal,
                        akhir: row.akhir,
                        satuan: row.satuan,
                        tanggal: date,
                        tanggalAkhir: dateAkhir
                    }
                }}
                >
                Detail
            </Link>,
            // <Button className="primary" onClick={handleChange} id={row.id}>Detail</Button>,
            // ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            minWidth: "10%",
        },
    ];
    const [date, setDate] = useState(
        moment(new Date())
        .startOf("month")
        .format("YYYY-MM-DD")
    );
    const [dateAkhir, setDateAkhir] = useState(
        moment(new Date())
        .endOf("month")
        .format("YYYY-MM-DD")
    );
    
    const [data, setData] = useState([]);
    const [cabangTitle, setTitle] = useState(`${store.getState().auth.toPerusahaan.singkat} - ${store.getState().auth.toPerusahaan.lokasi}`);
    const title = `Laporan Stok ${cabangTitle} from ${date} until ${dateAkhir}`;
    const [cabang, setCabang] = useState(store.getState().auth.id_perusahaan);
    const [opsi, setOptions] = useState([]);
    let opsiCabang = [];
    
    const coverage = async () => {
        await new LaporanGrafik().getCoverage().then((res) => {
            opsiCabang = res.data.map((data) => ({
                label: data.text,
                text: data.text,
                value: data.id,
            }));
        });
        setOptions(opsiCabang);
    };


    const fetchData = async (data) => {
        try {
            await new StokAPI().laporanStok(data).then((res) => {
                // console.log(
                //     "Valll",
                //     res.data.map((val) => val)
                // );
                let datas = [];
                res.data.map((v) => {
                    let newData = {
                        id: v.id_produk,
                        nama: v.nama_produk,
                        kode: v.kode,
                        satuan: v.satuan,
                        awal: v.masuk - v.keluar || 0,
                        masuk: v.masuknew,
                        keluar: v.keluarnew || 0,
                        akhir: (((v.masuk - v.keluar) + v.masuknew) - v.keluarnew) || 0
                    };
                    return (datas = [...datas, newData]);
                });
                setData(datas);
                // dataSeries.push(val)
            });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData({ cabang, date, dateAkhir });
        coverage();
        // }
    }, []);
    return (
        <>
            <AnimatedCard>
                <CardBody>
                    <div className="d-flex justify-content-start form-group row">
                        <div className="col-lg-3" style={{ marginTop: "20px" }}>
                            <label> Tanggal Awal </label>
                            <input
                                name={"tanggal_awal"}
                                type={"date"}
                                value={date}
                                className={"form-control"}
                                onChange={(e) => {
                                    setDate(e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-lg-3" style={{ marginTop: "20px" }}>
                            <label> Tanggal Akhir </label>
                            <input
                                name={"tanggal_akhir"}
                                type={"date"}
                                value={dateAkhir}
                                className={"form-control"}
                                onChange={(e) => {
                                    setDateAkhir(e.target.value);
                                }}
                            />
                        </div>
                        <div
                            className="col-lg-3"
                            style={{ marginTop: "20px", zIndex: "4" }}
                        >
                            <label> Pilih Perusahaan </label>
                            <Select2
                                name={"Cabang"}
                                placeholder={opsi.text}
                                size="sm"
                                options={opsi}
                                isClearable={true}
                                value={
                                    opsi ? opsi.find((option) => option.value === cabang) : ""
                                }
                                onChange={(e) => {
                                    setTitle(e.text)
                                    setCabang(e ? [e.value] : [0]);
                                }}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-start">
                        <div style={{ marginRight: "2%" }}>
                            <Button
                                variant="info"
                                onClick={(e) => {
                                    e.preventDefault();
                                    fetchData({ cabang, date, dateAkhir });
                                }}
                            >
                                <i className="fas fa-search"></i>
                                Cari Data
                            </Button>
                        </div>
                        <div>
                            <Button onClick={(e) => excelStok(data,title)}>
                                <i className={"far fa-file-excel"}></i> {" Export .xls"}
                            </Button>
                        </div>
                    </div>
                    <ReactDatatable
                        columns={columns}
                        data={data}
                        pagination
                        highlightOnHover
                        searchParameter={["nama", "kode"]}
                    // actions={actionsMemo}
                    />
                </CardBody>
            </AnimatedCard>
        </>
    );
}

export default LaporanAllStok
import XlsxPopulate from "xlsx-populate";
import moment from "moment";
import { saveAs } from "file-saver";

// Get Sheet Data Export To Excel
function getSheetData(data, header) {
  var fields = Object.keys(data[0]);
  var sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
}

// Export To Excell
function saveAsExcel(data, title) {
  console.log('Data For Excel', data);
  let currDate = moment().format('YYYY-MM-DD')
  let dataList = [];
  data.map((data, i) => {
    dataList.push({
      noInvoice: data.no_invoice,
      noTransaksi: data.no_transaksi,
      tglInv: data.tanggal_invoice,
      nama: data.nama_supplier,
      jTempo: data.jatuh_tempo,
      total: data.total || "0",
      terbayar: data.terbayar || "0",
      tersisa: data.tersisa || "0",
      sisaJtempo: data.sisa_jTempo,
    })
    return data;
  });
  let header = [
    'No Invoice',
    'No Transaksi',
    'Tanggal Invoice',
    'Nama Supplier',
    'Jatuh Tempo',
    'Total',
    'Terbayar',
    'Tersisa',
    'Sisa Jatuh Tempo',
  ];
  let dataLength = data.length + 1;
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);
    const sheetData = getSheetData(dataList, header);
    const totalColumns = sheetData[0].length;
    sheet1.cell("A1").value(sheetData);
    const range = sheet1.usedRange();
    const endColumn = String.fromCharCode(64 + totalColumns);
    sheet1.row(1).style("bold", true).height(25);
    sheet1.range("A1:" + endColumn + "1").style({
      fill: "BFBFBF",
      horizontalAlignment: "center",
      verticalAlignment: "center",
    });
    // Styls Center Align
    let centerAlign = ["A", "B", "C", "D", "E", "F", "G","H","I"];
    for (let i = 0; i < centerAlign.length; i++) {
      sheet1.range(centerAlign[i] + "2:" + centerAlign[i] + dataLength).style({
        horizontalAlignment: "center"
      });

    }
    let valFormat = ["F", "G","H"];
    // Styls wrapText
    valFormat.forEach((data) => {
      sheet1.range(`${data}2:${data}` + dataLength).style({
        numberFormat: "Rp#,###",
        // horizontalAlignment: "right",
      });
    })
    range.style("border", true);
    sheet1.column("A").width(23);
    sheet1.column("B").width(30);
    sheet1.column("C").width(40);
    sheet1.column("D").width(30);
    sheet1.column("E").width(23);
    sheet1.column("F").width(23);
    sheet1.column("G").width(23);
    sheet1.column("H").width(23);
    sheet1.column("I").width(25);
    return workbook.outputAsync().then((res) => {
      saveAs(res, "Laporan (" + title + " " + currDate + ").xlsx");
    });
  });
}

export { saveAsExcel, getSheetData }
import * as Yup from "yup";
import { CardBody, CardFooter, CardHeader, CardHeaderToolbar } from "../../../../../../_metronic/_partials/controls";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { AnimatedCard } from "../../../../../components/animated-card/animatedCard";
import DataTableModel from "../../../../../models/datatable-model/DataTableModel";
import { FormsFields } from "../../../../../components/forms-fields/FormsFields";
import { ModalForms } from "../../../../../components/modal-forms/modalForms";
import { ReactDatatableDetails } from "../../../../../components/react-datatable/reactDatatable";
import SimpleAlert from "../../../../../components/alert/alert";
import { Typography } from "@material-ui/core";
import { dispatcher } from "../../../../../redux/dispatcher";
import { kasirKasAPI } from "../../../../../api/kasir-kas";
import moment from "moment";
import pengeluaranKasDetailModel from "../../../../../models/kasir/kas/pengeluaranKasDetailModel";
import { hapusSeparator } from "../../../../../services/separator-harga";
import ButtonLoad from "../../../../../components/button-loading/buttonLoad";
import ButtonActions from "../../../../../components/button-actions/buttonActions";
import { getIdPerusahaan } from "../../../../../api/api";
export default class FormPengeluaranKas extends Component {
  constructor(props) {
    super(props);
    this['pageName'] = "Form Pengeluaran Kas";
    this['apiPengeluaranKas'] = new kasirKasAPI();
    this['initialData'] = dispatcher['kasir_kas'].getPengeluaranKas();
    this['initialDetailData'] = new pengeluaranKasDetailModel();
    this['option_jenis_pengeluaran'] = [];
    this['state'] = {
      submitForm: false,
      no_transaksi: "",
      total_detail: "",
      ceklist_detail: false,
      modal: {
        show: false,
        initialDetailData: this['initialDetailData'],
        isEdit: true,
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
    this['datatable'] = [{ id: "", no: "", name: "" }];
    this['columntable'] = [{ id: "", title: "" }];
  }
  //* ----------------------------------------------------------------------------- */
  async componentDidMount() {
    await this.LoadDetail();
    //** Start: Load Options Jenis Pengeluaran */
    this['apiPengeluaranKas'].getJenisTransaksi("kas").then((res) => {
      this['option_jenis_pengeluaran'] = res['data'].map((val) => {
        return {
          value: val['id'],
          label: val['text'],
        };
      });
    });
    //** End: Load Options Jenis Pengeluaran */
  }
  //* ----------------------------------------------------------------------------- */
  //** Start: Sending Data */
  async sendingData(values, setSubmitting) {
    let dataSend = {};
    let data = {
      id_transaksi_jenis: parseInt(values['id_transaksi_jenis']),
      tanggal: moment(values['tanggal']).format("YYYY-MM-DD"),
      kontak_ref: values['kontak_ref'],
      deskripsi: values['deskripsi'],
      id_user: parseInt(values['id_user']),
      id_perusahaan: getIdPerusahaan(),
    };

    let data_details = this['initialData']['detail'];
    if (data_details.length > 0) {
      dataSend = {
        ...data,
        total: this['state']['total_detail'],
        data_detail: data_details,
      }
    } else {
      dispatcher['snackbar'].show("Gagal", "Data detail tidak boleh kosong!", "danger");
      this.setState({ submitForm: false });
      setSubmitting(false);
      return true;
    }
    await this['apiPengeluaranKas'].setPengeluaranKas(dataSend).then((res) => {
      this.setState(res['data']); // set state No Transaksi
      if (res['status']) {
        dispatcher['snackbar'].show("Berhasil", res.message, "primary");
        this.setState({ submitForm: true });
        setSubmitting(true);
        this.LoadDetail();
      } else {
        dispatcher['snackbar'].show("Gagal", res.message, "danger");
        this.setState({ submitForm: false });
        setSubmitting(false);
      }
    });
  }
  //** End: Sending Data */
  //* ----------------------------------------------------------------------------- */
  //** Start: Load Detail Table */
  async LoadDetail() {
    let data = this['initialData']['detail'];
    this.setState({
      total_detail: data.reduce((total, value) => {
        const jumlah = typeof value['jumlah'] === "string" ? parseInt(hapusSeparator(value['jumlah'])) : value['jumlah'];
        if (value['ceklist']) {
          return (total - jumlah);
        } else {
          return (total + jumlah);
        }
      }, 0),
    });
    this['DataTableModel'] = new DataTableModel({ readOnly: this['state']['submitForm'] });
    data.map((item, i) => {
      if (item['status'] === 1) {
        this['DataTableModel'].add({
          id: i,
          no: i + 1,
          dataModel: item,
          actions:
            (!this['state']['submitForm']) ? (
              <>
                <ButtonActions
                  label={"edit"}
                  onClick={async () =>
                    await this['handleModal'].edit(item)
                  }
                />
                <ButtonActions
                  label={"delete"}
                  onClick={async () =>
                    this['handleAlert'].show(item)
                  }
                />
              </>
            ) : (null),
        });
      }
      return item;
    });
    this['columntable'] = await this['DataTableModel'].getColumn();
    this['datatable'] = await this['DataTableModel'].getDatas();
    this.setState({ ...this['state'] });
  }
  //** End: Load Detail Table */
  //* ----------------------------------------------------------------------------- */
  //** Start: Handle Modal/Alert */
  handleModal = {
    loadOptionAkunDetails: async () => {
      this['apiPengeluaranKas'].getAkunPengeluaran().then((res) => {
        // Load Data option Akun
        this['option_akun'] = res['data'].map((val) => {
          return {
            value: val['id'],
            label: val['text'],
            kode_akun: val['kode_akun'],
            nama_akun: val['nama_akun'],
          };
        });
      });
    },
    tambah: async () => {
      await this['handleModal'].loadOptionAkunDetails();
      this.setState({
        modal: {
          show: true,
          title: "Tambah Dana",
          isEdit: false,
          initialDetailData: new pengeluaranKasDetailModel()
        }
      });
    },
    edit: async (dataFromModel) => {
      await this['handleModal'].loadOptionAkunDetails();
      this.setState({
        modal: {
          show: true,
          title: "Edit Dana",
          isEdit: true,
          initialDetailData: new pengeluaranKasDetailModel({
            ...dataFromModel,
          })
        }
      });
    },
    hide: () => {
      this.setState({
        modal: {
          show: false,
          title: "",
          isEdit: false,
          initialDetailData: new pengeluaranKasDetailModel()
        }
      });
    },
  };
  handleAlert = {
    // Show Alert
    show: (dataModel) => {
      this.setState({
        alert: {
          activeId: dataModel,
          show: true
        }
      });
    },
    // Hide Alert
    hide: () => {
      this.setState({
        alert: {
          activeId: null,
          show: false
        }
      });
    },
    // Confirmed Alert
    confirmed: async () => {
      await this['initialData']['handleDetail'].del(this['state']['alert']['activeId']);
      this['handleAlert'].hide();
      await this.LoadDetail();
    },
  };
  //** End: Handle Modal/Alert */
  //* ----------------------------------------------------------------------------- */
  render() {
    const { no_transaksi, total_detail, modal, alert, submitForm } = this['state'];
    return (
      <>
        {/* Start: Formik Form */}
        <Formik
          enableReinitialize={true}
          initialValues={this['initialData']['transaksi']}
          validationSchema={Yup.object().shape({
            tanggal: Yup.string().nullable().required(),
            kontak_ref: Yup.string().required(),
            deskripsi: Yup.string()
              .max(150, "Maximum 150 String")
              .required("Keterangan harus diisi")
          })}
          onSubmit={(values, { setSubmitting }) => {
            this.sendingData(values, setSubmitting);
          }}
        >
          {({ handleSubmit, isSubmitting, touched, errors, setFieldValue, setFieldTouched, values }) => (
            <AnimatedCard>
              <CardHeader title={this['pageName']}>
                <CardHeaderToolbar>
                  {/* Button Cetak Pengeluaran */}
                  {submitForm ? (
                    <>
                      {/* Button Cetak Bukti Pengeluaran */}
                      <button
                        type={"button"}
                        className={"btn btn-outline-success mx-1 btn-sm"}
                        onClick={async () => {
                          dispatcher['kasir_kas'].cetakBuktiPengeluaran(no_transaksi);
                        }}
                      ><i className={"fa fa-envelope-open-text"}></i> Bukti Pengeluaran
                      </button>
                      {/* Button Transaksi Baru */}
                      <button
                        type={"button"}
                        className={"btn btn-outline-primary mx-1 btn-sm"}
                        onClick={async () => {
                          window['location'].reload();
                        }}
                      ><i className={"fa fa-plus"}></i> Transaksi Baru
                      </button>
                    </>
                  ) : (
                    <>
                      {/* Button Simpan */}
                      <ButtonLoad
                        label={"Simpan"}
                        pending={isSubmitting}
                        disabled={isSubmitting}
                        classNameIcon={"fas fa-save"}
                        className={"btn btn-primary mx-1"}
                        onClick={async () => handleSubmit()}
                      />
                    </>
                  )}
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <Form className={"form form-label-right"}>
                  <div className={"form-group row"}>
                    <div className={"col-lg-6"}>
                      <div className={"form-group row"}>
                        {/* Jenis Pengeluaran */}
                        <FormsFields
                          id={"id_transaksi_jenis"}
                          type={"select2"}
                          label={"Jenis Pengeluaran"}
                          className={"col-lg-12"}
                          isDisabled={true}
                          options={this['option_jenis_pengeluaran']}
                          paramsFormik={{
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          }}
                        />
                        {/* Dibayar Kepada */}
                        <FormsFields
                          id={"kontak_ref"}
                          type={"text"}
                          label={"Dibayar Kepada"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          disabled={isSubmitting}
                          paramsFormik={{
                            setFieldValue,
                            values
                          }}
                        />
                      </div>
                    </div>
                    <div className={"col-lg-6"}>
                      <div className={"form-group row"}>
                        {/* Tanggal Transaksi */}
                        <FormsFields
                          id={"tanggal"}
                          type={"DatePicker"}
                          label={"Tanggal Transaksi"}
                          className={"col-lg-12"}
                          disabled={isSubmitting}
                          paramsFormik={{
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          }}
                        />
                        {/* Keterangan */}
                        <FormsFields
                          id={"deskripsi"}
                          type={"textarea"}
                          label={"Keterangan"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          disabled={isSubmitting}
                          rows={3}
                          paramsFormik={{
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          }}
                        />
                        {/* Total */}
                        <FormsFields
                          id={"total"}
                          type={"currency"}
                          label={"Total"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          disabled={true}
                          value={total_detail}
                          paramsFormik={{
                            setFieldValue,
                            values
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              </CardBody>
              <CardFooter>
                {/* Start: Detail Dana */}
                <span className={"d-flex align-items-center justify-content-between mt-4 mb-4"}>
                  <Typography variant={"h6"}>Detail</Typography>
                  {(!submitForm) ? (
                    <>
                      <button
                        type={"button"}
                        className={"btn btn-outline-primary btn-sm"}
                        onClick={async () => {
                          await this['handleModal'].tambah();
                        }}
                      >
                        <i className={"fa fa-plus"}></i>
                        {"Tambah Dana"}
                      </button>
                    </>
                  ) : ""}
                </span>
                <ReactDatatableDetails
                  title={""}
                  data={this['datatable']}
                  columns={this['columntable']}
                />
                {/* End: Detail Dana */}
              </CardFooter>
            </AnimatedCard>
          )}
        </Formik>
        {/* End: Formik Form */}
        {/* ----------------------------------------------------------------------------- */}
        {/* Start: Modal Detail Form */}
        <Formik
          enableReinitialize={true}
          initialValues={modal['initialDetailData']}
          validationSchema={Yup.object().shape({
            id_akun: Yup.string()
              .ensure()
              .required("Akun harus diisi"),
            deskripsi: Yup.string()
              .max(150, "Maximum 150 String")
              .required("Keterangan harus diisi"),
            jumlah: Yup.string().required(),
          })}
          onSubmit={async (values, { resetForm }) => {
            if (modal['isEdit']) {
              await this['initialData']['handleDetail'].edit(values);
            } else {
              await this['initialData']['handleDetail'].set(values);
            }
            await this.LoadDetail();
            resetForm();
            this['handleModal'].hide();
          }}
        >
          {({ handleSubmit, setFieldValue, setFieldTouched, touched, errors, values }) => (
            <ModalForms
              show={modal['show']}
              title={modal['title']}
              onHide={this['handleModal']['hide']}
              onConfirm={handleSubmit}
              content={
                <div className={"form-group row"}>
                  {/* Akun */}
                  <FormsFields
                    id={"id_akun"}
                    type={"select2"}
                    label={"Akun"}
                    className={"col-lg-12"}
                    isClearable={false}
                    isSearchable={true}
                    options={this['option_akun']}
                    onChange={(e) => {
                      setFieldValue("kode_akun", e ? e['kode_akun'] : "");
                      setFieldValue("nama_akun", e ? e['nama_akun'] : "");
                    }}
                    paramsFormik={{
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    }}
                  />
                  {/* Keterangan */}
                  <FormsFields
                    id={"deskripsi"}
                    type={"textarea"}
                    label={"Keterangan"}
                    className={"col-lg-12"}
                    style={{ marginTop: "20px" }}
                    rows={2}
                    paramsFormik={{
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    }}
                  />
                  {/* Jumlah */}
                  <FormsFields
                    id={"jumlah"}
                    type={"currency"}
                    label={"Jumlah"}
                    className={"col-lg-12"}
                    style={{ marginTop: "20px" }}
                    value={values['jumlah']}
                    paramsFormik={{
                      setFieldValue
                    }}
                  />
                  {/* Negatif */}
                  <FormsFields
                    id={"ceklist"}
                    type={"checkbox"}
                    label={"Negatif"}
                    indeterminate={true}
                    className={"col-lg-12"}
                    style={{ marginTop: "5px" }}
                    paramsFormik={{
                      setFieldValue,
                      values
                    }}
                  />
                  {/* Upload Gambar */}
                  <FormsFields
                    id={"upload_gambar_bop_kas"}
                    type={"image"}
                    label={"Upload Gambar"}
                    className={"col-lg-12"}
                    style={{ marginTop: "20px" }}
                    paramsFormik={{
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    }}
                  />
                </div>
              }
            />
          )}
        </Formik>
        {/* End: Modal Detail Form */}
        {/* ----------------------------------------------------------------------------- */}
        {/* Start: Alert */}
        <SimpleAlert
          title={"Hapus Data"}
          content={"Anda yakin hapus data ini...?"}
          show={alert['show']}
          onHide={() => this['handleAlert'].hide()}
          onConfirmed={this['handleAlert']['confirmed']}
        />
        {/* End: Alert */}
      </>
    );
  }
}

import * as Yup from "yup";
import { ProdukKategoriAPI } from "../../api/produk-kategori";

export default class MarginHargaJualModel {
  constructor(data = {
    id: 0,
    type_margin: "",
    margin: 0,
    id_produk_kategori: 0,
    toProdukKategori: { nama: "" },
  }) {
    this.id = data.id;
    this.type_margin = data.type_margin;
    this.margin = data.margin;
    this.id_produk_kategori = data.id_produk_kategori;
    this.nama_produk_kategori = data.id_produk_kategori === 0 ? "Umum" : data.toProdukKategori.nama;
  }

  async getEditable(params = { pageName: "", isForm: false }) {
    let options_produk_kategori = [
      { id: 0, label: "Umum", value: 0 },
    ];

    if (params.isForm) {
      await new ProdukKategoriAPI()
        .getAll()
        .then((response) =>
          response.data.map(
            (data, i) =>
            (options_produk_kategori = [
              ...options_produk_kategori,
              { id: data.id, label: data.nama, value: data.id },
            ])
          )
        );
    }
    return [
      {
        validation: Yup.string().required("Nama harus diisi"),
        dataField: "type_margin",
        label: "Type Margin",
        type: "text",
        value: this.type_margin,
      },
      {
        dataField: "id_produk_kategori",
        label: "Kategori",
        type: "select2",
        options: options_produk_kategori,
        defaultValue: { label: this.nama_produk_kategori || "" },
        value: this.nama_produk_kategori,
        onChange: (e, setFieldValue) => {
          this.id_produk_kategori = e ? e.value : ""
          console.log(e);
        }
      },
      {
        validation: Yup.string().required("Margin Harus diisi"),
        dataField: "margin",
        label: "Margin",
        type: "number",
        value: `${this.margin} %`,
      },
    ];
  }
}

import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import List from "./list";
import Detail from "./detail";
import Inbound from "./inbound";
import { dispatcher } from "../../../redux/dispatcher";

export default function StokMasukPembelian() {
  const history = useHistory();
  const active = dispatcher.penerimaan.get();

  return (
    <Switch>
      <Route path="/stok-masuk-pembelian/list">
        <List />
      </Route>

      {active.master.id === 0 && <Redirect to="/stok-masuk-pembelian/list" />}

      <Route path="/stok-masuk-pembelian/inbound">
        <Inbound history={history} />
      </Route>

      <Route path="/stok-masuk-pembelian/detail">
        <Detail history={history} />
      </Route>

      <Redirect to="/stok-masuk-pembelian/list" />
    </Switch>
  );
}

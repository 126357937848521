/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { CardBody } from '../../../../_metronic/_partials/controls'
import { AnimatedCard } from '../../../components/animated-card/animatedCard'
import { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import store from '../../../redux/store';
import Select2 from "react-select";
import { LaporanGrafik } from '../../../api/laporan-grafik';
import { ReactDatatable } from '../../../components/react-datatable/reactDatatable';
import { saveAsExcel } from '../helperExportExcel';
import { CustomerAPI } from '../../../api/customer';
import DatePicker from "react-datepicker";


function PenjualanQtyProdukCust() {
  const [selectedYear,setSelectedYear] = useState(new Date());
  const years = new Date(selectedYear).getFullYear()
  const [opsi, setOptions] = useState([]);
  const [cust, setCust] = useState([]);
  const [slCust, setCustSl] = useState(0);
  const [cstLabel, setCustLabel] = useState("");
  const [bulan] = useState(['January', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'])
  const columns = [
    {
      name: 'Nama',
      selector: row => row.name,
      sortable: true,
      minWidth: '20%'
    }
  ];

  bulan.map((data, i) => {
    return columns.push(
      {
        name: data,
        selector: row => row.qty[i],
        sortable: true,
        minWidth: '12%'
      })
  })

  const [cabang, setCabang] = useState(store.getState().auth.id_perusahaan);

  const title = `LaporanPenjualan-by-QTYproduk ${cstLabel} ${years}`;
  const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>
    <i className={"far fa-file-excel"}></i> {" Export .xls"}
  </Button>
  const actionsMemo = <Export onExport={() => saveAsExcel(penjualan, title)} />

  let opsiCabang = []

  const coverage = async () => {
    await new LaporanGrafik().getCoverage().then((res) => {
      opsiCabang = res.data.map((data) => (
        {
          label: data.text,
          text: data.text,
          value: data.id,
        }));
    });
    setOptions(opsiCabang);
  };

  const customer = async (data) => {
    let opsiCust = []
    await new CustomerAPI().getByIdPerusahaan(data).then((res) => {
      opsiCust = res.data.map((data) => (
        {
          label: `${data.nama} || ${data.toOrganisasi ? data.toOrganisasi.nama : 'Tanpa Organisasi'}`,
          text: data.nama,
          value: data.id,
        }
      ))
    })
    setCust(opsiCust)
  }

  const [penjualan, setPenjualan] = useState([])

  const fetchData = async (data) => {
    try {
      await new LaporanGrafik().penjualanQTYProdukCust(data).then((res) => res.data.map((val) => {
        const map = new Map(val.map(({ nama, count, qty, id_produk }) => [id_produk, { id_produk, nama, count: [], qty: [], }]));
        for (let { nama, count, qty, id_produk } of val) {
          map.get(nama)
          map.get(id_produk).count.push(...[count].flat())
          map.get(id_produk).qty.push(...[qty].flat())
        }
        let temp = []
        temp.push([...map.values()])
        let datas = []
        temp.map((val) => {
          for (const item of val) {
            let newData = {
              name: item.nama,
              id: item.id_produk,
              qty: item.qty
            }
            datas = [...datas, newData]
          }
          setPenjualan(datas)
        })
      }));
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    let abortController = new AbortController();
    let data = {
      id_perusahaan: cabang,
      id_cust: slCust,
      years: years
    }
    fetchData(data).then(() => customer(cabang));
    coverage();
    return () => {
      abortController.abort()
  }
  }, [cabang,slCust,years])
  return (
    <>
      <AnimatedCard>
        <CardBody>
          <div className="d-flex justify-content-start form-group row" style={{zIndex: '2'}}>
            <div className="col-lg-3" style={{ marginTop: "20px", zIndex:'3'}}>
              <label> Pilih Perusahaan </label>
              <Select2
                name={"Cabang"}
                placeholder={opsi.text}
                size="sm"
                options={opsi}
                isClearable={true}
                value={
                  opsi
                    ? opsi.find(
                      (option) => option.value === cabang
                    )
                    : ""
                }
                onChange={(e) => {
                  setCabang(e ? e.value : 0);
                  setPenjualan([]);
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px", zIndex:'inherit'}}>
              <label> Pilih Customer </label>
              <Select2
                name={"Customer"}
                placeholder={cust.text}
                size="sm"
                options={cust}
                isClearable={true}
                value={
                  cust
                    ? cust.find(
                      (option) => option.value === slCust
                    )
                    : ""
                }
                onChange={(e) => {
                  console.log("Value",e);
                  setCustSl(e ? e.value : 0);
                  setCustLabel(e ? e.label : "");
                  setPenjualan([]);
                }}
              />
            </div>
            <div className="col-lg-2" style={{ marginTop: "20px" }}>
              <label> Tahun </label>
              <DatePicker
               id="DatePicker"
               className="form-control form-control-md text-center"
               type="string"
               selected={selectedYear}
               onChange={(date) => setSelectedYear(date)}
               showYearPicker
               dateFormat="yyyy"
            />
            </div>
          </div>
          <ReactDatatable
            columns={columns}
            data={penjualan}
            dense
            pagination
            searchParameter={['name']}
            actions={actionsMemo}
          />
        </CardBody>
      </AnimatedCard>
    </>
  )
}



export default PenjualanQtyProdukCust
import moment from "moment/moment";
import store from "../../../redux/store";
import { hapusSeparator, separatorHarga } from "../../../services/separator-harga";
import jurnalUmumDetailModel from "./JurnalUmumDetailModel ";
export default class jurnalUmumModel {
  constructor(
    data = {
      jurnal: {
        id: 0,
        tanggal: new Date(),
        no_transaksi: "",
        total_debet: 0,
        total_kredit: 0,
        total_tax: 0,
        total_ob: 0,
        id_user: store.getState().auth['id'],
        deskripsi: "",
        status: 0,
      },
      detail: []
    }
  ) {
    const { id, tanggal, no_transaksi, total_debet, total_kredit, total_tax, total_ob, id_user, deskripsi, status } = data['jurnal'];
    this['jurnal'] = {
      id: id,
      tanggal: tanggal,
      no_transaksi: no_transaksi,
      total_debet: total_debet,
      total_kredit: total_kredit,
      total_tax: total_tax,
      total_ob: total_ob,
      id_user: id_user,
      deskripsi: deskripsi,
      status: status,
    };
    this['detail'] = [];
    this.initDetails(data['detail']);
  }
  initDetails(detail) {
    if (detail.length > 0) {
      detail.forEach((item) => {
        this['detail'] = [
          ...this['detail'],
          new jurnalUmumDetailModel({
            ...item,
          }),
        ];
      });
    }
  }
  //** Start: Handle Detail */
  handleDetail = {
    set: async (item) => {
      const setSetails = this['detail'].filter((params) => { return params['status'] !== 0 });
      item['id'] = setSetails.length + 1;
      item['jumlah'] = parseInt(hapusSeparator(item['jumlah']));
      item['tax'] = item['tax'] ? parseInt(hapusSeparator(item['tax'])) : 0;
      this['detail'] = [...setSetails, item];
    },
    del: async (dataModel) => {
      this['detail'].map((val, i) => {
        if (val['id'] === dataModel['id']) {
          this['detail'][i]['status'] = 0;
        }
        return val;
      });
      const delDetails = this['detail'].filter((params) => { return params['status'] !== 0 });
      this['detail'] = [...delDetails];
    },
    reset: async () => {
      this['detail'].map((val, i) => {
        if (val['status'] === 1) {
          this['detail'][i]['status'] = 0;
        }
        return val;
      });
      const resetdetails = this['detail'].filter((params) => { return params['status'] !== 0 });
      this['detail'] = [...resetdetails];
    },
    edit: async (item) => {
      this['detail'][this['detail'].map((e) => e['id']).indexOf(item['id'])] = item;
    },
  };
  //** End: Handle Detail */
  async getdata() {
    const { tanggal, no_transaksi, total_debet, total_kredit, total_tax, total_ob, deskripsi } = this['jurnal'];
    return [{
      id: "tanggal",
      title: "Tanggal",
      label: moment(tanggal).format("DD-MM-YYYY"),
      center: true,
      sortable: true,
      minWidth: "12%",
    }, {
      id: "no_transaksi",
      title: "No. Transaksi",
      label: no_transaksi,
      center: true,
      sortable: true,
      minWidth: "20%",
    }, {
      id: "debet",
      title: "Total Debet",
      label: total_debet < 0 ? `Rp. -${separatorHarga(total_debet.toString())}` : separatorHarga(total_debet.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "kredit",
      title: "Total Kredit",
      label: total_kredit < 0 ? `Rp. -${separatorHarga(total_kredit.toString())}` : separatorHarga(total_kredit.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "total_tax",
      title: "Total Tax",
      label: total_tax < 0 ? `Rp. -${separatorHarga(total_tax.toString())}` : separatorHarga(total_tax.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "total_ob",
      title: "Total Out Off Balance",
      label: total_ob < 0 ? `Rp. -${separatorHarga(total_ob.toString())}` : separatorHarga(total_ob.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "deskripsi",
      title: "Keterangan",
      label: deskripsi,
      sortable: true,
      minWidth: "60%",
    }];
  }
}

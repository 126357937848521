import React, { useState, useEffect, createRef } from "react";
import { Card } from "../../../_metronic/_partials/controls";
import store from "../../redux/store";
import { Spinner } from "react-bootstrap";
import { Typography } from "@material-ui/core";

export function AnimatedCard({ children }) {
  const loadingContent = (
    <div
      style={{
        height: 100,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex" }}>
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
        <div style={{ marginLeft: 12 }}>
          <Typography variant="h6">Memuat data...</Typography>
        </div>
      </div>
    </div>
  );

  const [content, setContent] = useState(loadingContent);
  const [height, setHeight] = useState(100);

  useEffect(() => {
    if (!store.getState().loading) {
      setHeight(1200);
      setContent(children);
    } else if (store.getState().loading) {
      setHeight(100);
      setContent(loadingContent);
    }
  }, [store.getState().loading]);

  return (
    <Card
    //   style={{
    //     maxHeight: height,
    //     transition: "all 6000ms cubic-bezier(0.000, 0.005, 0.000, 1.005)",
    //     overflow: "hidden",
    //   }}
    >
      {/* {content} */}
      {children}
    </Card>
  );
}

import React, { useEffect, useState } from 'react'
import { CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls';
import { AnimatedCard } from '../../../../components/animated-card/animatedCard'
import ReactApexChart from 'react-apexcharts';
import { LaporanGrafik } from '../../../../api/laporan-grafik';
import { Button, Table } from 'react-bootstrap';
import store from '../../../../redux/store';
import Select2 from "react-select";
import { saveAsExcel } from './helperExportExcel';

function SisaAR() {
    const [bulan] = useState(['January', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'])
    const [series, setSeries] = useState([20, 20, 20])
    const ksa = [20, 46, 52, 69];
    const kpp = [68, 28, 70, 71];
    const ksb = [108, 105, 110, 109];
  
    const [pt] = useState([{
      value: ksa,
      label: "KSA"
    }, {
      value: kpp,
      label: "KPP"
    }, {
      value: ksb,
      label: "KSB"
    }])
  
    const [selectedPT, setSelectedPT] = useState(ksa.includes(store.getState().auth.id_perusahaan) ? ksa : kpp.includes(store.getState().auth.id_perusahaan) ? kpp : ksb);
    let total = (arr) => arr.reduce((a, b) => {
        return a + parseInt(b)
    }, 0)

    const rupiah = (number) => {
        return new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        }).format(number);
    }
    const [dt, setDT] = useState()
    const [foot, setFoot] = useState()
    const option = {
        chart: {
            width: 380,
            type: 'pie',
        },
        legend: {
            position: 'bottom'
        },
        labels: ['Total AR', 'AR Terbayar'],
        tooltip: {
            y: {
                formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    return rupiah(value)
                }
            }
        }
    };
    const title = 'LaporanPresentaseSisaAR';
    const Export = () => (<Button onClick={(e) =>{
        saveAsExcel(dt, title)
    } }>
        <i className={"far fa-file-excel"}></i> {" Export .xls"}
    </Button>)

    const fetchData = async (data) => {
        try {
            await new LaporanGrafik().presentaseAR(data).then((res) => res.data.map((val) => {

                // console.log("Cek Data",val.data.dataAll.flatMap((val) => val));
                let dataTemp = val.map((val) => val)
                const map = new Map(dataTemp.map(({ id_perusahaan, nama_perusahaan, total_penjualan, total_sisa }) => [id_perusahaan, { id_perusahaan, nama_perusahaan, total_penjualan: [], total_sisa: [] }]));
                for (let { id_perusahaan, nama_perusahaan, total_penjualan, total_sisa } of val) {
                    map.get(nama_perusahaan)
                    map.get(id_perusahaan).total_penjualan.push(...[total_penjualan].flat())
                    map.get(id_perusahaan).total_sisa.push(...[total_sisa].flat())
                }
                let temp = []
                temp.push([...map.values()])
                let datas = []
                temp.map((val) => {
                    for (const item of val) {
                        let newData = {
                            nama_perusahaan: item.nama_perusahaan,
                            id: item.id_perusahaan,
                            total_penjualan: item.total_penjualan,
                            total_sisa: item.total_sisa
                        }
                        datas = [...datas, newData]
                    }
                    setDT(datas)
                    console.log('PENJUALAN', datas);
                })
            }))
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        let abortController = new AbortController();

        fetchData(selectedPT)
        return () => {
            abortController.abort()
        }
    }, [selectedPT])

    return (
        <>
            <AnimatedCard>
                <CardHeader title={'Presentase Sisa AR'}>
                    <CardHeaderToolbar >
                        <Export/>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <div className="d-flex justify-content-start">
                        <div className="col-lg-3" style={{ marginTop: "20px" }}>
                            <label> Pilih PT </label>
                            <Select2
                                name={"PT"}
                                size="sm"
                                options={pt}
                                isClearable={false}
                                value={
                                    pt
                                        ? pt.find(
                                            (option) => option.value === selectedPT
                                        )
                                        : ""
                                }
                                onChange={(e) => {
                                    console.log("EEEE", e.value);
                                    setSelectedPT(e ? e.value : []);
                                    // setDT([]);
                                }}
                            />
                        </div>
                    </div>
                    {/* <ReactApexChart options={option} series={series} type="pie" width={380} /> */}
                    {dt ?
                        <Table responsive id="table-to-xls" className='text-center' bordered hover style={{ marginTop: "10px" }}>
                            <thead className='table-primary' >
                                <tr>
                                    <th rowSpan={2}>#</th>
                                    <th rowSpan={2}>Bulan / Tahun</th>
                                    <th colSpan={3}>{dt[0].nama_perusahaan}</th>
                                    <th colSpan={3}>{dt[1].nama_perusahaan}</th>
                                    <th colSpan={3}>{dt[2].nama_perusahaan}</th>
                                </tr>
                                <tr>
                                    <th>Total Penjualan</th>
                                    <th colSpan={2}> Sisa AR </th>
                                    <th>Total Penjualan</th>
                                    <th colSpan={2}> Sisa AR </th>
                                    <th>Total Penjualan</th>
                                    <th colSpan={2}> Sisa AR </th>
                                </tr>
                            </thead>
                            <tbody>
                                {bulan.map((val, i) => {
                                    return <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{val}</td>
                                        <td>{rupiah(dt[0].total_penjualan[i])}</td>
                                        <td>{rupiah(dt[0].total_sisa[i])}</td>
                                        <td>{`${dt[0].total_sisa[i] !== 0 ? ((parseFloat(dt[0].total_sisa[i]) / parseFloat(dt[0].total_penjualan[i])) * 100).toFixed(2) : 0} %`}</td>
                                        <td>{rupiah(dt[1].total_penjualan[i])}</td>
                                        <td>{rupiah(dt[1].total_sisa[i])}</td>
                                        <td>{`${dt[1].total_sisa[i] !== 0 ? ((parseFloat(dt[1].total_sisa[i]) / parseFloat(dt[1].total_penjualan[i])) * 100).toFixed(2) : 0} %`}</td>
                                        <td>{rupiah(dt[2].total_penjualan[i])}</td>
                                        <td>{rupiah(dt[2].total_sisa[i])}</td>
                                        <td>{`${dt[2].total_sisa[i] !== 0 ? ((parseFloat(dt[2].total_sisa[i]) / parseFloat(dt[2].total_penjualan[i])) * 100).toFixed(2) : 0} %`}</td>
                                    </tr>
                                })}
                            </tbody>
                            <tfoot>
                                <tr style={{ fontWeight: 'bold' }}>
                                    <td colSpan={2}>Total</td>
                                    <td>{rupiah(total(dt[0].total_penjualan))}</td>
                                    <td>{rupiah(total(dt[0].total_sisa))}</td>
                                    <td>{`${(((total(dt[0].total_sisa)) / total(dt[0].total_penjualan)) * 100).toFixed(2)} %`}</td>
                                    <td>{rupiah(total(dt[1].total_penjualan))}</td>
                                    <td>{rupiah(total(dt[1].total_sisa))}</td>
                                    <td>{`${(((total(dt[1].total_sisa)) / total(dt[1].total_penjualan)) * 100).toFixed(2)} %`}</td>
                                    <td>{rupiah(total(dt[2].total_penjualan))}</td>
                                    <td>{rupiah(total(dt[2].total_sisa))}</td>
                                    <td>{`${(((total(dt[2].total_sisa)) / total(dt[2].total_penjualan)) * 100).toFixed(2)} %`}</td>
                                </tr>
                            </tfoot>
                        </Table>
                        : "Loadinggg"
                    }
                </CardBody>
            </AnimatedCard>
        </>
    )
}

export default SisaAR
import React, { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import ApprovalPesananPenjualanAkhir from "./approval_penjualan";
import PesananPenjualanModel from "../../../models/pesanan-penjualan-model/PesananPenjualanModel";
import Detail from "./detail";

export default function ApprovalPesananPenjualan() {
  const [dataFromModel, setDataFromModel] = useState(new PesananPenjualanModel());
  const history = useHistory();

  return (
    <Switch>
      <Route path="/approval-pesanan-penjualan/list">
        <ApprovalPesananPenjualanAkhir setDataFromModel={setDataFromModel} />
      </Route>
      <Route path="/approval-pesanan-penjualan/detail">
        <Detail history={history} dataFromModel={dataFromModel} isEdit={true} />
      </Route>
      <Redirect to="/approval-pesanan-penjualan/list" />
    </Switch>
  )
  }
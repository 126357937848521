import { simpleFetch } from "./api";

export class login
{
  async getLogin ( username, password, id_perusahaan )
  {
    let outp = await simpleFetch( {
      url: "retail/login",
      body: {
        username: username,
        password: password,
        id_perusahaan: id_perusahaan,
      },
      method: "POST",
    } );
    return outp;
  }

  async getAllPerusahaan ( username )
  {
    let res = await simpleFetch( {
      url: "retail/cek/coverage?username=" + username,
      method: "GET",
    } );
    return res;
  }
}

import * as Yup from "yup";
import { KaryawanAPI } from "../../api/karyawan";
import { getAllRoles, getSingleRole } from "../../api/roles";
import React from "react";
import store from "../../redux/store";
import { CoverageAPI } from "../../api/coverage";

export default class UserModel {
  constructor(
    data = {
      id: 0,
      id_karyawan: 0,
      id_role: 0,
      nama: "",
      id_perusahaan: store.getState().auth.id_perusahaan,
      status: 0,
      username: "",
      password: "",
      toKaryawan: {
        nama_karyawan: "",
        nik: "",
      },
      toRole: {
        nama: "",
      },
    }
  ) {
    this.id = data.id;
    this.id_karyawan = data.id_karyawan;
    this.id_role = data.id_role;
    this.nama = data.nama;
    this.id_perusahaan = data.id_perusahaan;
    this.status = data.status;
    this.username = data.username;
    this.nik_karyawan = data.toKaryawan.nik;
    this.nama_karyawan = data.toKaryawan.nama_karyawan;
    this.nama_role = data.toRole.nama;
    this.coverage = [];
  }

  async getEditable(params = { pageName: "", isForm: false }) {
    let karyawan_options = [];
    let role_options = [];
    let list_coverage = [];
    if (params.isForm) {
      await new KaryawanAPI().getAll().then((response) =>
        response.data.map(
          (data) =>
            (karyawan_options = [
              ...karyawan_options,
              {
                id: data.id_karyawan,
                text: data.toPerusahaan.singkat + ' - ' + data.nama_karyawan,
                value: data.id_karyawan,
              },
            ])
        )
      );

      await getAllRoles().then((response) =>
        response.data.map(
          (data) =>
            (role_options = [
              ...role_options,
              { id: data.id, text: data.nama, value: data.id },
            ])
        )
      );

      await new CoverageAPI().getAll().then((res) =>
        res.data.map(
          (data) =>
            (list_coverage = [
              ...list_coverage,
              {
                id: data.id_perusahaan,
                nama: `${data.singkat} - ${data.lokasi}`,
              },
            ])
        )
      );
    }

    await new CoverageAPI().getSingle(this.id).then((response) => {
      if (response.data.length > 0 && this.id != "") {
        this.coverage = response.data;
      }
    });

    if (!params.isEdit) {
      this.password = this.nik_karyawan;
    } else {
      this.password = null;
    }

    return [
      {
        validation: Yup.string().required(),
        dataField: "id_karyawan",
        label: "karyawan",
        type: "select",
        options: karyawan_options,
        value: this.id_karyawan,
        onChange: async (e, setFieldValue) => {
          await new KaryawanAPI().getSingle(e.target.value).then((response) => {
            setFieldValue("nama_karyawan", response.data.nama_karyawan);
            setFieldValue("nik_karyawan", response.data.nik);
            this.id_karyawan = response.data.id_karyawan;
            this.nama_karyawan = response.data.nama_karyawan;
            this.nik_karyawan = response.data.nik;
            this.password = response.data.nik;
            if (!params.isEdit) {
              setFieldValue("password", response.data.nik);
            }
          });
        },
        invisibleOnTable: true,
      },
      {
        validation: Yup.string().required("Role harus diisi"),
        dataField: "id_role",
        label: "Role " + params.pageName,
        type: "select",
        options: role_options,
        onChange: (e) => {
          this.id_role = e.target.value;
        },
        value: this.nama_role,
      },
      {
        validation: Yup.string(),
        dataField: "coverage",
        label: "Coverage",
        type: "checked",
        list_check: list_coverage,
        checked: this.coverage,
        onClick: (checked) => {
          this.coverage = checked;
        },
        value: this.coverage,
        invisibleOnTable: true,
      },
      {
        validation: Yup.string().required("NIK karyawan harus diisi"),
        dataField: "nik_karyawan",
        label: "NIK Karyawan",
        type: "readonly-text",
        onChange: (e) => (this.nik_karyawan = e.target.value),
        value: this.nik_karyawan,
      },
      {
        validation: Yup.string().required("Nama karyawan harus diisi"),
        dataField: "nama_karyawan",
        label: "Nama Karyawan",
        type: "readonly-text",
        onChange: (e) => (this.nama_karyawan = e.target.value),

        value: this.nama_karyawan,
      },
      {
        validation: Yup.string().required("Username harus diisi"),
        dataField: "username",
        label: "Username",
        type: "text",
        onChange: (e) => (this.username = e.target.value),
        value: this.username,
      },
    ];
  }
}

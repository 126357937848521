/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState, useMemo,} from "react";
import {
  TableWithoutPagination,
} from "../../../components/table/Table";
import { dispatcher } from "../../../redux/dispatcher";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Button, Form, Modal } from "react-bootstrap";
import { GudangAPI } from "../../../api/gudang";
import StokMasukModel from "../../../models/stok-masuk-model/StokMasukModel";

export function InboundDetail({ data, handleSubmit, validateForm }) {
  const [initialData, setInitialData] = useState(dispatcher.penerimaan.get());
  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const [showAlert, setShowAlert] = useState(false);
  const [dModel, setDModel] = useState(new StokMasukModel());
  const [gudang, setGudang] = useState([]);
  const [selectedGudang, setSelectedGudang] = useState(null);
  const [qtyInbound, setQtyInbound] = useState(initialData.detail.qty);
  const [detail, setDetail] = useState()

  const loadData = async () => {
    let simpleTableModel = new SimpleTableModel();
    const actions = (dModel) => {
      let condition = dModel.qty_inbound < dModel.qty
      console.warn('dModel',dModel.qty_inbound < dModel.qty);
      if (initialData.master.kode == "" || condition === false) {
        return (
          <Button className="mx-1 btn btn-outline-secondary btn-sm" disabled>
            Inbound
          </Button>
        );
      } else if (dModel.status_validasi == 2) {
        return (
          <Button className="mx-1 btn btn-outline-secondary btn-sm" disabled>
            Sudah Inbound
          </Button>
        );
      } else if (condition === true) {
        return (
          <Button
            className="mx-1 btn btn-outline-success btn-sm"
            onClick={() => {
              setDModel(dModel);
              setDetail(dModel)
              setShowAlert(true);
            }}
          >
            Inbound
          </Button>
        );
      }
    };

    initialData.detail.forEach((item, i) => {
      if (item.status == 1)
        simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: item,
          actions: actions(item),
        });
    });

    setColumntable(await simpleTableModel.getColumnHeaders());
    setDatatable(await simpleTableModel.getDatatables());
  };

  const loadGudang = async () => {
    await new GudangAPI().getAll().then((values) => {
     let filter = values.data.filter((fil) => fil.id_perusahaan === initialData.master.id_perusahaan)
      setGudang(filter);
    });
  };

  const refreshData = async () => {
    await dispatcher.penerimaan.single(initialData.master.id);
    setQtyInbound(0);
    setInitialData(dispatcher.penerimaan.get());
  };

  const getList = useMemo(() => {
    setInitialData(dispatcher.penerimaan.get());
    return dispatcher.penerimaan.get();
  }, [dispatcher.penerimaan.get()]);

  useEffect(() => {
    loadData();
    loadGudang();
  }, [getList]);

  return (
    <>
      <Modal
        show={showAlert}
        onHide={() => setShowAlert(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Pilih Gudang</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Gudang</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => setSelectedGudang(e.target.value)}
              >
                <option value="">Tidak ada dipilih</option>
                {gudang.map((data) => {
                  // if (data.id_parent != null) {
                    return (
                      <option
                        key={data.id}
                        value={data.id}
                      >{`${data.nama}`}</option>
                    );
                  // }
                })}
              </Form.Control>
              <br/>
              <Form.Label>Inbound QTY</Form.Label>
              <Form.Control size="lg" type="number" placeholder="QTY Inbound" 
              onChange={(e) => setQtyInbound(parseInt(e.target.value))}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAlert(false)}>
            Batal
          </Button>
          <Button
            variant="primary"
            onClick={async () => {
              let unvalid = {};
              handleSubmit();
              await validateForm().then((val) => (unvalid = val));
              // console.log('nilai detail',detail);
              // console.log('nilai QTYInbound',detail.qty_inbound);
              console.warn(detail.qty_inbound + qtyInbound > detail.qty);
              console.warn(detail.qty_inbound + qtyInbound)
              if (detail.qty_inbound + qtyInbound > detail.qty) {
                dispatcher.snackbar.show(
                  "Warning",
                  "QTY Inbound tidak boleh lebih besar dari QTY Order",
                  "warning"
                );
                return null
              }

              if (selectedGudang == "" || selectedGudang == null) {
                dispatcher.snackbar.show(
                  "Warning",
                  "Pastikan gudang terpilih",
                  "warning"
                );
              }

              if (unvalid.kode == null) {
                let send = { id: dModel.id, id_gudang: selectedGudang, qty_inbound: qtyInbound };
                setShowAlert(false);
                console.log('Send',send);
                await dispatcher.penerimaan.validate(send);
              } else {
                dispatcher.snackbar.show(
                  "Warning",
                  "Pastikan semua field terisi",
                  "warning"
                );
              }
              setShowAlert(false);
              refreshData();
            }}
          >
            Simpan Perubahan
          </Button>
        </Modal.Footer>
      </Modal>
      <TableWithoutPagination columns={columntable} data={datatable} />
    </>
  );
}

import { Redirect, Route, Switch } from "react-router-dom";

import FormPenerimaanKas from "./form";
import React from "react";
import { dispatcher } from "../../../../../redux/dispatcher";

export default function IndexFormPenerimaanKas ()
{
  dispatcher[ 'kasir_kas' ].resetActivePenerimaanKas();
  const active = dispatcher[ 'kasir_kas' ].getPenerimaanKas();
  return (
    <Switch>
      <Route path="/kasir/tambah-penerimaan-kas/form">
        <FormPenerimaanKas />
      </Route>
      { active[ 'transaksi' ][ 'id' ] === 0 && (
        <Redirect to="/kasir/tambah-penerimaan-kas/form" /> )
      }
      <Redirect to="/kasir/tambah-penerimaan-kas/form" />
    </Switch>
  );
}

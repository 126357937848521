const initialValues = {
  active: null,
  list: [],
};

export const kasirKasAction = {
  list: "LIST_KASIR_KAS",
  active: "ACTIVE_KASIR_KAS",
  resetActive: "RESET_ACTIVE_KASIR_KAS",
};

const reducer = (state = initialValues, action) => {
  let states;
  switch (action.type) {
    case kasirKasAction.list:
      states = state;
      states.list = action.payload;
      return (state = states);
    case kasirKasAction.active:
      states = state;
      states.active = action.payload;
      return (state = states);
    case kasirKasAction.resetActive:
      states = state;
      states.active = null;
      return (state = states);
    default:
      return state;
  }
};

export default reducer;

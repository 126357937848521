import { getCoverageArea, getIdPerusahaan, simpleFetch } from "./api";

import store from "../redux/store";

export class LaporanGrafik {
  async getAllSales(values,y) {
    console.log('VALUES API',values);
    let params = "id_perusahaan=";
    if (values.length !== 1) {
      params += values.join("&id_perusahaan=");
    } else {
      params += values;
    }
    let res = await simpleFetch({
      url: `retail/performance/index-sales?${params}&years=${y}`,
      method: "GET",
    });
    return res;
  }

  async getARSales(values) {
    console.log('VALUES API',values);
    let params = "id_perusahaan=";
    if (values.length !== 1) {
      params += values.join("&id_perusahaan=");
    } else {
      params += values;
    }
    let res = await simpleFetch({
      url: "retail/laporan/pembayaran-ar?" + params,
      method: "GET",
    });
    return res;
  }

  async getAllCustomer(values,y) {
    let params = "id_perusahaan=";
    if (values.length !== 1) {
      params += values.join("&id_perusahaan=");
    } else {
      params += values;
    }
    let res = await simpleFetch({
      url: `retail/performance/index-customer?${params}&years=${y}`,
      method: "GET",
    });
    return res;
  }

  async getAllCabang(values,y) {
    let params = "id_perusahaan=";
    if (values.length !== 1) {
      params += values.join("&id_perusahaan=");
    } else {
      params += values;
    }
    let res = await simpleFetch({
      url: `retail/performance/index?${params}&years=${y}`,
      method: "GET",
    });
    return res;
  }

  async getARallCabang(values) {
    let params = "id_perusahaan=";
    if (values.length !== 1) {
      params += values.join("&id_perusahaan=");
    } else {
      params += values;
    }
    let res = await simpleFetch({
      url: "retail/pembayaran-ar-cabang?" + params,
      method: "GET",
    });
    return res;
  }

  async getARhari(values) {
    let filter = values.selectedPT ? values.selectedPT : values.cabang
    console.log(filter);
    let params = "id_perusahaan=";
    let tgl = "&tanggal="+ values.startDate
    if (filter.length !== 1) {
      params += filter.join("&id_perusahaan=");
    } else {
      params += filter;
    }
    let res = await simpleFetch({
      url: "retail/pembayaran-ar?" + params + tgl,
      method: "GET",
    });
    return res;
  }

  async presentaseAR(values) {
    let params = "id_perusahaan=";
    if (values.length !== 1) {
      params += values.join("&id_perusahaan=");
    } else {
      params += values;
    }
    let res = await simpleFetch({
      url: "retail/presentase-ar?" + params,
      method: "GET",
    });
    return res;
  }

  async laporanTarget(values) {
    let params = "id_perusahaan=";
    if (values.length !== 1) {
      params += values.join("&id_perusahaan=");
    } else {
      params += values;
    }
    let res = await simpleFetch({
      url: "retail/laporan-target?" + params,
      method: "GET",
    });
    return res;
  }
  

  async getAllOrganisasi(values) {
    let params = "id_perusahaan=";
    if (values.length !== 1) {
      params += values.join("&id_perusahaan=");
    } else {
      params += values;
    }
    let res = await simpleFetch({
      url: "retail/performance/index-organisasi?" + params,
      method: "GET",
    });
    return res;
  }

  async penjualanQTYProduk(values, y) {
    let params = "id_perusahaan=";
    if (values.length !== 1) {
      params += values.join("&id_perusahaan=");
    } else {
      params += values;
    }
    let res = await simpleFetch({
      url: `retail/performance/penjualan-produk?years=${y}&` + params,
      method: "GET",
    });
    return res;
  }

  async penjualanQTYProdukCust(values) {
    let params = `id_perusahaan=${values.id_perusahaan}&id_customer=${values.id_cust}&years=${values.years}`;
    let res = await simpleFetch({
      url: "retail/performance/penjualan-produk?" + params,
      method: "GET",
    });
    return res;
  }

  async pembelianCabang(values) {
    let params = "id_perusahaan=";
    if (values.length !== 1) {
      params += values.join("&id_perusahaan=");
    } else {
      params += values;
    }
    let res = await simpleFetch({
      url: "retail/performance/pembelian-cabang?" + params,
      method: "GET",
    });
    return res;
  }

  async pembelianSupplier(values) {
    let params = "id_perusahaan=";
    if (values.length !== 1) {
      params += values.join("&id_perusahaan=");
    } else {
      params += values;
    }
    let res = await simpleFetch({
      url: "retail/performance/pembelian-supplier?" + params,
      method: "GET",
    });
    return res;
  }

  async pembelianQTYProduk(values) {
    let params = "id_perusahaan=";
    if (values.length !== 1) {
      params += values.join("&id_perusahaan=");
    } else {
      params += values;
    }
    let res = await simpleFetch({
      url: "retail/performance/pembelian-produk?" + params,
      method: "GET",
    });
    return res;
  }

  async getCoverage() {
    let res = await simpleFetch({
      url: "retail/select/perusahaan?id_coverage=" + getCoverageArea(),
      method: "GET",
    });
    return res;
  }
}

import { FormDataFetch, getIdPerusahaan, getIdRole, simpleFetch, simpleFetchGetData } from "./api";

export class UserAPI
{
  async getAll ()
  {
    let res = await simpleFetchGetData( {
      // url:
      //   "retail/user?id_perusahaan=" +
      //   getIdPerusahaan() +
      //   "&id_role=" +
      //   getIdRole(),
      url:
        "retail/user?id_role=" +
        getIdRole(),
      method: "GET",
    } );
    return res;
  }

  async getSingle ( id )
  {
    let res = await simpleFetch( {
      url: "retail/user?id=" + id,
      method: "GET",
    } );
    return res;
  }

  async set ( values )
  {
    let res = await simpleFetch( {
      url: "retail/register",
      method: "POST",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values,
      },
    } );
    return res;
  }

  async edit ( values )
  {
    let res = await simpleFetch( {
      url: "retail/user",
      method: "PUT",
      body: {
        ...values,
      },
    } );
    return res;
  }

  async getUser ( id )
  {
    let res = await simpleFetchGetData( {
      url: "retail/get-users?id=" + id,
      method: "GET",
    } );
    return res;
  }

  async changePersonalUsers ( values )
  {
    const fd = new FormData();
    for ( const key in values )
    {
      fd.append( key, values[ key ] );
    }
    let res = await FormDataFetch( {
      url: "retail/user/save-change-profile",
      method: "PUT",
      body: fd,
    } );
    return res;
  }

  async forgotPass(email) {
    let res = await simpleFetch({
      url: "retail/req-reset",
      body: {
        email: email,
      },
      method: "POST",
    });
    return res;
  }

  async resetPass(values) {
    let res = await simpleFetch({
      url: "retail/reset-password",
      body: {
        id: values.id,
        token: values.token,
        password: values.password,
      },
      method: "POST",
    });
    return res;
  }

  async changePassword ( values )
  {
    let res = await simpleFetch( {
      url: "retail/user/save-change-password",
      method: "PUT",
      body: values,
    } );
    return res;
  }
}

export default class AuthModel {
  constructor(auth) {
    this.id = auth.id;
    this.id_karyawan = auth.id_karyawan;
    this.role = auth.id_role;
    this.id_perusahaan = auth.id_perusahaan;
    this.username = auth.username;
    this.token = auth._token;
    this.alias_role = auth.alias_role;
    this.coverage = auth.coverage_area;
    this.toPerusahaan = auth.toPerusahaan;
    this.userProfile = auth.userProfile;
    // this['notification'] = auth['notification'];
  }
}
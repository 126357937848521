import React, { useState, useEffect } from "react";
import {
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Button } from "react-bootstrap";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import { StokTransferAPI } from "../../../api/stok-transfer";
import { useHistory } from "react-router-dom";
import DaftarRequestModel from "../../../models/stok-req-model/DaftarRequestModel";

export default function List(params) {
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const history = useHistory();

  async function init() {
    let datas = await new StokTransferAPI().getAllByRef().then((res) =>
      res
    );
    let simpleTableModel = new SimpleTableModel();
    datas.forEach((item, i) => {
      const actions = (dataModel) => {
        return (
          <>
            {item.master.is_approved === 2 ?
              (
                <Button
                  className="mx-1 btn btn-primary btn-sm"
                  onClick={() => {
                    params.setDataFromModel(dataModel);
                    history.push("/approval-transfer-stok/detail");
                  }}
                >
                  <i className="fas fa-user-check"></i>
                </Button>
              ) : (
                <Button
                  className="mx-1 btn btn-warning btn-sm"
                  onClick={() => {
                    params.setDataFromModel(dataModel);
                    history.push("/approval-transfer-stok/detail");
                  }}
                >
                  <i className="fas fa-user-lock"></i>
                </Button>
              )}
          </>
        )
      }

      let dataModel = new DaftarRequestModel(item);
      simpleTableModel.add({
        id: i,
        no: i + 1,
        dataModel: dataModel,
        actions: actions(dataModel),
      });
    })
    setColumntable(await simpleTableModel.getColumnHeaders());
    setDatatable(await simpleTableModel.getDatatables());
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <AnimatedCard>
        <CardHeader title="Approval Transfer Stok">
          <CardHeaderToolbar>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <SimpleTable data={datatable} columns={columntable} />
        </CardBody>
      </AnimatedCard>
    </>
  );
}

import { CardBody, CardHeader } from "../../../../_metronic/_partials/controls";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import DataTableModel from "../../../models/datatable-model/DataTableModel";
import React from "react";
import { ReactDatatable } from "../../../components/react-datatable/reactDatatable";
import { dispatcher } from "../../../redux/dispatcher";
import ButtonActions from "../../../components/button-actions/buttonActions";
import PesananPembelianModel from "../../../models/transaksi/pesanan-pembelian/PesananPembelianModel";
import { notificationAPI } from "../../../api/notifications";
import { ModalFormsDetailTable } from "../../../components/modal-forms/modalForms";
import { ReactDatatableFooterDetails } from "../../../components/react-datatable-footer/reactDatatableFooter";
import { Form, Formik } from "formik";
import { FormsFields } from "../../../components/forms-fields/FormsFields";
import { separatorHarga } from "../../../services/separator-harga";
import { Paper, Typography, makeStyles } from "@material-ui/core";
import store from "../../../redux/store";

export default class NotificationApprovalPesananPembelian extends React.Component {
  constructor(props) {
    super(props);
    this['pageName'] = "Approval Pesanan Pembelian";
    this['searchParameter'] = ["tanggal", "supplier", "no_transaksi", "qty", "total_harga", "total"];
    this['apiNotification'] = new notificationAPI();
    this['initialData'] = new PesananPembelianModel();
    this['state'] = {
      pending: true,
      modal: {
        show: false,
        initialData: this['initialData'],
        isEdit: false,
      },
      footerDetail: {
        total_harga: "Total",
        qty: 0,
        diskon: 0,
        subtotal: 0,
        pajak: 0,
        total: 0,
      },
    };
    this['datatable'] = [{ id: "", no: "", name: "" }];
    this['columntable'] = [{ id: "", title: "" }];
    this['datatableDetail'] = [{ id: "", no: "", name: "" }];
    this['columntableDetail'] = [{ id: "", title: "" }];
  }
  async componentDidMount() {
    await this.LoadData();
  }
  async LoadData() {
    this.setState({ pending: true });
    let data = await this['apiNotification'].getAllPesananPembelian();
    this['DataTableModel'] = new DataTableModel();
    data['data'].map((item, i) => {
      let dataModel = new PesananPembelianModel(item);
      this['DataTableModel'].add({
        id: i,
        no: i + 1,
        dataModel: dataModel,
        actions: (
          <>
            <ButtonActions
              label={"approve"}
              onClick={() => {
                this['handleModal'].show(dataModel);
              }}
            />
          </>
        ),
      });
      return item;
    });
    this['columntable'] = await this['DataTableModel'].getColumn();
    this['datatable'] = await this['DataTableModel'].getDatas();
    this.setState({ ...this['state'], pending: false });
  }
  // Begin: Handle Modal/Alert
  handleModal = {
    LoadDataDetail: async (detail) => {
      this['DataTableModel'] = new DataTableModel({ readOnly: true });
      detail['detail'].map((item, i) => {
        this['DataTableModel'].add({
          id: item['id'],
          no: i + 1,
          dataModel: item,
          actions: null,
        });
        return item;
      });
      const totalList = (val) => {
        if (val === 'qty') {
          return detail['detail'].reduce((total, value) => {
            return parseFloat(total + value['qty']);
          }, 0);
        } else if (val === 'diskon') {
          return detail['detail'].reduce((total, value) => {
            return parseFloat(total + value['diskon']);
          }, 0);
        } else if (val === 'subtotal') {
          return detail['detail'].reduce((total, value) => {
            return parseFloat(total + value['subtotal']);
          }, 0);
        } else if (val === 'pajak') {
          return detail['detail'].reduce((total, value) => {
            return parseFloat(total + value['pajak']);
          }, 0);
        } else if (val === 'total') {
          return detail['detail'].reduce((total, value) => {
            return parseFloat(total + value['total']);
          }, 0);
        }
      };
      this['columntableDetail'] = await this['DataTableModel'].getColumn();
      this['datatableDetail'] = await this['DataTableModel'].getDatas();
      this.setState({
        ...this['state'],
        footerDetail: {
          total_harga: "Total",
          qty: totalList("qty"),
          diskon: totalList("diskon") < 0 ? `Rp. -${separatorHarga(totalList("diskon").toString())}` : separatorHarga(totalList("diskon").toString(), "Rp. "),
          subtotal: totalList("subtotal") < 0 ? `Rp. -${separatorHarga(totalList("subtotal").toString())}` : separatorHarga(totalList("subtotal").toString(), "Rp. "),
          pajak: totalList("pajak") < 0 ? `Rp. -${separatorHarga(totalList("pajak").toString())}` : separatorHarga(totalList("pajak").toString(), "Rp. "),
          total: totalList("total") < 0 ? `Rp. -${separatorHarga(totalList("total").toString())}` : separatorHarga(totalList("total").toString(), "Rp. "),
        }
      });
    },
    show: async (detail) => {
      await this['handleModal'].LoadDataDetail(detail);
      this.setState({
        modal: {
          show: true,
          title: "Approval Pesanan Pembelian",
          initialData: new PesananPembelianModel({ ...detail })
        }
      });
    },
    hide: () => {
      let state = { ...this['state'] };
      state = {
        modal: {
          show: false,
          initialData: this['initialData']
        }
      }
      this.setState({ ...state });
    },
  };
  // End: Handle Modal/Alert
  render() {
    const useStyles = makeStyles(theme => ({
      root: {
        textTransform: "uppercase",
        textAlign: "right",
        padding: theme.spacing(1, 2),
        backgroundColor: "#f3f6f9",
      },
    }));
    const { modal, pending, footerDetail } = this['state'];
    return (
      <>
        {/* Views Table */}
        <AnimatedCard>
          <CardHeader title={this['pageName']}></CardHeader>
          <CardBody>
            <ReactDatatable
              title={""}
              columns={this['columntable']}
              data={this['datatable']}
              searchParameter={this['searchParameter']}
              progressPending={pending}
            />
          </CardBody>
        </AnimatedCard>
        {/* ----------------------------------------------------------------------------- */}
        {/* Begin: Modal Detail Form */}
        <Formik
          enableReinitialize={true}
          initialValues={modal['initialData']['master']}
          validationSchema={false}
          onSubmit={async (values, { resetForm }) => {
            const { id } = values;
            let dataSend = {
              id: id,
              id_user: store.getState()['auth']['role'],
            };
            await this['apiNotification'].approvedPesananPembelian(dataSend).then((res) => {
              if (res['status']) {
                dispatcher['snackbar'].show("Berhasil", res['message'], "primary");
                this['handleModal'].hide();
                this.LoadData();
              } else {
                return dispatcher['snackbar'].show("Gagal", res['message'], "danger");
              }
            });
          }}
        >
          {({ values, handleSubmit }) => (
            <ModalFormsDetailTable
              show={modal['show']}
              title={modal['title']}
              onHide={this['handleModal']['hide']}
              onConfirm={handleSubmit}
              content={
                <>
                  <Form className={"form form-label-right"}>
                    <div className={"form-group row"}>
                      <div className={"col-lg-6"}>
                        <div className={"form-group row"}>
                          {/* Tanggal */}
                          <FormsFields
                            id={'tanggal'}
                            type={"disabled-DatePicker"}
                            label={"Tanggal"}
                            className={"col-lg-12"}
                            value={values['tanggal']}
                          />
                          {/* No. Transaksi */}
                          <FormsFields
                            id={'no_transaksi'}
                            type={"disabled-text"}
                            label={"No. Transaksi"}
                            className={"col-lg-12"}
                            style={{ marginTop: "20px" }}
                            value={values['no_transaksi']}
                          />
                        </div>
                      </div>
                      <div className={"col-lg-6"}>
                        <div className={"form-group row"}>
                          {/* Supplier */}
                          <FormsFields
                            id={'nama_supplier'}
                            type={"disabled-text"}
                            label={"Supplier"}
                            className={"col-lg-12"}
                            value={values['nama_supplier']}
                          />
                          {/* Tanggal Jatuh Tempo */}
                          <FormsFields
                            id={'jatuh_tempo'}
                            type={"disabled-DatePicker"}
                            label={"Jatuh Tempo"}
                            className={"col-lg-12"}
                            style={{ marginTop: "20px" }}
                            value={values['jatuh_tempo']}
                          />
                        </div>
                      </div>
                      {/* Keterangan */}
                      <FormsFields
                        id={'deskripsi'}
                        type={"disabled-textarea"}
                        label={"Keterangan"}
                        className={"col-lg-12"}
                        rows={3}
                        value={values['deskripsi']}
                      />
                    </div>
                    <ReactDatatableFooterDetails
                      title={"Detail Item"}
                      data={this['datatableDetail']}
                      columns={this['columntableDetail']}
                      footer={footerDetail}
                    />
                    {/* Total Harga */}
                    <div className={"tab-content mt-5"}>
                      <div className={"table-responsive"}>
                        <Paper className={useStyles().root}>
                          <Typography variant={"h5"} component={"h3"}>
                            Total Harga : {(values['hpp_total'] < 0) ? "Rp. -" + separatorHarga(values['hpp_total'].toString()) :
                              separatorHarga(values['hpp_total'].toString(), "Rp. ")}
                          </Typography>
                        </Paper>
                      </div>
                    </div>
                  </Form>
                </>
              }
            />
          )}
        </Formik>
        {/* End: Modal Detail Form */}
        {/* ----------------------------------------------------------------------------- */}
      </>
    );
  }
}

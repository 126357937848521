import { CardBody, CardFooter, CardHeader } from '../../../../_metronic/_partials/controls';
import React, { Component } from 'react';
import { AnimatedCard } from '../../../components/animated-card/animatedCard';
import DataTableModel from '../../../models/datatable-model/DataTableModel';
import { Formik } from 'formik';
import { FormsFields } from '../../../components/forms-fields/FormsFields';
import { ReactDatatableFooterDetails } from '../../../components/react-datatable-footer/reactDatatableFooter';
import { akuntansiAPI } from '../../../api/akuntansi';
import { separatorHarga } from '../../../services/separator-harga';
import * as Yup from "yup";
import ButtonLoad from '../../../components/button-loading/buttonLoad';
import { getIdPerusahaan } from '../../../api/api';
import neracaSaldoModel from '../../../models/akuntansi/neraca-saldo/NeracaSaldo';
import store from '../../../redux/store';
import { ProgressBar } from 'react-bootstrap';

function simulateNetworkRequest ()
{
   return new Promise( resolve => setTimeout( resolve, 1000 ) );
}
export default class NeracaSaldo extends Component
{
   constructor( props )
   {
      super( props );
      this[ 'pageName' ] = "Nerca Saldo";
      this[ 'apiNeracaSaldo' ] = new akuntansiAPI();
      this[ 'options_cabang' ] = [];
      this[ 'state' ] = {
         pending: false,
         percent: 0,
         lengthData: 0,
         isLoading: true,
         initialValues: {
            month_year: new Date(),
            id_region: "",
            id_perusahaan: getIdPerusahaan(),
            id_user: store.getState().auth[ 'id' ],
            ceklist_nilai: false,
            empty_table: true,
         },
         footerAktiva: {
            nama_akun: "Total Aktiva",
            saldo: 0,
         },
         footerPasiva: {
            nama_akun: "Total Pasiva",
            saldo: 0,
         },
      }
      this[ 'increase' ] = this[ 'increase' ].bind( this );
      // Aktiva
      this[ 'columntableAktiva' ] = [ { id: "", title: "" } ];
      this[ 'datatableAktiva' ] = [ { id: "", no: "", name: "" } ];
      // Pasiva
      this[ 'columntablePasiva' ] = [ { id: "", title: "" } ];
      this[ 'datatablePasiva' ] = [ { id: "", no: "", name: "" } ];
   }
   //* ----------------------------------------------------------------------------- */
   //** Start: Component */
   async componentDidMount ()
   {
      await this.LoadDataCabang( this[ 'state' ][ 'initialValues' ][ 'id_region' ] );
      await this.LoadData( this[ 'state' ][ 'initialValues' ] );
   }
   // Cabang
   async LoadDataCabang ( id_region )
   {
      this.setState( { isLoading: true }, () =>
      {
         simulateNetworkRequest().then( () =>
         {
            this[ 'apiNeracaSaldo' ].getPerusahaan( id_region ).then( ( values ) =>
            {
               this[ 'options_cabang' ] = values[ 'data' ].map( ( data ) => ( {
                  label: data[ 'text' ],
                  value: data[ 'id' ],
               } ) );
            } );
            this.setState( { isLoading: false } );
         } );
      } );

   }
   increase ()
   {
      const { percent } = this.state;
      const newPercent = percent + 1;
      if ( newPercent >= 100 )
      {
         clearTimeout( this.tm );
         return;
      }
      this.setState( { percent: newPercent } );
      console.warn( "this['state']['lengthData']", this[ 'state' ][ 'lengthData' ] );
      this.tm = setTimeout( this.increase, this[ 'state' ][ 'lengthData' ] );
   }
   //** End: Component */
   //* ----------------------------------------------------------------------------- */
   //** Start: Load Data */
   async LoadData ( form )
   {
      this.setState( { pending: true } );
      let data = await this[ 'apiNeracaSaldo' ].getAllNeracaSAldo( form );
      // Aktiva
      this[ 'DataTableModelAktiva' ] = new DataTableModel( { readOnly: true } );
      data[ 'data' ][ 'aktiva' ].map( ( item, i ) =>
      {
         let dataModel = new neracaSaldoModel( item );
         this[ 'DataTableModelAktiva' ].add( {
            id: item[ 'id' ],
            no: i + 1,
            dataModel: dataModel,
            actions: null,
         } );
         return item;
      } );
      var sumAktiva = data[ 'data' ][ 'aktiva' ].reduce( function ( pv, cv ) { return pv + cv[ 'saldo' ]; }, 0 );
      this[ 'columntableAktiva' ] = await this[ 'DataTableModelAktiva' ].getColumn();
      this[ 'datatableAktiva' ] = await this[ 'DataTableModelAktiva' ].getDatas();
      // Pasiva
      this[ 'DataTableModelPasiva' ] = new DataTableModel( { readOnly: true } );
      data[ 'data' ][ 'pasiva' ].map( ( item, i ) =>
      {
         let dataModel = new neracaSaldoModel( item );
         this[ 'DataTableModelPasiva' ].add( {
            id: item[ 'id' ],
            no: i + 1,
            dataModel: dataModel,
            actions: null,
         } );
         return item;
      } );
      var sumPasiva = data[ 'data' ][ 'totalPasiva' ]
      this[ 'columntablePasiva' ] = await this[ 'DataTableModelPasiva' ].getColumn();
      this[ 'datatablePasiva' ] = await this[ 'DataTableModelPasiva' ].getDatas();
      let total_data = data[ 'data' ][ 'count' ];
      // let Progress = Math.round((2 / total_data) * 100);
      this.setState( {
         ...this[ 'state' ],
         pending: false,
         lengthData: total_data,
         footerAktiva: {
            nama_akun: "Total Aktiva",
            saldo: sumAktiva < 0 ? `Rp. -${ separatorHarga( sumAktiva.toString() ) }` : separatorHarga( sumAktiva.toString(), "Rp. " ),
         },
         footerPasiva: {
            nama_akun: "Total Pasiva",
            saldo: sumPasiva < 0 ? `Rp. -${ separatorHarga( sumPasiva.toString() ) }` : separatorHarga( sumPasiva.toString(), "Rp. " ),
         },
      } );
   }
   //** End: Load Data */
   render ()
   {
      const { percent, pending, initialValues, footerPasiva, footerAktiva, isLoading } = this[ 'state' ];
      return (
         <>
            {/* Start: Formik List */ }
            <Formik
               enableReinitialize={ true }
               initialValues={ initialValues }
               validationSchema={ Yup.object().shape( {
                  month_year: Yup.string().nullable().required(),
                  // id_perusahaan: Yup.string().required("Cabang harus diisi"),
               } ) }
               onSubmit={ ( values ) =>
               {
                  this.LoadData( {
                     ...values,
                     empty_table: false
                  } );
                  // this.increase();
               } }
            >
               { ( { setFieldValue, setFieldTouched, touched, errors, values, handleSubmit } ) => (
                  <AnimatedCard>
                     <CardHeader title={ this.pageName }></CardHeader>
                     <CardBody style={ { marginTop: "-20px" } }>
                        <div className={ "form-group row" }>
                           {/* Forms Moth/Year  */ }
                           <FormsFields
                              id={ "month_year" }
                              type={ "DatePicker" }
                              label={ "Bulan/Tahun" }
                              className={ "col-lg-4" }
                              showMonthYearPicker={ true }
                              style={ { marginTop: "20px" } }
                              paramsFormik={ {
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              } }
                           />
                           {/* Forms Select Region */ }
                           <FormsFields
                              id={ "id_region" }
                              type={ "select2" }
                              label={ "Region" }
                              style={ { marginTop: "20px" } }
                              className={ "col-lg-4" }
                              isClearable={ true }
                              isSearchable={ true }
                              options={ [ {
                                 label: "PT. KUMALA SUKSES ABADI",
                                 value: 8,
                              }, {
                                 label: "PT. KUMALA PUTRA PRIMA",
                                 value: 16,
                              } ] }
                              onChange={ ( e ) =>
                              {
                                 this.LoadDataCabang( e ? e[ 'value' ] : "" );
                                 setFieldValue( 'id_perusahaan', "" );
                                 // setFieldValue('kode_akun', "");
                              } }
                              paramsFormik={ {
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              } }
                           />
                           {/* Forms Select Cabang */ }
                           <FormsFields
                              id={ "id_perusahaan" }
                              type={ "select2" }
                              label={ "Cabang" }
                              style={ { marginTop: "20px" } }
                              className={ "col-lg-4" }
                              isClearable={ true }
                              isSearchable={ true }
                              isLoading={ isLoading }
                              options={ this[ 'options_cabang' ] }
                              paramsFormik={ {
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              } }
                           />
                        </div>
                        <div className={ "form-group row" }>
                           {/* Tampilkan Akun dengan nilai 0 */ }
                           <FormsFields
                              id={ "ceklist_nilai" }
                              type={ "checkbox" }
                              label={ "Tampilkan Akun dengan nilai 0" }
                              style={ { marginTop: "-10px" } }
                              className={ "col-lg-4" }
                              paramsFormik={ {
                                 setFieldValue,
                                 values
                              } }
                           />
                        </div>
                        {/* {pending ?
                           <div className="mb-5">
                              <ProgressBar animated={true} now={percent} label={`${percent}%`} min={0} max={100} />
                           </div> : null} */}
                        {/* Button */ }
                        <div style={ { display: "flex", justifyContent: "center" } } >
                           {/* Button Lihat Data */ }
                           <ButtonLoad
                              label={ "Lihat Data" }
                              pending={ pending }
                              classNameIcon={ "fa fa-filter" }
                              className={ "btn btn-outline-success" }
                              onClick={ async () => handleSubmit() }
                           />
                        </div>
                     </CardBody>
                     <CardFooter>
                        <div className={ "form-group row" }>
                           <div className={ "col-lg-6" }>
                              {/* AKTIVA */ }
                              <ReactDatatableFooterDetails
                                 title={ "AKTIVA" }
                                 data={ this[ 'datatableAktiva' ] }
                                 columns={ this[ 'columntableAktiva' ] }
                                 footer={ footerAktiva }
                                 progressPending={ pending }
                                 dense={ true }
                              />
                           </div>
                           <div className={ "col-lg-6" }>
                              {/* PASIVA */ }
                              <ReactDatatableFooterDetails
                                 title={ "PASIVA" }
                                 data={ this[ 'datatablePasiva' ] }
                                 columns={ this[ 'columntablePasiva' ] }
                                 footer={ footerPasiva }
                                 progressPending={ pending }
                                 dense={ true }
                              />
                           </div>
                        </div>
                     </CardFooter>
                  </AnimatedCard>
               ) }
            </Formik>
            {/* End: Formik List */ }
         </>
      );
   }
}
import React, { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import List from "./list";
import Detail from "./detail"
import CustomerLimitModel from "../../../models/customer-limit-status/CustomerLimitStatusModel";

export default function DaftarCustomerLimitStatus() {
    const [dataFromModel, setDataFromModel] = useState(new CustomerLimitModel());
    const history = useHistory();

    return (
        <Switch>
            <Route path="/customer-limit-status/list">
                <List setDataFromModel={setDataFromModel} />
            </Route>
            <Route path="/customer-limit-status/detail">
                <Detail history={history} data={dataFromModel} />
            </Route>
            <Redirect from="/customer-limit-status/" to="/customer-limit-status/list" />
        </Switch>
    )
}
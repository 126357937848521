const initialValues = {
  active: null,
  list: [],
};

export const pembelianAction = {
  list: "LIST_PESANAN_PEMBELIAN",
  active: "ACTIVE_PESANAN_PEMBELIAN",
  resetActive: "RESET_ACTIVE_PESANAN_PEMBELIAN",
};

const reducerPembelian = (state = initialValues, action) => {
  let states;
  switch (action.type) {
    case pembelianAction.list:
      states = state;
      states.list = action.payload;
      return (state = states);
    case pembelianAction.active:
      states = state;
      states.active = action.payload;
      return (state = states);
    case pembelianAction.resetActive:
      states = state;
      states.active = null;
      return (state = states);
    default:
      return state;
  }
};

export default reducerPembelian;

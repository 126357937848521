const initialValues = {
  active: null,
  list: [],
};

export const organisasiAction = {
  list: "LIST_ORGANISASI",
  active: "ACTIVE_ORGANISASI",
  resetActive: "RESET_ACTIVE_ORGANISASI",
};

const reducerOrganisasi = (state = initialValues, action) => {
  let states;
  switch (action.type) {
    case organisasiAction.list:
      states = state;
      states.list = action.payload;
      return (state = states);
    case organisasiAction.active:
      states = state;
      states.active = action.payload;
      return (state = states);
    case organisasiAction.resetActive:
      states = state;
      states.active = null;
      return (state = states);
    default:
      return state;
  }
};

export default reducerOrganisasi;

import React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { dispatcher } from "../../../redux/dispatcher";
import store from "../../../redux/store";

export function ButtonValidasi({ dataModel, detail }) {
  const history = useHistory();
  if (
    (dataModel.master.status_invoice === 2 &&
    dataModel.master.status_validasi === 1) || detail === true
  ) {
    return (
      <>
        <Button
          onClick={async () => {
            let send = {
              id: dataModel.master.id,
              id_user: store.getState().auth.id,
              kode: dataModel.master.kode,
              tanggal: dataModel.master.tanggal
            };
            await dispatcher.penerimaan.invoice(send);
            history.goBack();
          }}
        >
          <i className="fa fa-check-circle"></i>
          Validasi Invoice
        </Button>
      </>
    );
  } else {
    return <></>;
  }
}

import * as Yup from "yup";
import { MerekAPI } from "../../api/merek";
import { ProdukKategoriAPI } from "../../api/produk-kategori";
import { ProdukSatuanAPI } from "../../api/produk-satuan";
import { SupplierAPI } from "../../api/supplier";
import store from "../../redux/store";
export default class ProdukCobaModel {
  constructor(
    data = {
      id: 0,
      id_supplier: 0,
      id_perusahaan_login: store.getState().auth.id_perusahaan,
      id_pt: 0,
      id_merek: 0,
      toMerek: { nama : ""},
      toSupplier: { nama: "" },
      id_produk_kategori: 0,
      id_sub_produk_kategori: 0,
      nama_sub_produk_kategori: 0,
      toProdukKategori: { nama: "" },
      id_produk_satuan: 0,
      toProdukSatuan: { nama: "" },
      nama: "",
      kode: "",
      persediaan_total: 0,
    }
  ) {
    this.id = data.id;
    this.id_pt = data.id_pt;
    this.id_perusahaan_login = data.id_perusahaan_login;
    this.id_supplier = data.id_supplier;
    this.nama_supplier = data.toSupplier.nama;
    this.id_produk_kategori = data.id_produk_kategori;
    this.id_sub_produk_kategori = data.id_sub_produk_kategori;
    this.nama_sub_produk_kategori = data.nama_sub_produk_kategori;
    this.nama_produk_kategori = data.toProdukKategori.nama;
    this.id_produk_satuan = data.id_produk_satuan;
    this.nama_produk_satuan = data.toProdukSatuan.nama;
    this.nama = data.nama;
    this.id_merek = data.id_merek;
    this.nama_merek = data.toMerek.nama || "";
    this.kode = data.kode;
    this.persediaan_total = data.persediaan_total;
  }


  async getEditable(params = { pageName: "", isForm: false }) {
    let options_produk_kategori = [];
    let options_sub_kategori = [];
    let options_produk_satuan = [];
    let options_merek = [];
    let options_pt = [
      { id: 1, text: "PT. Kumala Putra Prima",  value: 1 },
      { id: 2, text: "PT. Kumala Sukses Abadi",  value: 2 },
      { id: 3, text: "PT. Kumala Sukses Bersama",  value: 3 },
    ];

    let options_supplier = [];
    if (params.isForm) {
      let dataKategori = await new ProdukKategoriAPI().getAll()
        
          dataKategori.data.map(
            (data, i) =>
            (options_produk_kategori = [
              ...options_produk_kategori,
              { id: data.id, text: data.nama, value: data.id },
            ])
          )

          dataKategori.data.filter((data) => {
            // return console.log('cek nilai',this.id_produk_kategori);
           return data.id_parent !== null || 0 ? 
           (options_sub_kategori = [
            ...options_sub_kategori,
            { id: data.id, text: data.nama, value: data.id },
           ]) : ""
           ;
          })

      let ksa = [20,46,52,69]
      let kpp = [68,28,70,71]
      let ksb = [105,108,109,110]

      let dataSplr = await new SupplierAPI().getSemua()
      let filter = dataSplr.data.filter((fil) => 
      ksa.includes(fil.id_perusahaan) && ksa.includes(this.id_perusahaan_login) ? 
        ksa.includes(fil.id_perusahaan) : 
        kpp.includes(fil.id_perusahaan) && kpp.includes(this.id_perusahaan_login) ?
        kpp.includes(fil.id_perusahaan) : 
        ksb.includes(fil.id_perusahaan) && ksb.includes(this.id_perusahaan_login) ?
        ksb.includes(fil.id_perusahaan) : "");

      filter.map((data) => {
        options_supplier = [
          ...options_supplier,
          { id: data.id, text: data.nama, value: data.id },
        ]
      })

      let dataMerek = await new MerekAPI().getAll()
      let merekFilter = dataMerek.data.filter((fil) => 
      ksa.includes(fil.id_perusahaan) && ksa.includes(this.id_perusahaan_login) ? 
        ksa.includes(fil.id_perusahaan) : 
        kpp.includes(fil.id_perusahaan) && kpp.includes(this.id_perusahaan_login) ?
        kpp.includes(fil.id_perusahaan) : 
        ksb.includes(fil.id_perusahaan) && ksb.includes(this.id_perusahaan_login) ?
        ksb.includes(fil.id_perusahaan) : "");

      merekFilter.map((data) => {
        options_merek = [
          ...options_merek,
          { id: data.id, text: data.nama, value: data.id },
        ]
      })

      await new ProdukSatuanAPI()
        .getAll()
        .then((response) =>
          response.data.map(
            (data, i) =>
            // console.log(data)
            (options_produk_satuan = [
              ...options_produk_satuan,
              { id: data.id, text: data.nama, value: data.id },
            ])
          )
        );
    }
    return [
      {
        validation: Yup.string().required("Nama harus diisi"),
        dataField: "nama",
        label: "Nama " + params.pageName,
        type: "text",
        value: this.nama,
      },
      {
        validation: Yup.number('PT Harus Dipilih')
          .required("Pilih PT")
          .oneOf([1, 2,3]),
        dataField: "id_pt",
        label: "PT",
        type: "select",
        options: options_pt,
        value: this.id_pt === 1 ? 'PT. Kumala Putra Prima' : this.id_pt === 2 ? 'PT. Kumala Sukses Abadi' : 'PT. Kumala Sukses Bersama',
        onChange: (e) => {
          this.id_pt = e.target.value
          // console.log('cek text', this.id_pt);
        },
      },
      {
        validation: Yup.string().required("Supplier harus diisi"),
        dataField: "id_supplier",
        label: "Supplier",
        type: "select",
        onChange: (e) => {
          this.nama_supplier = e.target.value
          console.log('cek id_supplier', this.nama_supplier);
        },
        options: options_supplier,
        value: this.nama_supplier,
      },
      {
        validation: Yup.string().required("Merk Harus Diisi"),
        dataField: "id_merek",
        label: "Merek",
        type: "select",
        onChange: (e) => {
          this.id_merek = e.target.value
        },
        options: options_merek,
        value: this.nama_merek === null ? "Merek Belum Diinput" : this.nama_merek,
      },
      {
        validation: Yup.string().required("Produk kategori harus diisi"),
        dataField: "id_produk_kategori",
        label: "Produk Kategori",
        type: "select",
        onChange: (e) => {
          this.id_produk_kategori = e.target.value
         },
        options: options_produk_kategori,
        value: this.nama_produk_kategori,
      },
      {
        dataField: "id_sub_produk_kategori",
        label: "Produk Sub Kategori",
        type: "select",
        onChange: () => { },
        options: options_sub_kategori,
        value: this.nama_produk_kategori,
      },
      {
        validation: Yup.string().required("Produk satuan harus diisi"),
        dataField: "id_produk_satuan",
        label: "Produk Satuan",
        type: "select",
        onChange: (val) => { },
        options: options_produk_satuan,
        value: this.nama_produk_satuan,
      },
      {
        validation: Yup.string(),
        dataField: "kode",
        label: "Kode " + params.pageName,
        type: "text",
        value: this.kode,
      },
    ];
  }
}

import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import List from "./list";
import Detail from "./detail";
import Inbound from "./inbound";
import { useState } from "react";
// import { dispatcher } from "../../../redux/dispatcher";

export default function Penerimaan() {
  const history = useHistory();
  const [detail, setDetail] = useState([])
  // const active = dispatcher.penerimaan.get();

  return (
    <Switch>
      <Route path="/pembelian-penerimaan/list">
        <List setDetail={setDetail}/>
      </Route>

      {/* {active.master.id == 0 && <Redirect to="/pembelian-penerimaan/list" />} */}

      <Route path="/pembelian-penerimaan/inbound">
        <Inbound history={history} detail={detail}/>
      </Route>

      <Route path="/pembelian-penerimaan/detail">
        <Detail history={history} />
      </Route>

      <Redirect to="/pembelian-penerimaan/list" />
    </Switch>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import { CardBody, CardHeader, CardHeaderToolbar } from "../../../../../_metronic/_partials/controls";
import { Form, Formik } from "formik";
import React, { Component } from 'react';
import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import { FormsFields } from "../../../../components/forms-fields/FormsFields";
import { dispatcher } from '../../../../redux/dispatcher';
import { notificationAPI } from "../../../../api/notifications";
import ButtonLoad from "../../../../components/button-loading/buttonLoad";
import { Typography } from "@material-ui/core";
import { ReactDatatableDetails } from "../../../../components/react-datatable/reactDatatable";
import DataTableModel from "../../../../models/datatable-model/DataTableModel";
import { ModalFormsDetailTable } from "../../../../components/modal-forms/modalForms";
import ButtonActions from "../../../../components/button-actions/buttonActions";
import { getIdUser } from "../../../../api/api";

class ApprovalAdminPesananPenjualan extends Component {
   constructor(props) {
      super(props);
      this['title'] = props['isEdit'] === "approval" ? "Approval Admin Pesanan Penjulan" : "";
      this['isEdit'] = props['isEdit'];
      this['apiNotification'] = new notificationAPI();
      this['initialData'] = dispatcher['notification'].getApprovalPesananPenjualan();
      this['state'] = {
         countInvoiceBelumTerbayar: 0,
         dataDetailItem: [],
         modalDetailItem: {
            show: false,
            title: "",
         },
         modalDetail: {
            show: false,
            title: "",
         },
      };
      this['datatable'] = [{ id: "", no: "", name: "" }];
      this['columntable'] = [{ id: "", title: "" }];
      this['datatableDetailItem'] = [{ id: "", no: "", name: "" }];
      this['columntableDetailItem'] = [{ id: "", title: "" }];
      this['datatableDetail'] = [{ id: "", no: "", name: "" }];
      this['columntableDetail'] = [{ id: "", title: "" }];
   }
   componentDidMount() {
      this.LoadDetail();
   }
   async LoadDetail() {
      let data = this['initialData']['detail'];
      let dataItem = this['initialData']['detailItem'];
      this['DataTableModel'] = new DataTableModel();
      data.map((item, i) => {
         this['DataTableModel'].add({
            id: i,
            no: i + 1,
            dataModel: item,
            actions: (
               <>
                  {item['dpp'] < item['harga_margin'] ? (
                     <a className={"btn btn-icon btn-light-warning mr-5"} title={"harga dibawah margin"}>
                        <i className={"far fa-arrow-alt-circle-down"}></i>
                     </a>
                  ) : (
                     <a className={"btn btn-icon btn-light-primary mr-5"} title={"harga sesuai margin"}>
                        <i className={"far fa-check-circle"}></i>
                     </a>
                  )}
                  <ButtonActions
                     label={"history"}
                     onClick={async () =>
                        await this['handleDetailModal'].show(item['historyPembelian'])}
                  />
               </>
            ),
         });
         return item;
      });
      this['columntable'] = await this['DataTableModel'].getColumn();
      this['datatable'] = await this['DataTableModel'].getDatas();
      this.setState({
         ...this['state'],
         countInvoiceBelumTerbayar: dataItem.length,
         dataDetailItem: dataItem
      });
   }

   handleDetailItemModal = {
      LoadDataDetailItem: async (detail) => {
         this['DataTableModel'] = new DataTableModel({ readOnly: true });
         detail.map((item, i) => {
            this['DataTableModel'].add({
               id: item['id'],
               no: i + 1,
               dataModel: item,
               actions: null,
            });
            return item;
         });
         this['columntableDetailItem'] = await this['DataTableModel'].getColumn();
         this['datatableDetailItem'] = await this['DataTableModel'].getDatas();
      },
      show: async (detailItem) => {
         await this['handleDetailItemModal'].LoadDataDetailItem(detailItem);
         this.setState({
            modalDetailItem: {
               show: true,
               title: "Detail Invoice Belum Terbayar"
            }
         });
      },
      hide: () => {
         this.setState({
            modalDetailItem: {
               show: false,
               title: ""
            }
         });
      },
   };
   handleDetailModal = {
      LoadDataDetail: async (detail) => {
         this['DataTableModel'] = new DataTableModel({ readOnly: true });
         detail.map((item, i) => {
            this['DataTableModel'].add({
               id: item['id'],
               no: i + 1,
               dataModel: item,
               actions: null,
            });
            return item;
         });
         this['columntableDetail'] = await this['DataTableModel'].getColumn();
         this['datatableDetail'] = await this['DataTableModel'].getDatas();
      },
      show: async (detailItem) => {
         await this['handleDetailModal'].LoadDataDetail(detailItem);
         this.setState({
            modalDetail: {
               show: true,
               title: "History Pembelian Customer"
            }
         });
      },
      hide: () => {
         this.setState({
            modalDetail: {
               show: false,
               title: ""
            }
         });
      },
   };
   render() {
      const { modalDetailItem, dataDetailItem, modalDetail, countInvoiceBelumTerbayar } = this['state'];
      return (
         <>
            {/* Form */}
            <Formik
               enableReinitialize={true}
               initialValues={this['initialData']['master']}
               onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(true);
                  let dataSend = {
                     ...values,
                     aksi: "admin",
                     id_user: getIdUser(),
                  }
                  if (this['isEdit'] === "approval" && values['id'] !== 0) {
                     // Approved
                     this['apiNotification'].approvedPesananPenjualan(dataSend).then((res) => {
                        if (res['status']) {
                           dispatcher['snackbar'].show("Berhasil", res['message'], "primary");
                           this['props']['history'].goBack();
                        } else {
                           return dispatcher['snackbar'].show("Gagal", res['message'], "danger");
                        }
                     });
                  }
                  setSubmitting(false);
                  this.sendingData(values, setSubmitting);
               }}
            >
               {({ handleSubmit, isSubmitting, touched, errors, setFieldValue, setFieldTouched, values, }) => (
                  <AnimatedCard>
                     <CardHeader title={this['title']}>
                        <CardHeaderToolbar>
                           {/* Button Kembali */}
                           <button
                              type={"button"}
                              className={"btn btn-outline-secondary mx-1 btn-sm"}
                              onClick={() => this['props']['history'].goBack()}
                           ><i className={"fa fa-arrow-left"}></i> Kembali
                           </button>
                           {/* Button Simpan */}
                           <ButtonLoad
                              label={"Approve & Simpan"}
                              pending={isSubmitting}
                              disabled={isSubmitting}
                              classNameIcon={"fas fa-save"}
                              className={"btn btn-primary mx-1"}
                              onClick={async () => handleSubmit()}
                           />
                        </CardHeaderToolbar>
                     </CardHeader>
                     <CardBody>
                        <Form className={"form form-label-right"}>
                           <div className={"form-group row"}>
                              <div className={"col-lg-6"}>
                                 <div className={"form-group row"}>
                                    {/* Tanggal */}
                                    <FormsFields
                                       id={'tanggal'}
                                       type={"disabled-DatePicker"}
                                       label={"Tanggal"}
                                       className={"col-lg-12"}
                                       value={values['tanggal']}
                                    />
                                    {/* No Transaksi */}
                                    <FormsFields
                                       id={'no_transaksi'}
                                       type={"disabled-text"}
                                       label={"No. Transaksi"}
                                       className={"col-lg-12"}
                                       style={{ marginTop: "20px" }}
                                       value={values['no_transaksi']}
                                    />
                                    {/* Nama Customer */}
                                    <FormsFields
                                       id={'nama_customer'}
                                       type={"disabled-text"}
                                       label={"Nama Customer"}
                                       className={"col-lg-12"}
                                       style={{ marginTop: "20px" }}
                                       value={values['nama_customer']}
                                    />
                                    {/* Nama Organisasi */}
                                    <FormsFields
                                       id={'nama_organisasi'}
                                       type={"disabled-text"}
                                       label={"Nama Organisasi"}
                                       className={"col-lg-12"}
                                       style={{ marginTop: "20px" }}
                                       value={values['nama_organisasi']}
                                    />
                                 </div>
                                 <div>
                                    <label>Invoice Belum Terbayar</label>
                                    <div className={"input-group"}>
                                       <div className={"input-group-prepend"}>
                                          <button
                                             className={"btn btn-light-success"}
                                             type={"button"}
                                             onClick={async () => {
                                                await this['handleDetailItemModal'].show(dataDetailItem)
                                             }}>
                                             <i className={"far fa-list-alt"} aria-hidden={"false"}></i>
                                          </button>
                                       </div>
                                       <input type={"text"} className={"form-control col-lg-4"} disabled={true} aria-describedby={"basic-addon2"} value={countInvoiceBelumTerbayar} />
                                    </div>
                                 </div>
                              </div>
                              <div className={"col-lg-6"}>
                                 <div className={"form-group row"}>
                                    {/* Nama Sales */}
                                    <FormsFields
                                       id={'nama_sales'}
                                       type={"disabled-text"}
                                       label={"Nama Sales"}
                                       className={"col-lg-12"}
                                       value={values['nama_sales']}
                                    />
                                    {/* Jatuh Tempo */}
                                    <FormsFields
                                       id={'jatuh_tempo'}
                                       type={"disabled-text"}
                                       label={"Jatuh Tempo"}
                                       className={"col-lg-12"}
                                       style={{ marginTop: "20px" }}
                                       value={values['jatuh_tempo']}
                                    />
                                    {/* Jenis Pembayaran */}
                                    <FormsFields
                                       id={'jenis_pembayaran'}
                                       type={"disabled-text"}
                                       label={"Jenis Pembayaran"}
                                       className={"col-lg-12"}
                                       style={{ marginTop: "20px" }}
                                       value={values['jenis_pembayaran']}
                                    />
                                    {/* Nilai Limit Tersisa */}
                                    <FormsFields
                                       id={'limit_tersisa'}
                                       type={"disabled-currency"}
                                       label={values['limit_tersisa'] <= 0 ? "Over Limit" : "Sisa Limit"}
                                       className={"col-lg-12"}
                                       style={{ marginTop: "20px" }}
                                       inputStyle={values['limit_tersisa'] <= 0 ? {
                                          backgroundColor: '#f9d78a'
                                       } : null}
                                       value={values['limit_tersisa']}
                                    />
                                 </div>
                                 {values['nama_organisasi'] === "" ?
                                    (
                                       <span className={"label label-primary label-inline font-weight-bolder mr-2"}>Customer Umum</span>
                                    )
                                    : null}
                              </div>
                           </div>
                        </Form>
                        <div className={"form-group row"}>
                           <span className={"d-flex align-items-center justify-content-between mt-4 mb-4"}>
                              <Typography variant={"h6"}>Detail</Typography>
                           </span>
                           <ReactDatatableDetails
                              title={""}
                              data={this['datatable']}
                              columns={this['columntable']}
                           />
                           <div className={"col-lg-3"}>
                              <FormsFields
                                 id={'hidden'}
                                 type={"hidden"}
                                 label={"hidden"}
                                 className={"col-lg-3"}
                                 style={{ marginTop: "20px" }}
                              />
                           </div>
                           <div className={"col-lg-3"}>
                              {/* QTY Total */}
                              <FormsFields
                                 id={'qty_total'}
                                 type={"disabled-text"}
                                 label={"QTY Total"}
                                 className={"col-lg-12"}
                                 style={{ marginTop: "20px" }}
                                 value={values['qty_total']}
                              />
                              {/* Total Harga */}
                              <FormsFields
                                 id={'hpp_total'}
                                 type={"disabled-currency"}
                                 label={"Total Harga"}
                                 className={"col-lg-12"}
                                 style={{ marginTop: "20px" }}
                                 value={values['hpp_total']}
                              />
                              {/* Total Pajak */}
                              <FormsFields
                                 id={'pajak_total'}
                                 type={"disabled-currency"}
                                 label={"Total Pajak"}
                                 className={"col-lg-12"}
                                 style={{ marginTop: "20px" }}
                                 value={values['pajak_total']}
                              />
                           </div>
                           <div className={"col-lg-3"}>
                              {/* Diskon */}
                              <FormsFields
                                 id={'diskon_invoice'}
                                 type={"disabled-currency"}
                                 label={"Diskon"}
                                 className={"col-lg-12"}
                                 style={{ marginTop: "20px" }}
                                 value={values['diskon_invoice']}
                              />
                              {/* Total Diskon */}
                              <FormsFields
                                 id={'diskon_total'}
                                 type={"disabled-currency"}
                                 label={"Total Diskon"}
                                 className={"col-lg-12"}
                                 style={{ marginTop: "20px" }}
                                 value={values['diskon_total']}
                              />
                              {/* Total */}
                              <FormsFields
                                 id={'total'}
                                 type={"disabled-currency"}
                                 label={"Total"}
                                 className={"col-lg-12"}
                                 style={{ marginTop: "20px" }}
                                 value={values['total']}
                              />
                           </div>
                           <div className={"col-lg-3"}>
                              {/* Sub Total */}
                              <FormsFields
                                 id={'subtotal'}
                                 type={"disabled-currency"}
                                 label={"Sub Total"}
                                 className={"col-lg-12"}
                                 style={{ marginTop: "20px" }}
                                 value={values['subtotal']}
                              />
                           </div>
                           <div className={"col-lg-3"}>
                              <FormsFields
                                 id={'hidden'}
                                 type={"hidden"}
                                 label={"hidden"}
                                 className={"col-lg-12"}
                                 style={{ marginTop: "20px" }}
                              />
                           </div>
                           <div className={"col-lg-9"}>
                              <FormsFields
                                 id={'deskripsi'}
                                 type={"disabled-textarea"}
                                 label={"Keterangan"}
                                 className={"col-lg-12"}
                                 style={{ marginTop: "20px" }}
                                 rows={2}
                                 value={values['deskripsi']}
                              />
                           </div>
                        </div>
                     </CardBody>
                  </AnimatedCard>
               )}
            </Formik>
            {/* Begin: Modal Detail Invoice Belum Terbayar Form */}
            <ModalFormsDetailTable
               show={modalDetailItem['show']}
               title={modalDetailItem['title']}
               onHide={this['handleDetailItemModal']['hide']}
               content={
                  <>
                     <ReactDatatableDetails
                        title={""}
                        data={this['datatableDetailItem']}
                        columns={this['columntableDetailItem']}
                     />
                  </>
               }
            />
            {/* End: Modal Detail Invoice Belum Terbayar Form */}
            {/* Begin: Modal Detail History Customer Pembelian Form */}
            <ModalFormsDetailTable
               show={modalDetail['show']}
               title={modalDetail['title']}
               onHide={this['handleDetailModal']['hide']}
               content={
                  <>
                     <ReactDatatableDetails
                        title={""}
                        data={this['datatableDetail']}
                        columns={this['columntableDetail']}
                     />
                  </>
               }
            />
            {/* End: Modal Detail History Customer Pembelian Form */}
         </>
      );
   }
}

export default ApprovalAdminPesananPenjualan;
import XlsxPopulate from "xlsx-populate";
import moment from "moment";
import { saveAs } from "file-saver";
import { separatorHarga } from "../../../../services/separator-harga";

// Get Sheet Data Export To Excel
function getSheetData(data, header) {
  var fields = Object.keys(data[0]);
  var sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
}

// Export To Excell
function saveAsExcel(data, title) {
  console.log('Data For Excel', data);
  let currDate = moment().format('YYYY-MM-DD')
  console.log("Data", data);
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);

    // A
    const range = workbook.sheet(0).range("A1:A2");
    range.value("Bulan/Tahun");
    range.style({ horizontalAlignment: "center", verticalAlignment: "center", border:true})
    range.merged(true);
    sheet1.cell("A3").value('Januari');
    sheet1.cell("A4").value('Februari');
    sheet1.cell("A5").value('Maret');
    sheet1.cell("A6").value('April');
    sheet1.cell("A7").value('Mei');
    sheet1.cell("A8").value('Juni');
    sheet1.cell("A9").value('Juli');
    sheet1.cell("A10").value('Agustus');
    sheet1.cell("A11").value('September');
    sheet1.cell("A12").value('Oktober');
    sheet1.cell("A13").value('November');
    sheet1.cell("A14").value('Desember');
    sheet1.cell("A15").value('Total').style({ bold: true });
    // B
    const rangeB = workbook.sheet(0).range("B1:D1");
    rangeB.value(data[0].nama_perusahaan);
    rangeB.style({ horizontalAlignment: "center", verticalAlignment: "center", border:true})
    rangeB.merged(true);
    sheet1.cell("B2").value(`Total Penjualan`).style({ bold: true, border:true });
    sheet1.cell("B3").value(data[0].total_penjualan[0]);
    sheet1.cell("B4").value(data[0].total_penjualan[1]);
    sheet1.cell("B5").value(data[0].total_penjualan[2]);
    sheet1.cell("B6").value(data[0].total_penjualan[3]);
    sheet1.cell("B7").value(data[0].total_penjualan[4]);
    sheet1.cell("B8").value(data[0].total_penjualan[5]);
    sheet1.cell("B9").value(data[0].total_penjualan[6]);
    sheet1.cell("B10").value(data[0].total_penjualan[7]);
    sheet1.cell("B11").value(data[0].total_penjualan[8]);
    sheet1.cell("B12").value(data[0].total_penjualan[9]);
    sheet1.cell("B13").value(data[0].total_penjualan[10]);
    sheet1.cell("B14").value(data[0].total_penjualan[11]);
    sheet1.range("B15:C15").formula("=SUM(B3:B14)").style({ bold: true });
    sheet1.cell("D15").formula("=ROUND((C15/B15)*100,2)").style({ bold: true });

    // C
    const rangeC = workbook.sheet(0).range("C2:D2");
    rangeC.value('Sisa AR');
    rangeC.style({ horizontalAlignment: "center", verticalAlignment: "center", bold: true, border:true})
    rangeC.merged(true);
    sheet1.cell("C3").value(data[0].total_sisa[0]);
    sheet1.cell("C4").value(data[0].total_sisa[1]);
    sheet1.cell("C5").value(data[0].total_sisa[2]);
    sheet1.cell("C6").value(data[0].total_sisa[3]);
    sheet1.cell("C7").value(data[0].total_sisa[4]);
    sheet1.cell("C8").value(data[0].total_sisa[5]);
    sheet1.cell("C9").value(data[0].total_sisa[6]);
    sheet1.cell("C10").value(data[0].total_sisa[7]);
    sheet1.cell("C11").value(data[0].total_sisa[8]);
    sheet1.cell("C12").value(data[0].total_sisa[9]);
    sheet1.cell("C13").value(data[0].total_sisa[10]);
    sheet1.cell("C14").value(data[0].total_sisa[11]);
    let cellFormula1 = "=ROUND(IF(B3,C3/B3,0)*100,2)";

    sheet1.range("D3:D14").formula(cellFormula1);

    // E
    const rangeE = workbook.sheet(0).range("E1:G1");
    rangeE.value(data[1].nama_perusahaan);
    rangeE.style({ horizontalAlignment: "center", verticalAlignment: "center", })
    rangeE.merged(true);
    sheet1.cell("E2").value(`Total Penjualan`).style({ bold: true, border:true });
    sheet1.cell("E3").value(data[1].total_penjualan[0]);
    sheet1.cell("E4").value(data[1].total_penjualan[1]);
    sheet1.cell("E5").value(data[1].total_penjualan[2]);
    sheet1.cell("E6").value(data[1].total_penjualan[3]);
    sheet1.cell("E7").value(data[1].total_penjualan[4]);
    sheet1.cell("E8").value(data[1].total_penjualan[5]);
    sheet1.cell("E9").value(data[1].total_penjualan[6]);
    sheet1.cell("E10").value(data[1].total_penjualan[7]);
    sheet1.cell("E11").value(data[1].total_penjualan[8]);
    sheet1.cell("E12").value(data[1].total_penjualan[9]);
    sheet1.cell("E13").value(data[1].total_penjualan[10]);
    sheet1.cell("E14").value(data[1].total_penjualan[11]);
    sheet1.range("E15:F15").formula("=SUM(E3:E14)").style({ bold: true });
    
    // F
    const rangeF = workbook.sheet(0).range("F2:G2");
    rangeF.value('Sisa AR');
    rangeF.style({ horizontalAlignment: "center", verticalAlignment: "center", bold: true, border:true})
    rangeF.merged(true);
    sheet1.cell("F3").value(data[1].total_sisa[0]);
    sheet1.cell("F4").value(data[1].total_sisa[1]);
    sheet1.cell("F5").value(data[1].total_sisa[2]);
    sheet1.cell("F6").value(data[1].total_sisa[3]);
    sheet1.cell("F7").value(data[1].total_sisa[4]);
    sheet1.cell("F8").value(data[1].total_sisa[5]);
    sheet1.cell("F9").value(data[1].total_sisa[6]);
    sheet1.cell("F10").value(data[1].total_sisa[7]);
    sheet1.cell("F11").value(data[1].total_sisa[8]);
    sheet1.cell("F12").value(data[1].total_sisa[9]);
    sheet1.cell("F13").value(data[1].total_sisa[10]);
    sheet1.cell("F14").value(data[1].total_sisa[11]);
    let cellFormula2 = "=ROUND(IF(E3,F3/E3,0)*100,2)";
    sheet1.range("G3:G14").formula(cellFormula2);
    sheet1.cell("G15").formula("=ROUND((F15/E15)*100,2)").style({ bold: true });

    // H
    const rangeH = workbook.sheet(0).range("H1:J1");
    rangeH.value(data[2].nama_perusahaan);
    rangeH.style({ horizontalAlignment: "center", verticalAlignment: "center", border:true})
    rangeH.merged(true);
    sheet1.cell("H2").value(`Total Penjualan`).style({ bold: true, border:true });
    sheet1.cell("H3").value(data[2].total_penjualan[0]);
    sheet1.cell("H4").value(data[2].total_penjualan[1]);
    sheet1.cell("H5").value(data[2].total_penjualan[2]);
    sheet1.cell("H6").value(data[2].total_penjualan[3]);
    sheet1.cell("H7").value(data[2].total_penjualan[4]);
    sheet1.cell("H8").value(data[2].total_penjualan[5]);
    sheet1.cell("H9").value(data[2].total_penjualan[6]);
    sheet1.cell("H10").value(data[2].total_penjualan[7]);
    sheet1.cell("H11").value(data[2].total_penjualan[8]);
    sheet1.cell("H12").value(data[2].total_penjualan[9]);
    sheet1.cell("H13").value(data[2].total_penjualan[10]);
    sheet1.cell("H14").value(data[2].total_penjualan[11]);
    sheet1.range("H15:I15").formula("=SUM(H3:H14)").style({ bold: true });
    
    // I
    const rangeI = workbook.sheet(0).range("I2:J2");
    rangeI.value('Sisa AR');
    rangeI.style({ horizontalAlignment: "center", verticalAlignment: "center", bold: true, border:true })
    rangeI.merged(true);
    sheet1.cell("I3").value(data[2].total_sisa[0]);
    sheet1.cell("I4").value(data[2].total_sisa[1]);
    sheet1.cell("I5").value(data[2].total_sisa[2]);
    sheet1.cell("I6").value(data[2].total_sisa[3]);
    sheet1.cell("I7").value(data[2].total_sisa[4]);
    sheet1.cell("I8").value(data[2].total_sisa[5]);
    sheet1.cell("I9").value(data[2].total_sisa[6]);
    sheet1.cell("I10").value(data[2].total_sisa[7]);
    sheet1.cell("I11").value(data[2].total_sisa[8]);
    sheet1.cell("I12").value(data[2].total_sisa[9]);
    sheet1.cell("I13").value(data[2].total_sisa[10]);
    sheet1.cell("I14").value(data[2].total_sisa[11]);
    let cellFormula3 = "=ROUND(IF(H3,I3/H3,0)*100,2)";
    sheet1.range("J3:J14").formula(cellFormula3);
    sheet1.cell("J15").formula("=ROUND((I15/H15)*100,2)").style({ bold: true });

    // const range = sheet1.usedRange();
    sheet1.row(1).style({ bold: true, horizontalAlignment: 'center' ,border:true});
    let rowCt = 2
    while (rowCt <= 15) {
      sheet1.row(rowCt).style({ horizontalAlignment: 'center' });
      rowCt++
    }

    let rangeFmt = ["B3:C15", "E3:F15", "H3:I15"]

    rangeFmt.map((val) => {
     return sheet1.range(val).style({
        numberFormat: "Rp 0,0"
      })
    })

    const allrange = sheet1.usedRange();
    allrange.style("border", true);

    // Styls Center Align
    // let centerAlign = ["A", "B", "C", "D", "E", "F","G", "H", "I", "J", "K", "L", "M"];
    // for (let i = 0; i < centerAlign.length; i++) {
    //   sheet1.range(centerAlign[i] + "15:" + centerAlign[i] + dataLength).style({
    //     horizontalAlignment: "center"
    //   });

    // }
    sheet1.column("A").width(25);
    sheet1.column("B").width(23);
    sheet1.column("C").width(23);
    sheet1.column("D").width(23);
    sheet1.column("E").width(23);
    sheet1.column("F").width(23);
    sheet1.column("G").width(23);
    sheet1.column("H").width(23);
    sheet1.column("I").width(23);
    sheet1.column("J").width(23);
    sheet1.column("K").width(23);
    sheet1.column("L").width(23);
    sheet1.column("M").width(23);
    return workbook.outputAsync().then((res) => {
      saveAs(res, "Laporan (" + title + " " + currDate + ").xlsx");
    });
  });
}

export { saveAsExcel, getSheetData }
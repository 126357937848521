/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { CardBody, CardFooter, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls';
import { AnimatedCard } from '../../../components/animated-card/animatedCard';
import { Button, Modal } from 'react-bootstrap';
import { useHistory, useLocation } from "react-router-dom";
import Select2 from "react-select";
import { StokAPI } from '../../../api/stok';
import store from '../../../redux/store';
import moment from 'moment';
import { TableWithoutPagination } from '../../../components/table/Table';
import { dispatcher } from '../../../redux/dispatcher';
import { BagKeluarAPI } from '../../../api/bag-keluar';
import { ProdukAPI } from '../../../api/produk';
import { API } from '../../../api/api';

function FormDetail() {
  let dtMaster = useLocation()
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [dtDb, setdtDb] = useState([]);
  const hide = dtMaster.state?.form ? false : true;
  const [data, setData] = useState([]);
  const [opsi, setOpsi] = useState([]);
  const user = store.getState().auth.id;
  const [slcItem, setItem] = useState();
  const [slcGdg, setGdg] = useState("0")
  const [gudang, setGudang] = useState([])
  const [cabang] = useState(store.getState().auth.id_perusahaan);
  const [keterangan, setKeterangan] = useState(dtMaster.state?.keterangan)
  const [date, setDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const columns = [
    { dataField: 'id', text: 'Id', sort: true, hidden: true },
    { dataField: 'nama', text: 'Nama Produk', sort: true },
    { dataField: 'satuan', text: 'Satuan', sort: true },
    { dataField: 'masuk', text: 'Stok Masuk', sort: true },
    { dataField: 'keluar', text: 'Stok Keluar', sort: true },
    { dataField: 'total', text: 'Total Stok', sort: true },
    { dataField: 'fisik', text: 'Stok Fisik', sort: true },
    { dataField: 'selisih', text: 'Selisih', sort: false },
    { dataField: 'actions', text: 'Action', sort: false, hidden: hide }
  ];
  const handleClose = () => {
    setDetail({
      id_produk: 0,
      id: 0,
      nama: "",
      masuk: 0,
      keluar: 0,
      alokasi: 0,
      total: 0,
      fisik: 0,
      selisih: 0,
      status_validasi: 0,
      satuan: "",
    })
    setItem()
    setGdg("0")
    setGudang([])
    setRefresh(!refresh)
    setShow(false)
  };
  let cekValidasi = data.some(dt => dt.status_validasi === 0)
  const [detail, setDetail] = useState({
    id_produk: 0,
    id: 0,
    nama: "",
    masuk: 0,
    keluar: 0,
    alokasi: 0,
    total: 0,
    fisik: 0,
    selisih: 0,
    id_gudang: "0",
    status_validasi: 0,
    satuan: "",
    actions: "",
  });


  const totalStokAktual = Object.values(data).reduce((t, { fisik }) => t + fisik, 0)
  const totalStok = Object.values(data).reduce((t, { total }) => t + total, 0)
  const totalSelisih = Object.values(data).reduce((t, { selisih }) => t + selisih, 0)

  const setDefault = () => {
    setDetail({
      id_produk: 0,
      id: 0,
      nama: "",
      masuk: 0,
      keluar: 0,
      alokasi: 0,
      total: 0,
      fisik: 0,
      selisih: 0,
      id_gudang: "0",
      status_validasi: 0,
      satuan: "",
      actions: "",
    });
  }

  function onAddData(e) {
    const index = data.findIndex(object => object.id === e.id);
    console.log(index); // 👉️ -1

    if (index === -1) {
      setRefresh(!refresh)
      setData(data => [...data, detail])
      handleClose()
    } else {
      dispatcher.snackbar.show("Error", "Duplikat Data, Silahkan pilih item lain", "danger");
    }
  }

  function onDeleteData(val) {
    const newArr = data.filter(object => {
      return object.id !== val;
    });
    setData(newArr)
    setRefresh(!refresh)
  }

  const { masuk, keluar, alokasi, total, satuan, fisik, selisih } = detail

  const fetchDetail = async (data) => {
    await new BagKeluarAPI().getDetail(data).then((res) => {
      console.log("Data Detail", res.data);
      let ops = []
      if (res.data.length > 0 && dtMaster.state?.form) {
        res.data.map((v, i) => {
          let opt = {
            ...v,
            id: v.id_produk,
            actions: <Button
              disabled={v.status_validasi === 1 ? true : false}
              onClick={() => {
                onDeleteData(v.id_produk)
              }}><i className="fa fa-trash"></i></Button>
          }
          return ops = [...ops, opt]
        });
      } else {
        res.data.map((v, i) => {
          let opt = {
            ...v,
            id: v.id_produk,
          }
          return ops = [...ops, opt]
        });
      }

      if (res.data.length === 0) {
        return ops = [...ops]
      }
      setdtDb(ops)
      setRefresh(!refresh)
      return setData(ops)
    })
  }

  const loadDetail = async (v) => {
    await new StokAPI().stokAllGudang(v).then((value) => {
      value.data.map((val) => {
        return setDetail({
          id_produk: val.id_produk,
          id: val.id_produk,
          nama: val.nama_produk,
          masuk: val.masuk,
          keluar: val.keluar,
          alokasi: val.alokasi,
          id_gudang: val.id_gudang,
          total: (val.masuk - val.keluar) + val.alokasi,
          satuan: val.satuan,
          fisik: 0,
          status_validasi: 0,
          selisih: 0,
        })
      })
    })
  }


  const fetchData = async (val) => {
    try {
      let datas = await new ProdukAPI().getAll();
      let filtered = datas.data.filter((fil) => {
        return fil.id_perusahaan === val ? fil : "";
      });
      let produk_options = [];
      const newArr = filtered.filter(({ id_produk: id1 }) => !data.some(({ id_produk: id2 }) => id2 === id1));
      filtered.map((data, i) => {
        return produk_options = [
          ...produk_options,
          { id: data.id, text: data.nama, label: `${data.nama} || ${data.toProdukSatuan.nama}`, value: parseInt(data.id) },
        ]
      })
      setOpsi(produk_options)
    } catch (error) {
      console.error(error);
    }
  };

  const loadGudang = async (id) => {
    await new ProdukAPI().getGudangProduk(id).then((values) => {
      let filter = values.data.filter((fil) => fil.id_perusahaan === cabang)
      // let ops = []
      filter.map((v) => {
        return setGudang(<option value={v.id}> {v.nama} </option>)
      })
      if (filter.length === 0) {
        setGudang([])
      }
    });
  }

  useEffect(() => {
    fetchData(cabang)

    if (data.length === 0) {
      fetchDetail(dtMaster.state?.id_bag_keluar).then((res) => {
      })
    }

    if (data.length > 0 && dtMaster.state?.form) {
      let dt = []
      data.map((val) => {
        let newDt = {
          id_produk: val.id_produk,
          id: val.id,
          nama: val.nama,
          masuk: val.masuk,
          keluar: val.keluar,
          alokasi: val.alokasi,
          total: val.total,
          satuan: val.satuan,
          fisik: val.fisik,
          selisih: val.selisih,
          id_gudang: parseInt(val.id_gudang),
          status_validasi: val.status_validasi,
          actions: <Button
            disabled={val.status_validasi === 1 ? true : false}
            onClick={() => {
              onDeleteData(val.id)
            }}><i className="fa fa-trash"></i></Button>
        }
        return dt = [...dt, newDt]
      })
      setData(dt)
    }
  }, [refresh])


  return (
    <>
      <AnimatedCard>
        <CardHeader title={"Stok BAG Keluar Detail"}>
          <CardHeaderToolbar>
            <Button
              type="button"
              className="btn btn-secondary mx-1"
              onClick={() => history.goBack()}
            >
              <i className="fa fa-arrow-left"></i>
              Kembali
            </Button>
            {dtMaster.state?.status_validasi === 1 ?
              <Button
                type="button"
                className="btn btn-warning mx-1"
                onClick={async () => {
                  window.open(
                      API.host +
                      "retail/stok/ctkn_bagkeluar?id=" +
                      dtMaster.state?.id_bag_keluar + "&id_user=" + user,
                      "_blank",
                      "noopener,noreferrer"
                  );
              }}
              >
                <i className={"fa fa-envelope-open-text"}></i> Cetak BAG
              </Button> : ""
            }
            {dtMaster.state?.form === true ?
              <Button
                onClick={() => {
                  setShow(true)
                }}
              >
                Add Item
              </Button> : ""
            }
          </CardHeaderToolbar>
        </CardHeader>
        {/* Modal */}
        <Modal show={show} onHide={handleClose} style={{ textAlign: "center" }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Item</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <div className="row-xl-4" style={{ marginTop: "0px", marginBottom: "10px" }}>
              <label > Pilih Item </label>
              <Select2
                name={"opsi"}
                placeholder={"--- Pilih Item ---"}
                size="sm"
                options={opsi}
                isClearable={false}
                value={
                  opsi ? opsi.find((option) => option.value === slcItem) : ""
                }
                onChange={async (e) => {
                  setItem(e ? e.value : "");
                  setDefault()
                  setGdg("0");
                  setGudang([]);
                  await loadGudang(e.value)
                }}
              />
            </div>
            <div className='row-xl-4'>
              <label> Pilih Gudang </label>
              <select className='form-control form-select form-select-lg mb-3'
                required
                value={detail.id_gudang}
                onChange={async (e) => {
                  setDetail({
                    ...detail,
                    id_gudang: e.target.value
                  })
                  setGdg(e.target.value)
                  let gudang = parseInt(e.target.value)
                  let produk = slcItem
                  await loadDetail({ cabang, date, gudang, produk })
                }}>
                <option value="0" disabled>--Gudang--</option>
                {gudang}
              </select>
            </div>
            <div className='d-flex'>
              <div className='col-lg-6 mr-auto'>
                <label>Stok Masuk</label>
                <input className="form-control" type="number" value={masuk} disabled></input>
                <label>Stok Keluar</label>
                <input className="form-control" type="number" value={keluar} disabled></input>
                <label>Stok Alokasi</label>
                <input className="form-control" type="number" value={alokasi} disabled></input>
              </div>
              <div className='col-lg-6'>
                <label>Stok Aktual</label>
                <input className="form-control" type="number" value={total} disabled></input>
                <label>Stok Fisik</label>
                <input className="form-control" type="number" value={fisik}
                  onChange={(e) => {
                    setDetail({
                      ...detail,
                      fisik: parseInt(e.target.value),
                      selisih: total - parseInt(e.target.value)
                    })
                  }}
                ></input>
                <label>Selisih</label>
                <input className="form-control" type="number" value={selisih} disabled></input>
                <label>Satuan</label>
                <input className="form-control" type="text" value={satuan} disabled></input>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={() => {
              if (slcGdg === "0") {
                dispatcher.snackbar.show("Error", "Silahkan Pilih Gudang", "danger");
              } else {
                onAddData(detail)
              }
            }}>
              Add
            </Button>
          </Modal.Footer>
        </Modal>
        <CardBody>
          {data.length > 0 ?
            <TableWithoutPagination data={data} columns={columns} />
            : <h3>Empty Data</h3>}
          <div className='d-flex' style={{ marginTop: '15px' }}>
            <div className='col-lg-6'>
              <label>Total Stok </label>
              <input className="form-control" type="number" value={totalStok} disabled></input>
              <label className='mt-4'>Total Selisih </label>
              <input className="form-control" type="number" value={totalSelisih} disabled></input>
            </div>
            <div className='col-lg-6'>
              <label>Total Stok Fisik</label>
              <input className="form-control" type="number" value={totalStokAktual} disabled></input>
              <label className='mt-4'>Keterangan</label>
              <textarea className="form-control" onChange={(e) => {
                setKeterangan(e.target.value)
              }} type="textarea" rows={3} value={keterangan} disabled={hide ? true : false}></textarea>
            </div>
          </div>
        </CardBody>
        {data.length > 0 && dtMaster.state?.form === true ?
          <CardFooter>
            <div className="d-flex justify-content-end">
              {cekValidasi ? "" :
                <Button
                  className='mr-4'
                  type='button'
                  onClick={async () => {
                    let master = {
                      id_bag_keluar: dtMaster.state?.id_bag_keluar,
                      no_transaksi: dtMaster.state?.no_transaksi,
                      id_perusahaan: cabang,
                      id_user: store.getState().auth.id,
                      tanggal: dtMaster.state?.tanggal
                    }
                    // let details = data
                    await new BagKeluarAPI().closeBagKeluar({ master, user }).then((res) => {
                      if (res.status === true) {
                        dispatcher.snackbar.show("Berhasil", "Data Berhasil Disimpan", "primary");
                        setTimeout(() => {
                          history.goBack()
                        }, 1000)
                      }
                    })
                  }}
                >
                  <i className="fa fa-window-close"></i>
                  Close BAG Keluar
                </Button>
              }
              <Button
                type='button'
                onClick={async () => {
                  console.log("Data", data);
                  let master = {
                    id_bag_keluar: dtMaster.state?.id_bag_keluar,
                    no_transaksi: dtMaster.state?.no_transaksi,
                    id_perusahaan: cabang,
                    tanggal: dtMaster.state?.tanggal,
                    keterangan: keterangan
                  }
                  // let details = data
                  const results = data.filter(({ id_produk: id1, selisih: slsh1 }) => !dtDb.some(({ id_produk: id2, selisih: slsh2 }) => id2 === id1 && slsh2 === slsh1));
                  const results2 = dtDb.filter(({ id_produk: id1, selisih: slsh1 }) => !data.some(({ id_produk: id2, selisih: slsh2 }) => id2 === id1 && slsh2 === slsh1));
                  if (results.length > 0 || results2.length > 0) {
                    await new BagKeluarAPI().setDetail({ master, data }).then((res) => {
                      if (res.status === true) {
                        dispatcher.snackbar.show("Berhasil", "Data Berhasil Disimpan", "primary");
                        setTimeout(() => {
                          history.goBack()
                        }, 1000)
                      }
                    })
                  } else {
                    dispatcher.snackbar.show("Error", "Tidak Ada Perubahan Data", "danger");
                  }
                }}
              >
                <i className="fa fa-save"></i>
                Simpan
              </Button>
            </div>
          </CardFooter>
          : ""}
      </AnimatedCard>
    </>
  )
}

export default FormDetail
import React from 'react'
import { CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import { AnimatedCard } from '../../../components/animated-card/animatedCard'
import { useState } from 'react';
import Select2 from "react-select";
import GrafikPenjualanSales from './sales-kpi';
import GrafikPenjualanCabang from './cabang-kpi';
import GrafikPenjualanOrganisasi from './organisasi-kpi';
import GrafikPenjualanCustomer from './customer-kpi';
import GrafikPenjualanQtyProduk from './qtyProduk-kpi';
import GrafikPenjualanNilaiProduk from './nilaiProduk-kpi';
import PenjualanQtyProdukCust from './qtyProduk-cust';

function GrafikPenjualan() {
  const [kategori] = useState([{
    value: 1,
    label: "Sales"
  }, {
    value: 2,
    label: "Cabang"
  },
  {
    value: 3,
    label: "Organisasi"
  },
  {
    value: 4,
    label: "Customer"
  },
  {
    value: 5,
    label: "QTY Produk"
  },
  {
    value: 6,
    label: "Nilai Produk"
  },
  {
    value: 7,
    label: "QTY Produk by Customer"
  },
  ])

  const [sKategori, setSkategori] = useState(1)

  return (
    <>
      <AnimatedCard>
        <CardHeader title={'Laporan & Grafik Penjualan'}>
          <CardHeaderToolbar >
            <div style={{zIndex: '5'}}>
              <label style={{ width: "250px" }}>Kategori</label>
              <Select2
                name={"Kategori"}
                size="lg"
                options={kategori}
                isClearable={false}
                value={
                  kategori
                    ? kategori.find(
                      (option) => option.value === sKategori
                    )
                    : ""
                }
                onChange={(e) => {
                  setSkategori(e ? e.value : []);
                }}
              >
              </Select2>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {sKategori === 1 ?
            <GrafikPenjualanSales /> :
            sKategori === 2 ?
              <GrafikPenjualanCabang /> :
              sKategori === 3 ?
                <GrafikPenjualanOrganisasi /> :
                sKategori === 4 ?
                  <GrafikPenjualanCustomer /> :
                  sKategori === 5 ?
                    <GrafikPenjualanQtyProduk /> :
                    sKategori === 6 ?
                      <GrafikPenjualanNilaiProduk /> :
                      <PenjualanQtyProdukCust />
          }
        </CardBody>
      </AnimatedCard>
    </>
  )
}



export default GrafikPenjualan
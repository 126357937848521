/* eslint-disable array-callback-return */
import React from "react";
import {
  CardBody,
  CardHeader,
  CardFooter,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TableWithoutPagination } from "../../../../components/table/Table";
import { Modal, Button } from "react-bootstrap";
import { Fields } from "../../../../components/fields/Fields";
import { Typography } from "@material-ui/core";
import { dispatcher } from "../../../../redux/dispatcher";
import store from "../../../../redux/store";
import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import PesananPembelianDetailModel from "../../../../models/pesanan-pembelian-detail-model/PesananPembelianDetailModel";
import { PesananPembelianAPI } from "../../../../api/pesanan-pembelian";
import PesananPembelianModel from "../../../../models/pesanan-pembelian-model/PesananPembelianModel";
import SimpleTableModel from "../../../../models/simpletable-model/SimpleTableModel";
import { FormsFields } from "../../../../components/forms-fields/FormsFields";

export default class TambahPesananPembelian extends React.Component {
  constructor(props) {
    super(props);
    this.initialData = new PesananPembelianModel();
    this.deskripsi = this.initialData.master.deskripsi
    this.initialDetailData = new PesananPembelianDetailModel();
    this.api = new PesananPembelianAPI();
    this.simpleTableModel = new SimpleTableModel();
    this.pageName = "Tambah Purchase Order";
    this.isEdit = props.isEdit;
    this.state = {
      checked: false,
      checkBox: 1,
      deskripsi: this.initialData.master.deskripsi,
      modal: {
        show: false,
        initialDetailData: this.initialDetailData,
        isEdit: true,
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
    this.columntable = [{ dataField: "", text: "" }];
    this.datatable = [{ id: 0, nama: "" }];
    this.fields = [];
    this.validation = Yup.object().shape({});
    this.detailFields = [];
    this.detailValidation = Yup.object().shape({});
    this.role = store.getState().auth.alias_role;
  }

  async componentDidMount() {
    await this.loadFields();
    await this.loadDetail();
    let yup = {};
    await this.initialData.getEditable().then((values) => {
      values.map((item) => {
        yup[item.dataField] = item.validation;
      });
    });
    this.validation = Yup.object().shape(yup);

    yup = {};
    await this.state.modal.initialDetailData.getEditable().then((values) => {
      values.map((item) => {
        yup[item.dataField] = item.validation;
      });
    });
    this.detailValidation = Yup.object().shape(yup);
    this.setState({ ...this.state });
  }

  async loadFields() {
    this.fields = [];
    await this.initialData
      .getEditable({ pageName: this.pageName, isForm: true })
      .then((values) => {
        values.map((item, i) => {
          return (this.fields = [...this.fields, item]);
        });
      });
    this.setState({ ...this.state });
  }

  async loadDetail() {
    let datas = this.initialData.detail;
    this.simpleTableModel = new SimpleTableModel();
    datas.map((item, i) => {
      if (item.status !== 0)
        this.simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: item,
          actions: (
            <span>
              <Button
                size="sm"
                className="mx-1"
                variant="outline-primary"
                onClick={async () => await this.handleModal.edit(item)}
              >
                Edit
              </Button>
              {/* {["admin"].includes(this.role) && ( */}
              <Button
                size="sm"
                className="mx-1"
                variant="outline-danger"
                onClick={() => {
                  this.handleAlert.show(item);
                }}
              >
                Hapus
              </Button>
              {/* )} */}
            </span>
          ),
        });
    });

    this.datatable = await this.simpleTableModel.getDatatables();
    this.columntable = await this.simpleTableModel.getColumnHeaders();
    this.setState({ ...this.state });
  }

  onPajak () {
    if (this.state.checkBox === 1) {
      this.initialData.master.pajak_total= "0" 
      this.initialData.master.total = this.initialData.master.subtotal
      console.log(this.initialData.master);
    } 
    if (this.state.checkBox === 0) {
      this.initialData.hitungTotalan()
    }
  }

  handleModal = {
    loadDetailFields: async () => {
      this.detailFields = [];
      await this.state.modal.initialDetailData
        .getEditable({ pageName: this.pageName, isForm: true })
        .then((values) => {
          values.map((item, i) => {
            return (this.detailFields = [...this.detailFields, { ...item }]);
          });
        });
    },
    tambah: async () => {
      let state = { ...this.state };
      let supplier = this.initialData.master.id_pemasok
      state.modal.initialDetailData = new PesananPembelianDetailModel();
      state.modal.initialDetailData.id_pemasok = supplier;
      if (this.initialData.master.id_pemasok === 0) {
        dispatcher.snackbar.show(
          "Warning",
          "Pilih supplier terlebih dahulu",
          "warning"
        );
        return null;
      }
      state.modal.show = true;
      state.modal.title = `Item Baru`;
      state.modal.isEdit = false;
      await this.handleModal.loadDetailFields();
      this.setState({ ...state });
    },
    edit: async (dataFromModel) => {
      let state = { ...this.state };
      state.modal.initialDetailData = dataFromModel
      // new PesananPembelianDetailModel({
      //   ...dataFromModel.nulldata,
      //   ...dataFromModel,
      // });
      state.modal.show = true;
      state.modal.title = `Edit Item`;
      state.modal.isEdit = true;
      await this.handleModal.loadDetailFields();
      this.setState({ ...state });
    },
    hide: () => {
      let modal = { ...this.state.modal };
      modal.show = false;
      this.setState({ modal });
    },
  };

  handleAlert = {
    show: (dataModel) => {
      let alert = { ...this.state.alert };
      alert.show = true;
      alert.activeId = dataModel;
      this.setState({ alert: alert });
    },
    hide: () => {
      let alert = { ...this.state.alert };
      alert.show = false;
      alert.activeId = null;
      this.setState({ alert: alert });
    },
    confirmed: async () => {
      await this.initialData.handleDetail.del(this.state.alert.activeId);
      this.handleAlert.hide();
      this.loadDetail();
    },
  };

  render() {
    return (
      <>
        <Modal
          show={this.state.alert.show}
          onHide={() => this.handleAlert.hide()}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Hapus Data Ini?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Anda yakin ingin menghapus data ini?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleAlert.hide()}>
              Batal
            </Button>
            <Button
              variant="warning"
              onClick={() => this.handleAlert.confirmed()}
            >
              Hapus
            </Button>
          </Modal.Footer>
        </Modal>
        <Formik
          enableReinitialize={true}
          initialValues={this.initialData.master}
          validator={this.validation}
          onSubmit={async (values, { resetForm }) => {
            this.initialData.removeSeparator();
            this.initialData.removeAllDetailSeparator();
            console.log('Dataaa',this.initialData);
            await this.api.set(this.initialData);
            await dispatcher.snackbar.show("Berhasil", "Data berhasil ditambah", "primary")
            // console.log('cek values',values);
            // resetForm({values: ""})
            // this.props.history.push('/pembelian-pesanan-pembelian/list');
            // this.props.history.goBack();
            // console.log("isi on submit", this.initialData);
          }}
        >
          {({ handleSubmit, setFieldValue, resetForm, touched, errors, values, setFieldTouched }) => (
            <AnimatedCard>
              <CardHeader title={this.pageName}>
                <CardHeaderToolbar>
                  <button
                    type="reset"
                    className="btn btn-outline-primary mx-1"
                    onClick={(e) => {
                      // console.log('initial data',this.initialDetailData);
                      // console.log('data field',this.fields);
                      window.location.reload()
                      // resetForm({values: ''})
                    }}
                  >
                    <i className={"fa fa-plus"}></i>
                    Tambah Purchase Order
                  </button>
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <Form className="form form-label-right">
                  <div className="row">
                    {this.fields.map((field, i) => (
                      ['id_pemasok', 'tanggal', 'jatuh_tempo', 'no_transaksi', 'id_perusahaan'].includes(field.dataField)
                        ?
                        <div key={i} className="form-group col-lg-6">
                          <Fields
                            {...field}
                            setFieldValue={setFieldValue}
                          />
                        </div> : ""
                    ))}
                  </div>
                  <span className="d-flex align-items-center justify-content-between mt-4">
                    <Typography variant="h6">Detail Item</Typography>

                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      onClick={async () => await this.handleModal.tambah()}
                    >
                      <i className="fa fa-plus"></i>
                      Tambah Item
                    </button>
                  </span>
                  <TableWithoutPagination
                    data={this.datatable}
                    columns={this.columntable}
                  />
                  <div className="d-flex row" style={{ marginLeft: "40%", marginTop: "20px" }}>
                    {this.fields.map((field, i) => (
                      !['id_pemasok', 'tanggal', 'jatuh_tempo', 'no_transaksi', 'id_perusahaan'].includes(field.dataField)
                        ?
                        <div key={i} className="form-group col-md-4 justify-content-around">
                          <Fields
                            {...field}
                            // type="disabled-text"
                            setFieldValue={setFieldValue}
                          />
                        </div> : ""
                    ))}
                  <div className="form-group col-md-4">
                    <input
                      style={{scale:"1.5"}}
                      type="checkbox"
                      id="topping"
                      name="statuspajak"
                      value={this.state.checkBox}
                      checked={this.state.checked}
                      onChange={(e) => {
                        this.setState({
                          checked: !this.state.checked,
                          checkBox: this.state.checked ? 1 : 0
                        })
                        this.onPajak()
                      }}
                    />
                    <span style={{marginLeft:"5%"}}>
                    Tanpa Pajak
                    </span>
                  </div>
                  </div>
                  <div className="form-group">
                    <FormsFields
                      id={'deskripsi'}
                      type={'textarea'}
                      rows={8}
                      label={'Keterangan'}
                      setFieldValue={setFieldValue}
                      onChange={(e) => {
                        this.initialData.master.deskripsi = e.target.value
                      }}
                      paramsFormik={{ touched, errors, values, setFieldTouched }}
                    />
                  </div>
                </Form>
              </CardBody>
              <CardFooter>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-primary mx-1"
                    onClick={async () => {
                      handleSubmit();
                      // this.initialData.removeSeparator();
                      // this.initialData.removeAllDetailSeparator();
                      //   if (this.props.isEdit) {
                      //     await this.api.edit(this.initialData);
                      //   } else if (!this.state.modal.isEdit) {
                      //     await this.api.set(this.initialData);
                      // }
                      // console.log(handleSubmit());
                      // await this.api.set(this.initialData);
                      // dispatcher.snackbar.show("Berhasil", "Data berhasil ditambah", "primary");
                      //   this.props.history.goBack();
                      // await this.loadFields();
                    }}
                  >
                    <i className="fa fa-save"></i>
                    Simpan
                  </button>
                </div>
              </CardFooter>
            </AnimatedCard>
          )}
        </Formik>

        <Formik
          enableReinitialize={true}
          initialValues={this.state.modal.initialDetailData}
          validationSchema={this.detailValidation}
          onSubmit={async (values, { resetForm }) => {
            //  if(this.state.modal.initialDetailData.diskon_opsi === 2){
            //   this.state.modal.initialDetailData.typeDiskon = "disabled-text"
            //  }
            if (this.state.modal.isEdit) {
              await this.initialData.handleDetail.edit(
                this.state.modal.initialDetailData
              );
            } else {
              await this.initialData.handleDetail.set(
                this.state.modal.initialDetailData
              );
            }
            await this.loadDetail();
            resetForm();
            this.handleModal.hide();
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Modal
              show={this.state.modal.show}
              onHide={() => this.handleModal.hide()}
              aria-labelledby="contained-modal-title-vcenter"
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modal.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  {this.detailFields.map((field, i) => {
                    return (
                      <div key={i} className="form-group col-lg-6">
                        <Fields {...field} setFieldValue={setFieldValue} />
                      </div>
                    );
                  })}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={this.handleModal.hide}
                  className="btn btn-light btn-elevate"
                >
                  Batal
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() =>
                    handleSubmit()
                  }
                  className="btn btn-primary btn-elevate"
                >
                  Simpan
                </button>
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      </>
    );
  }
}

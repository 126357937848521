import React, { useEffect, useState } from "react";
import { simpleFetch } from "../../../api/api";
import _ from "lodash";
import {
    CardBody,
} from "../../../../_metronic/_partials/controls";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import { separatorHarga } from "../../../services/separator-harga";
import { MarginHargaJualAPI } from "../../../api/margin-harga-jual";
import { dispatcher } from "../../../redux/dispatcher";
import store from "../../../redux/store";

function UbahHarga({ data }) {

    const ubahMaster = async (values) => {
        let hargaJual = []
        await new MarginHargaJualAPI().getAll().then((res) => {
            let datas = res.data;
            let sama = datas.filter((val) => val.id_produk_kategori === values.id_produk_kategori)
            let beda = datas.filter((val) => val.toProdukKategori === null)
            let master;
            if (sama.length > 0) {
                master = sama
            }
            if (sama.length === 0) {
                master = beda
            }
            let arr = master.map((data) => {
                return data.margin
            })
            let max = Math.max(...arr)
            let margin = parseInt(max) / 100
            let hargaMargin = Math.ceil(values.dpp * margin)
            let hargaJualBaru = values.dpp + hargaMargin
            hargaJual.push(hargaJualBaru)
        })
        try {
            await simpleFetch({
                url: "retail/newMaster",
                method: "POST",
                body: {
                    id_user: store.getState().auth.id,
                    id: values.id_produk,
                    id_perusahaan: values.id_perusahaan,
                    harga_beli_baru: values.dpp,
                    harga_jual_baru: hargaJual,
                },
            }).then((res) =>
                dispatcher.snackbar.show(
                    "Berhasil",
                    `${res.data}`,
                    "primary"
                )
            )
        } catch (error) {
            dispatcher.snackbar.show(
                "Error",
                `${error}`,
                "danger"
            )
        }
    }

    return (
        <>
            <AnimatedCard>
                <CardBody>
                    <div className="table-responsive-md">
                        <table className="table table-hover">
                            <thead className="table-primary">
                                <tr className="text-center">
                                    <th scope="col">No</th>
                                    <th scope="col">Nama Produk</th>
                                    <th scope="col">Harga Master</th>
                                    <th scope="col">Harga Beli</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.length > 0 ? data.map((data, i) => (
                                    <tr key={i} className="text-center">
                                        <td>{i + 1}</td>
                                        <td>{data.nama}</td>
                                        <td>Rp.{separatorHarga(data.harga_beli.toString())}</td>
                                        <td>Rp. {separatorHarga(data.dpp.toString())}</td>
                                        <td>
                                            {data.harga_beli !== data.dpp ? (
                                                <span>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success"
                                                        onClick={() => {
                                                            ubahMaster(data)
                                                        }}
                                                    >
                                                        <i className="fas fa-edit"></i></button>
                                                </span>
                                            ) : ""}
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <th scope="row"></th>
                                        <td colSpan="4" className="text-center">Belum Ada Data Pembelian</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </AnimatedCard>
        </>
    );
}

export default UbahHarga;
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import {
    CardBody,
} from "../../../../../_metronic/_partials/controls";
import { ReactDatatable } from "../../../../components/react-datatable/reactDatatable";
import { Button } from "react-bootstrap";
import { useState } from "react";
import store from "../../../../redux/store";
import { LaporanGrafik } from "../../../../api/laporan-grafik";
import { useEffect } from "react";
import Select2 from "react-select";
import { StokAPI } from "../../../../api/stok";
import { excelSaldoPersediaan } from "./exportExcelStok";

function SaldoPersediaan() {
    const rupiah = (number) => {
        return new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        }).format(number);
    }
    const columns = [
        {
            name: "Nama Barang",
            selector: (row) => row.nama,
            sortable: true,
            minWidth: "25%",
        },
        {
            name: "Kode Barang",
            selector: (row) => row.kode,
            sortable: true,
            minWidth: "20%",
        },
        {
            name: "Satuan",
            selector: (row) => row.satuan,
            sortable: true,
            minWidth: "10%",
        },
        {
            name: <div style={{ textAlign: "center" }}>Stok Ready</div>,
            selector: (row) => row.tersisa,
            sortable: true,
            minWidth: "10%",
            // width: "fit-content",
        },
        {
            name: <div style={{ textAlign: "center" }}>Saldo Persediaan</div>,
            selector: (row) => rupiah(row.saldo_persediaan),
            sortable: true,
            minWidth: "10%",
            // width: "fit-content",
        },
    ];

    const [data, setData] = useState([]);
    const [cabangTitle, setTitle] = useState(`${store.getState().auth.toPerusahaan.singkat} - ${store.getState().auth.toPerusahaan.lokasi}`);
    const title = `Laporan Saldo Persediaan ${cabangTitle}`;
    const [cabang, setCabang] = useState(store.getState().auth.id_perusahaan);
    const [opsi, setOptions] = useState([]);
    let opsiCabang = [];

    const coverage = async () => {
        await new LaporanGrafik().getCoverage().then((res) => {
            opsiCabang = res.data.map((data) => ({
                label: data.text,
                text: data.text,
                value: data.id,
            }));
        });
        setOptions(opsiCabang);
    };


    const fetchData = async (data) => {
        try {
            await new StokAPI().laporanSaldoPersediaan(data).then((res) => {
                // console.log(
                //     "Valll",
                //     res.data.map((val) => val)
                // );
                let datas = [];
                res.data.map((v) => {
                    let newData = {
                        id: v.id_produk,
                        nama: v.nama,
                        kode: v.kode,
                        satuan: v.satuan,
                        tersisa: v.qty,
                        saldo_persediaan: v.saldo_persediaan
                    };
                    return (datas = [...datas, newData]);
                });
                setData(datas);
                // dataSeries.push(val)
            });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData({ cabang });
        coverage();
        // }
    }, []);
    return (
        <>
            <AnimatedCard>
                <CardBody>
                    <div className="d-flex justify-content-start form-group row">
                        <div
                            className="col-lg-3"
                            style={{ marginTop: "20px", zIndex: "4" }}
                        >
                            <label> Pilih Perusahaan </label>
                            <Select2
                                name={"Cabang"}
                                placeholder={opsi.text}
                                size="sm"
                                options={opsi}
                                isClearable={true}
                                value={
                                    opsi ? opsi.find((option) => option.value === cabang) : ""
                                }
                                onChange={(e) => {
                                    setTitle(e.text)
                                    setCabang(e ? [e.value] : [0]);
                                }}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-start">
                        <div style={{ marginRight: "2%" }}>
                            <Button
                                variant="info"
                                onClick={(e) => {
                                    e.preventDefault();
                                    fetchData({ cabang });
                                }}
                            >
                                <i className="fas fa-search"></i>
                                Cari Data
                            </Button>
                        </div>
                        <div>
                            <Button onClick={(e) => excelSaldoPersediaan(data, title)}>
                                <i className={"far fa-file-excel"}></i> {" Export .xls"}
                            </Button>
                        </div>
                    </div>
                    <ReactDatatable
                        columns={columns}
                        data={data}
                        pagination
                        highlightOnHover
                        searchParameter={["nama", "kode"]}
                    // actions={actionsMemo}
                    />
                </CardBody>
            </AnimatedCard>
        </>
    );
}

export default SaldoPersediaan
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import Model from "../../../models/retur-pembelian-model/ReturPembelianModel";
import { useHistory } from "react-router-dom";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import moment from "moment";
import { PenerimaanAPI } from "../../../api/penerimaan";
import Select2 from "react-select";
import store from "../../../redux/store";
import { Button } from "react-bootstrap";

export default function List(params) {
  const history = useHistory();
  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const pageName = "Daftar Retur Pembelian";
  const [startDate, setStartDate] = useState(
    moment(new Date())
      .startOf("month")
      .format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [search, setSearch] = useState("");
  const [option, setOptions] = useState([]);
  const [cabang, setCabang] = useState(store.getState().auth.id_perusahaan);
  const coverage = async () => {
    let options = [];
    await new PenerimaanAPI().getCoverage().then((res) => {
      options = res.data.map((data) => ({
        label: data.text,
        text: data.text,
        value: data.id,
      }));
    });
    setOptions(options);
  };

  async function loadData(val) {
    // let dataMaster = datas.data;
    let datas = await new PenerimaanAPI().showRetur(val)
    console.log('DATTAS',datas.data);
    let simpleTableModel = new SimpleTableModel();
    let searchParams = ["no_transaksi", "kode", "no_retur"];
    let filtered = datas.data.filter((filter) => {
      let dateFormat = moment(filter.created_at).format("YYYY-MM-DD");
      let dateFilter = dateFormat >= startDate && dateFormat <= endDate;
      return searchParams.some((item) => {
        // console.log(filter[item].toString().toLowerCase())
        return (
          dateFilter 
          &&
          filter[item]
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())
        );
      });
    });

    const actions = (dataModel) => {
      return (
        <>
          <Button
            size="sm"
            className="mx-1"
            variant="outline-primary"
            onClick={async () => {
              let datas = await new PenerimaanAPI().getAllRetur()
              let filter = datas.data.filter((val) => val.master.id === dataModel.id_penerimaan_pembelian)
              // // params.setMaster(masters);
              params.setDataFromModel(dataModel)
              params.setDataDetail(filter)
              history.push("/retur-pembelian/detail");
            }}
          >
            Detail
          </Button>
        </>
      );
    };

    // let master = data !== undefined ? data : data
    filtered.map((item, i) => {
      let dataModel = new Model(item);
        simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: actions(dataModel),
        });
    })

    setColumntable(await simpleTableModel.getColumnHeaders());
    setDatatable(await simpleTableModel.getDatatables());
  }

  useEffect(() => {
    loadData(cabang)
    coverage();
  },[startDate,endDate,cabang,search]);

  return (
    <>
      <AnimatedCard>
        <CardHeader title={pageName}></CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Mulai </label>
              <input
                name={"tanggal_awal"}
                type={"date"}
                value={startDate}
                className={"form-control"}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  loadData();
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Akhir </label>
              <input
                name={"tanggal_akhir"}
                type={"date"}
                value={endDate}
                className={"form-control"}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Pilih Perusahaan </label>
              <Select2
                name={"Cabang"}
                placeholder={option.text}
                size="sm"
                options={option}
                isClearable={true}
                value={
                  option
                    ? option.find(
                      (option) => option.value === cabang
                    )
                    : ""
                }
                onChange={(e) => {
                  setCabang(e ? e.value : [] );
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <input
                type={"text"}
                className={"form-control"}
                placeholder="Cari Data ..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </div>
          </div>
          {
            <SimpleTable data={datatable} columns={columntable} />
          }
        </CardBody>
      </AnimatedCard>
    </>
  );
}

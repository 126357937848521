/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import { useMediaQuery } from "@material-ui/core";
import InputResetPassword from "./InputResetPassword";

export function AuthPage() {
  const matches = useMediaQuery('(min-width: 800px)')
  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {matches ? 
         (<div
            id="sideLogin"
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-9.jpg")})`,
            }}
            >
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <img
                  alt="Logo"
                  className="max-h-70px bg-white p-3 rounded"
                  src={toAbsoluteUrl("/assets/logo-ver.png")}
                />
              </Link>

              <div className="flex-column-fluid d-flex flex-column justify-content-center">
                <h3 className="font-size-h1 mb-5 text-white">
                  Kumala Retail Admin Page
                </h3>
                <p className="font-weight-lighter text-white opacity-80">
                  Sistem Informasi Pengelolaan Retail Kumala Group
                </p>
              </div>

              <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                <div>
                  <div className="opacity-70 font-weight-normal	text-white">
                    Copyright &copy; 2022 IT Kumala Group. All Rights Reserved.
                  </div>
                  <div className="opacity-80 font-weight-bold	text-white">
                    Retail Kumala Connect v1.0.0
                  </div>
                </div>
                <div className="d-flex">
                  {/* <Link to="/terms" className="text-white">
                    Privacy
                  </Link>
                  <Link to="/terms" className="text-white ml-10">
                    Legal
                  </Link>
                  <Link to="/terms" className="text-white ml-10">
                    Contact
                  </Link> */}
                </div>
              </div>
            </div>
          </div>) : "" }

          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="font-weight-bold text-dark-50">
                Don't have an account yet?
              </span>
              <Link
                to="/auth/registration"
                className="font-weight-bold ml-2"
                id="kt_login_signup"
              >
                Sign Up!
              </Link>
            </div> */}

            <div id="rightPage" className="d-flex flex-column-fluid flex-center mt-0 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/auth/reset-password"
                  component={InputResetPassword}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>

            <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div>
                <div className="text-dark-50 font-weight-bold order-2 order-sm-1">
                  Copyright &copy; 2022 IT Kumala Group. All Rights Reserved.
                </div>
                <div className="text-dark-70 font-weight-normal order-2 order-sm-1">
                  Retail Kumala Connect v1.0.0
                </div>
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                {/* <Link to="/terms" className="text-dark-75 text-hover-primary">
                  Privacy
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Legal
                </Link>
                <Link
                  to="/terms"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Contact
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

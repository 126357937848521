import { getIdPerusahaan, simpleFetch, getCoverageArea } from "./api";
import store from "../redux/store";

export class PenjualanAPI {
  async getAll() {
    // let coverage = store.getState().auth.coverage;
    let params = "id_perusahaan=";
    // if (coverage.length > 0) {
    //   params += coverage.join("&id_perusahaan=");
    // } else {
    params += getIdPerusahaan();
    // }
    let res = await simpleFetch({
      url: "retail/penjualan?" + params,
      method: "GET",
    });
    return res;
  }

  async getAllLaporan(values) {
    let params = "id_perusahaan=";
    if (values.length !== 1) {
      params += values.join("&id_perusahaan=");
    } else {
      params += values;
    }
    let res = await simpleFetch({
      url: "retail/penjualan?" + params,
      method: "GET",
    });
    return res;
  }

  async getForInvoice(val) {
    let params = "id_perusahaan=";
    params += val;
    let res = await simpleFetch({
      url: "retail/penjualan/list?" + params,
      method: "GET",
    });
    return res;
  }

  async getAllRetur() {
    // let coverage = store.getState().auth.coverage;
    let params = "id_perusahaan=";
    // if (coverage.length > 0) {
    //   params += coverage.join("&id_perusahaan=");
    // } else {
    params += getIdPerusahaan();
    // }
    let res = await simpleFetch({
      url: "retail/penjualan-retur?" + params,
      method: "GET",
    });
    return res;
  }

  async getAllPiutang(data) {
    let params = "id_perusahaan=";
    params += data.cabang;
    let res = await simpleFetch({
      url: `retail/kartu-piutang?status_bayar=${data.status}&` + params,
      method: "GET",
    });
    return res.data;
  }

  async showRetur() {
    // let coverage = store.getState().auth.coverage;
    let params = "id_perusahaan=";
    // if (coverage.length > 0) {
    //   params += coverage.join("&id_perusahaan=");
    // } else {
    params += getIdPerusahaan();
    // }
    let res = await simpleFetch({
      url: "retail/penjualan/retur?" + params,
      method: "GET",
    });
    return res;
  }

  async getSingle(id) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/penjualan?id=" + id,
        method: "GET",
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async set(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/penjualan",
        method: "POST",
        body: {
          id_perusahaan: getIdPerusahaan(),
          ...values,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async retur(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/penjualan/retur",
        method: "POST",
        body: {
          id_user: store.getState().auth.id,
          ...values,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      console.log('cek res', values)
      return res;
    }
  }

  async edit(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/penjualan",
        method: "PUT",
        body: {
          id_perusahaan: getIdPerusahaan(),
          ...values,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async detail(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/penjualan/detail",
        method: "PUT",
        body: {
          ...values,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async delete(id) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/penjualan",
        method: "DELETE",
        body: {
          id: id,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async invoice(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/penjualan/invoice",
        method: "POST",
        body: {
          ...values,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async approveRetur(values) {
    let res = await simpleFetch({
      url: "retail/penjualan/approve-retur",
      method: "POST",
      body: {
        master: values.master,
        id: values.id,
        id_pesanan_penjualan: values.id_pesanan_penjualan,
        id_detail: values.id_detail
      }
    })
    return res;
  }

  async getCoverage() {
    let res = await simpleFetch({
      url: "retail/select/perusahaan?id_coverage=" + getCoverageArea(),
      method: "GET",
    });
    return res;
  }
}

import { CardBody, CardFooter, CardHeader } from '../../../../_metronic/_partials/controls';
import React, { Component } from 'react';
import { AnimatedCard } from '../../../components/animated-card/animatedCard';
import DataTableModel from '../../../models/datatable-model/DataTableModel';
import { Formik } from 'formik';
import { FormsFields } from '../../../components/forms-fields/FormsFields';
import { Paper, Typography, makeStyles } from '@material-ui/core';
import { ReactDatatableFooter, ReactDatatableFooterDetails } from '../../../components/react-datatable-footer/reactDatatableFooter';
import { akuntansiAPI } from '../../../api/akuntansi';
import bukuBesarModel from '../../../models/akuntansi/buku-besar/BukuBesarModel';
import { separatorHarga } from '../../../services/separator-harga';
import { export_excel } from '../../../components/export-excel/export_excel';
import { Button } from 'react-bootstrap';
import { dispatcher } from '../../../redux/dispatcher';
import * as Yup from "yup";
import bukuBesarDetailModel from '../../../models/akuntansi/buku-besar/BukuBesarDetailModel ';
import { ModalFormsDetailTable } from '../../../components/modal-forms/modalForms';
import ButtonLoad from '../../../components/button-loading/buttonLoad';
import store from '../../../redux/store';
import { getIdPerusahaan } from '../../../api/api';
import ButtonActions from '../../../components/button-actions/buttonActions';
import moment from 'moment';

function simulateNetworkRequest ()
{
   return new Promise( resolve => setTimeout( resolve, 1000 ) );
}
export default class BukuBesar extends Component
{
   constructor( props )
   {
      super( props );
      this[ 'pageName' ] = "Buku Besar";
      this[ 'tableTitleName' ] = "Nama Akun : ";
      this[ 'searchParameter' ] = [ "tanggal", "cabang", "bank", "deskripsi", "no_transaksi", "debet", "kredit", "saldo" ];
      this[ 'apiBukuBesar' ] = new akuntansiAPI();
      this[ 'initialDetailData' ] = new bukuBesarDetailModel();
      this[ 'export_excel' ] = new export_excel();
      this[ 'options_perusahaan' ] = [];
      this[ 'options_akun' ] = [];
      this[ 'state' ] = {
         isLoading: true,
         pending: false,
         total_debet: 0,
         total_kredit: 0,
         total_selisih: 0,
         toggledClearRows: false,
         rowSelectCritera: false,
         handleChange: true,
         showHideButtonClear: false,
         data_select_rows: [],
         initialValues: {
            start_date: new Date( "01-01-2020" ),
            end_date: new Date(),
            id_region: "",
            id_perusahaan: "",
            kode_akun: "",
            id_akun: 0,
            nama_akun: "",
            id_bank: "",
            ceklist_nilai: false,
            ceklist_clearing: false,
         },
         data: [],
         saldo_awal: 0,
         saldo_akhir: 0,
         footerBukuBesar: {
            no_transaksi: "Total",
            debet: 0,
            kredit: 0,
         },
         footerBukuBesarDetail: {
            kode_akun: "Total",
            debet: 0,
            kredit: 0,
         },
         modal: {
            show: false,
            title: "",
         },
      }
      this[ 'columntableBukuBesar' ] = [ { id: "", title: "" } ];
      this[ 'datatableBukuBesar' ] = [ { id: "", no: "", name: "" } ];
      this[ 'columntableBukuBesarDetail' ] = [ { id: "", title: "" } ];
      this[ 'datatableBukuBesarDetail' ] = [ { id: "", no: "", name: "" } ];
   }
   //* ----------------------------------------------------------------------------- */
   //** Begin: Component */
   async componentDidMount ()
   {
      await this.LoadDataCabang( this[ 'state' ][ 'initialValues' ][ 'id_region' ] );
      // await this.LoadDataBank(this['state']['initialValues']);
      // Akun
      this[ 'apiBukuBesar' ].getakun().then( ( values ) =>
      {
         this[ 'options_akun' ] = values[ 'data' ].map( ( data ) => ( {
            label: data[ 'text' ],
            value: data[ 'kode_akun' ],
            nama_akun: data[ 'nama_akun' ],
            id_akun: data[ 'id' ],
         } ) );
      } );
      await this.LoadData( this[ 'state' ][ 'initialValues' ] );
   }
   //** End: Component */
   //* ----------------------------------------------------------------------------- */
   //** Begin: Load Option Data Select) */
   // Cabang
   async LoadDataCabang ( id_region )
   {
      this.setState( { isLoading: true }, () =>
      {
         simulateNetworkRequest().then( () =>
         {
            this[ 'apiBukuBesar' ].getPerusahaan( id_region ).then( ( values ) =>
            {
               this[ 'options_perusahaan' ] = values[ 'data' ].map( ( data ) => ( {
                  label: data[ 'text' ],
                  value: data[ 'id' ],
               } ) );
            } );
            this.setState( { isLoading: false } );
         } );
      } );

   }
   // Bank
   async LoadDataBank ( form )
   {
      this.setState( { isLoading: true }, () =>
      {
         simulateNetworkRequest().then( () =>
         {
            this[ 'apiBukuBesar' ].getBank( form ).then( ( values ) =>
            {
               this[ 'options_bank' ] = values[ 'data' ].map( ( data ) => ( {
                  label: data[ 'text' ] + " - " + data[ 'no_rekening' ],
                  value: data[ 'id' ],
               } ) );
            } );
            this.setState( { isLoading: false } );
         } );
      } );

   }
   //** End: Load Option Data Select) */
   //* ----------------------------------------------------------------------------- */
   //** Begin: Load Data Buku Besar */
   async LoadData ( form )
   {
      this.setState( { pending: true } );
      let data = await this[ 'apiBukuBesar' ].getAllBukuBesar( form );
      this.setState( { data: data[ 'data' ][ 'data' ] } );
      this.setState( { saldo_awal: data[ 'data' ][ 'saldo_awal' ] ?? 0 } );
      this.setState( { saldo_akhir: data[ 'data' ][ 'saldo_akhir' ] ?? 0 } );
      this[ 'DataTableModel' ] = new DataTableModel();
      data[ 'data' ][ 'data' ].map( ( item, i ) =>
      {
         let dataModel = new bukuBesarModel( item );
         if ( item[ 'buku_besar' ][ 'status' ] === 1 )
         {
            this[ 'DataTableModel' ].add( {
               id: item[ 'buku_besar' ][ 'id' ],
               no: i + 1,
               dataModel: dataModel,
               actions: (
                  <>
                     <ButtonActions
                        label={ "details" }
                        onClick={ async () =>
                           await this[ 'handleModal' ].show( dataModel ) }
                     />
                  </>
               ),
            } );
         }
         return item;
      } );
      const totalList = ( val ) =>
      {
         if ( val === 'debet' )
         {
            return data[ 'data' ][ 'data' ].reduce( ( total, value ) =>
            {
               const debet = parseInt( value[ 'buku_besar' ][ 'debet' ] );
               return parseFloat( total + debet );
            }, 0 );
         } else
         {
            return data[ 'data' ][ 'data' ].reduce( ( total, value ) =>
            {
               const kredit = parseInt( value[ 'buku_besar' ][ 'kredit' ] );
               return parseFloat( total + kredit );
            }, 0 );
         }
      };
      this[ 'columntableBukuBesar' ] = await this[ 'DataTableModel' ].getColumn();
      this[ 'datatableBukuBesar' ] = await this[ 'DataTableModel' ].getDatas();
      this.setState( {
         ...this.state, pending: false, footerBukuBesar: {
            no_transaksi: "Total",
            debet: totalList( "debet" ) < 0 ? `Rp. -${ separatorHarga( totalList( "debet" ).toString() ) }` : separatorHarga( totalList( "debet" ).toString(), "Rp. " ),
            kredit: totalList( "kredit" ) < 0 ? `Rp. -${ separatorHarga( totalList( "kredit" ).toString() ) }` : separatorHarga( totalList( "kredit" ).toString(), "Rp. " ),
         }
      } );
   }
   //** End: Load Data List */
   //* ----------------------------------------------------------------------------- */
   //** Begin: Handle Alert */
   handleModal = {
      LoadDataDetail: async ( detail ) =>
      {
         this[ 'DataTableModel' ] = new DataTableModel( { readOnly: true } );
         detail[ 'detail' ].map( ( item, i ) =>
         {
            this[ 'DataTableModel' ].add( {
               id: item[ 'id' ],
               no: i + 1,
               dataModel: item,
               actions: null,
            } );
            return item;
         } );
         const totalList = ( val ) =>
         {
            if ( val === 'debet' )
            {
               return detail[ 'detail' ].reduce( ( total, value ) =>
               {
                  const debet = parseInt( value[ 'debet' ] );
                  return parseFloat( total + debet );
               }, 0 );
            } else
            {
               return detail[ 'detail' ].reduce( ( total, value ) =>
               {
                  const kredit = parseInt( value[ 'kredit' ] );
                  return parseFloat( total + kredit );
               }, 0 );
            }
         };
         this[ 'columntableBukuBesarDetail' ] = await this[ 'DataTableModel' ].getColumn();
         this[ 'datatableBukuBesarDetail' ] = await this[ 'DataTableModel' ].getDatas();
         this.setState( {
            ...this.state,
            footerBukuBesarDetail: {
               kode_akun: "Total",
               debet: totalList( "debet" ) < 0 ? `Rp. -${ separatorHarga( totalList( "debet" ).toString() ) }` : separatorHarga( totalList( "debet" ).toString(), "Rp. " ),
               kredit: totalList( "kredit" ) < 0 ? `Rp. -${ separatorHarga( totalList( "kredit" ).toString() ) }` : separatorHarga( totalList( "kredit" ).toString(), "Rp. " ),
            }
         } );
      },
      show: async ( detail ) =>
      {
         await this[ 'handleModal' ].LoadDataDetail( detail );
         this.setState( {
            modal: {
               show: true,
               title: "Detail Buku Besar"
            }
         } );
      },
      hide: () =>
      {
         this.setState( {
            modal: {
               show: false,
               title: ""
            }
         } );
      },
   };
   //** End: Handle Alert */
   render ()
   {
      const { pending, initialValues, data, saldo_awal, saldo_akhir, isLoading, modal, total_debet, total_kredit, total_selisih, toggledClearRows, showHideButtonClear, data_select_rows } = this[ 'state' ];
      // Style
      const useStyles = makeStyles( theme => ( {
         root: {
            textTransform: "uppercase",
            textAlign: "right",
            padding: theme.spacing( 1, 2 ),
            backgroundColor: "#f3f6f9",
         },
      } ) );
      // ContexAction Select Clearing
      const handleClearingSelectRows = ( () =>
      {
         let debet = 0;
         let kredit = 0;
         let id = [];
         let no_transaksi_ref = [];
         data_select_rows.forEach( val =>
         {
            id.push( val[ 'id' ] );
            no_transaksi_ref.push( val[ 'detailRef' ] );
            debet = val[ 'detailRef' ].reduce( ( total, value ) =>
            {
               return total + value[ 'debet' ];
            }, 0 );
            kredit = val[ 'detailRef' ].reduce( ( total, value ) =>
            {
               return total + value[ 'kredit' ];
            }, 0 );
         } );
         let dataSend = {};
         if ( debet !== kredit && no_transaksi_ref.length > 0 )
         {
            dispatcher.snackbar.show( "Warning", "Total Debet & Kredit tidak balance!", "warning" );
            return true;
         } else
         {
            dataSend = {
               id: id,
               id_user: store.getState().auth[ 'id' ],
               id_perusahaan: getIdPerusahaan(),
            }
         }
         this[ 'apiBukuBesar' ].setBukuBesarClearing( dataSend ).then( ( res ) =>
         {
            if ( res[ 'status' ] )
            {
               this.setState( {
                  toggledClearRows: !toggledClearRows,
                  showHideButtonClear: false,
               } );
               dispatcher[ 'snackbar' ].show( "Berhasil", res.message, "primary" );
               this.LoadData( initialValues );
            } else
            {
               dispatcher[ 'snackbar' ].show( "Gagal", res.message, "danger" );
               this.setState( {
                  toggledClearRows: !toggledClearRows,
                  showHideButtonClear: false,
               } );
               this.LoadData( initialValues );
            }
         } );
      } );
      // Select All Data In Table
      const handleChange = ( { selectedRows } ) =>
      {
         let noRef = [];
         selectedRows.forEach( element =>
         {
            noRef.push( element[ 'no_transaksi_ref' ] );
         } );
         let debet = selectedRows.reduce( ( total, value ) =>
         {
            const debet = parseInt( value[ 'debet' ].slice( 3 ).replace( /[.]/g, "" ) );
            return parseFloat( total + debet );
         }, 0 );
         let kredit = selectedRows.reduce( ( total, value ) =>
         {
            const kredit = parseInt( value[ 'kredit' ].slice( 3 ).replace( /[.]/g, "" ) );
            return parseFloat( total + kredit );
         }, 0 );
         let total_selisih = debet - kredit;
         this.setState( {
            total_debet: debet,
            total_kredit: kredit,
            total_selisih: total_selisih,
            showHideButtonClear: total_selisih === 0 && selectedRows.length > 0 && noRef.length > 0,
            rowSelectCritera: false,
            data_select_rows: selectedRows
         } );
         return;
      };
      const rowSelectCritera = ( row ) =>
      {
         let total_debet = row[ 'detailRef' ].reduce( ( total, value ) =>
         {
            return parseFloat( total + value[ 'debet' ] );
         }, 0 );
         let total_kredit = row[ 'detailRef' ].reduce( ( total, value ) =>
         {
            return parseFloat( total + value[ 'kredit' ] );
         }, 0 );
         this.setState( {
            rowSelectCritera: false,
         } );
         return total_debet === total_kredit && row[ 'id_clearing' ] === "" && row[ 'detailRef' ].length > 0 && row[ 'no_transaksi_ref' ] !== null;
      };
      const rowSelectDisabledCritera = ( row ) =>
      {
         return row[ 'id_clearing' ] !== "";
      };
      // Filter Auto Ceklist/All Ceklist
      const handleAutoCeklist = () =>
      {
         this.setState( {
            rowSelectCritera: true,
         } );
      }
      const handleClearRows = () =>
      {
         this.setState( {
            toggledClearRows: !toggledClearRows,
            showHideButtonClear: false,
         } );
      }
      return (
         <>
            {/* Begin: Formik Buku Besar List */ }
            <Formik
               enableReinitialize={ true }
               initialValues={ initialValues }
               validationSchema={ Yup.object().shape( {
                  start_date: Yup.string().nullable().required(),
                  kode_akun: Yup.string().required( "Akun harus diisi" ),
                  id_region: Yup.string().required( "Region harus diisi" ),
               } ) }
               onSubmit={ ( values ) =>
               {
                  this.LoadData( values );
                  this.setState( {
                     toggledClearRows: !toggledClearRows
                  } );
               } }
            >
               { ( { setFieldValue, setFieldTouched, touched, errors, values, handleSubmit } ) => (
                  <AnimatedCard>
                     <CardHeader title={ this.pageName }></CardHeader>
                     <CardBody style={ { marginTop: "-20px" } }>
                        <div className={ "form-group row" }>
                           {/* Forms Start Date  */ }
                           <FormsFields
                              id={ "start_date" }
                              type={ "DatePicker" }
                              label={ "Tanggal Mulai" }
                              className={ "col-lg-4" }
                              style={ { marginTop: "20px" } }
                              paramsFormik={ {
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              } }
                           />
                           {/* Forms End Date  */ }
                           <FormsFields
                              id={ "end_date" }
                              type={ "DatePicker" }
                              label={ "Tanggal Akhir" }
                              className={ "col-lg-4" }
                              style={ { marginTop: "20px" } }
                              paramsFormik={ {
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              } }
                           />
                           {/* Forms Select Region */ }
                           <FormsFields
                              id={ "id_region" }
                              type={ "select2" }
                              label={ "Region" }
                              style={ { marginTop: "20px" } }
                              className={ "col-lg-4" }
                              isClearable={ true }
                              isSearchable={ true }
                              options={ [ {
                                 label: "PT. KUMALA SUKSES ABADI",
                                 value: 8,
                              }, {
                                 label: "PT. KUMALA PUTRA PRIMA",
                                 value: 16,
                              },{
                                 label: "PT. KUMALA SUKSES BERSAMA",
                                 value: 30,
                              } ] }
                              onChange={ ( e ) =>
                              {
                                 this.LoadDataCabang( e ? e[ 'value' ] : "" );
                                 if ( e )
                                 {
                                    if ( values[ 'kode_akun' ] === "110201" || values[ 'kode_akun' ] === "110203" )
                                    {
                                       this.LoadDataBank( { id_region: e ? e[ 'value' ] : "", id_perusahaan: "", kode_akun: values[ 'kode_akun' ] } );
                                    }
                                 } else
                                 {
                                    if ( values[ 'kode_akun' ] === "110201" || values[ 'kode_akun' ] === "110203" )
                                    {
                                       this.LoadDataBank( { id_region: e ? e[ 'value' ] : "", id_perusahaan: "", kode_akun: values[ 'kode_akun' ] } );
                                    } else
                                    {
                                       setFieldValue( "id_bank", "" );
                                    }
                                 }
                                 setFieldValue( 'id_perusahaan', "" );
                                 // setFieldValue('kode_akun', "");
                              } }
                              paramsFormik={ {
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              } }
                           />
                           {/* Forms Select Cabang */ }
                           <FormsFields
                              id={ "id_perusahaan" }
                              type={ "select2" }
                              label={ "Cabang" }
                              style={ { marginTop: "20px" } }
                              className={ "col-lg-4" }
                              isClearable={ true }
                              isSearchable={ true }
                              isLoading={ isLoading }
                              options={ this[ 'options_perusahaan' ] }
                              onChange={ ( e ) =>
                              {
                                 if ( e )
                                 {
                                    if ( values[ 'kode_akun' ] === "110201" || values[ 'kode_akun' ] === "110203" )
                                    {
                                       this.LoadDataBank( { id_region: values[ 'id_region' ], id_perusahaan: e ? e[ 'value' ] : "", kode_akun: values[ 'kode_akun' ] } );
                                    }
                                 } else
                                 {
                                    if ( values[ 'kode_akun' ] === "110201" || values[ 'kode_akun' ] === "110203" )
                                    {
                                       this.LoadDataBank( { id_region: values[ 'id_region' ], id_perusahaan: e ? e[ 'value' ] : "", kode_akun: values[ 'kode_akun' ] } );
                                    } else
                                    {
                                       setFieldValue( 'kode_akun', "" );
                                    }
                                 }
                              } }
                              paramsFormik={ {
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              } }
                           />
                           {/* Forms Select Akun */ }
                           <FormsFields
                              id={ "kode_akun" }
                              type={ "select2" }
                              label={ "Akun" }
                              style={ { marginTop: "20px" } }
                              className={ "col-lg-4" }
                              isClearable={ true }
                              isSearchable={ true }
                              options={ this[ 'options_akun' ] }
                              onChange={ ( e ) =>
                              {
                                 if ( e )
                                 {
                                    if ( e[ 'value' ] === "110201" || e[ 'value' ] === "110203" )
                                    {
                                       this.LoadDataBank( { id_region: values[ 'id_region' ], id_perusahaan: values[ 'id_perusahaan' ], kode_akun: e ? e[ 'value' ] : "" } );
                                    }
                                 } else
                                 {
                                    setFieldValue( "id_bank", "" );
                                 }
                                 setFieldValue( "id_akun", ( e ? e[ 'id_akun' ] : "" ) );
                                 setFieldValue( "nama_akun", ( e ? e[ 'nama_akun' ] : "Semua Akun" ) );
                              } }
                              paramsFormik={ {
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              } }
                           />
                           {/* Forms Select Bank */ }
                           { values[ 'kode_akun' ] === "110201" || values[ 'kode_akun' ] === "110203" ?
                              ( <>
                                 <FormsFields
                                    id={ "id_bank" }
                                    type={ "select2" }
                                    label={ "Bank" }
                                    style={ { marginTop: "20px" } }
                                    className={ "col-lg-4" }
                                    isClearable={ true }
                                    isSearchable={ true }
                                    isLoading={ isLoading }
                                    options={ this[ 'options_bank' ] }
                                    paramsFormik={ {
                                       setFieldValue,
                                       setFieldTouched,
                                       touched,
                                       errors,
                                       values
                                    } }
                                 />
                              </> ) : null
                           }
                        </div>
                        <div className={ "form-group row" }>
                           {/* Tampilkan Akun dengan nilai 0 */ }
                           <FormsFields
                              id={ "ceklist_nilai" }
                              type={ "checkbox" }
                              label={ "Tampilkan Akun dengan nilai 0" }
                              style={ { marginTop: "-10px" } }
                              className={ "col-lg-4" }
                              paramsFormik={ {
                                 setFieldValue,
                                 values
                              } }
                           />
                           {/* Pisahkan akun clearing */ }
                           <FormsFields
                              id={ "ceklist_clearing" }
                              type={ "checkbox" }
                              label={ "Pisahkan akun clearing" }
                              style={ { marginTop: "-10px" } }
                              className={ "col-lg-4" }
                              paramsFormik={ {
                                 setFieldValue,
                                 values
                              } }
                           />
                        </div>
                        {/* Button */ }
                        <div style={ { display: "flex", justifyContent: "center" } } >
                           <div>
                              {/* Button Lihat Data */ }
                              <ButtonLoad
                                 label={ "Lihat Data" }
                                 pending={ pending }
                                 classNameIcon={ "fa fa-filter" }
                                 className={ "btn btn-outline-success" }
                                 onClick={ async () => handleSubmit() }
                              />
                              {/* Button Export Excel */ }
                              <ButtonLoad
                                 label={ "Export .xls" }
                                 pending={ pending }
                                 classNameIcon={ "far fa-file-excel" }
                                 className={ "btn btn-outline-primary" }
                                 disabled={ data.length === 0 }
                                 onClick={ async () =>
                                 {
                                    let startDate = moment( values[ 'start_date' ] ).format( "L" );
                                    let endDate = moment( values[ 'end_date' ] ).format( "L" );
                                    let times = moment().format( 'h.mm.ss a' );
                                    const dateFilter = ( startDate === endDate ) ? startDate : startDate + " - " + endDate;

                                    let record = [];
                                    data.map( ( val, i ) =>
                                    {
                                       const { tanggal, cabang, bank, deskripsi, no_transaksi, no_transaksi_ref, debet, kredit, saldo } = val[ 'buku_besar' ];
                                       record.push( {
                                          tanggal: tanggal ? moment( tanggal ).format( "DD-MM-YYYY" ) : "",
                                          cabang: cabang,
                                          bank: bank,
                                          deskripsi: deskripsi,
                                          no_transaksi: no_transaksi,
                                          no_transaksi_ref: no_transaksi_ref,
                                          debet: debet,
                                          kredit: kredit,
                                          saldo: saldo,
                                       } );
                                       return val;
                                    } );

                                    let totalDebet = data.reduce( ( total, value ) =>
                                    {
                                       const { debet } = value[ 'buku_besar' ];
                                       return parseFloat( total + debet );
                                    }, 0 );
                                    let totalKredit = data.reduce( ( total, value ) =>
                                    {
                                       const { kredit } = value[ 'buku_besar' ];
                                       return parseFloat( total + kredit );
                                    }, 0 );

                                    let datas = {
                                       headerTitle: "BUKU BESAR",
                                       title1: `Nama Akun : ${ values[ 'nama_akun' ] }`,
                                       title2: "",
                                       headerField: [ "TANGGAL", "CABANG", "BANK", "KETERANGAN", "NO. TRANSAKSI", "NO. TRANSAKSI REF", "DEBET", "KREDIT", "SALDO" ],
                                       headerField2: [],
                                       recordData: record,
                                       recordData2: [],
                                       totalSaldoAwal: saldo_awal,
                                       totalSaldoAkhir: saldo_akhir,
                                       footerSubTotal: [ totalDebet, totalKredit, "" ],
                                       footerSubTotal1: [],
                                       columnWidth: [ 17, 23, 60, 80, 35, 35, 20, 20, 20 ],
                                       centerAlign: [ "A", "B", "E", "F" ],
                                       wrapText: [ "C", "D" ],
                                       numberFormatRp: [ "G", "H", "I" ],
                                       fileName: `Buku Besar (${ dateFilter } - ${ times })`
                                    }
                                    this[ 'export_excel' ].saveAsExcel( datas );
                                 } }
                              />
                           </div>
                        </div>
                     </CardBody>
                     <CardFooter>
                        {/* Total Selisi */ }
                        <div className={ "form-group row" }>
                           {/* Total Debet */ }
                           <FormsFields
                              id={ "total_debet" }
                              type={ "disabled-currency" }
                              label={ "Total Debet" }
                              className={ "col-lg-4" }
                              style={ { marginTop: "20px" } }
                              value={ total_debet }
                           />
                           {/* Total Kredit */ }
                           <FormsFields
                              id={ "total_kredit" }
                              type={ "disabled-currency" }
                              label={ "Total Kredit" }
                              className={ "col-lg-4" }
                              style={ { marginTop: "20px" } }
                              value={ total_kredit }
                           />
                           {/* Total Selisih */ }
                           <FormsFields
                              id={ "total_debet" }
                              type={ "disabled-currency" }
                              label={ "Total Selisih" }
                              className={ "col-lg-4" }
                              style={ { marginTop: "20px" } }
                              value={ total_selisih }
                           />
                        </div>
                        {/* List Transaksi */ }
                        {/* Saldo Awal */ }
                        <div className={ "tab-content mt-5" }>
                           <div className={ "table-responsive" }>
                              <Paper className={ useStyles().root }>
                                 <Typography variant={ "h5" } component={ "h3" }>
                                    Saldo Awal : { ( saldo_awal < 0 ) ? "Rp. -" + separatorHarga( saldo_awal.toString() ) :
                                       separatorHarga( saldo_awal.toString(), "Rp. " ) }
                                 </Typography>
                              </Paper>
                           </div>
                        </div>
                        { data.length > 0 ? (
                           <div style={ { display: "flex", justifyContent: "center", marginTop: "20px" } } >
                              <div className={ "form-group row" }>
                                 { showHideButtonClear ? (
                                    <>
                                       <Button variant={ "outline-info" } className={ "btn-sm mx-1" } onClick={ handleClearRows }>
                                          <i className={ "far fa-check-circle" }></i> { "Clear Ceklist" }
                                       </Button>
                                       <Button variant={ "outline-danger" } className={ "btn-sm mx-1" } onClick={ handleClearingSelectRows } >
                                          <i className={ "fa fa-eraser" }></i> { "Clearing" }
                                       </Button>
                                    </>
                                 ) : (
                                    <>
                                       <Button variant={ "outline-info" } className={ "btn-sm mx-1" } onClick={ handleAutoCeklist }>
                                          <i className={ "far fa-circle" }></i> { "Auto Ceklist" }
                                       </Button>
                                    </>
                                 ) }
                              </div>
                           </div>
                        ) : "" }
                        <ReactDatatableFooter
                           title={ this[ 'tableTitleName' ] + values[ 'nama_akun' ] }
                           columns={ this[ 'columntableBukuBesar' ] }
                           data={ this[ 'datatableBukuBesar' ] }
                           searchParameter={ this[ 'searchParameter' ] }
                           progressPending={ pending }
                           selectableRows={ true }
                           selectableRowSelected={ this[ 'state' ][ 'rowSelectCritera' ] ? rowSelectCritera : null }
                           selectableRowDisabled={ rowSelectDisabledCritera }
                           onSelectedRowsChange={ this[ 'state' ][ 'handleChange' ] ? handleChange : "" }
                           clearSelectedRows={ toggledClearRows }
                           footer={ this.state[ 'footerBukuBesar' ] }
                        />
                        {/* Saldo Akhir */ }
                        <div className={ "tab-content mt-5" }>
                           <div className={ "table-responsive" }>
                              <Paper className={ useStyles().root }>
                                 <Typography variant={ "h5" } component={ "h3" }>
                                    Saldo Akhir : { ( saldo_akhir < 0 ) ? "Rp. -" + separatorHarga( saldo_akhir.toString() ) :
                                       separatorHarga( saldo_akhir.toString(), "Rp. " ) }
                                 </Typography>
                              </Paper>
                           </div>
                        </div>
                     </CardFooter>
                  </AnimatedCard>
               ) }
            </Formik>
            {/* End: Formik Buku Besar List */ }
            {/* ----------------------------------------------------------------------------- */ }
            {/* Begin: Modal Detail Form */ }
            <ModalFormsDetailTable
               show={ modal[ 'show' ] }
               title={ modal[ 'title' ] }
               onHide={ this[ 'handleModal' ][ 'hide' ] }
               content={
                  <>
                     <ReactDatatableFooterDetails
                        title={ "" }
                        data={ this[ 'datatableBukuBesarDetail' ] }
                        columns={ this[ 'columntableBukuBesarDetail' ] }
                        footer={ this.state[ 'footerBukuBesarDetail' ] }
                     />
                  </>
               }
            />
            {/* End: Modal Detail Form */ }
            {/* ----------------------------------------------------------------------------- */ }
         </>
      );
   }
}
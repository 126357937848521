import { separatorHarga } from "../../../services/separator-harga";
export default class neracaSaldoModel {
  constructor(
    data = {
      id: 0,
      kode_akun: "",
      nama_akun: "",
      saldo: 0,
    }
  ) {
    const { id, kode_akun, nama_akun, saldo } = data;
    this['id'] = id;
    this['kode_akun'] = kode_akun;
    this['nama_akun'] = nama_akun;
    this['saldo'] = saldo;
  }
  async getdata() {
    const { kode_akun, nama_akun, saldo } = this;
    return [{
      id: "kode_akun",
      title: "Kode Akun",
      label: kode_akun,
      center: true,
      minWidth: "15%",
    }, {
      id: "nama_akun",
      title: "Nama Akun",
      label: nama_akun,
      minWidth: "30%",
    }, {
      id: "saldo",
      title: "Saldo",
      label: saldo < 0 ? `Rp. -${separatorHarga(saldo.toString())}` : separatorHarga(saldo.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "20%",
    }];
  }
}

import store from "../../redux/store";
import { authAction } from "../../redux/auth/actions";

export default class ResponseModel {
  constructor(
    res = {
      data: [],
      status: false,
      isExpired: false,
      message: "",
    }
  ) {
    this.data = res.data;
    this.status = res.status;
    this.isExpired = res.isExpired;
    this.message = res.message;
  }

  checkToken() {
    // const state = store.getState();
    if (this.isExpired) {
      store.dispatch({ type: authAction.delUser });
    }
  }

  getData() {
    return this.data;
  }
}

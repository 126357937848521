import * as Yup from "yup";
import { ProdukKategoriAPI } from "../../api/produk-kategori";
import store from "../../redux/store";
export default class ProdukKategoriModel {
  constructor(
    data = { 
      id: 0, 
      id_parent: 0, 
      nama: "", 
      id_perusahaan_login: store.getState().auth.id_perusahaan,
      toParent: { nama: "" } 
    }
  ) {
    this.id = data.id;
    this.id_parent = data.id_parent ?? "";
    this.nama = data.nama;
    this.id_perusahaan_login = data.id_perusahaan_login;
    this.parent_nama = data.toParent != null ? data.toParent.nama : "";
  }

  async getEditable(params = { pageName: "", isForm: false }) {
    let options_produk_kategori = [];
    if (params.isForm) {
      await new ProdukKategoriAPI().getAll().then((values) => {
        values.data.map(
          (data, i) =>
            (options_produk_kategori = [
              ...options_produk_kategori,
              { id: data.id, text: data.nama, value: data.id },
            ])
        );
      });
    }

    return [
      {
        validation: Yup.string(),
        dataField: "id_parent",
        label: "Kategori",
        type: "select",
        options: options_produk_kategori,
        onChange: () => {},
        value: this.id_parent == 0 ? this.nama : this.parent_nama,
      },
      {
        validation: Yup.string().required("Nama harus diisi"),
        dataField: "nama",
        label: "Sub Kategori",
        type: "text",
        value: this.id_parent == 0 ? " - " : this.nama,
      },
    ];
  }
}

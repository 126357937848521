import moment from "moment/moment";
import { separatorHarga } from "../../../services/separator-harga";
export default class cariTransaksi {
  constructor(
    data = {
      id: 0,
      tanggal: "",
      no_transaksi: "",
      cabang: "",
      user: "",
      bank: "",
      deskripsi: "",
      nama_akun: "",
      kode_akun: "",
      user_clearing: "",
      debet: 0,
      kredit: 0,
    }
  ) {
    const { id, tanggal, no_transaksi, cabang, user, bank, deskripsi, nama_akun, kode_akun, user_clearing, debet, kredit } = data;
    this['id'] = id;
    this['tanggal'] = tanggal;
    this['no_transaksi'] = no_transaksi;
    this['cabang'] = cabang;
    this['user'] = user;
    this['bank'] = bank;
    this['deskripsi'] = deskripsi;
    this['nama_akun'] = nama_akun;
    this['kode_akun'] = kode_akun;
    this['user_clearing'] = user_clearing;
    this['debet'] = debet;
    this['kredit'] = kredit;
  }
  async getdata() {
    const { tanggal, no_transaksi, cabang, user, bank, deskripsi, nama_akun, kode_akun, user_clearing, debet, kredit } = this;
    return [{
      id: "tanggal",
      title: "Tanggal",
      label: moment(tanggal).format("DD-MM-YYYY"),
      center: true,
      minWidth: "12%",
    }, {
      id: "no_transaksi",
      title: "No. Transaksi",
      label: no_transaksi,
      center: true,
      minWidth: "22%",
    }, {
      id: "cabang",
      title: "Cabang",
      label: cabang,
      center: true,
      minWidth: "20%",
    }, {
      id: "user",
      title: "Kepada",
      label: user,
      minWidth: "20%",
    }, {
      id: "bank",
      title: "Bank",
      label: bank,
      minWidth: "30%",
    }, {
      id: "deskripsi",
      title: "Keterangan",
      label: deskripsi,
      minWidth: "60%",
    }, {
      id: "nama_akun",
      title: "Nama Akun",
      label: nama_akun,
      minWidth: "20%",
    }, {
      id: "kode_akun",
      title: "Kode Akun",
      label: kode_akun,
      center: true,
      minWidth: "10%",
    }, {
      id: "user_clearing",
      title: "User Clearing",
      label: user_clearing,
      center: true,
      minWidth: "20%",
    }, {
      id: "debet",
      title: "Debet",
      label: debet < 0 ? `Rp. -${separatorHarga(debet.toString())}` : separatorHarga(debet.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "kredit",
      title: "Kredit",
      label: kredit < 0 ? `Rp. -${separatorHarga(kredit.toString())}` : separatorHarga(kredit.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }];
  }
}

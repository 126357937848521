import React, { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import List from "./list";
import Detail from "./detail"
import KartuHutangModel from "../../../../models/kartu-hutang/KartuHutangModel";

export default function LaporanKartuHutang() {
    const [dataFromModel, setDataFromModel] = useState(new KartuHutangModel());
    const history = useHistory();

    return (
        <Switch>
            <Route path="/laporan/kartu-hutang/list">
                <List setDataFromModel={setDataFromModel} />
            </Route>
            <Route path="/laporan/kartu-hutang/detail">
                <Detail history={history} data={dataFromModel} />
            </Route>
            <Redirect from="/laporan/kartu-hutang/" to="/laporan/kartu-hutang/list" />
        </Switch>
    )
}
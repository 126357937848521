import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Select2 from "react-select";
import { AnimatedCard } from '../../../components/animated-card/animatedCard';
import { CardBody, CardFooter, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls';
import { OrganisasiAPI } from '../../../api/organisasi';
import { hapusSeparator, separatorHarga } from '../../../services/separator-harga';
import { CustomerLimitAPI } from '../../../api/customer-limit';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { kontakAPI } from '../../../api/kontak';
import DetailCust from '../../penjualan/cust-detail/detailCust';

function Forms() {
    const [dOrganisasi, setOrganisasi] = useState([])
    const [state, setState] = useState({id_perusahaan: 0,id_customer:0})
    const [selected, setSelected] = useState(0)
    const [keterangan, setKeterangan] = useState("")
    const [nilaiLimit, setNilaiLimit] = useState([])
    const [kode, setKode] = useState([])
    const [limitReq, setLimitReq] = useState('')
    const history = useHistory();

    const dataOrganisasi = async () => {
        let option = [];
        await new kontakAPI().getDataCustomerAll().then((res) => {
            let filter = res.data.filter((val) => val.nama_organisasi !== '-')
            option = filter.map((data) => ({
                label: `${data.nama} || ${data.nama_organisasi}`,
                value: data.id,
                nilai_limit: data.limit_organisasi,
                id_perusahaan: parseInt(data.id_perusahaan),
                kode: data.kode,
            }))
        })
        setOrganisasi(option)
    }
    useEffect(() => {
        console.log('It is Running');
        dataOrganisasi()
    }, [])
    return (
        <>
            <Formik
                initialValues={{ selected, nilaiLimit, limitReq, keterangan }}
                validationSchema={Yup.object({
                    // firstName: Yup.string()
                    //     .max(15, 'Must be 15 characters or less')
                    //     .required('Required'),
                    // limitReq: Yup.number()
                    //     .min(limitReq > nilaiLimit, '')
                    //     .required('Required'),
                    // lastName: Yup.string()
                    //     .max(20, 'Must be 20 characters or less')
                    //     .required('Required'),
                    // email: Yup.string().email('Invalid email address').required('Required'),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    let limitRequest = parseInt(hapusSeparator(limitReq))
                    let sendData = {
                        id_kontak: selected,
                        nilai_limit: nilaiLimit,
                        limit_request: limitRequest,
                        keterangan: keterangan,
                    }
                    await new CustomerLimitAPI().pengajuanLimit(sendData)
                    history.goBack();
                    // setTimeout(() => {
                    //     alert(JSON.stringify(values, null, 2));
                    //     setSubmitting(false);
                    // }, 400);
                }}
            >
                {formik => (
                    <AnimatedCard>
                        <CardHeader title="Tambah Pengajuan Limit">
                            <CardHeaderToolbar>
                                <Button
                                    type="button"
                                    className="btn btn-secondary mx-1"
                                    onClick={() => history.goBack()}
                                >
                                    <i className="fa fa-arrow-left"></i>
                                    Kembali
                                </Button>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <div className="row">
                                    <div className="form-group col-lg-6">
                                        <label>Nama Organisasi</label>
                                        <Select2
                                            name={"Cabang"}
                                            size="sm"
                                            isClearable={false}
                                            options={dOrganisasi}
                                            value={
                                                dOrganisasi ?
                                                    dOrganisasi.find((data) => data.value === selected) : ""
                                            }
                                            onChange={(e) => {
                                                setSelected(e ? e.value : 0);
                                                setState({id_perusahaan: e.id_perusahaan,id_customer:e.value})
                                                setNilaiLimit(e ? e.nilai_limit : [])
                                                setKode(e ? e.kode : [])
                                                // setFieldValue("cabang", e ? e.value : "");
                                            }}
                                        />
                                        {/* {formik.touched.firstName && formik.errors.firstName ? (
                                            <div>{formik.errors.firstName}</div>
                                        ) : null} */}
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="kode">Kode Customer</label>
                                        <input
                                            id="kode"
                                            className='form-control'
                                            type="text"
                                            disabled
                                            // {...formik.getFieldProps('kode')}
                                            value={kode}
                                        />
                                        {/* {formik.touched.lastName && formik.errors.lastName ? (
                                            <div>{formik.errors.lastName}</div>
                                        ) : null} */}
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="nilaiLimit">Nilai Limit</label>
                                        <input
                                            id="nilaiLimit"
                                            className='form-control'
                                            type="text"
                                            disabled
                                            // {...formik.getFieldProps('nilaiLimit')}
                                            value={`Rp. ${separatorHarga(nilaiLimit.toString())}`}
                                        />
                                        {/* {formik.touched.lastName && formik.errors.lastName ? (
                                            <div>{formik.errors.lastName}</div>
                                        ) : null} */}
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="limitReq">Limit Diajukan</label>
                                        <input
                                            id="limitReq"
                                            className='form-control'
                                            value={`Rp. ${separatorHarga(limitReq.toString())}`}
                                            onChange={(e) => {
                                                setLimitReq(e.target.value)
                                            }}
                                            type="text"
                                        // {...formik.getFieldProps('limitReq')}
                                        />
                                        {/* {formik.touched.limitReq && formik.errors.limitReq ? (
                                            <div>{formik.errors.limitReq}</div>
                                        ) : null} */}
                                    </div>
                                    {selected === 0 ? 
                                    '' : <DetailCust data={state}/>
                                    }
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="Keterangan">Keterangan</label>
                                        <textarea
                                            id="keterangan"
                                            className='form-control'
                                            value={keterangan}
                                            onChange={(e) => {
                                                setKeterangan(e.target.value)
                                            }}
                                            rows="6"
                                        // {...formik.getFieldProps('limitReq')}
                                        />
                                        {/* {formik.touched.limitReq && formik.errors.limitReq ? (
                                            <div>{formik.errors.limitReq}</div>
                                        ) : null} */}
                                    </div>
                                </div>
                            </Form>
                        </CardBody>
                        <CardFooter>
                            <div className="d-flex justify-content-end">
                                <button type="submit"
                                    className="btn btn-primary mx-1"
                                    onClick={() => {
                                        formik.handleSubmit()
                                    }}
                                >
                                    <i className="fa fa-save"></i>Simpan</button>
                            </div>
                        </CardFooter>
                    </AnimatedCard>
                )}
            </Formik>
        </>
    )
}

export default Forms
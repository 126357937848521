import moment from "moment/moment";
import { separatorHarga } from "../../../services/separator-harga";
export default class cekSelisihModel {
  constructor(
    data = {
      id: 0,
      tanggal: "",
      no_transaksi: "",
      jurnal: "",
      deskripsi: "",
      debet: 0,
      kredit: 0,
    }
  ) {
    const { id, tanggal, no_transaksi, jurnal, deskripsi, debet, kredit } = data;
    this['id'] = id;
    this['tanggal'] = tanggal;
    this['no_transaksi'] = no_transaksi;
    this['jurnal'] = jurnal;
    this['deskripsi'] = deskripsi;
    this['debet'] = debet;
    this['kredit'] = kredit;
  }
  async getdata() {
    const { tanggal, no_transaksi, jurnal, deskripsi, debet, kredit } = this;
    let selisih = parseInt(debet - kredit);
    return [{
      id: "tanggal",
      title: "Tanggal",
      label: moment(tanggal).format("DD-MM-YYYY"),
      center: true,
      sortable: true,
      minWidth: "12%",
    }, {
      id: "no_transaksi",
      title: "No. Transaksi",
      label: no_transaksi,
      center: true,
      sortable: true,
      minWidth: "22%",
    }, {
      id: "jurnal",
      title: "Jurnal",
      label: jurnal,
      center: true,
      sortable: true,
      minWidth: "20%",
    }, {
      id: "deskripsi",
      title: "Keterangan",
      label: deskripsi,
      sortable: true,
      minWidth: "60%",
    }, {
      id: "debet",
      title: "Debet",
      label: debet < 0 ? `Rp. -${separatorHarga(debet.toString())}` : separatorHarga(debet.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "kredit",
      title: "Kredit",
      label: kredit < 0 ? `Rp. -${separatorHarga(kredit.toString())}` : separatorHarga(kredit.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "selisih",
      title: "Selisih",
      label: selisih < 0 ? `Rp. -${separatorHarga(selisih.toString())}` : separatorHarga(selisih.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    },
    ];
  }
}

import React from "react";
import { CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls";
import { Formik } from "formik";
import * as Yup from "yup";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import { StokAPI } from "../../../api/stok";
import stokAwalModel from "../../../models/stok-awal-model/stokAwalModel";
import DataTableModel from "../../../models/datatable-model/DataTableModel";
import { FormsFields } from "../../../components/forms-fields/FormsFields";
import { ReactDatatable } from "../../../components/react-datatable/reactDatatable";
import { ModalForms } from "../../../components/modal-forms/modalForms";
import { hapusSeparator } from "../../../services/separator-harga";
import { dispatcher } from "../../../redux/dispatcher";
import ButtonActions from "../../../components/button-actions/buttonActions";

export default class StokAwal extends React.Component {
  constructor(props) {
    super(props);
    this['pageName'] = "Stok Awal";
    this['searchParameter'] = ["tanggal", "nama_produk", "produk_satuan", "nama_gudang", "qty", "hpp", "nilai_persediaan"];
    this['apiStok'] = new StokAPI();
    this['initialData'] = new stokAwalModel();
    this['state'] = {
      pending: true,
      modal: {
        show: false,
        initialData: this['initialData'],
        isEdit: false,
      },
    };
    this['datatable'] = [{ id: "", no: "", name: "" }];
    this['columntable'] = [{ id: "", title: "" }];
  }

  async componentDidMount() {
    await this.LoadData();
  }

  async LoadData() {
    this.setState({ pending: true });
    let data = await this['apiStok'].getAll();
    this['DataTableModel'] = new DataTableModel();
    data['data'].map((item, i) => {
      let dataModel = new stokAwalModel(item);
      this['DataTableModel'].add({
        id: i,
        no: i + 1,
        dataModel: dataModel,
        actions: (
          <>
            <ButtonActions
              label={"edit"}
              onClick={async () =>
                await this['handleModal'].edit(dataModel)}
            />
          </>
        ),
      });
      return item;
    });
    this['columntable'] = await this['DataTableModel'].getColumn();
    this['datatable'] = await this['DataTableModel'].getDatas();
    this.setState({ ...this['state'], pending: false });
  }

  handleModal = {
    loadOptionBarang: async (values) => {
      this['apiStok'].getProduk(values).then((res) => {
        this['option_nama_barang'] = res['data'].map((val) => {
          return {
            value: val['id'],
            label: val['text'],
            produk_satuan: val['produk_satuan'],
          };
        });
      });
    },
    loadOptionGudang: async () => {
      this['apiStok'].getGudang().then((res) => {
        this['option_nama_gudang'] = res['data'].map((val) => {
          return {
            value: val['id'],
            label: val['text'],
          };
        });
      });
    },
    tambah: async () => {
      await this['handleModal'].loadOptionBarang(false);
      await this['handleModal'].loadOptionGudang();
      this.setState({
        ...this['state'], modal: {
          show: true,
          title: `Tambah ${this['pageName']}`,
          isEdit: false,
          initialData: this['initialData'],
        }
      });
    },
    edit: async (dataFromModel) => {
      await this['handleModal'].loadOptionBarang(true);
      await this['handleModal'].loadOptionGudang();
      this.setState({
        modal: {
          show: true,
          title: `Edit ${this['pageName']}`,
          isEdit: true,
          initialData: new stokAwalModel({
            ...dataFromModel,
          })
        }
      });
    },
    hide: () => {
      this.setState({
        ...this['state'],
        modal: {
          show: false,
          initialData: this['initialData']
        }
      });
    },
  };
  render() {
    const { pending, modal } = this['state'];
    return (
      <>
        {/* Views Table */}
        <AnimatedCard>
          <CardHeader title={this['pageName']}>
            <CardHeaderToolbar>
              <button
                type={"button"}
                className={"btn btn-primary btn-sm"}
                onClick={() => this['handleModal'].tambah()}
              > <i className={"fa fa-plus"}></i> Tambah
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <ReactDatatable
              title={""}
              columns={this['columntable']}
              data={this['datatable']}
              searchParameter={this['searchParameter']}
              progressPending={pending}
            />
          </CardBody>
        </AnimatedCard>
        {/* Modal Tambah/Edit */}
        <Formik
          enableReinitialize={true}
          initialValues={modal['initialData']}
          validationSchema={Yup.object().shape({
            id_produk: Yup.string().nullable(true).required("Nama Barang harus diisi"),
            id_gudang: Yup.string().nullable(true).required("Nama Gudang harus diisi"),
            qty: Yup.string().required(),
            nilai_persediaan: Yup.string().required(),
          })}
          onSubmit={async (values, { resetForm }) => {
            let dataSend = {
              id_perusahaan: values['id_perusahaan'],
              id_produk: values['id_produk'],
              id_gudang: values['id_gudang'],
              id_user: values['id_user'],
              nilai_hpp: values['hpp'],
              ammount: values['ammount'],
              qty: values['qty'],
              nilai_persediaan: typeof values['nilai_persediaan'] === "string" ? parseFloat(hapusSeparator(values['nilai_persediaan'])) : parseFloat(values['nilai_persediaan']),
            };
            if (modal['isEdit']) {
              // Update Data
              dataSend = {
                id: parseInt(values['id']),
                ...dataSend
              }
              await this['apiStok'].edit(dataSend).then((res) => {
                if (res['status']) {
                  dispatcher['snackbar'].show("Berhasil", res['message'], "primary");
                  this['handleModal'].hide();
                  this.LoadData();
                } else {
                  return dispatcher['snackbar'].show("Gagal", res['message'], "danger");
                }
              });
            } else {
              // Insert Data
              await this['apiStok'].set(dataSend).then((res) => {
                if (res['status']) {
                  dispatcher['snackbar'].show("Berhasil", res['message'], "primary");
                  this['handleModal'].hide();
                  this.LoadData();
                } else {
                  return dispatcher['snackbar'].show("Gagal", res['message'], "danger");
                }
              });
            }
          }}
        >
          {({ handleSubmit, setFieldValue, setFieldTouched, touched, errors, values }) => (
            <ModalForms
              show={modal['show']}
              title={modal['title']}
              onHide={this['handleModal']['hide']}
              onConfirm={handleSubmit}
              size={"lg"}
              content={
                <div className={"form-group row"}>
                  <div className={"col-lg-6"}>
                    {/* Forms Nama Barang */}
                    <FormsFields
                      id={"id_produk"}
                      type="select2"
                      label={"Nama Barang"}
                      className={"col-lg-12"}
                      isClearable={true}
                      isSearchable={true}
                      isDisabled={modal['isEdit']}
                      options={this['option_nama_barang']}
                      onChange={(e) => {
                        setFieldValue("produk_satuan", e ? e['produk_satuan'] : "");
                      }}
                      paramsFormik={{
                        setFieldValue,
                        setFieldTouched,
                        touched,
                        errors,
                        values
                      }}
                    />
                    {/* Forms Produk Satuan */}
                    <FormsFields
                      id={'produk_satuan'}
                      type={"disabled-text"}
                      label={"Produk Satuan"}
                      className={"col-lg-12"}
                      style={{ marginTop: "20px" }}
                      value={values['produk_satuan']}
                    />
                    {/* Forms Select Nama Gudang */}
                    <FormsFields
                      id={"id_gudang"}
                      type="select2"
                      label={"Nama Gudang"}
                      style={{ marginTop: "20px" }}
                      className={"col-lg-12"}
                      isClearable={true}
                      isSearchable={true}
                      isDisabled={modal['isEdit']}
                      options={this['option_nama_gudang']}
                      paramsFormik={{
                        setFieldValue,
                        setFieldTouched,
                        touched,
                        errors,
                        values
                      }}
                    />
                  </div>
                  <div className={"col-lg-6"}>
                    {/* Forms Jumlah Stok */}
                    <FormsFields
                      id={"qty"}
                      type={"number"}
                      label={"Jumlah Stok"}
                      className={"col-lg-12"}
                      min={"0"}
                      max={"99999"}
                      disabled={modal['isEdit']}
                      onChange={(e) => {
                        const nilaiPersediaan = values['nilai_persediaan'] ? parseFloat(hapusSeparator(values['nilai_persediaan'])) : 0;
                        const qty = e['target']['value'] ? parseFloat(e['target']['value']) : 0;
                        let nilai_hpp = Math.ceil(nilaiPersediaan / qty);
                        setFieldValue("ammount", nilaiPersediaan);
                        setFieldValue("hpp", nilai_hpp);
                      }}
                      paramsFormik={{
                        setFieldValue,
                        values
                      }}
                    />
                    {/* Forms Nilai Persediaan */}
                    <FormsFields
                      id={"nilai_persediaan"}
                      type={"currency"}
                      label={"Nilai Persediaan"}
                      className={"col-lg-12"}
                      style={{ marginTop: "20px" }}
                      onChange={(e) => {
                        const nilaiPersediaan = e['target']['value'] ? parseFloat(hapusSeparator(e['target']['value'])) : 0;
                        const qty = values['qty'] ? parseFloat(values['qty']) : 0;
                        let nilai_hpp = Math.ceil(nilaiPersediaan / qty);
                        setFieldValue("ammount", nilaiPersediaan);
                        setFieldValue("hpp", nilai_hpp);
                      }}
                      paramsFormik={{
                        setFieldValue,
                        values
                      }}
                    />
                    {/* Forms Select Parent Control */}
                    <FormsFields
                      id={'hpp'}
                      type={"disabled-currency"}
                      label={"Nilai HPP"}
                      className={"col-lg-12"}
                      style={{ marginTop: "20px" }}
                      value={values['hpp']}
                    />
                  </div>
                </div>
              }
            />
          )}
        </Formik>
      </>
    );
  }
}

export default class BankModel {
  constructor(
    data = {
      id: 0,
      id_bank_jenis: "",
      id_perusahaan: "",
      nama_perusahaan: "",
      cabang: "",
      jenis_bank: "",
      nama_bank: "",
      no_rekening: "",
      status: 0,
    }
  ) {
    const { id, id_perusahaan, id_bank_jenis, nama_perusahaan, cabang, jenis_bank, nama_bank, no_rekening, status } = data;
    this['id'] = id;
    this['id_bank_jenis'] = id_bank_jenis;
    this['id_perusahaan'] = id_perusahaan;
    this['nama_perusahaan'] = nama_perusahaan;
    this['cabang'] = cabang;
    this['jenis_bank'] = jenis_bank;
    this['nama_bank'] = nama_bank;
    this['no_rekening'] = no_rekening;
    this['status'] = status;
  }
  async getdata() {
    const { nama_perusahaan, cabang, jenis_bank, nama_bank, no_rekening } = this;
    return [
      {
        id: "jenis_bank",
        title: "Jenis Bank",
        label: jenis_bank,
        center: true,
        sortable: true,
        minWidth: "14%",
      },
      {
        id: "nama_perusahaan",
        title: "Nama Perusahaan",
        label: nama_perusahaan,
        sortable: true,
        minWidth: "18%",
      },
      {
        id: "cabang",
        title: "Cabang",
        label: cabang,
        center: true,
        sortable: true,
        minWidth: "12%",
      },
      {
        id: "nama_bank",
        title: "Nama Bank",
        label: nama_bank,
        sortable: true,
        minWidth: "22%",
      },
      {
        id: "no_rekening",
        title: "No. Rekening",
        label: no_rekening,
        center: true,
        sortable: true,
        minWidth: "15%",
      },
    ];
  }
}

const initialValues = {
  active: null,
  list: [],
};

export const kasirBankOperasionalAction = {
  list: "LIST_BANK_OPERASIONAL",
  active: "ACTIVE_BANK_OPERASIONAL",
  resetActive: "RESET_ACTIVE_BANK_OPERASIONAL",
};

const reducer = (state = initialValues, action) => {
  let states;
  switch (action.type) {
    case kasirBankOperasionalAction.list:
      states = state;
      states.list = action.payload;
      return (state = states);
    case kasirBankOperasionalAction.active:
      states = state;
      states.active = action.payload;
      return (state = states);
    case kasirBankOperasionalAction.resetActive:
      states = state;
      states.active = null;
      return (state = states);
    default:
      return state;
  }
};

export default reducer;

import { CardBody, CardFooter, CardHeader, CardHeaderToolbar } from "../../../../../_metronic/_partials/controls";
import React, { Component } from "react";
import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import DataTableModel from "../../../../models/datatable-model/DataTableModel";
import { Formik } from "formik";
import { FormsFields } from "../../../../components/forms-fields/FormsFields";
import { dispatcher } from "../../../../redux/dispatcher";
import { Paper, Typography, makeStyles } from "@material-ui/core";
import { ReactDatatableFooterDetails } from "../../../../components/react-datatable-footer/reactDatatableFooter";
import { hapusSeparator, separatorHarga } from "../../../../services/separator-harga";

export default class DetailJurnalUmum extends Component {
  constructor(props) {
    super(props);
    this['pagename'] = "Detail Jurnal Umum";
    this['initialData'] = dispatcher['akuntansi'].getJurnalUmum();
    this['state'] = {
      total_out_off_balance: 0,
      footerJurnalUmum: {
        deskripsi: "Total",
        debet: 0,
        kredit: 0,
        tax: 0,
      },
    };
    this['datatable'] = [{ id: "", no: "", name: "" }];
    this['columntable'] = [{ id: "", title: "" }];
  }
  //* ----------------------------------------------------------------------------- */
  //** Start: Component */
  async componentDidMount() {
    await this.LoadDetail();
  }
  //** End: Component */
  //* ----------------------------------------------------------------------------- */
  //** Start: Load Data List */
  async LoadDetail() {
    let data = this['initialData']['detail'];
    this['DataTableModel'] = new DataTableModel({ readOnly: true });
    data.map((item, i) => {
      this['DataTableModel'].add({
        id: i,
        no: i + 1,
        dataModel: item,
        actions: null,
      });
      return item;
    });
    const total = (val) => {
      if (val === 'debet') {
        return data.reduce((total, value) => {
          let debet = 0;
          if (value['posisi'] === 'debet' || value['posisi'] === 'D') {
            debet = (typeof value['jumlah'] === "string") ? parseInt(hapusSeparator(value['jumlah'])) : parseInt(value['jumlah']);
          }
          return parseFloat(total + debet);
        }, 0);
      } else if (val === 'kredit') {
        return data.reduce((total, value) => {
          let kredit = 0;
          if (value['posisi'] === 'kredit' || value['posisi'] === 'K') {
            kredit = (typeof value['jumlah'] === "string") ? parseInt(hapusSeparator(value['jumlah'])) : parseInt(value['jumlah']);
          }
          return parseFloat(total + kredit);
        }, 0);
      } else if (val === 'tax') {
        return data.reduce((total, value) => {
          let tax = (typeof value['tax'] === "string") ? parseInt(hapusSeparator(value['tax'])) : parseInt(value['tax']);
          return parseFloat(total + tax);
        }, 0);
      }
    };
    let total_out_off_balance = (total("debet") - total("kredit"))
    this['columntable'] = await this['DataTableModel'].getColumn();
    this['datatable'] = await this['DataTableModel'].getDatas();
    this.setState({
      ...this['state'],
      total_out_off_balance: total_out_off_balance,
      footerJurnalUmum: {
        deskripsi: "Total",
        debet: separatorHarga(total("debet").toString(), "Rp. "),
        kredit: separatorHarga(total("kredit").toString(), "Rp. "),
        tax: separatorHarga(total("tax").toString(), "Rp. "),
      }
    });
  }
  //** End: Load Detail Table */
  //* ----------------------------------------------------------------------------- */

  render() {
    // Style
    const useStyles = makeStyles(theme => ({
      root: {
        textTransform: "uppercase",
        textAlign: "right",
        padding: theme.spacing(1, 2),
        backgroundColor: "#f3f6f9",
      },
    }));
    const { footerJurnalUmum, total_out_off_balance } = this['state'];
    return (
      <>
        {/* Start: Formik List Approval */}
        <Formik
          nableReinitialize={true}
          initialValues={this['initialData']['jurnal']}
          validationSchema={false}
        >
          {({ handleSubmit, values }) => (
            <AnimatedCard>
              <CardHeader title={this['pagename']}>
                <CardHeaderToolbar>
                  {/* Button Kembali */}
                  <button
                    type={"button"}
                    className={"btn btn-outline-secondary mx-1 btn-sm"}
                    onClick={() => this['props']['history'].goBack()}
                  ><i className={"fa fa-arrow-left"}></i> Kembali
                  </button>
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody style={{ marginTop: "-20px" }}>
                <div className={"form-group row"}>
                  <div className={"col-lg-6"}>
                    <div className={"form-group row"}>
                      {/* No. Transaksi */}
                      <FormsFields
                        id={'no_transaksi'}
                        type={"disabled-text"}
                        label={"No. Transaksi"}
                        className={"col-lg-12"}
                        style={{ marginTop: "20px" }}
                        value={values['no_transaksi']}
                      />
                      {/* Tanggal Transaksi */}
                      <FormsFields
                        id={'tanggal'}
                        type={"disabled-DatePicker"}
                        label={"Tanggal Transaksi"}
                        className={"col-lg-12"}
                        style={{ marginTop: "20px" }}
                        value={values['tanggal']}
                      />
                    </div>
                  </div>
                  <div className={"col-lg-6"}>
                    <div className={"form-group row"}>
                      {/* Keterangan */}
                      <FormsFields
                        id={'deskripsi'}
                        type={"disabled-textarea"}
                        label={"Keterangan"}
                        className={"col-lg-12"}
                        style={{ marginTop: "20px" }}
                        rows={3}
                        value={values['deskripsi']}
                      />
                    </div>
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <>
                  <span className={"d-flex align-items-center justify-content-between mt-4 mb-4"}>
                    <Typography variant={"h6"}>Detail</Typography>
                  </span>
                  <ReactDatatableFooterDetails
                    title={""}
                    data={this['datatable']}
                    columns={this['columntable']}
                    footer={footerJurnalUmum}
                  />
                </>
                {/* End: Detail Dana */}
                {/* Total Out Off Balance */}
                <div className={"tab-content mt-5"}>
                  <div className={"table-responsive"}>
                    <Paper className={useStyles().root}>
                      <Typography variant={"h5"} component={"h3"}>
                        Total Out Off Balance : {(total_out_off_balance < 0) ? "Rp. -" + separatorHarga(total_out_off_balance.toString()) :
                          separatorHarga(total_out_off_balance.toString(), "Rp. ")}
                      </Typography>
                    </Paper>
                  </div>
                </div>
              </CardFooter>
            </AnimatedCard>
          )}
        </Formik>
        {/* End: Formik List Approval */}
        {/* ----------------------------------------------------------------------------- */}
      </>
    );
  }
}

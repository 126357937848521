import * as Yup from "yup";
import { CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import DataTableModel from "../../../models/datatable-model/DataTableModel";
import { Formik } from "formik";
import { FormsFields } from "../../../components/forms-fields/FormsFields";
import { ModalForms } from "../../../components/modal-forms/modalForms";
import React from "react";
import { ReactDatatable } from "../../../components/react-datatable/reactDatatable";
import SimpleAlert from "../../../components/alert/alert";
import { TransaksiRefAPI } from "../../../api/transaksi-referensi";
import { dispatcher } from "../../../redux/dispatcher";
import transaksiRefModel from "../../../models/transaksi-referensi-model/transaksiRefModel";
import ButtonActions from "../../../components/button-actions/buttonActions";
export default class transaksiRef extends React.Component {
  constructor(props) {
    super(props);
    this['pageName'] = "Transaksi Referensi";
    this['searchParameter'] = ["name", "id_akun", "id_transaksi_jenis", "id_transaksi_tipe"];
    this['apiTransaksiRef'] = new TransaksiRefAPI();
    this['initialData'] = new transaksiRefModel();
    this['state'] = {
      pending: true,
      modal: {
        show: false,
        initialData: this['initialData'],
        isEdit: false,
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
    this['datatable'] = [{ id: "", no: "", name: "" }];
    this['columntable'] = [{ id: "", title: "" }];
  }
  async componentDidMount() {
    await this.LoadData();
  }
  // LoadData
  async LoadData() {
    this.setState({ pending: true });
    let data = await this['apiTransaksiRef'].getList();
    this['DataTableModel'] = new DataTableModel();
    data['data'].map((item, i) => {
      let dataModel = new transaksiRefModel(item);
      if (item['status'] === 1) {
        this['DataTableModel'].add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: (
            <>
              <>
                <ButtonActions
                  label={"edit"}
                  onClick={async () =>
                    await this['handleModal'].edit(dataModel)}
                />
                <ButtonActions
                  label={"delete"}
                  onClick={async () => {
                    this['handleAlert'].show(item['id']);
                    this.LoadData();
                  }}
                />
              </>
            </>
          ),
        });
      }
      return item;
    });
    this['columntable'] = await this['DataTableModel'].getColumn();
    this['datatable'] = await this['DataTableModel'].getDatas();
    this.setState({ ...this['state'], pending: false });
  }
  handleModal = {
    loadOptionAkun: async () => {
      this['apiTransaksiRef'].getAkun().then((res) => {
        // Load Data Bank
        this['option_akun'] = res['data'].map((val) => {
          return {
            value: val['id'],
            label: val['text'],
          };
        });
      });
    },
    loadOptionJenisTransaksi: async () => {
      this['apiTransaksiRef'].getTransaksiJenis().then((res) => {
        // Load Data Transaksi Jenis
        this['option_transaksi_jenis'] = res['data'].map((val) => {
          return {
            value: val['id'],
            label: val['text'],
          };
        });
      });
    },
    loadOptionTipeTransaksi: async () => {
      this['apiTransaksiRef'].getTransaksiTipe().then((res) => {
        // Load Data Transaksi Tipe
        this['option_transaksi_tipe'] = res['data'].map((val) => {
          return {
            value: val['id'],
            label: val['text'],
          };
        });
      });
    },
    tambah: async () => {
      let state = { ...this['state'] };
      state = {
        modal: {
          show: true,
          title: `Tambah ${this['pageName']}`,
          isEdit: false,
          initialData: this['initialData'],
        }
      }
      await this['handleModal'].loadOptionAkun();
      await this['handleModal'].loadOptionJenisTransaksi();
      await this['handleModal'].loadOptionTipeTransaksi();
      this.setState({ ...state });
    },
    edit: async (dataFromModel) => {
      let state = { ...this['state'] };
      state = {
        modal: {
          show: true,
          title: `Edit ${this['pageName']}`,
          isEdit: true,
          initialData: dataFromModel
        }
      }
      await this['handleModal'].loadOptionAkun();
      await this['handleModal'].loadOptionJenisTransaksi();
      await this['handleModal'].loadOptionTipeTransaksi();
      this.setState({ ...state });
    },
    hide: () => {
      let state = { ...this['state'] };
      state = {
        modal: {
          show: false,
          initialData: this['initialData']
        }
      }
      this.setState({ ...state });
    },
  };
  handleAlert = {
    show: (id) => {
      let alert = { ...this['state'] };
      alert = {
        alert: {
          show: true,
          activeId: id
        }
      }
      this.setState({ ...alert });
    },
    hide: () => {
      let alert = { ...this['state'] };
      alert = {
        alert: {
          show: false,
          activeId: null
        }
      }
      this.setState({ ...alert });
    },
    confirmed: async () => {
      await this['apiTransaksiRef'].delete(this['state']['alert']['activeId']).then((res) => {
        if (res['status']) {
          dispatcher['snackbar'].show("Berhasil", res.message, "primary");
          this['handleAlert'].hide();
          this.LoadData();
        } else {
          return dispatcher['snackbar'].show("Gagal", res.message, "danger");
        }
      });
    },
  };
  render() {
    const { pending, modal, alert } = this['state'];
    return (
      <>
        <AnimatedCard>
          <CardHeader title={this['pageName']}>
            <CardHeaderToolbar>
              <button
                type={"button"}
                className={"btn btn-primary"}
                onClick={() => this['handleModal'].tambah()}
              > <i className={"fa fa-plus"}></i> Tambah
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <ReactDatatable
              title={""}
              columns={this['columntable']}
              data={this['datatable']}
              searchParameter={this['searchParameter']}
              progressPending={pending}
            />
          </CardBody>
        </AnimatedCard>
        <Formik
          enableReinitialize={true}
          initialValues={modal['initialData']}
          validationSchema={Yup.object().shape({
            nama: Yup.string().required(),
            id_transaksi_jenis: Yup.string().nullable(true).required("Jenis Transaksi harus diisi"),
            id_transaksi_tipe: Yup.string().nullable(true).required("Tipe Transaksi harus diisi")
          })}
          onSubmit={async (values, { resetForm }) => {
            let dataSend = {
              nama: values['nama'],
              id_akun: parseInt(values['id_akun']),
              id_transaksi_jenis: parseInt(values['id_transaksi_jenis']),
              id_transaksi_tipe: parseInt(values['id_transaksi_tipe'])
            };
            if (modal['isEdit']) {
              // Update Data
              dataSend = {
                id: parseInt(values['id']),
                ...dataSend
              }
              await this['apiTransaksiRef'].edit(dataSend).then((res) => {
                if (res['status']) {
                  dispatcher['snackbar'].show("Berhasil", res.message, "primary");
                  this['handleModal'].hide();
                  this.LoadData();
                } else {
                  return dispatcher['snackbar'].show("Gagal", res.message, "danger");
                }
              });
            } else {
              // Insert Data
              await this['apiTransaksiRef'].set(dataSend).then((res) => {
                if (res['status']) {
                  dispatcher['snackbar'].show("Berhasil", res.message, "primary");
                  this['handleModal'].hide();
                  this.LoadData();
                } else {
                  return dispatcher['snackbar'].show("Gagal", res.message, "danger");
                }
              });
            }
          }}
        >
          {({ handleSubmit, setFieldValue, setFieldTouched, touched, errors, values }) => (
            <ModalForms
              show={modal['show']}
              title={modal['title']}
              onHide={this['handleModal']['hide']}
              onConfirm={handleSubmit}
              content={
                <div className={"form-group row"}>
                  {/* Forms Nama */}
                  <FormsFields
                    id={"nama"}
                    type="text"
                    label={"Nama"}
                    className={"col-lg-12"}
                    paramsFormik={{
                      setFieldValue,
                      values
                    }}
                  />
                  {/* Forms Select Akun */}
                  <FormsFields
                    id={"id_akun"}
                    type="select2"
                    label={"Akun"}
                    style={{ marginTop: "20px" }}
                    className={"col-lg-12"}
                    isClearable={true}
                    isSearchable={true}
                    options={this['option_akun']}
                    paramsFormik={{
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    }}
                  />
                  {/* Forms Select Jenis Transaksi */}
                  <FormsFields
                    id={"id_transaksi_jenis"}
                    type="select2"
                    label={"Jenis Transaksi"}
                    style={{ marginTop: "20px" }}
                    className={"col-lg-12"}
                    isClearable={true}
                    isSearchable={true}
                    options={this['option_transaksi_jenis']}
                    paramsFormik={{
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    }}
                  />
                  {/* Forms Select Tipe Transaksi */}
                  <FormsFields
                    id={"id_transaksi_tipe"}
                    type="select2"
                    label={"Tipe Transaksi"}
                    style={{ marginTop: "20px" }}
                    className={"col-lg-12"}
                    isClearable={true}
                    isSearchable={true}
                    options={this['option_transaksi_tipe']}
                    paramsFormik={{
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    }}
                  />
                </div>
              }
            />
          )}
        </Formik>
        {/* Alert Hapus */}
        <SimpleAlert
          title={"Hapus Data"}
          content={"Anda yakin hapus data ini...?"}
          show={alert['show']}
          onHide={() => this['handleAlert'].hide()}
          onConfirmed={this['handleAlert']['confirmed']}
        />
      </>
    );
  }
}

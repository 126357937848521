import { getIdPerusahaan, simpleFetch, simpleFetchGetData } from "./api";

export async function getAllMenuSidebar(idRole) {
  let menus = await simpleFetch({
    url: "retail/menu/sidebar?id_role=" + idRole,
    method: "GET",
  });
  return menus;
}
export class KonfigurasiMenuAPI {
  //** Start: Konfigurasi Menu */
  //** Get List */
  async getAllListKonfigurasiMenu() {
    let res = await simpleFetchGetData({
      url: "retail/menu?tipe=getList",
      method: "GET",
    });
    return res;
  }
  //** Save
  async setKonfigurasiMenu(values) {
    let res = await simpleFetch({
      url: "retail/menu",
      method: "POST",
      body: {
        ...values,
      },
    });
    return res;
  }
  //** Edit */
  async editKonfigurasiMenu(values) {
    let res = await simpleFetch({
      url: "retail/menu",
      method: "PUT",
      body: {
        ...values,
      },
    });
    return res;
  }
  //** Delete */
  async deleteKonfigurasiMenu(id) {
    let res = await simpleFetch({
      url: "retail/menu",
      method: "DELETE",
      body: {
        id: id,
      },
    });
    return res;
  }
  //** End: Konfigurasi Menu */
  //* ------------------------------------------------------------------------------------------- */
  //** Start: Get Select Option */
  // Menu Parent
  async getMenuSistemParent() {
    let res = await simpleFetch({
      url: "retail/select/konfigurasi_sistem/menu-parent?",
      method: "GET",
    });
    return res;
  }
  //** End: Get Select Option */
  //* ------------------------------------------------------------------------------------------- */
























  async getSingle(id) {
    let res = await simpleFetch({
      url: "retail/menu?id=" + id,
      method: "GET",
    });
    return res;
  }

  async set(values) {
    let values2send = {};
    if (values.id_parent === "") {
      values2send = {
        ...values,
        id_parent: null
      }
    } else {
      values2send = {
        ...values,
      }
    }
    let res = await simpleFetch({
      url: "retail/menu",
      method: "POST",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values2send,
      },
    });
    return res;
  }

  async edit(values) {
    let values2send = {};
    if (values.id_parent === "") {
      values2send = {
        ...values,
        id_parent: null
      }
    } else {
      values2send = {
        ...values,
      }
    }
    let res = await simpleFetch({
      url: "retail/menu",
      method: "PUT",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values2send,
      },
    });
    return res;
  }


}


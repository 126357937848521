/* eslint-disable eqeqeq */
import * as Yup from "yup";
import { ProdukAPI } from "../../api/produk";
import { separatorHarga, hapusSeparator } from "../../services/separator-harga";
import store from "../../redux/store";
import { dispatcher } from "../../redux/dispatcher";
import { MarginHargaJualAPI } from "../../api/margin-harga-jual";

export default class PesananPenjualanDetailModel {
  constructor(
    data = {
      id: 0,
      id_pesanan_penjualan: 0,
      id_perusahaan: store.getState().auth.id_perusahaan,
      id_produk: 0,
      qty: 0,
      hpp: 0,
      hpp_total: 0,
      dpp: 0,
      dpp_total: 0,
      diskon: 0,
      diskon_opsi: 2,
      diskon_persen: 0,
      diskon_item: 0,
      subtotal: 0,
      pajak: 0,
      pajak_persen: parseInt(11),
      total: 0,
      id_user: store.getState().auth.id,
      id_customer: 0,
      id_sales: 0,
      deskripsi: "",
      status: 1,
      created_at: "",
      update_at: "",
      toProduk: {
        nama: "",
        persediaan_total: 0,
        toProdukSatuan: {
          nama: ""
        }
      },
      harga_beli: 0,
      harga_margin: 0,
      harga_after_diskon: 0,
      harga_ppn: 0,
      id_produk_kategori: 0,
      hpp_temp: 0,
      diskon_temp: 0,
    }
  ) {
    this.id = data.id;
    this.id_pesanan_penjualan = data.id_pesanan_penjualan;
    this.id_perusahaan = data.id_perusahaan;
    this.id_produk = data.id_produk;
    this.nama_produk = data.toProduk.nama;
    this.produk_satuan = data.toProduk.toProdukSatuan.nama || "";
    this.qty = data.qty;
    this.hpp = separatorHarga(data.hpp.toString());
    this.hpp_total = separatorHarga(data.hpp_total.toString());
    this.hpp_temp = data.hpp_temp;
    this.dpp = separatorHarga(data.dpp.toString());
    this.dpp_total = separatorHarga(data.dpp_total.toString());
    this.diskon = separatorHarga(data.diskon.toString());
    this.diskon_temp = data.diskon_temp;
    this.diskon_persen = data.diskon_persen;
    this.diskon_item = data.diskon_item;
    this.subtotal = separatorHarga(data.subtotal.toString());
    this.pajak = separatorHarga(data.pajak.toString());
    this.pajak_persen = data.pajak_persen;
    this.total = separatorHarga(data.total.toString());
    this.id_user = data.id_user;
    this.id_customer = data.id_customer;
    this.id_sales = data.id_sales;
    this.deskripsi = data.deskripsi;
    this.status = data.status;
    this.created_at = data.created_at;
    this.update_at = data.update_at;
    this.persediaan_total = data.toProduk.persediaan_total;
    this.diskon_opsi = data.diskon_opsi;
    this.harga_beli = data.harga_beli;
    this.harga_margin = data.harga_margin;
    this.harga_after_diskon = data.harga_after_diskon;
    this.hargaDpp = data.dpp;
    this.harga_ppn = data.harga_ppn;
    this.id_produk_kategori = data.id_produk_kategori;
    this.options = [
      { value: 1, text: "Persen", id: 1 },
      { value: 2, text: "Rupiah", id: 2 },
    ];
    this.typeDiskon = data.typeDiskon;
  }

  nulldata = {
    id: 0,
    id_pesanan_penjualan: 0,
    id_perusahaan: store.getState().auth.id_perusahaan,
    id_produk: 0,
    nama_produk: "",
    qty: 0,
    hpp: 0,
    hpp_total: 0,
    dpp: 0,
    dpp_total: 0,
    diskon: 0,
    diskon_persen: 0,
    diskon_item: 0,
    subtotal: 0,
    pajak: 0,
    pajak_persen: Math.round(11),
    total: 0,
    id_user: store.getState().auth.id,
    id_customer: 0,
    id_sales: 0,
    deskripsi: "",
    status: 1,
    created_at: "",
    update_at: "",
    toProduk: {
      nama: "",
      persediaan_total: 0,
      toProdukSatuan: {
        nama: ""
      }
    },
    persediaan_total: 0,
  };

  removeSeparator() {
    this.hpp = parseInt(hapusSeparator(this.hpp));
    this.dpp = parseInt(hapusSeparator(this.dpp));
    this.hpp_total = parseInt(hapusSeparator(this.hpp_total));
    this.dpp_total = parseInt(hapusSeparator(this.dpp_total));
    this.diskon = parseInt(hapusSeparator(this.diskon));
    this.pajak = parseInt(hapusSeparator(this.pajak));
    this.subtotal = parseInt(hapusSeparator(this.subtotal));
    this.total = parseInt(hapusSeparator(this.total));
  }

  hitungTotalan = {
    all: (setFieldValue) => {
      this.hitungTotalan.dpp_total();
      this.hitungTotalan.diskon();
      this.hitungTotalan.subtotal();
      this.hitungTotalan.pajak();
      this.hitungTotalan.dpp_ppn();
      this.hitungTotalan.total();
      setFieldValue("harga_margin", this.harga_margin);
      setFieldValue("harga_beli", this.harga_beli);
      setFieldValue("hpp_total", this.hpp_total);
      setFieldValue("dpp_total", this.dpp_total);
      setFieldValue("diskon", this.diskon);
      setFieldValue("subtotal", this.subtotal);
      setFieldValue("pajak", this.pajak);
      setFieldValue("harga_ppn", this.harga_ppn);
      setFieldValue("total", this.total);
    },
    dpp_total: () => {
      this.MasterMargin = this.hargaMargin;
      this.hargaDpp = parseInt(hapusSeparator(this.dpp));
      let hpp = parseInt(hapusSeparator(this.hpp));
      let dpp = parseInt(hapusSeparator(this.dpp));

      // this.harga_ppn = Math.ceil(dpp * 1.11);
      this.hpp_temp = parseInt(this.qty) * hpp;
      this.hpp_total = separatorHarga((parseInt(this.qty) * hpp).toString());
      this.dpp_total = separatorHarga((parseInt(this.qty) * dpp).toString());
    },
    diskon: () => {
      let dpp_total = parseInt(hapusSeparator(this.hpp_total));
      let diskonPersen = parseInt(hapusSeparator(this.diskon));
      let persen = diskonPersen / 100;
      let hasilPersen = Math.round(dpp_total * persen)
      let diskon =
        this.diskon_opsi === 2
          ? this.diskon
          : separatorHarga(
            ((hasilPersen)).toString()
          );

      this.diskon_temp = parseInt(hapusSeparator(diskon));
      this.diskon = diskon;
      this.diskon_persen = this.diskon_opsi === 1
        ? diskonPersen
        : ((diskonPersen / dpp_total) * 100).toFixed(2);

      this.diskon_item = this.diskon_opsi === 1 ?
        separatorHarga(Math.round(hasilPersen / this.qty).toString()) : separatorHarga(Math.round(diskonPersen / this.qty).toString());
      console.log("Diskon Item", Math.round(parseInt(hapusSeparator(diskon)) / this.qty));
      
    },
    dpp_ppn: () => {
      let dpp = parseInt(hapusSeparator(this.dpp));
      this.harga_ppn = separatorHarga(Math.ceil(dpp * 1.11).toString());
    },
    subtotal: () => {
      let dpp_total = parseInt(hapusSeparator(this.dpp_total));
      this.subtotal = separatorHarga(
        (dpp_total - parseInt(hapusSeparator(this.diskon))).toString()
      );
      this.harga_after_diskon = parseInt(hapusSeparator(this.subtotal)) / this.qty;
      if (this.harga_after_diskon < this.harga_margin) {
        dispatcher.snackbar.show(
          "Perhatian",
          "Harga lebih kecil dari margin",
          "warning"
        )
      }
      if (this.harga_after_diskon === this.harga_margin) {
        dispatcher.snackbar.show(
          "Perhatian",
          "Harga berada di range margin terkecil",
          "success"
        )
      }
      if (this.harga_after_diskon > this.harga_margin) {
        dispatcher.snackbar.show(
          "Perhatian",
          "Harga bisa digunakan",
          "primary"
        )
      }
    },
    pajak: () => {
      let subtotal = parseInt(hapusSeparator(this.subtotal));
      let pajak = Math.ceil(subtotal * (this.pajak_persen / 100))
      this.pajak = separatorHarga(parseInt(pajak).toString())
    },
    total: () => {
      let subtotal = parseInt(hapusSeparator(this.subtotal));
      this.total = separatorHarga(
        (subtotal + parseInt(hapusSeparator(this.pajak))).toString()
      );
    },
  };

  async getEditable(params = { pageName: "", isForm: false }) {
    const getProdukDetail = async (id) => {
      return await new ProdukAPI().getSingle(id).then((res) => {
        return res.data;
      });
    };

    const marginBottom = async (dt) => {
      return await new MarginHargaJualAPI().getAll().then((res) => {
        let datas = res.data;
        let sama = datas.filter((val) => val.id_produk_kategori === dt)
        let beda = datas.filter((val) => val.toProdukKategori === null)
        let master;
        if (sama.length > 0) {
          master = sama
        }
        if (sama.length === 0) {
          master = beda
        }
        let arr = master.map((data) => {
          return data.margin
        })
        const min = Math.min(...arr)
        console.log('Master', master);
        // console.log('MIN', min);
        return min
      })
    }

    let produk_options = [];
    if (params.isForm) {
      let datas = await new ProdukAPI().getAll();
      let filtered = datas.data.filter((fil) => {
        const idPerusahaan = store.getState().auth.toPerusahaan.id_perusahaan;
        return fil.id_perusahaan === idPerusahaan ? fil : "";
      });
      filtered.map((data, i) => {
        return produk_options = [
          ...produk_options,
          { id: data.id, text: data.nama, label: `${data.nama} || ${data.toProdukSatuan.nama}`, value: parseInt(data.id) },
        ]
      })

      await getProdukDetail(this.id_produk).then((val) => {
        this.persediaan_total = val.persediaan_total;
      });
    }

    return [
      {
        validation: Yup.number()
          .typeError("")
          .required("Produk harus diisi"),
        dataField: "id_produk",
        label: "Produk",
        type: "select2",
        isClearable: false,
        options: produk_options,
        defaultValue: { label: this.nama_produk },
        onChange: (e, setFieldValue) => {
          // setFieldValue("dpp", "Memuat...");
          // setFieldValue("persediaan_total", "Memuat...");
          // if (e.value != 0)
          this.id_produk = e ? e.value : "";
          getProdukDetail(e.value).then((val) => {
            this.harga_beli = val.harga_beli;
            this.id_produk_kategori = val.id_produk_kategori
            this.harga_ppn = separatorHarga(Math.ceil(val.harga_jual * 1.11).toString());
            this.hpp = separatorHarga(val.harga_jual.toString());
            this.dpp = separatorHarga(val.harga_jual.toString());
            this.nama_produk = val.nama
            this.produk_satuan = val.toProdukSatuan.nama
            // this.nama_produk =
            //   e.target.options[e.target.options.selectedIndex].text;
            this.persediaan_total = val.persediaan_total;
            setFieldValue("persediaan_total", this.persediaan_total);
            setFieldValue("hpp", this.hpp);
            setFieldValue("dpp", this.dpp);
            setFieldValue("harga_ppn", this.harga_ppn);
            setFieldValue("harga_margin", this.harga_margin);
            setFieldValue("harga_beli", this.harga_beli);
            return val
          }).then((dt) => {
            marginBottom(dt.id_produk_kategori).then((val) => {
              let harga = this.harga_beli;
              let hargaMargin =
                ((harga * parseInt(val)) / 100)
              let hargaJual = harga + hargaMargin;
              this.harga_margin = Math.ceil(hargaJual)
              console.log('margin', this.harga_margin);
            })
          })
          this.hitungTotalan.all(setFieldValue);
          // return (this.id_produk = parseInt(e.target.value));
        },
        value: `${this.nama_produk} ${this.produk_satuan}`,
      },
      {
        validation: Yup.string().required("Harga harus diisi"),
        dataField: "dpp",
        label: "Harga Non PPN",
        type: "currency",
        onChange: (e, setFieldValue) => {
          this.hpp = e.target.value;
          this.dpp = e.target.value;
          console.log('Harga MARGIN', this.harga_margin);
          this.hargaDpp = parseInt(hapusSeparator(this.dpp));
          this.hitungTotalan.all(setFieldValue);
          if (this.hargaDpp < this.harga_margin) {
            dispatcher.snackbar.show(
              "Perhatian",
              "Harga lebih kecil dari margin",
              "warning"
            )
          }
          if (this.hargaDpp === this.harga_margin) {
            dispatcher.snackbar.show(
              "Perhatian",
              "Harga berada di range margin terkecil",
              "success"
            )
          }
          if (this.hargaDpp > this.harga_margin) {
            dispatcher.snackbar.show(
              "Perhatian",
              "Harga bisa digunakan",
              "primary"
            )
          }
          this.hitungTotalan.dpp_ppn()
        },
        value: this.dpp,
        // invisibleOnTable: true,
      },
      {
        validation: Yup.string(),
        dataField: "persediaan_total",
        label: "Stok Tersedia",
        type: "disabled-text",
        onChange: (e, setFieldValue) => {
          this.persediaan_total = e.target.value
        },
        value: this.persediaan_total,
        invisibleOnTable: true,
      },
      {
        // validation: Yup.string(),
        dataField: "harga_ppn",
        label: "Harga + PPN",
        type: "disabled-text",
        value: this.harga_ppn,
        // invisibleOnTable: true,
      },
      {
        // validation: Yup.string(),
        dataField: "name",
        label: "nama",
        type: "hidden",
        invisibleOnTable: true,
      },
      {
        validation: Yup.number()
          .integer()
          .positive()
          .typeError("QTY tidak valid")
          .required("QTY harus diisi"),
        dataField: "qty",
        label: "QTY",
        type: "number",
        value: this.qty,
        onChange: (e, setFieldValue) => {
          this.qty = parseInt(e.target.value);
          this.hitungTotalan.all(setFieldValue);
          if (this.qty > this.persediaan_total) {
            dispatcher.snackbar.show(
              "Perhatian",
              "QTY lebih besar dari persediaan",
              "warning"
            );
          }
        },
      },
      // {
      //   validation: Yup.number()
      //     .integer()
      //     .positive()
      //     .max(100)
      //     .typeError("Diskon tidak valid")
      //     .required("Diskon harus diisi"),
      //   dataField: "diskon_persen",
      //   label: "Diskon",
      //   onChange: (e, setFieldValue) => {
      //     this.diskon_persen = parseInt(e.target.value);
      //     this.hitungTotalan.all(setFieldValue);
      //   },
      //   type: "number",
      //   value: this.diskon_persen,
      //   invisibleOnTable: true,
      // },
      {
        // validation: Yup.string(),
        dataField: "name",
        label: "nama",
        type: "hidden",
        invisibleOnTable: true,
      },
      {
        validation: Yup.string().required("Total harga harus diisi"),
        dataField: "hpp_total",
        label: "Harga Total",
        type: "disabled-text",
        value: this.hpp_total,
      },
      {
        dataField: "diskon_opsi",
        label: "Diskon By",
        onChange: (e, setFieldValue) => {
          this.diskon_opsi = parseInt(e.target.value);
          this.hitungTotalan.all(setFieldValue);
          this.hitungTotalan.diskon();
        },
        type: "select",
        options: this.options,
        value: this.diskon_opsi,
        invisibleOnTable: true,
      },
      {
        // validation: Yup.string(),
        dataField: "diskon_item",
        label: "Diskon / Item",
        type: "hidden",
        value: this.diskon_item
        // invisibleOnTable: true,
      },
      {
        validation: Yup.string()
        // .required()
        // .matches(/^[0-9]+$/, "Must be only digits")
        ,
        dataField: "diskon",
        label: "Diskon",
        onChange: (e, setFieldValue) => {
          this.diskon = e.target.value;
          this.hitungTotalan.all(setFieldValue);
        },
        type: "currency",
        value: this.diskon,
      },
      {
        // validation: Yup.string(),
        dataField: "diskon_persen",
        label: "Diskon %",
        type: "hidden",
        value: this.diskon_persen,
        onChange: (e) => {
        },
        // invisibleOnTable: true,
      },
      {
        validation: Yup.string().required("Subtotal harus diisi"),
        dataField: "subtotal",
        label: "Sub Total",
        type: "disabled-text",
        value: this.subtotal,
      },
      {
        dataField: "pajak_persen",
        label: "Pajak",
        type: "hidden",
        onChange: (e, setFieldValue) => {
        },
        value: this.pajak_persen,
        invisibleOnTable: true,
      },
      {
        validation: Yup.string(),
        dataField: "pajak",
        label: "Pajak Rupiah",
        onChange: (e, setFieldValue) => {
          // this.pajak = e.target.value;
          // this.hitungTotalan.all(setFieldValue);
        },
        type: "disabled-text",
        value: this.pajak,
        invisibleOnTable: true,
      },
      {
        // validation: Yup.string(),
        dataField: "name",
        label: "Deskripsi",
        type: "hidden",
        invisibleOnTable: true,
      },
      {
        validation: Yup.string().required("Total harus diisi"),
        dataField: "total",
        label: "Total",
        type: "disabled-text",
        value: this.total,
        invisibleOnTable: true,
      },
      {
        // validation: Yup.string(),
        dataField: "name",
        label: "Deskripsi",
        type: "hidden",
        invisibleOnTable: true,
      },
      {
        validation: Yup.string(),
        dataField: "deskripsi",
        label: "Deskripsi",
        type: "textarea",
        value: this.deskripsi,
        onChange: (e) => (this.deskripsi = e.target.value),
        invisibleOnTable: true,
      },
    ];
  }
}

import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import ListPengeluaranBankCollection from "./list";
import DetailPengeluaranBankCollection from "./detail";
import { dispatcher } from "../../../../../redux/dispatcher";
export default function IndexListPengeluaranBankCollection ()
{
  const history = useHistory();
  const active = dispatcher[ 'kasir_bank_collection' ].getPengeluaranBankCollection();
  return (
    <Switch>
      <Route path="/kasir/daftar-pengeluaran-bank-collection/list">
        <ListPengeluaranBankCollection />
      </Route>
      { active[ 'transaksi' ][ 'id' ] === 0 && (
        <Redirect to="/kasir/daftar-pengeluaran-bank-collection/list" />
      ) }
      <Route path="/kasir/daftar-pengeluaran-bank-collection/detail">
        <DetailPengeluaranBankCollection history={ history } />
      </Route>
      <Redirect to="/kasir/daftar-pengeluaran-bank-collection/list" />
    </Switch>
  );
}

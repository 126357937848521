
import { CardBody, CardHeader } from "../../../../../_metronic/_partials/controls";
import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import DataTableModel from "../../../../models/datatable-model/DataTableModel";
import React from "react";
import { ReactDatatable } from "../../../../components/react-datatable/reactDatatable";
import { dispatcher } from "../../../../redux/dispatcher";
import ButtonActions from "../../../../components/button-actions/buttonActions";
import { notificationAPI } from "../../../../api/notifications";
import PesananPenjualanModel from "../../../../models/transaksi/pesanan-penjualan/pesananPenjualanModel";


export default class ApprovalManagerPesananPenjualanList extends React.Component {
  constructor(props) {
    super(props);
    this['pageName'] = "List Approval Manager Pesanan Penjulan";
    this['searchParameter'] = ["tanggal", "no_transaksi", "nama_customer", "nama_organisasi", "nama_sales", "jenis_pembayaran", "qty_total", "total"];
    this['apiNotification'] = new notificationAPI();
    this['initialData'] = new PesananPenjualanModel();
    this['state'] = {
      pending: true,
      modal: {
        show: false,
        initialData: this['initialData'],
        isEdit: false,
      },
    };
    this['datatable'] = [{ id: "", no: "", name: "" }];
    this['columntable'] = [{ id: "", title: "" }];
    this['datatableDetail'] = [{ id: "", no: "", name: "" }];
    this['columntableDetail'] = [{ id: "", title: "" }];
  }
  async componentDidMount() {
    await this.LoadData();
  }
  async LoadData() {
    this.setState({ pending: true });
    await dispatcher['notification'].listApprovalPesananPenjualan('manager');
    let data = dispatcher['notification'].getListApprovalPesananPenjualan();
    this['DataTableModel'] = new DataTableModel();
    data.map((item, i) => {
      let dataModel = new PesananPenjualanModel(item);
      if (item['master']['status'] === 1) {
        this['DataTableModel'].add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: (
            <>
              <ButtonActions
                label={"approveLink"}
                linkTo={"/notifications/approval-manager-pesanan-penjualan/approval"}
                onClick={async () => dispatcher['notification'].setApprovalPesananPenjualan(dataModel)}
              />
            </>
          ),
        });
      }
      return item;
    });
    this['columntable'] = await this['DataTableModel'].getColumn();
    this['datatable'] = await this['DataTableModel'].getDatas();
    this.setState({ ...this['state'], pending: false });
  }
  render() {
    const { pending } = this['state'];
    return (
      <>
        {/* Views Table */}
        <AnimatedCard>
          <CardHeader title={this['pageName']}></CardHeader>
          <CardBody>
            <ReactDatatable
              title={""}
              columns={this['columntable']}
              data={this['datatable']}
              searchParameter={this['searchParameter']}
              progressPending={pending}
            />
          </CardBody>
        </AnimatedCard>
      </>
    );
  }
}

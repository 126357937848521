import { simpleFetch, simpleFetchGetData } from "./api";

export class CoverageAPI {
  async getAll() {
    let res = await simpleFetch({
      url: "retail/user/coverage",
      method: "GET",
    });
    return res;
  }

  async getSingle(id) {
    let res = await simpleFetchGetData({
      url: "retail/user/coverage/checked?id_user=" + id,
      method: "GET",
    });
    return res;
  }

  async set(values) {
    let res = await simpleFetch({
      url: "retail/user/coverage",
      method: "POST",
      body: {
        ...{ id_user: values.id },
        ...values,
        ...{ checked: values.coverage },
      },
    });
    return res;
  }
}

import { authAction } from "./actions";
import AuthModel from "../../models/auth-model/AuthModel";

const reducerAuth = (state = new AuthModel({}), action) => {
  switch (action.type) {
    case authAction.setUser:
      return (state = new AuthModel(action.payload));
    case authAction.delUser:
      return (state = new AuthModel({}));
    default:
      return state;
  }
};

export default reducerAuth;

import React, { useEffect, useState } from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import { Fields } from "../../../components/fields/Fields";
import * as Yup from "yup";
import { CustomerAPI } from "../../../api/customer";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import { hapusSeparator } from "../../../services/separator-harga";
import store from "../../../redux/store";

export default function FormEdit(params) {
  const history = useHistory();
  let sales = store.getState().auth;
  let salesName = sales.userProfile.nama_karyawan;
  const [initialData, setInitialData] = useState(params.dataFromModel);

  const [fields, setFields] = useState([]);
  const [yup, setYup] = useState(Yup.object().shape({}));

  useEffect(() => {
    let items = [];
    let yup = {};
    initialData.getEditable({ isForm: true }).then((val) =>
      val.map((item) => {
        items = [...items, item];
        setFields(items);
        yup[item.dataField] = item.validation;
        setYup(Yup.object().shape(yup));
        return null;
      })
    );
  }, [initialData]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialData}
      validationSchema={yup}
      onSubmit={async () => {
        let sendingData = initialData;

        if ("nilai_limit" in sendingData) {
          sendingData = {
            ...initialData,
            nilai_limit: parseInt(hapusSeparator(initialData.nilai_limit)),
            nilai_top : parseInt(initialData.nilai_top),
            id_sales : sales.id,
            nama_sales : salesName
          };
        }
        console.log(sendingData);
        if (initialData.id === 0) {
          await new CustomerAPI().set(sendingData);

        } else {
          await new CustomerAPI().edit(sendingData);
        }
        history.goBack();
      }}
    >
      {(props) => {
        return (
          <AnimatedCard>
            <CardHeader title="Tambah Customer">
              <CardHeaderToolbar>
                <button
                  type="button"
                  className="btn btn-secondary mx-1"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-arrow-left"></i>
                  Kembali
                </button>
                <button
                  type="button"
                  className="btn btn-primary mx-1"
                  onClick={() => props.handleSubmit()}
                >
                  <i className="fa fa-save"></i>
                  Simpan
                </button>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              <Form className="form form-label-right">
                <div className="row">
                  {fields.map((field, i) => {
                    return (
                      <div key={i} className="form-group col-lg-6">
                        <Fields
                          {...field}
                          setFieldValue={props.setFieldValue}
                        />
                      </div>
                    );
                  })}
                  {/* <div className="form-group col-lg-6">
                    <Fields name="ktp" type="image" label="KTP" />
                  </div>
                  <div className="form-group col-lg-6">
                    <Fields name="kk" type="image" label="Kartu Keluarga" />
                  </div> */}
                </div>
              </Form>
            </CardBody>
          </AnimatedCard>
        );
      }}
    </Formik>
  );
}

import { getDefaultMiddleware } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import persistReducer from "./reducers";
import { createStore } from "redux";
import { applyMiddleware } from "redux";

const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }),
];

const store = createStore(persistReducer, {}, applyMiddleware(...middleware));

export const persistor = persistStore(store);

export default store;

import React, { useState, useMemo, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import Model from "../../../models/pesanan-pembelian-model/PesananPembelianModel";
import { dispatcher } from "../../../redux/dispatcher";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import moment from "moment";
import { PesananPembelianAPI } from "../../../api/pesanan-pembelian";

export default function ListBatalPembelian() {
  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const pageName = "Batal Pesanan Pembelian";
  const [startDate, setStartDate] = useState(
    moment(new Date())
      .startOf("month")
      .format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [search, setSearch] = useState("");

  async function loadData() {
    let datas = await new PesananPembelianAPI().getBatal();
    let simpleTableModel = new SimpleTableModel({readOnly: true});
    let searchParams = ["no_transaksi", "total", "kode"];
    let filtered = datas.data.filter((filter) => {
      let dateFormat = moment(filter.master.created_at).format("YYYY-MM-DD");
      let dateFilter = dateFormat >= startDate && dateFormat <= endDate;
      return searchParams.some((item) => {
        return (
          dateFilter &&
          // console.log(filter.master.toSupplier.nama)
          filter.master[item]
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())
        );
      });
    });
    // let filtered = datas.filter((filters) =>
    // filters.master.is_approved ===  parseInt(filter.value)
    // );
    filtered.forEach((item, i) => {
      let dataModel = new Model(item);
      if (item.master.status == 1)
        simpleTableModel.add({ 
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: (null),
        });
    });

    setColumntable(await simpleTableModel.getColumnHeaders());
    setDatatable(await simpleTableModel.getDatatables());
  }

  useEffect(() => {
    dispatcher.pesanan_pembelian.list();
  }, [startDate, endDate]);

  useEffect(() => {
    loadData();
  }, [dispatcher.pesanan_pembelian.getList()]);

  return (
    <>
      <AnimatedCard>
        <CardHeader title={pageName}>
        </CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Mulai </label>
              <input
                name={"tanggal_awal"}
                type={"date"}
                value={startDate}
                className={"form-control"}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  loadData()
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Akhir </label>
              <input
                name={"tanggal_akhir"}
                type={"date"}
                value={endDate}
                className={"form-control"}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  loadData()
                }}
              />
            </div>
            <div className="col-lg-3" style={{ marginTop: "45px" }}>
              <input
                type={"text"}
                className={"form-control"}
                placeholder="Cari Data ..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  dispatcher.pesanan_pembelian.list();
                }}
              />
            </div>
          </div>
          <SimpleTable data={datatable} columns={columntable} />
        </CardBody>
      </AnimatedCard>
    </>
  );
}

import * as Yup from "yup";
import { ProdukAPI } from "../../api/produk";
import store from "../../redux/store";
import { dispatcher } from "../../redux/dispatcher";

export default class StokRequestDetailModel {
  constructor(
    data = {
      id: 0,
      id_stok_transfer: 0,
      id_perusahaan: store.getState().auth.id_perusahaan,
      id_produk: 0,
      qty: 0,
      id_user: store.getState().auth.id,
      deskripsi: "",
      status: 1,
      status_validasi: 0,
      created_at: "",
      update_at: "",
      toProduk: {
        nama: "",
        kode: "",
        persediaan_total: "",
      },
      persediaan_total: 0,
      cabang_ref: 0,
    }
  ) {
    this.id = data.id;
    this.id_stok_transfer = data.id_stok_transfer;
    this.id_perusahaan = data.id_perusahaan;
    this.id_produk = data.id_produk;
    this.nama_produk = data.toProduk.nama;
    this.kode_produk = data.toProduk.kode;
    this.qty = data.qty;
    this.id_user = data.id_user;
    this.deskripsi = data.deskripsi;
    this.status = data.status;
    this.status_validasi = data.status_validasi;
    this.created_at = data.created_at;
    this.update_at = data.update_at;
    this.persediaan_total = data.toProduk.persediaan_total;
    this.cabang_ref = data.cabang_ref;
  }

  nulldata = {
    id: 0,
    id_stok_transfer: 0,
    id_perusahaan: store.getState().auth.id_perusahaan,
    id_produk: 0,
    nama_produk: "",
    kode_produk: "",
    qty: 0,
    id_user: store.getState().auth.id,
    deskripsi: "",
    status: 1,
    created_at: "",
    update_at: "",
    status_validasi: "",
    toProduk: {
      nama: "",
      kode:"",
    },
    persediaan_total: 0,
  };

  async getEditable(params = { pageName: "", isForm: false }) {
    const getProdukDetail = async (id) => {
      return await new ProdukAPI().getSingle(id).then((res) => {
        return res.data;
      });
    };

    let produk_options = [];
    if (params.isForm) {
      let datas = await new ProdukAPI().getAll().then((res) => {
        return res.data
      })
      let filtered = datas.filter((fil) => {
        let data = fil.id_perusahaan === this.cabang_ref ? fil : "";
        return data
      });
      filtered.map((data, i) => {
        return produk_options = [
          ...produk_options,
          { id: data.id, text: data.nama, label: `${data.nama} || ${data.toProdukSatuan.nama}`, value: parseInt(data.id) },
        ]
      })
    }

    return [
      {
        validation: Yup.number()
          .typeError("")
          .required("Produk harus diisi"),
        dataField: "id_produk",
        label: "Produk",
        type: "select2",
        options: produk_options,
        defaultValue: {label: this.nama_produk},
        onChange: async (e, setFieldValue) => {
          this.id_produk = e ? e.value : "";
          // setFieldValue("dpp", "Memuat...");
          // setFieldValue("persediaan_total", "Memuat...");
          // if (e.target.value !== 0)
            getProdukDetail(e.value).then((val) => {
              this.nama_produk = val.nama
              this.kode_produk = val.kode
                // e.target.options[e.target.options.selectedIndex].text;
              this.persediaan_total = val.persediaan_total;
              setFieldValue("persediaan_total", this.persediaan_total);
            });
          // return (this.id_produk = parseInt(e.target.value));
        },
        value: this.nama_produk,
      },
      {
        validation: Yup.string(),
        dataField: "persediaan_total",
        label: "Stok Tersedia",
        type: "disabled-text",
        onChange: (e, setFieldValue) => {
          this.persediaan_total = e.target.value
        },
        value: this.persediaan_total,
        invisibleOnTable: true,
      },
      {
        validation: Yup.number()
          .integer()
          .positive()
          .typeError("QTY tidak valid")
          .required("QTY harus diisi"),
        dataField: "qty",
        label: "QTY",
        type: "number",
        value: this.qty,
        onChange: (e, setFieldValue) => {
          this.qty = parseInt(e.target.value);
          if (this.qty > this.persediaan_total) {
            dispatcher.snackbar.show(
              "Perhatian",
              "QTY lebih besar dari persediaan",
              "warning"
            );
          }
        },
      },
      {
        validation: Yup.string(),
        dataField: "deskripsi",
        label: "Deskripsi",
        type: "textarea",
        value: this.deskripsi,
        onChange: (e) => (this.deskripsi = e.target.value),
        invisibleOnTable: true,
      },
    ];
  }
}

import XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";

export class export_excel
{
  // Get Sheet Data Export To Excel
  getSheetData ( data, header )
  {
    var fields = Object.keys( data[ 0 ] === undefined ? data : data[ 0 ] );
    var sheetData = data.map( function ( row )
    {
      return fields.map( function ( fieldName )
      {
        return row[ fieldName ] ? row[ fieldName ] : "";
      } );
    } );
    sheetData.unshift( header );
    return sheetData;
  }
  // Export To Excell
  async saveAsExcel ( record )
  {
    const { headerTitle, title1, title2, headerField, headerField2, recordData, recordData2, totalSaldoAwal, totalSaldoAkhir, footerSubTotal, footerSubTotal1, columnWidth, centerAlign, wrapText, numberFormatRp, fileName } = record;
    XlsxPopulate.fromBlankAsync().then( async ( workbook ) =>
    {
      //** --------------------------- START: TABLE 2 ------------------------------------------------- */
      const sheetData1 = this.getSheetData( recordData, headerField );
      const totalColumns1 = sheetData1[ 0 ].length;
      const endColumnAbjad1 = String.fromCharCode( 64 + totalColumns1 );
      const sheet = workbook.sheet( 0 );
      let EndColumnData = recordData.length + recordData2.length + 50;
      sheet.range( `A1:Z${ EndColumnData}` ).style( {
        fill: "FFFFFF",
      } );
      // --------------------------- COLUMN WIDTH -------------------------------------------------
      for ( let i = 0; i < columnWidth.length; i++ )
      {
        const abjad = String.fromCharCode( 65 + i );
        sheet.column( abjad ).width( columnWidth[ i ] );
      }
      // --------------------------- HEADER -------------------------------------------------
      // Header
      const headerTitleColumn = sheet.range( `A1:${ endColumnAbjad1 }2` );
      headerTitleColumn.value( headerTitle );
      headerTitleColumn.style( {
        bold: true,
        horizontalAlignment: "center",
        verticalAlignment: "center",
        border: true
      } );
      headerTitleColumn.merged( true );
      let startColumn = 3;
      // --------------------------- TITLE 1 -------------------------------------------------
      if ( title1 !== "" )
      {
        let EndColumn = startColumn + 1;
        const titleColumn1 = sheet.range( `A${ startColumn }:${ endColumnAbjad1 }${ EndColumn }` );
        titleColumn1.value( title1 );
        titleColumn1.style( {
          bold: true,
          verticalAlignment: "center",
          border: true
        } );
        titleColumn1.merged( true );
        startColumn = EndColumn + 1;

      }
      // --------------------------- SALDO AWAL -------------------------------------------------
      if ( totalSaldoAwal !== false )
      {
        sheet.range( `A${ startColumn }:${ endColumnAbjad1 }${ startColumn }` ).style( {
          border: true
        } );
        const endColumnAbjad = String.fromCharCode( 64 + ( totalColumns1 - 1 ) );
        const saldoAwal = sheet.range( `A${ startColumn }:${ endColumnAbjad }${ startColumn }` );
        saldoAwal.value( "Saldo Awal" );
        saldoAwal.style( {
          bold: true,
          horizontalAlignment: "right",
          verticalAlignment: "center"
        } );
        saldoAwal.merged( true );
        const SaldoAwalTotal = sheet.range( `${ endColumnAbjad1 }${ startColumn }:${ endColumnAbjad1 }${ startColumn }` );
        SaldoAwalTotal.value( totalSaldoAwal );
        SaldoAwalTotal.style( {
          bold: true,
          numberFormat: "Rp#,###",
          horizontalAlignment: "right",
          verticalAlignment: "center"
        } );
        startColumn = startColumn + 1;
      }
      // --------------------------- HEADER FIELD RECORD TABLE 1 -------------------------------------------------
      const headerFieldSheet = sheet;
      headerFieldSheet.cell( `A${ startColumn }` ).value( sheetData1 );
      // Style Header Field Data
      headerFieldSheet.range( `A${ startColumn }:${ endColumnAbjad1 }${ startColumn }` ).style( {
        fill: "BFBFBF",
        horizontalAlignment: "center",
        verticalAlignment: "center",
        border: true
      } );
      const endColumnRecord = startColumn + ( sheetData1.length - 1 );
      startColumn = startColumn + 1;
      headerFieldSheet.range( `A${ startColumn }:${ endColumnAbjad1 }${ endColumnRecord }` ).style( {
        border: true
      } );
      //** Style: Center Align */
      for ( let i = 0; i < centerAlign.length; i++ )
      {
        headerFieldSheet.range( `${ centerAlign[ i ] }${ startColumn }:${ centerAlign[ i ] }${ endColumnRecord }` ).style( {
          horizontalAlignment: "center",
          verticalAlignment: "center",
        } );
      }
      //** Style: Wrap Text */
      for ( let i = 0; i < wrapText.length; i++ )
      {
        headerFieldSheet.range( `${ wrapText[ i ] }${ startColumn }:${ wrapText[ i ] }${ endColumnRecord }` ).style( {
          wrapText: true,
          verticalAlignment: "center",
        } );
      }
      //** Style: Format Number : Rupiah */
      for ( let i = 0; i < numberFormatRp.length; i++ )
      {
        headerFieldSheet.range( `${ numberFormatRp[ i ] }${ startColumn }:${ numberFormatRp[ i ] }${ endColumnRecord }` ).style( {
          numberFormat: "Rp#,###",
          horizontalAlignment: "right",
          verticalAlignment: "center",
        } );
      }
      startColumn = endColumnRecord;
      // --------------------------- FOOTER TABLE 1 SUBTOTAL -------------------------------------------------
      if ( footerSubTotal.length > 0 )
      {
        startColumn = startColumn + 1;
        sheet.range( `A${ startColumn }:${ endColumnAbjad1 }${ startColumn }` ).style( {
          border: true
        } );
        const endColumnAbjad = String.fromCharCode( 64 + ( totalColumns1 - footerSubTotal.length ) );
        const subTotalText = sheet.range( `A${ startColumn }:${ endColumnAbjad }${ startColumn }` );
        subTotalText.value( "Sub Total" );
        subTotalText.style( {
          bold: true,
          horizontalAlignment: "right",
          verticalAlignment: "center"
        } );
        // Total Value
        subTotalText.merged( true );
        for ( let i = 0; i < footerSubTotal.length; i++ )
        {
          const totalColums = totalColumns1 - footerSubTotal.length;
          const endColumnAbjad2 = String.fromCharCode( 65 + ( totalColums + i ) );
          const subTotal = sheet.range( `${ endColumnAbjad2 }${ startColumn }:${ endColumnAbjad2 }${ startColumn }` );
          subTotal.value( footerSubTotal[ i ] );
          subTotal.style( {
            bold: true,
            numberFormat: "Rp#,###",
            horizontalAlignment: "right",
            verticalAlignment: "center"
          } );
        }
        startColumn = startColumn + 2;
      }
      // --------------------------- SALDO AWAL -------------------------------------------------
      if ( totalSaldoAkhir !== false )
      {
        sheet.range( `A${ startColumn }:${ endColumnAbjad1 }${ startColumn }` ).style( {
          border: true
        } );
        const endColumnAbjad = String.fromCharCode( 64 + ( totalColumns1 - 1 ) );
        const saldoAwal = sheet.range( `A${ startColumn }:${ endColumnAbjad }${ startColumn }` );
        saldoAwal.value( "Saldo Akhir" );
        saldoAwal.style( {
          bold: true,
          horizontalAlignment: "right",
          verticalAlignment: "center"
        } );
        saldoAwal.merged( true );
        const SaldoAwalTotal = sheet.range( `${ endColumnAbjad1 }${ startColumn }:${ endColumnAbjad1 }${ startColumn }` );
        SaldoAwalTotal.value( totalSaldoAkhir );
        SaldoAwalTotal.style( {
          bold: true,
          numberFormat: "Rp#,###",
          horizontalAlignment: "right",
          verticalAlignment: "center"
        } );
        startColumn = startColumn + 1;
      }
      //** --------------------------- END: TABLE 2 ------------------------------------------------- */
      //** --------------------------- START: Table 2 ------------------------------------------------- */
      if ( title2 !== "" )
      {
        let EndColumn = startColumn + 1;
        const titleColumn1 = sheet.range( `A${ startColumn }:${ endColumnAbjad1 }${ EndColumn }` );
        titleColumn1.value( title2 );
        titleColumn1.style( {
          bold: true,
          verticalAlignment: "center",
          border: true
        } );
        titleColumn1.merged( true );
        startColumn = EndColumn + 1;

      }
      // --------------------------- HEADER FIELD RECORD TABLE 2 -------------------------------------------------
      if ( headerField2.length > 0 )
      {
        const sheetData2 = this.getSheetData( recordData2, headerField2 );
        const totalColumns2 = sheetData2[ 0 ].length;
        const endColumnAbjad2 = String.fromCharCode( 64 + totalColumns2 );
        const headerFieldSheet2 = sheet;
        headerFieldSheet2.cell( `A${ startColumn }` ).value( sheetData2 );
        // Style Header Field Data
        headerFieldSheet2.range( `A${ startColumn }:${ endColumnAbjad2 }${ startColumn }` ).style( {
          fill: "BFBFBF",
          horizontalAlignment: "center",
          verticalAlignment: "center",
          border: true
        } );
        const endColumnRecord2 = startColumn + ( sheetData2.length - 1 );
        startColumn = startColumn + 1;
        headerFieldSheet2.range( `A${ startColumn }:${ endColumnAbjad2 }${ endColumnRecord2 }` ).style( {
          border: true
        } );
        //** Style: Center Align */
        for ( let i = 0; i < centerAlign.length; i++ )
        {
          headerFieldSheet.range( `${ centerAlign[ i ] }${ startColumn }:${ centerAlign[ i ] }${ endColumnRecord2 }` ).style( {
            horizontalAlignment: "center",
            verticalAlignment: "center",
          } );
        }
        //** Style: Wrap Text */
        for ( let i = 0; i < wrapText.length; i++ )
        {
          headerFieldSheet.range( `${ wrapText[ i ] }${ startColumn }:${ wrapText[ i ] }${ endColumnRecord2 }` ).style( {
            wrapText: true,
            verticalAlignment: "center",
          } );
        }
        //** Style: Format Number : Rupiah */
        for ( let i = 0; i < numberFormatRp.length; i++ )
        {
          headerFieldSheet.range( `${ numberFormatRp[ i ] }${ startColumn }:${ numberFormatRp[ i ] }${ endColumnRecord2 }` ).style( {
            numberFormat: "Rp#,###",
            horizontalAlignment: "right",
            verticalAlignment: "center",
          } );
        }
        startColumn = endColumnRecord2;
        // --------------------------- FOOTER TABLE 2 SUBTOTAL -------------------------------------------------
        if ( footerSubTotal1.length > 0 )
        {
          startColumn = startColumn + 1;
          sheet.range( `A${ startColumn }:${ endColumnAbjad2 }${ startColumn }` ).style( {
            border: true
          } );
          const endColumnAbjad = String.fromCharCode( 64 + ( totalColumns2 - footerSubTotal1.length ) );
          const subTotalText = sheet.range( `A${ startColumn }:${ endColumnAbjad }${ startColumn }` );
          subTotalText.value( "Sub Total" );
          subTotalText.style( {
            bold: true,
            horizontalAlignment: "right",
            verticalAlignment: "center"
          } );
          // Total Value
          subTotalText.merged( true );
          for ( let i = 0; i < footerSubTotal1.length; i++ )
          {
            const totalColums = totalColumns2 - footerSubTotal1.length;
            const endColumnAbjad2 = String.fromCharCode( 65 + ( totalColums + i ) );
            const subTotal = sheet.range( `${ endColumnAbjad2 }${ startColumn }:${ endColumnAbjad2 }${ startColumn }` );
            subTotal.value( footerSubTotal1[ i ] );
            subTotal.style( {
              bold: true,
              numberFormat: "Rp#,###",
              horizontalAlignment: "right",
              verticalAlignment: "center"
            } );
          }
        }
      }
      //** --------------------------- END: Table 2 ------------------------------------------------- */
      return workbook.outputAsync().then( ( res ) =>
      {
        saveAs( res, `${ fileName }.xlsx` );
      } );
    } );
  }
}

import { getIdPerusahaan, simpleFetch } from "./api";

export class AkunAksesAPI {
  async getAll() {
    let res = await simpleFetch({
      url: "retail/akun/akses?id_perusahaan=" + getIdPerusahaan(),
      method: "GET",
    });
    return res;
  }

  async getSingle(id) {
    let res = await simpleFetch({
      url: "retail/akun/akses?id=" + id,
      method: "GET",
    });
    return res;
  }

  async set(values) {
    let values2send = {};
    if (values.id_parent === 0) {
      values2send = {
        ...values,
        id_parent: null,
      };
    } else {
      values2send = {
        ...values,
      };
    }
    let res = await simpleFetch({
      url: "retail/akun/akses",
      method: "POST",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values2send,
      },
    });
    return res;
  }

  async edit(values) {
    let values2send = {};
    if (values.id_parent === 0) {
      values2send = {
        ...values,
        id_parent: null,
      };
    } else {
      values2send = {
        ...values,
      };
    }
    let res = await simpleFetch({
      url: "retail/akun/akses",
      method: "PUT",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values2send,
      },
    });
    return res;
  }

  async delete(id) {
    let res = await simpleFetch({
      url: "retail/akun/akses",
      method: "DELETE",
      body: {
        id: id,
      },
    });
    return res;
  }
}

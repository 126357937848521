/* eslint-disable array-callback-return */
import React from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import { Button } from "react-bootstrap";
import SimpleAlert from "../../../components/alert/alert";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { OrganisasiAPI } from "../../../api/organisasi";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Fields } from "../../../components/fields/Fields";
import OrganisasiModel from "../../../models/organisasi-model/OrganisasiModel";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import { hapusSeparator } from "../../../services/separator-harga";
import store from "../../../redux/store";
import Select2 from "react-select";
import { FormsFields } from "../../../components/forms-fields/FormsFields";

export default class ApprovalAkhir extends React.Component {
  constructor(props) {
    super(props);
    this.initialData = new OrganisasiModel();
    this.api = new OrganisasiAPI();
    this.simpleTableModel = new SimpleTableModel();
    this.pageName = "Approval Akhir";
    this.state = {
      value: 2,
      modal: {
        show: false,
        initialData: this.initialData,
        isEdit: false,
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
    this.validation = Yup.object().shape({});
    this.columntable = [{ dataField: "", text: "" }];
    this.datatable = [{ id: 0, nama: "" }];
    this.fields = [];
    this.user = store.getState().auth.alias_role;
    this.options = [
      { value: 2, label: "Sudah Approve Awal" },
      { value: 3, label: "Sudah Approve Akhir" },
    ];
  }

  async componentDidMount() {
    await this.loadData();
    let yup = {};
    await this.initialData.getEditable().then((values) =>
      values.map((item) => {
        yup[item.dataField] = item.validation;
      })
    );
    this.validation = Yup.object().shape(yup);
  }

  async loadData() {
    let datas = await this.api.getAll();
    this.simpleTableModel = new SimpleTableModel();
    let filtered = datas.data.filter((filter) =>
      filter.is_approved === this.state.value ? this.state.value : ""
    );
    // Tampilkan data yang sudah difilter
    filtered.map((item, i) => {
      console.log(this.user);
      let dataModel = new OrganisasiModel(item);
      console.log('isi item', item);
      this.simpleTableModel.add({
        id: item.id,
        no: i + 1,
        dataModel: dataModel,
        actions: (
          <span>
            {item.is_approved >= 3 ? (
              <Button
                size="sm"
                className="mx-1"
                variant="primary"
              >
                <i className="fas fa-user-check"></i>
                Sudah Approve
              </Button>
            ) : (
              <Button
                size="sm"
                className="mx-1"
                variant="warning"
                onClick={() => {
                  this.handleModal.edit(dataModel);
                }}
              >
                <i className="fas fa-user-lock"></i>
                Belum Approve
              </Button>
            )}
            <Button
              size="sm"
              className="mx-1"
              variant="outline-danger"
              onClick={() => {
                this.handleAlert.show(item.id);
                this.loadData();
              }}
            >
              Hapus
            </Button>
          </span>
        ),
      });
    });

    this.datatable = await this.simpleTableModel.getDatatables();
    this.columntable = await this.simpleTableModel.getColumnHeaders();
    this.setState({ ...this.state });
  }

  handleModal = {
    loadFields: async () => {
      this.fields = [];
      await this.initialData
        .getEditable({ pageName: this.pageName, isForm: true })
        .then((values) => {
          values.map((item, i) => {
            return (this.fields = [...this.fields, item]);
          });
        });
    },
    tambah: async () => {
      let modal = { ...this.state.modal };
      modal.show = true;
      modal.title = `${this.pageName} Baru`;
      modal.isEdit = false;
      await this.handleModal.loadFields();
      this.setState({ modal });
    },
    edit: async (dataFromModel) => {
      let modal = { ...this.state.modal };
      modal.show = true;
      modal.title = `Edit ${this.pageName}`;
      modal.initialData = dataFromModel;
      modal.isEdit = true;
      await this.handleModal.loadFields();
      this.setState({ modal });
    },
    hide: () => {
      let modal = { ...this.state.modal };
      modal.show = false;
      modal.initialData = this.initialData;
      this.setState({ modal });
    },
  };

  handleAlert = {
    show: (id) => {
      let alert = { ...this.state.alert };
      alert.show = true;
      alert.activeId = id;
      this.setState({ alert: alert });
    },
    hide: () => {
      let alert = { ...this.state.alert };
      alert.show = false;
      alert.activeId = null;
      this.setState({ alert: alert });
    },
    confirmed: async () => {
      await this.api.delete(this.state.alert.activeId);
      this.handleAlert.hide();
      this.loadData();
    },
  };

  render() {
    return (
      <>
        <AnimatedCard>
          <SimpleAlert
            title="Hapus Data"
            content="Anda yakin hapus data ini?"
            show={this.state.alert.show}
            onConfirmed={this.handleAlert.confirmed}
            onHide={this.handleAlert.hide}
          />

          <CardHeader title={this.pageName}>
            <CardHeaderToolbar>
              {/* <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.handleModal.tambah()}
              >
                <i className="fa fa-plus"></i>
                Tambah {this.pageName}
              </button> */}
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <div className="col-lg-3">
              <Select2
                name={"Filter"}
                placeholder={"--- Status Approval ---"}
                size="sm"
                options={this.options}
                value={
                  this.options
                    ? this.options.find(
                      (option) => option.value === this.state.value
                    )
                    : ""
                }
                isClearable={true}
                onChange={(e) => {
                  this.setState(
                    e ? { value: e.value } : []
                  );
                  this.loadData();
                }}
              />
            </div>
            <SimpleTable data={this.datatable} columns={this.columntable} />
          </CardBody>
        </AnimatedCard>
        <Formik
          enableReinitialize={true}
          initialValues={this.state.modal.initialData}
          validationSchema={this.validation}
          onSubmit={async (values, { resetForm }) => {
            if ("nilai_limit" in values) {
              values = {
                ...values,
                is_approved: 3,
                nilai_limit: parseInt(hapusSeparator(values.nilai_limit)),
              };
            }
            // if (this.state.modal.isEdit) {
              await this.api.approve(values);
            // } else if (!this.state.modal.isEdit) {
            //   await this.api.set(values);
            // }
            this.handleModal.hide();
            resetForm();
            this.loadData();
          }}
        >
          {({ handleSubmit, setFieldValue, setFieldTouched,
            touched,
            errors,
            values }) => (
            <Modal
              show={this.state.modal.show}
              onHide={this.handleModal.hide}
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modal.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <div className="row">
                    {this.fields.map((field, i) => (
                      ['nama',
                        'kode',
                        'alamat',
                        'telepon',
                        'no_rekening',
                        'nama_rekening',
                        'nama_bank',
                        'nama',
                        'kode',
                        'alamat',
                        'telepon',
                        'no_rekening',
                        'nama_rekening',
                        'nama_bank',
                        'npwp',].includes(field.dataField) ?
                        <div key={i} className="form-group col-lg-6">
                          <Fields
                            {...field}
                            setFieldValue={setFieldValue}
                          />
                        </div> :
                        ['nilai_limit'].includes(field.dataField) ?
                          <div key={i} className="form-group col-lg-6">
                            <Fields {...field}
                              type="currency"
                              setFieldValue={setFieldValue} />
                          </div> : ['nilai_top'].includes(field.dataField) ?
                            <div key={i} className="form-group col-lg-6">
                              <Fields {...field}
                                type="number"
                                setFieldValue={setFieldValue} />
                            </div> :
                            ""
                    ))}
                    {/* {this.field.map((field, i) => (
                      console.log(field)
                    ))} */}
                    <div className={"col-lg-12"}>
                      <div className={"form-group row"}>
                        <div className={"col-lg-6"}>
                          {/* No. KTP */}
                          <FormsFields
                            id={"file_ktp"}
                            type={"image"}
                            label={"KTP"}
                            className={"col-lg-12"}
                            style={{ marginTop: "20px" }}
                            paramsFormik={{
                              setFieldValue,
                              setFieldTouched,
                              touched,
                              errors,
                              values
                            }}
                          />
                        </div>
                        <div className={"col-lg-6"}>
                          {/* File SITU/SIUP */}
                          <FormsFields
                            id={"file_situ_siup"}
                            type={"image"}
                            label={"SITU/SIUP"}
                            className={"col-lg-12"}
                            style={{ marginTop: "20px" }}
                            paramsFormik={{
                              setFieldValue,
                              setFieldTouched,
                              touched,
                              errors,
                              values
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={"col-lg-12"}>
                      <div className={"form-group row"}>
                        <div className={"col-lg-6"}>
                          {/* File NPWP */}
                          <FormsFields
                            id={"file_npwp"}
                            type={"image"}
                            label={"NPWP"}
                            className={"col-lg-12"}
                            style={{ marginTop: "20px" }}
                            paramsFormik={{
                              setFieldValue,
                              setFieldTouched,
                              touched,
                              errors,
                              values
                            }}
                          />
                        </div>
                        <div className={"col-lg-6"}>
                          {/* File PKS */}
                          <FormsFields
                            id={"file_pks"}
                            type={"image"}
                            label={"PKS(Perjanjian Kerjasama)"}
                            className={"col-lg-12"}
                            style={{ marginTop: "20px" }}
                            paramsFormik={{
                              setFieldValue,
                              setFieldTouched,
                              touched,
                              errors,
                              values
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={this.handleModal.hide}
                  className="btn btn-light btn-elevate"
                >
                  Batal
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  Approve & Simpan
                </button>
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      </>
    );
  }
}

import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import ApprovalAccountingPengeluaranKas from "./approvalAccounting";
import DetailPengeluaranKas from "../list/detail";
import React from "react";
import { dispatcher } from "../../../../../redux/dispatcher";
export default function IndexApprovalAccountingPengeluaranKas ()
{
  const history = useHistory();
  const active = dispatcher[ 'kasir_kas' ].getPengeluaranKas();
  return (
    <Switch>
      <Route path="/kasir/approval-accounting-kas/approval-accounting">
        <ApprovalAccountingPengeluaranKas />
      </Route>
      { active[ 'transaksi' ][ 'id' ] === 0 && (
        <Redirect to="/kasir/approval-accounting-kas/approval-accounting" />
      ) }
      <Route path="/kasir/approval-accounting-kas/detail">
        <DetailPengeluaranKas history={ history } />
      </Route>
      <Redirect to="/kasir/approval-accounting-kas/approval-accounting" />
    </Switch>
  );
}

import moment from "moment/moment";
import { separatorHarga } from "../../../services/separator-harga";
import bukuBesarDetailModel from "./BukuBesarDetailModel ";
export default class bukuBesarModel
{
  constructor(
    data = {
      buku_besar: {
        id: 0,
        id_akun: "",
        id_clearing: "",
        id_perusahaan: "",
        posisi: "",
        tanggal: "",
        cabang: "",
        bank: "",
        deskripsi: "",
        no_transaksi: "",
        no_transaksi_ref: "",
        debet: 0,
        kredit: 0,
        jumlah: 0,
        saldo: 0,
        status: 0,
      },
      detail: [],
      detailRef: [],
    }
  )
  {
    const { id, id_akun, id_clearing, id_perusahaan, posisi, tanggal, cabang, bank, deskripsi, no_transaksi, no_transaksi_ref, debet, kredit, jumlah, saldo, status } = data[ 'buku_besar' ];
    this[ 'buku_besar' ] = {
      id: id,
      id_akun: id_akun,
      id_perusahaan: id_perusahaan,
      id_clearing: id_clearing,
      posisi: posisi,
      tanggal: tanggal,
      cabang: cabang,
      bank: bank,
      deskripsi: deskripsi,
      no_transaksi: no_transaksi,
      no_transaksi_ref: no_transaksi_ref,
      debet: debet,
      kredit: kredit,
      jumlah: jumlah,
      saldo: saldo,
      status: status,
    };
    this[ 'detail' ] = [];
    this[ 'detailRef' ] = data[ 'detailRef' ];
    this.initDetails( data[ 'detail' ] );
  }
  initDetails ( detail )
  {
    if ( detail.length > 0 )
    {
      detail.forEach( ( item ) =>
      {
        this[ 'detail' ] = [
          ...this[ 'detail' ],
          new bukuBesarDetailModel( {
            ...item,
          } ),
        ];
      } );
    }
  }
  async getdata ()
  {
    const { id_akun, id_clearing, id_perusahaan, posisi, tanggal, cabang, bank, deskripsi, no_transaksi, no_transaksi_ref, debet, kredit, saldo } = this[ 'buku_besar' ];
    return [ {
      id: "id_clearing",
      title: "Clearing",
      label: id_clearing,
      omit: true
    }, {
      id: "id_perusahaan",
      title: "Perusahaan",
      label: id_perusahaan,
      omit: true
    }, {
      id: "posisi",
      title: "Posisi",
      label: posisi,
      omit: true
    }, {
      id: "tanggal",
      title: "Tanggal",
      label: moment( tanggal ).format( "DD-MM-YYYY" ),
      center: true,
      sortable: true,
      minWidth: "17%",
    }, {
      id: "cabang",
      title: "Cabang",
      label: cabang,
      center: true,
      sortable: true,
      minWidth: "20%",
    }, {
      id: "bank",
      title: "Bank",
      label: bank,
      sortable: true,
      minWidth: "40%",
      omit: id_akun === "" ? false : !bank,
    }, {
      id: "deskripsi",
      title: "Keterangan",
      label: deskripsi,
      sortable: true,
      minWidth: "60%",
    }, {
      id: "no_transaksi",
      title: "No. Transaksi",
      label: no_transaksi,
      center: true,
      sortable: true,
      minWidth: "25%",
    }, {
      id: "no_transaksi_ref",
      title: "No. Transaksi Ref",
      label: no_transaksi_ref,
      center: true,
      sortable: true,
      minWidth: "25%",
    }, {
      id: "debet",
      title: "Debet",
      label: debet < 0 ? `Rp. -${ separatorHarga( debet.toString() ) }` : separatorHarga( debet.toString(), "Rp. " ),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "kredit",
      title: "Kredit",
      label: kredit < 0 ? `Rp. -${ separatorHarga( kredit.toString() ) }` : separatorHarga( kredit.toString(), "Rp. " ),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "saldo",
      title: "Saldo",
      label: saldo < 0 ? `Rp. -${ separatorHarga( saldo.toString() ) }` : separatorHarga( saldo.toString(), "Rp. " ),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "detailRef",
      title: "Detail",
      label: this[ 'detailRef' ],
      right: true,
      sortable: false,
      minWidth: "13%",
      omit: true
    } ];
  }
}

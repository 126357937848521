const initialValues = {
  active: null,
  list: [],
};

export const kasirBankCollectionAction = {
  list: "LIST_BANK_COLLECTION",
  active: "ACTIVE_BANK_COLLECTION",
  resetActive: "RESET_ACTIVE_BANK_COLLECTION",
};

const reducer = (state = initialValues, action) => {
  let states;
  switch (action.type) {
    case kasirBankCollectionAction.list:
      states = state;
      states.list = action.payload;
      return (state = states);
    case kasirBankCollectionAction.active:
      states = state;
      states.active = action.payload;
      return (state = states);
    case kasirBankCollectionAction.resetActive:
      states = state;
      states.active = null;
      return (state = states);
    default:
      return state;
  }
};

export default reducer;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Fields } from "../../../components/fields/Fields";
import { Typography } from "@material-ui/core";
import { dispatcher } from "../../../redux/dispatcher";
import { InboundDetail } from "./inbound_detail";
import { Alert } from "react-bootstrap";
import { PenerimaanAPI } from "../../../api/penerimaan";
import PenerimaanPembelianModel from "../../../models/penerimaan-pembelian-model/PenerimaanPembelianModel";
import { ButtonValidasi } from "./button_validasi";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";

export default function Inbound({ history }) {
  const [initialData, setInitialData] = useState(dispatcher.penerimaan.get());
  const [fields, setFields] = useState([]);
  const [yup, setYup] = useState(Yup.object().shape({}));
  const [showInfo, setShowInfo] = useState(true);
  const invoice = initialData.master.kode === "" ? false : true;
  const detail = initialData.detail.map((data) => data.qty !== data.qty_inbound)

  const saveTransaksi = async (values) => {
    let send = {
      id: initialData.master.id,
      kode: values.kode,
      tanggal: initialData.master.tanggal,
    };
    await new PenerimaanAPI().edit(send).then((val) => {
      if (val.status)
        dispatcher.snackbar.show(
          "Berhasil",
          "No invoice berhasil disimpan",
          "primary"
        );
      else
        dispatcher.snackbar.show(
          "Gagal",
          "No invoice gagal disimpan",
          "danger"
        );
    });
  };

  const getList = useMemo(() => {
    return dispatcher.penerimaan.get();
  }, [dispatcher.penerimaan.get()]);

  useEffect(() => {
    setInitialData(getList);
    console.log('InitialData',initialData);
    const loadFields = async () => {
      let fieldsLoaded = [];
      let yup = {};
      await initialData
        .getEditable({ pageName: "", isForm: true })
        .then((values) => {
          values.map((item, i) => {
            fieldsLoaded = [...fieldsLoaded, item];
            yup[item.dataField] = item.validation;
          });
        });
      setFields(fieldsLoaded);
      setYup(Yup.object().shape(yup));
    };
    loadFields();
  }, [getList]);

  return (
    <>
      {showInfo && (
        <Alert variant="primary" onClose={() => setShowInfo(false)} dismissible>
          <i className="fa fa-info-circle text-white ml-2 mr-4"></i>
          <span>
            Pastikan nomor transaksi dan tanggal penerimaan pembelian telah
            diinput sebelum melakukan Inbound stock.
          </span>
        </Alert>
      )}

      <Formik
        enableReinitialize={true}
        initialValues={initialData.master}
        validationSchema={yup}
        onSubmit={async (values, { resetForm }) => { }}
      >
        {({ handleSubmit, setFieldValue, validateForm, values }) => (
          <AnimatedCard>
            <CardHeader title="Inbound">
              <CardHeaderToolbar>
                <button
                  type="button"
                  className="btn btn-secondary mx-1"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-arrow-left"></i>
                  Kembali
                </button>
                <ButtonValidasi dataModel={initialData} detail={detail}/>
                {/* {invoice === false && (
                  <button
                    type="button"
                    className="btn btn-primary mx-1"
                    onClick={async () => {
                      handleSubmit();
                      if (values.kode !== null || values.kode !== "") {
                        await saveTransaksi(values);
                        await new PenerimaanAPI()
                          .getSingle(initialData.master.id)
                          .then((response) => {
                            dispatcher.penerimaan.set(
                              new PenerimaanPembelianModel(response.data)
                            );
                          });
                      }
                    }}
                  >
                    <i className="fa fa-save"></i>
                    Simpan No. Transaksi
                  </button>
                )} */}
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              <Form className="form form-label-right">
                <div className="row">
                  {fields.length > 0 &&
                    fields.map((field, i) => (
                      <div key={i} className="form-group col-lg-6">
                        <Fields {...field} setFieldValue={setFieldValue} />
                      </div>
                    ))}
                </div>
              </Form>
              <span className="d-flex align-items-center justify-content-between mt-4">
                <Typography variant="h6">Detail Item</Typography>
              </span>
              <InboundDetail
                data={initialData}
                validateForm={validateForm}
                handleSubmit={handleSubmit}
              />
            </CardBody>
          </AnimatedCard>
        )}
      </Formik>
    </>
  );
}

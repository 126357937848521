import { Button, Modal } from "react-bootstrap";

import React, { useState } from "react";
import ButtonLoad from "../button-loading/buttonLoad";

export default function SimpleAlert(params) {
  const { show, onHide, title, variant, labelHide, content, onConfirmed, label } = params;
  const [loading, setloading] = useState(false);
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby={"contained-modal-title-vcenter"}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{content}</Modal.Body>
        <Modal.Footer>
          <Button size={"sm"} variant={"secondary"} onClick={onHide}>
            {labelHide ?? "Batal"}
          </Button>
          <ButtonLoad
            label={label ?? "Hapus"}
            disabled={loading}
            pending={loading}
            classNameIcon={!label ? "fas fa-trash" : "fas fa-save"}
            className={`btn btn-light-${variant ?? "danger"} font-weight-bold mr-2 mx-1 btn-sm`}
            onClick={() => {
              setloading(true);
              setTimeout(() => {
                if (onConfirmed !== null) onConfirmed();
                setloading(false);
              }, 150);
            }}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}

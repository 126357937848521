import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import ApprovalCustomerAkhirList from "./list";
import ApprovalCustomerAkhirForms from './forms';
import React from "react";
import { dispatcher } from "../../../../redux/dispatcher";

export default function ApprovalCustomerAkhirIndex() {
   const history = useHistory();
   const active = dispatcher['notification'].getApprovalCustomerAkhir();
   return (
      <Switch>
         <Route path={"/notifications/approval-customer-akhir/list"}>
            <ApprovalCustomerAkhirList />
         </Route>
         <Route path={"/notifications/approval-customer-akhir/add-form"}>
            <ApprovalCustomerAkhirForms history={history} isEdit={false} />
         </Route>
         {active['id'] === 0 && (
            <Redirect to={"/notifications/approval-customer-akhir/list"} />
         )}
         <Route path={"/notifications/approval-customer-akhir/edit-form"}>
            <ApprovalCustomerAkhirForms history={history} isEdit={true} />
         </Route>
         <Redirect to="/notifications/approval-customer-akhir/list" />
      </Switch>
   );
}

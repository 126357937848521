import React, { Suspense, lazy } from "react";

import { Redirect, Route, Switch } from "react-router-dom";

import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";

import { BuilderPage } from "./pages/BuilderPage";
import { DashboardPage } from "./pages/dashboard/DashboardPage";
import GrafikPembelian from "./pages/grafik/grafik-pembelian";
import GrafikPenjualan from "./pages/grafik/grafik-penjualan";
import DaftarCustomerLimitStatus from "./pages/master/customer-limit-status";
import SalesCustomer from "./pages/master/customer-sales";
import PengajuanLimit from "./pages/master/pengajuan-limit";
import ApprovalPengajuanLimit from "./pages/master/pengajuan-limit-approval";
import DaftarStokGudang from "./pages/master/stok-gudang";
import StokKeluarPenjualan from "./pages/master/stok-keluar-penjualan";
import StokMasukPembelian from "./pages/master/stok-masuk-pembelian";
import ApprovalTransferReq from "./pages/master/stok-transfer-approval";
import DaftarTransferReq from "./pages/master/stok-transfer-daftar";
import KirimTransferReq from "./pages/master/stok-transfer-kirim";
import StokTransferReq from "./pages/master/stok-transfer-req";
import TerimaTransferReq from "./pages/master/stok-transfer-terima";
import ApprovalPesananPembelian from "./pages/pembelian/approval-pesanan-pembelian";
import ListApprovalReturPembelian from "./pages/pembelian/approval-retur-pembelian";
import Penerimaan from "./pages/pembelian/penerimaan";
import PesananPembelian from "./pages/pembelian/pesanan-pembelian";
import ListReturPembelian from "./pages/pembelian/retur-pembelian";
import InvoicePembelian from "./pages/pembelian/tambah-invoice-pembelian";
import ApprovalAdminPesananPenjualan from "./pages/penjualan/approval-admin-pesanan-penjualan";
import ApprovalAwalPesananPenjualan from "./pages/penjualan/approval-awal-pesanan-penjualan";
import ListApprovalReturPenjualan from "./pages/penjualan/approval-retur-penjualan";
import Penjualan from "./pages/penjualan/penjualan";
import PesananPenjualan from "./pages/penjualan/pesanan-penjualan";
import ListReturPenjualan from "./pages/penjualan/retur-penjualan";
import InvoicePenjualan from "./pages/penjualan/tambah-invoice-penjualan";
import DaftarKartuHutang from "./pages/transaksi/kartu_hutang";
import DaftarKartuPiutang from "./pages/transaksi/kartu_piutang";
import BukuBesar from "./pages/akuntansi/buku-besar/bukuBesar";
import CekSaldo from "./pages/akuntansi/cek-saldo/cekSaldo";
import CekSelisih from "./pages/akuntansi/cek-selisih/cekSelisih";
import Clearing from "./pages/akuntansi/clearing/clearing";
import LabaRugi from "./pages/akuntansi/laba_rugi/labaRugi";
import NeracaSaldo from "./pages/akuntansi/neraca_saldo/neracaSaldo";
import CekTransaksi from "./pages/akuntansi/transaksi/cekTransaksi";
import UpdateTransaksi from "./pages/akuntansi/transaksi/updateTransaksi";
import KonfigurasiMenu from "./pages/konfigurasi-sistem/konfigurasi-menu/konfigurasiMenu";
import Role from "./pages/konfigurasi-sistem/role/Role";
import AkunAkses from "./pages/master/akun-akses/AkunAkses";
import AkunJenis from "./pages/master/akun-jenis/AkunJenis";
import Akun from "./pages/master/akun/akun";
import ApprovalAwal from "./pages/master/approval-customer-1/ApprovalAwal";
import ApprovalAkhir from "./pages/master/approval-customer-2/ApprovalAkhir";
import BankJenis from "./pages/master/bank-jenis/BankJenis";
import Bank from "./pages/master/bank/Bank";
import Gudang from "./pages/master/gudang/Gudang";
import InventoriJenis from "./pages/master/inventori-jenis/InventoriJenis";
import Jurnal from "./pages/master/jurnal/Jurnal";
import KontakJenis from "./pages/master/kontak-jenis/KontakJenis";
import LaporanKartuHutang from "./pages/master/laporan/kartu-hutang";
import LaporanKartuPiutang from "./pages/master/laporan/kartu-piutang";
import LaporanStok from "./pages/master/laporan/laporan-stok";
import LaporanAR from "./pages/master/laporan/pembayaran-ar-sales";
import LaporanPembelian from "./pages/master/laporan/pembelian";
import LaporanPenjualan from "./pages/master/laporan/penjualan";
import MarginHargaJual from "./pages/master/margin-harga-jual/MarginHargaJual";
import Merek from "./pages/master/merek/Merek";
import MutasiSales from "./pages/master/mutasi-sales/MutasiSales";
import Pajak from "./pages/master/pajak/Pajak";
import ProdukKategori from "./pages/master/produk-kategori/ProdukKategori";
import ProdukSatuan from "./pages/master/produk-satuan/ProdukSatuan";
import Produk from "./pages/master/produk/Produk";
import ProdukDetail from "./pages/master/produk/ProdukDetail";
import ProdukHarga from "./pages/master/produk/ProdukHarga";
import Profile from "./pages/master/profile/Profile";
import StokAwal from "./pages/master/stok-awal/StokAwal";
import StokGudang from "./pages/master/stok-gudang/StokGudang";
import Supplier from "./pages/master/supplier/Supplier";
import TargetPenjualan from "./pages/master/target-penjualan/TargetPenjualan";
import Tax from "./pages/master/tax/Tax";
import TransaksiJenis from "./pages/master/transaksi-jenis/transaksiJenis";
import TransaksiRef from "./pages/master/transaksi-ref/transaksiRef";
import TransaksiTipe from "./pages/master/transaksi-tipe/transaksiTipe";
import User from "./pages/master/user/User";
import ApprovalCustomerAkhirIndex from "./pages/notifications/approval-customer/customer-akhir";
import ApprovalCustomerAwalIndex from "./pages/notifications/approval-customer/customer-awal";
import NotificationApprovalPesananPembelian from "./pages/notifications/approval-pesanan-pembelian/approvalPesananPembelian";
import ApprovalAdminPesananPenjualanIndex from "./pages/notifications/approval-pesanan-penjualan/approval-admin";
import ApprovalManagerPesananPenjualanIndex from "./pages/notifications/approval-pesanan-penjualan/approval-manager";
import ApprovalSupervisorPesananPenjualanIndex from "./pages/notifications/approval-pesanan-penjualan/approval-supervisor";
import ListBatalPembelian from "./pages/pembelian/batal-pembelian/list";
import ApprovalPesananPenjualan from "./pages/penjualan/approval-pesanan-penjualan/index";
import ListBatalSpb from "./pages/penjualan/batal-spb/list";
import TambahPesananPenjualan from "./pages/penjualan/tambah-pesanan-penjualan/form";
import JurnalBalik from "./pages/akuntansi/jurnal/jurnal-balik/jurnalBalik";
import JurnalUmum from "./pages/akuntansi/jurnal/jurnal-umum/index";
import SaldoAwalAkun from "./pages/akuntansi/saldo-awal/saldo-awal-akun/saldoAwalAkun";
import SaldoAwalBank from "./pages/akuntansi/saldo-awal/saldo-awal-bank/saldoAwalBank";
import LaporanBankCollection from "./pages/kasir/laporan/laporan-bank-collection/laporanBankCollection";
import LaporanBankOperasional from "./pages/kasir/laporan/laporan-bank-operasional/laporanBankOperasional";
import LaporanKas from "./pages/kasir/laporan/laporan-kas/laporanKas";
import CustomerSales from "./pages/master/kontak/customer-sales/index";
import DataCustomer from "./pages/master/kontak/data-customer/index";
import Organisasi from "./pages/master/kontak/organisasi/index";
import AgingScheduleHutang from "./pages/master/laporan/aging-schedule-hutang/agingScheduleHutang";
import AgingSchedulePiutang from "./pages/master/laporan/aging-schedule-piutang/agingSchedulePiutang";
import HutangBeredar from "./pages/master/laporan/hutang-beredar/hutangBeredar";
import PiutangBeredar from "./pages/master/laporan/piutang-beredar/piutangBeredar";
import SisaAR from "./pages/master/laporan/presentase-sisa-ar/sisaAR";
import LaporanTargetPenjualan from "./pages/master/laporan/target-penjualan/laporanTargetPenjualan";
import TambahPesananPembelian from "./pages/pembelian/tambah-pesanan-pembelian/form/form";
import IndexFormPenerimaanBankCollection from "./pages/kasir/bank-collection/penerimaan/form/index";
import IndexListPenerimaanBankCollection from "./pages/kasir/bank-collection/penerimaan/list/index";
import IndexApprovalAccountingPengeluaranBankCollection from "./pages/kasir/bank-collection/pengeluaran/approval-accounting/index";
import IndexApprovalAdministrasiPengeluaranBankCollections from "./pages/kasir/bank-collection/pengeluaran/approval-administrasi/index";
import IndexFormPengeluaranBankCollection from "./pages/kasir/bank-collection/pengeluaran/form/index";
import IndexListPengeluaranBankCollection from "./pages/kasir/bank-collection/pengeluaran/list/index";
import IndexMutasiPengeluaranBankCollection from "./pages/kasir/bank-collection/pengeluaran/mutasi/index";
import IndexFormPenerimaanBankOperasional from "./pages/kasir/bank-operasional/penerimaan/form/index";
import IndexListPenerimaanBankOperasional from "./pages/kasir/bank-operasional/penerimaan/list/index";
import IndexApprovalAccountingPengeluaranBankOperasional from "./pages/kasir/bank-operasional/pengeluaran/approval-accounting/index";
import IndexApprovalAdministrasiPengeluaranBankOperasional from "./pages/kasir/bank-operasional/pengeluaran/approval-administrasi/index";
import IndexFormPengeluaranBankOperasional from "./pages/kasir/bank-operasional/pengeluaran/form/index";
import IndexListPengeluaranBankOperasional from "./pages/kasir/bank-operasional/pengeluaran/list/index";
import IndexPengeluaranBankOperasional from "./pages/kasir/bank-operasional/pengeluaran/mutasi/index";
import IndexFormPenerimaanKas from "./pages/kasir/kas/penerimaan/form/index";
import IndexListPenerimaanKas from "./pages/kasir/kas/penerimaan/list/index";
import IndexApprovalAccountingPengeluaranKas from "./pages/kasir/kas/pengeluaran/approval-accounting/index";
import IndexApprovalAdministrasiPengeluaranKas from "./pages/kasir/kas/pengeluaran/approval-administrasi/index";
import IndexFormPengeluaranKas from "./pages/kasir/kas/pengeluaran/form/index";
import IndexListPengeluaranKas from "./pages/kasir/kas/pengeluaran/list/index";
import IndexMutasiPengeluaranKas from "./pages/kasir/kas/pengeluaran/mutasi/index";
import StokOpnameRoute from "./pages/master/stok-opname/route";
import ApprovalOpname from "./pages/master/stok-opname/approval";
import StokBagMasuk from "./pages/master/stok-bag-masuk/route";
import AprvlBagMasuk from "./pages/master/stok-bag-masuk/approval";
import BagKeluarRoute from "./pages/master/stok-bag-keluar/route";
import ApprovalBagKeluar from "./pages/master/stok-bag-keluar/approval";
const GoogleMaterialPage = lazy( () => import( "./modules/GoogleMaterialExamples/GoogleMaterialPage" ) );
const ReactBootstrapPage = lazy( () => import( "./modules/ReactBootstrapExamples/ReactBootstrapPage" ) );
const ECommercePage = lazy( () => import( "./modules/ECommerce/pages/eCommercePage" ) );
const UserProfilepage = lazy( () => import( "./modules/UserProfile/UserProfilePage" ) );

// const MasteritemPage = lazy(() =>
//   import("./modules/MasterItem/MasterItemPage")
// );import BukuBesar from './pages/akuntansi/buku-besar/bukuBesar';

export default function MainPage ( { menu } )
{
  function initialMenu ()
  {
    if ( menu.active.menu != null )
    {
      // return menu.active.menu;
      return "/user-profile";
    } else
    {
      if ( menu.menus.length <= 0 )
      {
        return "/user-profile";
      } else
      {
        return "/user-profile";
        // return menu.menus[0].child[0].path;
      }
    }
  }

  return (
    <Suspense fallback={ <LayoutSplashScreen /> }>
      <Switch>
        <Redirect exact from="/" to={ initialMenu() } />
        <ContentRoute path="/dashboard" component={ DashboardPage } />
        <ContentRoute path="/builder" component={ BuilderPage } />
        <Route path="/google-material" component={ GoogleMaterialPage } />
        <Route path="/react-bootstrap" component={ ReactBootstrapPage } />
        <Route path="/e-commerce" component={ ECommercePage } />
        <Route path="/user-profile" component={ UserProfilepage } />
        <Route path="/profile" component={ Profile } />
        {/* Konfigurasi Sistem */ }
        <Route path="/konfigurasi-sistem-menu" component={ KonfigurasiMenu } />
        <Route path="/konfigurasi-sistem-role" component={ Role } />


        <Route path="/master-data-gudang" component={ Gudang } />

        {/* Stok Request */ }
        <Route path="/transfer-stok" component={ StokTransferReq } />
        <Route path="/daftar-transfer-stok" component={ DaftarTransferReq } />
        <Route path="/kirim-transfer-stok" component={ KirimTransferReq } />
        <Route path="/terima-transfer-stok" component={ TerimaTransferReq } />
        <Route path="/approval-transfer-stok" component={ ApprovalTransferReq } />
        {/* Stok */ }

        <Route path="/stok-awal" component={ StokAwal } />
        <Route path="/stok-gudang" component={ DaftarStokGudang } />
        <Route path="/stok-masuk-pembelian" component={ StokMasukPembelian } />
        <Route path="/stok-keluar-penjualan" component={ StokKeluarPenjualan } />
        <Route path="/stok-opname" component={ StokOpnameRoute } />
        <Route path="/approval-stok-opname" component={ ApprovalOpname } />
        <Route path="/bag-masuk" component={ StokBagMasuk } />
        <Route path="/approval-bag-masuk" component={ AprvlBagMasuk } />
        <Route path="/bag-keluar" component={ BagKeluarRoute } />
        <Route path="/approval-bag-keluar" component={ ApprovalBagKeluar } />

        {/* Pengajuan Limit */ }
        <Route path="/master/pengajuan-limit" component={ PengajuanLimit } />
        <Route path="/approval-limit-customer" component={ ApprovalPengajuanLimit } />


        {/* Master */ }
        <Route path="/master-data-customer" component={ DataCustomer } />
        <Route path="/master-organisasi" component={ Organisasi } />
        <Route path="/master-customer-sales" component={ CustomerSales } />
        <Route path="/customer-limit-status" component={ DaftarCustomerLimitStatus } />

        {/* Pajak */ }
        <Route path="/master/pajak" component={ Pajak } />

        {/* Target Penjualan */ }
        <Route path="/master/target-penjualan" component={ TargetPenjualan } />

        {/* Mutasi Sales */ }
        <Route path="/master/mutasi-sales" component={ MutasiSales } />
        {/* Target Penjualan */}
        <Route path="/master/target-penjualan" component={TargetPenjualan} />

        {/* Mutasi Sales */}
        <Route path="/master/mutasi-sales" component={MutasiSales} />


        <Route path="/master-data-produk" component={ Produk } />
        <Route path="/detail-produk" component={ ProdukDetail } />
        <Route path="/daftar-harga" component={ ProdukHarga } />
        <Route path="/master-data-kategori-produk" component={ ProdukKategori } />
        <Route path="/master-data-satuan-produk" component={ ProdukSatuan } />
        <Route path="/master-data-kontak-jenis" component={ KontakJenis } />
        <Route path="/master-data-supplier" component={ Supplier } />
        <Route path="/approval-customer" component={ ApprovalAwal } />
        <Route path="/approval-customer-akhir" component={ ApprovalAkhir } />
        <Route path="/customer-sales" component={ SalesCustomer } />
        <Route path="/master-data-inventori-jenis" component={ InventoriJenis } />
        <Route path="/master-data-tax" component={ Tax } />
        <Route path="/master-data-akun-jenis" component={ AkunJenis } />
        <Route path="/master-data-akun" component={ Akun } />
        <Route path="/master-data-user-login" component={ User } />
        <Route path="/master/transaksi/tipe" component={ TransaksiTipe } />
        <Route path="/master/transaksi/jenis" component={ TransaksiJenis } />
        <Route path="/master/transaksi/referensi" component={ TransaksiRef } />
        <Route path="/master-data-bank" component={ Bank } />
        <Route path="/master/bank-jenis" component={ BankJenis } />
        <Route path="/master-data-jurnal" component={ Jurnal } />
        <Route path="/master-akun-akses" component={ AkunAkses } />
        <Route path="/margin-harga-jual" component={ MarginHargaJual } />
        <Route path="/merek" component={ Merek } />

        {/* Laporan */ }
        <Route path="/laporan-pembelian" component={ LaporanPembelian } />
        <Route path="/laporan-penjualan" component={ LaporanPenjualan } />

        {/* Menu Kasir */ }
        {/* Start: Penerimaan Kas */ }
        <Route path="/kasir/tambah-penerimaan-kas" component={ IndexFormPenerimaanKas } />
        <Route path="/kasir/daftar-penerimaan-kas" component={ IndexListPenerimaanKas } />
        {/* End: Penerimaan Kas */ }
        {/* Start: Pengeluaran Kas */ }
        <Route path="/kasir/tambah-pengeluaran-kas" component={ IndexFormPengeluaranKas } />
        <Route path="/kasir/daftar-pengeluaran-kas" component={ IndexListPengeluaranKas } />
        <Route path="/kasir/mutasi-pengeluaran-kas" component={ IndexMutasiPengeluaranKas } />
        <Route path="/kasir/approval-administrasi-kas" component={ IndexApprovalAdministrasiPengeluaranKas } />
        <Route path="/kasir/approval-accounting-kas" component={ IndexApprovalAccountingPengeluaranKas } />
        {/* End: Penerimaan Kas */ }
        {/* Penerimaan Bank Collection */ }
        <Route path="/kasir/tambah-penerimaan-bank-collection" component={ IndexFormPenerimaanBankCollection } />
        <Route path="/kasir/daftar-penerimaan-bank-collection" component={ IndexListPenerimaanBankCollection } />
        {/* Pengeluaran Bank Collection */ }
        <Route path="/kasir/tambah-pengeluaran-bank-collection" component={ IndexFormPengeluaranBankCollection } />
        <Route path="/kasir/daftar-pengeluaran-bank-collection" component={ IndexListPengeluaranBankCollection } />
        <Route path="/kasir/mutasi-pengeluaran-bank-collection" component={ IndexMutasiPengeluaranBankCollection } />
        <Route path="/kasir/approval-administrasi-bank-collection" component={ IndexApprovalAdministrasiPengeluaranBankCollections } />
        <Route path="/kasir/approval-accounting-bank-collection" component={ IndexApprovalAccountingPengeluaranBankCollection } />
        {/* Penerimaan Bank Operasional */ }
        <Route path="/kasir/tambah-penerimaan-bank-operasional" component={ IndexFormPenerimaanBankOperasional } />
        <Route path="/kasir/daftar-penerimaan-bank-operasional" component={ IndexListPenerimaanBankOperasional } />
        {/* Pengeluaran Bank Operasional */ }
        <Route path="/kasir/tambah-pengeluaran-bank-operasional" component={ IndexFormPengeluaranBankOperasional } />
        <Route path="/kasir/daftar-pengeluaran-bank-operasional" component={ IndexListPengeluaranBankOperasional } />
        <Route path="/kasir/mutasi-pengeluaran-bank-operasional" component={ IndexPengeluaranBankOperasional } />
        <Route path="/kasir/approval-administrasi-bank-operasional" component={ IndexApprovalAdministrasiPengeluaranBankOperasional } />
        <Route path="/kasir/approval-accounting-bank-operasional" component={ IndexApprovalAccountingPengeluaranBankOperasional } />
        {/* Laporan Kasir  */ }
        {/* Laporan Kas */ }
        <Route path="/kasir/laporan-kas" component={ LaporanKas } />
        <Route path="/kasir/laporan-bank-collection" component={ LaporanBankCollection } />
        <Route path="/kasir/laporan-bank-operasional" component={ LaporanBankOperasional } />

        {/* Menu Transaksi */ }
        {/* Pesanan Pembelian */ }
        <Route path="/tambah-pesanan-pembelian" component={ TambahPesananPembelian } />
        <Route path="/pembelian-pesanan-pembelian" component={ PesananPembelian } />
        <Route path="/approval-pesanan-pembelian" component={ ApprovalPesananPembelian } />
        <Route path="/batal-pesanan-pembelian" component={ ListBatalPembelian } />
        <Route path="/retur-pembelian" component={ ListReturPembelian } />
        <Route path="/approval-retur-pembelian" component={ ListApprovalReturPembelian } />
        <Route path="/tambah-invoice-pembelian" component={ InvoicePembelian } />
        <Route path="/pembelian-penerimaan" component={ Penerimaan } />
        <Route path="/kartu-hutang" component={ DaftarKartuHutang } />

        {/* Pesanan Penjualan (SPB) */ }
        <Route path="/tambah-pesanan-penjualan" component={ TambahPesananPenjualan } />
        <Route path="/batal-surat-pesanan-barang" component={ ListBatalSpb } />
        <Route path="/approval-pesanan-penjualan-awal" component={ ApprovalAwalPesananPenjualan } />
        <Route path="/approval-admin-pesanan-penjualan" component={ ApprovalAdminPesananPenjualan } />
        <Route path="/approval-pesanan-penjualan" component={ ApprovalPesananPenjualan } />
        <Route path="/tambah-penjualan" component={ InvoicePenjualan } />
        <Route path="/penjualan-pesanan-penjualan" component={ PesananPenjualan } />
        <Route path="/penjualan" component={ Penjualan } />
        <Route path="/retur-penjualan" component={ ListReturPenjualan } />
        <Route path="/approval-retur-penjualan" component={ ListApprovalReturPenjualan } />
        <Route path="/tambah-pesanan-pembelian" component={ TambahPesananPembelian } />
        <Route path="/approval-pesanan-pembelian" component={ ApprovalPesananPembelian } />
        <Route path="/pembelian-pesanan-pembelian" component={ PesananPembelian } />
        <Route path="/tambah-invoice-pembelian" component={ InvoicePembelian } />
        <Route path="/pembelian-penerimaan" component={ Penerimaan } />
        <Route path="/tambah-pesanan-penjualan" component={ TambahPesananPenjualan } />
        <Route path="/approval-pesanan-penjualan-awal" component={ ApprovalAwalPesananPenjualan } />
        <Route path="/approval-admin-pesanan-penjualan" component={ ApprovalAdminPesananPenjualan } />
        <Route path="/approval-pesanan-penjualan" component={ ApprovalPesananPenjualan } />
        <Route path="/tambah-penjualan" component={ InvoicePenjualan } />
        <Route path="/penjualan-pesanan-penjualan" component={ PesananPenjualan } />
        <Route path="/penjualan" component={ Penjualan } />
        <Route path="/kartu-piutang" component={ DaftarKartuPiutang } />

        {/* Laporan */ }
        <Route path="/laporan-piutang-beredar" component={ PiutangBeredar } />
        <Route path="/laporan/kartu-piutang" component={ LaporanKartuPiutang } />
        <Route path="/laporan-hutang-beredar" component={ HutangBeredar } />
        <Route path="/laporan/kartu-hutang" component={ LaporanKartuHutang } />
        <Route path="/laporan-aging-schedule-piutang" component={ AgingSchedulePiutang } />
        <Route path="/laporan-aging-schedule-hutang" component={ AgingScheduleHutang } />
        <Route path="/laporan/target-penjualan" component={ LaporanTargetPenjualan } />
        <Route path="/laporan-pembayaran-ar" component={LaporanAR} />
        <Route path="/presentase-sisa-ar" component={SisaAR} />
        <Route path="/laporan-stok" component={LaporanStok} />
        {/* Grafik */ }
        {/* Grafik Penjualan */ }
        <Route path="/laporan-performance-penjualan" component={ GrafikPenjualan } />
        <Route path="/laporan-performance-pembelian" component={ GrafikPembelian } />

        {/* Start: Akuntansi */ }
        {/* Jurnal Umum */ }
        <Route path="/akuntansi/jurnal-umum" component={ JurnalUmum } />
        <Route path="/akuntansi/jurnal-balik" component={ JurnalBalik } />
        {/* Clearing */ }
        <Route path="/akuntansi/clearing" component={ Clearing } />
        {/* Buku Besar */ }
        <Route path="/akuntansi/buku-besar" component={ BukuBesar } />
        {/* Transaksi */ }
        <Route path="/akuntansi/cek-transaksi" component={ CekTransaksi } />
        <Route path="/akuntansi/update-transaksi" component={ UpdateTransaksi } />
        {/* Cek Selisih */ }
        <Route path="/akuntansi/cek-selisih" component={ CekSelisih } />
        {/* Cek Saldo Akun */ }
        <Route path="/akuntansi/cek-saldo" component={ CekSaldo } />
        {/* Laba Rugi */ }
        <Route path="/akuntansi/laba-rugi" component={ LabaRugi } />
        {/* Neraca Saldo */ }
        <Route path="/akuntansi/neraca-saldo" component={ NeracaSaldo } />
        {/* Saldo Awal Akun & Bank */ }
        <Route path="/akuntansi/saldo-awal-akun" component={ SaldoAwalAkun } />
        <Route path="/akuntansi/saldo-awal-bank" component={ SaldoAwalBank } />
        {/* Begin: Notification */ }
        {/* Approval Pesanan Pembelian */ }
        <Route path="/notifications/approval-pesanan-pembelian" component={ NotificationApprovalPesananPembelian } />
        {/* Approval Customer */ }
        <Route path="/notifications/approval-customer-awal" component={ ApprovalCustomerAwalIndex } />
        <Route path="/notifications/approval-customer-akhir" component={ ApprovalCustomerAkhirIndex } />
        {/* Approval Pesanan Penjualan */ }
        <Route path="/notifications/approval-supervisor-pesanan-penjualan" component={ ApprovalSupervisorPesananPenjualanIndex } />
        <Route path="/notifications/approval-admin-pesanan-penjualan" component={ ApprovalAdminPesananPenjualanIndex } />
        <Route path="/notifications/approval-manager-pesanan-penjualan" component={ ApprovalManagerPesananPenjualanIndex } />
        {/* End: Notification */ }
        {/* Saldo Awal Bank */ }
        <Redirect to="error/error-v3" />
      </Switch>
    </Suspense>
  );
}

import { getIdPerusahaan, simpleFetch } from "./api";

export class OrganisasiAPI {
  async getAll() {
    let res = await simpleFetch({
      url: "retail/kontak/organisasi_approve?id_perusahaan=" + getIdPerusahaan(),
      method: "GET",
    });
    return res;
  }

  async getSingle(id) {
    let res = await simpleFetch({
      url: "retail/kontak/organisasi?id=" + id,
      method: "GET",
    });
    return res;
  }

  async set(values) {
    let res = await simpleFetch({
      url: "retail/kontak/organisasi",
      method: "POST",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values,
      },
    });
    return res;
  }

  async edit(values) {
    let res = await simpleFetch({
      url: "retail/kontak",
      method: "PUT",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values,
      },
    });
    return res;
  }

  async approve(values) {
    let res = await simpleFetch({
      url: "retail/kontak/organisasi/approve",
      method: "POST",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values,
      }
    });
    return res;
  }

  async delete(id) {
    let res = await simpleFetch({
      url: "retail/kontak",
      method: "DELETE",
      body: {
        id: id,
      },
    });
    return res;
  }
}

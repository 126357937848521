import React, { useEffect, useState } from "react";
import { Snackbar, SnackbarContent } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import { Spinner } from "react-bootstrap";
import { Typography } from "@material-ui/core";

export function LoadingDialog(params) {
  const Transition = (props) => <Slide {...props} direction="up" />;
  const [show, setShow] = useState({ open: false, Transition });

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    setShow({
      ...show,
      open: false,
    });
  }

  useEffect(() => {
    setShow({
      ...show,
      open: params.show,
    });
  }, [params.show]);

  const style = {
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(250,250,250,0)",
    position: "absolute",
    top: "0",
    left: "0",
    display: show.open ? "flex" : "none",
    zIndex: 100000,
    justifyContent: "center",
    alignItems: "end",
    padding: "150px",
  };

  return (
    <>
      <div style={style}></div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={show.open}
        onClose={handleClose}
        TransitionComponent={show.Transition}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#21c5bd",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
            margin: 0,
          }}
          aria-describedby="client-snackbar"
          message={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ paddingRight: 7 }}>
                <Spinner animation="border" size="sm" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
              <Typography variant="body1" style={{ margin: 0 }}>
                Tunggu, sedang memuat data.....
              </Typography>
            </div>
          }
        />
      </Snackbar>
    </>
  );
}

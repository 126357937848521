import * as Yup from "yup";
import { separatorHarga, hapusSeparator } from "../../services/separator-harga";
import PenerimaanPembelianDetailModel from "../../models/penerimaan-pembelian-detail-model/PenerimaanPembelianDetailModel";
import moment from "moment";
import { SupplierAPI } from "../../api/supplier";

export default class LaporanPembelianModel {
    constructor(
        data = {
            master: {
                id: 0,
                id_pembelian: 0,
                id_perusahaan: 0,
                kode: "",
                no_transaksi: "",
                tanggal: "",
                jatuh_tempo: 0,
                qty_total: 0,
                hpp_total: 0,
                dpp_total: 0,
                diskon_total: 0,
                subtotal: 0,
                pajak_total: 0,
                total: 0,
                terbayar: 0,
                tersisa: 0,
                status_bayar: 0,
                status_invoice: 0,
                status_validasi: 0,
                status_retur: 0,
                id_user: 0,
                id_pemasok: 0,
                id_kontak: 0,
                deskripsi: "",
                status: 0,
                created_at: 0,
                updated_at: 0,
                toSupplier: {
                    nama: "",
                },
            },
            detail: [],
        }
    ) {
        this.master = {
            id: data.master.id,
            id_pembelian: data.master.id_pembelian,
            id_perusahaan: data.master.id_perusahaan,
            kode: data.master.kode,
            no_transaksi: data.master.no_transaksi,
            tanggal: data.master.tanggal,
            jatuh_tempo: data.master.jatuh_tempo,
            qty_total: data.master.qty_total,
            hpp_total: separatorHarga(data.master.hpp_total.toString()),
            dpp_total: separatorHarga(data.master.dpp_total.toString()),
            diskon_total: separatorHarga(data.master.diskon_total.toString()),
            subtotal: separatorHarga(data.master.subtotal.toString()),
            pajak_total: separatorHarga(data.master.pajak_total.toString()),
            total: separatorHarga(data.master.total.toString()),
            terbayar: separatorHarga(data.master.terbayar.toString()),
            tersisa: separatorHarga(data.master.tersisa.toString()),
            status_bayar: data.master.status_bayar,
            status_invoice: data.master.status_invoice,
            status_validasi: data.master.status_validasi,
            id_user: data.master.id_user,
            id_pemasok: data.master.id_pemasok,
            nama_supplier: data.master.toSupplier.nama,
            id_kontak: data.master.id_kontak,
            deskripsi: data.master.deskripsi,
            status: data.master.status,
            status_retur: data.master.status_retur,
            created_at: data.master.created_at,
            updated_at: data.update_at,
        };
        this.detail = [];
        this.initDetails(data.detail);
    }

    initDetails(detail) {
        if (detail.length > 0)
            detail.forEach((item) => {
                let diskon_persen = (item.diskon / item.hpp_total) * 100;
                let pajak_persen = (item.pajak / item.subtotal) * 100;
                this.detail = [
                    ...this.detail,
                    new PenerimaanPembelianDetailModel({
                        ...item,
                        id_penerimaan_pembelian: this.master.id,
                        diskon_persen: diskon_persen,
                        pajak_persen: pajak_persen,
                    }),
                ];
            });
    }

    nulldata = {
        master: {
            id: 0,
            id_pembelian: 0,
            id_perusahaan: 0,
            kode: "",
            no_transaksi: "",
            tanggal: "",
            jatuh_tempo: 0,
            qty_total: 0,
            hpp_total: 0,
            dpp_total: 0,
            diskon_total: 0,
            subtotal: 0,
            pajak_total: 0,
            total: 0,
            terbayar: 0,
            tersisa: 0,
            status_bayar: 0,
            status_invoice: 0,
            status_validasi: 0,
            id_user: 0,
            id_pemasok: 0,
            id_kontak: 0,
            deskripsi: "",
            status: 0,
            created_at: 0,
            updated_at: 0,
        },
        detail: [],
    };

    reset() {
        this(this.nulldata);
    }

    hitungTotalan() {
        let qty_total = 0;
        let hpp_total = 0;
        let dpp_total = 0;
        let diskon_total = 0;
        let subtotal = 0;
        let pajak_total = 0;
        let total = 0;
        if (this.detail.length > 0) {
            this.detail.forEach((item) => {
                if (item.status !== 0) {
                    qty_total = parseInt(qty_total) + parseInt(item.qty);
                    hpp_total =
                        parseInt(hpp_total) + parseInt(hapusSeparator(item.hpp_total));
                    dpp_total =
                        parseInt(dpp_total) + parseInt(hapusSeparator(item.dpp_total));
                    diskon_total =
                        parseInt(diskon_total) + parseInt(hapusSeparator(item.diskon));
                    subtotal =
                        parseInt(subtotal) + parseInt(hapusSeparator(item.subtotal));
                    pajak_total =
                        parseInt(pajak_total) + parseInt(hapusSeparator(item.pajak));
                    total = parseInt(total) + parseInt(hapusSeparator(item.total));
                }
            });
        }
        this.master.qty_total = qty_total;
        this.master.hpp_total = separatorHarga(hpp_total.toString());
        this.master.dpp_total = separatorHarga(dpp_total.toString());
        this.master.diskon_total = separatorHarga(diskon_total.toString());
        this.master.subtotal = separatorHarga(subtotal.toString());
        this.master.pajak_total = separatorHarga(pajak_total.toString());
        this.master.total = separatorHarga(total.toString());
    }

    removeSeparator() {
        this.master.hpp_total = parseInt(hapusSeparator(this.master.hpp_total));
        this.master.dpp_total = parseInt(hapusSeparator(this.master.dpp_total));
        this.master.diskon_total = parseInt(
            hapusSeparator(this.master.diskon_total)
        );
        this.master.pajak_total = parseInt(hapusSeparator(this.master.pajak_total));
        this.master.subtotal = parseInt(hapusSeparator(this.master.subtotal));
        this.master.total = parseInt(hapusSeparator(this.master.total));
        this.master.terbayar = parseInt(hapusSeparator(this.master.terbayar));
        this.master.tersisa = parseInt(hapusSeparator(this.master.tersisa));
    }

    removeAllDetailSeparator() {
        this.detail.forEach((item) => item.removeSeparator());
    }

    handleDetail = {
        set: async (item) => {
            item.id_pembelian = this.master.id;
            item.id_pemasok = this.master.id_pemasok;
            this.detail = [...this.detail, item];
            this.hitungTotalan();
        },
        del: async (id) => {
            this.detail[this.detail.map((e) => e.id).indexOf(id)].status = 0;
            this.hitungTotalan();
        },
        reset: async () => {
            this.detail = [];
            this.hitungTotalan();
        },
        edit: async (item) => {
            this.detail[this.detail.map((e) => e.id).indexOf(item.id)] = item;
            this.hitungTotalan();
        },
        getSingle: async (id) => this.detail.find((e) => e.id === id),
    };

    jatuh_tempo() {
        let target = new Date(this.master.tanggal)
        let val = this.valDate;
        this.master.jatuh_tempo = moment(target).add(val, 'days').format("YYYY-MM-DD")
    }


    async getEditable(params = { pageName: "", isForm: false }) {
        const getSupplierTop = async (id) => {
            return await new SupplierAPI()
                .getSingle(id)
                .then((res) => res.data.nilai_top);
        };

        return [
            {
                validation: Yup.string().required("Tanggal harus diisi"),
                dataField: "tanggal",
                label: "Tanggal Invoice",
                type: this.master.kode === "" ? "date" : "disabled-text",
                value: this.master.tanggal,
                onChange: (e) => {
                    this.master.tanggal = e.target.value
                    getSupplierTop(this.master.id_pemasok).then((val) => {
                        this.valDate = val;
                        this.jatuh_tempo();
                        console.log('cek id pemasok', this.master.id_pemasok);
                    })
                },
            },
            {
                validation: Yup.string().required("Jatuh tempo harus diisi"),
                dataField: "jatuh_tempo",
                label: "Jatuh Tempo",
                type: "disabled-text",
                value: this.master.jatuh_tempo,
                onChange: (e) => (this.master.jatuh_tempo = e.target.value),
                invisibleOnTable: true,
            },
            {
                validation: Yup.string().required("No Invoice harus diisi"),
                dataField: "kode",
                label: "No Invoice",
                type: this.master.kode === "" ? "text" : "disabled-text",
                value: this.master.kode === "" ? "(belum input no invoice)" : this.master.kode,
                onChange: (e) => {
                    this.master.kode = e.target.value;
                },
            },
            {
                validation: Yup.string().required("Supplier harus diisi"),
                dataField: "nama_supplier",
                label: "Supplier",
                type: "disabled-text",
                value: this.master.nama_supplier,
            },
            {
                validation: Yup.string(),
                dataField: "no_transaksi",
                label: "No Transaksi",
                type: "disabled-text",
                value: this.master.no_transaksi,
                onChange: (e) => { },
            },
            {
                validation: Yup.string().required("QTY harus diisi"),
                dataField: "qty_total",
                label: "QTY Total",
                type: "disabled-text",
                value: this.master.qty_total,
                onChange: (e) => (this.master.qty_total = e.target.value),
            },
            {
                validation: Yup.string(),
                dataField: "hpp_total",
                label: "Total Harga",
                type: "disabled-text",
                value: this.master.hpp_total,
                onChange: (e) => (this.master.hpp_total = e.target.value),
            },
            {
                validation: Yup.string(),
                dataField: "diskon_total",
                label: "Total Diskon",
                type: "disabled-text",
                value: this.master.diskon_total,
                onChange: (e) => (this.master.diskon_total = e.target.value),
                // invisibleOnTable: true,
            },
            {
                validation: Yup.string(),
                dataField: "subtotal",
                label: "Sub Total",
                type: "disabled-text",
                value: this.master.subtotal,
                onChange: (e) => (this.master.subtotal = e.target.value),
                // invisibleOnTable: true,
            },
            {
                validation: Yup.string(),
                dataField: "pajak_total",
                label: "Total Pajak",
                type: "disabled-text",
                value: this.master.pajak_total,
                onChange: (e) => (this.master.pajak_total = e.target.value),
                // invisibleOnTable: false,
            },
            {
                validation: Yup.string(),
                dataField: "total",
                label: "Total",
                type: "disabled-text",
                value: this.master.total,
                onChange: (e) => (this.master.total = e.target.value),
            },
        ];
    }
}

import React, { useEffect, useState } from 'react'
import store from '../../../../redux/store';
import { AnimatedCard } from '../../../../components/animated-card/animatedCard';
import { CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls';
import Select2 from "react-select";
import { ReactDatatable } from '../../../../components/react-datatable/reactDatatable';
import { separatorHarga } from '../../../../services/separator-harga';
import { PenerimaanAPI } from '../../../../api/penerimaan';
import { Button } from 'react-bootstrap';
import { saveAsExcel } from './helperExportExcel';

function HutangBeredar() {
    const title = 'Hutang Beredar';
    const API = new PenerimaanAPI();
    const [cabang, setCabang] = useState(store.getState().auth.id_perusahaan);
    const [options, setOptions] = useState([]);
    const [master, setMaster] = useState([]);
    const columns = [
        {
            name: 'Tanggal',
            selector: row => row.tglInv,
            sortable: true,
            minWidth: '10%'
        },
        {
            name: 'No Invoice',
            selector: row => row.noInvoice,
            sortable: true,
            minWidth: '15%'
        },
        {
            name: 'No Transaksi',
            selector: row => row.noTransaksi,
            sortable: true,
            minWidth: '20%'
        },
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
            minWidth: '20%'
        },
        {
            name: 'Jatuh Tempo',
            selector: row => row.jTempo,
            sortable: true,
            minWidth: '12%'
        },
        {
            name: 'Total',
            selector: row => separatorHarga(row.total.toString(), 'Rp.'),
            sortable: true,
            minWidth: '12%'
        },
        {
            name: 'Terbayar',
            selector: row => separatorHarga(row.terbayar.toString(), 'Rp.'),
            sortable: true,
            minWidth: '12%'
        },
        {
            name: 'Tersisa',
            selector: row => separatorHarga(row.tersisa.toString(), 'Rp.'),
            sortable: true,
            minWidth: '12%'
        },
    ];
    
    async function init(sendData) {
        let datas = await API.getAllHutang(sendData);
        console.log('Datas',datas);
        let data = [] 
        datas.map((v) => {
            let newData = {
                nama: v.master.nama_supplier,
                noTransaksi: v.master.no_transaksi,
                noInvoice: v.master.kode,
                tglInv: v.master.tanggal_invoice,
                jTempo: v.master.jatuh_tempo,
                total: v.master.total,
                terbayar: v.master.terbayar,
                tersisa: v.master.tersisa,
            }
         return data = [...data,newData]
        })
        setMaster(data)
    }

    const coverage = async () => {
        let opsi = [];
        await API.getCoverage().then((res) => {
            opsi = res.data.map((data) => ({
                label: data.text,
                text: data.text,
                value: data.id,
            }));
        });
        // options.push(options)
        setOptions(opsi);
    };

    useEffect(() => {
        coverage()
        let sendData = ({ cabang })
        init(sendData)
        console.log('Master', master);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cabang]);

    return (
        <>
        {console.log('Master',master)}
            <AnimatedCard>
                <CardHeader title="Laporan Hutang Beredar">
                    <CardHeaderToolbar>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <div className="d-flex justify-content-start">
                        <div className="col-lg-4" style={{ marginTop: "20px", zIndex:"5" }}>
                            <label> Pilih Perusahaan </label>
                            <Select2
                                name={"Cabang"}
                                placeholder={" --- Pilih Perusahaan --- "}
                                size="sm"
                                options={options}
                                isClearable={false}
                                value={
                                    options
                                        ? options.find(
                                            (data) => data.value === cabang
                                        )
                                        : ""
                                }
                                onChange={(e) => {
                                    setCabang(e ? e.value : []);
                                    // setFieldValue("cabang", e ? e.value : "");
                                }}
                            />
                        </div>
                        <div className="col-lg-4" style={{ marginTop: "45px", marginBottom: "0px" }}>
                            <Button
                                type="button"
                                onClick={async () => {
                                    saveAsExcel(master, title)
                                }}
                            >
                                <i className={"far fa-file-excel"}></i> {" Export .xls"}
                            </Button>
                        </div>
                    </div>
                    <ReactDatatable
                        columns={columns}
                        data={master}
                        dense
                        pagination
                        searchParameter={['nama','noInvoice']}
                        // actions={actionsMemo}
                    />
                </CardBody>
            </AnimatedCard>
        </>
    )
}

export default HutangBeredar
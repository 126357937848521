import { getIdPerusahaan, simpleFetch } from "./api";

export class StokAPI {
  async getAll() {
    let res = await simpleFetch({
      url: "retail/stok/awal?id_perusahaan=" + getIdPerusahaan(),
      method: "GET",
    });
    return res;
  }

  async set(values) {
    let res = await simpleFetch({
      url: "retail/stok/awal",
      method: "POST",
      body: {
        ...values,
      },
    });
    return res;
  }

  async edit(values) {
    let res = await simpleFetch({
      url: "retail/stok/awal",
      method: "PUT",
      body: {
        ...values,
      },
    });
    return res;
  }

  //** Start: Get Select Option */
  // Produk
  async getProduk(value) {
    let get = `&aksi=${value}&id_perusahaan=${getIdPerusahaan()}`;
    let res = await simpleFetch({
      url: "retail/select/master?alias=produk" + get,
      method: "GET",
    });
    return res;
  }
  // Produk
  async getGudang() {
    let get = `&id_perusahaan=${getIdPerusahaan()}`;
    let res = await simpleFetch({
      url: "retail/select/master?alias=gudang" + get,
      method: "GET",
    });
    return res;
  }
  //** End: Get Select Option */
  
  async laporanStok(values) {
    let params = "id_perusahaan="+values.cabang;
    // let startTgl = "&tanggalAwal="+ values.date.toString()
    // let endTgl = "&tanggalAkhir="+ values.dateAkhir.toString()
    let res = await simpleFetch({
      url: `retail/laporan/stoks?${params}&tanggalAwal=${values.date}&tanggalAkhir=${values.dateAkhir}`,
      method: "GET",
    });
    return res;
  }
  async laporanSaldoPersediaan(values) {
    let params = "id_perusahaan="+values.cabang;
    let res = await simpleFetch({
      url: "retail/laporan/saldo-persediaan?" + params,
      method: "GET",
    });
    return res;
  }

  async stokAllGudang(values) {
    console.log("API Values",values);
    let params = "id_perusahaan="+values.cabang;
    let tgl = "&tanggal="+ values.date
    let id_gudang = "&id_gudang="+ values.gudang
    let id_produk = "&id_produk="+ values.produk
    let res = await simpleFetch({
      url: "retail/stok/allGudang?" + params + tgl + id_gudang + id_produk,
      method: "GET",
    });
    return res;
  }



  async laporanInventori(values) {
    let filter = values.selectedPT ? values.selectedPT : values.cabang
    let params = "id_perusahaan=";
    let tgl = "&tanggal="+ values.startDate
    if (filter.length !== 1) {
      params += filter.join("&id_perusahaan=");
    } else {
      params += filter;
    }
    let res = await simpleFetch({
      url: "retail/laporan/stok-all?" + params + tgl,
      method: "GET",
    });
    return res;
  }

  async laporanStokSum(values) {
    let filter = values.selectedPT ? values.selectedPT : values.cabang
    let params = "id_perusahaan=";
    let tgl = "&tanggal="+ values.startDate
    if (filter.length !== 1) {
      params += filter.join("&id_perusahaan=");
    } else {
      params += filter;
    }
    let res = await simpleFetch({
      url: "retail/laporan/stok-sum?" + params + tgl,
      method: "GET",
    });
    return res;
  }
}

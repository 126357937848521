import React, { useState, useEffect } from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import { StokTransferAPI } from "../../../api/stok-transfer";
import { useHistory } from "react-router-dom";
import StokRequestModel from "../../../models/stok-req-model/StokRequestModel";
import { Modal, Button } from 'react-bootstrap';

export default function List(params) {
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const history = useHistory();

  async function init() {
    let datas = await new StokTransferAPI().getAll().then((res) =>
      res.data
    );
    let simpleTableModel = new SimpleTableModel();
    datas.forEach((item, i) => {
      const actions = (dataModel) => {
        return (
          <>
          {item.master.is_approved === 2 ? 
          (
            <span>
              <Button
                className="mx-1 btn btn-secondary btn-sm"
                disabled
              >
                Edit
              </Button>
              <Button
                className="mx-1 btn btn-secondary btn-sm"
                disabled
              >
                Batal
              </Button>
              <Button
                className="mx-1 btn btn-outline-success btn-sm"
                onClick={() => {
                  params.setDataFromModel(dataModel);
                  history.push("/transfer-stok/detail");
                }}
              >
                Detail
              </Button>
            </span>
          ) : (<span>
            <Button
              size="sm"
              className="mx-1"
              variant="outline-primary"
              onClick={() => {
                params.setDataFromModel(dataModel);
                history.push("/transfer-stok/edit");
              }}
            >
              Edit
            </Button>
            <Button
              size="sm"
              className="mx-1"
              variant="outline-danger"
              onClick={() => {
                setId4batal(item.master.id);
                handleShow();
              }}
            >
              Batal
            </Button>
            <Button
              className="mx-1 btn btn-outline-success btn-sm"
              onClick={() => {
                params.setDataFromModel(dataModel);
                history.push("/transfer-stok/detail");
              }}
            >
              Detail
            </Button>
          </span>)  
        }
          </>
        )
      }

        let dataModel = new StokRequestModel(item);
        simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: actions(dataModel),
        });
      })
    setColumntable(await simpleTableModel.getColumnHeaders());
    setDatatable(await simpleTableModel.getDatatables());
  }

  const [id4batal, setId4batal] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSubmit = async () => await new StokTransferAPI().batal(id4batal);
  const [refreshData, setRefresh] = useState(false);

  useEffect(() => {
    init()
  }, [refreshData]);
  return (
    <>
      <AnimatedCard>
        <CardHeader title="Request Transfer Stok">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                history.push("/transfer-stok/new");
              }}
            >
              <i className="fa fa-plus"></i>
              Tambah
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <SimpleTable data={datatable} columns={columntable} />
        </CardBody>
        <Modal size="md" show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Batal Request Stok</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Anda Yakin Ingin Batalkan Request Stok ?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" 
                onClick={() => {
                  handleSubmit()
                  handleClose()
                  setRefresh(!refreshData)
                }}
                >
                 Batalkan & Simpan
                </Button>
              </Modal.Footer>
            </Modal>
      </AnimatedCard>
    </>
  );
}

import { getIdPerusahaan, simpleFetch, getCoverageArea } from "./api";
import store from "../redux/store";

export class MerekAPI {
  async getAll() {
    let res = await simpleFetch({
      url: "retail/master/merek",
      method: "GET",
    });
    return res;
  }

  async getSingle(id) {
    let res = await simpleFetch({
      url: "retail/master/merek?id=" + id,
      method: "GET",
    });
    return res;
  }

  async set(values) {
    let res = await simpleFetch({
      url: "retail/master/merek",
      method: "POST",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values,
      },
    });
    return res;
  }

  async edit(values) {
    let res = await simpleFetch({
      url: "retail/master/merek",
      method: "PUT",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values,
      },
    });
    return res;
  }

  async delete(id) {
    let res = await simpleFetch({
      url: "retail/master/merek",
      method: "DELETE",
      body: {
        id: id,
      },
    });
    return res;
  }

  async getCoverage() {
    let res = await simpleFetch({
      url: "retail/select/perusahaan?id_coverage=" + getCoverageArea(),
      method: "GET",
    });
    return res;
  }
}

import React from 'react'
import { CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls';
import { AnimatedCard } from '../../../../components/animated-card/animatedCard'
import { useState } from 'react';
import Select2 from "react-select";
import PembayaranARsales from './laporanARsales';
import PembayaranARcabang from './cabangAR';
import PembayaranARhari from './cabangARhari';

function LaporanAR() {
  const [kategori] = useState([{
    value: 1,
    label: "Sales"
  }, {
    value: 2,
    label: "Cabang"
  },
  {
    value: 3,
    label: "Cabang / Hari"
  },
])

  const [sKategori, setSkategori] = useState(1)

  return (
    <>
      <AnimatedCard>
        <CardHeader title={'Laporan Pembayaran AR'}>
          <CardHeaderToolbar >
            <div className="form-group">
              <label style={{ width: "250px"}}>Kategori</label>
              <Select2
                name={"Kategori"}
                size="lg"
                options={kategori}
                isClearable={false}
                value={
                  kategori
                    ? kategori.find(
                      (option) => option.value === sKategori
                    )
                    : ""
                }
                onChange={(e) => {
                  setSkategori(e ? e.value : []);
                }}
              >
              </Select2>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {sKategori === 1 ?
           <PembayaranARsales/> :
           sKategori === 2 ?
           <PembayaranARcabang/> :
           <PembayaranARhari/>
        }
        </CardBody>
      </AnimatedCard>
    </>
  )
}



export default LaporanAR
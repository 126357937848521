/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import {
  CardBody,
} from "../../../../../_metronic/_partials/controls";
import { ReactDatatable } from "../../../../components/react-datatable/reactDatatable";
import { Button } from "react-bootstrap";
import store from "../../../../redux/store";
import Select2 from "react-select";
import moment from "moment";
import { StokAPI } from "../../../../api/stok";
import { excelStokSummary } from "./excelStokSummary";

function StokSummary() {
  const columns = [
    {
      name: "Nama Barang",
      selector: (row) => row.nama_produk,
      sortable: true,
      minWidth: "25%",
    },
    {
      name: "Kode Barang",
      selector: (row) => row.kode,
      sortable: true,
      minWidth: "20%",
    },
    {
      name: "Satuan",
      selector: (row) => row.satuan,
      sortable: true,
      minWidth: "10%",
    },
    {
      name: <div style={{ textAlign: "center" }}>Masuk</div>,
      selector: (row) => row.masuk,
      sortable: true,
      minWidth: "10%",
      // width: "fit-content",
    },
    {
      name: <div style={{ textAlign: "center" }}>Keluar</div>,
      selector: (row) => row.keluar,
      sortable: true,
      minWidth: "10%",
      // width: "fit-content",
    },
  ];

  const ksa = [20, 46, 52, 69];
  const kpp = [68, 28, 70, 71];
  const ksb = [108, 105, 110, 109];

  const [pt] = useState([{
    value: ksa,
    label: "KSA"
  }, {
    value: kpp,
    label: "KPP"
  }, {
    value: ksb,
    label: "KSB"
  }])

  const [selectedPT, setSelectedPT] = useState(ksa.includes(store.getState().auth.id_perusahaan) ? ksa : kpp.includes(store.getState().auth.id_perusahaan) ? kpp : ksb);

  const [startDate, setStartDate] = useState(
    moment(new Date())
      // .startOf("month")
      .format("YYYY-MM-DD")
  );

  const [ptTitle, setTitle] = useState(`${store.getState().auth.toPerusahaan.singkat}`);
  const title = `Laporan Stok Summary ${ptTitle} ${startDate}`;
  const [data, setData] = useState([]);
  const fetchData = async (data) => {
    try {
      await new StokAPI().laporanStokSum(data).then((res) => {
        let datas = [];
        for (let [k,v] of Object.entries(res.data)) {
          // newObj[k] = v * v;
          datas = [...datas, v]
        }
        setData(datas);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData({ selectedPT, startDate });
  }, []);
  return (
    <>
      <AnimatedCard>
        <CardBody>
          <div className="d-flex justify-content-start form-group row">
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <label> Tanggal </label>
              <input
                name={"tanggal_awal"}
                type={"date"}
                value={startDate}
                className={"form-control"}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <label> Pilih PT </label>
              <Select2
                name={"PT"}
                size="sm"
                options={pt}
                isClearable={false}
                value={
                  pt ? pt.find((option) => option.value === selectedPT) : ""
                }
                onChange={(e) => {
                  setTitle(e.label)
                  setSelectedPT(e ? e.value : []);
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-start">
            <div style={{ marginRight: "2%" }}>
              <Button
                variant="info"
                onClick={(e) => {
                  e.preventDefault();
                  fetchData({ selectedPT, startDate });
                }}
              >
                <i className="fas fa-search"></i>
                Cari Data
              </Button>
            </div>
            <div>
              <Button onClick={(e) => excelStokSummary(data, title)}>
                <i className={"far fa-file-excel"}></i> {" Export .xls"}
              </Button>
            </div>
            {/* <div>{actionsMemo}</div> */}
          </div>
          <ReactDatatable
            columns={columns}
            data={data}
            dense
            pagination
            searchParameter={["nama", "kode"]}
          />
        </CardBody>
      </AnimatedCard>
    </>
  );
}

export default StokSummary
import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import ListJurnalUmum from "./list";
import RecordJurnalUmum from "./record";
import DetailJurnalUmum from "./detail";
import { dispatcher } from "../../../../redux/dispatcher";
import FormJurnalUmum from "./form";
export default function JurnalUmum() {
  const history = useHistory();
  const active = dispatcher['akuntansi'].getJurnalUmum();
  return (
    <Switch>
      <Route path="/akuntansi/jurnal-umum/list">
        <ListJurnalUmum />
      </Route>
      <Route path={"/akuntansi/jurnal-umum/form"}>
        <FormJurnalUmum history={history} isEdit={false} />
      </Route>
      {active['jurnal']['id'] === 0 && (
        <Redirect to="/akuntansi/jurnal-umum/list" />
      )}
      <Route path="/akuntansi/jurnal-umum/form">
        <FormJurnalUmum history={history} isEdit={true} />
      </Route>
      <Route path={"/akuntansi/jurnal-umum/record"}>
        <RecordJurnalUmum history={history} />
      </Route>
      <Route path={"/akuntansi/jurnal-umum/detail"}>
        <DetailJurnalUmum history={history} />
      </Route>
      <Redirect to="/akuntansi/jurnal-umum/list" />
    </Switch>
  );
}

import React from "react";
import PesananPembelianModel from "../../../models/pesanan-pembelian-model/PesananPembelianModel";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import FormPesananPembelian from "./form";
import ListPesananPembelian from "./list";
import DetailPesananPembelian from "./detail";
import { dispatcher } from "../../../redux/dispatcher";

export default function PesananPembelian() {
  const history = useHistory();
  const active = dispatcher.pesanan_pembelian.get();
  return (
    <Switch>
      <Route path="/pembelian-pesanan-pembelian/list">
        <ListPesananPembelian />
      </Route>

      {/* <Route path="/pembelian-pesanan-pembelian/new">
        <FormPesananPembelian history={history} isEdit={false} />
      </Route> */}

      {/* {active.master.id == 0 && (
        <Redirect to="/pembelian-pesanan-pembelian/list" />
      )} */}

      <Route path="/pembelian-pesanan-pembelian/edit">
        <FormPesananPembelian history={history} isEdit={true} />
      </Route>

      <Route path="/pembelian-pesanan-pembelian/detail">
        <DetailPesananPembelian history={history} />
      </Route>

      <Redirect to="/pembelian-pesanan-pembelian/list" />
    </Switch>
  );
}

import React, { useState } from 'react'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import List from "./list"
import Form from "./form"
import Detail from "./detail"
import PengajuanLimitModel from '../../../models/pengajuan-limit/PengajuanLimitModel'

function PengajuanLimit() {
  const [dataFromModel, setDataFromModel] = useState(new PengajuanLimitModel())
  const history = useHistory();
  return (
    <Switch>
      <Route path="/master/pengajuan-limit/list">
        <List setDataFromModel={setDataFromModel} />
      </Route>
      <Route path="/master/pengajuan-limit/tambah">
        <Form history={history}/>
      </Route>
      <Route path="/master/pengajuan-limit/detail">
        <Detail history={history} data={dataFromModel}/>
      </Route>
      <Redirect from="/master/pengajuan-limit" to="/master/pengajuan-limit/list" />
    </Switch>
  )
}

export default PengajuanLimit
import store from "../../redux/store";

export default class stokGudangModel {
  constructor(
    data = {
      nama: "",
      persediaan_total: "",
      id_gudang:0,
      id_produk:0,
      qty:0,
      masuk : 0,
      toProduk: {
        nama: "",
        kode: "",
        harga_jual: 0,
        toProdukSatuan: {
          nama: ""
        }
      },
      toPerusahaan:"",
      keluar:0,
      alokasi:0,
      ready:0,
      id_perusahaan:0
    },
  ) {
    this.nama = data.nama;
    this.nama_produk = data.toProduk.nama;
    this.kode_produk = data.toProduk.kode;
    this.nama_produkSatuan = data.toProduk.toProdukSatuan.nama
    this.qty = data.qty;
    this.masuk = parseInt(data.masuk) || 0;
    this.id_gudang = data.id_gudang;
    this.id_produk = data.id_produk;
    this.tanggalInput = data.tanggalInput;
    this.stok_keluar = parseInt(data.keluar) || 0;
    this.stok_alokasi = parseInt(data.alokasi) || 0;
    this.stok_ready = (this.masuk - this.stok_keluar) - this.stok_alokasi;
    this.total_stok = this.masuk - this.stok_keluar;
    this.harga = data.toProduk ? Math.round(data.toProduk.harga_jual*1.11) : 0;
    this.id_perusahaan = data.id_perusahaan;
  }
  rupiah (number) {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      maximumFractionDigits: 0, 
      minimumFractionDigits: 0, 
      currency: "IDR"
    }).format(number);
  }

  async getEditable(params = { pageName: "", isForm: false }) {
    return [
      {
        dataField: "kode",
        label: "Kode Barang",
        type: "number",
        value: this.kode_produk,
      },
      {
        dataField: "id_produk",
        label: "Nama Barang",
        type: "disabled-text",
        value: this.nama_produk,
      },
      {
        dataField: "nama_produk_satuan",
        label: "Nama Produk Satuan",
        type: "disabled-text",
        value: this.nama_produkSatuan,
      },
      {
        dataField: "harga_jual",
        label: "Harga Jual + PPN",
        type: "disabled-text",
        value: this.rupiah(this.harga),
        invisibleOnTable: [1,2,4,5,9].includes(store.getState().auth.role) 
        && ![105, 108,109,110].includes(this.id_perusahaan) ? false : true,
      },
      {
        dataField: "stok_ready",
        label: "Stok Ready",
        type: "number",
        value: this.stok_ready,
      },
      {
        dataField: "masuk",
        label: "Total Stok",
        type: "number",
        value: this.total_stok,
      },
      {
        dataField: "alokasi",
        label: "Stok Alokasi",
        type: "number",
        value: this.stok_alokasi,
      },
      {
        dataField: "stok_masuk",
        label: "Stok Masuk",
        type: "number",
        value: this.masuk,
      },
      {
        dataField: "stok_keluar",
        label: "Stok Keluar",
        type: "number",
        value: this.stok_keluar,
      },
    ];
  }
}

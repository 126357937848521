const initialValues = {
    active: null,
    list: [],
  };
  
  export const akunAction = {
    list: "LIST_AKUN",
    active: "ACTIVE_AKUN",
    resetActive: "RESET_ACTIVE_AKUN",
  };
  
  const reducer = (state = initialValues, action) => {
    let states;
    switch (action.type) {
      case akunAction.list:
        states = state;
        states.list = action.payload;
        return (state = states);
      case akunAction.active:
        states = state;
        states.active = action.payload;
        return (state = states);
      case akunAction.resetActive:
        states = state;
        states.active = null;
        return (state = states);
      default:
        return state;
    }
  };
  
  export default reducer;
  
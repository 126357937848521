import { messageAction } from "./actions";

const initialValues = {
  show: false,
  variant: "primary",
  title: "",
  message: "",
};

const reducerMessage = (state = initialValues, action) => {
  switch (action.type) {
    case messageAction.setMessage:
      return (state = { ...state, show: true, ...action.payload });
    case messageAction.hideMessage:
      return (state = { ...state, show: false });
    case messageAction.resetMessage:
      return (state = initialValues);
    default:
      return state;
  }
};

export default reducerMessage;

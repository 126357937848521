import React from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TableWithoutPagination } from "../../../components/table/Table";
import { Modal, Button } from "react-bootstrap";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Fields } from "../../../components/fields/Fields";
import {Typography} from "@material-ui/core";
import { PesananPenjualanAPI } from "../../../api/pesanan-penjualan";
import PesananPenjualanDetailModel from "../../../models/pesanan-penjualan-detail-model/PesananPenjualanDetailModel";
import { dispatcher } from "../../../redux/dispatcher";
// import { ApproveButtonPesananPenjualan } from "../../../components/approve-button/approveButtonPesananPenjualan";
import { Alert } from "react-bootstrap";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import { API } from "../../../api/api";
import DetailCust from "../cust-detail/detailCust";
import { hapusSeparator } from "../../../services/separator-harga";

export default class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.initialData = dispatcher.pesanan_penjualan.get();
    this.initialDetailData = new PesananPenjualanDetailModel();
    this.api = new PesananPenjualanAPI();
    this.simpleTableModel = new SimpleTableModel({ readOnly: true });
    this.pageName = "Detail Pesanan Penjualan";
    this.isEdit = props.isEdit;
    this.state = {
      modal: {
        show: false,
        initialDetailData: this.initialDetailData,
        isEdit: true,
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
    this.columntable = [{ dataField: "", text: "" }];
    this.datatable = [{ id: 0, nama: "" }];
    this.fields = [];
    this.validation = Yup.object().shape({});
    this.detailFields = [];
    this.detailValidation = Yup.object().shape({});
  }

  async componentDidMount() {
    console.warn(this.initialData);
    await this.loadFields();
    await this.loadDetail();
    let yup = {};
    await this.initialData.getEditable().then((values) => {
      values.map((item) => {
        yup[item.dataField] = item.validation;
      });
    });
    this.validation = Yup.object().shape(yup);

    yup = {};
    await this.state.modal.initialDetailData.getEditable().then((values) => {
      values.map((item) => {
        yup[item.dataField] = item.validation;
      });
    });
    this.detailValidation = Yup.object().shape(yup);
    this.setState({ ...this.state });
  }

  async loadFields() {
    this.fields = [];
    await this.initialData
      .getEditable({ pageName: this.pageName, isForm: false })
      .then((values) => {
        values.map((item, i) => {
          return (this.fields = [...this.fields, item]);
        });
      });
    this.setState({ ...this.state });
  }

  async loadDetail() {
    let datas = this.initialData.detail;
    this.simpleTableModel = new SimpleTableModel();
    datas.map((item, i) => {
      console.log(item);
      if (item.status !== 0)
        this.simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: item,
          actions: (
            <span>
              {item.harga_margin === 0 ? (
                <Button size="sm" className="mx-1" variant="primary" title="harga sesuai margin">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </Button>
              ) : item.hargaDpp < item.harga_margin ? (
                <Button size="sm" className="mx-1" variant="warning" title="harga dibawah margin">
                  <i className="fa fa-arrow-down" aria-hidden="true"></i>
                </Button>
              ) : 
                Math.round(parseInt(hapusSeparator(item.dpp_total))/item.qty) < item.harga_margin ? (
                <Button size="sm" className="mx-1" variant="warning" title="harga dibawah margin">
                  <i className="fa fa-arrow-down" aria-hidden="true"></i>
                </Button>
              ) : (
                <Button size="sm" className="mx-1" variant="primary" title="harga sesuai margin">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </Button>
              )}
            </span>
          ),
        });
    });

    this.datatable = await this.simpleTableModel.getDatatables();
    this.columntable = await this.simpleTableModel.getColumnHeaders();
    this.setState({ ...this.state });
  }

  handleModal = {
    loadDetailFields: async () => {
      this.detailFields = [];
      await this.state.modal.initialDetailData
        .getEditable({ pageName: this.pageName, isForm: true })
        .then((values) => {
          values.map((item, i) => {
            return (this.detailFields = [...this.detailFields, { ...item }]);
          });
        });
    },
    tambah: async () => {
      this.state.modal.initialDetailData = new PesananPenjualanDetailModel();
      let modal = { ...this.state.modal };
      modal.show = true;
      modal.title = `Item Baru`;
      modal.isEdit = false;
      await this.handleModal.loadDetailFields();
      this.setState({ modal });
    },
    edit: async (dataFromModel) => {
      let state = { ...this.state };
      state.modal.initialDetailData = dataFromModel;
      state.modal.show = true;
      state.modal.title = `Edit Item`;
      state.modal.isEdit = true;
      await this.handleModal.loadDetailFields();
      this.setState({ ...state });
    },
    hide: () => {
      let modal = { ...this.state.modal };
      modal.show = false;
      this.setState({ modal });
    },
  };

  handleAlert = {
    show: (id) => {
      let alert = { ...this.state.alert };
      alert.show = true;
      alert.activeId = id;
      this.setState({ alert: alert });
    },
    hide: () => {
      let alert = { ...this.state.alert };
      alert.show = false;
      alert.activeId = null;
      this.setState({ alert: alert });
    },
    confirmed: async () => {
      await this.initialData.handleDetail.del(this.state.alert.activeId);
      this.handleAlert.hide();
      this.loadDetail();
    },
  };

  render() {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={this.initialData.master}
          validationSchema={this.validation}
          onSubmit={async (values, { resetForm }) => {
            this.initialData.removeSeparator();
            this.initialData.removeAllDetailSeparator();
            if (this.props.isEdit) {
              await this.api.edit(this.initialData);
            } else if (!this.state.modal.isEdit) {
              await this.api.set(this.initialData);
            }
            this.props.history.goBack();
          }}
        >
          {({ handleSubmit, setFieldValue, resetForm }) => (
            <AnimatedCard>
              <CardHeader title={this.pageName}>
                <CardHeaderToolbar>
                  <button
                    type="button"
                    className="btn btn-secondary mx-1"
                    onClick={() => this.props.history.goBack()}
                  >
                    <i className="fa fa-arrow-left"></i>
                    Kembali
                  </button>
                  {/* {console.warn(this.initialData.master)} */}
                  <button
                    type={"button"}
                    className={"btn btn-warning mx-1"}
                    onClick={async () => {
                      window.open(
                        API.host +
                        "retail/penjualan/pesanan_penjualan/invoice?id=" +
                        this.initialData.master.id + "&id_user=" + this.initialData.master.id_user,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }}
                  >
                    <i className={"fa fa-envelope-open-text"}></i> Cetak Pesanan Barang
                  </button>
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <Form className="form form-label-right">
                <div className="row">
                    {this.fields.map((field, i) => (
                      ['nama_customer','tanggal', 'jatuh_tempo', 'no_transaksi', 'nama_sales',].includes(field.dataField)
                        ?
                        <div key={i} className="form-group col-lg-6">
                          <Fields
                            {...field}
                            type="disabled-text"
                            setFieldValue={setFieldValue}
                          />
                        </div> 
                        : ['jenis_pembayaran'].includes(field.dataField)
                        ?
                        <div key={i} className="form-group col-lg-6">
                          <Fields
                            {...field}
                            disabled={true}
                          />
                        </div> : ['nama_organisasi'].includes(field.dataField)
                          ?
                          <div key={i} className="form-group col-lg-6">
                            <label>  Organisasi </label>
                           <input type="text" className="form-control" disabled value={this.initialData.master.nama_organisasi} />
                          </div> : ""
                    ))}
                    <DetailCust
                    data = {this.initialData.master}
                    />
                    {/* <div className="form-group col-lg-12">
                      <label>Status Approve</label>
                      <div className="d-flex justify-content-between my-1">
                        {this.initialData.master.is_approved < 2 ? (
                          <Alert variant="secondary" className="m-0">
                            Belum Approve Sales Manager
                          </Alert>
                        ) : (
                          <Alert variant="primary" className="m-0">
                            Sudah Approve Sales Manager
                          </Alert>
                        )}
                        {store.getState().auth.alias_role ==
                          "sales-manager" && (
                          <ApproveButtonPesananPenjualan
                            dataModel={this.initialData}
                          />
                        )}
                      </div>
                      <div className="d-flex justify-content-between my-1">
                        {this.initialData.master.is_approved < 1 ? (
                          <Alert variant="secondary" className="m-0">
                            Belum Approve Supervisor
                          </Alert>
                        ) : (
                          <Alert variant="primary" className="m-0">
                            Sudah Approve Supervisor
                          </Alert>
                        )}

                        {store.getState().auth.alias_role == "supervisor" && (
                          <ApproveButtonPesananPenjualan
                            dataModel={this.initialData}
                          />
                        )}
                      </div>
                    </div> */}
                  </div>
                <span className="d-flex align-items-center justify-content-between mt-4">
                  <Typography variant="h6">Detail Item</Typography>
                </span>
                <TableWithoutPagination
                  data={this.datatable}
                  columns={this.columntable}
                />
                <div className="d-flex row" style={{ marginLeft: "40%" , marginTop: "20px"}}>
                    {this.fields.map((field, i) => (
                      // console.log("cek field", this.fields[0])
                      // field.dataField === "nama_customer" && "tanggal" 
                      !['nama_customer', 'nama_organisasi','tanggal','jatuh_tempo','no_transaksi','jenis_pembayaran','nama_sales'].includes(field.dataField)
                      ?
                      <div key={i} className="form-group col-md-4 justify-content-around">
                        <Fields
                          {...field}
                          type="disabled-text"
                          setFieldValue={setFieldValue}
                          />
                      </div> : ""    
                    ))}
                    </div>
                </Form>
              </CardBody>
            </AnimatedCard>
          )}
        </Formik>

        <Formik
          enableReinitialize={true}
          initialValues={this.state.modal.initialDetailData}
          validationSchema={this.detailValidation}
          onSubmit={async (values, { resetForm }) => {
            if (this.state.modal.isEdit) {
              await this.initialData.handleDetail.edit(
                this.state.modal.initialDetailData
              );
            } else {
              await this.initialData.handleDetail.set(
                this.state.modal.initialDetailData
              );
            }
            this.loadDetail();
            resetForm();
            this.handleModal.hide();
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Modal
              show={this.state.modal.show}
              onHide={() => this.handleModal.hide()}
              aria-labelledby="contained-modal-title-vcenter"
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modal.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  {this.detailFields.map((field, i) => {
                    return (
                      <div key={i} className="form-group col-lg-6">
                        <Fields {...field} setFieldValue={setFieldValue} />
                      </div>
                    );
                  })}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={this.handleModal.hide}
                  className="btn btn-light btn-elevate"
                >
                  Batal
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  Simpan
                </button>
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      </>
    );
  }
}

import { Redirect, Route, Switch } from "react-router-dom";
import FormPengeluaranBankOperasional from "./form";
import React from "react";
import { dispatcher } from "../../../../../redux/dispatcher";

export default function IndexFormPengeluaranBankOperasional ()
{
  dispatcher[ 'kasir_bank_operasional' ].resetActivePengeluaranBankOperasional();
  const active = dispatcher[ 'kasir_bank_operasional' ].getPengeluaranBankOperasional();
  return (
    <Switch>
      <Route path={ "/kasir/tambah-pengeluaran-bank-operasional/form" }>
        <FormPengeluaranBankOperasional />
      </Route>
      { active[ 'transaksi' ][ 'id' ] === 0 && (
        <Redirect to={ "/kasir/tambah-pengeluaran-bank-operasional/form" } />
      ) }
      <Redirect to={ "/kasir/tambah-pengeluaran-bank-operasional/form" } />
    </Switch>
  );
}

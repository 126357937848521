import React from "react";
import {
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TableWithoutPagination } from "../../../components/table/Table";
import { Modal, Button } from "react-bootstrap";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Fields } from "../../../components/fields/Fields";
import { Typography } from "@material-ui/core";
import { PesananPenjualanAPI } from "../../../api/pesanan-penjualan";
import PesananPenjualanDetailModel from "../../../models/pesanan-penjualan-detail-model/PesananPenjualanDetailModel";
import { dispatcher } from "../../../redux/dispatcher";
// import { ApproveButtonPesananPenjualan } from "../../../components/approve-button/approveButtonPesananPenjualan";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import store from "../../../redux/store";
import HistoryPembelianCustomer from "../history-pembelian-customer/historyPembelianCustomer";
import DetailCust from "../cust-detail/detailCust";
import HistoryPembelian from "../../pembelian/history-pembelian/historyPembelian";
import { hapusSeparator } from "../../../services/separator-harga";

export default class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.initialData = dispatcher.pesanan_penjualan.get();
    this.initialDetailData = new PesananPenjualanDetailModel();
    this.api = new PesananPenjualanAPI();
    this.simpleTableModel = new SimpleTableModel({ readOnly: true });
    this.pageName = "Detail Pesanan Penjualan";
    this.isEdit = props.isEdit;
    this.state = {
      modal: {
        show: false,
        initialDetailData: this.initialDetailData,
        isEdit: true,
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
    this.columntable = [{ dataField: "", text: "" }];
    this.datatable = [{ id: 0, nama: "" }];
    this.fields = [];
    this.validation = Yup.object().shape({});
    this.detailFields = [];
    this.detailValidation = Yup.object().shape({});
  }

  async componentDidMount() {
    await this.loadFields();
    await this.loadDetail();
    let yup = {};
    await this.initialData.getEditable().then((values) => {
      values.map((item) => {
        return yup[item.dataField] = item.validation;
      });
    });
    this.validation = Yup.object().shape(yup);

    yup = {};
    await this.state.modal.initialDetailData.getEditable().then((values) => {
      values.forEach((item) => {
        yup[item.dataField] = item.validation;
      });
    });
    this.detailValidation = Yup.object().shape(yup);
    this.setState({ ...this.state });
  }

  async loadFields() {
    this.fields = [];
    await this.initialData
      .getEditable({ pageName: this.pageName, isForm: false })
      .then((values) => {
        values.map((item, i) => {
          return (this.fields = [...this.fields, item]);
        });
      });
    this.setState({ ...this.state });
  }

  async loadDetail() {
    let datas = this.initialData.detail;
    this.simpleTableModel = new SimpleTableModel();
    datas.map((item, i) => {
      // if (item.status !== 0)
      return this.simpleTableModel.add({
        id: i,
        no: i + 1,
        dataModel: item,
        actions: (
          <span>
            {item.harga_margin === 0 ? (
                <Button size="sm" className="mx-1" variant="primary" title="harga sesuai margin">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </Button>
              ) : item.hargaDpp < item.harga_margin ? (
                <Button size="sm" className="mx-1" variant="warning" title="harga dibawah margin">
                  <i className="fa fa-arrow-down" aria-hidden="true"></i>
                </Button>
              ) : 
                Math.round(parseInt(hapusSeparator(item.dpp_total))/item.qty) < item.harga_margin ? (
                <Button size="sm" className="mx-1" variant="warning" title="harga dibawah margin">
                  <i className="fa fa-arrow-down" aria-hidden="true"></i>
                </Button>
              ) : (
                <Button size="sm" className="mx-1" variant="primary" title="harga sesuai margin">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </Button>
              )}
            <Button
              size="sm"
              className="mx-1 btn-info"
              variant="primary"
              title="History"
              onClick={() => {
                this.handleModal.edit(item)
              }}
            >
              <i className="fa fa-history" aria-hidden="true"></i>
            </Button>
          </span>
        ),
      });
    });

    this.datatable = await this.simpleTableModel.getDatatables();
    this.columntable = await this.simpleTableModel.getColumnHeaders();
    this.setState({ ...this.state });
  }

  handleModal = {
    loadDetailFields: async () => {
      this.detailFields = [];
      await this.state.modal.initialDetailData
        .getEditable({ pageName: this.pageName, isForm: true })
        .then((values) => {
          values.map((item, i) => {
            return (this.detailFields = [...this.detailFields, { ...item }]);
          });
        });
    },
    tambah: async () => {
      this.state.modal.initialDetailData = new PesananPenjualanDetailModel();
      let modal = { ...this.state.modal };
      modal.show = true;
      modal.title = `Item Baru`;
      modal.isEdit = false;
      await this.handleModal.loadDetailFields();
      this.setState({ modal });
    },
    edit: async (dataFromModel) => {
      let state = { ...this.state };
      state.modal.show = true;
      state.modal.title = `History Penjualan & Pembelian`;
      state.modal.data = dataFromModel;
      this.setState({ ...state });
    },
    hide: () => {
      let modal = { ...this.state.modal };
      modal.show = false;
      this.setState({ modal });
    },
  };

  handleAlert = {
    show: (id) => {
      let alert = { ...this.state.alert };
      alert.show = true;
      alert.activeId = id;
      this.setState({ alert: alert });
    },
    hide: () => {
      let alert = { ...this.state.alert };
      alert.show = false;
      alert.activeId = null;
      this.setState({ alert: alert });
    },
    confirmed: async () => {
      await this.initialData.handleDetail.del(this.state.alert.activeId);
      this.handleAlert.hide();
      this.loadDetail();
    },
  };

  render() {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={this.initialData.master}
          validationSchema={this.validation}
          onSubmit={async (values, { resetForm }) => {
            this.initialData.removeSeparator();
            this.initialData.removeAllDetailSeparator();
            this.initialData.master.approved_manager_by = store.getState().auth.id;
            this.initialData.master.is_approved = 2;
            Promise.all([
              await this.api.alokasi(this.initialData),
              await this.api.edit(this.initialData)
            ]).then(([a,b]) => {
              console.log('A,B',a,b);
              this.props.history.goBack();
            }).catch((err) => {
              console.log(err);
            })
          }}
        >
          {({ handleSubmit, setFieldValue, resetForm }) => (
            <AnimatedCard>
              <CardHeader title={this.pageName}>
                <CardHeaderToolbar>
                  <button
                    type="button"
                    className="btn btn-secondary mx-1"
                    onClick={() => this.props.history.goBack()}
                  >
                    <i className="fa fa-arrow-left"></i>
                    Kembali
                  </button>
                  {/* {console.warn(this.initialData.master)} */}
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <Form className="form form-label-right">
                  <div className="row">
                    {this.fields.map((field, i) => (
                      ['nama_customer', 'tanggal', 'jatuh_tempo', 'no_transaksi', 'nama_sales',].includes(field.dataField)
                        ?
                        <div key={i} className="form-group col-lg-6">
                          <Fields
                            {...field}
                            type="disabled-text"
                            setFieldValue={setFieldValue}
                          />
                        </div>
                        : ['jenis_pembayaran'].includes(field.dataField)
                          ?
                          <div key={i} className="form-group col-lg-6">
                            <Fields
                              {...field}
                              disabled={true}
                            />
                          </div> : ['nama_organisasi'].includes(field.dataField)
                            ?
                            <div key={i} className="form-group col-lg-6">
                              <label>  Organisasi </label>
                              <input type="text" className="form-control" disabled value={this.initialData.master.nama_organisasi} />
                            </div> : ""
                    ))}
                    <DetailCust
                      data={this.initialData.master}
                    />
                  </div>
                  <span className="d-flex align-items-center justify-content-between mt-4">
                    <Typography variant="h6">Detail Item</Typography>
                  </span>
                  <TableWithoutPagination
                    data={this.datatable}
                    columns={this.columntable}
                  />
                  <div className="d-flex row" style={{ marginLeft: "40%", marginTop: "20px" }}>
                    {this.fields.map((field, i) => (
                      !['nama_customer', 'nama_organisasi', 'tanggal', 'jatuh_tempo', 'no_transaksi', 'jenis_pembayaran', 'nama_sales'].includes(field.dataField)
                        ?
                        <div key={i} className="form-group col-md-4 justify-content-around">
                          <Fields
                            {...field}
                            type="disabled-text"
                            setFieldValue={setFieldValue}
                          />
                        </div> : ""
                    ))}
                  </div>
                </Form>
              </CardBody>
              <CardFooter>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-danger mx-1"
                    onClick={async () => {
                      // handleReject()
                      this.initialData.removeSeparator();
                      this.initialData.removeAllDetailSeparator();
                      this.initialData.master.approved_spv_by = 0;
                      this.initialData.master.approved_admin_by = 0;
                      this.initialData.master.approved_manager_by = 0;
                      this.initialData.master.is_approved = 0;
                      this.initialData.master.status_reject = 1;
                      await this.api.edit(this.initialData);
                      this.props.history.goBack();
                    }}
                  >
                    <i className="fa fa-times"></i>
                    Reject
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary mx-1"
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    <i className="fa fa-save"></i>
                    Approve & Simpan
                  </button>
                </div>
              </CardFooter>
            </AnimatedCard>
          )}
        </Formik>
        <Formik
          enableReinitialize={true}
          initialValues={this.state.modal.initialDetailData}
          validationSchema={this.detailValidation}
          onSubmit={async (values, { resetForm }) => {
            await this.initialData.handleDetail.edit(
              this.state.modal.initialDetailData
            );
            this.loadDetail();
            resetForm();
            this.handleModal.hide();
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Modal
              show={this.state.modal.show}
              onHide={() => this.handleModal.hide()}
              aria-labelledby="contained-modal-title-vcenter"
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modal.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <HistoryPembelianCustomer
                  data={this.state.modal.data}
                />
                <HistoryPembelian
                  data={this.state.modal.data}
                />
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={this.handleModal.hide}
                  className="btn btn-light btn-elevate"
                >
                  Tutup
                </button>
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      </>
    );
  }
}

import React, { useEffect, useState } from "react";
import {
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import Model from "../../../models/retur-pembelian-model/ReturPembelianModel";
import { Link, useHistory } from "react-router-dom";
import { dispatcher } from "../../../redux/dispatcher";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import moment from "moment";
import { PenerimaanAPI } from "../../../api/penerimaan";
import Select2 from "react-select";
import store from "../../../redux/store";
import { Button } from "react-bootstrap";

export default function List(params) {
  const history = useHistory();
  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const pageName = "Approval Retur Pembelian";
  const [startDate, setStartDate] = useState(
    moment(new Date())
      .startOf("month")
      .format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [search, setSearch] = useState("");
  const [option, setOptions] = useState([]);
  const [cabang, setCabang] = useState(store.getState().auth.id_perusahaan);
  
  const coverage = async () => {
    let options = [];
    await new PenerimaanAPI().getCoverage().then((res) => {
      options = res.data.map((data) => ({
        label: data.text,
        text: data.text,
        value: data.id,
      }));
    });
    setOptions(options);
  };

  async function loadData(data) {
    console.log(data);
    let detail = await new PenerimaanAPI().getAllRetur();
    let datas = await new PenerimaanAPI().showRetur(data);
    let dataMaster = datas.data;
    let simpleTableModel = new SimpleTableModel();
    let searchParams = ["no_transaksi", "total", "kode"];
    console.warn("dataMaster", datas);
    let filtered = datas.data.filter((filter) => {
      let dateFormat = moment(filter.created_at).format("YYYY-MM-DD");
      let dateFilter = dateFormat >= startDate && dateFormat <= endDate;
      return searchParams.some((item) => {
        return (
          dateFilter &&
          // console.log(filter.master.toSupplier.nama)
          filter[item]
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())
        );
      });
    });

    const actions = (dataModel) => {
      return (
        <>
          {dataModel.is_approved >= 2 ? (
            <Button size="sm" className="mx-1" variant="primary"
            onClick={async () => {
              let filter = detail.data.filter((val) => val.master.id === dataModel.id_penerimaan_pembelian)
              params.setDataDetail(filter)
              params.setDataFromModel(dataModel);
              history.push("/approval-retur-pembelian/detail");
            }}
            >
              <i className="fas fa-user-check"></i>
              Sudah Approve
            </Button>
          ) : (
            <span>
              <Link
                className="mx-1 btn btn-warning btn-sm"
                to="/approval-retur-pembelian/detail"
                onClick={() => {
                  let filter = detail.data.filter((val) => val.master.id === dataModel.id_penerimaan_pembelian)
                  console.log('cek filter', filter);
                  console.log('cek datas', datas);
                  params.setDataFromModel(dataModel);
                  params.setDataDetail(filter)
                }}
              >
                <i className="fas fa-user-lock"></i>
                Belum Approve
              </Link>
            </span>
          )}
        </>
      );
    };

    filtered.map((item, i) => {
      console.log('item', item);
      let dataModel = new Model(item);
      if (item.status === 1)
        simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: actions(dataModel),
        });
    });


    setColumntable(await simpleTableModel.getColumnHeaders());
    setDatatable(await simpleTableModel.getDatatables());
  }

  useEffect(() => {
    loadData(cabang);
    coverage();
  }, [cabang,startDate,endDate,search]);

  return (
    <>
      <AnimatedCard>
        <CardHeader title={pageName}></CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Mulai </label>
              <input
                name={"tanggal_awal"}
                type={"date"}
                value={startDate}
                className={"form-control"}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  // loadData();
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Akhir </label>
              <input
                name={"tanggal_akhir"}
                type={"date"}
                value={endDate}
                className={"form-control"}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  // loadData();
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Pilih Perusahaan </label>
              <Select2
                name={"Cabang"}
                placeholder={option.text}
                size="sm"
                options={option}
                isClearable={true}
                value={
                  option
                    ? option.find(
                      (data) => data.value === cabang
                    )
                    : ""
                }
                onChange={(e) => {
                  console.log('cek e', e);
                  setCabang(e ? e.value : []);
                  // loadData();
                  // setFieldValue("cabang", e ? e.value : "");
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <input
                type={"text"}
                className={"form-control"}
                placeholder="Cari Data ..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  // loadData()
                  // dispatcher.penerimaan.list();
                }}
              />
            </div>
          </div>
          <SimpleTable data={datatable} columns={columntable} />
        </CardBody>
      </AnimatedCard>
    </>
  );
}

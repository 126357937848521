import React from "react";
import {
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Typography } from "@material-ui/core";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import StokRequestDetailModel from "../../../models/stok-req-model/StokRequestDetailModel";
import { StokTransferAPI } from "../../../api/stok-transfer";
import StokRequestModel from "../../../models/stok-req-model/StokRequestModel";
import { getIdPerusahaan, simpleFetch } from "../../../api/api";
import { dispatcher } from "../../../redux/dispatcher";
import { TableWithoutPagination } from "../../../components/table/Table";
import { GudangAPI } from "../../../api/gudang";
import { Alert, Button, Modal } from "react-bootstrap";
import Select2 from "react-select";
import { ProdukAPI } from "../../../api/produk";
import store from "../../../redux/store";

export default class InboundTransferRequest extends React.Component {
    constructor(props) {
        super(props);
        this.initialData = props.dataFromModel;
        this.initialDetailData = new StokRequestDetailModel();
        this.api = new StokTransferAPI();
        this.simpleTableModel = new SimpleTableModel({ readOnly: true });
        this.pageName = "Detail Request Transfer Stok";
        this.isEdit = props.isEdit;
        this.state = {
            gudang: 0,
            selected: 0,
            status_validasi: 1,
            persediaan: 0,
            opsi_gudang: [
                { value: 0, label: "Tidak Ada Terpilih" }
            ],
            modal: {
                show: false,
                initialDetailData: this.initialDetailData,
                isEdit: true,
            },
            alert: {
                activeId: null,
                show: false,
            },
        };
        this.columntable = [{ dataField: "", text: "" }];
        this.datatable = [{ id: 0, nama: "" }];
        this.fields = [];
        this.validation = Yup.object().shape({});
        this.detailFields = [];
        this.detailValidation = Yup.object().shape({});
        this.validasi = [];
    }

    async componentDidMount() {
        await this.loadFields();
        await this.takeDetail(this.initialData.master.id);
        await this.loadDetail();
        let yup = {};
        await this.initialData.getEditable().then((values) => {
            values.map((item) => {
                return yup[item.dataField] = item.validation;
            });
        });
        this.validation = Yup.object().shape(yup);

        this.setState({ ...this.state });
    }

    async takeDetail(id) {
        await new StokTransferAPI().getSingle(id).then((response) => {
            // let detail = response.data.detail;
            this.initialDetailData = new StokRequestModel(response.data)
            // this.setState({initialDetailData: response.data})
            // this.initialDetailData = new StokRequestModel(response.data);
        });
    }

    async loadIdProduk(data) {
        console.log('DataIn Fun', data);
        await new ProdukAPI().getAll().then((val) => {
            let fil = val.data.filter((dt) => dt.nama === data.nama_produk && dt.kode === data.kode_produk && dt.id_perusahaan === data.id_perusahaan)
            console.log("Fil", fil);
            let id = fil.map((data) =>
                this.state.selected = data.id
                //   this.setState({selected: data.id})
            )
            return fil !== [] ? id :(this.setState({selected: 0}) )
            //   return console.log('Fun',id);
        })
    }

    async loadFields() {
        this.fields = [];
        this.yup = {};
        await this.initialData
            .getEditable({ pageName: this.pageName, isForm: false })
            .then((values) => {
                values.map((item, i) => {
                    this.yup[item.dataField] = item.validation;
                    return (this.fields = [...this.fields, item]);
                });
            });
        this.setState({ ...this.state });
    }

    async loadDetail() {
        let datas = this.initialDetailData.detail;
        this.simpleTableModel = new SimpleTableModel({ readOnly: false });
        datas.forEach((item, i) => {
            console.log("Item Inside load",item);
            this.validasi.push(item.status_validasi);
            if (item.status !== 0)
                this.simpleTableModel.add({
                    id: i,
                    no: i + 1,
                    dataModel: item,
                    actions: item.status_validasi === 1 ?
                        (
                            <Button
                                className="mx-1 btn btn-outline-success btn-sm"
                                onClick={() => {
                                    this.setState({selected: 0})
                                    console.log(this.state.selected);
                                    this.loadIdProduk(item).then(() => {
                                        this.handleModal.edit(item)
                                    })
                                }}
                            >
                                Alokasi Item
                            </Button>
                        ) : (
                            <Button className="mx-1 btn btn-outline-secondary btn-sm" disabled>
                                Sudah Alokasi
                            </Button>
                        ),
                });
        });

        this.datatable = await this.simpleTableModel.getDatatables();
        this.columntable = await this.simpleTableModel.getColumnHeaders();
        this.setState({ ...this.state });
    }

    handleModal = {
        edit: async (dataFromModel) => {
            // console.log('CEK INITIAL DETAIL DATA', this.initialDetailData);
            this.state.persediaan = dataFromModel.persediaan_total;
            await new GudangAPI().getAll().then((values) => {
                let filter = values.data.filter((fil) => fil.id_perusahaan === this.initialData.master.id_perusahaan)
                this.state.opsi_gudang = filter.map((data) => (
                    {
                        label: `${data.nama}`,
                        text: data.nama,
                        value: data.id,
                    }));
            });
            console.log('Cek state', this.state.selected);
            let state = { ...this.state };
            state.modal.initialDetailData.status_validasi = 1;
            state.modal.initialDetailData = dataFromModel;
            state.modal.show = true;
            state.modal.title = `Edit Item`;
            state.modal.isEdit = true;
            this.setState({ ...state });
        },
        hide: () => {
            let states = { ...this.state }
            let modal = { ...this.state.modal };
            modal.show = false;
            states.selected = 0;
            this.setState({ modal, states });
        },
    };

    handleAlert = {
        show: (id) => {
            let alert = { ...this.state.alert };
            alert.show = true;
            alert.activeId = id;
            this.setState({ alert: alert });
        },
        hide: () => {
            let alert = { ...this.state.alert };
            alert.show = false;
            alert.activeId = null;
            this.setState({ alert: alert });
        },
        confirmed: async () => {
            await this.initialData.handleDetail.del(this.state.alert.activeId);
            this.handleAlert.hide();
            this.loadDetail();
        },
    };

    render() {
        return (
            <>
                <Formik
                    enableReinitialize={true}
                    initialValues={this.initialData}
                    validator={this.validation}
                >
                    {({ handleSubmit, setFieldValue, resetForm }) => (
                        <AnimatedCard>
                            <CardHeader title={this.pageName}>
                                <CardHeaderToolbar>
                                    <button
                                        type="button"
                                        className="btn btn-secondary mx-1"
                                        onClick={() => this.props.history.goBack()}
                                    >
                                        <i className="fa fa-arrow-left"></i>
                                        Kembali
                                    </button>
                                    {!this.validasi.includes(1) && this['initialData']['master']['status_jurnal'] === "1" ?
                                        (
                                            <Button
                                                className="mx-1 btn btn-outline-primary btn-sm"
                                                onClick={async () => {
                                                    let send = {
                                                        id: this['initialData']['master']['id'],
                                                        id_user: store.getState().auth['id'],
                                                        id_perusahaan: getIdPerusahaan(),
                                                    };
                                                    await this['api'].validasiTerimaStok(send).then((res) => {
                                                        if (res['status']) {
                                                            dispatcher['snackbar'].show("Berhasil", res['message'], "primary");
                                                            setTimeout(() => {
                                                                this.props['history'].goBack()
                                                            }, 3000);
                                                        } else {
                                                            return dispatcher['snackbar'].show("Gagal", res['message'], "danger");
                                                        }
                                                    });
                                                }}
                                            ><i className="fa fa-check-circle"></i> Validasi
                                            </Button>
                                        ) : ""

                                    }
                                </CardHeaderToolbar>
                            </CardHeader>
                            <CardBody>
                                <Form className="form form-label-right">
                                    <div className="row">
                                        {this.fields.map((field, i) => (
                                            <div key={i} className="form-group col-lg-6">
                                                <label>{field.label}</label>
                                                <input type="text" className="form-control" disabled value={field.value}></input>
                                            </div>
                                        ))}
                                    </div>
                                    <span className="d-flex align-items-center justify-content-between mt-4">
                                        <Typography variant="h6">Detail Item</Typography>
                                    </span>
                                    {/* <InboundDetail
                                    data={this.initialData}
                                    id_gudang={this.id_gudang}
                                    handleSubmit={handleSubmit}
                                    /> */}
                                    <TableWithoutPagination
                                        data={this.datatable}
                                        columns={this.columntable}
                                    />
                                </Form>
                            </CardBody>
                        </AnimatedCard>
                    )}
                </Formik>

                {/* Detail */}
                <Formik
                    enableReinitialize={true}
                    initialValues={this.state.modal.initialDetailData}
                    validationSchema={this.detailValidation}
                    onSubmit={async (values, { resetForm }) => {
                        if (this.state.selected === 0) {
                          return  dispatcher.snackbar.show(
                                "Error",
                                `Produk belum terdaftar`,
                                "danger"
                            )
                        }
                        console.log("Values", values);
                        // this.setState({validasi: values.status_validasi});
                        try {
                            await simpleFetch({
                                url: "retail/stok/transfer_masuk",
                                method: "POST",
                                body: {
                                    id: values.id,
                                    id_gudang: this.state.gudang,
                                    id_produk: this.state.selected,
                                },
                            }).then((res) => {
                                dispatcher.snackbar.show(
                                    "Berhasil",
                                    `${res.data}`,
                                    "primary"
                                );
                                this.state.modal.initialDetailData.status_validasi = 2;
                                this.validasi = [];
                                // setTimeout(() => {
                                //     this.props['history'].goBack()
                                // }, 3000);
                            }
                            )
                        } catch (error) {
                            dispatcher.snackbar.show(
                                "Error",
                                `${error}`,
                                "danger"
                            )
                            this.setState({ status_validasi: 1 })
                        }
                        this.handleModal.hide()
                        await this.takeDetail(values.id_stok_transfer).then(() => {
                            this.setState({selected: 0})
                            this.loadDetail()
                            this.loadFields()
                        })
                    }}
                >
                    {({ handleSubmit, setFieldValue }) => (
                        <Modal
                            show={this.state.modal.show}
                            onHide={() => this.handleModal.hide()}
                            aria-labelledby="contained-modal-title-vcenter"
                            size="md"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>{this.state.modal.title}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <>
                                    <div className="form-group col-lg">
                                        <label>QTY</label>
                                        <input type="text" className="form-control" name="jatuh_tempo" placeholder="Jatuh Tempo" autoComplete="off" disabled defaultValue={this.state.modal.initialDetailData.qty} />
                                    </div>
                                    <div className="col-lg" style={{ marginTop: "20px" }}>
                                        <label> Pilih Gudang </label>
                                        <Select2
                                            name={"Gudang"}
                                            placeholder={" --- Pilih Gudang --- "}
                                            size="sm"
                                            options={this.state.opsi_gudang}
                                            isClearable={false}
                                            value={
                                                this.state.opsi_gudang
                                                    ? this.state.opsi_gudang.find(
                                                        (data) => data.value === this.state.gudang
                                                    )
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                this.setState({ gudang: e ? e.value : [] });
                                                // setFieldValue("cabang", e ? e.value : "");
                                            }}
                                        />
                                    </div>
                                </>
                                {/* )} */}
                            </Modal.Body>
                            <Modal.Footer>
                                <button
                                    type="button"
                                    onClick={this.handleModal.hide}
                                    className="btn btn-light btn-elevate"
                                >
                                    Batal
                                </button>
                                <> </>
                                <button
                                    type="submit"
                                    onClick={() => {
                                        console.log('Cek onSubmit', this.state.selected);
                                        handleSubmit()
                                    }}
                                    className="btn btn-primary btn-elevate"
                                >
                                    Simpan Perubahan
                                </button>
                            </Modal.Footer>
                        </Modal>
                    )}
                </Formik>
            </>
        );
    }
}

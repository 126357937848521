import React from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Fields } from "../../../components/fields/Fields";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import stokGudangModel from "../../../models/stok-gudang-model/StokGudangModel";
import { ProdukAPI } from "../../../api/produk";
import _ from "lodash";
import { Button } from "react-bootstrap";
import store from "../../../redux/store";
import Select2 from "react-select";
import { API } from "../../../api/api";
import { Link } from "react-router-dom";

export default class StokGudang extends React.Component {
  constructor(props) {
    super(props);
    this.initialData = new stokGudangModel();
    this.api = new ProdukAPI();
    this.simpleTableModel = new SimpleTableModel();
    this.pageName = "Stok Gudang";
    this.state = {
      search: "",
      role: store.getState().auth.role,
      cabang: store.getState().auth.id_perusahaan,
      modal: {
        show: false,
        initialData: this.initialData,
        isEdit: false,
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
    this.validation = Yup.object().shape({});
    this.columntable = [{ dataField: "", text: "" }];
    this.datatable = [{ id: 0, nama: "" }];
    this.fields = [];
  }

  async componentDidMount() {
    // this.api.getCoverage().then((res) => {
    //   this.opsi_cabang = res.data.map((data) => ({
    //     label: data.text,
    //     text: data.text,
    //     value: data.id,
    //   }));
    // });
    this.opsi_cabang = ([
      { value: 20, text: "KSA - MAKASSAR", label: "KSA - MAKASSAR" },
      { value: 28, text: "KPP - MAKASSAR", label: "KPP - MAKASSAR" },
      { value: 46, text: "KSA - KENDARI", label: "KSA - KENDARI" },
      { value: 71, text: "KPP - KENDARI", label: "KPP - KENDARI" },
      { value: 52, text: "KSA - MANADO", label: "KSA - MANADO" },
      { value: 70, text: "KPP - MANADO", label: "KPP - MANADO" },
      // { value: 68, text: "KPP - HEAD OFFICE", label: "KPP - HEAD OFFICE" },
      // { value: 69, text: "KSA - HEAD OFFICE", label: "KSA - HEAD OFFICE" },
      { value: 105, text: "KSB - MAKASSAR", label: "KSB - MAKASSAR" },
      // { value: 108, text: "KSB - HEAD OFFICE", label: "KSB - HEAD OFFICE" },
      { value: 109, text: "KSB - KENDARI", label: "KSB - KENDARI" },
      { value: 110, text: "KSB - MANADO", label: "KSB - MANADO" },
    ]);
    await this.loadData();
    let yup = {};
    await this.initialData.getEditable().then((values) =>
      values.forEach((item) => {
        yup[item.dataField] = item.validation;
      })
    );
    this.validation = Yup.object().shape(yup);
  }

  async loadData() {
    let datas = await this.api.getAllProdukGudang();
    let los = _.merge(_.keyBy(datas.data.masuk, 'id_produk'), _.keyBy(datas.data.keluar, 'id_produk'), _.keyBy(datas.data.alokasi, 'id_produk'))
    let data = _.values(los)
    let searchParams = ["nama", "kode"];
    let filtered = data.filter((fil) => {
      let cabangSelected = fil.id_perusahaan === this.state.cabang;
      return searchParams.some((item) => {
        return (
          cabangSelected &&
          fil.toProduk[item]
            .toString()
            .toLowerCase()
            .includes(this.state.search.toString().toLowerCase())
        );
      });
    })
    this.simpleTableModel = new SimpleTableModel({ readOnly: false });
    filtered.map((item, i) => {
      let dataModel = new stokGudangModel(item);
      this.simpleTableModel.add({
        id: i,
        no: i + 1,
        dataModel: dataModel,
        actions: (
          <>
            <Link
              className="mx-1 btn btn-outline-success btn-sm"
              title="Detail Data"
              to="/stok-gudang/detail"
              onClick={() => {
                this.props.setDataFromModel(dataModel)
              }}
            >
              Detail
            </Link>
          </>
        ),
      });
    });

    this.datatable = await this.simpleTableModel.getDatatables();
    this.columntable = await this.simpleTableModel.getColumnHeaders();
    this.setState({ ...this.state });
  }

  handleModal = {
    loadFields: async () => {
      this.fields = [];
      await this.initialData
        .getEditable({ pageName: this.pageName, isForm: true })
        .then((values) => {
          values.map((item, i) => {
            return (this.fields = [...this.fields, item]);
          });
        });
    },
    tambah: async () => {
      let modal = { ...this.state.modal };
      modal.show = true;
      modal.title = `${this.pageName} Baru`;
      modal.isEdit = false;
      await this.handleModal.loadFields();
      this.setState({ modal });
    },
    edit: async (dataFromModel) => {
      let modal = { ...this.state.modal };
      modal.show = true;
      modal.title = `Edit ${this.pageName}`;
      modal.initialData = dataFromModel;
      modal.isEdit = true;
      await this.handleModal.loadFields();
      this.setState({ modal });
    },
    hide: () => {
      let modal = { ...this.state.modal };
      modal.show = false;
      modal.initialData = this.initialData;
      this.setState({ modal });
    },
  };

  handleAlert = {
    show: (id) => {
      let alert = { ...this.state.alert };
      alert.show = true;
      alert.activeId = id;
      this.setState({ alert: alert });
    },
    hide: () => {
      let alert = { ...this.state.alert };
      alert.show = false;
      alert.activeId = null;
      this.setState({ alert: alert });
    },
    confirmed: async () => {
      await this.api.delete(this.state.alert.activeId);
      this.handleAlert.hide();
      this.loadData();
    },
  };

  render() {
    return (
      <>
        <AnimatedCard>
          <CardHeader title={this.pageName}>
            <CardHeaderToolbar>
              <Button
                type="download"
                onClick={async () => {
                  window.open(
                    API.host +
                    `retail/gudang/download?id_perusahaan=${this.state.cabang}&id_role=${this.state.role}`,
                    "_self",
                    "noopener,noreferrer"
                  );
                }}
              >
                <i className={"far fa-file-excel"}></i> {" Export .xls"}
              </Button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody key={this.datatable.id}>
            <div className="d-flex justify-content-between form-group row">
              <div className="col-xl-4" style={{ marginTop: "0px", marginBottom: "10px" }}>
                <label > Pilih Cabang </label>
                <Select2
                  name={"Filter"}
                  placeholder={"--- Pilih Cabang ---"}
                  size="sm"
                  options={this.opsi_cabang}
                  isClearable={true}
                  value={
                    this.opsi_cabang
                      ? this.opsi_cabang.find(
                        (option) => option.value === this.state.cabang
                      )
                      : ""
                  }
                  onChange={(e) => {
                    this.setState({
                      cabang: e ? e.value : "",
                    });
                    this.loadData();
                    // this.setState(e ? { value: e.value } : []);
                    // setFieldValue("cabang", e ? e.value : "");
                  }}
                />
              </div>
              {/* <Link to={`retail/gudang/download?id_perusahaan=${this.state.cabang}`} download>Download</Link> */}
              <div className="col-lg-3" style={{ marginTop: "15px" }}>
                <input
                  type={"text"}
                  className={"form-control"}
                  placeholder="Cari Data ..."
                  value={this.state.search}
                  onChange={(e) => {
                    this.setState({ search: e.target.value })
                    this.loadData()
                  }}
                />
              </div>
            </div>
            <SimpleTable data={this.datatable} columns={this.columntable} />
          </CardBody>
        </AnimatedCard>
      </>
    );
  }
}

import React from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import * as Yup from "yup";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { ProdukAPI } from "../../../api/produk";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import Select2 from "react-select";
import ProdukHargaModel from "../../../models/produk-model/ProdukHargaModel";
import store from "../../../redux/store";

export default class ProdukHarga extends React.Component {
  constructor(props) {
    super(props);
    this.initialData = new ProdukHargaModel();
    this.api = new ProdukAPI();
    this.simpleTableModel = new SimpleTableModel();
    this.pageName = "Daftar Harga Produk";
    this.state = {
      search: "",
      cabang: store.getState().auth.id_perusahaan,
      modal: {
        show: false,
        initialData: this.initialData,
        isEdit: false,
      },
    };
    this.validation = Yup.object().shape({});
    this.columntable = [{ dataField: "", text: "" }];
    this.datatable = [{ id: 0, nama: "" }];
    this.fields = [];
  }

  async componentDidMount() {
    this.api.getCoverage().then((res) => {
      this.opsi_cabang = res.data.map((data) => ({
         label: data.text,
         text: data.text,
         value: data.id,
       }));
     });
    await this.loadData();
    let yup = {};
    await this.initialData.getEditable().then((values) =>
      values.map((item) => {
       return yup[item.dataField] = item.validation;
      })
    );
    this.validation = Yup.object().shape(yup);
  }

  async loadData() {
    let datas = await this.api.getAll();
    // console.log('cek datas',datas.data);
    let dataMaster = datas.data;
    let searchParams = ["nama", "kode"];
    let filtered = dataMaster.filter((filter) => {
      // let master = filter.master.toSupplier.nama.toString().toLowerCase();
      let cabangSelected = filter.id_perusahaan === this.state.cabang;
      return searchParams.some((item) => {
        return (
          cabangSelected &&
          filter[item]
            .toString()
            .toLowerCase()
            .includes(this.state.search.toString().toLowerCase())
            );
          });
        });
    this.simpleTableModel = new SimpleTableModel({ readOnly: true });
    let allData = this.state.search === "" && this.state.cabang === "" ? 
    dataMaster : filtered
    filtered.forEach((item, i) => {
      let dataModel = new ProdukHargaModel(item);
      this.simpleTableModel.add({
        id: item.id,
        no: i + 1,
        dataModel: dataModel,
        actions: null,
      });
    });

    this.datatable = await this.simpleTableModel.getDatatables();
    this.columntable = await this.simpleTableModel.getColumnHeaders();
    console.table(this.columntable);
    this.setState({ ...this.state });
  }

  handleModal = {
    loadFields: async () => {
      this.fields = [];
      await this.initialData
        .getEditable({ pageName: this.pageName, isForm: true })
        .then((values) => {
          values.map((item, i) => {
            return (this.fields = [...this.fields, item]);
          });
        });
    },
    tambah: async () => {
      let modal = { ...this.state.modal };
      modal.show = true;
      modal.title = `${this.pageName} Baru`;
      modal.isEdit = false;
      await this.handleModal.loadFields();
      this.setState({ modal });
    },
    edit: async (dataFromModel) => {
      let modal = { ...this.state.modal };
      modal.show = true;
      modal.title = `Edit ${this.pageName}`;
      modal.initialData = dataFromModel;
      modal.isEdit = true;
      await this.handleModal.loadFields();
      this.setState({ modal });
    },
    hide: () => {
      let modal = { ...this.state.modal };
      modal.show = false;
      modal.initialData = this.initialData;
      this.setState({ modal });
    },
  };

  render() {
    return (
      <>
        <AnimatedCard>
          <CardHeader title={this.pageName}>
            <CardHeaderToolbar>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
          <div className="d-flex justify-content-between">
          <div className="col-xl-4" style={{ marginTop: "0px" }}>
                <label > Pilih Perusahaan </label>
                <Select2
                  name={"Filter"}
                  placeholder={"--- Pilih Perusahaan ---"}
                  size="sm"
                  options={this.opsi_cabang}
                  isClearable={false}
                  value={
                    this.opsi_cabang
                    ? this.opsi_cabang.find(
                      (option) => option.value === this.state.cabang
                      )
                      : ""
                    }
                    onChange={(e) => {
                      this.setState({
                        cabang: e ? e.value : "",
                    });
                    this.loadData();
                    // this.setState(e ? { value: e.value } : []);
                    // setFieldValue("cabang", e ? e.value : "");
                  }}
                />
                </div>
                <div className="col-lg-3" style={{ marginTop: "20px" }}>
                <input
                  type={"text"}
                  className={"form-control"}
                  placeholder="Cari Data ..."
                  value={this.state.search}
                  onChange={(e) => {
                    this.setState({ search: e.target.value });
                    this.loadData();
                    // console.log(this.state.search);
                  }}
                />
              </div>
                  </div>
            <SimpleTable data={this.datatable} columns={this.columntable} />
          </CardBody>
        </AnimatedCard>
      </>
    );
  }
}

import XlsxPopulate from "xlsx-populate";
import moment from "moment";
import { saveAs } from "file-saver";

// Get Sheet Data Export To Excel
function getSheetData(data, header) {
  var fields = Object.keys(data[0]);
  var sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
}

// Export To Excell
function saveAsExcel(data, title) {
  console.log('Data For Excel', data);
  let currDate = moment().format('YYYY-MM-DD')
  let dataList = [];
  data.map((data, i) => {
    let op = data.pembelian ? data.pembelian : 
    data.penjualan ? data.penjualan : data.qty;
    console.log(op === data.qty ? "Benarr" : "Salah");
    op === data.qty ? 
    dataList.push({
      Nama: data.name,
      January:op[0] || "0",
      February:op[1] || "0",
      Maret:op[2] || "0",
      April:op[3] || "0",
      Mei:op[4] || "0",
      Juni:op[5] || "0",
      Juli:op[6] || "0",
      Agustus:op[7] || "0",
      September:op[8] || "0",
      Oktober:op[9] || "0",
      November:op[10] || "0",
      Desember:op[11] || "0",
    })
    :
    dataList.push({
      Nama: data.name,
      January: parseInt(op[0]) || "0",
      February: parseInt(op[1]) || "0",
      Maret: parseInt(op[2]) || "0",
      April: parseInt(op[3]) || "0",
      Mei: parseInt(op[4]) || "0",
      Juni: parseInt(op[5]) || "0",
      Juli: parseInt(op[6]) || "0",
      Agustus: parseInt(op[7]) || "0",
      September: parseInt(op[8]) || "0",
      Oktober: parseInt(op[9]) || "0",
      November: parseInt(op[10]) || "0",
      Desember: parseInt(op[11]) || "0",
    });
    return data;
  });
  let header = [
    'Nama',
    'January',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember'];
  let dataLength = data.length + 1;
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);
    const sheetData = getSheetData(dataList, header);
    const totalColumns = sheetData[0].length;
    sheet1.cell("A1").value(sheetData);
    const range = sheet1.usedRange();
    const endColumn = String.fromCharCode(64 + totalColumns);
    sheet1.row(1).style("bold", true);
    sheet1.range("A1:" + endColumn + "1").style({
      fill: "BFBFBF",
      horizontalAlignment: "center",
    });
    // Styls Center Align
    let centerAlign = ["A", "B", "C", "D", "E", "F","G", "H", "I", "J", "K", "L", "M"];
    for (let i = 0; i < centerAlign.length; i++) {
      sheet1.range(centerAlign[i] + "2:" + centerAlign[i] + dataLength).style({
        horizontalAlignment: "center"
      });
      
    }
    let valFormat = ["B", "C", "D", "E", "F","G", "H", "I", "J", "K", "L", "M"];
    // Styls wrapText
    valFormat.forEach((data) => {
      sheet1.range(`${data}2:${data}` + dataLength).style({
        numberFormat: "Rp#,###",
        // horizontalAlignment: "right",
      });
    })
    range.style("border", true);
    sheet1.column("A").width(40);
    sheet1.column("B").width(17);
    sheet1.column("C").width(23);
    sheet1.column("D").width(23);
    sheet1.column("E").width(23);
    sheet1.column("F").width(23);
    sheet1.column("G").width(23);
    sheet1.column("H").width(23);
    sheet1.column("I").width(23);
    sheet1.column("J").width(23);
    sheet1.column("K").width(23);
    sheet1.column("L").width(23);
    sheet1.column("M").width(23);
    return workbook.outputAsync().then((res) => {
      saveAs(res, "Laporan (" + title + " " + currDate + ").xlsx");
    });
  });
}

export { saveAsExcel, getSheetData }
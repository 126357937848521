import React, { useEffect, useState } from "react";
import {
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { separatorHarga } from "../../../services/separator-harga";
import { useHistory } from "react-router-dom";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import { Button } from "react-bootstrap";
import { PenjualanAPI } from "../../../api/penjualan";

const rupiah = (number)=>{
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR"
  }).format(number);
}
function Detail({ data, dataFromModel }) {
  const API = new PenjualanAPI();
  const history = useHistory();
  const [master, setMaster] = useState([])
  const [detail, setDetail] = useState([])
  const [idDetail, setIdDetail] = useState([])

  useEffect(() => {
    let abortController = new AbortController();
    data.map((val) => {
      setMaster(val.master)
      setDetail(val.detail)
      val.detail.map((data) =>
        idDetail.push(data.id)
        // setIdDetail(data.detail.id)
      )
      return val
    })
    return () => {
      abortController.abort()
    }
  }, [data])

  return (
    <>
      <AnimatedCard>
        <CardHeader title={'Detail'}>
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-secondary mx-1"
              onClick={() => history.goBack()}
            >
              <i className="fa fa-arrow-left"></i>
              Kembali
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <form action="#" className="form form-label-right">
            <div className="row">
              <div className="form-group col-lg-6">
                <label>Tanggal Invoice</label>
                <input type="text" className="form-control" name="tanggal" placeholder="Tanggal Invoice" autoComplete="off" disabled defaultValue={master.tanggal_invoice} />
              </div>
              <div className="form-group col-lg-6">
                <label>Jatuh Tempo</label>
                <input type="text" className="form-control" name="jatuh_tempo" placeholder="Jatuh Tempo" autoComplete="off" disabled defaultValue={master.jatuh_tempo} />
              </div>
              <div className="form-group col-lg-6">
                <label>Customer</label>
                <input type="text" className="form-control" name="nama_customer" placeholder="Customer" autoComplete="off" disabled defaultValue={master.nama_customer} />
              </div>
              <div className="form-group col-lg-6">
                <label>No Transaksi</label>
                <input type="text" className="form-control" name="no_transaksi" placeholder="No Transaksi" autoComplete="off" disabled defaultValue={master.no_transaksi} />
              </div>
              <div className="form-group col-lg-6">
                <label>Jenis Pembayaran</label>
                <input type="text" className="form-control" placeholder="Jenis Pembayaran" autoComplete="off" disabled defaultValue={master.jenis_pembayaran === 1 ? "Cash" : "Kredit"} />
              </div>
              <div className="form-group col-lg-6">
                <label>Nama Sales</label>
                <input type="text" className="form-control" placeholder="Nama Sales" autoComplete="off" disabled defaultValue={master.nama_sales} />
              </div>
            </div>
            <span className="d-flex align-items-center justify-content-between mt-4">
              <h6 className="MuiTypography-root MuiTypography-h6">Detail Item</h6>
            </span>
            <div className="react-bootstrap-table table-responsive">
              <table className="table table table-head-custom table-vertical-center overflow-hidden">
                <thead>
                  <tr className="text-center">
                    <th tabIndex="0" aria-label="No sortable" className="sortable">No <span className="order">
                      <span className="dropdown">
                        <span className="caret"></span>
                      </span>
                      <span className="dropup">
                        <span className="caret"></span>
                      </span>
                    </span>
                    </th>
                    <th tabIndex="0" aria-label="Produk sortable" className="sortable">Produk <span className="order">
                      <span className="dropdown">
                        <span className="caret"></span>
                      </span>
                      <span className="dropup">
                        <span className="caret"></span>
                      </span>
                    </span>
                    </th>
                    <th tabIndex="0" aria-label="Harga sortable" className="sortable">Harga <span className="order">
                      <span className="dropdown">
                        <span className="caret"></span>
                      </span>
                      <span className="dropup">
                        <span className="caret"></span>
                      </span>
                    </span>
                    </th>
                    <th tabIndex="0" aria-label="QTY sortable" className="sortable">QTY <span className="order">
                      <span className="dropdown">
                        <span className="caret"></span>
                      </span>
                      <span className="dropup">
                        <span className="caret"></span>
                      </span>
                    </span>
                    </th>
                    <th tabIndex="0" aria-label="Diskon Rupiah sortable" className="sortable">Diskon Rupiah <span className="order">
                      <span className="dropdown">
                        <span className="caret"></span>
                      </span>
                      <span className="dropup">
                        <span className="caret"></span>
                      </span>
                    </span>
                    </th>
                    <th tabIndex="0" aria-label="Pajak Rupiah sortable" className="sortable">Pajak Rupiah <span className="order">
                      <span className="dropdown">
                        <span className="caret"></span>
                      </span>
                      <span className="dropup">
                        <span className="caret"></span>
                      </span>
                    </span>
                    </th>
                    <th tabIndex="0" aria-label="Sub Total sortable" className="sortable">Sub Total <span className="order">
                      <span className="dropdown">
                        <span className="caret"></span>
                      </span>
                      <span className="dropup">
                        <span className="caret"></span>
                      </span>
                    </span>
                    </th>
                    <th tabIndex="0" aria-label="Total sort asc" className="sortable">Total <span className="react-bootstrap-table-sort-order dropup">
                      <span className="caret"></span>
                    </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {detail.map((data, i) => (
                    <tr key={i} className="text-center">
                      <td className="text-nowrap">{i + 1}</td>
                      <td className="text-nowrap">{data.toProduk.nama}</td>
                      <td className="text-nowrap">Rp. {separatorHarga(data.hpp.toString())}</td>
                      <td className="text-nowrap">{data.qty}</td>
                      <td className="text-nowrap">Rp. {separatorHarga(data.diskon.toString())}</td>
                      <td className="text-nowrap">Rp. {separatorHarga(data.pajak.toString())}</td>
                      <td className="text-nowrap">Rp. {separatorHarga(data.subtotal.toString())}</td>
                      <td className="text-nowrap">Rp. {separatorHarga(data.total.toString())}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex row" style={{ marginTop: '50px' }}>
              <div className="form-group col-md-4 justify-content-around">
                <label>QTY Total</label>
                <input type="text" className="form-control" name="qty_total" placeholder="QTY Total" autoComplete="off" disabled value={parseInt(master.qty_total)}/>
              </div>
              <div className="form-group col-md-4 justify-content-around">
                <label>Total Harga</label>
                <input type="text" className="form-control" name="hpp_total" placeholder="Total Harga" autoComplete="off" disabled defaultValue={master.hpp_total} />
              </div>
              <div className="form-group col-md-4 justify-content-around">
                <label>Total Diskon</label>
                <input type="text" className="form-control" name="diskon_total" placeholder="Total Diskon" autoComplete="off" disabled defaultValue={master.diskon_total} />
              </div>
              <div className="form-group col-md-4 justify-content-around">
                <label>Sub Total</label>
                <input type="text" className="form-control" name="subtotal" placeholder="Sub Total" autoComplete="off" disabled defaultValue={master.subtotal} />
              </div>
              <div className="form-group col-md-4 justify-content-around">
                <label>Total Pajak</label>
                <input type="text" className="form-control" name="pajak_total" placeholder="Total Pajak" autoComplete="off" disabled defaultValue={master.pajak_total} />
              </div>
              <div className="form-group col-md-4 justify-content-around">
                <label>Total</label>
                <input type="text" className="form-control" name="total" placeholder="Total" autoComplete="off" disabled defaultValue={master.total} />
              </div>
            </div>
          </form>
        </CardBody>
        <CardFooter>
          {dataFromModel.is_approved === 0 ? (
            <div className="d-flex justify-content-end">
              <Button size="sm"
                className="mx-1"
                variant="primary"
                onClick={async () => {
                  let sendData = {
                    master: master,
                    id_pesanan_penjualan: master.id_pesanan_penjualan,
                    id: dataFromModel.id,
                    id_detail: idDetail
                  }
                  // console.log('SendData',sendData);
                  await API.approveRetur(sendData).then(res => {
                    if (res.status === true) {
                      setMaster([])
                      setIdDetail([])
                      history.goBack()
                    }
                  })
                }}
              >
                <i className="fas fa-save"></i>
                Approve
              </Button>
            </div>
          ) : ""
          }
        </CardFooter>
      </AnimatedCard>
    </>
  );
}

export default Detail;
import React from 'react';
export default class KonfigurasiMenuModel {
  constructor(
    data = {
      id: 0,
      id_parent: null,
      nama_menu: "",
      nama_parent: "",
      nama_path: "",
      icon_menu: "",
      urutan: "",
      status: 0,
    }
  ) {
    const { id, id_parent, nama_menu, nama_parent, nama_path, icon_menu, urutan, status } = data;
    this['id'] = id;
    this['id_parent'] = id_parent;
    this['nama_menu'] = nama_menu;
    this['nama_parent'] = nama_parent;
    this['nama_path'] = nama_path;
    this['icon_menu'] = icon_menu;
    this['urutan'] = urutan;
    this['status'] = status;

  }

  async getdata() {
    const { nama_menu, nama_parent, nama_path, icon_menu, urutan } = this;
    return [{
      id: "nama_menu",
      title: "Nama Menu",
      label: nama_menu,
      sortable: true,
      minWidth: "30%",
    }, {
      id: "nama_parent",
      title: "Parent Menu",
      label: nama_parent,
      sortable: true,
      minWidth: "20%",
    }, {
      id: "nama_path",
      title: "Path",
      label: nama_path,
      sortable: true,
      minWidth: "30%",
    }, {
      id: "icon_menu",
      title: "Icon",
      label: <><span className={`${icon_menu} icon-xl mr-3`} /> {`${icon_menu}`}</>,
      sortable: false,
      minWidth: "25%",
    }, {
      id: "urutan",
      title: "Urutan",
      label: urutan,
      center: true,
      sortable: true,
      minWidth: "10%",
    }];
  }
}

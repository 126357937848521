import { CardBody, CardFooter, CardHeader } from "../../../../../../_metronic/_partials/controls";
import React, { Component } from "react";

import { AnimatedCard } from "../../../../../components/animated-card/animatedCard";
import DataTableModel from "../../../../../models/datatable-model/DataTableModel";
import { Formik } from "formik";
import { FormsFields } from "../../../../../components/forms-fields/FormsFields";
import ModelPengeluaranOperasional from "../../../../../models/kasir/bank-operasional/pengeluaranBankOperasionalApprovalModel";
import { ReactDatatable } from "../../../../../components/react-datatable/reactDatatable";
import SimpleAlert from "../../../../../components/alert/alert";
import { dispatcher } from "../../../../../redux/dispatcher";
import { getIdPerusahaan } from "../../../../../api/api";
import { kasirBankOperasionalAPI } from "../../../../../api/kasir-bank-operasional";
import store from "../../../../../redux/store";
import * as Yup from "yup";
import ButtonActions from "../../../../../components/button-actions/buttonActions";
import ButtonLoad from "../../../../../components/button-loading/buttonLoad";
import moment from "moment";
import { export_excel } from "../../../../../components/export-excel/export_excel";

export default class MutasiPengeluaranBankOperasional extends Component
{
  constructor( props )
  {
    super( props );
    this[ 'pagename' ] = "Mutasi Pengeluaran Bank Operasional";
    this[ 'searchParameter' ] = [ "no_transaksi", "no_bku", "id_perusahaan", "kontak_ref", "tanggal", "akun", "total", "deskripsi" ];
    this[ 'apiPengeluaranBankOperasional' ] = new kasirBankOperasionalAPI();
    this[ 'newDateMonth' ] = ( new Date().getMonth() + 1 ).toString();
    this['export_excel'] = new export_excel();
    this[ 'month' ] = this[ 'newDateMonth' ].length > 1 ? this[ 'newDateMonth' ] : "0" + this[ 'newDateMonth' ];
    this[ 'options_cabang' ] = [];
    this[ 'state' ] = {
      pending: true,
      isSubmitting: false,
      initialValues: {
        begin_date: new Date( this[ 'month' ] + "-01-" + new Date().getFullYear() ),
        end_date: new Date(),
        id_perusahaan: getIdPerusahaan(),
        no_transaksi: "",
        kepada: "",
      },
      data: [],
      alert: {
        initialValueAlert: {
          id: null,
          id_user: null,
          tanggal_bku: null,
        },
        show: false,
      },
    };
    this[ 'datatable' ] = [ { id: "", no: "", name: "" } ];
    this[ 'columntable' ] = [ { id: "", title: "" } ];
  }
  //* ----------------------------------------------------------------------------- */
  //** Start: Component */
  async componentDidMount ()
  {
    await this.LoadData( this[ 'state' ][ 'initialValues' ] );
    // Get Cabang Coverage
    this[ 'apiPengeluaranBankOperasional' ].getCoverage().then( ( values ) =>
    {
      this[ 'options_cabang' ] = values[ 'data' ].map( ( data ) => ( {
        label: data[ 'text' ],
        value: data[ 'id' ],
      } ) );
    } );
  }
  //** End: Component */
  //* ----------------------------------------------------------------------------- */
  //** Start: Load Data List */
  async LoadData ( form )
  {
    this.setState( { pending: true } );
    await dispatcher[ 'kasir_bank_operasional' ].listPengeluaranBankOperasional( form );
    let data = dispatcher[ 'kasir_bank_operasional' ].getListPengeluaranBankOperasional();
    this.setState({ data: data });
    this[ 'DataTableModel' ] = new DataTableModel();
    data.map( ( item, i ) =>
    {
      let dataModel = new ModelPengeluaranOperasional( item );
      const { id, approval_accounting_by, approval_administrasi_by, is_approved, status } = item[ 'transaksi' ];
      if ( status === 1 )
        this[ 'DataTableModel' ].add( {
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: (
            <>
              { is_approved === 0 && approval_administrasi_by !== 0 && approval_accounting_by !== 0 ? (
                <ButtonActions
                  label={ "mutasi" }
                  onClick={ () =>
                  {
                    this[ 'handleAlert' ].show( id );
                  } }
                />
              ) : "" }
              <ButtonActions
                label={ "detailsLink" }
                linkTo={ "/kasir/mutasi-pengeluaran-bank-operasional/detail" }
                onClick={ () =>
                {
                  dispatcher[ 'kasir_bank_operasional' ].setPengeluaranBankOperasional( dataModel );
                } }
              />
            </>
          ),
        } );
      return item;
    } );
    this[ 'columntable' ] = await this[ 'DataTableModel' ].getColumn();
    this[ 'datatable' ] = await this[ 'DataTableModel' ].getDatas();
    this.setState( { ...this[ 'state' ], pending: false } );
  }
  //** End: Load Detail Table */
  //* ----------------------------------------------------------------------------- */
  //** Start: Handle Alert */
  handleAlert = {
    show: ( id ) =>
    {
      this.setState( {
        ...alert,
        alert: {
          show: true,
          initialValueAlert: {
            id: id,
            id_user: store.getState().auth[ 'id' ],
            tanggal_bku: null,
          }
        }
      } );
    },
    hide: () =>
    {
      this.setState( {
        ...alert, alert: {
          show: false,
          initialValueAlert: {
            id: null,
            id_user: null,
            tanggal_bku: null,
          }
        }
      } );
    }
  };
  //** End: Handle Alert */
  render ()
  {
    const { pending, initialValues,data, alert } = this[ 'state' ];
    return (
      <>
        {/* Start: Formik List Approval */ }
        <Formik
          initialValues={ initialValues }
          onSubmit={ ( values ) =>
          {
            this.LoadData( values );
            this.setState( { initialValues: values } );
          } }
        >
          { ( { setFieldValue, setFieldTouched, touched, errors, values, handleSubmit } ) => (
            <AnimatedCard>
              <CardHeader title={ this[ 'pagename' ] }></CardHeader>
              <CardBody style={ { marginTop: "-20px" } }>
                <div className={ "form-group row" }>
                  {/* Forms Start Date  */ }
                  <FormsFields
                    id={ "begin_date" }
                    type={ "DatePicker" }
                    label={ "Tanggal Mulai" }
                    className={ "col-lg-4" }
                    style={ { marginTop: "20px" } }
                    paramsFormik={ {
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    } }
                  />
                  {/* Forms End Date  */ }
                  <FormsFields
                    id={ "end_date" }
                    type={ "DatePicker" }
                    label={ "Tanggal Akhir" }
                    className={ "col-lg-4" }
                    style={ { marginTop: "20px" } }
                    paramsFormik={ {
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    } }
                  />
                  {/* Forms Select Cabang */ }
                  <FormsFields
                    id={ "id_perusahaan" }
                    type={ "select2" }
                    label={ "Cabang" }
                    style={ { marginTop: "20px" } }
                    className={ "col-lg-4" }
                    isClearable={ true }
                    isSearchable={ true }
                    options={ this[ 'options_cabang' ] }
                    paramsFormik={ {
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    } }
                  />
                  {/* No. Transaksi/No. BKU */ }
                  <FormsFields
                    id={ "no_transaksi" }
                    type={ "text" }
                    label={ "No. Transaksi/No. BKU" }
                    className={ "col-lg-4" }
                    style={ { marginTop: "20px" } }
                    paramsFormik={ {
                      setFieldValue,
                      values
                    } }
                  />

                  {/* Kepada */ }
                  <FormsFields
                    id={ "kepada" }
                    type={ "text" }
                    label={ "Kepada" }
                    className={ "col-lg-4" }
                    style={ { marginTop: "20px" } }
                    paramsFormik={ {
                      setFieldValue,
                      values
                    } }
                  />
                </div>
                <div style={ { display: "flex", justifyContent: "center" } } >
                  {/* Button Lihat Data */ }
                  <ButtonLoad
                    label={ "Lihat Data" }
                    pending={ pending }
                    classNameIcon={ "fa fa-filter" }
                    className={ "btn btn-outline-success" }
                    onClick={ async () => handleSubmit() }
                  />
                  {/* Button Export Excel */}
                  <ButtonLoad
                      label={"Export .xls"}
                      pending={pending}
                      classNameIcon={"far fa-file-excel"}
                      className={"btn btn-outline-primary"}
                      disabled={data.length === 0}
                      onClick={async () => {
                        let startDate = moment(values['begin_date']).format("L");
                        let endDate = moment(values['end_date']).format("L");
                        let times = moment().format('h.mm.ss a');
                        const dateFilter = (startDate === endDate) ? startDate : startDate + " - " + endDate;

                        let record = [];
                        data.map((val, i) => {
                          const { no_transaksi, no_transaksi_bku, tanggal_approved, tanggal, cabang, kontak_ref, akun, nama_bank, no_rekening, total, deskripsi } = val['transaksi'];
                          record.push({
                            no_transaksi: no_transaksi,
                            tanggal: tanggal ? moment(tanggal).format("DD-MM-YYYY") : "",
                            no_transaksi_bku: no_transaksi_bku,
                            tanggal_approved: tanggal_approved ? moment(tanggal_approved).format("DD-MM-YYYY") : "",
                            cabang: cabang,
                            kontak_ref: kontak_ref,
                            akun: akun,
                            nama_bank: nama_bank,
                            no_rekening: no_rekening,
                            total: total,
                            deskripsi: deskripsi,
                          });
                          return val;
                        });

                        let datas = {
                          headerTitle: "PENGELUARAN BANK OPERASIONAL",
                          title1: "",
                          title2: "",
                          headerField: ["NO. TRANSAKSI", "TANGGAL", "NO. BUKTI BKU", "TANGGAL APPROVED", "CABANG", "KEPADA", "AKUN", "NAMA BANK", "NO. REKENING", "TOTAL", "KETERANGAN"],
                          headerField2: [],
                          recordData: record,
                          recordData2: [],
                          totalSaldoAwal: false,
                          totalSaldoAkhir: false,
                          footerSubTotal: [],
                          footerSubTotal1: [],
                          columnWidth: [35, 17, 35, 23, 23, 40, 20, 35, 20, 20, 80],
                          centerAlign: ["A", "B", "C", "D", "E", "G", "I"],
                          wrapText: ["F", "K"],
                          numberFormatRp: ["J"],
                          fileName: `Kasir Pengeluaran Operasional (${dateFilter} - ${times})`
                        }
                        this['export_excel'].saveAsExcel(datas);
                      }}
                    />
                </div>
              </CardBody>
              <CardFooter>
                <ReactDatatable
                  title={ this[ 'pageName' ] }
                  columns={ this[ 'columntable' ] }
                  data={ this[ 'datatable' ] }
                  searchParameter={ this[ 'searchParameter' ] }
                  progressPending={ pending }
                />
              </CardFooter>
            </AnimatedCard>
          ) }
        </Formik>
        {/* End: Formik List Approval */ }
        {/* ----------------------------------------------------------------------------- */ }
        {/* Start: Alert Approved */ }
        <Formik
          enableReinitialize={ true }
          initialValues={ alert[ 'initialValueAlert' ] }
          validationSchema={ Yup.object().shape( {
            tanggal_bku: Yup.string().nullable().required(),
          } ) }
          onSubmit={ async ( values, { resetForm } ) =>
          {
            await this[ 'apiPengeluaranBankOperasional' ].approval( values ).then( ( res ) =>
            {
              if ( res[ 'status' ] )
              {
                dispatcher[ 'snackbar' ].show( "Berhasil", res[ 'message' ], "primary" );
                this[ 'handleAlert' ].hide();
                this.LoadData( this[ 'state' ][ 'initialValues' ] );
              } else
              {
                return dispatcher[ 'snackbar' ].show( "Gagal", res[ 'message' ], "danger" );
              }
            } );
          } }
        >
          { ( { handleSubmit, setFieldValue, setFieldTouched, touched, errors, values } ) => (
            <SimpleAlert
              title={ "Approve Transaksi" }
              content={
                <>
                  <div className={ "form-group row" }>
                    <div className={ "mb-3" }>
                      <span>Anda yakin ingin mutasi transaksi ini...?</span>
                    </div>
                    {/* Tanggal */ }
                    <FormsFields
                      id={ "tanggal_bku" }
                      type={ "DatePicker" }
                      label={ "Tanggal BKU" }
                      className={ "col-lg-12" }
                      paramsFormik={ {
                        setFieldValue,
                        setFieldTouched,
                        touched,
                        errors,
                        values
                      } }
                    />
                  </div>
                </>
              }
              show={ alert[ 'show' ] }
              onHide={ () => this[ 'handleAlert' ].hide() }
              variant={ "primary" }
              label={ "Approve" }
              onConfirmed={ handleSubmit }
            />
          ) }
        </Formik>
        {/* End: Alert Approved */ }
      </>
    );
  }
}

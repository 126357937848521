import * as Yup from "yup";
import { CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import DataTableModel from "../../../models/datatable-model/DataTableModel";
import { Formik } from "formik";
import { FormsFields } from "../../../components/forms-fields/FormsFields";
import { ModalForms } from "../../../components/modal-forms/modalForms";
import React from "react";
import { ReactDatatable } from "../../../components/react-datatable/reactDatatable";
import SimpleAlert from "../../../components/alert/alert";
import { dispatcher } from "../../../redux/dispatcher";
import { KonfigurasiMenuAPI } from "../../../api/menu";
import KonfigurasiMenuModel from '../../../models/konfigurasi-menu-model/KonfigurasiMenuModel';
import ButtonActions from "../../../components/button-actions/buttonActions";

function cheatsheet() {
  return [
    "fa fa-ad",
    "fa fa-address-book",
    "fa fa-address-card",
    "fa fa-adjust",
    "fa fa-air-freshener",
    "fa fa-align-center",
    "fa fa-align-justify",
    "fa fa-align-left",
    "fa fa-align-right",
    "fa fa-allergies",
    "fa fa-ambulance",
    "fa fa-american-sign-language-interpreting",
    "fa fa-anchor",
    "fa fa-angle-double-down",
    "fa fa-angle-double-left",
    "fa fa-angle-double-right",
    "fa fa-angle-double-up",
    "fa fa-angle-down",
    "fa fa-angle-left",
    "fa fa-angle-right",
    "fa fa-angle-up",
    "fa fa-angry",
    "fa fa-ankh",
    "fa fa-apple-alt",
    "fa fa-archive",
    "fa fa-archway",
    "fa fa-arrow-alt-circle-down",
    "fa fa-arrow-alt-circle-left",
    "fa fa-arrow-alt-circle-right",
    "fa fa-arrow-alt-circle-up",
    "fa fa-arrow-circle-down",
    "fa fa-arrow-circle-left",
    "fa fa-arrow-circle-right",
    "fa fa-arrow-circle-up",
    "fa fa-arrow-down",
    "fa fa-arrow-left",
    "fa fa-arrow-right",
    "fa fa-arrow-up",
    "fa fa-arrows-alt",
    "fa fa-arrows-alt-h",
    "fa fa-arrows-alt-v",
    "fa fa-assistive-listening-systems",
    "fa fa-asterisk",
    "fa fa-at",
    "fa fa-atlas",
    "fa fa-atom",
    "fa fa-audio-description",
    "fa fa-award",
    "fa fa-baby",
    "fa fa-baby-carriage",
    "fa fa-backspace",
    "fa fa-backward",
    "fa fa-bacon",
    "fa fa-bahai",
    "fa fa-balance-scale",
    "fa fa-balance-scale-left",
    "fa fa-balance-scale-right",
    "fa fa-ban",
    "fa fa-band-aid",
    "fa fa-barcode",
    "fa fa-bars",
    "fa fa-baseball-ball",
    "fa fa-basketball-ball",
    "fa fa-bath",
    "fa fa-battery-empty",
    "fa fa-battery-full",
    "fa fa-battery-half",
    "fa fa-battery-quarter",
    "fa fa-battery-three-quarters",
    "fa fa-bed",
    "fa fa-beer",
    "fa fa-bell",
    "fa fa-bell-slash",
    "fa fa-bezier-curve",
    "fa fa-bible",
    "fa fa-bicycle",
    "fa fa-biking",
    "fa fa-binoculars",
    "fa fa-biohazard",
    "fa fa-birthday-cake",
    "fa fa-blender",
    "fa fa-blender-phone",
    "fa fa-blind",
    "fa fa-blog",
    "fa fa-bold",
    "fa fa-bolt",
    "fa fa-bomb",
    "fa fa-bone",
    "fa fa-bong",
    "fa fa-book",
    "fa fa-book-dead",
    "fa fa-book-medical",
    "fa fa-book-open",
    "fa fa-book-reader",
    "fa fa-bookmark",
    "fa fa-border-all",
    "fa fa-border-none",
    "fa fa-border-style",
    "fa fa-bowling-ball",
    "fa fa-box",
    "fa fa-box-open",
    "fa fa-boxes",
    "fa fa-braille",
    "fa fa-brain",
    "fa fa-bread-slice",
    "fa fa-briefcase",
    "fa fa-briefcase-medical",
    "fa fa-broadcast-tower",
    "fa fa-broom",
    "fa fa-brush",
    "fa fa-bug",
    "fa fa-building",
    "fa fa-bullhorn",
    "fa fa-bullseye",
    "fa fa-burn",
    "fa fa-bus",
    "fa fa-bus-alt",
    "fa fa-business-time",
    "fa fa-calculator",
    "fa fa-calendar",
    "fa fa-calendar-alt",
    "fa fa-calendar-check",
    "fa fa-calendar-day",
    "fa fa-calendar-minus",
    "fa fa-calendar-plus",
    "fa fa-calendar-times",
    "fa fa-calendar-week",
    "fa fa-camera",
    "fa fa-camera-retro",
    "fa fa-campground",
    "fa fa-candy-cane",
    "fa fa-cannabis",
    "fa fa-capsules",
    "fa fa-car",
    "fa fa-car-alt",
    "fa fa-car-battery",
    "fa fa-car-crash",
    "fa fa-car-side",
    "fa fa-caravan",
    "fa fa-caret-down",
    "fa fa-caret-left",
    "fa fa-caret-right",
    "fa fa-caret-square-down",
    "fa fa-caret-square-left",
    "fa fa-caret-square-right",
    "fa fa-caret-square-up",
    "fa fa-caret-up",
    "fa fa-carrot",
    "fa fa-cart-arrow-down",
    "fa fa-cart-plus",
    "fa fa-cash-register",
    "fa fa-cat",
    "fa fa-certificate",
    "fa fa-chair",
    "fa fa-chalkboard",
    "fa fa-chalkboard-teacher",
    "fa fa-charging-station",
    "fa fa-chart-area",
    "fa fa-chart-bar",
    "fa fa-chart-line",
    "fa fa-chart-pie",
    "fa fa-check",
    "fa fa-check-circle",
    "fa fa-check-double",
    "fa fa-check-square",
    "fa fa-cheese",
    "fa fa-chess",
    "fa fa-chess-bishop",
    "fa fa-chess-board",
    "fa fa-chess-king",
    "fa fa-chess-knight",
    "fa fa-chess-pawn",
    "fa fa-chess-queen",
    "fa fa-chess-rook",
    "fa fa-chevron-circle-down",
    "fa fa-chevron-circle-left",
    "fa fa-chevron-circle-right",
    "fa fa-chevron-circle-up",
    "fa fa-chevron-down",
    "fa fa-chevron-left",
    "fa fa-chevron-right",
    "fa fa-chevron-up",
    "fa fa-child",
    "fa fa-church",
    "fa fa-circle",
    "fa fa-circle-notch",
    "fa fa-city",
    "fa fa-clinic-medical",
    "fa fa-clipboard",
    "fa fa-clipboard-check",
    "fa fa-clipboard-list",
    "fa fa-clock",
    "fa fa-clone",
    "fa fa-closed-captioning",
    "fa fa-cloud",
    "fa fa-cloud-download-alt",
    "fa fa-cloud-meatball",
    "fa fa-cloud-moon",
    "fa fa-cloud-moon-rain",
    "fa fa-cloud-rain",
    "fa fa-cloud-showers-heavy",
    "fa fa-cloud-sun",
    "fa fa-cloud-sun-rain",
    "fa fa-cloud-upload-alt",
    "fa fa-cocktail",
    "fa fa-code",
    "fa fa-code-branch",
    "fa fa-coffee",
    "fa fa-cog",
    "fa fa-cogs",
    "fa fa-coins",
    "fa fa-columns",
    "fa fa-comment",
    "fa fa-comment-alt",
    "fa fa-comment-dollar",
    "fa fa-comment-dots",
    "fa fa-comment-medical",
    "fa fa-comment-slash",
    "fa fa-comments",
    "fa fa-comments-dollar",
    "fa fa-compact-disc",
    "fa fa-compass",
    "fa fa-compress",
    "fa fa-compress-alt",
    "fa fa-compress-arrows-alt",
    "fa fa-concierge-bell",
    "fa fa-cookie",
    "fa fa-cookie-bite",
    "fa fa-copy",
    "fa fa-copyright",
    "fa fa-couch",
    "fa fa-credit-card",
    "fa fa-crop",
    "fa fa-crop-alt",
    "fa fa-cross",
    "fa fa-crosshairs",
    "fa fa-crow",
    "fa fa-crown",
    "fa fa-crutch",
    "fa fa-cube",
    "fa fa-cubes",
    "fa fa-cut",
    "fa fa-database",
    "fa fa-deaf",
    "fa fa-democrat",
    "fa fa-desktop",
    "fa fa-dharmachakra",
    "fa fa-diagnoses",
    "fa fa-dice",
    "fa fa-dice-d20",
    "fa fa-dice-d6",
    "fa fa-dice-five",
    "fa fa-dice-four",
    "fa fa-dice-one",
    "fa fa-dice-six",
    "fa fa-dice-three",
    "fa fa-dice-two",
    "fa fa-digital-tachograph",
    "fa fa-directions",
    "fa fa-divide",
    "fa fa-dizzy",
    "fa fa-dna",
    "fa fa-dog",
    "fa fa-dollar-sign",
    "fa fa-dolly",
    "fa fa-dolly-flatbed",
    "fa fa-donate",
    "fa fa-door-closed",
    "fa fa-door-open",
    "fa fa-dot-circle",
    "fa fa-dove",
    "fa fa-download",
    "fa fa-drafting-compass",
    "fa fa-dragon",
    "fa fa-draw-polygon",
    "fa fa-drum",
    "fa fa-drum-steelpan",
    "fa fa-drumstick-bite",
    "fa fa-dumbbell",
    "fa fa-dumpster",
    "fa fa-dumpster-fire",
    "fa fa-dungeon",
    "fa fa-edit",
    "fa fa-egg",
    "fa fa-eject",
    "fa fa-ellipsis-h",
    "fa fa-ellipsis-v",
    "fa fa-envelope",
    "fa fa-envelope-open",
    "fa fa-envelope-open-text",
    "fa fa-envelope-square",
    "fa fa-equals",
    "fa fa-eraser",
    "fa fa-ethernet",
    "fa fa-euro-sign",
    "fa fa-exchange-alt",
    "fa fa-exclamation",
    "fa fa-exclamation-circle",
    "fa fa-exclamation-triangle",
    "fa fa-expand",
    "fa fa-expand-alt",
    "fa fa-expand-arrows-alt",
    "fa fa-external-link-alt",
    "fa fa-external-link-square-alt",
    "fa fa-eye",
    "fa fa-eye-dropper",
    "fa fa-eye-slash",
    "fa fa-fan",
    "fa fa-fast-backward",
    "fa fa-fast-forward",
    "fa fa-fax",
    "fa fa-feather",
    "fa fa-feather-alt",
    "fa fa-female",
    "fa fa-fighter-jet",
    "fa fa-file",
    "fa fa-file-alt",
    "fa fa-file-archive",
    "fa fa-file-audio",
    "fa fa-file-code",
    "fa fa-file-contract",
    "fa fa-file-csv",
    "fa fa-file-download",
    "fa fa-file-excel",
    "fa fa-file-export",
    "fa fa-file-image",
    "fa fa-file-import",
    "fa fa-file-invoice",
    "fa fa-file-invoice-dollar",
    "fa fa-file-medical",
    "fa fa-file-medical-alt",
    "fa fa-file-pdf",
    "fa fa-file-powerpoint",
    "fa fa-file-prescription",
    "fa fa-file-signature",
    "fa fa-file-upload",
    "fa fa-file-video",
    "fa fa-file-word",
    "fa fa-fill",
    "fa fa-fill-drip",
    "fa fa-film",
    "fa fa-filter",
    "fa fa-fingerprint",
    "fa fa-fire",
    "fa fa-fire-alt",
    "fa fa-fire-extinguisher",
    "fa fa-first-aid",
    "fa fa-fish",
    "fa fa-fist-raised",
    "fa fa-flag",
    "fa fa-flag-checkered",
    "fa fa-flag-usa",
    "fa fa-flask",
    "fa fa-flushed",
    "fa fa-folder",
    "fa fa-folder-minus",
    "fa fa-folder-open",
    "fa fa-folder-plus",
    "fa fa-font",
    "fa fa-football-ball",
    "fa fa-forward",
    "fa fa-frog",
    "fa fa-frown",
    "fa fa-frown-open",
    "fa fa-funnel-dollar",
    "fa fa-futbol",
    "fa fa-gamepad",
    "fa fa-gas-pump",
    "fa fa-gavel",
    "fa fa-gem",
    "fa fa-genderless",
    "fa fa-ghost",
    "fa fa-gift",
    "fa fa-gifts",
    "fa fa-glass-cheers",
    "fa fa-glass-martini",
    "fa fa-glass-martini-alt",
    "fa fa-glass-whiskey",
    "fa fa-glasses",
    "fa fa-globe",
    "fa fa-globe-africa",
    "fa fa-globe-americas",
    "fa fa-globe-asia",
    "fa fa-globe-europe",
    "fa fa-golf-ball",
    "fa fa-gopuram",
    "fa fa-graduation-cap",
    "fa fa-greater-than",
    "fa fa-greater-than-equal",
    "fa fa-grimace",
    "fa fa-grin",
    "fa fa-grin-alt",
    "fa fa-grin-beam",
    "fa fa-grin-beam-sweat",
    "fa fa-grin-hearts",
    "fa fa-grin-squint",
    "fa fa-grin-squint-tears",
    "fa fa-grin-stars",
    "fa fa-grin-tears",
    "fa fa-grin-tongue",
    "fa fa-grin-tongue-squint",
    "fa fa-grin-tongue-wink",
    "fa fa-grin-wink",
    "fa fa-grip-horizontal",
    "fa fa-grip-lines",
    "fa fa-grip-lines-vertical",
    "fa fa-grip-vertical",
    "fa fa-guitar",
    "fa fa-h-square",
    "fa fa-hamburger",
    "fa fa-hammer",
    "fa fa-hamsa",
    "fa fa-hand-holding",
    "fa fa-hand-holding-heart",
    "fa fa-hand-holding-usd",
    "fa fa-hand-lizard",
    "fa fa-hand-middle-finger",
    "fa fa-hand-paper",
    "fa fa-hand-peace",
    "fa fa-hand-point-down",
    "fa fa-hand-point-left",
    "fa fa-hand-point-right",
    "fa fa-hand-point-up",
    "fa fa-hand-pointer",
    "fa fa-hand-rock",
    "fa fa-hand-scissors",
    "fa fa-hand-spock",
    "fa fa-hands",
    "fa fa-hands-helping",
    "fa fa-handshake",
    "fa fa-hanukiah",
    "fa fa-hard-hat",
    "fa fa-hashtag",
    "fa fa-hat-cowboy",
    "fa fa-hat-cowboy-side",
    "fa fa-hat-wizard",
    "fa fa-hdd",
    "fa fa-heading",
    "fa fa-headphones",
    "fa fa-headphones-alt",
    "fa fa-headset",
    "fa fa-heart",
    "fa fa-heart-broken",
    "fa fa-heartbeat",
    "fa fa-helicopter",
    "fa fa-highlighter",
    "fa fa-hiking",
    "fa fa-hippo",
    "fa fa-history",
    "fa fa-hockey-puck",
    "fa fa-holly-berry",
    "fa fa-home",
    "fa fa-horse",
    "fa fa-horse-head",
    "fa fa-hospital",
    "fa fa-hospital-alt",
    "fa fa-hospital-symbol",
    "fa fa-hot-tub",
    "fa fa-hotdog",
    "fa fa-hotel",
    "fa fa-hourglass",
    "fa fa-hourglass-end",
    "fa fa-hourglass-half",
    "fa fa-hourglass-start",
    "fa fa-house-damage",
    "fa fa-hryvnia",
    "fa fa-i-cursor",
    "fa fa-ice-cream",
    "fa fa-icicles",
    "fa fa-icons",
    "fa fa-id-badge",
    "fa fa-id-card",
    "fa fa-id-card-alt",
    "fa fa-igloo",
    "fa fa-image",
    "fa fa-images",
    "fa fa-inbox",
    "fa fa-indent",
    "fa fa-industry",
    "fa fa-infinity",
    "fa fa-info",
    "fa fa-info-circle",
    "fa fa-italic",
    "fa fa-jedi",
    "fa fa-joint",
    "fa fa-journal-whills",
    "fa fa-kaaba",
    "fa fa-key",
    "fa fa-keyboard",
    "fa fa-khanda",
    "fa fa-kiss",
    "fa fa-kiss-beam",
    "fa fa-kiss-wink-heart",
    "fa fa-kiwi-bird",
    "fa fa-landmark",
    "fa fa-language",
    "fa fa-laptop",
    "fa fa-laptop-code",
    "fa fa-laptop-medical",
    "fa fa-laugh",
    "fa fa-laugh-beam",
    "fa fa-laugh-squint",
    "fa fa-laugh-wink",
    "fa fa-layer-group",
    "fa fa-leaf",
    "fa fa-lemon",
    "fa fa-less-than",
    "fa fa-less-than-equal",
    "fa fa-level-down-alt",
    "fa fa-level-up-alt",
    "fa fa-life-ring",
    "fa fa-lightbulb",
    "fa fa-link",
    "fa fa-lira-sign",
    "fa fa-list",
    "fa fa-list-alt",
    "fa fa-list-ol",
    "fa fa-list-ul",
    "fa fa-location-arrow",
    "fa fa-lock",
    "fa fa-lock-open",
    "fa fa-long-arrow-alt-down",
    "fa fa-long-arrow-alt-left",
    "fa fa-long-arrow-alt-right",
    "fa fa-long-arrow-alt-up",
    "fa fa-low-vision",
    "fa fa-luggage-cart",
    "fa fa-magic",
    "fa fa-magnet",
    "fa fa-mail-bulk",
    "fa fa-male",
    "fa fa-map",
    "fa fa-map-marked",
    "fa fa-map-marked-alt",
    "fa fa-map-marker",
    "fa fa-map-marker-alt",
    "fa fa-map-pin",
    "fa fa-map-signs",
    "fa fa-marker",
    "fa fa-mars",
    "fa fa-mars-double",
    "fa fa-mars-stroke",
    "fa fa-mars-stroke-h",
    "fa fa-mars-stroke-v",
    "fa fa-mask",
    "fa fa-medal",
    "fa fa-medkit",
    "fa fa-meh",
    "fa fa-meh-blank",
    "fa fa-meh-rolling-eyes",
    "fa fa-memory",
    "fa fa-menorah",
    "fa fa-mercury",
    "fa fa-meteor",
    "fa fa-microchip",
    "fa fa-microphone",
    "fa fa-microphone-alt",
    "fa fa-microphone-alt-slash",
    "fa fa-microphone-slash",
    "fa fa-microscope",
    "fa fa-minus",
    "fa fa-minus-circle",
    "fa fa-minus-square",
    "fa fa-mitten",
    "fa fa-mobile",
    "fa fa-mobile-alt",
    "fa fa-money-bill",
    "fa fa-money-bill-alt",
    "fa fa-money-bill-wave",
    "fa fa-money-bill-wave-alt",
    "fa fa-money-check",
    "fa fa-money-check-alt",
    "fa fa-monument",
    "fa fa-moon",
    "fa fa-mortar-pestle",
    "fa fa-mosque",
    "fa fa-motorcycle",
    "fa fa-mountain",
    "fa fa-mouse",
    "fa fa-mouse-pointer",
    "fa fa-mug-hot",
    "fa fa-music",
    "fa fa-network-wired",
    "fa fa-neuter",
    "fa fa-newspaper",
    "fa fa-not-equal",
    "fa fa-notes-medical",
    "fa fa-object-group",
    "fa fa-object-ungroup",
    "fa fa-oil-can",
    "fa fa-om",
    "fa fa-otter",
    "fa fa-outdent",
    "fa fa-pager",
    "fa fa-paint-brush",
    "fa fa-paint-roller",
    "fa fa-palette",
    "fa fa-pallet",
    "fa fa-paper-plane",
    "fa fa-paperclip",
    "fa fa-parachute-box",
    "fa fa-paragraph",
    "fa fa-parking",
    "fa fa-passport",
    "fa fa-pastafarianism",
    "fa fa-paste",
    "fa fa-pause",
    "fa fa-pause-circle",
    "fa fa-paw",
    "fa fa-peace",
    "fa fa-pen",
    "fa fa-pen-alt",
    "fa fa-pen-fancy",
    "fa fa-pen-nib",
    "fa fa-pen-square",
    "fa fa-pencil-alt",
    "fa fa-pencil-ruler",
    "fa fa-people-carry",
    "fa fa-pepper-hot",
    "fa fa-percent",
    "fa fa-percentage",
    "fa fa-person-booth",
    "fa fa-phone",
    "fa fa-phone-alt",
    "fa fa-phone-slash",
    "fa fa-phone-square",
    "fa fa-phone-square-alt",
    "fa fa-phone-volume",
    "fa fa-photo-video",
    "fa fa-piggy-bank",
    "fa fa-pills",
    "fa fa-pizza-slice",
    "fa fa-place-of-worship",
    "fa fa-plane",
    "fa fa-plane-arrival",
    "fa fa-plane-departure",
    "fa fa-play",
    "fa fa-play-circle",
    "fa fa-plug",
    "fa fa-plus",
    "fa fa-plus-circle",
    "fa fa-plus-square",
    "fa fa-podcast",
    "fa fa-poll",
    "fa fa-poll-h",
    "fa fa-poo",
    "fa fa-poo-storm",
    "fa fa-poop",
    "fa fa-portrait",
    "fa fa-pound-sign",
    "fa fa-power-off",
    "fa fa-pray",
    "fa fa-praying-hands",
    "fa fa-prescription",
    "fa fa-prescription-bottle",
    "fa fa-prescription-bottle-alt",
    "fa fa-print",
    "fa fa-procedures",
    "fa fa-project-diagram",
    "fa fa-puzzle-piece",
    "fa fa-qrcode",
    "fa fa-question",
    "fa fa-question-circle",
    "fa fa-quidditch",
    "fa fa-quote-left",
    "fa fa-quote-right",
    "fa fa-quran",
    "fa fa-radiation",
    "fa fa-radiation-alt",
    "fa fa-rainbow",
    "fa fa-random",
    "fa fa-receipt",
    "fa fa-record-vinyl",
    "fa fa-recycle",
    "fa fa-redo",
    "fa fa-redo-alt",
    "fa fa-registered",
    "fa fa-remove-format",
    "fa fa-reply",
    "fa fa-reply-all",
    "fa fa-republican",
    "fa fa-restroom",
    "fa fa-retweet",
    "fa fa-ribbon",
    "fa fa-ring",
    "fa fa-road",
    "fa fa-robot",
    "fa fa-rocket",
    "fa fa-route",
    "fa fa-rss",
    "fa fa-rss-square",
    "fa fa-ruble-sign",
    "fa fa-ruler",
    "fa fa-ruler-combined",
    "fa fa-ruler-horizontal",
    "fa fa-ruler-vertical",
    "fa fa-running",
    "fa fa-rupee-sign",
    "fa fa-sad-cry",
    "fa fa-sad-tear",
    "fa fa-satellite",
    "fa fa-satellite-dish",
    "fa fa-save",
    "fa fa-school",
    "fa fa-screwdriver",
    "fa fa-scroll",
    "fa fa-sd-card",
    "fa fa-search",
    "fa fa-search-dollar",
    "fa fa-search-location",
    "fa fa-search-minus",
    "fa fa-search-plus",
    "fa fa-seedling",
    "fa fa-server",
    "fa fa-shapes",
    "fa fa-share",
    "fa fa-share-alt",
    "fa fa-share-alt-square",
    "fa fa-share-square",
    "fa fa-shekel-sign",
    "fa fa-shield-alt",
    "fa fa-ship",
    "fa fa-shipping-fast",
    "fa fa-shoe-prints",
    "fa fa-shopping-bag",
    "fa fa-shopping-basket",
    "fa fa-shopping-cart",
    "fa fa-shower",
    "fa fa-shuttle-van",
    "fa fa-sign",
    "fa fa-sign-in-alt",
    "fa fa-sign-language",
    "fa fa-sign-out-alt",
    "fa fa-signal",
    "fa fa-signature",
    "fa fa-sim-card",
    "fa fa-sitemap",
    "fa fa-skating",
    "fa fa-skiing",
    "fa fa-skiing-nordic",
    "fa fa-skull",
    "fa fa-skull-crossbones",
    "fa fa-slash",
    "fa fa-sleigh",
    "fa fa-sliders-h",
    "fa fa-smile",
    "fa fa-smile-beam",
    "fa fa-smile-wink",
    "fa fa-smog",
    "fa fa-smoking",
    "fa fa-smoking-ban",
    "fa fa-sms",
    "fa fa-snowboarding",
    "fa fa-snowflake",
    "fa fa-snowman",
    "fa fa-snowplow",
    "fa fa-socks",
    "fa fa-solar-panel",
    "fa fa-sort",
    "fa fa-sort-alpha-down",
    "fa fa-sort-alpha-down-alt",
    "fa fa-sort-alpha-up",
    "fa fa-sort-alpha-up-alt",
    "fa fa-sort-amount-down",
    "fa fa-sort-amount-down-alt",
    "fa fa-sort-amount-up",
    "fa fa-sort-amount-up-alt",
    "fa fa-sort-down",
    "fa fa-sort-numeric-down",
    "fa fa-sort-numeric-down-alt",
    "fa fa-sort-numeric-up",
    "fa fa-sort-numeric-up-alt",
    "fa fa-sort-up",
    "fa fa-spa",
    "fa fa-space-shuttle",
    "fa fa-spell-check",
    "fa fa-spider",
    "fa fa-spinner",
    "fa fa-splotch",
    "fa fa-spray-can",
    "fa fa-square",
    "fa fa-square-full",
    "fa fa-square-root-alt",
    "fa fa-stamp",
    "fa fa-star",
    "fa fa-star-and-crescent",
    "fa fa-star-half",
    "fa fa-star-half-alt",
    "fa fa-star-of-david",
    "fa fa-star-of-life",
    "fa fa-step-backward",
    "fa fa-step-forward",
    "fa fa-stethoscope",
    "fa fa-sticky-note",
    "fa fa-stop",
    "fa fa-stop-circle",
    "fa fa-stopwatch",
    "fa fa-store",
    "fa fa-store-alt",
    "fa fa-stream",
    "fa fa-street-view",
    "fa fa-strikethrough",
    "fa fa-stroopwafel",
    "fa fa-subscript",
    "fa fa-subway",
    "fa fa-suitcase",
    "fa fa-suitcase-rolling",
    "fa fa-sun",
    "fa fa-superscript",
    "fa fa-surprise",
    "fa fa-swatchbook",
    "fa fa-swimmer",
    "fa fa-swimming-pool",
    "fa fa-synagogue",
    "fa fa-sync",
    "fa fa-sync-alt",
    "fa fa-syringe",
    "fa fa-table",
    "fa fa-table-tennis",
    "fa fa-tablet",
    "fa fa-tablet-alt",
    "fa fa-tablets",
    "fa fa-tachometer-alt",
    "fa fa-tag",
    "fa fa-tags",
    "fa fa-tape",
    "fa fa-tasks",
    "fa fa-taxi",
    "fa fa-teeth",
    "fa fa-teeth-open",
    "fa fa-temperature-high",
    "fa fa-temperature-low",
    "fa fa-tenge",
    "fa fa-terminal",
    "fa fa-text-height",
    "fa fa-text-width",
    "fa fa-th",
    "fa fa-th-large",
    "fa fa-th-list",
    "fa fa-theater-masks",
    "fa fa-thermometer",
    "fa fa-thermometer-empty",
    "fa fa-thermometer-full",
    "fa fa-thermometer-half",
    "fa fa-thermometer-quarter",
    "fa fa-thermometer-three-quarters",
    "fa fa-thumbs-down",
    "fa fa-thumbs-up",
    "fa fa-thumbtack",
    "fa fa-ticket-alt",
    "fa fa-times",
    "fa fa-times-circle",
    "fa fa-tint",
    "fa fa-tint-slash",
    "fa fa-tired",
    "fa fa-toggle-off",
    "fa fa-toggle-on",
    "fa fa-toilet",
    "fa fa-toilet-paper",
    "fa fa-toolbox",
    "fa fa-tools",
    "fa fa-tooth",
    "fa fa-torah",
    "fa fa-torii-gate",
    "fa fa-tractor",
    "fa fa-trademark",
    "fa fa-traffic-light",
    "fa fa-trailer",
    "fa fa-train",
    "fa fa-tram",
    "fa fa-transgender",
    "fa fa-transgender-alt",
    "fa fa-trash",
    "fa fa-trash-alt",
    "fa fa-trash-restore",
    "fa fa-trash-restore-alt",
    "fa fa-tree",
    "fa fa-trophy",
    "fa fa-truck",
    "fa fa-truck-loading",
    "fa fa-truck-monster",
    "fa fa-truck-moving",
    "fa fa-truck-pickup",
    "fa fa-tshirt",
    "fa fa-tty",
    "fa fa-tv",
    "fa fa-umbrella",
    "fa fa-umbrella-beach",
    "fa fa-underline",
    "fa fa-undo",
    "fa fa-undo-alt",
    "fa fa-universal-access",
    "fa fa-university",
    "fa fa-unlink",
    "fa fa-unlock",
    "fa fa-unlock-alt",
    "fa fa-upload",
    "fa fa-user",
    "fa fa-user-alt",
    "fa fa-user-alt-slash",
    "fa fa-user-astronaut",
    "fa fa-user-check",
    "fa fa-user-circle",
    "fa fa-user-clock",
    "fa fa-user-cog",
    "fa fa-user-edit",
    "fa fa-user-friends",
    "fa fa-user-graduate",
    "fa fa-user-injured",
    "fa fa-user-lock",
    "fa fa-user-md",
    "fa fa-user-minus",
    "fa fa-user-ninja",
    "fa fa-user-nurse",
    "fa fa-user-plus",
    "fa fa-user-secret",
    "fa fa-user-shield",
    "fa fa-user-slash",
    "fa fa-user-tag",
    "fa fa-user-tie",
    "fa fa-user-times",
    "fa fa-users",
    "fa fa-users-cog",
    "fa fa-utensil-spoon",
    "fa fa-utensils",
    "fa fa-vector-square",
    "fa fa-venus",
    "fa fa-venus-double",
    "fa fa-venus-mars",
    "fa fa-vial",
    "fa fa-vials",
    "fa fa-video",
    "fa fa-video-slash",
    "fa fa-vihara",
    "fa fa-voicemail",
    "fa fa-volleyball-ball",
    "fa fa-volume-down",
    "fa fa-volume-mute",
    "fa fa-volume-off",
    "fa fa-volume-up",
    "fa fa-vote-yea",
    "fa fa-vr-cardboard",
    "fa fa-walking",
    "fa fa-wallet",
    "fa fa-warehouse",
    "fa fa-water",
    "fa fa-wave-square",
    "fa fa-weight",
    "fa fa-weight-hanging",
    "fa fa-wheelchair",
    "fa fa-wifi",
    "fa fa-wind",
    "fa fa-window-close",
    "fa fa-window-maximize",
    "fa fa-window-minimize",
    "fa fa-window-restore",
    "fa fa-wine-bottle",
    "fa fa-wine-glass",
    "fa fa-wine-glass-alt",
    "fa fa-won-sign",
    "fa fa-wrench",
    "fa fa-x-ray",
    "fa fa-yen-sign",
    "fa fa-yin-yang",
  ];
}

export default class KonfigurasiMenu extends React.Component {
  constructor(props) {
    super(props);
    this['pageName'] = "Konfigurasi Menu";
    this['searchParameter'] = ["nama_menu", "nama_parent", "nama_path", "urutan"];
    this['apiKonfigurasiMenu'] = new KonfigurasiMenuAPI();
    this['initialData'] = new KonfigurasiMenuModel();
    this['option_parent'] = [];
    this['option_icon'] = [];
    this['state'] = {
      pending: true,
      modal: {
        show: false,
        initialData: this['initialData'],
        isEdit: false,
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
    this['datatable'] = [{ id: "", no: "", name: "" }];
    this['columntable'] = [{ id: "", title: "" }];
  }
  //* ----------------------------------------------------------------------------- */
  //** Start: Component */
  async componentDidMount() {
    await this.LoadData();
  }
  //** End: Component */
  //* ----------------------------------------------------------------------------- */
  //** Start: Load Data List */
  async LoadData() {
    this.setState({ pending: true });
    let data = await this['apiKonfigurasiMenu'].getAllListKonfigurasiMenu();
    this['DataTableModel'] = new DataTableModel();
    data['data'].map((item, i) => {
      let dataModel = new KonfigurasiMenuModel(item);
      if (item['status'] === 1) {
        this['DataTableModel'].add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: (
            <>
              <ButtonActions
                label={"edit"}
                onClick={async () =>
                  await this['handleModal'].edit(dataModel)}
              />
              <ButtonActions
                label={"delete"}
                onClick={async () => {
                  this['handleAlert'].show(item['id']);
                }}
              />
            </>
          ),
        });
      }
      return item;
    });
    this['columntable'] = await this['DataTableModel'].getColumn();
    this['datatable'] = await this['DataTableModel'].getDatas();
    this.setState({ ...this['state'], pending: false });
  }
  //** End: Load Data List */
  //* ----------------------------------------------------------------------------- */
  //** Start: Handle Modal/Alert */
  handleModal = {
    loadOptionParent: async () => {
      this['apiKonfigurasiMenu'].getMenuSistemParent().then((res) => {
        this['option_parent'] = res['data'].map((val) => {
          return {
            value: val['id'],
            label: val['text'],
          };
        });
      });
    },
    loadOptionCheateSheet: async () => {
      this['option_icon'] = cheatsheet().map((val) => {
        return {
          value: val,
          label: <><span className={`${val} icon-xl mr-3`} /> {val}</>,
        };
      })
    },
    tambah: async () => {
      let state = { ...this['state'] };
      state = {
        modal: {
          show: true,
          title: `Tambah ${this['pageName']}`,
          isEdit: false,
          initialData: this['initialData'],
        }
      }
      this.setState({ ...state });
      await this['handleModal'].loadOptionParent();
      await this['handleModal'].loadOptionCheateSheet();
    },
    edit: async (dataFromModel) => {
      let state = { ...this['state'] };
      state = {
        modal: {
          show: true,
          title: `Edit ${this['pageName']}`,
          isEdit: true,
          initialData: dataFromModel
        }
      }
      this.setState({ ...state });
      await this['handleModal'].loadOptionParent();
      await this['handleModal'].loadOptionCheateSheet();
    },
    hide: () => {
      let state = { ...this['state'] };
      state = {
        modal: {
          show: false,
          initialData: this['initialData']
        }
      }
      this.setState({ ...state });
    },
  };
  handleAlert = {
    show: (id) => {
      let alert = { ...this['state'] };
      alert = {
        alert: {
          show: true,
          activeId: id
        }
      }
      this.setState({ ...alert });
    },
    hide: () => {
      let alert = { ...this['state'] };
      alert = {
        alert: {
          show: false,
          activeId: null
        }
      }
      this.setState({ ...alert });
    },
    confirmed: async () => {
      await this['apiKonfigurasiMenu'].deleteKonfigurasiMenu(this['state']['alert']['activeId']).then((res) => {
        if (res['status']) {
          dispatcher['snackbar'].show("Berhasil", res['message'], "primary");
          this['handleAlert'].hide();
          this.LoadData();
        } else {
          return dispatcher['snackbar'].show("Gagal", res['message'], "danger");
        }
      });
    },
  };
  //** End: Handle Alert */
  render() {
    const { pending, modal, alert } = this['state'];
    return (
      <>
        {/* Views Table */}
        <AnimatedCard>
          <CardHeader title={this['pageName']}>
            <CardHeaderToolbar>
              <button
                type={"button"}
                className={"btn btn-primary btn-sm"}
                onClick={async () => {
                  await this['handleModal'].tambah();
                }}
              > <i className={"fa fa-plus"}></i> Tambah
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <ReactDatatable
              title={""}
              columns={this['columntable']}
              data={this['datatable']}
              searchParameter={this['searchParameter']}
              progressPending={pending}
            />
          </CardBody>
        </AnimatedCard>
        {/* ----------------------------------------------------------------------------- */}
        {/* Modal Tambah/Edit */}
        <Formik
          enableReinitialize={true}
          initialValues={modal['initialData']}
          validationSchema={Yup.object().shape({
            nama_menu: Yup.string().required(),
            nama_path: Yup.string().required(),
            icon_menu: Yup.string().nullable(true).required("Icon Menu harus diisi"),
            urutan: Yup.string().required(),
          })}
          onSubmit={async (values, { resetForm }) => {
            let dataSend = {
              id_parent: parseInt(values['id_parent']),
              nama: values['nama_menu'],
              icon: values['icon_menu'],
              path: values['nama_path'],
              urutan: parseInt(values['urutan']),
            };
            if (modal['isEdit']) {
              // Update Data
              dataSend = {
                id: parseInt(values['id']),
                ...dataSend
              }
              await this['apiKonfigurasiMenu'].editKonfigurasiMenu(dataSend).then((res) => {
                if (res['status']) {
                  dispatcher['snackbar'].show("Berhasil", res['message'], "primary");
                  this['handleModal'].hide();
                  this.LoadData();
                } else {
                  return dispatcher['snackbar'].show("Gagal", res['message'], "danger");
                }
              });
            } else {
              // Insert Data
              await this['apiKonfigurasiMenu'].setKonfigurasiMenu(dataSend).then((res) => {
                if (res['status']) {
                  dispatcher['snackbar'].show("Berhasil", res['message'], "primary");
                  this['handleModal'].hide();
                  this.LoadData();
                } else {
                  return dispatcher['snackbar'].show("Gagal", res['message'], "danger");
                }
              });
            }
            resetForm();
          }}
        >
          {({ handleSubmit, setFieldValue, setFieldTouched, touched, errors, values }) => (
            <ModalForms
              show={modal['show']}
              title={modal['title']}
              onHide={this['handleModal']['hide']}
              onConfirm={handleSubmit}
              size={"lg"}
              content={
                <div className={"form-group row"}>
                  <div className={"col-lg-6"}>
                    {/* Forms Nama Menu */}
                    <FormsFields
                      id={"nama_menu"}
                      type="text"
                      label={"Nama Menu"}
                      className={"col-lg-12"}
                      paramsFormik={{
                        setFieldValue,
                        values
                      }}
                    />
                    {/* Forms Nama Path */}
                    <FormsFields
                      id={"nama_path"}
                      type={"textarea"}
                      label={"Nama Path"}
                      className={"col-lg-12"}
                      style={{ marginTop: "20px" }}
                      rows={2}
                      paramsFormik={{
                        setFieldValue,
                        setFieldTouched,
                        touched,
                        errors,
                        values
                      }}
                    />
                    {/* Forms Urutan Menu */}
                    <FormsFields
                      id={"urutan"}
                      type={"number"}
                      label={"Urutan Menu"}
                      className={"col-lg-12"}
                      style={{ marginTop: "20px" }}
                      paramsFormik={{
                        setFieldValue,
                        values
                      }}
                    />
                  </div>
                  <div className={"col-lg-6"}>
                    {/* Forms Kode Parent */}
                    <FormsFields
                      id={"id_parent"}
                      type="select2"
                      label={"Parent Menu"}
                      className={"col-lg-12"}
                      isClearable={true}
                      isSearchable={true}
                      options={this['option_parent']}
                      paramsFormik={{
                        setFieldValue,
                        setFieldTouched,
                        touched,
                        errors,
                        values
                      }}
                    />
                    {/* Forms Perusahaan */}
                    <FormsFields
                      id={"icon_menu"}
                      type="select2"
                      label={"Icon Menu"}
                      className={"col-lg-12"}
                      style={{ marginTop: "20px" }}
                      isClearable={true}
                      isSearchable={true}
                      options={this['option_icon']}
                      paramsFormik={{
                        setFieldValue,
                        setFieldTouched,
                        touched,
                        errors,
                        values
                      }}
                    />
                  </div>
                </div>
              }
            />
          )}
        </Formik>
        {/* ----------------------------------------------------------------------------- */}
        {/* Start: Alert Hapus */}
        <SimpleAlert
          title={"Hapus Data"}
          content={"Anda yakin hapus data ini...?"}
          show={alert['show']}
          onHide={() => this['handleAlert'].hide()}
          onConfirmed={this['handleAlert']['confirmed']}
        />
        {/* End: Alert Hapus */}
      </>
    );
  }
}

/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { Layout } from "../_metronic/layout";
import MainPage from "./MainPage";
import { Logout, AuthPage } from "../app/pages/auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { useSelector } from "react-redux";
import { LoadingDialog } from "./components/loading-dialog/loadingDialog";

export function Routes() {
  const auth = useSelector((state) => state.auth);
  const menu = useSelector((state) => state.menu);
  const loading = useSelector((state) => state.loading);

  return (
    <Switch>
      {auth.token == null ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>
          <AuthPage />
        </Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />

      {auth.token == null ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to="/auth/login" />
      ) : (
        <>
          <Layout menu={menu}>
            <LoadingDialog show={loading} />
            <MainPage menu={menu} />
          </Layout>
        </>
      )}
    </Switch>
  );
}

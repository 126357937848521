import React, { useState } from "react";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import store from "../../../redux/store";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";

function Profile() {
  // const [oldPassword, setoldPassword] = useState("");
  // const [password, setpassword] = useState("");
  // const [changepassword, setchangepassword] = useState("");

  // const handleSubmit = (event) => {
  //     event.preventDefault();
  //     alert(`Password Lama anda: `)
  //   }

  const dataUser = store.getState().auth;
  console.log(dataUser);

  const dataPass = dataUser.password;
  console.log(dataPass);

  const userProfil = store.getState().auth.userProfile;
  console.log(userProfil);

  const dataPerusahaan = store.getState().auth.toPerusahaan;
  console.log(dataPerusahaan);

  //   Show & Hide Password
  const [passwordType, setPasswordType] = useState("password");
  // const [passwordInput, setPasswordInput] = useState("");
  // const handleChange =(evnt)=>{
  //     setPasswordInput(evnt.target.value);
  // }
  const togglePassword = (e) => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const Schema = Yup.object().shape({
    password: Yup.string()
      .required("")
      .min(6, "Minimal 6 karakter"),
    changepassword: Yup.string().when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Password baru dan konfirmasi password harus sama"
      ),
    }),
  });

  // Modal show & hide

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <AnimatedCard>
      <CardHeader title={"Profile"}>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleShow}
          >
            <i className="fa fa-key"></i>
            Ganti Password
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <Modal show={show} onHide={handleClose} size="sm" centered>
        <Modal.Header closeButton>
          <Modal.Title>Ganti Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              oldPassword: "",
              password: "",
              changepassword: "",
            }}
            validationSchema={Schema}
            onSubmit={async (values) => {
              alert(JSON.stringify(values, null, 2));
              console.log(values);
            }}
          >
            {({ values, errors, handleChange, handleSubmit, handleBlur }) => (
              <form id="passForm" onSubmit={handleSubmit}>
                <label htmlFor="passowrd">Password Sekarang</label>
                <div className="input-group my-4">
                  <input
                    className="form-control"
                    type={passwordType}
                    name="oldPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.oldPassword}
                  />
                  <div className="input-group-btn">
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={togglePassword}
                    >
                      {passwordType === "password" ? (
                        <i className="fa fa-eye-slash"></i>
                      ) : (
                        <i className="fa fa-eye"></i>
                      )}
                    </button>
                  </div>
                </div>
                <label className="mt-5" htmlFor="passowrd">
                  Password Baru
                </label>
                <input
                  className="form-control"
                  type={passwordType}
                  name="password"
                  placeholder="Masukkan Password Baru"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                />
                <span className="error" style={{ color: "red" }}>
                  {errors.password}
                </span>
                <label className="mt-5" htmlFor="passowrd">
                  Konfirmasi Password Baru
                </label>
                <input
                  className="form-control"
                  type={passwordType}
                  name="changepassword"
                  placeholder="Ulang Password Baru"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.changepassword}
                />
                <span className="error" style={{ color: "red" }}>
                  {errors.changepassword}
                </span>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                  <Button id="passForm" variant="primary" type="submit">
                    Save Changes
                  </Button>
                </Modal.Footer>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <CardBody>
        <div className="container emp-profile">
          <div className="row">
            <div className="col-md-3">
              <div className="profile-img">
                <img
                  className="rounded-circle img-thumbnail"
                  src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                  alt="foto-profil"
                />
              </div>
            </div>
            <div className="col mt-4 col-lg-8 col-xxl rounded shadow p-4 mb-4 bg-white">
              <div
                className="card-header border-0 py-5"
                style={{ backgroundColor: "#CEE5D0" }}
              >
                <div className="row">
                  <div className="col">
                    <div style={{ fontSize: "20px" }}>
                      {userProfil.nama_karyawan}
                    </div>
                    <div style={{ fontSize: "18px" }}>
                      {dataUser.alias_role}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body p-0 position-relative overflow-hidden">
                <div
                  className="card-rounded-bottom"
                  style={{ height: "100px", backgroundColor: "#CEE5D0" }}
                ></div>
                <div className="card-spacer mt-n25">
                  <div className="row m-0">
                    <div className="col bg-light-warning px-6 py-8 rounded-xl mr-7 mb-7">
                      <i className="fa fa-user fa-2x" aria-hidden="true"></i>
                      <div style={{ fontSize: "16px" }}>
                        {dataUser.username}
                      </div>
                    </div>
                    <div className="col bg-light-primary px-6 py-8 rounded-xl mb-7">
                      <i className="fa fa-id-card fa-2x" aria-hidden="true"></i>
                      <div style={{ fontSize: "16px" }}>{userProfil.nik}</div>
                    </div>
                  </div>
                  <div className="row m-0">
                    <div className="col bg-light-danger px-6 py-8 rounded-xl mr-7 mb-7">
                      <i className="fa fa-envelope fa-2x" aria-hidden="true"></i>
                      <div style={{ fontSize: "16px" }}>{userProfil.email}</div>
                    </div>
                    <div className="col bg-light-success px-6 py-8 rounded-xl mb-7">
                      <i className="fa fa-phone fa-2x" aria-hidden="true"></i>
                      <div style={{ fontSize: "16px" }}>
                        {userProfil.handphone}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </AnimatedCard>
  );
}
export default Profile;

import { getIdPerusahaan, simpleFetch, getCoverageArea } from "./api";
import store from "../redux/store";
export class ProdukKategoriAPI {
    async getAll() {
      let coverage = store.getState().auth.coverage;
      let params = "id_perusahaan=";
      if (coverage.length > 0) {
        params += coverage.join("&id_perusahaan=");
      } else {
        params += getIdPerusahaan();
      }
    let res = await simpleFetch({
      // url: "retail/produk/kategori?id_perusahaan=" + getIdPerusahaan(),
      url: "retail/produk/kategori?" + params,
      method: "GET",
    });
    return res;
  }

  async getSingle(id) {
    let res = await simpleFetch({
      url: "retail/produk/kategori?id=" + id,
      method: "GET",
    });
    return res;
  }

  async set(values) {
    let values2send = {};
    if (values.id_parent === 0) {
      values2send = {
        ...values,
        id_parent: null,
      };
    } else {
      values2send = {
        ...values,
      };
    }
    let res = await simpleFetch({
      url: "retail/produk/kategori",
      method: "POST",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values2send,
      },
    });
    return res;
  }

  async edit(values) {
    let values2send = {};
    if (values.id_parent === 0) {
      values2send = {
        ...values,
        id_parent: null,
      };
    } else {
      values2send = {
        ...values,
      };
    }
    let res = await simpleFetch({
      url: "retail/produk/kategori",
      method: "PUT",
      body: {
        id: values.id,
        id_perusahaan: getIdPerusahaan(),
        ...values2send,
      },
    });
    return res;
  }

  async delete(id) {
    let res = await simpleFetch({
      url: "retail/produk/kategori",
      method: "DELETE",
      body: {
        id: id,
      },
    });
    return res;
  }
}

import React, { useEffect, useState } from "react";
import { simpleFetch } from "../../../api/api";
import {
    CardBody,
} from "../../../../_metronic/_partials/controls";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import { separatorHarga } from "../../../services/separator-harga";

function HistoryPembelianCustomer({ data }) {
    const [produk, setProduk] = useState([]);

    const fetchDataHistory = async () => {
        try {
            await simpleFetch({
                url: `retail/penjualan/history?id_perusahaan=${data.id_perusahaan}&id_customer=${data.id_customer}&id_produk=${data.id_produk}`,
                method: "GET",
            }).then((res) => setProduk(res.data))
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchDataHistory()
    }, [])
    
    return (
        <>
            <AnimatedCard>
                <CardBody>
                    <h1> History Pembelian Customer </h1>
                    <div className="table-responsive-md">
                        <table className="table table-hover">
                            <thead className="table-primary">
                                <tr className="text-center">
                                    <th scope="col">No</th>
                                    <th scope="col">Nama Produk</th>
                                    <th scope="col">Tanggal Penjualan</th>
                                    <th scope="col">QTY</th>
                                    <th scope="col">Harga Jual</th>
                                </tr>
                            </thead>
                            <tbody>
                                {produk && produk.length > 0 ? produk.map((data, i) => (
                                    <tr key={i} className="text-center">
                                        <td>{i + 1}</td>
                                        <td>{data.toProduk.nama}</td>
                                        <td>{data.tanggal}</td>
                                        <td>{data.qty}</td>
                                        <td>Rp. {separatorHarga(data.dpp.toString())}</td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <th scope="row"></th>
                                        <td colSpan="4" className="text-center">Belum Ada Data Pembelian</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </CardBody>
            </AnimatedCard>
        </>
    );
}

export default HistoryPembelianCustomer;
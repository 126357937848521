import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import Model from "../../../models/penjualan-model/PenjualanModel";
import { Link } from "react-router-dom";
import { dispatcher } from "../../../redux/dispatcher";
import SimpleAlert2 from "../../../components/alert/alert2";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import moment from "moment";
import { Button, Form, Modal } from "react-bootstrap";
import { PenjualanAPI } from "../../../api/penjualan";
import Select2 from "react-select";
import store from "../../../redux/store";

export default function List() {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([])
  // const [cabang, setCabang] = useState(store.getState().auth.id_perusahaan);
  // const [options, setOptions] = useState([]);
  const [deskripsi, setDeskripsi] = useState("");
  const [search, setSearch] = useState("");
  const api = new PenjualanAPI();
  const [refreshData, setRefresh] = useState(false);

  // const coverage = async () => {
  //   let opsi = [];
  //   await new PenjualanAPI().getCoverage().then((res) => {
  //     opsi = res.data.map((data) => ({
  //       label: data.text,
  //       text: data.text,
  //       value: data.id,
  //     }));
  //   });
  //   // options.push(options)
  //   setOptions(opsi);
  // };

  const handleClose = () => {
    setShow(false)
  };
  const handleChange = (e) => setDeskripsi(e.target.value)
  const handleSimpan = () => {
    data.removeSeparator()
    data.removeAllDetailSeparator()
    data.master.status_retur = 1
    api.edit(data.master)
    data.master.deskripsi = deskripsi
    api.retur(data.master)
    console.log(data.master)

  };
  const handleShow = (data) => {
    setData(data)
    setShow(true)
  }


  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const pageName = "Penjualan";
  const [startDate, setStartDate] = useState(
    moment(new Date())
      .startOf("month")
      .format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  async function loadData() {
    let datas = dispatcher.penjualan.getList();
    let dataMaster = datas;
    let searchParams = ["no_transaksi", "nama_sales", "nama_pelanggan", "nama_organisasi"];
    let filtered = dataMaster.filter((filter) => {
      let dateFormat = moment(filter.master.created_at).format("YYYY-MM-DD");
      let dateFilter = dateFormat >= startDate && dateFormat <= endDate;
      // let cabangSelected = filter.master.id_perusahaan === cabang;
      return searchParams.some((item) => {
        return (
          dateFilter &&
          filter.master[item]
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())
        );
      });
    });
    let simpleTableModel = new SimpleTableModel();
    const actions = (dataModel) => {
      return (
        <>
          <Link
            className="mx-1 btn btn-outline-success btn-sm"
            to="/penjualan/detail"
            onClick={() => {
              dispatcher.penjualan.set(dataModel);
            }}
          >
            Detail
          </Link>
          {dataModel.master.status_validasi === 2 ? (
            <Button
              className="mx-1 btn-outline-danger btn-sm"
              onClick=
              {() => {
                handleShow(dataModel)
              }}
            >
              Retur
            </Button>
          ) : (
            <Button
              className="mx-1 btn btn-secondary btn-sm"
              disabled
            >
              Retur
            </Button>
          )}
          {/* {dataModel.master.status_invoice === 0 && (
            <Link
              className="mx-1 btn btn-outline-primary btn-sm"
              to="/penjualan/outbound"
              onClick={() => {
                dispatcher.penjualan.set(dataModel);
              }}
            >
              Validasi
            </Link>
          )} */}
        </>
      );
    };

    filtered.map((item, i) => {
      let dataModel = new Model(item);
      if (item.master.status === 1)
        simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: actions(dataModel),
        });
    });

    setColumntable(await simpleTableModel.getColumnHeaders());
    setDatatable(await simpleTableModel.getDatatables());
  }

  useEffect(() => {
    dispatcher.penjualan.list();
  }, [startDate, endDate, refreshData, refreshData]);

  useEffect(() => {
    loadData();
  }, [dispatcher.penjualan.getList()]);

  return (
    <>
      <SimpleAlert2
        title="Hapus Data Ini?"
        content="Anda yakin ingin menghapus data ini?"
        variant="danger"
        onConfirm={() => { }}
      />
      <AnimatedCard>
        <CardHeader title={pageName}></CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Mulai </label>
              <input
                name={"tanggal_awal"}
                type={"date"}
                value={startDate}
                className={"form-control"}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  loadData()
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Akhir </label>
              <input
                name={"tanggal_akhir"}
                type={"date"}
                value={endDate}
                className={"form-control"}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  loadData()
                }}
              />
            </div>
            {/* <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Pilih Perusahaan </label>
              <Select2
                name={"Cabang"}
                placeholder={" --- Pilih Perusahaan --- "}
                size="sm"
                options={options}
                isClearable={false}
                value={
                  options
                    ? options.find(
                      (data) => data.value === cabang
                    )
                    : ""
                }
                onChange={(e) => {
                  setCabang(e ? e.value : []);
                  // setFieldValue("cabang", e ? e.value : "");
                }}
              />
            </div> */}
          <div className="col-lg-3" style={{ marginTop: "20px" }}>
            <label>Cari Data</label>
            <input
              type={"text"}
              className={"form-control"}
              placeholder="Cari Data ..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                dispatcher.penjualan.list();
              }}
            />
          </div>
          </div>
          <SimpleTable data={datatable} columns={columntable} />
        </CardBody>
        {/* Modal */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Retur Pembelian Barang</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            <Form.Group >
              <Form.Label>Alasan Retur: </Form.Label>
              <Form.Control onChange={handleChange} value={deskripsi} as="textarea" rows={3} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Batal
            </Button>
            <Button variant="primary"
              type="submit"
              onClick={() => {
                handleSimpan()
                handleClose()
                setRefresh(!refreshData)
              }}
            >
              Retur Penjualan
            </Button>
          </Modal.Footer>
        </Modal>
      </AnimatedCard>
    </>
  );
}

export default class transaksiRefModel {
  constructor(
    data = {
      id: 0,
      id_akun: "",
      id_transaksi_jenis: "",
      id_transaksi_tipe: "",
      nama: "",
      nama_akun: "",
      jenis_transaksi: "",
      tipe_transaksi: ""
    }
  ) {
    this.id = data.id;
    this.id_akun = data.id_akun;
    this.id_transaksi_jenis = data.id_transaksi_jenis;
    this.id_transaksi_tipe = data.id_transaksi_tipe;
    this.nama = data.nama;
    this.nama_akun = data.nama_akun;
    this.jenis_transaksi = data.jenis_transaksi;
    this.tipe_transaksi = data.tipe_transaksi;
  }

  async getdata() {
    // async getdata(params = { pageName: "", isForm: false }) {
    return [
      {
        id: "name",
        title: "Nama",
        label: this.nama,
        sortable: true,
      },
      {
        id: "id_akun",
        title: "Akun",
        label: this.nama_akun,
        sortable: true,
      },
      {
        id: "id_transaksi_jenis",
        title: "Jenis Transaksi",
        label: this.jenis_transaksi,
        sortable: true,
      },
      {
        id: "id_transaksi_tipe",
        title: "Tipe Transaksi",
        label: this.tipe_transaksi,
        sortable: true,
      },
    ];
  }
}

import React, { useEffect, useState } from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { separatorHarga } from "../../../services/separator-harga";
import { useHistory } from "react-router-dom";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import { Form } from "react-bootstrap";

function Detail({ data }) {
  const history = useHistory();
  const [master, setMaster] = useState([]);
  const [ret, setRet] = useState([]);
  const [detail, setDetail] = useState([]);

  const rupiah = (number) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(number);
  };

  useEffect(() => {
    setRet(data.dataModel);
    console.log(data);
    // data.dataModel.map((val) => {
    //   setRet(val)
    // })
    data.filter.map((val) => {
      console.log(val.master);
      let newMaster = {
        deskripsi: val.master.deskripsi,
        diskon_invoice: val.master.diskon_invoice,
        diskon_total: val.master.diskon_total,
        dpp_total: rupiah(val.master.dpp_total),
        hpp_total: rupiah(val.master.hpp_total),
        jenis_pembayaran: val.master.jenis_pembayaran,
        nama_customer: `${val.master.nama_customer} ${val.master.organisasi}`,
        nama_sales: val.master.nama_sales,
        no_transaksi: val.master.no_transaksi,
        pajak_total: rupiah(val.master.pajak_total),
        qty_total: val.master.qty_total,
        status: val.master.status,
        subtotal: rupiah(val.master.subtotal),
        tanggal: val.master.tanggal,
        tanggal_invoice: val.master.tanggal_invoice,
        terbayar: val.master.terbayar,
        tersisa: rupiah(val.master.tersisa),
        total: rupiah(val.master.total),
      };
      setMaster(newMaster);
      setDetail(val.detail);
      return val;
    });
  }, []);

  return (
    <>
      <AnimatedCard>
        <CardHeader title={"Detail"}>
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-secondary mx-1"
              onClick={() => history.goBack()}
            >
              <i className="fa fa-arrow-left"></i>
              Kembali
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <form action="#" className="form form-label-right">
            <div className="row">
              <div className="form-group col-lg-6">
                <label>Tanggal Invoice</label>
                <input
                  type="text"
                  className="form-control"
                  name="tanggal"
                  placeholder="Tanggal Invoice"
                  disabled
                  defaultValue={master.tanggal_invoice}
                />
              </div>
              <div className="form-group col-lg-6">
                <label>No Retur</label>
                <input
                  type="text"
                  className="form-control"
                  name="jatuh_tempo"
                  placeholder="Jatuh Tempo"
                  disabled
                  defaultValue={ret.no_retur}
                />
              </div>
              <div className="form-group col-lg-6">
                <label>Customer</label>
                <input
                  type="text"
                  className="form-control"
                  name="nama_customer"
                  placeholder="Customer"
                  disabled
                  defaultValue={master.nama_customer}
                />
              </div>
              <div className="form-group col-lg-6">
                <label>No Transaksi</label>
                <input
                  type="text"
                  className="form-control"
                  name="no_transaksi"
                  placeholder="No Transaksi"
                  disabled
                  defaultValue={master.no_transaksi}
                />
              </div>
              <div className="form-group col-lg-6">
                <label>Jenis Pembayaran</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Jenis Pembayaran"
                  disabled
                  defaultValue={
                    master.jenis_pembayaran === 1 ? "Cash" : "Kredit"
                  }
                />
              </div>
              <div className="form-group col-lg-6">
                <label>Nama Sales</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nama Sales"
                  disabled
                  defaultValue={master.nama_sales}
                />
              </div>
            </div>
            <span className="d-flex align-items-center justify-content-between mt-4">
              <h6 className="MuiTypography-root MuiTypography-h6">
                Detail Item
              </h6>
            </span>
            <div className="react-bootstrap-table table-responsive">
              <table className="table table table-head-custom table-vertical-center overflow-hidden">
                <thead>
                  <tr className="text-center">
                    <th
                      tabIndex="0"
                      aria-label="No sortable"
                      className="sortable"
                    >
                      No
                      <span className="order">
                        <span className="dropdown">
                          <span className="caret"></span>
                        </span>
                        <span className="dropup">
                          <span className="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabIndex="0"
                      aria-label="Produk sortable"
                      className="sortable"
                    >
                      Produk
                      <span className="order">
                        <span className="dropdown">
                          <span className="caret"></span>
                        </span>
                        <span className="dropup">
                          <span className="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabIndex="0"
                      aria-label="Harga sortable"
                      className="sortable"
                    >
                      Harga
                      <span className="order">
                        <span className="dropdown">
                          <span className="caret"></span>
                        </span>
                        <span className="dropup">
                          <span className="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabIndex="0"
                      aria-label="QTY sortable"
                      className="sortable"
                    >
                      QTY
                      <span className="order">
                        <span className="dropdown">
                          <span className="caret"></span>
                        </span>
                        <span className="dropup">
                          <span className="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabIndex="0"
                      aria-label="Diskon Rupiah sortable"
                      className="sortable"
                    >
                      Diskon Rupiah
                      <span className="order">
                        <span className="dropdown">
                          <span className="caret"></span>
                        </span>
                        <span className="dropup">
                          <span className="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabIndex="0"
                      aria-label="Pajak Rupiah sortable"
                      className="sortable"
                    >
                      Pajak Rupiah
                      <span className="order">
                        <span className="dropdown">
                          <span className="caret"></span>
                        </span>
                        <span className="dropup">
                          <span className="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabIndex="0"
                      aria-label="Sub Total sortable"
                      className="sortable"
                    >
                      Sub Total
                      <span className="order">
                        <span className="dropdown">
                          <span className="caret"></span>
                        </span>
                        <span className="dropup">
                          <span className="caret"></span>
                        </span>
                      </span>
                    </th>
                    <th
                      tabIndex="0"
                      aria-label="Total sort asc"
                      className="sortable"
                    >
                      Total
                      <span className="react-bootstrap-table-sort-order dropup">
                        <span className="caret"></span>
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {detail.map((data, i) => (
                    <tr key={i} className="text-center">
                      <td className="text-nowrap">{i + 1}</td>
                      <td className="text-nowrap">{data.toProduk.nama}</td>
                      <td className="text-nowrap">
                        Rp. {separatorHarga(data.hpp.toString())}
                      </td>
                      <td className="text-nowrap">{data.qty}</td>
                      <td className="text-nowrap">
                        Rp. {separatorHarga(data.diskon.toString())}
                      </td>
                      <td className="text-nowrap">
                        Rp. {separatorHarga(data.pajak.toString())}
                      </td>
                      <td className="text-nowrap">
                        Rp. {separatorHarga(data.subtotal.toString())}
                      </td>
                      <td className="text-nowrap">
                        Rp. {separatorHarga(data.total.toString())}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="d-flex row" style={{ marginTop: "50px" }}>
              <div className="form-group col-md-4 justify-content-around">
                <label>QTY Total</label>
                <input
                  type="text"
                  className="form-control"
                  name="qty_total"
                  placeholder="QTY Total"
                  disabled
                  defaultValue={master.qty_total}
                />
              </div>
              <div className="form-group col-md-4 justify-content-around">
                <label>Total Harga</label>
                <input
                  type="text"
                  className="form-control"
                  name="hpp_total"
                  placeholder="Total Harga"
                  disabled
                  defaultValue={master.hpp_total}
                />
              </div>
              <div className="form-group col-md-4 justify-content-around">
                <label>Total Diskon</label>
                <input
                  type="text"
                  className="form-control"
                  name="diskon_total"
                  placeholder="Total Diskon"
                  disabled
                  defaultValue={master.diskon_total}
                />
              </div>
              <div className="form-group col-md-4 justify-content-around">
                <label>Sub Total</label>
                <input
                  type="text"
                  className="form-control"
                  name="subtotal"
                  placeholder="Sub Total"
                  disabled
                  defaultValue={master.subtotal}
                />
              </div>
              <div className="form-group col-md-4 justify-content-around">
                <label>Total Pajak</label>
                <input
                  type="text"
                  className="form-control"
                  name="pajak_total"
                  placeholder="Total Pajak"
                  disabled
                  defaultValue={master.pajak_total}
                />
              </div>
              <div className="form-group col-md-4 justify-content-around">
                <label>Total</label>
                <input
                  type="text"
                  className="form-control"
                  name="total"
                  placeholder="Total"
                  disabled
                  defaultValue={master.total}
                />
              </div>
              <Form.Group className="mb-3 col-xl-4">
                <Form.Label>Alasan Retur</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={8}
                  disabled
                  defaultValue={ret.deskripsi}
                />
              </Form.Group>
            </div>
          </form>
        </CardBody>
      </AnimatedCard>
    </>
  );
}

export default Detail;

import { getIdPerusahaan, simpleFetch, simpleFetchGetData } from "./api";

export class AkunAPI {
    // Get All Data List
  async getList() {
    let res = await simpleFetchGetData({
      url: "retail/akun?tipe=getList",
      method: "GET",
    });
    return res;
  }

  // Get Akun Parent
  async getAkunParent() {
    let res = await simpleFetch({
      url: "retail/select/akun",
      method: "GET",
    });
    return res;
  }
  // Get All Perusahaan
  async getAllPerusahaan() {
    let res = await simpleFetch({
      url: "retail/select/perusahaan",
      method: "GET",
    });
    return res;
  }
  // Get Jenis Akun
  async getJenisAkun() {
    let res = await simpleFetch({
      url: "retail/select/jenis_akun",
      method: "GET",
    });
    return res;
  }
// Get Jenis Akun Akses
  async getAksesAkun() {
    let res = await simpleFetch({
      url: "retail/select/jenis_akun_akses",
      method: "GET",
    });
    return res;
  }










  

  async getSingle(id) {
    let res = await simpleFetch({
      url: "retail/akun?id=" + id,
      method: "GET",
    });
    return res;
  }

  async set(values) {
    let values2send = {};
    if (values.id_parent === 0) {
      values2send = {
        ...values,
        id_parent: null,
      };
    } else {
      values2send = {
        ...values,
      };
    }
    if (values.id_perusahaan === 0) {
      values2send = {
        ...values2send,
        id_perusahaan: null,
      };
    }
    let res = await simpleFetch({
      url: "retail/akun",
      method: "POST",
      body: {
        ...values2send,
      },
    });
    return res;
  }

  async getAkunAkses(values) {
    let res = await simpleFetchGetData({
      url: "retail/akun?tipe=init&id_akun_akses=" + values,
      method: "GET",
    });
    return res;
  }

  async edit(values) {
    let values2send = {};
    if (values.id_parent === "") {
      values2send = {
        ...values,
        id_parent: null,
      };
    } else {
      values2send = {
        ...values,
      };
    }
    if (values.id_perusahaan === "") {
      values2send = {
        ...values2send,
        id_perusahaan: null,
      };
    }
    let res = await simpleFetch({
      url: "retail/akun",
      method: "PUT",
      body: {
        ...values2send,
      },
    });
    return res;
  }

  async delete(id) {
    let res = await simpleFetch({
      url: "retail/akun",
      method: "DELETE",
      body: {
        id: id,
      },
    });
    return res;
  }
}

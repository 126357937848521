import moment from "moment/moment";
import { separatorHarga } from "../../../services/separator-harga";
export default class jurnalBalikModel {
  constructor(
    data = {
      id: 0,
      tanggal: "",
      no_transaksi: "",
      cabang: "",
      deskripsi: "",
      kode_akun: "",
      debet: 0,
      kredit: 0,
      status: 0,
    }
  ) {
    const { id, tanggal, no_transaksi, cabang, deskripsi, kode_akun, debet, kredit, status } = data;
    this['id'] = id;
    this['tanggal'] = tanggal;
    this['no_transaksi'] = no_transaksi;
    this['cabang'] = cabang;
    this['deskripsi'] = deskripsi;
    this['kode_akun'] = kode_akun;
    this['debet'] = debet;
    this['kredit'] = kredit;
    this['status'] = status;
  }
  async getdata() {
    const { tanggal, kode_akun, no_transaksi, cabang, deskripsi, debet, kredit } = this;
    return [{
      id: "tanggal",
      title: "Tanggal",
      label: moment(tanggal).format("DD-MM-YYYY"),
      center: true,
      sortable: true,
      minWidth: "12%",
    }, {
      id: "no_transaksi",
      title: "No. Transaksi",
      label: no_transaksi,
      center: true,
      sortable: true,
      minWidth: "22%",
    }, {
      id: "cabang",
      title: "Cabang",
      label: cabang,
      center: true,
      sortable: true,
      minWidth: "20%",
    }, {
      id: "deskripsi",
      title: "Keterangan",
      label: deskripsi,
      sortable: true,
      minWidth: "30%",
    }, {
      id: "kode_akun",
      title: "Kode Akun",
      label: kode_akun,
      center: true,
      minWidth: "10%",
    }, {
      id: "debet",
      title: "Debet",
      label: debet < 0 ? `Rp. -${separatorHarga(debet.toString())}` : separatorHarga(debet.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "13%",
    }, {
      id: "kredit",
      title: "Kredit",
      label: kredit < 0 ? `Rp. -${separatorHarga(kredit.toString())}` : separatorHarga(kredit.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "13%",
    },
    ];
  }
}

import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import DetailPengeluaranBankOperasional from "./detail";
import ListPengeluaranBankOperasional from "./list";
import React from "react";
import { dispatcher } from "../../../../../redux/dispatcher";
export default function IndexListPengeluaranBankOperasional ()
{
  const history = useHistory();
  const active = dispatcher[ 'kasir_bank_operasional' ].getPengeluaranBankOperasional();
  return (
    <Switch>
      <Route path={ "/kasir/daftar-pengeluaran-bank-operasional/list" }>
        <ListPengeluaranBankOperasional />
      </Route>
      { active[ 'transaksi' ][ 'id' ] === 0 && (
        <Redirect to={ "/kasir/daftar-pengeluaran-bank-operasional/list" } />
      ) }
      <Route path="/kasir/daftar-pengeluaran-bank-operasional/detail">
        <DetailPengeluaranBankOperasional history={ history } />
      </Route>
      <Redirect to={ "/kasir/daftar-pengeluaran-bank-operasional/list" } />
    </Switch>
  );
}

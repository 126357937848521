import * as Yup from "yup";
export default class AkunAksesModel {
  constructor(data = { id: 0, nama: "", deskripsi: "" }) {
    this.id = data.id;
    this.nama = data.nama;
  }

  async getEditable(params = { pageName: "", isForm: false }) {
    return [
      {
        validation: Yup.string().required("Nama harus diisi"),
        dataField: "nama",
        label: "Nama",
        type: "text",
        value: this.nama,
      },
    ];
  }
}

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import {
  TableWithoutPagination,
} from "../../../components/table/Table";
import { dispatcher } from "../../../redux/dispatcher";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Link } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import { ProdukAPI } from "../../../api/produk";
import { Alert } from "react-bootstrap";
import StokKeluarModel from "../../../models/stok-keluar-model/StokKeluarModel";

export function OutboundDetail({ data, handleSubmit, validateForm }) {
  const [initialData, setInitialData] = useState(dispatcher.penjualan.get());
  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const [showAlert, setShowAlert] = useState(false);
  const [dModel, setDModel] = useState(new StokKeluarModel());
  const [gudang, setGudang] = useState([
    { id: 0, nama: "", toParent: { nama: "" }, persediaan: 0 },
  ]);
  const [selectedGudang, setSelectedGudang] = useState(null);
  const [selectedGudangPersediaan, setSelectedGudangPersediaan] = useState(0);

  const loadData = async () => {
    let simpleTableModel = new SimpleTableModel();
    const actions = (dModel) => {
      if (dModel.id_gudang === 0 || dModel.id_gudang === null) {
        return (
          <Button
            className="mx-1 btn btn-outline-success btn-sm"
            onClick={() => {
              loadGudang(dModel.id_produk);
              setDModel(dModel);
              setShowAlert(true);
            }}
          >
            Alokasi Item
          </Button>
        );
      } else {
        return (
          <>
            <Button className="mx-1 btn btn-outline-secondary btn-sm" disabled>
              Alokasi Item
            </Button>
          </>
        );
      }
    };

    initialData.detail.forEach((item, i) => {
      console.log(initialData.detail);
      // if (item.status == 1)
        simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: item,
          actions: actions(item),
        });
    });

    setColumntable(await simpleTableModel.getColumnHeaders());
    setDatatable(await simpleTableModel.getDatatables());
  };

  const loadGudang = async (id) => {
    await new ProdukAPI().getGudangProduk(id).then((values) => {
      let filter = values.data.filter((fil) => fil.id_perusahaan === initialData.master.id_perusahaan)
      setGudang(filter);
    });
  };

  const refreshData = async () => {
    await dispatcher.penjualan.single(initialData.master.id);
    setInitialData(dispatcher.penjualan.get());
  };

  const getList = useMemo(() => {
    setInitialData(dispatcher.penjualan.get());
    return dispatcher.penjualan.get();
  }, [dispatcher.penjualan.get()]);

  useEffect(() => {
    loadData();
  }, [getList]);

  return (
    <>
      <Modal
        show={showAlert}
        onHide={() => setShowAlert(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Pilih Gudang</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {dModel.qty > dModel.persediaan_total && (
            <Alert variant="danger">
              QTY penjualan tidak boleh lebih besar dari stok yang tersedia
            </Alert>
          )}
          <Form>
            <Form.Group>
              <Form.Label>Stok Tersedia</Form.Label>
              <Form.Control
                type="text"
                value={dModel.persediaan_total}
                disabled
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>QTY</Form.Label>
              <Form.Control type="text" value={dModel.qty} disabled />
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Gudang</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  setSelectedGudang(e.target.value);
                  let gudangTerpilih = gudang.find(
                    (data) => data.id == e.target.value
                  );
                  if (gudangTerpilih) {
                    setSelectedGudangPersediaan(gudangTerpilih.persediaan);
                  } else {
                    setSelectedGudangPersediaan(0);
                  }
                }}
              >
                <option value="">Tidak ada dipilih</option>
                {gudang.map((data) => {
                  return (
                    <option key={data.id} value={data.id}>
                      {`${data.nama} - ${data.persediaan}`}
                    </option>
                  );
                })}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAlert(false)}>
            Batal
          </Button>
          {dModel.qty > selectedGudangPersediaan ?
              <Button variant="outline-secondary" disabled
                onClick={() => {
                  
                }}
              >
                Simpan Perubahan
              </Button>
         :
            (<Button
              variant="primary"
              onClick={async () => {
                let unvalid = {};
                handleSubmit();
                await validateForm().then((val) => (unvalid = val));

                if (selectedGudang == "" || selectedGudang == null) {
                  dispatcher.snackbar.show(
                    "Warning",
                    "Pastikan gudang terpilih",
                    "warning"
                  );
                }

                if (unvalid.kode == null) {
                  let send = { id: dModel.id, id_gudang: selectedGudang };
                  setShowAlert(false);
                  console.log('Send',send);
                  await dispatcher.penjualan.validate(send);
                } else {
                  dispatcher.snackbar.show(
                    "Warning",
                    "Pastikan semua field terisi",
                    "warning"
                  );
                }
                setShowAlert(false);
                refreshData();
              }}
            >
              Simpan Perubahan
            </Button>)
        }
        </Modal.Footer>
      </Modal>
      <TableWithoutPagination columns={columntable} data={datatable} />
    </>
  );
}

import {
  hapusSeparator,
  separatorHarga,
} from "../../../services/separator-harga";

import moment from "moment";
import pengeluaranBankOperasionalDetailModel from "./pengeluaranBankOperasionalDetailModel";
import store from "../../../redux/store";

export class pengeluaranBankOperasionalModel {
  constructor(
    data = {
      transaksi: {
        id: 0,
        no_transaksi: "",
        no_transaksi_bku: "",
        id_transaksi_jenis: 3,
        id_transaksi_ref: "",
        nama_supplier: "",
        cabang: "",
        kontak_ref: "",
        tanggal: "",
        tanggal_approved: "",
        akun: "",
        id_bank: "",
        nama_bank: "",
        no_rekening: "",
        total: "",
        deskripsi: "",
        id_user: store.getState().auth['id'],
        is_approved: 0,
        status: 0,
      },
      detail: [],
    }
  ) {
    const { id, no_transaksi, id_transaksi_jenis, id_transaksi_ref, nama_supplier, tanggal, no_transaksi_bku, tanggal_approved, cabang, kontak_ref, akun, id_bank, nama_bank, no_rekening, total, deskripsi, id_user, is_approved, status } = data['transaksi'];
    this['transaksi'] = {
      id: id,
      no_transaksi: no_transaksi,
      no_transaksi_bku: no_transaksi_bku,
      cabang: cabang,
      kontak_ref: kontak_ref,
      tanggal: tanggal,
      tanggal_approved: tanggal_approved,
      akun: akun,
      id_bank: id_bank,
      nama_bank: nama_bank,
      no_rekening: no_rekening,
      total: total,
      deskripsi: deskripsi,
      id_user: id_user,
      id_transaksi_jenis: id_transaksi_jenis,
      nama_supplier: nama_supplier,
      id_transaksi_ref: id_transaksi_ref,
      is_approved: is_approved,
      status: status,
    };
    this.detail = [];
    this.initDetails(data['detail']);
  }

  initDetails(detail) {
    if (detail.length > 0) {
      detail.forEach((item) => {
        this['detail'] = [
          ...this['detail'],
          new pengeluaranBankOperasionalDetailModel({
            ...item,
          }),
        ];
      });
    }
  }

  // Handle Detail
  handleDetail = {
    // Set Data Detail Modal
    set: async (item) => {
      const setSetails = this['detail'].filter((params) => { return params['status'] !== 0 });
      item['id'] = setSetails.length + 1;
      item['jumlah'] = parseInt(hapusSeparator(item['jumlah']));
      this['detail'] = [...setSetails, item];
    },
    // Delete Detail Modal
    del: async (dataModel) => {
      this['detail'].map((val, i) => {
        if (val['id'] === dataModel['id']) {
          this['detail'][i]['status'] = 0;
        }
        return val;
      });
      const delDetails = this['detail'].filter((params) => { return params['status'] !== 0 });
      this['detail'] = [...delDetails];
    },
    // Reset Detail Data
    reset: async () => {
      this['detail'].map((val, i) => {
        if (val['status'] === 1) {
          this['detail'][i]['status'] = 0;
        }
        return val;
      });
      const resetdetails = this['detail'].filter((params) => { return params['status'] !== 0 });
      this['detail'] = [...resetdetails];
    },
    // Edit Details Modal
    edit: async (item) => {
      this['detail'][this['detail'].map((e) => e['id']).indexOf(item['id'])] = item;
    },
  };

  async getdata() {
    const { no_transaksi, no_transaksi_bku, cabang, kontak_ref, tanggal, tanggal_approved, akun, nama_bank, no_rekening, total, deskripsi } = this['transaksi'];
    return [
      {
        id: "no_transaksi",
        title: "No. Transaksi",
        label: no_transaksi,
        center: true,
        sortable: true,
        minWidth: "25%",
      }, {
        id: "tanggal",
        title: "Tanggal",
        label: moment(tanggal).format("DD-MM-YYYY"),
        center: true,
        sortable: true,
        minWidth: "17%",
      }, {
        id: "no_bku",
        title: "No. Bukti BKU",
        label: no_transaksi_bku,
        center: true,
        sortable: true,
        minWidth: "25%",
      }, {
        id: "tanggal_approved",
        title: "Tanggal Approved",
        label: tanggal_approved !== "" ? moment(tanggal_approved).format("DD-MM-YYYY") : "",
        center: true,
        sortable: true,
        minWidth: "17%",
      }, {
        id: "id_perusahaan",
        title: "Cabang",
        label: cabang,
        center: true,
        sortable: true,
        minWidth: "20%",
      }, {
        id: "kontak_ref",
        title: "Kepada",
        label: kontak_ref,
        sortable: true,
        minWidth: "30%",
      }, {
        id: "akun",
        title: "Akun",
        label: akun,
        center: true,
        sortable: true,
        minWidth: "15%",
      }, {
        id: "nama_bank",
        title: "Nama Bank",
        label: nama_bank,
        center: true,
        sortable: true,
        minWidth: "25%",
      }, {
        id: "no_rekening",
        title: "No. Rekening",
        label: no_rekening,
        center: true,
        sortable: true,
        minWidth: "20%",
      }, {
        id: "total",
        title: "Total",
        label: total < 0 ? `Rp. -${separatorHarga(total.toString())}` : separatorHarga(total.toString(), "Rp. "),
        right: true,
        sortable: false,
        minWidth: "15%",
      }, {
        id: "deskripsi",
        title: "Keterangan",
        label: deskripsi,
        sortable: true,
        minWidth: "60%",
      },
    ];
  }
}
export class PengeluaranOperasionalSupplierModel {
  constructor(
    data = {
      id: 0,
      kode: '',
      tanggal: '',
      nama_supplier: '',
      alamat_supplier: '',
      nama_sales: '',
      tersisa: '',
      terbayar: '',
      total: '',
      status: 0,
    }
  ) {
    const {
      id,
      kode,
      tanggal,
      nama_supplier,
      alamat_supplier,
      nama_sales,
      tersisa,
      terbayar,
      total,
      status,
    } = data;
    this['id'] = id;
    this['kode'] = kode;
    this['tanggal'] = tanggal;
    this['nama_supplier'] = nama_supplier;
    this['alamat_supplier'] = alamat_supplier;
    this['nama_sales'] = nama_sales;
    this['tersisa'] = tersisa;
    this['terbayar'] = terbayar;
    this['total'] = total;
    this['jumlah'] = '';
    this['status'] = status;
  }

  async getdata() {
    const {
      id,
      kode,
      tanggal,
      alamat_supplier,
      nama_sales,
      tersisa,
      total,
      terbayar,
      jumlah,
    } = this;
    return [
      {
        id: "kode",
        title: "No. Invoice",
        label: kode,
        center: true,
        sortable: true,
        minWidth: "20%",
      }, {
        id: "tanggal",
        title: "Tanggal",
        label: moment(tanggal).format("DD-MM-YYYY"),
        center: true,
        sortable: true,
        minWidth: "15%",
      }, {
        id: "alamat_supplier",
        title: "Alamat Supplier",
        label: alamat_supplier,
        sortable: true,
        minWidth: "40%",
      }, {
        id: "nama_sales",
        title: "Nama Sales",
        label: nama_sales,
        sortable: true,
        minWidth: "30%",
      }, {
        id: "tersisa",
        title: "Tersisa",
        label: tersisa < 0 ? `Rp. -${separatorHarga(tersisa.toString())}` : separatorHarga(tersisa.toString(), "Rp. "),
        sortable: false,
        minWidth: "20%",
      }, {
        id: "total",
        title: "Total",
        label: total < 0 ? `Rp. -${separatorHarga(total.toString())}` : separatorHarga(total.toString(), "Rp. "),
        sortable: false,
        minWidth: "20%",
      }, {
        id: "jumlah",
        title: "Jumlah",
        label: jumlah,
        sortable: false,
        minWidth: "20%",
        omit: true
      }, {
        id: "terbayar",
        title: "Terbayar",
        label: terbayar < 0 ? `Rp. -${separatorHarga(terbayar.toString())}` : separatorHarga(terbayar.toString(), "Rp. "),
        sortable: false,
        minWidth: "20%",
        omit: true
      },
    ];
  }
}

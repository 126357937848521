import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import Model from "../../../models/penerimaan-pembelian-model/PenerimaanPembelianModel";
import { Link } from "react-router-dom";
import { dispatcher } from "../../../redux/dispatcher";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import StokMasukModel from "../../../models/stok-masuk-model/StokMasukModel";
import moment from "moment";
import { PenerimaanAPI } from "../../../api/penerimaan";
import Select2 from "react-select";
import store from "../../../redux/store";
import StokMasukDetailModel from "../../../models/stok-masuk-model/StokMasukDetailModel";

export default function List() {
  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const pageName = "Stok Masuk Pembelian";
  const [startDate, setStartDate] = useState(
    moment(new Date())
      .startOf("month")
      .format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [search, setSearch] = useState("");
  const [option, setOptions] = useState({
    value: store.getState().auth.id_perusahaan || 0,
    text: "--- Pilih Cabang ---",
  });
  const [cabang, setCabang] = useState([]);
  let options = [];

  const coverage = async () => {
    await new PenerimaanAPI().getCoverage().then((res) => {
      options = res.data.map((data) => ({
        label: data.text,
        text: data.text,
        value: data.id,
      }));
    });
    setCabang(options);
  };


  async function loadData() {
    let datas = dispatcher.penerimaan.getList();
    let dataMaster = datas;
    console.log('dataMaster',dataMaster);
    let simpleTableModel = new SimpleTableModel();
    let searchParams = ["no_transaksi", "qty_total", "kode"];
    let filtered = dataMaster.filter((filter) => {
      let dateFormat = moment(filter.master.created_at).format("YYYY-MM-DD");
      let dateFilter = dateFormat >= startDate && dateFormat <= endDate;
      let cabangSelected = filter.master.id_perusahaan === option.value;
      return searchParams.some((item) => {
        return (
          dateFilter &&
          cabangSelected &&
          // console.log(filter.master.toSupplier.nama)
          filter.master[item]
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())
        );
      });
    });
    const actions = (dataModel, dataDetail) => {
      return (
        <>
         <Link
            className="mx-1 btn btn-outline-success btn-sm"
            to="/stok-masuk-pembelian/detail"
            onClick={() => {
              dispatcher.penerimaan.set(dataModel);
            }}
          >
            Detail
          </Link>
          {
            dataModel.master.status_invoice === 2 &&
            dataDetail.includes(true) ?
              <Link
                className="mx-1 btn btn-outline-primary btn-sm"
                to="/stok-masuk-pembelian/inbound"
                onClick={() => {
                  dispatcher.penerimaan.set(dataModel);
                }}
              >
                Inbound
              </Link>
            : dataModel.master.status_validasi === 1 ?
            <Link
                className="mx-1 btn btn-outline-primary btn-sm"
                to="/stok-masuk-pembelian/inbound"
                onClick={() => {
                  dispatcher.penerimaan.set(dataModel);
                }}
              >
                Inbound
              </Link> : " "}
        </>
      );
    };

    filtered.map((item, i) => {
      let dataModel = new StokMasukModel(item);
      let dataDetail = item.detail.map((val) => val.qty !== val.qty_inbound)
      console.warn('detailData',dataDetail);
      if (item.master.status === 1)
        simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: actions(dataModel, dataDetail),
        });
    });

    setColumntable(await simpleTableModel.getColumnHeaders());
    setDatatable(await simpleTableModel.getDatatables());
  }

  useEffect(() => {
    dispatcher.penerimaan.list();
  }, []);

  useEffect(() => {
    loadData();
    coverage();
  }, [dispatcher.penerimaan.getList()]);

  return (
    <>
      <AnimatedCard>
        <CardHeader title={pageName}></CardHeader>
        <CardBody>
        <div className="d-flex justify-content-start form-group row">
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Mulai </label>
              <input
                name={"tanggal_awal"}
                type={"date"}
                value={startDate}
                className={"form-control"}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  loadData();
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Akhir </label>
              <input
                name={"tanggal_akhir"}
                type={"date"}
                value={endDate}
                className={"form-control"}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  loadData();
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Pilih Perusahaan </label>
              <Select2
                name={"Cabang"}
                placeholder={option.text}
                size="sm"
                options={cabang}
                isClearable={true}
                onChange={(e) => {
                  setOptions({ value: e ? e.value : [] });
                  dispatcher.penerimaan.list();
                  // setFieldValue("cabang", e ? e.value : "");
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <input
                type={"text"}
                className={"form-control"}
                placeholder="Cari Data ..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  dispatcher.penerimaan.list();
                }}
              />
            </div>
          </div>
          <SimpleTable data={datatable} columns={columntable} />
        </CardBody>
      </AnimatedCard>
    </>
  );
}

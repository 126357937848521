import React from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Fields } from "../../../components/fields/Fields";
import UserModel from "../../../models/user-model/User";
import { UserAPI } from "../../../api/user";
import { CoverageAPI } from "../../../api/coverage";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import { dispatcher } from "../../../redux/dispatcher";

export default class User extends React.Component {
  constructor(props) {
    super(props);
    this.initialData = new UserModel();
    this.api = new UserAPI();
    this.pageName = "User";
    this.state = {
      modal: {
        show: false,
        initialData: this.initialData,
        isEdit: false,
      },
      alert: {
        initialData: this.initialData,
        activeId: null,
        show: false,
      },
    };
    this.validation = Yup.object().shape({});
    this.columntable = [{ dataField: "", text: "" }];
    this.datatable = [{ id: 0, nama: "" }];
    this.fields = [];
  }

  async componentDidMount() {
    dispatcher.loading.show();
    await this.loadData();
    let yup = {};
    this.state.modal.initialData.getEditable().then((values) =>
      values.forEach((item) => {
        yup[item.dataField] = item.validation;
      })
    );
    this.validation = Yup.object().shape(yup);
    dispatcher.loading.hide();
  }

  async loadData() {
    // let datas = await this.api.getAll();
    await dispatcher.userLogin.list();
    let datas = dispatcher.userLogin.getList();
    this.simpleTableModel = new SimpleTableModel();
    datas.forEach((item, i) => {
      let dataModel = new UserModel(item);
      this.simpleTableModel.add({
        id: item.id,
        no: i + 1,
        dataModel: dataModel,
        actions: (
          <span>
            <Button
              size="sm"
              className="mx-1"
              variant="outline-primary"
              onClick={() => this.handleModal.edit(dataModel)}
            >
              Edit User
            </Button>
            {item.status === 1 ? (
              <Button
                size="sm"
                className="mx-1"
                variant="primary"
                onClick={() => {
                  this.handleAlert.show(dataModel);
                }}
              >
                <i className="fas fa-user-check"></i>
                Aktif
              </Button>
            ) : (
              <Button
                size="sm"
                className="mx-1"
                variant="warning"
                onClick={() => {
                  this.handleAlert.show(dataModel);
                }}
              >
                <i className="fas fa-user-lock"></i>
                Tidak Aktif
              </Button>
            )}
          </span>
        ),
      });
    });

    this.datatable = await this.simpleTableModel.getDatatables();
    this.columntable = await this.simpleTableModel.getColumnHeaders();
    this.setState({ ...this.state });
  }

  handleModal = {
    loadFields: async (isEdit) => {
      this.fields = [];
      await this.state.modal.initialData
        .getEditable({
          pageName: this.pageName,
          isForm: true,
          ...isEdit,
        })
        .then((values) => {
          values.map((item, i) => {
            return (this.fields = [...this.fields, { ...item }]);
          });
        });
    },
    tambah: async () => {
      let state = { ...this.state };
      state.modal.show = true;
      state.modal.title = `${this.pageName} Baru`;
      state.modal.isEdit = false;
      await this.handleModal.loadFields({ isEdit: false });
      this.setState({ state });
    },
    edit: async (dataFromModel) => {
      let state = { ...this.state };
      state.modal.show = true;
      state.modal.title = `Edit ${this.pageName}`;
      state.modal.initialData = dataFromModel;
      state.modal.isEdit = true;
      await this.handleModal.loadFields();
      this.setState({ state });
    },
    hide: () => {
      let modal = { ...this.state.modal };
      modal.show = false;
      modal.initialData = this.initialData;
      this.setState({ modal });
    },
  };

  handleAlert = {
    show: (dataFromModel) => {
      let alert = { ...this.state.alert };
      alert.initialData = dataFromModel;
      alert.show = true;
      this.setState({ alert: alert });
    },
    hide: () => {
      let alert = { ...this.state.alert };
      alert.show = false;
      alert.activeId = null;
      this.setState({ alert: alert });
    },
    confirmed: async () => {
      let alert = { ...this.state.alert };
      if (alert.initialData.status === 0) {
        alert.initialData.status = 1;
      } else {
        alert.initialData.status = 0;
      }
      await this.api.edit(alert.initialData);
      this.handleAlert.hide();
      this.loadData();
    },
  };

  render() {
    return (
      <>
        <AnimatedCard>
          <Modal
            show={this.state.alert.show}
            onHide={() => this.handleAlert.hide()}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Ubah Status User</Modal.Title>
            </Modal.Header>
            <Modal.Body>Anda yakin ingin menrubah status user ini?</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => this.handleAlert.hide()}
              >
                Batal
              </Button>
              <Button
                variant="warning"
                onClick={() => this.handleAlert.confirmed()}
              >
                Simpan Perubahan
              </Button>
            </Modal.Footer>
          </Modal>
          <CardHeader title={this.pageName}>
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.handleModal.tambah()}
              >
                <i className="fa fa-plus"></i>
                Tambah {this.pageName}
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <SimpleTable data={this.datatable} columns={this.columntable} />
          </CardBody>
        </AnimatedCard>
        <Formik
          enableReinitialize={true}
          initialValues={this.state.modal.initialData}
          validationSchema={this.validation}
          onSubmit={async (values, { resetForm }) => {
            if (this.state.modal.isEdit) {
              await this.api.edit(this.state.modal.initialData);
              await new CoverageAPI().set(this.state.modal.initialData);
            } else if (!this.state.modal.isEdit) {
              await this.api.set(this.state.modal.initialData).then((res) => {
                var send = {
                  ...this.state.modal.initialData,
                  id: res.data.id_user,
                };
                new CoverageAPI().set(send);
              });
            }
            this.handleModal.hide();
            resetForm();
            this.loadData();
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Modal
              show={this.state.modal.show}
              onHide={this.handleModal.hide}
              size="md"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modal.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <div className="row">
                    {this.fields.map((field, i) => {
                      return (
                        <div key={i} className="form-group col-lg-12">
                          <Fields {...field} setFieldValue={setFieldValue} />
                        </div>
                      );
                    })}
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={this.handleModal.hide}
                  className="btn btn-light btn-elevate"
                >
                  Batal
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  Simpan
                </button>
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      </>
    );
  }
}

import React, { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import List from "./list";
import Detail from "./detail";
import Inbound from "./inbound";
import StokRequestModel from "../../../models/stok-req-model/StokRequestModel";

export default function TerimaTransferReq() {
  const [dataFromModel, setDataFromModel] = useState(new StokRequestModel());
  const history = useHistory();

  return (
    <Switch>
      <Route path="/terima-transfer-stok/list">
        <List setDataFromModel={setDataFromModel} />
      </Route>
      <Route path="/terima-transfer-stok/detail">
        <Detail history={history} dataFromModel={dataFromModel} />
      </Route>
      <Route path="/terima-transfer-stok/inbound">
        <Inbound history={history} dataFromModel={dataFromModel} />
      </Route>
      <Redirect from="/terima-transfer-stok/" to="/terima-transfer-stok/list"/>
    </Switch>
  )
  }
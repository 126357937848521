import React, { useEffect } from 'react'
import { CardBody } from '../../../../_metronic/_partials/controls'
import { AnimatedCard } from '../../../components/animated-card/animatedCard'
import Chart from "react-apexcharts";
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { separatorHarga } from '../../../services/separator-harga';
import store from '../../../redux/store';
import Select2 from "react-select";
import { LaporanGrafik } from '../../../api/laporan-grafik';
import { saveAsExcel } from '../helperExportExcel';
import { ReactDatatable } from '../../../components/react-datatable/reactDatatable';
import DatePicker from "react-datepicker";


function GrafikPenjualanCabang() {
  const [selectedYear, setSelectedYear] = useState(new Date());
  const years = new Date(selectedYear).getFullYear();
  const [bulan] = useState(['January', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'])
  const columns = [
    {
      name: 'Nama',
      selector: row => row.name,
      sortable: true,
      minWidth: '12%'
    }
  ];

  bulan.map((data, i) => {
    return columns.push(
      {
        name: data,
        selector: row => separatorHarga(row.penjualan[i].toString(), 'Rp.'),
        sortable: true,
        minWidth: '12%'
      })
  })

  const [options] = useState({
    chart: {
      type: 'line',
      width: "100%",
      // id: "basic-bar"
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
        }).format(value);
        }
      },
    },
    xaxis: {
      categories: bulan
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
            show: false
        }
      }
    }]
  })

  const ksa = [20, 46, 52, 69];
  const kpp = [68, 28, 70, 71];
  const ksb = [108, 105, 110, 109];

  const [pt] = useState([{
    value: ksa,
    label: "KSA"
  }, {
    value: kpp,
    label: "KPP"
  }, {
    value: ksb,
    label: "KSB"
  }])

  const [selectedPT, setSelectedPT] = useState(ksa.includes(store.getState().auth.id_perusahaan) ? ksa : kpp.includes(store.getState().auth.id_perusahaan) ? kpp : ksb);

  const [cabang, setCabang] = useState([0]);

  const title = `LaporanPenjualanCabang_${years}`;
  const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>
    <i className={"far fa-file-excel"}></i> {" Export .xls"}
  </Button>
  const actionsMemo = <Export onExport={() => saveAsExcel(penjualan, title)} />

  const [opsi, setOptions] = useState([
  ]);

  let opsiCabang = []

  const coverage = async () => {
    await new LaporanGrafik().getCoverage().then((res) => {
      let filter = res.data.filter((data) => selectedPT.includes(data.id))
      opsiCabang = filter.map((data) => (
        {
          label: data.text,
          text: data.text,
          value: data.id,
        }));
    });
    setOptions(opsiCabang);
  };


  const [penjualan, setPenjualan] = useState([])

  const [series, setSeries] = useState([])

  const fetchData = async (data,y) => {
    try {
      await new LaporanGrafik().getAllCabang(data,y).then((res) => res.data.map((val) => {
        // console.log('Valll',val);
        // dataSeries.push(val)
        console.log("Vall",val);
        const map = new Map(val.map(({ nama_perusahaan, count, total_penjualan }) => [nama_perusahaan, { nama_perusahaan, count: [], total_penjualan: [], }]));
        for (let { nama_perusahaan, count, total_penjualan } of val) {
          map.get(nama_perusahaan).count.push(...[count].flat())
          map.get(nama_perusahaan).total_penjualan.push(...[total_penjualan].flat())
        }
        let temp = []
        temp.push([...map.values()])
        temp.map((val) => {
          for (const item of val) {
            // console.log('ITEMM', item);
            setSeries(series => [...series, {
              name: item.nama_perusahaan,
              data: item.total_penjualan
            }])
            setPenjualan(penjualan => [...penjualan, {
              name: item.nama_perusahaan,
              penjualan: item.total_penjualan
            }])
          }
        })
      }));
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    // if (dataSeries.length === 0)
    // {
    if (cabang.includes(0)) {
      fetchData(selectedPT,years);
      // setIdPerusahaan(selectedPT)
    } else {
      fetchData(cabang,years);
      // setIdPerusahaan(cabang)
    }
    coverage();
    // }
  }, [cabang, selectedPT,years])
  return (
    <>
      {/* {console.log('Selected', sKategori)} */}
      <AnimatedCard>
        <CardBody>
          <div className="d-flex justify-content-start form-group row">
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <label> Pilih PT </label>
              <Select2
                name={"PT"}
                size="sm"
                options={pt}
                isClearable={false}
                value={
                  pt
                    ? pt.find(
                      (option) => option.value === selectedPT
                    )
                    : ""
                }
                onChange={(e) => {
                  setSelectedPT(e ? e.value : []);
                  setCabang([0])
                  setSeries([]);
                  setPenjualan([]);
                }}
              />
            </div>
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <label> Pilih Perusahaan </label>
              <Select2
                name={"Cabang"}
                placeholder={opsi.text}
                size="sm"
                options={opsi}
                isClearable={true}
                value={
                  opsi
                    ? opsi.find(
                      (option) => option.value === cabang
                    )
                    : ""
                }
                onChange={(e) => {
                  setCabang(e ? [e.value] : [0]);
                  setSeries([]);
                  setPenjualan([]);
                }}
              />
            </div>
            <div className="col-lg-2" style={{ marginTop: "20px" }}>
              <label> Tahun </label>
              <DatePicker
                id="DatePicker"
                className="form-control form-control-md text-center"
                type="string"
                selected={selectedYear}
                onChange={(date) => {
                  if (years !== date.getFullYear()) {
                    setSelectedYear(date);
                    setSeries([]);
                    setPenjualan([]);
                  }
                }}
                showYearPicker
                dateFormat="yyyy"
              />
            </div>
          </div>
          <div className="col">
            <div className="mixed-chart">
              {
                series.length > 0 ?
                  <Chart
                    options={options}
                    series={series}
                    height={450}
                    animations={{
                      enabled: true,
                      easing: 'easeinout',
                      speed: 800,
                      animateGradually: {
                        enabled: true,
                        delay: 150
                      },
                      dynamicAnimation: {
                        enabled: true,
                        speed: 350
                      }
                    }}
                  />
                  :
                  ""
              }
            </div>
          </div>
          <ReactDatatable
            columns={columns}
            data={penjualan}
            dense
            pagination
            searchParameter={['name']}
            actions={actionsMemo}
          />
        </CardBody>
      </AnimatedCard>
    </>
  )
}

export default GrafikPenjualanCabang
import { getCoverageArea, getIdPerusahaan, simpleFetch } from "./api";
import store from "../redux/store";
export class GudangAPI {
  async getAll() {
    let coverage = store.getState().auth.coverage;
      let params = "id_perusahaan=";
      if (coverage.length > 0) {
        params += coverage.join("&id_perusahaan=");
      } else {
        params += getIdPerusahaan();
      }
    let res = await simpleFetch({
      // url: "retail/gudang?id_perusahaan=" + getIdPerusahaan(),
      url: "retail/gudang?" + params,
      method: "GET",
    });
    return res;
  }

  async getAllParent() {
    let res = await simpleFetch({
      url: "retail/gudang?id_perusahaan=" + getIdPerusahaan() + "&jenis=parent",
      method: "GET",
    });
    return res;
  }

  async getbyPerusahaan(id) {
    let res = await simpleFetch({
      url: "retail/gudang/perusahaan?id_perusahaan=" + id,
      method: "GET",
    });
    return res;
  }

  async getCoverage(){
    let res = await simpleFetch({
      url: "retail/select/perusahaan?id_coverage=" + getCoverageArea(),
      method: "GET",
    })
    return res;
  }

  async getAllRow() {
    let res = await simpleFetch({
      url: "retail/gudang?id_perusahaan=" + getIdPerusahaan() + "&jenis=row",
      method: "GET",
    });
    return res;
  }

  async getSingle(id) {
    let res = await simpleFetch({
      url: "retail/gudang?id=" + id,
      method: "GET",
    });
    return res;
  }

  async set(values) {
    let values2send = {};
    if (values.id_parent == 0) {
      values2send = {
        ...values,
        id_parent: null,
      };
    } else {
      values2send = {
        ...values,
      };
    }
    let res = await simpleFetch({
      url: "retail/gudang",
      method: "POST",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values2send,
      },
    });
    return res;
  }

  async edit(values) {
    let values2send = {};
    if (values.id_parent == 0) {
      values2send = {
        ...values,
        id_parent: null,
      };
    } else {
      values2send = {
        ...values,
      };
    }
    let res = await simpleFetch({
      url: "retail/gudang",
      method: "PUT",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values2send,
      },
    });
    return res;
  }

  async delete(id) {
    let res = await simpleFetch({
      url: "retail/gudang",
      method: "DELETE",
      body: {
        id: id,
      },
    });
    return res;
  }
}

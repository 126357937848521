import * as Yup from "yup";

import { CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls";

import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import { BankAPI } from "../../../api/bank";
import BankModel from "../../../models/bank-model/bankModel";
import { Button } from "react-bootstrap";
import DataTableModel from "../../../models/datatable-model/DataTableModel";
import { Formik } from "formik";
import { FormsFields } from "../../../components/forms-fields/FormsFields";
import { ModalForms } from "../../../components/modal-forms/modalForms";
import React from "react";
import { ReactDatatable } from "../../../components/react-datatable/reactDatatable";
import SimpleAlert from "../../../components/alert/alert";
import { dispatcher } from "../../../redux/dispatcher";

export default class Bank extends React.Component {
  constructor(props) {
    super(props);
    this['pageName'] = "Bank";
    this['searchParameter'] = ["jenis_bank", "nama_perusahaan", "cabang", "nama_bank", "no_rekening"];
    this['apiBank'] = new BankAPI();
    this['initialData'] = new BankModel();
    this['state'] = {
      pending: true,
      modal: {
        show: false,
        initialData: this['initialData'],
        isEdit: false,
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
    this['datatable'] = [{ id: "", no: "", name: "" }];
    this['columntable'] = [{ id: "", title: "" }];
  }
  async componentDidMount() {
    await this.LoadData();
  }
  async LoadData() {
    this.setState({ pending: true });
    let data = await this['apiBank'].getList();
    this['DataTableModel'] = new DataTableModel();
    data['data'].map((item, i) => {
      let dataModel = new BankModel(item);
      if (item['status'] === 1) {
        this['DataTableModel'].add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: (
            <>
              <Button
                size={"sm"}
                className={"mx-1"}
                variant={"outline-primary"}
                onClick={async () => await this['handleModal'].edit(dataModel)}
              > Edit
              </Button>
              <Button
                size={"sm"}
                className={"mx-1"}
                variant={"outline-danger"}
                onClick={async () => {
                  this['handleAlert'].show({ id: item['id'], id_perusahaan: item['id_perusahaan'] });
                  this.LoadData();
                }}
              > Hapus
              </Button>
            </>
          ),
        });
      }
      return item;
    });
    this['columntable'] = await this['DataTableModel'].getColumn();
    this['datatable'] = await this['DataTableModel'].getDatas();
    this.setState({ ...this['state'], pending: false });
  }
  handleModal = {
    loadOptionJenisBank: async () => {
      this['apiBank'].getJenisBank().then((res) => {
        // Load Data option Bank
        this['option_jenis_bank'] = res['data'].map((val) => {
          return {
            value: val['id'],
            label: val['text'],
          };
        });
      });
    },
    loadOptionCoverage: async () => {
      this['apiBank'].getCoverage().then((res) => {
        // Load Data option Bank
        this['options_cabang'] = res['data'].map((val) => {
          return {
            value: val['id'],
            label: val['text'],
          };
        });
      });
    },
    // Tambah Modal
    tambah: async () => {
      let state = { ...this['state'] };
      state = {
        modal: {
          show: true,
          title: `Tambah ${this['pageName']}`,
          isEdit: false,
          initialData: this['initialData']
        }
      }
      await this['handleModal'].loadOptionJenisBank();
      await this['handleModal'].loadOptionCoverage();
      this.setState({ ...state });
    },
    edit: async (dataFromModel) => {
      let state = { ...this['state'] };
      state = {
        modal: {
          show: true,
          title: `Edit ${this['pageName']}`,
          isEdit: true,
          initialData: dataFromModel
        }
      }
      await this['handleModal'].loadOptionJenisBank();
      await this['handleModal'].loadOptionCoverage();
      this.setState({ ...state });
    },
    hide: () => {
      let state = { ...this['state'] };
      state = {
        modal: {
          show: false,
          initialData: this['initialData']
        }
      }
      this.setState({ ...state });
    },
  };
  handleAlert = {
    show: (id) => {
      let alert = { ...this['state'] };
      alert = {
        alert: {
          show: true,
          activeId: id
        }
      }
      this.setState({ ...alert });
    },
    hide: () => {
      let alert = { ...this['state'] };
      alert = {
        alert: {
          show: false,
          activeId: null
        }
      }
      this.setState({ ...alert });
    },
    confirmed: async () => {
      await this['apiBank'].delete(this['state']['alert']['activeId']).then((res) => {
        if (res['status']) {
          dispatcher['snackbar'].show("Berhasil", res.message, "primary");
          this['handleAlert'].hide();
          this.LoadData();
        } else {
          return dispatcher['snackbar'].show("Gagal", res.message, "danger");
        }
      });
    },
  };
  render() {
    const { pending, modal, alert } = this['state'];
    return (
      <>
        {/* Form List */}
        <AnimatedCard>
          <CardHeader title={this['pageName']}>
            <CardHeaderToolbar>
              <button
                type={"button"}
                className={"btn btn-primary"}
                onClick={() => this['handleModal'].tambah()}
              ><i className="fa fa-plus"></i> Tambah
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <ReactDatatable
              title={null}
              columns={this['columntable']}
              data={this['datatable']}
              searchParameter={this['searchParameter']}
              progressPending={pending}
            />
          </CardBody>
        </AnimatedCard>
        {/* Modal Tambah/Edit */}
        <Formik
          enableReinitialize={true}
          initialValues={modal['initialData']}
          validationSchema={Yup.object().shape({
            id_bank_jenis: Yup.string().nullable(true).required("Jenis Bank harus diisi"),
            id_perusahaan: Yup.string().nullable(true).required("Perusahaan harus diisi"),
            nama_bank: Yup.string().required(),
            no_rekening: Yup.number().required(),
          })}
          onSubmit={async (values, { resetForm }) => {
            let dataSend = {
              id_perusahaan: parseInt(values['id_perusahaan']),
              id_bank_jenis: parseInt(values['id_bank_jenis']),
              nama: values['nama_bank'],
              no_rekening: values['no_rekening']
            };
            if (modal['isEdit']) {
              // Update Data
              dataSend = {
                id: parseInt(values['id']),
                ...dataSend
              }
              await this['apiBank'].edit(dataSend).then((res) => {
                if (res['status']) {
                  dispatcher['snackbar'].show("Berhasil", res.message, "primary");
                  this['handleModal'].hide();
                  this.LoadData();
                } else {
                  return dispatcher['snackbar'].show("Gagal", res.message, "danger");
                }
              });
            } else {
              // Insert Data
              await this['apiBank'].set(dataSend).then((res) => {
                if (res['status']) {
                  dispatcher['snackbar'].show("Berhasil", res.message, "primary");
                  this['handleModal'].hide();
                  this.LoadData();
                } else {
                  return dispatcher['snackbar'].show("Gagal", res.message, "danger");
                }
              });
            }
          }}
        >
          {({ handleSubmit, setFieldValue, setFieldTouched, touched, errors, values }) => (
            <ModalForms
              show={modal['show']}
              title={modal['title']}
              onHide={this['handleModal']['hide']}
              onConfirm={handleSubmit}
              content={
                <div className={"form-group row"}>
                  {/* Jenis Bank */}
                  <FormsFields
                    id={"id_bank_jenis"}
                    type={"select2"}
                    label={"Jenis Bank"}
                    className={"col-lg-12"}
                    isClearable={true}
                    isSearchable={true}
                    options={this['option_jenis_bank']}
                    paramsFormik={{
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    }}
                  />
                  {/* Perusahaan */}
                  <FormsFields
                    id={"id_perusahaan"}
                    type={"select2"}
                    label={"Perusahaan"}
                    className={"col-lg-12"}
                    style={{ marginTop: "20px" }}
                    isClearable={true}
                    isSearchable={true}
                    options={this['options_cabang']}
                    paramsFormik={{
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    }}
                  />
                  {/* No Rekening */}
                  <FormsFields
                    id={"nama_bank"}
                    type={"text"}
                    label={"Nama Bank"}
                    className={"col-lg-12"}
                    style={{ marginTop: "20px" }}
                    paramsFormik={{
                      setFieldValue,
                      values
                    }}
                  />
                  {/* No Rekening */}
                  <FormsFields
                    id={"no_rekening"}
                    type={"number"}
                    label={"No. Rekening"}
                    className={"col-lg-12"}
                    style={{ marginTop: "20px" }}
                    paramsFormik={{
                      setFieldValue,
                      values
                    }}
                  />
                </div>
              }
            />
          )}
        </Formik>
        {/* Alert Hapus */}
        <SimpleAlert
          title={"Hapus Data"}
          content={"Anda yakin hapus data ini...?"}
          show={alert['show']}
          onHide={() => this['handleAlert'].hide()}
          onConfirmed={this['handleAlert']['confirmed']}
        />
      </>
    );
  }
}

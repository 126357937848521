import { Form, Formik } from "formik";
import React, { Component } from "react";
import { CardBody, CardHeader, CardHeaderToolbar } from "../../../../../../_metronic/_partials/controls";
import { AnimatedCard } from "../../../../../components/animated-card/animatedCard";
import { dispatcher } from "../../../../../redux/dispatcher";
import { Typography } from "@material-ui/core";
import DataTableModel from "../../../../../models/datatable-model/DataTableModel";
import { FormsFields } from "../../../../../components/forms-fields/FormsFields";
import { ReactDatatableDetails } from "../../../../../components/react-datatable/reactDatatable";

export default class DetailPenerimaanBankOperasional extends Component
{
  constructor( props )
  {
    super( props );
    this[ 'pageName' ] = "Detail Penerimaan Bank Operasional";
    this[ 'initialData' ] = dispatcher[ 'kasir_bank_operasional' ].getPenerimaanBankOperasional();
    this[ 'datatable' ] = [ { id: "", no: "", name: "" } ];
    this[ 'columntable' ] = [ { id: "", title: "" } ];
  }
  //* ----------------------------------------------------------------------------- */
  //** Start: Component */
  async componentDidMount ()
  {
    await this.LoadDetail();
  }
  //** End: Component */
  //* ----------------------------------------------------------------------------- */
  //** Start: Load Data List Details */
  async LoadDetail ()
  {
    let data = this[ 'initialData' ][ 'detail' ];
    this[ 'DataTableModel' ] = new DataTableModel( { readOnly: true } );
    data.map( ( item, i ) =>
    {
      this[ 'DataTableModel' ].add( {
        id: i,
        no: i + 1,
        dataModel: item,
        actions: null,
      } );
      return item;
    } );
    this[ 'columntable' ] = await this[ 'DataTableModel' ].getColumn();
    this[ 'datatable' ] = await this[ 'DataTableModel' ].getDatas();
    this.setState( { ...this[ 'state' ] } );
  }
  //** End: Load Detail Table */
  //* ----------------------------------------------------------------------------- */
  render ()
  {
    return (
      <>
        <Formik
          nableReinitialize={ true }
          initialValues={ this[ 'initialData' ][ 'transaksi' ] }
          validationSchema={ false }
        >
          { ( { values } ) => (
            <AnimatedCard>
              <CardHeader title={ this[ 'pageName' ] }>
                <CardHeaderToolbar>
                  {/* Button Kembali */ }
                  <button
                    type={ "button" }
                    className={ "btn btn-outline-secondary mx-1 btn-sm" }
                    onClick={ () => this[ 'props' ][ 'history' ].goBack() }
                  ><i className={ "fa fa-arrow-left" }></i> Kembali
                  </button>
                  {/* Button Cetak Tanda Terima */ }
                  <button
                    type={ "button" }
                    className={ "btn btn-outline-success mx-1 btn-sm" }
                    onClick={ async () =>
                    {
                      dispatcher[ 'kasir_bank_operasional' ].cetakTandaTerima( values[ 'no_transaksi' ] );
                    } }
                  ><i className={ "fa fa-envelope-open-text" }></i> Cetak Tanda Terima
                  </button>
                  {/* Button Cetak Bukti Penerimaan */ }
                  <button
                    type={ "button" }
                    className={ "btn btn-outline-success mx-1 btn-sm" }
                    onClick={ async () =>
                    {
                      dispatcher[ 'kasir_bank_operasional' ].cetakBuktiPenerimaan( values[ 'no_transaksi' ] );
                    } }
                  ><i className={ "fa fa-envelope-open-text" }></i> Bukti Penerimaan
                  </button>
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <Form className={ "form form-label-right" }>
                  <div className={ "form-group row" }>
                    <div className={ "col-lg-6" }>
                      <div className={ "form-group row" }>
                        {/* Jenis Penerimaan */ }
                        <FormsFields
                          id={ 'akun' }
                          type={ "disabled-text" }
                          label={ "Jenis Penerimaan" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          value={ values[ 'akun' ] }
                        />
                        {/* Nama Bank */ }
                        <FormsFields
                          id={ 'nama_bank' }
                          type={ "disabled-text" }
                          label={ "Bank" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          value={ values[ 'nama_bank' ] }
                        />
                        {/* No. Rekening */ }
                        <FormsFields
                          id={ 'no_rekening' }
                          type={ "disabled-text" }
                          label={ "No. Rekening" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          value={ values[ 'no_rekening' ] }
                        />
                        {/* Jenis Transaksi */ }
                        <FormsFields
                          id={ 'nama_transaksi_ref' }
                          type={ "disabled-text" }
                          label={ "Transaksi Ref" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          value={ values[ 'nama_transaksi_ref' ] }
                        />
                        {/* No. Transaksi */ }
                        <FormsFields
                          id={ 'no_transaksi' }
                          type={ "disabled-text" }
                          label={ "No. Transaksi" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          value={ values[ 'no_transaksi' ] }
                        />
                      </div>
                    </div>
                    <div className={ "col-lg-6" }>
                      <div className={ "form-group row" }>
                        {/* Tanggal Transaksi */ }
                        <FormsFields
                          id={ 'tanggal' }
                          type={ "disabled-DatePicker" }
                          label={ "Tanggal Transaksi" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          value={ values[ 'tanggal' ] }
                        />
                        {/* Diterima DAri */ }
                        <FormsFields
                          id={ 'kontak_ref' }
                          type={ "disabled-text" }
                          label={ "Diterima Dari" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          value={ values[ 'kontak_ref' ] }
                        />
                        {/* Keterangan */ }
                        <FormsFields
                          id={ 'deskripsi' }
                          type={ "disabled-textarea" }
                          label={ "Keterangan" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          rows={ 3 }
                          value={ values[ 'deskripsi' ] }
                        />
                        {/* Total */ }
                        <FormsFields
                          id={ 'total' }
                          type={ "disabled-currency" }
                          label={ "Total" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          value={ values[ 'total' ] }
                        />
                      </div>
                    </div>
                  </div>
                </Form>
                {/* Detail Dana */ }
                { values[ 'alias_transaksi_ref' ] === "penerimaan-lain-lain" || values[ 'alias_transaksi_ref' ] === "penerimaan-operasional" ? (
                  <>
                    <span className={ "d-flex align-items-center justify-content-between mt-4 mb-4" }>
                      <Typography variant={ "h6" }>Detail</Typography>
                    </span>
                    <ReactDatatableDetails
                      title={ "" }
                      data={ this[ 'datatable' ] }
                      columns={ this[ 'columntable' ] }
                    />
                  </>
                ) : "" }
              </CardBody>
            </AnimatedCard>
          ) }
        </Formik>
      </>
    );
  }
}

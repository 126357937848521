import React from "react";
import {
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TableWithoutPagination } from "../../../components/table/Table";
import { Modal, Button, Alert } from "react-bootstrap";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Fields } from "../../../components/fields/Fields";
import { Typography } from "@material-ui/core";
import { PesananPenjualanAPI } from "../../../api/pesanan-penjualan";
import PesananPenjualanDetailModel from "../../../models/pesanan-penjualan-detail-model/PesananPenjualanDetailModel";
import { dispatcher } from "../../../redux/dispatcher";
import store from "../../../redux/store";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import PesananPenjualanModel from "../../../models/pesanan-penjualan-model/PesananPenjualanModel";
import { hapusSeparator, separatorHarga } from "../../../services/separator-harga";

export default class TambahPesananPenjualan extends React.Component {
  constructor(props) {
    super(props);
    this.initialData = new PesananPenjualanModel();
    this.initialDetailData = new PesananPenjualanDetailModel();
    this.api = new PesananPenjualanAPI();
    this.simpleTableModel = new SimpleTableModel();
    this.pageName = props.isEdit
      ? "Edit Pesanan Penjualan"
      : "Tambah Pesanan Penjualan";
    this.isEdit = props.isEdit;
    this.state = {
      modal: {
        disabled: false,
        show: false,
        initialDetailData: this.initialDetailData,
        isEdit: true,
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
    this.columntable = [{ dataField: "", text: "" }];
    this.datatable = [{ id: 0, nama: "" }];
    this.fields = [];
    this.validation = Yup.object().shape({});
    this.detailFields = [];
    this.detailValidation = Yup.object().shape({});
    this.role = store.getState().auth.alias_role;
  }

  async componentDidMount() {
    await this.loadFields();
    await this.loadDetail();
    let yup = {};
    await this.initialData.getEditable().then((values) =>
      values.map((item) => {
        return yup[item.dataField] = item.validation;
      })
    );
    this.validation = Yup.object().shape(yup);

    yup = {};
    await this.state.modal.initialDetailData.getEditable().then((values) =>
      values.map((item) => {
        return yup[item.dataField] = item.validation;
      })
    );
    this.detailValidation = Yup.object().shape(yup);
    this.setState({ ...this.state });
  }

  async loadFields() {
    this.fields = [];
    await this.initialData
      .getEditable({ pageName: this.pageName, isForm: true })
      .then((values) => {
        values.map((item, i) => {
          return (this.fields = [...this.fields, item]);
        });
      });
    this.setState({ ...this.state });
  }

  async loadDetail() {
    let datas = this.initialData.detail;
    this.simpleTableModel = new SimpleTableModel();
    datas.map((item, i) => {
      return item.status !== 0 ?
        this.simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: item,
          actions: (
            <span>
             {item.harga_margin === 0 ? (
                <Button size="sm" className="mx-1" variant="primary" title="harga sesuai margin">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </Button>
              ) : item.hargaDpp < item.harga_margin ? (
                <Button size="sm" className="mx-1" variant="warning" title="harga dibawah margin">
                  <i className="fa fa-arrow-down" aria-hidden="true"></i>
                </Button>
              ) : 
                Math.round(parseInt(hapusSeparator(item.dpp_total))/item.qty) < item.harga_margin ? (
                <Button size="sm" className="mx-1" variant="warning" title="harga dibawah margin">
                  <i className="fa fa-arrow-down" aria-hidden="true"></i>
                </Button>
              ) : (
                <Button size="sm" className="mx-1" variant="primary" title="harga sesuai margin">
                  <i className="fa fa-check" aria-hidden="true"></i>
                </Button>
              )}
              <Button
                size="sm"
                className="mx-1"
                variant="outline-primary"
                onClick={async () => await this.handleModal.edit(item)}
              >
                Edit
              </Button>
              {/* {["admin"].includes(this.role) && ( */}
              <Button
                size="sm"
                className="mx-1"
                variant="outline-danger"
                onClick={() => {
                  this.handleAlert.show(item);
                }}
              >
                Hapus
              </Button>
              {/* )} */}
            </span>
          ),
        }) : "";
    });

    this.datatable = await this.simpleTableModel.getDatatables();
    this.columntable = await this.simpleTableModel.getColumnHeaders();
    this.setState({ ...this.state });
  }

  handleModal = {
    loadDetailFields: async () => {
      this.detailFields = [];
      await this.state.modal.initialDetailData
        .getEditable({ pageName: this.pageName, isForm: true })
        .then((values) => {
          values.map((item, i) => {
            return (this.detailFields = [...this.detailFields, { ...item }]);
          });
        });
    },
    tambah: async () => {
      let state = { ...this.state };
      state.modal.initialDetailData = new PesananPenjualanDetailModel();
      console.log('initialData', this.initialData);
      if (this.initialData.master.id_customer === 0) {
        dispatcher.snackbar.show(
          "Warning",
          "Pilih customer terlebih dahulu",
          "warning"
        );
        return null;
      }
      state.modal.show = true;
      state.modal.title = `Item Baru`;
      state.modal.isEdit = false;
      await this.handleModal.loadDetailFields();
      this.setState({ ...state });
    },
    edit: async (dataFromModel) => {
      let state = { ...this.state };
      state.modal.initialDetailData.nama_produk = dataFromModel.nama_produk
      state.modal.initialDetailData = dataFromModel;
      state.modal.show = true;
      state.modal.title = `Edit Item`;
      state.modal.isEdit = true;
      await this.handleModal.loadDetailFields();
      this.setState({ ...state });
    },
    hide: () => {
      let modal = { ...this.state.modal };
      modal.show = false;
      this.setState({ modal });
    },
  };

  handleAlert = {
    show: (dataModel) => {
      let alert = { ...this.state.alert };
      alert.show = true;
      alert.activeId = dataModel;
      this.setState({ alert: alert });
    },
    hide: () => {
      let alert = { ...this.state.alert };
      alert.show = false;
      alert.activeId = null;
      this.setState({ alert: alert });
    },
    confirmed: async () => {
      await this.initialData.handleDetail.del(this.state.alert.activeId);
      this.handleAlert.hide();
      this.loadDetail();
    },
  };

  render() {
    return (
      <>
        <Modal
          show={this.state.alert.show}
          onHide={() => this.handleAlert.hide()}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Hapus Data Ini?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Anda yakin ingin menghapus data ini?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleAlert.hide()}>
              Batal
            </Button>
            <Button
              variant="warning"
              onClick={() => this.handleAlert.confirmed()}
            >
              Hapus
            </Button>
          </Modal.Footer>
        </Modal>
        <Formik
          enableReinitialize={true}
          initialValues={this.initialData.master}
          validationSchema={this.validation}
          onSubmit={async (values) => {
            this.initialData.removeSeparator();
            this.initialData.removeAllDetailSeparator();
            console.log(this.initialData.nilai_limit_tersisa);
            // this.initialData.nilai_limit_tersisa = parseInt(hapusSeparator(this.initialData.nilai_limit_tersisa))
            // console.log('valuesOnSubmit', this.initialData);
            // console.log('valuesOnSubmit2', this.values);
            try {
              // if( this.initialData.master.jenis_pembayaran == 2 && this.initialData.nilai_limit_tersisa - this.initialData.master.total <= 0 ){
              // dispatcher.snackbar.show(
              // "Warning",
              // "Nilai limit tidak mencukupi",
              // "warning"
              // )
              // return null
              // throw Error(dispatcher.snackbar.show(
              //   "Warning",
              //   "Nilai limit tidak mencukupi",
              //   "warning"
              // ))
              // }
              this.setState({ modal: { ...this.state.modal, disabled: true } })
              await this.api.set(this.initialData).then(
                dispatcher.snackbar.show(
                  "Berhasil",
                  "Data berhasil ditambah",
                  "primary"
                )
              );
            } catch (error) {
              console.error(error)
            }
            //   await this.api.edit(this.initialData);
            // } else if (!this.state.modal.isEdit) {
            //   await this.api.set(this.initialData);
            // }
            // this.props.history.goBack();
          }}
        >
          {({ handleSubmit, setFieldValue, }) => (
            <AnimatedCard>
              <CardHeader title={this.pageName}>
                <CardHeaderToolbar>
                  <button
                    type="button"
                    className="btn btn-outline-primary mx-1"
                    onClick={async () => {
                      // console.log('data field',this.fields);
                      window.location.reload();
                    }}
                  >
                    <i className={"fa fa-plus"}></i>
                    Tambah Pesanan Penjualan
                  </button>
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <Form className="form form-label-right">
                  <div className="row">
                    {this.fields.map((field, i) => (
                      ['id_customer', 'tanggal', 'jatuh_tempo', 'no_transaksi', 'nama_sales', 'jenis_pembayaran'].includes(field.dataField)
                        ?
                        <div key={i} className="form-group col-lg-6">
                          <Fields
                            {...field}
                            setFieldValue={setFieldValue}
                          />
                        </div> : ['nama_organisasi'].includes(field.dataField)
                          ?
                          <div key={i} className="form-group col-lg-6">
                            <label>  Organisasi </label>
                            <input type="text" className="form-control" disabled value={this.initialData.nama_organisasi} />
                          </div> : ""
                    ))}
                    <div className="form-group col-md-4">
                      {this.initialData.nilai_limit_tersisa < 0 ?
                        <label>Over Limit</label> : <label>Sisa Limit</label>
                      }
                      <div className="input-group mb-2">
                        <div className="input-group-prepend">
                          <div className="input-group-text"><i className="fa fa-credit-card"></i></div>
                        </div>
                        <input type="text" className={`form-control ${this.initialData.nilai_limit_tersisa < 0 ? "bg-warning" : "bg-primary"}`} disabled value={separatorHarga(this.initialData.nilai_limit_tersisa.toString(), 'Rp.') || "Customer Umum"} />
                      </div>
                    </div>
                  </div>
                  <span className="d-flex align-items-center justify-content-between mt-4">
                    <Typography variant="h6">Detail Item</Typography>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      onClick={async () => await this.handleModal.tambah()}
                    >
                      <i className="fa fa-plus"></i>
                      Tambah Item
                    </button>
                  </span>
                  <TableWithoutPagination
                    data={this.datatable}
                    columns={this.columntable}
                  />
                  <div className="d-flex row" style={{ marginLeft: "40%", marginTop: "20px" }}>
                    {this.fields.map((field, i) => (
                      // console.log("cek field", this.fields[0])
                      // field.dataField === "nama_customer" && "tanggal" 
                      !['id_customer', 'nama_organisasi', 'tanggal', 'jatuh_tempo', 'no_transaksi', 'jenis_pembayaran', 'nama_sales'].includes(field.dataField)
                        ?
                        <div key={i} className="form-group col-md-4 justify-content-around">
                          <Fields
                            {...field}
                            // type="disabled-text"
                            setFieldValue={setFieldValue}
                          />
                        </div> : ""
                    ))}
                  </div>
                </Form>
              </CardBody>
              <CardFooter>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-primary mx-1"
                    disabled={this.state.modal.disabled}
                    onClick={() => {
                      if (
                        this.initialData.master.jenis_pembayaran === "" ||
                        this.initialData.master.jenis_pembayaran === 0
                      ) {
                       return dispatcher.snackbar.show(
                          "Warning",
                          "Pilih jenis pembayaran terlebih dahulu",
                          "warning"
                        );
                      }
                      if (parseInt(this.initialData.master.jenis_pembayaran) === 2 && this.initialData.nama_organisasi === "Tanpa Organisasi") {
                        console.log("Kondisi tanpa organisasi");
                        return dispatcher.snackbar.show(
                          "Warning",
                          "Pilih Jenis Pembayaran yg lain",
                          "warning"
                        )
                        // return null
                      }
                      if (parseInt(this.initialData.master.jenis_pembayaran) === 2 && this.initialData.nilai_limit_tersisa - this.initialData.master.tempTotal < 0) {
                        console.log("kondisi limit terpenuhi", this.initialData.master.tempTotal);
                        return dispatcher.snackbar.show(
                          "Warning",
                          "Nilai limit tidak mencukupi",
                          "warning"
                        )
                      }
                      else {
                        // this.state.modal.disabled =  true;
                        handleSubmit();
                      }
                      // dispatcher.snackbar.show("Berhasil", "Data berhasil ditambah", "primary");
                      // setTimeout(window.location.reload(), 7000)
                    }}
                  >
                    <i className="fa fa-save"></i>
                    Simpan
                  </button>
                </div>
              </CardFooter>
            </AnimatedCard>
          )}
        </Formik>

        <Formik
          enableReinitialize={true}
          initialValues={this.state.modal.initialDetailData}
          validationSchema={this.detailValidation}
          onSubmit={async (values, { resetForm }) => {
            // console.log(this.state.modal.initialDetailData);
            if (this.state.modal.isEdit) {
              await this.initialData.handleDetail.edit(
                this.state.modal.initialDetailData
              );
            } else {
              await this.initialData.handleDetail.set(
                this.state.modal.initialDetailData
              );
            }
            await this.loadDetail();
            resetForm();
            this.handleModal.hide();
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Modal
              show={this.state.modal.show}
              onHide={() => this.handleModal.hide()}
              aria-labelledby="contained-modal-title-vcenter"
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modal.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Alert variant="warning">
                  Pastikan Harga & Detail Lain Yang diinput sudah sesuai
                </Alert>
                <div className="row">
                  {this.detailFields.map((field, i) => {
                    return (
                      !['diskon_item',].includes(field.dataField) ?
                        <div key={i} className="form-group col-lg-6">
                          <Fields {...field} setFieldValue={setFieldValue} />
                        </div> : ""
                    );
                  })}
                </div>
                <Alert variant="warning">
                  Pastikan Harga & Detail Lain Yang diinput sudah sesuai
                </Alert>
                {/* {this.detailFields.map((field, i) => console.log(field))} */}
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={this.handleModal.hide}
                  className="btn btn-light btn-elevate"
                >
                  Batal
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => {
                    console.log("cek submit", this.state.modal.initialDetailData);
                    if (this.state.modal.initialDetailData.diskon_temp > this.state.modal.initialDetailData.hpp_temp) {
                      dispatcher.snackbar.show(
                        "Warning",
                        "Perhatikan Nilai Diskon",
                        "warning"
                      )
                    } else {
                      handleSubmit()
                    }
                  }}
                  className="btn btn-primary btn-elevate"
                >
                  Simpan
                </button>
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      </>
    );
  }
}

import React, { useState }  from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import List from "./list";
import Detail from "./detail";
import ReturPenjualanModel from "../../../models/retur-penjualan-model/ReturPenjualanModel";

export default function ListReturPenjualan() {
  const [dataFromModel, setDataFromModel] = useState()
  const [data, setDataDetail] = useState([])
  const [master, setMaster] = useState([])
  const history = useHistory();
  return (
    <Switch>
      <Route path="/retur-penjualan/list">
        <List setDataFromModel={setDataFromModel} setDataDetail={setDataDetail} setMaster={setMaster}/>
      </Route>

     <Route path="/retur-penjualan/detail">
        <Detail history={history} dataFromModel={dataFromModel} data={data} masters={master}/>
      </Route>

      <Redirect from="/retur-penjualan/" to="/retur-penjualan/list" />
    </Switch>
  );
}

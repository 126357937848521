import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import ListPenerimaanBankCollection from "./list";
import DetailPenerimaanBankCollection from "./detail";
import { dispatcher } from "../../../../../redux/dispatcher";
export default function IndexListPenerimaanBankCollection ()
{
  const history = useHistory();
  const active = dispatcher[ 'kasir_bank_collection' ].getPenerimaanBankCollection();
  return (
    <Switch>
      <Route path="/kasir/daftar-penerimaan-bank-collection/list">
        <ListPenerimaanBankCollection />
      </Route>
      { active[ 'transaksi' ][ 'id' ] === 0 && (
        <Redirect to="/kasir/daftar-penerimaan-bank-collection/list" />
      ) }
      <Route path="/kasir/daftar-penerimaan-bank-collection/detail">
        <DetailPenerimaanBankCollection history={ history } />
      </Route>
      <Redirect to="/kasir/daftar-penerimaan-bank-collection/list" />
    </Switch>
  );
}

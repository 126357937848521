import React, { useEffect, useState } from 'react'
import { CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import { AnimatedCard } from '../../../components/animated-card/animatedCard'
import { Button, Modal, Table } from 'react-bootstrap'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import Select2 from "react-select";
import { kontakAPI } from '../../../api/kontak'
import { simpleFetch } from '../../../api/api'
import store from '../../../redux/store'


function MutasiSales() {
    const [show, setShow] = useState(false);
    const [sales, setSales] = useState();
    const [newSales, setNewSales] = useState();
    const [opsiSales, setOpsiSales] = useState([])
    const [customer, setCustomer] = useState([])
    const [cusSelect, setCusSelect] = useState([]);
    const [allCheck, setAllCheck] = useState(false)
    const [search, setSearch] = useState("");
    const id_user = store.getState().auth.id;


    const loadAllSales = async () => {
        try {
            let salesArr = []
            await simpleFetch({
                url: `retail/kontak/get_sales`,
                method: "GET",
            }).then((res) => {
                console.log("cek res", res.data);
                salesArr = res.data.map((val) => ({
                    value: val.id,
                    label: val.nama
                }))
            })
            setOpsiSales(salesArr)
        } catch (error) {
            console.error(error);
        }
    }

    const loadCustBySales = async (data) => {
        try {
            let custSales = []
            await simpleFetch({
                url: `retail/kontak/get_sales_customer?id_sales=${data}`,
                method: "GET",
            }).then((res) => {
                // console.log("cek customer", res.data);
                custSales = res.data.map((val) => ({
                    id: val.id,
                    nama: val.nama,
                    org: val.org,
                    checked: false,
                }))
            })
            setCustomer(custSales)
        } catch (error) {
            console.error(error);
        }
    }

    function loadData() {
        let searchParams = ["nama", "org"];
        let filtered = customer.filter((fil) => {
            return searchParams.some((item) => {
                return (
                    fil[item]
                        // .toString()
                        .toLowerCase()
                        .includes(search.toString().toLowerCase())
                )
            })
        })

        let data = customer.length > 0 && search === "" ? customer : filtered

        return data.map((val, i) => (
            <tbody key={i}>
                <tr key={Math.floor(1000 + Math.random() * 9000) + val.nama}>
                    <th scope="row" >{
                        <input
                            // className="form-check-input"
                            type="checkbox"
                            name="cusSelect"
                            value={val.id}
                            checked={val.checked}
                            id={val.id}
                            onClick={(e) => {
                                const { value, checked } = e.target;
                                console.log('On Click', e.target);
                                setCustomer(prevState => {
                                    const newState = prevState.map(obj => {
                                        // console.log('OBJ', obj.id);
                                        // 👇️ if id equals 2, update country property
                                        if (obj.id === parseInt(value)) {
                                            return { ...obj, checked: !obj.checked };
                                        }
                                        // 👇️ otherwise return the object as is
                                        return obj;
                                    });
                                    return newState;
                                });
                                if (allCheck) {
                                    // console.log("This is", checked);
                                    // console.log("CusSelect", cusSelect);
                                    setCusSelect(cusSelect.filter((e) => e !== parseInt(value)))
                                }
                            }}
                            onChange={(e) => {
                                const { value, checked } = e.target;
                                console.log('Value', checked);
                                if (checked === true) {
                                    setCusSelect([...cusSelect, parseInt(value)])
                                }
                                else {
                                    // console.log("Elseee");
                                    setCusSelect(cusSelect.filter((e) => e !== parseInt(value)))
                                }
                            }}
                        />
                    }</th>
                    <td>{val.nama}</td>
                    <td>{val.org}</td>
                </tr>
            </tbody>
        ))
    }

    function checkAll() {
        return (
            <div className="form-check m-3">
                <input
                    className="form-check-input"
                    type="checkbox"
                    name="selectAll"
                    value={allCheck ? 0 : 1}
                    checked={allCheck}
                    id="flexCheck"
                    onClick={(e) => {
                        const { value, checked } = e.target;
                        // console.log('On Click', value);
                        if (checked) {
                            setCustomer(prevState => {
                                const newState = prevState.map(obj => {
                                    return { ...obj, checked: true };
                                });
                                return newState;
                            });
                            // console.log('Cus', customer);
                            customer.map((val) => {
                                cusSelect.push(val.id)
                                // console.log('CEKK', val.id);
                                // setCusSelect([...cusSelect, val.id])
                            })
                        } else {
                            setCustomer(prevState => {
                                const newState = prevState.map(obj => {
                                    return { ...obj, checked: false };
                                });
                                return newState;
                            });
                            setCusSelect([])
                        }
                    }}
                    onChange={(e) => {
                        setAllCheck(!allCheck)
                        const { value, checked } = e.target;
                        // console.log('Value', checked);
                    }}
                />
                <label
                    className="form-check-label"
                    htmlFor="flexCheckDefault"
                >
                    Pilih Semua
                </label>
            </div>
        )
    }

    const submitData = async (data) => {
        console.log(data)
        try {
            await simpleFetch({
                url: `retail/kontak/mutasi_customer`,
                method: "POST",
                body: data,
            }).then((res) => {
                console.log("cek customer", res);
            })
        } catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
        loadAllSales()
    }, [])

    const handleClose = () => {
        setSales()
        setNewSales()
        setCustomer([])
        setAllCheck(false)
        setCusSelect([])
        setShow(false)
    };
    const handleShow = () => setShow(true);
    return (
        <>
            <AnimatedCard>
                <CardHeader title={'Mutasi Sales'}>
                    <CardHeaderToolbar>
                        <Button
                            onClick={() => {
                                handleShow()
                            }}
                        > <i className={"fa fa-plus"}></i> Tambah
                        </Button>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                </CardBody>
            </AnimatedCard>
            <Modal
                show={show}
                onHide={handleClose}
                size='xl'
            >
                <Formik
                    initialValues={{
                        Sales: sales,
                        cusSelect: cusSelect,
                        NewSales: newSales
                    }}
                    // validate={(values) => {
                    //     const errors = {};
                    //     if (!values.fullname) {
                    //         errors.fullname = "Required";
                    //     }

                    //     if (!values.email) {
                    //         errors.email = "Required";
                    //     } else if (
                    //         !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    //     ) {
                    //         errors.email = "Invalid email address";
                    //     }
                    //     if (!values.password) {
                    //         errors.password = "Required";
                    //     }
                    //     return errors;
                    // }}
                    onSubmit={(val) => {
                        console.log("Val", val);
                    }}
                >
                    {({ values, handleSubmit, handleChange }) => (
                        <Form>
                            <Modal.Header closeButton>
                                <Modal.Title></Modal.Title>
                                <div className="col-lg-3" style={{ marginTop: "20px" }}>
                                    <label> Pilih Sales </label>
                                    <Select2
                                        name={"Sales"}
                                        size="md"
                                        options={opsiSales}
                                        isClearable={false}
                                        value={
                                            opsiSales ? opsiSales.find(
                                                (option) => option.value === sales
                                            ) : ""
                                        }
                                        onChange={(e) => {
                                            setSales(e ? e.value : 0)
                                            setCusSelect([])
                                            loadCustBySales(e.value)
                                        }}
                                    />
                                </div>
                                <div className="col-lg-3" style={{ marginTop: "20px" }}>
                                    <label>Cari Data</label>
                                    <input
                                        type={"text"}
                                        className={"form-control"}
                                        placeholder="Cari Data ..."
                                        value={search}
                                        onChange={(e) => {
                                            // console.log(e.target.value);
                                            setSearch(e.target.value);
                                        }}
                                    />
                                </div>
                            </Modal.Header>
                            <Modal.Body>

                                {/* <div className="container mt-5  pb-5 pt-5">
                                    <div className="row"> */}
                                < Table className="table table-hover" >
                                    <thead className='thead-dark' style={{ position: "sticky", top: "0" }}>
                                        <tr>
                                            <th scope="col">{checkAll()}</th>
                                            <th scope="col">Nama Customer</th>
                                            <th scope="col">Nama Organisasi</th>
                                        </tr>
                                    </thead>
                                    {customer.length > 0 ?
                                        loadData()
                                        :
                                        (
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "center" }} colSpan="4">Belum ada sales dipilih /data customer</td>
                                                </tr>
                                            </tbody>
                                        )
                                    }
                                    {/* </div> */}
                                    {/* </div> */}
                                </Table>
                                {/* <div>
                                    <h1>
                                        {cusSelect}
                                    </h1>
                                </div> */}
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="col-lg-3" style={{ marginTop: "-20px" }}>
                                    <label> Pilih Sales Baru</label>
                                    <Select2
                                        name={"NewSales"}
                                        size="md"
                                        options={opsiSales}
                                        isClearable={false}
                                        value={
                                            opsiSales ? opsiSales.find(
                                                (option) => option.value === newSales
                                            ) : ""
                                        }
                                        onChange={(e) => {
                                            setNewSales(e ? e.value : 0)
                                        }}
                                    />
                                </div>
                                <Button variant="secondary" onClick={() => {
                                    handleClose()
                                }
                                }>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={() => {
                                    let send = {
                                        id_sales: sales,
                                        newSales: newSales,
                                        customer: cusSelect,
                                        id_user: id_user,
                                    }
                                    console.log(send);
                                    submitData(send)
                                    console.log("ABC", sales, cusSelect, newSales);
                                    // handleSubmit()
                                    handleClose()
                                }}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </>
    )
}

export default MutasiSales
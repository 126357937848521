import { getIdPerusahaan, simpleFetch } from "./api";
import store from "../redux/store";
import { dispatcher } from "../redux/dispatcher";

export class StokTransferAPI {
  async getAll() {
    let id_perusahaan = store.getState().auth.id_perusahaan
    let res = await simpleFetch({
      url: `retail/stok/transfer?id_perusahaan=${id_perusahaan}`,
      method: "GET",
    });
    return res;
  }

  async getAllByRef() {
    let id_perusahaan = store.getState().auth.id_perusahaan;
    let res = await simpleFetch({
      url: `retail/stok/daftar_transfer?id_perusahaan=${id_perusahaan}`,
      method: "GET",
    });
    return res.data;
  }

  async getSingle(id) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/stok/transfer?id=" + id,
        method: "GET",
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async set(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/stok/transfer",
        method: "POST",
        body: {
          id_perusahaan: getIdPerusahaan(),
          ...values,
        },
      }).then((res) =>
        dispatcher.snackbar.show(
          "Berhasil",
          `Data Berhasil Disimpan`,
          "primary"
        )
      );
    } catch (e) {
      dispatcher.snackbar.show(
        "Error",
        `${e}`,
        "danger"
      )
    } finally {
      return res;
    }
  }

  // Validasi Transfer Stok
  async validasiTransferStok(values) {
    let res = await simpleFetch({
      url: "retail/stok/transfer/validasi_transfer_stok",
      method: "POST",
      body: {
        ...values,
      },
    });
    return res;
  }
  // Validasi Transfer Stok
  async validasiTerimaStok(values) {
    let res = await simpleFetch({
      url: "retail/stok/transfer/validasi_terima_stok",
      method: "POST",
      body: {
        ...values,
      },
    });
    return res;
  }

  async approve(values) {
    try {
      await simpleFetch({
        url: "retail/stok/approval_transfer",
        method: "POST",
        body: {
          id_user: store.getState().auth.id,
          id: values.id
        },
      }).then((res) =>
        dispatcher.snackbar.show(
          "Berhasil",
          `${res.data}`,
          "primary"
        )
      )
    } catch (error) {
      dispatcher.snackbar.show(
        "Error",
        `${error}`,
        "danger"
      )
    }
  }
  async edit(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/stok/transfer",
        method: "PUT",
        body: {
          id_perusahaan: getIdPerusahaan(),
          ...values,
        },
      }).then((res) =>
        dispatcher.snackbar.show(
          "Berhasil",
          `Data Berhasil Diupdate`,
          "primary"
        )
      );
    } catch (e) {
      dispatcher.snackbar.show(
        "Error",
        `${e}`,
        "danger"
      )
    } finally {
      return res;
    }
  }

  async batal(id) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/stok/batal_transfer",
        method: "POST",
        body: {
          id: id,
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      return res;
    }
  }

}

import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import FormPesananPenjualan from "./form";
import ListPesananPenjualan from "./list";
import DetailPesananPenjualan from "./detail";
import EditPenjualan from "./edit";
import { dispatcher } from "../../../redux/dispatcher";

export default function PesananPenjualan() {
  const history = useHistory();
  const active = dispatcher.pesanan_penjualan.get();
  return (
    <Switch>
      <Route path="/penjualan-pesanan-penjualan/list">
        <ListPesananPenjualan />
      </Route>

      <Route path="/tambah-pesanan-penjualan">
        <FormPesananPenjualan history={history} isEdit={false} />
      </Route>

      {/* {active.master.id === 0 && (
        <Redirect to="/penjualan-pesanan-penjualan/list" />
      )} */}

      <Route path="/penjualan-pesanan-penjualan/edit">
        <EditPenjualan history={history} isEdit={true} />
      </Route>

      <Route path="/penjualan-pesanan-penjualan/detail">
        <DetailPesananPenjualan history={history} />
      </Route>

      <Redirect to="/penjualan-pesanan-penjualan/list" />
    </Switch>
  );
}

import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { CardBody, CardHeader, CardHeaderToolbar } from "../../../../../_metronic/_partials/controls";
import { simpleFetch } from "../../../../api/api";
import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import { separatorHarga } from "../../../../services/separator-harga";

function Detail({ data }) {
    const [pembayaran, setPembayaran] = useState([]);
    const [invoice, setInvoice] = useState([data]);
    const history = useHistory();

    const fetchDataHistory = async () => {
        try {
            await simpleFetch({
                url: `retail/kartu-hutang/detail?id_perusahaan=${data.master.id_perusahaan}&no_transaksi_ref=${data.master.no_transaksi}`,
                method: "GET",
            }).then((res) => setPembayaran(res.data))
        } catch (error) {
            console.error(error);
        }
    }

    // const fetchInvoice = async () => {
    //     try {
    //         await simpleFetch({
    //             url: `retail/penjualan-customer?id_perusahaan=${data.id_perusahaan}&id_customer=${data.id_customer}`,
    //             method: "GET",
    //         }).then((res) => setInvoice(res.data))
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

    useEffect(() => {
        console.log('DATADetail', data);
        fetchDataHistory()
        // fetchInvoice()
    }, [])

    console.log('Data', invoice);
    console.log('Pembayaran', pembayaran);
    return (
        <>
            <AnimatedCard>
                <CardHeader title={'Detail Kartu Hutang'}>
                    <CardHeaderToolbar>
                        <Button
                            type="button"
                            className="btn btn-secondary mx-1"
                            onClick={() => history.goBack()}
                        >
                            <i className="fa fa-arrow-left"></i>
                            Kembali
                        </Button>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <div className="table-responsive-xl">
                        <Table responsive="xl">
                            <thead className="table-primary">
                                <tr className="text-center">
                                    <th scope="col">No</th>
                                    <th scope="col">Tanggal</th>
                                    <th scope="col">No Transaksi</th>
                                    <th scope="col">Debet</th>
                                    <th scope="col">Kredit</th>
                                    <th scope="col">Sisa Hutang</th>
                                </tr>
                            </thead>
                            {invoice && invoice.length > 0 ? invoice.map((val, i) => (
                                console.log(val),
                                <tbody key={i}>
                                    <tr className="text-center">
                                        <td>{i + 1}</td>
                                        <td>{val.master.tanggal_invoice}</td>
                                        <td>{val.master.no_transaksi}</td>
                                        <td>Rp. {0}</td>
                                        <td>Rp. {separatorHarga(val.master.total.toString())}</td>
                                        <td>Rp. {separatorHarga(val.master.total.toString())}</td>
                                    </tr>
                                    {pembayaran.length > 0 ? pembayaran.map((data, i, arr) => (
                                        <tr key={data.no_transaksi} className="text-center" >
                                            <td>{(i + 1) + 1}</td>
                                            <td>{data.tanggal}</td>
                                            <td>{data.no_transaksi}</td>
                                            <td>Rp. {separatorHarga(data.total.toString())}</td>
                                            <td>Rp. {0}</td>
                                            { }
                                            <td>Rp. {
                                                separatorHarga((val.master.total - (data.total + (arr[i - 1] ? arr[i - 1].total : 0))).toString())
                                            }</td>
                                        </tr>
                                    ))
                                        :
                                        (
                                            <tr>
                                                <td colSpan={6}></td>
                                            </tr>
                                        )
                                    }
                                    <tr className="text-center">
                                        <td colSpan="3"> {"Total"} </td>
                                        <td>Rp. {separatorHarga(val.master.terbayar.toString())}</td>
                                        <td>Rp. {separatorHarga(val.master.total.toString())}</td>
                                        <td>Rp. {separatorHarga(val.master.tersisa.toString())}</td>
                                    </tr>
                                </tbody>
                            )) : (
                                <tr>
                                    <th scope="row"></th>
                                    <td colSpan="4" className="text-center">Belum Ada Data Pembelian</td>
                                </tr>
                            )}
                        </Table>
                    </div>
                </CardBody>
            </AnimatedCard>
        </>
    );
}

export default Detail;
import { separatorHarga } from "../../../services/separator-harga";
import store from "../../../redux/store";

export default class CustomerSalesModel {
  constructor(
    data = {
      id: 0,
      kode: "",
      nama: "",
      alamat: "",
      nama_sales: "",
      nama_rekening: "",
      no_rekening: "",
      nama_bank: "",
      nama_organisasi: "",
      nilai_top: 0,
      nilai_limit: 0,
      id_jenis_kelamin: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      id_sales: store.getState().auth['id'],
      ktp: "",
      hp: "",
      id_organisasi: "",
      id_kolektor: "",
      nama_kolektor: "",
      file_ktp: "",
      file_kk: "",
      status: 0,
    }
  ) {
    const { id, kode, nama,nama_kolektor, id_kolektor, alamat, nama_sales, nama_rekening, no_rekening, nama_bank, nama_organisasi, nilai_top, nilai_limit, id_jenis_kelamin, tempat_lahir, tanggal_lahir, id_sales, ktp, hp, id_organisasi, file_ktp, file_kk } = data;
    this['id'] = id;
    this['kode'] = kode;
    this['nama'] = nama;
    this['alamat'] = alamat;
    this['nama_sales'] = nama_sales;
    this['nama_rekening'] = nama_rekening;
    this['no_rekening'] = no_rekening;
    this['nama_bank'] = nama_bank;
    this['nama_organisasi'] = nama_organisasi;
    this['nilai_top'] = nilai_top;
    this['nilai_limit'] = nilai_limit;
    this['id_jenis_kelamin'] = id_jenis_kelamin;
    this['tempat_lahir'] = tempat_lahir;
    this['tanggal_lahir'] = tanggal_lahir ? new Date(tanggal_lahir) : "";
    this['id_sales'] = id_sales;
    this['ktp'] = ktp;
    this['hp'] = hp;
    this['id_organisasi'] = id_organisasi;
    this['id_kolektor'] = id_kolektor;
    this['nama_kolektor'] = nama_kolektor;
    this['file_ktp'] = file_ktp;
    this['file_kk'] = file_kk;
  }

  async getdata() {
    const { kode, nama,nama_kolektor, id_kolektor, alamat, nama_sales, nama_rekening, no_rekening, nama_bank, nama_organisasi, nilai_top, nilai_limit } = this;
    return [
      {
        id: "kode",
        title: "Kode",
        label: kode,
        center: true,
        sortable: true,
        minWidth: "10%",
      },
      {
        id: "nama",
        title: "Nama",
        label: nama,
        sortable: true,
        minWidth: "25%",
      },
      {
        id: "alamat",
        title: "Alamat",
        label: alamat,
        sortable: true,
        minWidth: "35%",
      },
      {
        id: "nama_sales",
        title: "Nama Sales",
        label: nama_sales,
        sortable: true,
        minWidth: "15%",
      },
      {
        id: "nama_rekening",
        title: "Nama Rekening Pemilik",
        label: nama_rekening,
        sortable: true,
        minWidth: "15%",
      },
      {
        id: "no_rekening",
        title: "No. Rekening",
        label: no_rekening,
        center: true,
        sortable: true,
        minWidth: "10%",
      },
      {
        id: "nama_bank",
        title: "Nama Bank",
        label: nama_bank,
        center: true,
        sortable: true,
        minWidth: "10%",
      },
      {
        id: "nama_organisasi",
        title: "Organisasi",
        label: nama_organisasi,
        sortable: true,
        minWidth: "25%",
      },
      {
        id: "nilai_top",
        title: "Limit T-O-P",
        label: nilai_top !== "No Limit" ? `${nilai_top} Hari` : nilai_top,
        center: true,
        sortable: true,
        minWidth: "10%",
      },
      {
        id: "nilai_limit",
        title: "Limit Order",
        label: nilai_limit !== "No Limit" ? separatorHarga(nilai_limit.toString(), "Rp. ") : nilai_limit,
        center: true,
        sortable: true,
        minWidth: "10%",
      },
      {
        id: "id_kolektor",
        title: "Kolektor",
        label: nama_kolektor,
        center: true,
        sortable: true,
        minWidth: "10%",
      },
    ];
  }
}

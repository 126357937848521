import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import List from "./list";
import Detail from "./detail";
import Outbound from "./outbound";
import { dispatcher } from "../../../redux/dispatcher";

export default function StokKeluarPenjualan() {
  const history = useHistory();
  const active = dispatcher.penjualan.get();
  return (
    <Switch>
      <Route path="/stok-keluar-penjualan/list">
        <List />
      </Route>
      {active.master.id === 0 && <Redirect to="/stok-keluar-penjualan/list" />}

      <Route path="/stok-keluar-penjualan/outbound">
        <Outbound history={history} />
      </Route>

      <Route path="/stok-keluar-penjualan/detail">
        <Detail history={history} />
      </Route>

      {/* <Route path="/stok-keluar-penjualan/invoice">
      </Route> */}

      <Redirect to="/stok-keluar-penjualan/list" />
    </Switch>
  );
}

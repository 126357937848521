import { CardBody, CardHeader, CardHeaderToolbar } from "../../../../../_metronic/_partials/controls";
import React, { Component } from "react";

import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import { Button } from "react-bootstrap";
import DataCustomerModel from "../../../../models/kontak/data-customer/DataCustomerModel";
import DataTableModel from "../../../../models/datatable-model/DataTableModel";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { ReactDatatable } from "../../../../components/react-datatable/reactDatatable";
import SimpleAlert from "../../../../components/alert/alert";
import { dispatcher } from "../../../../redux/dispatcher";
import { kontakAPI } from "../../../../api/kontak";

export default class ListDataCustomer extends Component {
  constructor(props) {
    super(props);
    this['pageName'] = "Data Customer";
    this['searchParameter'] = ["kode", "nama", "alamat", "nama_sales", "nama_rekening", "no_rekening", "nama_bank", "nama_organisasi", "nilai_top", "nilai_limit"];
    this['apiDataCustomer'] = new kontakAPI();
    this['state'] = {
      pending: true,
      alert: {
        activeId: null,
        show: false,
      },
    };
    this['datatable'] = [{ id: "", no: "", name: "" }];
    this['columntable'] = [{ id: "", title: "" }];
  }

  async componentDidMount() {
    await this.LoadData();
  }

  async LoadData() {
    this.setState({ pending: true });
    await dispatcher['kontak'].listDataCustomer();
    let data = dispatcher['kontak'].getListDataCustomer();
    this['DataTableModel'] = new DataTableModel();
    data.map((item, i) => {
      let dataModel = new DataCustomerModel(item);
      if (item['status'] === 1) {
        this['DataTableModel'].add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: (
            <>
              {/* Info */}
              {
                item['nama_organisasi'] === "-" ? (
                  <Button size={"sm"} style={{ cursor: 'default' }} variant={"light"} disabled>
                    <i
                      className={"fas fa-flag"}
                      aria-hidden={"true"}
                      style={{ color: "blue" }}
                    ></i>
                  </Button>
                ) : item['is_approved'] <= 2 ? (
                  <Button size={"sm"} style={{ cursor: 'default' }} variant={"light"} disabled>
                    <i
                      className={"fas fa-flag"}
                      aria-hidden={"true"}
                      style={{ color: "red" }}
                    ></i>
                  </Button>
                ) : (
                  <Button size={"sm"} style={{ cursor: 'default' }} variant={"light"} disabled>
                    <i
                      className={"fas fa-flag"}
                      aria-hidden={"true"}
                      style={{ color: "green" }}
                    ></i>
                  </Button>
                )
              }
              {/* Edit Form */}
              <Link
                className={"mx-1 btn btn-outline-success btn-sm"}
                to={"/master-data-customer/edit-form"}
                onClick={async () => dispatcher['kontak'].setDataCustomer(dataModel)}
              > Edit
              </Link>
              {/* Delete */}
              <Button
                size={"sm"}
                className={"mx-1"}
                variant={"outline-danger"}
                onClick={async () => {
                  this['handleAlert'].show(item['id']);
                  // this.LoadData();
                }}
              > Hapus
              </Button>
            </>
          ),
        });
      }
      return item;
    });
    this['columntable'] = await this['DataTableModel'].getColumn();
    this['datatable'] = await this['DataTableModel'].getDatas();
    this.setState({ ...this['state'], pending: false });
  }

  // Handle Alert
  handleAlert = {
    show: (id) => {
      let alert = { ...this['state'] };
      alert = {
        alert: {
          show: true,
          activeId: id
        }
      }
      this.setState({ ...alert });
    },
    hide: () => {
      let alert = { ...this['state'] };
      alert = {
        alert: {
          show: false,
          activeId: null
        }
      }
      this.setState({ ...alert });
    },
    confirmed: async () => {
      await this['apiDataCustomer'].deleteDataCustomer(this['state']['alert']['activeId']).then((res) => {
        if (res['status']) {
          dispatcher['snackbar'].show("Berhasil", res.message, "primary");
          this['handleAlert'].hide();
          this.LoadData();
        } else {
          return dispatcher['snackbar'].show("Gagal", res.message, "danger");
        }
      });
    },
  };

  render() {
    const { pending, alert } = this['state'];
    return (
      <>
        <Formik
          // initialValues={}
          onSubmit={(values) => {
            this.LoadData(values);
          }}
        >
          {({ setFieldValue, setFieldTouched, touched, errors, values, handleSubmit }) => (
            <AnimatedCard>
              <CardHeader title={this['pageName']}>
                <CardHeaderToolbar>
                  <Link
                    className={"btn btn-primary"}
                    to={"/master-data-customer/add-form"}
                    onClick={() => {
                      dispatcher['kontak'].resetActiveDataCustomer()
                    }}
                  > <i className={"fa fa-plus"}></i> Tambah
                  </Link>
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody style={{ marginTop: "-20px" }}>
                <ReactDatatable
                  title={""}
                  columns={this['columntable']}
                  data={this['datatable']}
                  searchParameter={this['searchParameter']}
                  progressPending={pending}
                />
              </CardBody>
            </AnimatedCard>
          )}
        </Formik>
        {/* Alert Hapus */}
        <SimpleAlert
          title={"Hapus Data"}
          content={"Anda yakin hapus data ini...?"}
          show={alert['show']}
          onHide={() => this['handleAlert'].hide()}
          onConfirmed={this['handleAlert']['confirmed']}
        />
      </>
    );
  }
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Table, Tab, Tabs } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { simpleFetch } from "../../../api/api";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import { saveAsExcel } from "./excelDetail";
import { useLocation } from 'react-router'
import { saveAsExcelLaporan } from "./excelLaporanDetail";


const rupiah = (number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR"
  }).format(number);
}
function Detail({ data }) {
  let dtLaporan = useLocation()
  const [detail, setDetail] = useState([]);
  const [key, setKey] = useState("home");
  const history = useHistory();

  const fetchDataHistory = async () => {
    try {
      let url = data.id_produk !== 0 ?
        `retail/inventori/detail?id=${data.id_produk}` :
        `retail/inventori/detail/laporan?id=${dtLaporan.state?.id_produk}&tanggalAwal=${dtLaporan.state?.tanggal}&tanggalAkhir=${dtLaporan.state?.tanggalAkhir}`
      await simpleFetch({
        url: url,
        method: "GET",
      }).then((res) => setDetail(res.data));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let abortController = new AbortController();
    fetchDataHistory();
    return () => {
      abortController.abort();
    }
  }, []);

  return (
    <>
      <AnimatedCard>
        <CardHeader title={"Detail Produk Inventori"}>
          <CardHeaderToolbar>
            <Button
              type="button"
              className="btn btn-secondary mx-1"
              onClick={() => history.goBack()}
            >
              <i className="fa fa-arrow-left"></i>
              Kembali
            </Button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="form-group col-lg-6">
              <label>Kode</label>
              <input
                type="text"
                className="form-control"
                name="tanggal"
                placeholder="Tanggal Invoice"
                autoComplete="off"
                disabled
                defaultValue={data.kode_produk || dtLaporan.state?.kode || "Empty"}
              />
            </div>
            <div className="form-group col-lg-6">
              <label>Nama Produk</label>
              <input
                type="text"
                className="form-control"
                name="jatuh_tempo"
                placeholder="Jatuh Tempo"
                autoComplete="off"
                disabled
                defaultValue={data.nama_produk || dtLaporan.state?.nama || "Loading"}
              />
            </div>
            {data.id_produk !== 0 ?
              <div className="form-group col-lg-2">
                <label>QTY</label>
                <input
                  type="text"
                  className="form-control"
                  name="kode"
                  placeholder="No Invoice"
                  autoComplete="off"
                  disabled
                  defaultValue={`${data.stok_ready} ${data.nama_produkSatuan}`}
                />
              </div>
              :
              <>
                <div className="form-group col-lg-2">
                  <label>Stok Awal</label>
                  <input
                    type="text"
                    className="form-control"
                    name="kode"
                    placeholder="No Invoice"
                    autoComplete="off"
                    disabled
                    defaultValue={`${dtLaporan.state?.ready || "0"} ${dtLaporan.state?.satuan || "-"}`}
                  />
                </div>
                <div className="form-group col-lg-2">
                  <label>Stok Akhir</label>
                  <input
                    type="text"
                    className="form-control"
                    name="kode"
                    placeholder="No Invoice"
                    autoComplete="off"
                    disabled
                    defaultValue={`${dtLaporan.state?.akhir || "0"} ${dtLaporan.state?.satuan || "-"}`}
                  />
                </div>
              </>
            }

            <div style={{ marginTop: "25px", marginBottom: "10px" }}>
              {data.id_produk !== 0 ?
                <Button
                  onClick={() => {
                    let dataExcels = []
                    detail.dataKeluar.map((val) => {
                      return dataExcels.push({
                        tanggal: val.tanggal,
                        no_transaksi: val.no_transaksi,
                        qty: `${val.qty} ${data.nama_produkSatuan}`,
                        lokasi: val.lokasi,
                        aksi: val.aksi,
                      });
                    })
                    saveAsExcel(dataExcels, `${data.nama_produk}`);
                  }}
                >
                  <i className={"far fa-file-excel"}></i> {" Export .xls"}
                </Button>
                :
                <Button
                  onClick={() => {
                    saveAsExcelLaporan(detail, `${dtLaporan.state?.nama || "Loading"}`);
                  }}
                >
                  <i className={"far fa-file-excel"}></i> {" Export .xls"}
                </Button>
              }
            </div>
          </div>
          {data.id_produk !== 0 ?
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="home" title="Stok Masuk">
                <div className="table-responsive-xl" style={{ height: '50vh', overflow: 'scroll' }}>
                  <Table responsive="xl">
                    <thead className="table-primary" style={{ position: "sticky", top: 0 }}>
                      <tr className="text-center">
                        <th scope="col">No</th>
                        <th scope="col">Tanggal</th>
                        <th scope="col">No Transaksi</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Lokasi</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    {detail.dataMasuk && detail.dataMasuk.length > 0 ? (
                      detail.dataMasuk.map((val, i) => (
                        <tbody key={i}>
                          <tr className="text-center">
                            <td>{i + 1}</td>
                            <td>{val.tanggal}</td>
                            <td>{val.no_transaksi}</td>
                            <td>{`${val.qty} ${data.nama_produkSatuan}`}</td>
                            <td>{val.lokasi}</td>
                            <td>{val.aksi}</td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <tbody>
                        <tr>
                          <th scope="row"></th>
                          <td colSpan="5" className="text-center">
                            Belum Ada Data
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </div>
              </Tab>
              <Tab eventKey="profile" title="Stok Keluar">
                <div className="table-responsive-xl" style={{ height: '50vh', overflow: 'scroll' }}>
                  <Table responsive="xl">
                    <thead className="table-primary" style={{ position: "sticky", top: 0 }}>
                      <tr className="text-center">
                        <th scope="col">No</th>
                        <th scope="col">Tanggal</th>
                        <th scope="col">No Transaksi</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Lokasi</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    {detail.dataKeluar && detail.dataKeluar.length > 0 ? (
                      detail.dataKeluar.map((val, i) => (
                        <tbody key={i}>
                          <tr className="text-center">
                            <td>{i + 1}</td>
                            <td>{val.tanggal}</td>
                            <td>{val.no_transaksi}</td>
                            <td>{`${val.qty} ${data.nama_produkSatuan}`}</td>
                            <td>{val.lokasi}</td>
                            <td>{val.aksi}</td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <tbody>
                        <tr>
                          <th scope="row"></th>
                          <td colSpan="5" className="text-center">
                            Belum Ada Data
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </div>
              </Tab>
              <Tab eventKey="alokasi" title="Stok Alokasi">
                <div className="table-responsive-xl" style={{ height: '50vh', overflow: 'scroll' }}>
                  <Table responsive="xl">
                    <thead className="table-primary" style={{ position: "sticky", top: 0 }}>
                      <tr className="text-center">
                        <th scope="col">No</th>
                        <th scope="col">Tanggal</th>
                        <th scope="col">No Transaksi</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    {detail.dataAlokasi && detail.dataAlokasi.length > 0 ? (
                      detail.dataAlokasi.map((val, i) => (
                        <tbody key={i}>
                          <tr className="text-center">
                            <td>{i + 1}</td>
                            <td>{val.tanggal}</td>
                            <td>{val.no_transaksi}</td>
                            <td>{`${val.qty} ${data.nama_produkSatuan}`}</td>
                            <td>{val.aksi}</td>
                          </tr>
                        </tbody>
                      ))
                    ) : (
                      <tbody>
                        <tr>
                          <th scope="row"></th>
                          <td colSpan="5" className="text-center">
                            Belum Ada Data
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                </div>
              </Tab>
            </Tabs>
            :
            <div className="table-responsive-xl" style={{ height: '50vh', overflow: 'scroll' }}>
              <Table responsive="xl">
                <thead className="table-primary" style={{ position: "sticky", top: 0 }}>
                  <tr className="text-center">
                    <th scope="col">No</th>
                    <th scope="col">Tanggal</th>
                    <th scope="col">No Transaksi</th>
                    <th scope="col">Status</th>
                    <th scope="col">Qty Keluar/Masuk</th>
                    <th scope="col">Saldo Stok</th>
                    <th scope="col">Saldo Persediaan</th>
                  </tr>
                </thead>
                {detail.dataLast ? (
                  <tbody key={"awal"}>
                    <tr className="text-center">
                      <td>Awal</td>
                      <td>{detail.dataLast.tanggal}</td>
                      <td>{detail.dataLast.no_transaksi}</td>
                      <td>{detail.dataLast.status_aksi}</td>
                      <td>{`${detail.dataLast.qty} ${detail.dataLast.toProduk.toProdukSatuan.nama}`}</td>
                      <td>{`${detail.dataLast.tersisa} ${detail.dataLast.toProduk.toProdukSatuan.nama}`}</td>
                      <td>{rupiah(detail.dataLast.saldo_persediaan)}</td>
                    </tr>
                  </tbody>
                )
                  : (
                    <tbody>
                      <tr>
                        <th scope="row"></th>
                        <td colSpan="5" className="text-center">
                          Belum Ada Data
                        </td>
                      </tr>
                    </tbody>
                  )}
                {detail.data && detail.data.length > 0 ? (
                  detail.data.map((val, i) => (
                    <tbody key={i}>
                      <tr className="text-center">
                        <td>{i + 1}</td>
                        <td>{val.tanggal}</td>
                        <td>{val.no_transaksi}</td>
                        <td>{`${val.tersisa} ${val.toProduk.toProdukSatuan.nama}`}</td>
                        <td>{`${val.qty} ${val.toProduk.toProdukSatuan.nama}`}</td>
                        <td>{val.status_aksi}</td>
                        <td>{rupiah(val.saldo_persediaan)}</td>
                      </tr>
                    </tbody>
                  ))
                ) : (
                  <tbody>
                    <tr>
                      <th scope="row"></th>
                      <td colSpan="5" className="text-center">
                        Belum Ada Data
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </div>
          }
        </CardBody>
      </AnimatedCard>
    </>
  );
}

export default Detail;

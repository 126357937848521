import React, { useEffect, useState } from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { separatorHarga } from "../../../services/separator-harga";
import { useHistory } from "react-router-dom";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";

function Detail({ data, dataFromModel }) {
  const history = useHistory();
  const [master, setMaster] = useState([])
  const [detail, setDetail] = useState([])

  const rupiah = (number)=>{
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR"
    }).format(number);
  }

  useEffect(() => {
    console.log('Test Log', data);
    console.log('Logs', dataFromModel);
    data.map((val) => {
      setMaster(val.master)
      setDetail(val.detail)
      return val
    })
  }, [])

  return (
    <>
      <AnimatedCard>
        <CardHeader title={'Detail'}>
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-secondary mx-1"
              onClick={() => history.goBack()}
            >
              <i className="fa fa-arrow-left"></i>
              Kembali
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <form action="#" className="form form-label-right">
            <div className="row">
              <div className="form-group col-lg-6">
                <label>Tanggal Invoice</label>
                <input type="text" className="form-control" name="tanggal" placeholder="Tanggal Invoice" autoComplete="off" disabled defaultValue={master.tanggal_invoice} />
              </div>
              <div className="form-group col-lg-6">
                <label>Jatuh Tempo</label>
                <input type="text" className="form-control" name="jatuh_tempo" placeholder="Jatuh Tempo" autoComplete="off" disabled defaultValue={master.jatuh_tempo} />
              </div>
              <div className="form-group col-lg-6">
                <label>No Invoice</label>
                <input type="text" className="form-control" name="kode" placeholder="No Invoice" autoComplete="off" disabled defaultValue={master.kode} />
              </div>
              <div className="form-group col-lg-6">
                <label>Supplier</label>
                <input type="text" className="form-control" name="nama_supplier" placeholder="Supplier" autoComplete="off" disabled defaultValue={master.nama_supplier} />
              </div>
              <div className="form-group col-lg-6">
                <label>No Transaksi</label>
                <input type="text" className="form-control" name="no_transaksi" placeholder="No Transaksi" autoComplete="off" disabled defaultValue={master.no_transaksi} />
              </div>
              <div className="form-group col-lg-6">
                <label>Alasan Retur</label>
                <input type="text" className="form-control" name="deskripsi" placeholder="Alasan Retur" autoComplete="off" disabled defaultValue={dataFromModel.deskripsi} />
              </div>
            </div>
            <span className="d-flex align-items-center justify-content-between mt-4">
              <h6 className="MuiTypography-root MuiTypography-h6">Detail Item</h6>
            </span>
            <div className="react-bootstrap-table table-responsive">
              <table className="table table table-head-custom table-vertical-center overflow-hidden">
                <thead>
                  <tr className="text-center">
                    <th tabIndex="0" aria-label="No sortable" className="sortable">No <span className="order">
                      <span className="dropdown">
                        <span className="caret"></span>
                      </span>
                      <span className="dropup">
                        <span className="caret"></span>
                      </span>
                    </span>
                    </th>
                    <th tabIndex="0" aria-label="Produk sortable" className="sortable">Produk <span className="order">
                      <span className="dropdown">
                        <span className="caret"></span>
                      </span>
                      <span className="dropup">
                        <span className="caret"></span>
                      </span>
                    </span>
                    </th>
                    <th tabIndex="0" aria-label="Harga sortable" className="sortable">Harga <span className="order">
                      <span className="dropdown">
                        <span className="caret"></span>
                      </span>
                      <span className="dropup">
                        <span className="caret"></span>
                      </span>
                    </span>
                    </th>
                    <th tabIndex="0" aria-label="QTY sortable" className="sortable">QTY <span className="order">
                      <span className="dropdown">
                        <span className="caret"></span>
                      </span>
                      <span className="dropup">
                        <span className="caret"></span>
                      </span>
                    </span>
                    </th>
                    <th tabIndex="0" aria-label="Diskon Rupiah sortable" className="sortable">Diskon Rupiah <span className="order">
                      <span className="dropdown">
                        <span className="caret"></span>
                      </span>
                      <span className="dropup">
                        <span className="caret"></span>
                      </span>
                    </span>
                    </th>
                    <th tabIndex="0" aria-label="Pajak Rupiah sortable" className="sortable">Pajak Rupiah <span className="order">
                      <span className="dropdown">
                        <span className="caret"></span>
                      </span>
                      <span className="dropup">
                        <span className="caret"></span>
                      </span>
                    </span>
                    </th>
                    <th tabIndex="0" aria-label="Sub Total sortable" className="sortable">Sub Total <span className="order">
                      <span className="dropdown">
                        <span className="caret"></span>
                      </span>
                      <span className="dropup">
                        <span className="caret"></span>
                      </span>
                    </span>
                    </th>
                    <th tabIndex="0" aria-label="Total sort asc" className="sortable">Total <span className="react-bootstrap-table-sort-order dropup">
                      <span className="caret"></span>
                    </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {detail.map((data, i) => (
                    <tr key={i} className="text-center">
                      <td className="text-nowrap">{i + 1}</td>
                      <td className="text-nowrap">{data.toProduk.nama}</td>
                      <td className="text-nowrap">Rp. {separatorHarga(data.hpp.toString())}</td>
                      <td className="text-nowrap">{data.qty}</td>
                      <td className="text-nowrap">Rp. {separatorHarga(data.diskon.toString())}</td>
                      <td className="text-nowrap">Rp. {separatorHarga(data.pajak.toString())}</td>
                      <td className="text-nowrap">Rp. {separatorHarga(data.subtotal.toString())}</td>
                      <td className="text-nowrap">Rp. {separatorHarga(data.total.toString())}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {
              console.log(typeof master.hpp_total)
            }
            <div className="d-flex row" style={{ marginTop: '50px' }}>
              <div className="form-group col-md-4 justify-content-around">
                <label>QTY Total</label>
                <input type="text" className="form-control" name="qty_total" placeholder="QTY Total" autoComplete="off" disabled defaultValue={master.qty_total} />
              </div>
              <div className="form-group col-md-4 justify-content-around">
                <label>Total Harga</label>
                <input type="text" className="form-control" name="hpp_total" placeholder="Total Harga" autoComplete="off" disabled defaultValue={master.hpp_total} />
              </div>
              <div className="form-group col-md-4 justify-content-around">
                <label>Total Diskon</label>
                <input type="text" className="form-control" name="diskon_total" placeholder="Total Diskon" autoComplete="off" disabled defaultValue={master.diskon_total} />
              </div>
              <div className="form-group col-md-4 justify-content-around">
                <label>Sub Total</label>
                <input type="text" className="form-control" name="subtotal" placeholder="Sub Total" autoComplete="off" disabled defaultValue={master.subtotal} />
              </div>
              <div className="form-group col-md-4 justify-content-around">
                <label>Total Pajak</label>
                <input type="text" className="form-control" name="pajak_total" placeholder="Total Pajak" autoComplete="off" disabled defaultValue={master.pajak_total} />
              </div>
              <div className="form-group col-md-4 justify-content-around">
                <label>Total</label>
                <input type="text" className="form-control" name="total" placeholder="Total" autoComplete="off" disabled defaultValue={master.total} />
              </div>
            </div>
          </form>
        </CardBody>
      </AnimatedCard>
    </>
  );
}

export default Detail;
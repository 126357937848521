import store from "../app/redux/store";
import { messageAction } from "../app/redux/message/actions";
import { authAction } from "../app/redux/auth/actions";

export async function SimpleException(e) {
  let resJson = await e.json();
  if (resJson.isExpired) {
    store.dispatch({ type: authAction.delUser });
    store.dispatch({
      type: messageAction.setMessage,
      payload: {
        variant: "warning",
        title: "Silakan Login Ulang",
        message: resJson.message,
      },
    });
  } else {
    store.dispatch({
      type: messageAction.setMessage,
      payload: {
        variant: "danger",
        title: "Error status " + e.status,
        message: e.statusText,
      },
    });
  }
}

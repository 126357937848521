/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { AnimatedCard } from '../../../components/animated-card/animatedCard';
import { CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls';
import moment from 'moment';
import store from '../../../redux/store';
import { useEffect } from 'react';
import { Link } from "react-router-dom";
import { TableWithSearch } from '../../../components/table/Table.js';
import { BagKeluarAPI } from '../../../api/bag-keluar';

function BagKeluar() {
    const [show, setShow] = useState(false);
    const currDate = moment(new Date()).format("YYYY-MM-DD");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const currUser = (store.getState().auth.nama);
    const [data, setData] = useState([]);
    const idPerusahaan = store.getState().auth.id_perusahaan;

    async function fetchData() {
        await new BagKeluarAPI().getBagKeluar(idPerusahaan).then((res) => {
            let datas = []
            res.data.map((val) => {
                let newData = {
                    id: val.id,
                    no_transaksi: val.no_transaksi,
                    tanggal: val.tanggal,
                    qty_total: val.qty_total,
                    status: val.status,
                    keterangan: val.keterangan,
                    status_validasi: val.status_validasi === 0 ? "Ongoing" : "Closed",
                    actions:
                        <div>
                            <Link
                                className="mx-1 btn btn-outline-success btn-sm"
                                to={
                                    {
                                        pathname: '/bag-keluar/detail',
                                        state: {
                                            id_bag_keluar: val.id,
                                            keterangan: val.keterangan,
                                            status_validasi: val.status_validasi,
                                            form: false
                                        }
                                    }
                                }
                            >
                                Detail
                            </Link>
                            <Link
                                className={`mx-1 btn btn-outline-success btn-sm ${val.status_validasi === 1 ? "disabled" : ""}`}
                                to={
                                    {
                                        pathname: '/bag-keluar/detail',
                                        state: {
                                            id_bag_keluar: val.id,
                                            no_transaksi: val.no_transaksi,
                                            tanggal: val.tanggal,
                                            keterangan: val.keterangan,
                                            form: true
                                        }
                                    }
                                }
                            >
                                Input Data
                            </Link>
                        </div>
                }
                return datas = [...datas, newData]
            })
            setData(datas);
        })
    }

    const columns = [
        { dataField: 'id', text: 'Id', sort: true },
        { dataField: 'tanggal', text: 'Tanggal', sort: true },
        { dataField: 'no_transaksi', text: 'No Transaksi', sort: true },
        { dataField: 'qty_total', text: 'Item Hilang', sort: true },
        { dataField: 'status_validasi', text: 'Status', sort: true },
        { dataField: 'keterangan', text: 'Keterangan', sort: true },
        { dataField: 'actions', text: 'Action', sort: false }
    ];

    const defaultSorted = [{
        dataField: 'no_transaksi',
        order: 'desc'
    }];


    useEffect(() => {
        let abortController = new AbortController();
        fetchData()
        return () => {
            abortController.abort()
        }
    }, [])

    return (
        <>
            <AnimatedCard>
                <CardHeader title={"Stok BAG Keluar"}>
                    <CardHeaderToolbar>
                        <Button
                            onClick={() => {
                                handleShow()
                            }}
                        >
                            Tambah
                        </Button>
                    </CardHeaderToolbar>
                </CardHeader>
                {/* Modal */}
                <Modal show={show} onHide={handleClose} style={{ textAlign: "center" }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm BAG Keluar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <p>Tanggal Bag Keluar : {currDate}</p>
                        <p>User :  {currUser}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={async () => {
                            let master = {
                                tanggal: currDate,
                                id_user: store.getState().auth.id,
                                id_perusahaan: idPerusahaan
                            }
                            Promise.all([
                                await new BagKeluarAPI().set(master),
                                fetchData()
                            ]).then((res) => {
                                handleClose()
                            })
                        }}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
                <CardBody>
                    <TableWithSearch dt={data} col={columns} srtd={defaultSorted} />
                </CardBody>
            </AnimatedCard>
        </>
    );
}

export default BagKeluar
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../_metronic/_partials/controls";
import { editRole, setRole } from "../../../api/roles";

export function ModalDialog(params) {
  const DataEditSchema = Yup.object().shape({
    nama: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Firstname is required"),
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={params.initialData}
        validationSchema={DataEditSchema}
        onSubmit={async (values, { resetForm }) => {
          if (params.isEdit) {
            await editRole(values);
          } else if (!params.isEdit) {
            await setRole(values);
          }
          params.onHide();
          resetForm();
        }}
      >
        {({ handleSubmit }) => (
          <Modal show={params.show} onHide={params.onHide}>
            <Modal.Header closeButton>
              <Modal.Title>{params.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="nama"
                      component={Input}
                      placeholder="Nama Role"
                      label="Nama Role"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={params.onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
}

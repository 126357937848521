import React, { useState } from 'react'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom'
import List from "./list"
import Form from "./form"
import Detail from "./detail"
import PengajuanLimitModel from '../../../models/pengajuan-limit/PengajuanLimitModel'

function ApprovalPengajuanLimit() {
  const [dataFromModel, setDataFromModel] = useState(new PengajuanLimitModel())
  const history = useHistory();
  return (
    <Switch>
      <Route path="/approval-limit-customer/list">
        <List setDataFromModel={setDataFromModel} />
      </Route>
      <Route path="/approval-limit-customer/approve">
        <Form history={history} data={dataFromModel}/>
      </Route>
      <Route path="/approval-limit-customer/detail">
        <Detail history={history} data={dataFromModel}/>
      </Route>
      <Redirect from="/approval-limit-customer" to="/approval-limit-customer/list" />
    </Switch>
  )
}

export default ApprovalPengajuanLimit
import React, { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import ApprovalPesananPenjualanAwal from "./approval_penjualan";
import Detail from "./detail";
import PesananPenjualanModel from "../../../models/pesanan-penjualan-model/PesananPenjualanModel";

export default function ApprovalAwalPesananPenjualan() {
  const [dataFromModel, setDataFromModel] = useState(new PesananPenjualanModel());
  const history = useHistory();

  return (
    <Switch>
      <Route path="/approval-pesanan-penjualan-awal/list">
        <ApprovalPesananPenjualanAwal setDataFromModel={setDataFromModel} />
      </Route>
      <Route path="/approval-pesanan-penjualan-awal/detail">
        <Detail history={history} dataFromModel={dataFromModel} isEdit={true} />
      </Route>
      <Redirect to="/approval-pesanan-penjualan-awal/list" />
    </Switch>
  )
  }
import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  SimpleTable,
  TableWithoutPagination,
} from "../../../components/table/Table";
import { dispatcher } from "../../../redux/dispatcher";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Link } from "react-router-dom";
import Model from "../../../models/penerimaan-pembelian-detail-model/PenerimaanPembelianDetailModel";
import { Button, Form, Modal } from "react-bootstrap";
import SimpleAlert2 from "../../../components/alert/alert2";
import { GudangAPI } from "../../../api/gudang";
import { Fields } from "../../../components/fields/Fields";
import PenerimaanDetailModel from "../../../models/penerimaan-pembelian-detail-model/PenerimaanPembelianDetailModel";

export function InboundDetail({ data, handleSubmit, validateForm }) {
  const [initialData, setInitialData] = useState(dispatcher.penerimaan.get());
  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const [showAlert, setShowAlert] = useState(false);
  const [dModel, setDModel] = useState(new PenerimaanDetailModel());
  const [gudang, setGudang] = useState([]);
  const [selectedGudang, setSelectedGudang] = useState(null);

  const loadData = async () => {
    let simpleTableModel = new SimpleTableModel({ readOnly: true });
    const actions = (dModel) => {
      if (initialData.master.kode == "") {
        return (
          <Button className="mx-1 btn btn-outline-secondary btn-sm" disabled>
            Validasi
          </Button>
        );
      } else if (dModel.status_validasi == 1) {
        return (
          <Button className="mx-1 btn btn-outline-secondary btn-sm" disabled>
            Sudah Validasi
          </Button>
        );
      } else {
        return (
          <Button
            className="mx-1 btn btn-outline-success btn-sm"
            onClick={() => {
              setDModel(dModel);
              setShowAlert(true);
            }}
          >
            Validasi
          </Button>
        );
      }
    };

    initialData.detail.map((item, i) => {
      if (item.status == 1)
        simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: item,
          actions: null,
          // actions: actions(item),
        });
    });

    setColumntable(await simpleTableModel.getColumnHeaders());
    setDatatable(await simpleTableModel.getDatatables());
  };

  const loadGudang = async () => {
    await new GudangAPI().getAll().then((values) => {
      setGudang(values.data);
    });
  };

  const refreshData = async () => {
    await dispatcher.penerimaan.single(initialData.master.id);
    setInitialData(dispatcher.penerimaan.get());
  };

  const getList = useMemo(() => {
    setInitialData(dispatcher.penerimaan.get());
    return dispatcher.penerimaan.get();
  }, [dispatcher.penerimaan.get()]);

  useEffect(() => {
    loadData();
    loadGudang();
  }, [getList]);

  return (
    <>
      <Modal
        show={showAlert}
        onHide={() => setShowAlert(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Pilih Gudang</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Gudang</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => setSelectedGudang(e.target.value)}
              >
                <option value="">Tidak ada dipilih</option>
                {gudang.map((data) => {
                  if (data.id_parent != null) {
                    return (
                      <option
                        key={data.id}
                        value={data.id}
                      >{`${data.toParent.nama} ${data.nama}`}</option>
                    );
                  }
                })}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAlert(false)}>
            Batal
          </Button>
          <Button
            variant="primary"
            onClick={async () => {
              let unvalid = {};
              handleSubmit();
              await validateForm().then((val) => (unvalid = val));

              if (selectedGudang == "" || selectedGudang == null) {
                dispatcher.snackbar.show(
                  "Warning",
                  "Pastikan gudang terpilih",
                  "warning"
                );
              }

              if (unvalid.kode == null) {
                let send = { id: dModel.id, id_gudang: selectedGudang };
                setShowAlert(false);
                await dispatcher.penerimaan.validate(send);
              } else {
                dispatcher.snackbar.show(
                  "Warning",
                  "Pastikan semua field terisi",
                  "warning"
                );
              }
              refreshData();
              setShowAlert(false);
            }}
          >
            Simpan Perubahan
          </Button>
        </Modal.Footer>
      </Modal>
      <TableWithoutPagination columns={columntable} data={datatable} />
    </>
  );
}

import moment from "moment";

import store from "../../../redux/store";
import { hapusSeparator, separatorHarga } from "../../../services/separator-harga";

import penerimaanBankCollectionDetailModel from "./penerimaanBankCollectionDetailModel";

export default class penerimaanBankCollectionModel {
  constructor(
    data = {
      transaksi: {
        id: 0,
        id_transaksi_jenis: 2,
        id_transaksi_ref: "",
        tanggal: "",
        kontak_ref: "",
        total: "",
        no_transaksi: "",
        tersisa: "",
        deskripsi: "",
        id_kontak: "",
        id_bank: "",
        nama_bank: "",
        no_rekening: "",
        id_user: store.getState().auth['id'],
        no_transaksi_ref: "",
        nama_customer: "",
        alamat_customer: "",
        nama_sales: "",
        tanggal_dp: "",
        tersisa_dp: "",
        total_dp: "",
        tanggal_ar: "",
        tersisa_ar: "",
        total_ar: "",
        cabang: "",
        akun: "",
        nama_transaksi_ref: "",
        alias_transaksi_ref: "",
        status: 0,
      },
      detail: [],
    }
  ) {
    const { id, id_transaksi_jenis, id_transaksi_ref, tanggal, kontak_ref, total, no_transaksi, tersisa, deskripsi, id_kontak, id_bank, nama_bank, no_rekening, id_user, no_transaksi_ref, nama_customer, alamat_customer, nama_sales, tanggal_dp, tersisa_dp, total_dp, tanggal_ar, tersisa_ar, total_ar, cabang, akun, nama_transaksi_ref, alias_transaksi_ref, status } = data['transaksi'];
    this['transaksi'] = {
      id: id,
      id_transaksi_jenis: id_transaksi_jenis,
      id_transaksi_ref: id_transaksi_ref,
      tanggal: tanggal,
      kontak_ref: kontak_ref,
      total: total,
      no_transaksi: no_transaksi,
      tersisa: tersisa,
      deskripsi: deskripsi,
      id_kontak: id_kontak,
      id_bank: id_bank,
      nama_bank: nama_bank,
      no_rekening: no_rekening,
      id_user: id_user,
      no_transaksi_ref: no_transaksi_ref,
      nama_customer: nama_customer,
      alamat_customer: alamat_customer,
      nama_sales: nama_sales,
      tanggal_dp: tanggal_dp,
      tersisa_dp: tersisa_dp,
      total_dp: total_dp,
      tanggal_ar: tanggal_ar,
      tersisa_ar: tersisa_ar,
      total_ar: total_ar,
      cabang: cabang,
      akun: akun,
      nama_transaksi_ref: nama_transaksi_ref,
      alias_transaksi_ref: alias_transaksi_ref,
      status: status,
    };
    this['detail'] = [];
    this.initDetails(data['detail']);
  }
  initDetails(detail) {
    if (detail.length > 0) {
      detail.forEach((item) => {
        this['detail'] = [
          ...this['detail'],
          new penerimaanBankCollectionDetailModel({
            ...item,
          }),
        ];
      });
    }
  }
  //** Start: Handle Detail */
  handleDetail = {
    set: async (item) => {
      const setSetails = this['detail'].filter((params) => { return params['status'] !== 0 });
      item['id'] = setSetails.length + 1;
      item['jumlah'] = parseInt(hapusSeparator(item['jumlah']));
      this['detail'] = [...setSetails, item];
    },
    del: async (dataModel) => {
      this['detail'].map((val, i) => {
        if (val['id'] === dataModel['id']) {
          this['detail'][i]['status'] = 0;
        }
        return val;
      });
      const delDetails = this['detail'].filter((params) => { return params['status'] !== 0 });
      this['detail'] = [...delDetails];
    },
    reset: async () => {
      this['detail'].map((val, i) => {
        if (val['status'] === 1) {
          this['detail'][i]['status'] = 0;
        }
        return val;
      });
      const resetdetails = this['detail'].filter((params) => { return params['status'] !== 0 });
      this['detail'] = [...resetdetails];
    },
    edit: async (item) => {
      this['detail'][this['detail'].map((e) => e['id']).indexOf(item['id'])] = item;
    },
  };
  //** End: Handle Detail */
  async getdata() {
    const { no_transaksi, cabang, kontak_ref, nama_bank, no_rekening, tanggal, akun, total, deskripsi } = this['transaksi'];
    return [
      {
        id: "no_transaksi",
        title: "No. Transaksi",
        label: no_transaksi,
        center: true,
        sortable: true,
        minWidth: "25%",
      }, {
        id: "id_perusahaan",
        title: "Cabang",
        label: cabang,
        center: true,
        sortable: true,
        minWidth: "20%",
      }, {
        id: "kontak_ref",
        title: "Diterima Dari",
        label: kontak_ref,
        sortable: true,
        minWidth: "30%",
      }, {
        id: "tanggal",
        title: "Tanggal",
        label: moment(tanggal).format("DD-MM-YYYY"),
        center: true,
        sortable: true,
        minWidth: "17%",
      }, {
        id: "akun",
        title: "Akun",
        label: akun,
        center: true,
        sortable: true,
        minWidth: "15%",
      }, {
        id: "nama_bank",
        title: "Nama Bank",
        label: nama_bank,
        center: true,
        sortable: true,
        minWidth: "25%",
      }, {
        id: "no_rekening",
        title: "No. Rekening",
        label: no_rekening,
        center: true,
        sortable: true,
        minWidth: "20%",
      }, {
        id: "total",
        title: "Total",
        label: total < 0 ? `Rp. -${separatorHarga(total.toString())}` : separatorHarga(total.toString(), "Rp. "),
        right: true,
        sortable: false,
        minWidth: "15%",
      }, {
        id: "deskripsi",
        title: "Keterangan",
        label: deskripsi,
        sortable: true,
        minWidth: "60%",
      },
    ];
  }
}
import * as Yup from "yup";

import { CardBody, CardHeader, CardHeaderToolbar } from "../../../../../_metronic/_partials/controls";
import { Form, Formik } from "formik";
import React, { Component } from 'react';

import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import { FormsFields } from "../../../../components/forms-fields/FormsFields";
import { Spinner } from "react-bootstrap";
import { dispatcher } from '../../../../redux/dispatcher';
import { kontakAPI } from '../../../../api/kontak';

class FormDataCustomer extends Component {
  constructor(props) {
    super(props);
    this['title'] = props['isEdit'] ? "Edit Form Data Customer" : "Add Form Data Customer";
    this['isEdit'] = props['isEdit'];
    this['apiDataCustomer'] = new kontakAPI();
    this['initialData'] = dispatcher['kontak'].getDataCustomer();
    this['options_nama_sales'] = [];
    this['options_kolektor'] = [];
    this['options_organisasi'] = [];
  }

  async componentDidMount() {
    // Get Nama Sales
    this['apiDataCustomer'].getNamaSales().then((res) => {
      this['options_nama_sales'] = res['data'].map((val) => {
        return {
          value: val['id'],
          label: val['text'],
        };
      });
    });
    // Get Organisasi
    this['apiDataCustomer'].getOrganisasi().then((res) => {
      this['options_organisasi'] = res['data'].map((val) => {
        return {
          value: val['id'],
          label: val['text'],
        };
      });
    });

    this['apiDataCustomer'].getAllKaryawan().then((res) => {
      this['options_kolektor'] = res['data'].map((val) => {
        return {
          value: val['id_karyawan'],
          label: val['nama_karyawan'],
        };
      });
    });

  }
  render() {
    return (
      <>
        {/* Form */}
        <Formik
          enableReinitialize={true}
          initialValues={this['initialData']}
          validationSchema={Yup.object().shape({
            file_ktp: Yup.string().nullable(true).required("Images KTP/SIM harus diisi"),
            nama: Yup.string().required(),
            id_jenis_kelamin: Yup.string().nullable(true).required("Jenis Kelamain harus diisi"),
            tempat_lahir: Yup.string().required(),
            tanggal_lahir: Yup.string().required(),
            alamat: Yup.string().max(150, "Maximum 150 String").required("Keterangan harus diisi"),
            nama_rekening: Yup.string().required(),
            no_rekening: Yup.string().required(),
            nama_bank: Yup.string().required(),
            ktp: Yup.string().required(),
            hp: Yup.string().required(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (!this['isEdit'] && values['id'] === 0) {
              // Insert
              this['apiDataCustomer'].setDataCustomer(values).then((res) => {
                if (res['status']) {
                  dispatcher['snackbar'].show("Berhasil", res['message'], "primary");
                  this['props']['history'].goBack();
                } else {
                  return dispatcher['snackbar'].show("Gagal", res['message'], "danger");
                }
              });
            } else {
              // Update
              this['apiDataCustomer'].editDataCustomer(values).then((res) => {
                if (res['status']) {
                  dispatcher['snackbar'].show("Berhasil", res['message'], "primary");
                  this['props']['history'].goBack();
                } else {
                  return dispatcher['snackbar'].show("Gagal", res['message'], "danger");
                }
              });
            }
            setSubmitting(false);
            // this.sendingData(values, setSubmitting);
          }}
        >
          {({ handleSubmit, isSubmitting, touched, errors, setFieldValue, setFieldTouched, values, }) => (
            <AnimatedCard>
              <CardHeader title={this['title']}>
                <CardHeaderToolbar>
                  {/* Button Back */}
                  <button
                    type={"button"}
                    className={"btn btn-secondary mx-1"}
                    onClick={() => this['props']['history'].goBack()}
                  >
                    <i className={"fa fa-arrow-left"}></i>
                    Kembali
                  </button>
                  {/* Button Simpan */}
                  <button
                    type={"button"}
                    disabled={isSubmitting}
                    className={"btn btn-primary mx-1"}
                    onClick={async () => handleSubmit()}
                  >
                    <span>
                      {(!isSubmitting) ?
                        (<>
                          <i className={"fa fa-plus"}></i> {" Simpan"}
                        </>) : (<>
                          <Spinner
                            as={"span"}
                            animation={"border"}
                            size={"sm"}
                            role={"status"}
                            aria-hidden={"true"}
                          /> {" Loading..."}
                        </>)
                      }
                    </span>
                  </button>
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <Form className={"form form-label-right"}>
                  <div className={"form-group row"}>
                    <div className={"col-lg-6"}>
                      <div className={"form-group row"}>
                        {/* Kode */}
                        <FormsFields
                          id={"kode"}
                          type={"text"}
                          label={"Kode"}
                          className={"col-lg-12"}
                          // style={{ marginTop: "20px" }}
                          disabled={true}
                          // value={values['kode']}
                          paramsFormik={{
                            setFieldValue,
                            values
                          }}
                        />
                        {/* Jenis Kelamin */}
                        <FormsFields
                          id={"id_jenis_kelamin"}
                          type={"select2"}
                          label={"Jenis Kelamin"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          isClearable={false}
                          isSearchable={false}
                          options={[{
                            value: 1,
                            label: "Laki-laki",
                          }, {
                            value: 2,
                            label: "Perempuan",
                          }]}
                          paramsFormik={{
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          }}
                        />
                        {/* Tanggal Lahir */}
                        <FormsFields
                          id={"tanggal_lahir"}
                          type={"DatePicker"}
                          label={"Tanggal Lahir"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          paramsFormik={{
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          }}
                        />
                        {/* Nama Sales */}
                        <FormsFields
                          id={"id_sales"}
                          type={"select2"}
                          label={"Nama Sales"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          isClearable={true}
                          isSearchable={true}
                          options={this['options_nama_sales']}
                          paramsFormik={{
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          }}
                        />
                        {/* No Rekening */}
                        <FormsFields
                          id={"no_rekening"}
                          type={"number"}
                          label={"No. Rekening"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          paramsFormik={{
                            setFieldValue,
                            values
                          }}
                        />
                        {/* No. KTP/SIM */}
                        <FormsFields
                          id={"ktp"}
                          type={"number"}
                          label={"No. KTP/SIM"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          paramsFormik={{
                            setFieldValue,
                            values
                          }}
                        />
                        {/* Organisasi */}
                        <FormsFields
                          id={"id_organisasi"}
                          type={"select2"}
                          label={"Organisasi"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          isClearable={true}
                          isSearchable={true}
                          options={this['options_organisasi']}
                          paramsFormik={{
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          }}
                        />
                      </div>
                    </div>
                    <div className={"col-lg-6"}>
                      <div className={"form-group row"}>
                        {/* Nama */}
                        <FormsFields
                          id={"nama"}
                          type={"text"}
                          label={"Nama"}
                          className={"col-lg-12"}
                          paramsFormik={{
                            setFieldValue,
                            values
                          }}
                        />
                        {/* Tempat Lahir */}
                        <FormsFields
                          id={"tempat_lahir"}
                          type={"text"}
                          label={"Tempat Lahir"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          paramsFormik={{
                            setFieldValue,
                            values
                          }}
                        />
                        {/* Alamat */}
                        <FormsFields
                          id={"alamat"}
                          type={"textarea"}
                          label={"Alamat"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          rows={2}
                          paramsFormik={{
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          }}
                        />
                        {/* Nama Rekening */}
                        <FormsFields
                          id={"nama_rekening"}
                          type={"text"}
                          label={"Nama Rekening"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          paramsFormik={{
                            setFieldValue,
                            values
                          }}
                        />
                        {/* Nama Bank */}
                        <FormsFields
                          id={"nama_bank"}
                          type={"text"}
                          label={"Nama Bank"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          paramsFormik={{
                            setFieldValue,
                            values
                          }}
                        />
                        {/* No. HP */}
                        <FormsFields
                          id={"hp"}
                          type={"text"}
                          label={"No. HP"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          paramsFormik={{
                            setFieldValue,
                            values
                          }}
                        />
                        {/* Nama Kolektor */}
                        <FormsFields
                          id={"id_kolektor"}
                          type={"select2"}
                          label={"Nama Kolektor"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          isClearable={true}
                          isSearchable={true}
                          options={this['options_kolektor']}
                          paramsFormik={{
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          }}
                        />
                      </div>
                    </div>
                    <div className={"col-lg-12"}>
                      <div className={"form-group row"}>
                        <div className={"col-lg-6"}>
                          {/* No. KTP/SIM */}
                          <FormsFields
                            id={"file_ktp"}
                            type={"image"}
                            label={"KTP/SIM"}
                            className={"col-lg-12"}
                            style={{ marginTop: "20px" }}
                            paramsFormik={{
                              setFieldValue,
                              setFieldTouched,
                              touched,
                              errors,
                              values
                            }}
                          />
                        </div>
                        <div className={"col-lg-6"}>
                          {/* No. KK */}
                          <FormsFields
                            id={"file_kk"}
                            type={"image"}
                            label={"Kartu Keluarga"}
                            className={"col-lg-12"}
                            style={{ marginTop: "20px" }}
                            paramsFormik={{
                              setFieldValue,
                              setFieldTouched,
                              touched,
                              errors,
                              values
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </AnimatedCard>
          )}
        </Formik>
      </>
    );
  }
}

export default FormDataCustomer;
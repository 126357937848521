/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { AnimatedCard } from '../../../components/animated-card/animatedCard';
import { CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls';
import moment from 'moment';
import store from '../../../redux/store';
import { StokOpnameAPI } from '../../../api/stok-opname';
import { useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, Link } from "react-router-dom";
import { TableWithSearch } from '../../../components/table/Table.js';
import { API } from '../../../api/api';
// import TableWithSearch from '../../../components/table-with-search/TableWithSearch';

function StokOpname() {
    const [show, setShow] = useState(false);
    const currDate = moment(new Date()).format("YYYY-MM-DD");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const currUser = (store.getState().auth.nama);
    const [data, setData] = useState([]);
    const user = store.getState().auth.id;
    const idPerusahaan = store.getState().auth.id_perusahaan;
    // console.log(currUser);

    async function fetchData() {
        await new StokOpnameAPI().getOpname(idPerusahaan).then((res) => {
            let datas = []
            res.data.map((val) => {
                let newData = {
                    id: val.id,
                    no_transaksi: val.no_transaksi,
                    tanggal: val.tanggal,
                    qty_total: val.qty_total,
                    status: val.status,
                    status_validasi: val.status_validasi === 0 ? "Ongoing" : "Closed",
                    actions:
                        <div>
                            <Link
                                className="mx-1 btn btn-outline-success btn-sm"
                                to={
                                    {
                                        pathname: '/stok-opname/detail',
                                        state: {
                                            id_opname: val.id,
                                            form: false
                                        }
                                    }
                                }
                            >
                                Detail
                            </Link>
                            {val.status_validasi !== 1 ?
                                <Link
                                    className={`mx-1 btn btn-outline-success btn-sm`}
                                    to={
                                        {
                                            pathname: '/stok-opname/detail',
                                            state: {
                                                id_opname: val.id,
                                                no_transaksi: val.no_transaksi,
                                                tanggal: val.tanggal,
                                                form: true
                                            }
                                        }
                                    }
                                >
                                    <i className="fas fa-pencil-alt"></i>
                                </Link>
                                :
                                <Button
                                    type={"button"}
                                    className={"btn btn-warning mx-1"}
                                    onClick={async () => {
                                        window.open(
                                            API.host +
                                            "retail/stok/ctkn-opname?id=" +
                                            val.id + "&id_user=" + user,
                                            "_blank",
                                            "noopener,noreferrer"
                                        );
                                    }}
                                >
                                    <i className={"fa fa-print"}></i>
                                </Button>
                            }
                            {/* <Button onClick={() => { 
                                return <FormDetail/>
                            }}><i className="fa fa-pen"></i></Button> */}
                        </div>
                }
                return datas = [...datas, newData]
            })
            setData(datas);
        })
    }

    const columns = [
        { dataField: 'id', text: 'Id', sort: true },
        { dataField: 'tanggal', text: 'Tanggal', sort: true },
        { dataField: 'no_transaksi', text: 'No Transaksi', sort: true },
        { dataField: 'qty_total', text: 'Total Item', sort: true },
        { dataField: 'status_validasi', text: 'Status', sort: true },
        { dataField: 'actions', text: 'Action', sort: false }
    ];

    const defaultSorted = [{
        dataField: 'no_transaksi',
        order: 'desc'
    }];


    useEffect(() => {
        let abortController = new AbortController();
        fetchData()
        return () => {
            abortController.abort()
        }
    }, [])

    return (
        <>
            <AnimatedCard>
                <CardHeader title={"Stok Opname"}>
                    <CardHeaderToolbar>
                        <Button
                            onClick={() => {
                                handleShow()
                            }}
                        >
                            Tambah
                        </Button>
                    </CardHeaderToolbar>
                </CardHeader>
                {/* Modal */}
                <Modal show={show} onHide={handleClose} style={{ textAlign: "center" }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Opname</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <p>Tanggal Opname : {currDate}</p>
                        <p>User :  {currUser}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={async () => {
                            let master = {
                                tanggal: currDate,
                                id_user: store.getState().auth.id,
                                id_perusahaan: idPerusahaan
                            }
                            Promise.all([
                                await new StokOpnameAPI().set(master),
                                fetchData()
                            ]).then((res) => {
                                console.log(res);
                                handleClose()
                            })
                        }}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
                <CardBody>
                    <TableWithSearch dt={data} col={columns} srtd={defaultSorted} />
                </CardBody>
            </AnimatedCard>
        </>
    );
}

export default StokOpname
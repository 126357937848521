import * as Yup from "yup";
export default class TaxModel {
  constructor(data = { id: 0, nama: "", start: "", end: "", nilai: 0 }) {
    this.id = data.id;
    this.nama = data.nama;
    this.start = data.start;
    this.end = data.end;
    this.nilai = data.nilai;
  }

  async getEditable(params = { pageName: "", isForm: false }) {
    return [
      {
        validation: Yup.string().required("Nama harus diisi"),
        dataField: "nama",
        label: "Nama",
        type: "text",
        value: this.id_parent !== 0 ? this.nama : " - ",
      },
      {
        validation: Yup.string().required("Nama harus diisi"),
        dataField: "start",
        label: "Mulai",
        type: "date",
        value: this.start,
      },
      {
        validation: Yup.string().required("Nama harus diisi"),
        dataField: "end",
        label: "Berakhir",
        type: "date",
        value: this.end,
      },
      {
        validation: Yup.string().required("Nama harus diisi"),
        dataField: "nilai",
        label: "Nilai",
        type: "number",
        value: this.nilai,
      },
    ];
  }
}

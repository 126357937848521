import * as Yup from "yup";
export default class AkunJenisModel {
  constructor(data = { id: 0, nama: "", deskripsi: "" }) {
    this.id = data.id;
    this.nama = data.nama;
    this.deskripsi = data.deskripsi;
  }

  async getEditable(params = { pageName: "", isForm: false }) {
    return [
      {
        validation: Yup.string().required("Nama harus diisi"),
        dataField: "nama",
        label: "Nama",
        type: "text",
        value: this.nama,
      },
      {
        validation: Yup.string(),
        dataField: "deskripsi",
        label: "Deskripsi",
        type: "text",
        value: this.deskripsi,
      },
    ];
  }
}

import moment from "moment";
import { separatorHarga } from "../../../services/separator-harga";

export default class DetailInvoiceBelumTerbayarModel {
  constructor(
    data = {
      no_transaksi: 0,
      tanggal_invoice: "",
      tanggal_jatuh_tempo: "",
      terbayar: 0,
      tersisa: 0,
      jatuh_tempo: 0,
    }
  ) {
    const { no_transaksi, tanggal_invoice, tanggal_jatuh_tempo, terbayar, tersisa, jatuh_tempo } = data;
    this['no_transaksi'] = no_transaksi;
    this['tanggal_invoice'] = tanggal_invoice;
    this['tanggal_jatuh_tempo'] = tanggal_jatuh_tempo;
    this['terbayar'] = terbayar;
    this['tersisa'] = tersisa;
    this['jatuh_tempo'] = jatuh_tempo;
  }

  async getdata() {
    const { no_transaksi, tanggal_invoice, tanggal_jatuh_tempo, terbayar, tersisa, jatuh_tempo } = this;
    return [{
      id: "no_transaksi",
      title: "No. Transaksi",
      label: no_transaksi,
      center: true,
      sortable: true,
      minWidth: "25%",
    }, {
      id: "tanggal_invoice",
      title: "Tanggal Invoice",
      label: moment(tanggal_invoice).format("DD-MM-YYYY"),
      center: true,
      sortable: true,
      minWidth: "15%",
    }, {
      id: "tanggal_jatuh_tempo",
      title: "Tanggal Jatuh Tempo",
      label: moment(tanggal_jatuh_tempo).format("DD-MM-YYYY"),
      center: true,
      sortable: true,
      minWidth: "15%",
    }, {
      id: "terbayar",
      title: "Total Dibayar",
      label: terbayar < 0 ? `Rp. -${separatorHarga(terbayar.toString())}` : separatorHarga(terbayar.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "tersisa",
      title: "Tersisa",
      label: tersisa < 0 ? `Rp. -${separatorHarga(tersisa.toString())}` : separatorHarga(tersisa.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "jatuh_tempo",
      title: "Jatuh Tempo",
      label: jatuh_tempo,
      center: true,
      sortable: true,
      minWidth: "20%",
    }];
  }
}

import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../../components/table/Table";
import SimpleTableModel from "../../../../models/simpletable-model/SimpleTableModel";
import Model from "../../../../models/laporan-penjualan-model/LaporanPenjualanModel";
import { Link } from "react-router-dom";
import { dispatcher } from "../../../../redux/dispatcher";
import SimpleAlert2 from "../../../../components/alert/alert2";
import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import moment from "moment";
import { Button, Form, Modal } from "react-bootstrap";
import { PenjualanAPI } from "../../../../api/penjualan";
import Select2 from "react-select";
import store from "../../../../redux/store";
import { API } from "../../../../api/api";

export default function List(params) {
  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const pageName = "Laporan Penjualan";
  const [startDate, setStartDate] = useState(
    moment(new Date())
      .startOf("month")
      .format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [search, setSearch] = useState("");
  const [option, setOptions] = useState([]);
  const [cabang, setCabang] = useState([0]);
  const [show, setShow] = useState(false);

  let options = [];

  const ksa = [20, 46, 52, 69];
  const kpp = [68, 28, 70, 71];
  const ksb = [108, 105, 110, 109];

  const [pt] = useState([{
    value: ksa,
    label: "KSA"
  }, {
    value: kpp,
    label: "KPP"
  }, {
    value: ksb,
    label: "KSB"
  }])

  const [selectedPT, setSelectedPT] = useState(ksa.includes(store.getState().auth.id_perusahaan) ? ksa : kpp.includes(store.getState().auth.id_perusahaan) ? kpp : ksb);
  const [idPerusahaan, setIdPerusahaan] = useState([selectedPT]);

  const coverage = async () => {
    await new PenjualanAPI().getCoverage().then((res) => {
      let filter = res.data.filter((data) => selectedPT.includes(data.id))
      options = filter.map((data) => (
        {
          label: data.text,
          text: data.text,
          value: data.id,
        }));
    });
    setOptions(options);
  };

  const [data, setData] = useState([])
  const [deskripsi, setDeskripsi] = useState("");
  const api = new PenjualanAPI();
  const [refreshData, setRefresh] = useState(false);

  const handleClose = () => {
    setShow(false)
  };
  const handleChange = (e) => setDeskripsi(e.target.value)
  const handleSimpan = () => {
    data.removeSeparator()
    data.removeAllDetailSeparator()
    data.master.status_retur = 1
    api.edit(data.master)
    data.master.deskripsi = deskripsi
    api.retur(data.master)
    console.log(data.master)

  };
  // const handleShow = (data) => {
  //   setData(data)
  //   setShow(true)
  // }

  async function loadData(vals) {
    let datas = await new PenjualanAPI().getAllLaporan(vals).then((dta) => dta.data);
    let dataMaster = datas;
    let simpleTableModel = new SimpleTableModel();
    let searchParams = ["no_transaksi", "nama_sales", "nama_pelanggan", "nama_organisasi"];
    let filtered = dataMaster.filter((filter) => {
      let dateFormat = moment(filter.master.tanggal_invoice).format("YYYY-MM-DD");
      let dateFilter = dateFormat >= startDate && dateFormat <= endDate;
      // let cabangSelected = filter.master.id_perusahaan === cabang;
      return searchParams.some((item) => {
        return (
          dateFilter &&
          filter.master[item]
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())
        );
      });
    });
    const actions = (dataModel) => {
      return (
        <>
          <Link
            className="mx-1 btn btn-outline-success btn-sm"
            to="/penjualan/detail"
            onClick={() => {
              dispatcher.penjualan.set(dataModel);
            }}
          >
            Detail
          </Link>
          {/* {dataModel.master.status_invoice === 0 && (
            <Link
              className="mx-1 btn btn-outline-primary btn-sm"
              to="/penjualan/outbound"
              onClick={() => {
                dispatcher.penjualan.set(dataModel);
              }}
            >
              Validasi
            </Link>
          )} */}
        </>
      );
    };

    filtered.map((item, i) => {
      let dataModel = new Model(item);
      if (item.master.status === 1)
        simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: actions(dataModel),
        });
    });

    setColumntable(await simpleTableModel.getColumnHeaders());
    setDatatable(await simpleTableModel.getDatatables());
  }

  useEffect(() => {
    if (cabang.includes(0)) {
      loadData(selectedPT)
      setIdPerusahaan(selectedPT)
    } else {
      loadData(cabang)
      setIdPerusahaan(cabang)
    }
    coverage();
  }, [selectedPT, cabang, startDate, endDate, search]);

  return (
    <>
      <SimpleAlert2
        title="Hapus Data Ini?"
        content="Anda yakin ingin menghapus data ini?"
        variant="danger"
        onConfirm={() => { }}
      />
      <AnimatedCard>
        <CardHeader title={pageName}>
          <div className="d-flex justify-content-lg-end">
            {/* Cari Data */}
            <div style={{ marginTop: "20px" }}>
              <input
                type={"text"}
                className={"form-control"}
                placeholder="Cari Data ..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  dispatcher.penjualan.list();
                }}
              />
            </div>
            &emsp;
            &emsp;
            {/* Ekspor Excel */}
            <div style={{ marginTop: "20px" }}>
              <Button
                type="download"
                onClick={async () => {
                  let params = [];
                  if (idPerusahaan.length !== 1) {
                    params += idPerusahaan.join("&id_perusahaan=");
                  } else {
                    params += idPerusahaan;
                  }
                  window.open(
                    API.host +
                    "retail/laporan_penjualan/download?id_perusahaan=" +
                    params + `&start_date=${startDate}&end_date=${endDate}`,
                    "_self",
                    "noopener,noreferrer"
                  );
                }}
              >
                <i className={"far fa-file-excel"}></i> {" Export .xls"}
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="d-flex justify-content-start form-group row">
            {/* Tanggal Mulai */}
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <label> Tanggal Mulai </label>
              <input
                name={"tanggal_awal"}
                type={"date"}
                value={startDate}
                className={"form-control"}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </div>
            {/* Tanggal Akhir */}
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <label> Tanggal Akhir </label>
              <input
                name={"tanggal_akhir"}
                type={"date"}
                value={endDate}
                className={"form-control"}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            </div>
            {/* Pilih Perusahaan */}
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <label> Pilih Perusahaan </label>
              <Select2
                name={"PT"}
                size="sm"
                options={pt}
                isClearable={false}
                value={
                  pt
                    ? pt.find(
                      (option) => option.value === selectedPT
                    )
                    : ""
                }
                onChange={(e) => {
                  // console.log('Opsi', pt);
                  setSelectedPT(e ? e.value : []);
                  // console.log('Selected', selectedPT);
                }}
              />
            </div>
            {/* Pilih Cabang */}
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <label> Pilih Cabang </label>
              <Select2
                name={"cabang"}
                placeholder={option.text}
                size="sm"
                options={option}
                isClearable={true}
                value={
                  option
                    ? option.find(
                      (option) => option.value === cabang
                    )
                    : ""
                }
                onChange={(e) => {
                  setCabang(e ? [e.value] : [0]);
                  dispatcher.penjualan.list();
                }}
              />
            </div>
          </div>
          <SimpleTable data={datatable} columns={columntable} />
        </CardBody>
        {/* Modal */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Retur Pembelian Barang</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            <Form.Group >
              <Form.Label>Alasan Retur: </Form.Label>
              <Form.Control onChange={handleChange} value={deskripsi} as="textarea" rows={3} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Batal
            </Button>
            <Button variant="primary"
              type="submit"
              onClick={() => {
                handleSimpan()
                handleClose()
                setRefresh(!refreshData)
              }}
            >
              Retur Penjualan
            </Button>
          </Modal.Footer>
        </Modal>
      </AnimatedCard>
    </>
  );
}

import store from "../redux/store";
import { getCoverageArea, getIdPerusahaan, simpleFetch } from "./api";

export class CustomerLimitAPI {
  async getAllCustLimit(data) {
    let params = "id_perusahaan=";
    params += data.cabang;
    let res = await simpleFetch({
      url: "retail/kontak/customer_limit?" + params,
      method: "GET",
    });
    return res.data;
  }

  async getPengajuanLimit(data) {
    let params = "id_perusahaan=";
    params += data.cabang;
    let res = await simpleFetch({
      url: "retail/limit/data?" + params + "&status=" + data.status,
      method: "GET",
    });
    return res.data;
  }

  async pengajuanLimit(val) {
    let res;
    try {
      res = await simpleFetch({
        url: 'retail/limit/form',
        method: 'POST',
        body: {
          id_user: store.getState().auth.id,
          id_perusahaan: getIdPerusahaan(),
          ...val,
        }
      })
    } catch (error) {
      console.log(error);
    } finally {
      return res;
    }
  }

  async approvalPengajuanLimit(val) {
    let res;
    try {
      res = await simpleFetch({
        url: 'retail/limit/approve',
        method: 'POST',
        body: {
          id_user: store.getState().auth.id,
          ...val,
        }
      })
    } catch (error) {
      console.log(error);
    } finally {
      return res;
    }
  }

  async getCoverage() {
    let res = await simpleFetch({
      url: "retail/select/perusahaan?id_coverage=" + getCoverageArea(),
      method: "GET",
    });
    return res;
  }
}
import React, { useEffect, useMemo, useState } from "react";
import {
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import Model from "../../../models/penerimaan-pembelian-model/PenerimaanPembelianModel";
import { Link } from "react-router-dom";
import { dispatcher } from "../../../redux/dispatcher";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import moment from "moment";
import { PenerimaanAPI } from "../../../api/penerimaan";
import Select2 from "react-select";
import store from "../../../redux/store";
import { Button, Form, Modal } from "react-bootstrap";

export default function List(params) {
  const [refreshData, setRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([])
  const [deskripsi, setDeskripsi] = useState("")
  const api = new PenerimaanAPI();
  const handleClose = () => {
    setShow(false)
  };
  const handleChange = (e) => setDeskripsi(e.target.value)
  const handleSimpan = () => {
    data.removeSeparator()
    data.removeAllDetailSeparator()
    data.master.deskripsi = deskripsi
    api.retur(data.master);
  };
  const handleShow = (data) => {
    setData(data)
    setShow(true)
  }


  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const pageName = "Invoice Pembelian";
  const [startDate, setStartDate] = useState(
    moment(new Date())
      .startOf("month")
      .format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [search, setSearch] = useState("");
  const [option, setOptions] = useState([]);
  const [cabang, setCabang] = useState(store.getState().auth.id_perusahaan);

  const coverage = async () => {
    let options = [];
    await new PenerimaanAPI().getCoverage().then((res) => {
      options = res.data.map((data) => ({
        label: data.text,
        text: data.text,
        value: data.id,
      }));
    });
    setOptions(options);
  };


  // useEffect(() => {
  //   loadData()
  // },[options])

  async function loadData() {
    let datas = dispatcher.penerimaan.getList();
    let dataMaster = datas;
    let simpleTableModel = new SimpleTableModel();
    let searchParams = ["no_transaksi", "total", "kode"];
    let filtered = dataMaster.filter((filter) => {
      let dateFormat = moment(filter.master.tanggal).format("YYYY-MM-DD");
      let dateFilter = dateFormat >= startDate && dateFormat <= endDate;
      let cabangSelected = filter.master.id_perusahaan === cabang;
      return searchParams.some((item) => {
        return (
          dateFilter &&
          cabangSelected &&
          filter.master[item]
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())
        );
      });
    });
    const actions = (dataModel) => {
      return (
        <>
          <Link
            className="mx-1 btn btn-outline-success btn-sm"
            to="/pembelian-penerimaan/detail"
            onClick={() => {
              dispatcher.penerimaan.set(dataModel);
            }}
          >
            Detail
          </Link>
          {dataModel.master.status_validasi === 2 ? (
            <Button
              className="mx-1 btn-outline-danger btn-sm"
              onClick=
              {() => {
                handleShow(dataModel)
              }}
            >
              Retur
            </Button>
          ) : (
            <Button
              className="mx-1 btn btn-secondary btn-sm"
              disabled
            >
              Retur
            </Button>
          )}
          {dataModel.master.kode === "" ? (
            <span>
              <Link
                className="mx-1 btn btn-outline-primary btn-sm"
                to="/pembelian-penerimaan/inbound"
                onClick={() => {
                  params.setDetail(dataModel.detail)
                  dispatcher.penerimaan.set(dataModel);
                }}
              >
                Validasi
              </Link>
            </span>
          ) : ""}
        </>
      );
    };

    filtered.map(async (item, i) => {
      let dataModel = new Model(item);
      if (item.master.status === 1)
        simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: actions(dataModel),
        });
    });

    setColumntable(await simpleTableModel.getColumnHeaders());
    setDatatable(await simpleTableModel.getDatatables());
  }

  useEffect(() => {
    loadData();
    coverage();
  }, [dispatcher.penerimaan.getList()]);

  useEffect(() => {
    dispatcher.penerimaan.list();
  }, [refreshData, startDate, endDate, cabang]);

  return (
    <>
      <AnimatedCard>
        <CardHeader title={pageName}></CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Mulai </label>
              <input
                name={"tanggal_awal"}
                type={"date"}
                value={startDate}
                className={"form-control"}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  loadData();
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Akhir </label>
              <input
                name={"tanggal_akhir"}
                type={"date"}
                value={endDate}
                className={"form-control"}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  loadData();
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Pilih Perusahaan </label>
              <Select2
                name={"Cabang"}
                size="sm"
                options={option}
                isClearable={true}
                value={
                  option
                    ? option.find(
                      (option) => option.value === cabang
                    )
                    : ""
                }
                onChange={(e) => {
                  setCabang(e ? e.value : []);
                  dispatcher.penerimaan.list();
                  // setFieldValue("cabang", e ? e.value : "");
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <input
                type={"text"}
                className={"form-control"}
                placeholder="Cari Data ..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  dispatcher.penerimaan.list();
                }}
              />
            </div>
          </div>
          <SimpleTable data={datatable} columns={columntable} />
        </CardBody>
        {/* Modal */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Retur Pembelian Barang</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ textAlign: "center" }}>
            <Form.Group >
              <Form.Label>Alasan Retur: </Form.Label>
              <Form.Control onChange={handleChange} value={deskripsi} as="textarea" rows={3} />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Batal
            </Button>
            <Button variant="primary"
              type="submit"
              onClick={() => {
                handleSimpan()
                handleClose()
                setRefresh(!refreshData)
              }}
            >
              Retur Pembelian
            </Button>
          </Modal.Footer>
        </Modal>
      </AnimatedCard>
    </>
  );
}

import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import List from "./list";
import Detail from "./detail";
import { useState } from "react";
// import { dispatcher } from "../../../redux/dispatcher";

export default function LaporanPembelian() {
  const history = useHistory();
  const [detail, setDetail] = useState([])
  // const active = dispatcher.penerimaan.get();

  return (
    <Switch>
      <Route path="/laporan-pembelian/list">
        <List setDetail={setDetail} />
      </Route>

      {/* {active.master.id == 0 && <Redirect to="/pembelian-penerimaan/list" />} */}

      <Route path="/laporan-pembelian/detail">
        <Detail history={history} />
      </Route>

      <Redirect to="/laporan-pembelian/list" />
    </Switch>
  );
}

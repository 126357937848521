import { separatorHarga } from "../../services/separator-harga";

export default class PengajuanLimitModel {
  constructor(
    data = {
      id: 0,
      id_kontak: 0,
      id_organisasi:0,
      id_perusahaan: 0,
      nama: "",
      nama_organisasi:"",
      tanggal: "",
      no_pengajuan: "",
      nilai_limit:0,
      limit_request: 0,
      is_approved: 0,
      approved_by:0,
      keterangan: "",
    }
  ) {
    this.data = {
      id: data.id,
      id_organisasi: data.id_organisasi,
      id_kontak: data.id_kontak,
      id_perusahaan: data.id_perusahaan,
      no_pengajuan: data.no_pengajuan,
      tanggal: data.tanggal,
      nama: data.nama,
      nama_organisasi: data.nama_organisasi,
      nilai_limit: data.nilai_limit,
      limit_request: data.limit_request,
      is_approved: data.is_approved,
      approved_by: data.approved_by,
      keterangan: data.keterangan,
    }
  }

  async getEditable() {
    const { nama, nama_organisasi,no_pengajuan, id_perusahaan, tanggal, nilai_limit, limit_request, keterangan} = this.data;
    return [
      {
        dataField: "id_kontak",
        label: "Customer",
        value: nama,
      },
      {
        dataField: "organisasi",
        label: "Organisasi",
        value: nama_organisasi,
      },
      {
        dataField: "id_perusahaan",
        label: "Perusahaan",
        value: id_perusahaan,
        invisibleOnTable: true,
      },
      {
        dataField: "no_pengajuan",
        label: "No Pengajuan",
        value: no_pengajuan,
        invisibleOnTable: false,
      },
      {
        dataField: "tanggal",
        label: "Tanggal",
        value: tanggal,
      },
      {
        dataField: "nilai_limit",
        label: "Nilai Limit",
        value: `Rp. ${separatorHarga(nilai_limit.toString())}`,
      },
      {
        dataField: "limit_request",
        label: "Limit Diajukan",
        value: `Rp. ${separatorHarga(limit_request.toString())}`,
      },
      {
        dataField: "keterangan",
        label: "Keterangan",
        value: keterangan,
      },
    ];
  }
}

import * as Yup from "yup";
import store from "../../redux/store";

export default class MerekModel {
  constructor(
    data = {
      id: 0,
      id_pt: 0,
      id_perusahaan: store.getState().auth.id_perusahaan,
      nama: "",
      id_perusahaan_login: store.getState().auth.id_perusahaan,
    }
  ) {
    this.id = data.id;
    this.id_pt = data.id_pt;
    this.id_perusahaan = data.id_perusahaan;
    this.nama = data.nama;
    this.id_perusahaan_login = data.id_perusahaan_login;
  }

  async getEditable(params = { pageName: "", isForm: false }) {
    let options_pt = [
      { id: 1, text: "PT. Kumala Putra Prima", value: 1 },
      { id: 2, text: "PT. Kumala Sukses Abadi", value: 2 },
      { id: 3, text: "PT. Kumala Sukses Bersama", value: 3 },
    ];

    return [
      {
        validation: Yup.string().required('Nama Merek Harus diisi'),
        dataField: "nama",
        label: "Nama Merek",
        type: "text",
        onChange: () => { },
        value: this.nama,
      },
      {
        validation: Yup.number('PT Harus Dipilih')
        .required("Pilih PT")
        .oneOf([1, 2, 3]),
        dataField: "id_pt",
        label: "PT",
        type: "select",
        options: options_pt,
        value: this.id_pt === 1 ? 'PT. Kumala Putra Prima' : this.id_pt === 2 ? 'PT. Kumala Sukses Abadi' : 'PT. Kumala Sukses Bersama',
        onChange: (e) => {
          this.id_pt = e.target.value
        }
      },
    ];
  }
}

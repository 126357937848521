import { getIdPerusahaan, simpleFetch, getCoverageArea } from "./api";
import store from "../redux/store";

export class ProdukMasterAPI {
  async getAll() {
    let coverage = store.getState().auth.coverage;
    let params = "id_perusahaan=";

    // if(coverage.length > 0){
    //   params += coverage.join("&id_perusahaan=");
    // } else {
    //   params += getIdPerusahaan();
    // }
    let res = await simpleFetch({
      url: "retail/produk_master",
      method: "GET",
    });
    return res;
  }

  async getSingle(id) {
    let res = await simpleFetch({
      url: "retail/produk_master?id=" + id,
      method: "GET",
    });
    return res;
  }

  async set(values) {
    let res = await simpleFetch({
      url: "retail/produk_master",
      method: "POST",
      body: {
        // id_perusahaan: getIdPerusahaan(),
        ...values,
      },
    });
    return res;
  }

  async edit(values) {
    let res = await simpleFetch({
      url: "retail/produk_master",
      method: "PUT",
      body: {
        // id_perusahaan: getIdPerusahaan(),
        ...values,
      },
    });
    return res;
  }

  async delete(id) {
    let res = await simpleFetch({
      url: "retail/produk_master",
      method: "DELETE",
      body: {
        id: id,
      },
    });
    return res;
  }

  async getCoverage() {
    let res = await simpleFetch({
      url: "retail/select/perusahaan?id_coverage=" + getCoverageArea(),
      method: "GET",
    });
    return res;
  }
}

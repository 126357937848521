export default class UsersModel
{
  constructor( users )
  {
    this[ 'id' ] = users[ 'id' ];
    this[ 'username' ] = users[ 'username' ];
    this[ 'email' ] = users[ 'email' ];
    this[ 'profile' ] = users[ 'profile' ];
    this[ 'nama' ] = users[ 'nama' ];
    this[ 'jabatan' ] = users[ 'jabatan' ];
    this[ 'handphone' ] = users[ 'handphone' ];
    this[ 'alamat' ] = users[ 'alamat' ];
  }
}
import { separatorHarga } from "../../services/separator-harga";

export default class CustomerLimitModel {
  constructor(
    data = {
      id: 0,
      id_perusahaan: 0,
      kode: "",
      nama: "",
      alamat: "",
      nama_sales: "",
      nama_organisasi: "",
      nilai_top: 0,
      nilai_limit: 0,
      nilai_limit_tersisa: 0,
      nilai_limit_terpakai: 0,
      id_sales: 0,
      id_organisasi: 0,
    }
  ) {
    this.datas = {
      id: data.id,
      id_perusahaan: data.id_perusahaan,
      kode: data.kode,
      nama: data.nama,
      alamat: data.alamat,
      nama_sales: data.nama_sales,
      nama_organisasi: data.nama_organisasi,
      nilai_top: data.nilai_top,
      nilai_limit: data.nilai_limit,
      nilai_limit_tersisa: data.nilai_limit_tersisa,
      nilai_limit_terpakai: data.nilai_limit_terpakai,
      id_sales: data.id_sales,
      id_organisasi: data.id_organisasi,
    }
  }

  async getEditable() {
    const {
      kode,
      nama,
      nama_sales,
      nama_organisasi,
      nilai_limit,
      nilai_limit_tersisa,
      nilai_limit_terpakai,
    } = this.datas;
    return [
      {
        dataField: "kode",
        label: "Kode",
        value: kode,
        type: 'disabled-text'
      },
      {
        dataField: "nama",
        label: "Nama",
        value: nama,
      },
      {
        dataField: "nama_sales",
        label: "Nama Sales",
        value: nama_sales,
      },
      {
        dataField: "nama_organisasi",
        label: "Nama Organisasi",
        value: nama_organisasi,
      },
      {
        dataField: "nilai_limit",
        label: "Nilai Limit",
        value: separatorHarga(nilai_limit.toString(), "Rp. "),
      },
      {
        dataField: "nilai_limit_terpakai",
        label: "Limit Terpakai",
        value: separatorHarga(nilai_limit_terpakai.toString(), "Rp. "),
      },
      {
        dataField: "nilai_limit_tersisa",
        label: "Limit Tersisa",
        value:  nilai_limit_tersisa <= 0 ?
        `- Rp. ${separatorHarga(nilai_limit_tersisa.toString())}` :
        separatorHarga(nilai_limit_tersisa.toString(), "Rp. "),
      },
    ];
  }
}

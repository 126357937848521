import React from "react";
import {
  CardBody,
  CardHeader,
  CardFooter,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TableWithoutPagination } from "../../../components/table/Table";
import { Modal, Button, Alert } from "react-bootstrap";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Fields } from "../../../components/fields/Fields";
import { Typography } from "@material-ui/core";
import { dispatcher } from "../../../redux/dispatcher";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import { Link } from "react-router-dom";
import { PenjualanAPI } from "../../../api/penjualan";
import PenjualanDetailModel from "../../../models/penjualan-detail-model/PenjualanDetailModel";
import { PesananPenjualanAPI } from "../../../api/pesanan-penjualan";
import InvoicePenjualanDetailModel from "../../../models/invoice-penjualan-model/InvoicePenjualanDetailModel";
import moment from "moment";

export default class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.initialData = dispatcher.pesanan_penjualan.get();
    this.initialData.master.tanggal_invoice = moment(new Date()).format("YYYY-MM-DD");
    this.initialDetailData = new InvoicePenjualanDetailModel();
    this.apiPO = new PesananPenjualanAPI();
    this.api = new PenjualanAPI();
    this.simpleTableModel = new SimpleTableModel();
    this.pageName = "Tambah Invoice Penjualan";
    this.stokClear = this.initialData.detail.map((data) => data.persediaan_total < 0);
    this.isEdit = props.isEdit;
    this.state = {
      modal: {
        show: false,
        initialDetailData: this.initialDetailData,
        isEdit: true,
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
    this.columntable = [{ dataField: "", text: "" }];
    this.datatable = [{ id: 0, nama: "" }];
    this.fields = [];
    this.validation = Yup.object().shape({});
    this.detailFields = [];
    this.detailValidation = Yup.object().shape({});
  }

  async componentDidMount() {
    await this.loadFields();
    await this.loadDetail();
    let yup = {};
    await this.initialData.getEditable().then((values) => {
      values.map((item) => {
        return yup[item.dataField] = item.validation;
      });
    });
    this.validation = Yup.object().shape(yup);

    yup = {};
    await this.state.modal.initialDetailData.getEditable().then((values) => {
      values.map((item) => {
        return yup[item.dataField] = item.validation;
      });
    });
    this.detailValidation = Yup.object().shape(yup);
    this.setState({ ...this.state });
  }

  async loadFields() {
    this.fields = [];
    await this.initialData
      .getEditable({ pageName: this.pageName, isForm: true })
      .then((values) => {
        values.map((item, i) => {
          return (this.fields = [...this.fields, item]);
        });
      });
    this.setState({ ...this.state });
  }

  async loadDetail() {
    let datas = this.initialData.detail;
    this.simpleTableModel = new SimpleTableModel({ readOnly: true });
    datas.map((item, i) => {
      return item.status === 1 ?
        this.simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: item,
          actions: (
            <span>
              <Button
                size="sm"
                className="mx-1"
                variant="outline-primary"
                onClick={async () => await this.handleModal.edit(item)}
              >
                Edit
              </Button>
              {/* {["admin"].includes(this.role) && ( */}
              <Button
                size="sm"
                className="mx-1"
                variant="outline-danger"
                onClick={() => {
                  this.handleAlert.show(item);
                }}
              >
                Hapus
              </Button>
              {/* )} */}
            </span>
          )
        }) : ""
    });

    this.datatable = await this.simpleTableModel.getDatatables();
    this.columntable = await this.simpleTableModel.getColumnHeaders();
    this.setState({ ...this.state });
  }

  handleModal = {
    loadDetailFields: async () => {
      this.detailFields = [];
      await this.state.modal.initialDetailData
        .getEditable({ pageName: this.pageName, isForm: true })
        .then((values) => {
          values.map((item, i) => {
            // console.log('cek loadDetailFields',item);
            return (this.detailFields = [...this.detailFields, { ...item }]);
          });
        });
    },
    tambah: async () => {
      let state = { ...this.state };
      state.modal.initialDetailData = new PenjualanDetailModel();
      state.modal.show = true;
      state.modal.title = `Item Baru`;
      state.modal.isEdit = false;
      await this.handleModal.loadDetailFields();
      this.setState({ ...state });
    },
    edit: async (dataFromModel) => {
      let state = { ...this.state };
      state.modal.initialDetailData = new PenjualanDetailModel({
        ...dataFromModel.nulldata,
        ...dataFromModel,
      });
      state.modal.show = true;
      state.modal.title = `Edit Item`;
      state.modal.isEdit = true;
      await this.handleModal.loadDetailFields();
      this.setState({ ...state });
    },
    hide: () => {
      let modal = { ...this.state.modal };
      modal.show = false;
      this.setState({ modal });
    },
  };

  handleAlert = {
    show: (id) => {
      let alert = { ...this.state.alert };
      alert.show = true;
      alert.activeId = id;
      this.setState({ alert: alert });
    },
    hide: () => {
      let alert = { ...this.state.alert };
      alert.show = false;
      alert.activeId = null;
      this.setState({ alert: alert });
    },
    confirmed: async () => {
      await this.initialData.handleDetail.del(this.state.alert.activeId);
      this.handleAlert.hide();
      this.loadDetail();
    },
  };

  render() {
    return (
      <>
        <Modal
          show={this.state.alert.show}
          onHide={() => this.handleAlert.hide()}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Hapus Data Ini?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Anda yakin ingin menghapus data ini?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleAlert.hide()}>
              Batal
            </Button>
            <Button
              variant="warning"
              onClick={() => this.handleAlert.confirmed()}
            >
              Hapus
            </Button>
          </Modal.Footer>
        </Modal>

        <Formik
          enableReinitialize={true}
          initialValues={this['initialData']['master']}
          validator={this['validation']}
          onSubmit={async (values) => {
            this['initialData'].removeSeparator();
            this['initialData'].removeAllDetailSeparator();
            let sendData = this['initialData'];
            let masterApprove = (sendData['master']['status_invoice'] = 1);
            if ("is_approved" in sendData) {
              sendData = {
                ...this['initialData']['detail'],
                ...this['initialData']['master'],
                masterApprove,
              };
            }
            // Hapus alokasi,ubah status_invoice,buat invoice
            Promise.all([
              await this.api.set(this['initialData']),
              await this.apiPO.batalAlokasi(sendData)
            ]).then(() => {
              dispatcher['snackbar'].show("Berhasil", "Data berhasil disimpan.", "primary");
            }).catch(err => {
              console.log(err);
            })
          }}>
          {({ handleSubmit, setFieldValue }) => (
            <AnimatedCard>
              <CardHeader title={this.pageName}>
                <CardHeaderToolbar>
                  <Link
                    className="mx-1 btn btn-outline-success btn-sm"
                    to="/tambah-penjualan/list"
                    onClick={() => {

                    }}
                  >
                    Buat Invoice Baru
                  </Link>
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                {this.stokClear.includes(true) ? (
                  <Alert variant="danger">
                    Terdapat item yang QTY diminta lebih besar dari stok yang ada di gudang
                  </Alert>
                ) : ""}
                <Form className="form form-label-right">
                  <div className="row">
                    {this.fields.map((field, i) => (
                      ['nama_customer', 'nama_organisasi', 'tanggal_invoice', 'jatuh_tempo', 'no_transaksi', 'nama_sales'].includes(field.dataField)
                        ?
                        <div key={i} className="form-group col-lg-6">
                          <Fields
                            {...field}
                            type="disabled-text"
                            setFieldValue={setFieldValue}
                          />
                        </div>
                        : ['jenis_pembayaran'].includes(field.dataField)
                          ?
                          <div key={i} className="form-group col-lg-6">
                            <Fields
                              {...field}
                              disabled={true}
                            />
                          </div> : ""
                    ))}
                  </div>
                  <span className="d-flex align-items-center justify-content-between mt-4">
                    <Typography variant="h6">Detail Item</Typography>

                    {/* <button
                  type="button"
                  className="btn btn-outline-primary btn-sm"
                  onClick={async () =>
                    await this.handleModal.tambah()
                  }
                >
                  <i className="fa fa-plus"></i>
                  Tambah Item
                </button> */}
                  </span>
                  <TableWithoutPagination
                    data={this.datatable}
                    columns={this.columntable}
                  />
                  <div className="d-flex row" style={{ marginLeft: "40%", marginTop: "20px" }}>
                    {this.fields.map((field, i) => (
                      !['nama_customer', 'nama_organisasi', 'tanggal_invoice', 'jatuh_tempo', 'no_transaksi', 'jenis_pembayaran', 'nama_sales'].includes(field.dataField)
                        ?
                        <div key={i} className="form-group col-md-4 justify-content-around">
                          <Fields
                            {...field}
                            type="disabled-text"
                            setFieldValue={setFieldValue}
                          />
                        </div> : ""
                    ))}
                  </div>
                </Form>
              </CardBody>
              {console.log(this.stokClear)}
              <CardFooter>
                {this.stokClear.includes(true) ? "" :
                  (<div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-primary mx-1"
                      onClick={async () => {
                        handleSubmit();
                        //   this.props.history.goBack();
                        // dispatcher.snackbar.show(
                        //   "Berhasil",
                        //   "Data berhasil ditambah",
                        //   "primary"
                        // );
                        // await this.loadFields();
                      }}
                    >
                      <i className="fa fa-save"></i>
                      Simpan
                    </button>
                  </div>)
                }
              </CardFooter>
            </AnimatedCard>
          )}
        </Formik>

        <Formik
          enableReinitialize={true}
          initialValues={this.state.modal.initialDetailData}
          validator={this.detailValidation}
          onSubmit={async (values, { resetForm }) => {
            // console.log(this.state.modal.initialDetailData);
            //  if(this.state.modal.initialDetailData.diskon_opsi === 2){
            //   this.state.modal.initialDetailData.typeDiskon = "disabled-text"
            //  }
            // await this.initialData.handleDetail.set(
            //   this.state.modal.initialDetailData
            // );

            await this.loadDetail();
            resetForm();
            this.handleModal.hide();
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Modal
              show={this.state.modal.show}
              onHide={() => this.handleModal.hide()}
              aria-labelledby="contained-modal-title-vcenter"
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modal.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  {this.detailFields.map((field, i) => {
                    return (
                      <div key={i} className="form-group col-lg-6">
                        <Fields {...field} setFieldValue={setFieldValue} />
                      </div>
                    );
                  })}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={this.handleModal.hide}
                  className="btn btn-light btn-elevate"
                >
                  Batal
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() =>
                    handleSubmit()
                  }
                  className="btn btn-primary btn-elevate"
                >
                  Simpan
                </button>
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      </>
    );
  }
}

import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { CardBody, CardHeader, CardHeaderToolbar } from "../../../../_metronic/_partials/controls";
import { simpleFetch } from "../../../api/api";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import { separatorHarga } from "../../../services/separator-harga";

function Detail({ data }) {
    const [pembayaran, setPembayaran] = useState([]);
    const [invoice, setInvoice] = useState([data]);
    const history = useHistory();

    const fetchDataHistory = async () => {
        try {
            await simpleFetch({
                url: `retail/limit-customer?id_perusahaan=${data.datas.id_perusahaan}&id_customer=${data.datas.id}`,
                method: "GET",
            }).then((res) => {
                console.log('RES DATA', res.data);
                return setPembayaran(res.data)
            })
        } catch (error) {
            console.error(error);
        }
    }

    // const fetchInvoice = async () => {
    //     try {
    //         await simpleFetch({
    //             url: `retail/penjualan-customer?id_perusahaan=${data.id_perusahaan}&id_customer=${data.id_customer}`,
    //             method: "GET",
    //         }).then((res) => setInvoice(res.data))
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

    useEffect(() => {
        console.log(data.datas.id_perusahaan);
        fetchDataHistory()
        // fetchInvoice()
    }, [])

    console.log('Data', invoice);
    console.log('Pembayaran', pembayaran);
    return (
        <>
            <AnimatedCard>
                <CardHeader title={'Detail Limit Customer'}>
                    <CardHeaderToolbar>
                        <Button
                            type="button"
                            className="btn btn-secondary mx-1"
                            onClick={() => history.goBack()}
                        >
                            <i className="fa fa-arrow-left"></i>
                            Kembali
                        </Button>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <div className="row">
                        <div className="form-group col-lg-6">
                            <label>Kode</label>
                            <input type="text" className="form-control" name="tanggal" placeholder="Tanggal Invoice" autoComplete="off" disabled defaultValue={data.datas.kode} />
                        </div>
                        <div className="form-group col-lg-6">
                            <label>Nilai Limit</label>
                            <input type="text" className="form-control" name="jatuh_tempo" placeholder="Jatuh Tempo" autoComplete="off" disabled defaultValue={`Rp. ${separatorHarga(data.datas.nilai_limit.toString())}`} />
                        </div>
                        <div className="form-group col-lg-6">
                            <label>Nama Organisasi</label>
                            <input type="text" className="form-control" name="kode" placeholder="No Invoice" autoComplete="off" disabled defaultValue={data.datas.nama_organisasi} />
                        </div>
                        <div className="form-group col-lg-6">
                            <label>Nilai Limit Terpakai</label>
                            <input type="text" className="form-control" name="nama_supplier" placeholder="Supplier" autoComplete="off" disabled defaultValue={`Rp. ${separatorHarga(data.datas.nilai_limit_terpakai.toString())}`} />
                        </div>
                        <div className="form-group col-lg-6">
                            <label>Contact Person</label>
                            <input type="text" className="form-control" name="no_transaksi" placeholder="No Transaksi" autoComplete="off" disabled defaultValue={data.datas.nama} />
                        </div>
                        {data.datas.nilai_limit_tersisa <= 0 ? 
                        (<div className="form-group col-lg-6">
                            <label>Over Limit</label>
                            <input type="text" className="form-control bg-warning" name="deskripsi" placeholder="Sisa Limit" autoComplete="off" disabled defaultValue={
                                `Rp. ${separatorHarga(data.datas.nilai_limit_tersisa.toString())}`}/>
                        </div>) :
                       ( <div className="form-group col-lg-6">
                            <label>Nilai Limit Tersisa</label>
                            <input type="text" className="form-control" name="deskripsi" placeholder="Sisa Limit" autoComplete="off" disabled defaultValue={
                                `Rp. ${separatorHarga(data.datas.nilai_limit_tersisa.toString())}`}/>
                        </div>) }
                    </div>
                    <div className="table-responsive-xl">
                        <Table responsive="xl">
                            <thead className="table-primary">
                                <tr className="text-center">
                                    <th scope="col">No</th>
                                    <th scope="col">Tanggal</th>
                                    <th scope="col">No Transaksi</th>
                                    {/* <th scope="col">Nilai Limit</th> */}
                                    <th scope="col">Limit Terpakai</th>
                                    {/* <th scope="col">Limit Tersisa</th> */}
                                </tr>
                            </thead>
                            {invoice && invoice.length > 0 ? invoice.map((val, i) => (
                                // console.log(val),
                                <tbody key={i}>
                                    {/* <tr className="text-center">
                                        <td>{i + 1}</td>
                                        <td>{`-`}</td>
                                        <td>{`-`}</td>
                                        <td>Rp. {separatorHarga(val.datas.nilai_limit_terpakai.toString())}</td>
                                        <td>Rp. {separatorHarga(val.datas.nilai_limit.toString())}</td>
                                        <td>Rp. {separatorHarga(val.datas.nilai_limit_tersisa.toString())}</td>
                                    </tr> */}
                                    {pembayaran.length > 0 ? pembayaran.map((data, i, arr) => (
                                        console.log(data.master.total + (arr[i - 1] ? arr[i - 1].master.total : 0)),
                                        <tr key={data.master.no_transaksi} className="text-center" >
                                            <td>{(i + 1)}</td>
                                            <td>{data.master.tanggal}</td>
                                            <td>{data.master.no_transaksi}</td>
                                            {/* <td>Rp. {0}</td> */}
                                            <td>Rp. {separatorHarga(data.master.total.toString())}</td>
                                            {/* <td>Rp. {
                                                separatorHarga((val.datas.nilai_limit - (data.master.total + (arr[i - 1] ? arr[i - 1].master.total : 0))).toString())
                                            }</td> */}
                                        </tr>
                                    ))
                                        :
                                        (
                                            <tr>
                                                <td colSpan={6}></td>
                                            </tr>
                                        )
                                    }
                                    {/* <tr className="text-center">
                                        <td colSpan="3"> {"Total"} </td>
                                        <td>Rp. {separatorHarga(val.master.terbayar.toString())}</td>
                                        <td>Rp. {separatorHarga(val.master.total.toString())}</td>
                                        <td>Rp. {separatorHarga(val.master.tersisa.toString())}</td>
                                    </tr> */}
                                </tbody>
                            )) : (
                                <tr>
                                    <th scope="row"></th>
                                    <td colSpan="4" className="text-center">Belum Ada Data Pembelian</td>
                                </tr>
                            )}
                        </Table>
                    </div>
                </CardBody>
            </AnimatedCard>
        </>
    );
}

export default Detail;
import React, { useState, useEffect } from "react";
import {
    CardBody,
    CardHeader,
    CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../../components/table/Table";
import { Button } from "react-bootstrap";
import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import store from "../../../../redux/store";
import KartuHutangModel from "../../../../models/kartu-hutang/KartuHutangModel";
import { PenerimaanAPI } from "../../../../api/penerimaan";
import SimpleTableModel from "../../../../models/simpletable-model/SimpleTableModel";
import { useHistory } from "react-router-dom";
import Select2 from "react-select";
import { saveAsExcel } from "./helperExportExcel";

export default function List(params) {
    const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
    const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
    const history = useHistory();
    const [dt,setDT] = useState();
    const [status, setStatus] = useState(0);
    const [cabang, setCabang] = useState(store.getState().auth.id_perusahaan);
    const [options, setOptions] = useState([]);

    let statusOpsi = ([
        { value: 0, label: "Outstanding" },
        { value: 1, label: "Done" }
    ])

    const coverage = async () => {
        let opsi = [];
        await new PenerimaanAPI().getCoverage().then((res) => {
            opsi = res.data.map((data) => ({
                label: data.text,
                text: data.text,
                value: data.id,
            }));
        });
        // options.push(options)
        setOptions(opsi);
    };
    // const [datas, setDatas] = useState()
    // const [curId, setId] = useState(store.getState().auth.id_perusahaan)
    // const searchParameter = useState(["kode", "nama_supplier", "no_invoice", "no_transaksi", "tanggal_invoice", "jatuh_tempo", "total", "terbayar", "tersisa", "sisa_jTempo"]);



    async function init(sendData) {
        let datas = await new PenerimaanAPI().getAllHutang(sendData);
        let simpleTableModel = new SimpleTableModel();
        let dtTemp = []
        datas.map((item, i) => {
            const actions = (dataModel) => {
                return (
                    <>
                        <Button
                            className="mx-1 btn btn-outline-success btn-sm"
                            onClick={() => {
                                params.setDataFromModel(dataModel);
                                history.push('/laporan/kartu-hutang/detail');
                            }}
                        >
                            Detail
                        </Button>
                    </>
                )
            }

            let dataModel = new KartuHutangModel(item.master);
            dtTemp = [...dtTemp, dataModel.master]
            simpleTableModel.add({
                id: i,
                no: i + 1,
                dataModel: dataModel,
                actions: actions(dataModel),
            });
        })
        setDT(dtTemp)
        setColumntable(await simpleTableModel.getColumnHeaders());
        setDatatable(await simpleTableModel.getDatatables());
    }

    const title = 'LaporanKartuHutang';
    const Export = () => (<Button onClick={(e) =>{
        console.log("ExportExcel",dt);
        saveAsExcel(dt, title)
    } }>
        <i className={"far fa-file-excel"}></i> {" Export .xls"}
    </Button>)

    useEffect(() => {
        coverage()
        let sendData = ({cabang, status})
        init(sendData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, cabang]);
    return (
        <>
            <AnimatedCard>
                <CardHeader title="Laporan Kartu Hutang">
                    <CardHeaderToolbar>
                        <Export/>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <div className="d-flex justify-content-between">
                        <div className="col-lg-4" style={{ marginTop: "20px" }}>
                            <label> Pilih Perusahaan </label>
                            <Select2
                                name={"Cabang"}
                                placeholder={" --- Pilih Perusahaan --- "}
                                size="sm"
                                options={options}
                                isClearable={false}
                                value={
                                    options
                                        ? options.find(
                                            (data) => data.value === cabang
                                        )
                                        : ""
                                }
                                onChange={(e) => {
                                    setCabang(e ? e.value : []);
                                    // setFieldValue("cabang", e ? e.value : "");
                                }}
                            />
                        </div>
                        <div className="col-xl-3" style={{ marginTop: "20px" }}>
                            <label>Status </label>
                            <Select2
                                name={"Status"}
                                placeholder={"--- Status ---"}
                                size="sm"
                                options={statusOpsi}
                                isClearable={false}
                                value={
                                    statusOpsi ? statusOpsi.find((data) => data.value === status)
                                    : ""
                                }
                                onChange={(e) => {
                                    setStatus( e ? e.value : []);
                                }}
                            />
                        </div>
                    </div>
                    <SimpleTable data={datatable} columns={columntable} />
                </CardBody>
            </AnimatedCard>
        </>
    );
}

class SimpleTableDataModel {
  constructor(data) {
    this.id = data.id;
    this.no = data.no;
    this.dataModel = data.dataModel;
    this.actions = data.actions;
  }

  async getDatatable(readOnly) {
    let object = {};
    object["id"] = this.id;
    object["no"] = this.no;
    if (!readOnly) object["actions"] = this.actions;
    await this.dataModel.getEditable().then((values) =>
      values.map((item) => {
        if (item.invisibleOnTable == null || item.invisibleOnTable == false) {
          object[item.dataField] = item.value;
        }
        return null;
      })
    );
    return object;
  }

  async getColumnHeaders(readOnly) {
    let outp = [];
    await this.dataModel.getEditable().then((values) =>
      values.map((item) => {
        if (item.invisibleOnTable == null || item.invisibleOnTable == false) {
          outp = [
            ...outp,
            {
              dataField: item.dataField,
              text: item.label,
              sort: true,
              classes: "text-nowrap",
            },
          ];
        }
      })
    );
    return readOnly
      ? [
          {
            dataField: "no",
            text: "No",
            classes: "text-nowrap",
            sort: true,
          },
          ...outp,
        ]
      : [
          {
            dataField: "no",
            text: "No",
            classes: "text-nowrap",
            sort: true,
          },
          ...outp,
          {
            dataField: "actions",
            text: "Actions",
            classes: "text-right pr-0 text-nowrap",
            headerClasses: "text-right pr-3",
            // style: {
            //   minWidth: "100px",
            // },
          },
        ];
  }
}

export default class SimpleTableModel {
  constructor(props) {
    this.datas = [];
    this.readOnly =
      props == null || props.readOnly == null ? false : props.readOnly;
  }

  add(newData) {
    this.datas = [...this.datas, new SimpleTableDataModel(newData)];
  }

  async getDatatables() {
    let outp = [];
    for (let i = 0; i < this.datas.length; i++) {
      outp = [
        ...outp,
        { id: i, ...(await this.datas[i].getDatatable(this.readOnly)) },
      ];
    }
    return outp;
  }

  async getColumnHeaders() {
    if (this.datas.length <= 0)
      return [
        {
          dataField: "no",
          text: "",
        },
        {
          dataField: "actions",
          text: "",
        },
      ];
    else return await this.datas[0].getColumnHeaders(this.readOnly);
  }
}

import { CardBody, CardFooter, CardHeader } from '../../../../_metronic/_partials/controls';
import React, { Component } from 'react';
import { AnimatedCard } from '../../../components/animated-card/animatedCard';
import DataTableModel from '../../../models/datatable-model/DataTableModel';
import { Formik } from 'formik';
import { FormsFields } from '../../../components/forms-fields/FormsFields';
import { ReactDatatableFooterDetails } from '../../../components/react-datatable-footer/reactDatatableFooter';
import { akuntansiAPI } from '../../../api/akuntansi';
import { separatorHarga } from '../../../services/separator-harga';
import { Button } from 'react-bootstrap';
import * as Yup from "yup";
import { ModalFormsDetailTable } from '../../../components/modal-forms/modalForms';
import ButtonLoad from '../../../components/button-loading/buttonLoad';
import clearingDetailModel from '../../../models/akuntansi/clearing/ClearingDetailModel ';
import { ReactDatatable } from '../../../components/react-datatable/reactDatatable';
import clearingModel from '../../../models/akuntansi/clearing/ClearingModel';
import { getIdPerusahaan } from '../../../api/api';
import SimpleAlert from '../../../components/alert/alert';
import { dispatcher } from '../../../redux/dispatcher';
import ButtonActions from '../../../components/button-actions/buttonActions';

export default class Clearing extends Component {
   constructor(props) {
      super(props);
      this['pageName'] = "Clearing";
      this['tableTitleName'] = "Nama Akun : ";
      this['searchParameter'] = ["tanggal", "tanggal_insert", "no_transaksi", "debet", "kredit"];
      this['apiClearing'] = new akuntansiAPI();
      this['initialDetailData'] = new clearingDetailModel();
      this['options_cabang'] = [];
      this['options_akun'] = [];
      this['state'] = {
         pending: false,
         toggledClearRows: false,
         rowSelectCritera: false,
         handleChange: true,
         showHideButton: false,
         showHideButtonBalance: false,
         data_select_rows: [],
         initialForms: {},
         initialValues: {
            start_date: new Date("01-01-2020"),
            end_date: new Date(),
            id_perusahaan: getIdPerusahaan(),
            kode_akun: "",
            nama_akun: "",
         },
         data: [],
         footerClearingDetail: {
            kode_akun: "Total",
            debet: 0,
            kredit: 0,
         },
         modal: {
            show: false,
            title: "",
         },
         alert: {
            activeId: {
               no_clearing: null,
            },
            show: false,
         },
      }
      this['columntableClearing'] = [{ id: "", title: "" }];
      this['datatableClearing'] = [{ id: "", no: "", name: "" }];
      this['columntableClearingDetail'] = [{ id: "", title: "" }];
      this['datatableClearingDetail'] = [{ id: "", no: "", name: "" }];
   }
   //* ----------------------------------------------------------------------------- */
   //** Start: Component */
   async componentDidMount() {
      // Cabang
      this['apiClearing'].getCoverage().then((res) => {
         this['options_cabang'] = res['data'].map((data) => ({
            label: data['text'],
            value: data['id'],
         }));
      });
      // Akun
      this['apiClearing'].getakun().then((res) => {
         this['options_akun'] = res['data'].map((data) => ({
            label: data['text'],
            value: data['kode_akun'],
            nama_akun: data['nama_akun'],
            id_akun: data['id'],
         }));
      });
      await this.LoadData(this['state']['initialValues']);
   }
   //** End: Component */
   //* ----------------------------------------------------------------------------- */
   //** Start: Load Data Clearing */
   async LoadData(form) {
      this.setState({
         pending: true,
         initialForms: form
      });
      let data = await this['apiClearing'].getAllClearing(form);
      this.setState({ data: data['data'] });
      this['DataTableModel'] = new DataTableModel();
      data['data'].map((item, i) => {
         let dataModel = new clearingModel(item);
         this.setState({ showHideButtonBalance: false });
         if (item['buku_besar']['status'] === 1) {
            this['DataTableModel'].add({
               id: item['buku_besar']['id'],
               no: i + 1,
               dataModel: dataModel,
               actions: (
                  <>
                     <ButtonActions
                        label={"details"}
                        onClick={async () =>
                           await this['handleModal'].show(dataModel)}
                     />
                  </>
               ),
            });
         }
         return item;
      });
      this['columntableClearing'] = await this['DataTableModel'].getColumn();
      this['datatableClearing'] = await this['DataTableModel'].getDatas();
      this.setState({
         ...this['state'],
         pending: false,
      });
   }
   async LoadDataBelumBalance(form) {
      this.setState({
         pending: true,
         showHideButtonBalance: true,
         initialForms: form
      });
      let data = await this['apiClearing'].getAllClearing(form);
      this.setState({ data: data['data'] });
      this['DataTableModel'] = new DataTableModel();
      data['data'].map((item, i) => {
         let dataModel = new clearingModel(item);
         if (item['buku_besar']['status'] === 1 && item['buku_besar']['debet'] !== item['buku_besar']['kredit']) {
            this['DataTableModel'].add({
               id: item['buku_besar']['id'],
               no: i + 1,
               dataModel: dataModel,
               actions: (
                  <>
                     <Button
                        size={"sm"}
                        className={"mx-1"}
                        variant={"outline-primary"}
                        onClick={async () => {
                           this['handleModal'].show(dataModel);
                        }}
                     > Details
                     </Button>
                  </>
               ),
            });
         }
         return item;
      });
      this['columntableClearing'] = await this['DataTableModel'].getColumn();
      this['datatableClearing'] = await this['DataTableModel'].getDatas();
      this.setState({
         ...this['state'],
         pending: false,
      });
   }
   //** End: Load Data Clearing */
   //* ----------------------------------------------------------------------------- */
   //** Start: Handle Alert */
   handleModal = {
      LoadDataDetail: async (detail) => {
         this['DataTableModel'] = new DataTableModel({ readOnly: true });
         detail['detail'].map((item, i) => {
            this['DataTableModel'].add({
               id: item['id'],
               no: i + 1,
               dataModel: item,
               actions: null,
            });
            return item;
         });
         const totalList = (val) => {
            if (val === 'debet') {
               return detail['detail'].reduce((total, value) => {
                  const debet = parseInt(value['debet']);
                  return parseFloat(total + debet);
               }, 0);
            } else {
               return detail['detail'].reduce((total, value) => {
                  const kredit = parseInt(value['kredit']);
                  return parseFloat(total + kredit);
               }, 0);
            }
         };
         this['columntableClearingDetail'] = await this['DataTableModel'].getColumn();
         this['datatableClearingDetail'] = await this['DataTableModel'].getDatas();
         this.setState({
            ...this.state,
            footerClearingDetail: {
               kode_akun: "Total",
               debet: totalList("debet") < 0 ? `Rp. -${separatorHarga(totalList("debet").toString())}` : separatorHarga(totalList("debet").toString(), "Rp. "),
               kredit: totalList("kredit") < 0 ? `Rp. -${separatorHarga(totalList("kredit").toString())}` : separatorHarga(totalList("kredit").toString(), "Rp. "),
            }
         });
      },
      show: async (detail) => {
         await this['handleModal'].LoadDataDetail(detail);
         this.setState({
            modal: {
               show: true,
               title: "Detail Buku Besar Clearing"
            }
         });
      },
      hide: () => {
         this.setState({
            modal: {
               show: false,
               title: ""
            }
         });
      },
   };

   handleAlert = {
      show: (no_clearing) => {
         let alert = { ...this['state'] };
         alert = {
            alert: {
               show: true,
               activeId: {
                  no_clearing: no_clearing,
               }
            }
         }
         this.setState({ ...alert });
      },
      hide: () => {
         let alert = { ...this['state'] };
         alert = {
            alert: {
               show: false,
               activeId: {
                  no_clearing: null
               }
            }
         }
         this.setState({ ...alert });
      },
      confirmed: async () => {
         await this['apiClearing'].setClearing(this['state']['alert']['activeId']).then((res) => {
            if (res['status']) {
               dispatcher['snackbar'].show("Berhasil", res['message'], "primary");
               this['handleAlert'].hide();
               this.LoadData(this['state']['initialForms']);
               this.setState({
                  toggledClearRows: !this['state']['toggledClearRows'],
                  showHideButton: false,
               });
            } else {
               return dispatcher['snackbar'].show("Gagal", res['message'], "danger");
            }
         });
      },
   };
   //** End: Handle Alert */
   render() {
      const { pending, initialValues, modal, toggledClearRows, showHideButton, data_select_rows, data, initialForms, showHideButtonBalance, alert } = this['state'];
      // ContexAction Select Bersihkan
      const handleClearingSelectRows = (() => {
         let no_transaksi = [];
         data_select_rows.forEach(val => {
            no_transaksi.push(val['no_transaksi']);
         });
         this['handleAlert'].show(no_transaksi);
      });
      // Select All Data In Table
      const handleChange = ({ selectedRows }) => {
         this.setState({
            showHideButton: selectedRows.length > 0,
            rowSelectCritera: false,
            data_select_rows: selectedRows
         });
      };
      const rowSelectCritera = (row) => {
         this.setState({
            rowSelectCritera: false,
            showHideButton: true
         });
      };
      // Filter Show Data Belum Balance
      const handleShowHideBalance = async () => {
         await this.LoadDataBelumBalance(initialForms);
         this.setState({
            rowSelectCritera: false,
         });
      }
      const HandleShowAllData = async () => {
         await this.LoadData(initialForms);
      }
      return (
         <>
            {/* Start: Formik Buku Besar List */}
            <Formik
               enableReinitialize={true}
               initialValues={initialValues}
               validationSchema={Yup.object().shape({
                  start_date: Yup.string().nullable().required(),
                  end_date: Yup.string().nullable().required(),
                  kode_akun: Yup.string().required("Akun harus diisi"),
               })}
               onSubmit={(values) => {
                  this.LoadData(values);
                  this.setState({
                     toggledClearRows: !toggledClearRows
                  });
               }}
            >
               {({ setFieldValue, setFieldTouched, touched, errors, values, handleSubmit }) => (
                  <AnimatedCard>
                     <CardHeader title={this.pageName}></CardHeader>
                     <CardBody style={{ marginTop: "-20px" }}>
                        <div className={"form-group row"}>
                           {/* Forms Start Date  */}
                           <FormsFields
                              id={"start_date"}
                              type={"DatePicker"}
                              label={"Tanggal Mulai"}
                              className={"col-lg-4"}
                              style={{ marginTop: "20px" }}
                              paramsFormik={{
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              }}
                           />
                           {/* Forms End Date  */}
                           <FormsFields
                              id={"end_date"}
                              type={"DatePicker"}
                              label={"Tanggal Akhir"}
                              className={"col-lg-4"}
                              style={{ marginTop: "20px" }}
                              paramsFormik={{
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              }}
                           />
                           {/* Forms Select Cabang */}
                           <FormsFields
                              id={"id_perusahaan"}
                              type={"select2"}
                              label={"Cabang"}
                              style={{ marginTop: "20px" }}
                              className={"col-lg-4"}
                              isClearable={false}
                              isSearchable={true}
                              options={this['options_cabang']}
                              paramsFormik={{
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              }}
                           />
                           {/* Forms Select Akun */}
                           <FormsFields
                              id={"kode_akun"}
                              type={"select2"}
                              label={"Akun"}
                              style={{ marginTop: "20px" }}
                              className={"col-lg-4"}
                              isClearable={true}
                              isSearchable={true}
                              options={this['options_akun']}
                              onChange={(e) => {
                                 setFieldValue("nama_akun", (e ? e['nama_akun'] : "Semua Akun"));
                              }}
                              paramsFormik={{
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              }}
                           />
                        </div>
                        {/* Button */}
                        <div style={{ display: "flex", justifyContent: "center" }} >
                           {/* Button Lihat Data */}
                           <ButtonLoad
                              label={"Lihat Data"}
                              pending={pending}
                              classNameIcon={"fa fa-filter"}
                              className={"btn btn-outline-success"}
                              onClick={async () => handleSubmit()}
                           />
                        </div>
                     </CardBody>
                     <CardFooter>
                        {data.length > 0 ? (
                           <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }} >
                              <div className={"form-group row"}>
                                 {showHideButton ? (
                                    <>
                                       <Button variant={"outline-danger"} className={"btn-sm mx-1"} onClick={handleClearingSelectRows} >
                                          <i className={"fa fa-eraser"}></i> {"Kembalikkan ke Buku Besar"}
                                       </Button>
                                    </>
                                 ) : (showHideButtonBalance ? (
                                    <>
                                       <Button variant={"outline-info"} className={"btn-sm mx-1"} onClick={HandleShowAllData}>
                                          <i className={"fas fa-eye-slash"}></i> {"Tampilkan semua data"}
                                       </Button>
                                    </>
                                 ) : (
                                    <>
                                       <Button variant={"outline-info"} className={"btn-sm mx-1"} onClick={handleShowHideBalance}>
                                          <i className={"fas fa-eye"}></i> {"Tampilkan data yang belum Balance"}
                                       </Button>
                                    </>))}
                              </div>
                           </div>
                        ) : ""}
                        <ReactDatatable
                           title={this['tableTitleName'] + values['nama_akun']}
                           columns={this['columntableClearing']}
                           data={this['datatableClearing']}
                           searchParameter={this['searchParameter']}
                           progressPending={pending}
                           selectableRows={true}
                           selectableRowSelected={this['state']['rowSelectCritera'] ? rowSelectCritera : null}
                           onSelectedRowsChange={this['state']['handleChange'] ? handleChange : ""}
                           clearSelectedRows={toggledClearRows}
                        />
                     </CardFooter>
                  </AnimatedCard>
               )}
            </Formik>
            {/* End: Formik Buku Besar List */}
            {/* ----------------------------------------------------------------------------- */}
            {/* Start: Modal Detail Form */}
            <ModalFormsDetailTable
               show={modal['show']}
               title={modal['title']}
               onHide={this['handleModal']['hide']}
               content={
                  <ReactDatatableFooterDetails
                     title={""}
                     data={this['datatableClearingDetail']}
                     columns={this['columntableClearingDetail']}
                     footer={this.state['footerClearingDetail']}
                  />
               }
            />
            {/* End: Modal Detail Form */}
            {/* ----------------------------------------------------------------------------- */}
            {/* Start: Alert Approved */}
            <SimpleAlert
               title={"Peringatan!"}
               content={"Yakin ingin kembalikan data Clearing ke Buku Besar...?"}
               show={alert['show']}
               onHide={() => this['handleAlert'].hide()}
               variant={"primary"}
               label={"Ya"}
               labelHide={"Tidak"}
               onConfirmed={this['handleAlert']['confirmed']}
            />
            {/* End: Alert Approved */}
         </>
      );
   }
}
import moment from "moment/moment";
import { separatorHarga } from "../../../services/separator-harga";
import clearingDetailModel from "./ClearingDetailModel ";
export default class clearingModel {
  constructor(
    data = {
      buku_besar: {
        id: 0,
        id_clearing: 0,
        tanggal: "",
        tanggal_insert: "",
        no_transaksi: "",
        bank: "",
        debet: 0,
        kredit: 0,
        status: 0,
      },
      detail: []
    }
  ) {
    const { id, id_clearing, tanggal, tanggal_insert, no_transaksi, debet, kredit, status } = data['buku_besar'];
    this['buku_besar'] = {
      id: id,
      id_clearing: id_clearing,
      tanggal: tanggal,
      tanggal_insert: tanggal_insert,
      no_transaksi: no_transaksi,
      debet: debet,
      kredit: kredit,
      status: status,
    };
    this['detail'] = [];
    this.initDetails(data['detail']);
  }
  initDetails(detail) {
    if (detail.length > 0) {
      detail.forEach((item) => {
        this['detail'] = [
          ...this['detail'],
          new clearingDetailModel({
            ...item,
          }),
        ];
      });
    }
  }
  async getdata() {
    const { tanggal, tanggal_insert, no_transaksi, debet, kredit } = this['buku_besar'];
    return [{
      id: "tanggal",
      title: "Tanggal",
      label: moment(tanggal).format("DD-MM-YYYY"),
      center: true,
      sortable: true,
      minWidth: "12%",
    }, {
      id: "tanggal_insert",
      title: "Tanggal Insert",
      label: moment(tanggal_insert).format("DD-MM-YYYY"),
      center: true,
      sortable: true,
      minWidth: "12%",
    }, {
      id: "no_transaksi",
      title: "No. Transaksi",
      label: no_transaksi,
      center: true,
      sortable: true,
      minWidth: "20%",
    }, {
      id: "debet",
      title: "Debet",
      label: debet < 0 ? `Rp. -${separatorHarga(debet.toString())}` : separatorHarga(debet.toString(), "Rp. "),
      conditionalCellStyles: [{
        when: row => row['debet'] !== row['kredit'],
        style: {
          backgroundColor: 'rgba(245, 0, 25, 1)',
          color: 'white',
          '&:hover': {
            cursor: 'pointer',
          },
        },
      }],
      right: true,
      sortable: false,
      minWidth: "13%",
    }, {
      id: "kredit",
      title: "Kredit",
      label: kredit < 0 ? `Rp. -${separatorHarga(kredit.toString())}` : separatorHarga(kredit.toString(), "Rp. "),
      conditionalCellStyles: [{
        when: row => row['kredit'] !== row['debet'],
        style: {
          backgroundColor: 'rgba(245, 0, 25, 1)',
          color: 'white',
          '&:hover': {
            cursor: 'pointer',
          },
        },
      }],
      right: true,
      sortable: false,
      minWidth: "13%",
    }];
  }
}

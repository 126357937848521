import * as Yup from "yup";
import store from "../../redux/store";
import { separatorHarga, hapusSeparator } from "../../services/separator-harga";

export default class SupplierModel {
  constructor(
    data = {
      id: 0,
      id_perusahaan_login: store.getState().auth.id_perusahaan,
      nama: "",
      kode: "",
      alamat: "",
      hp: "",
      telepon: "",
      no_rekening: "",
      nama_rekening: "",
      nama_bank: "",
      npwp: "",
      nilai_limit: 0,
      nilai_top:0,
    }
  ) {
    this.id = data.id;
    this.id_perusahaan_login = data.id_perusahaan_login;
    this.nama = data.nama;
    this.kode = data.kode;
    this.alamat = data.alamat;
    this.hp = data.hp;
    this.telepon = data.telepon;
    this.no_rekening = data.no_rekening;
    this.nama_rekening = data.nama_rekening;
    this.nama_bank = data.nama_bank;
    this.npwp = data.npwp;
    this.nilai_top = data.nilai_top;
    this.nilai_limit = separatorHarga(
      data.nilai_limit == null ? "0" : data.nilai_limit.toString()
    );
  }

  removeSeparator() {
    this.nilai_limit = parseInt(hapusSeparator(this.nilai_limit));
  }

  async getEditable(params = { pageName: "", isForm: false }) {
    return [
      {
        validation: Yup.string().required("Nama harus diisi"),
        dataField: "nama",
        columnHeaderText: "Nama Kategori Produk",
        label: "Nama " + params.pageName,
        type: "text",
        value: this.nama,
      },
      {
        dataField: "kode",
        columnHeaderText: "Kode Supplier",
        label: "Kode " + params.pageName,
        type: "disabled-text",
        value: this.kode,
      },
      {
        validation: Yup.string().required("Alamat harus diisi"),
        dataField: "alamat",
        columnHeaderText: "Alamat",
        label: "Alamat " + params.pageName,
        type: "text",
        value: this.alamat,
      },
      {
        validation: Yup.string().required("No. HP harus diisi"),
        dataField: "telepon",
        label: "Telepon",
        type: "text",
        value: this.telepon,
      },
      {
        validation: Yup.string(),
        dataField: "no_rekening",
        columnHeaderText: "No. Rekening",
        label: "No. Rekening " + params.pageName,
        type: "text",
        value: this.no_rekening,
      },
      {
        validation: Yup.string(),
        dataField: "nama_rekening",
        columnHeaderText: "Nama Rekening",
        label: "Nama Rekening " + params.pageName,
        type: "text",
        value: this.nama_rekening,
      },
      {
        validation: Yup.string(),
        dataField: "nama_bank",
        columnHeaderText: "Nama Bank",
        label: "Nama Bank " + params.pageName,
        type: "text",
        value: this.nama_bank,
      },
      {
        validation: Yup.string(),
        dataField: "npwp",
        columnHeaderText: "NPWP",
        label: "NPWP " + params.pageName,
        type: "text",
        value: this.npwp,
      },
      {
        validation: Yup.string(),
        dataField: "nilai_top",
        columnHeaderText: "T.O.P",
        label: "T.O.P",
        type: "number",
        value: `${this.nilai_top} hari`,
      },
      // {
      //   validation: Yup.string(),
      //   dataField: "nilai_limit",
      //   columnHeaderText: "Limit",
      //   label: "Limit " + params.pageName,
      //   type: "currency",
      //   value: this.nilai_limit,
      // },
    ];
  }
}

import { currencyFormatter, formatNpwp, separatorHarga } from "../../../services/separator-harga";

export default class OrganisasiModel {
  constructor(
    data = {
      id: 0,
      kode: "",
      nama: "",
      alamat: "",
      nama_rekening: "",
      no_rekening: "",
      nama_bank: "",
      npwp: "",
      telepon: "",
      nilai_top: "",
      nilai_limit: "",
      nilai_limit_tersisa: "",
      nilai_limit_terpakai: "",
      file_ktp: "",
      file_npwp: "",
      file_situ_siup: "",
      file_pks: "",
      status: 0,
    }
  ) {
    const { id, kode, nama, alamat, nama_rekening, no_rekening, nama_bank, npwp, telepon, nilai_top, nilai_limit, nilai_limit_tersisa, nilai_limit_terpakai, file_ktp, file_npwp, file_situ_siup, file_pks } = data;
    this['id'] = id;
    this['kode'] = kode;
    this['nama'] = nama;
    this['alamat'] = alamat;
    this['nama_rekening'] = nama_rekening;
    this['no_rekening'] = no_rekening;
    this['nama_bank'] = nama_bank;
    this['nilai_top'] = nilai_top;
    this['nilai_limit'] = nilai_limit;
    this['nilai_limit_tersisa'] = nilai_limit_tersisa;
    this['nilai_limit_terpakai'] = nilai_limit_terpakai;
    this['telepon'] = telepon;
    this['npwp'] = formatNpwp(npwp);
    this['file_ktp'] = file_ktp;
    this['file_npwp'] = file_npwp;
    this['file_situ_siup'] = file_situ_siup;
    this['file_pks'] = file_pks;
  }

  async getdata() {
    const { kode, nama, alamat, telepon, nama_bank, nama_rekening, no_rekening, npwp, nilai_top, nilai_limit, nilai_limit_tersisa, nilai_limit_terpakai } = this;
    return [{
      id: "kode",
      title: "Kode",
      label: kode,
      center: true,
      sortable: true,
      minWidth: "12%",
    }, {
      id: "nama",
      title: "Nama",
      label: nama,
      sortable: true,
      minWidth: "25%",
    }, {
      id: "alamat",
      title: "Alamat",
      label: alamat,
      sortable: true,
      minWidth: "35%",
    }, {
      id: "telepon",
      title: "Telepone",
      label: telepon,
      center: true,
      sortable: true,
      minWidth: "15%",
    }, {
      id: "nama_bank",
      title: "Nama Bank",
      label: nama_bank,
      center: true,
      sortable: true,
      minWidth: "10%",
    }, {
      id: "nama_rekening",
      title: "Nama Rekening Pemilik",
      label: nama_rekening,
      sortable: true,
      minWidth: "25%",
    }, {
      id: "no_rekening",
      title: "No. Rekening",
      label: no_rekening,
      center: true,
      sortable: true,
      minWidth: "16%",
    }, {
      id: "npwp",
      title: "NPWP",
      label: npwp,
      center: true,
      sortable: true,
      minWidth: "16%",
    }, {
      id: "nilai_top",
      title: "Limit T-O-P",
      label: `${nilai_top} Hari`,
      center: true,
      sortable: true,
      minWidth: "10%",
    }, {
      id: "nilai_limit",
      title: "Nilai Limit",
      label: separatorHarga(nilai_limit.toString(), "Rp. "),
      center: true,
      sortable: true,
      minWidth: "10%",
    }, {
      id: "nilai_limit_terpakai",
      title: "Nilai Limit Terpakai",
      label: separatorHarga(nilai_limit_terpakai.toString(), "Rp. "),
      center: true,
      sortable: true,
      minWidth: "12%",
    }, {
      id: "nilai_limit_tersisa",
      title: "Nilai Limit Tersisa",
      label: currencyFormatter(nilai_limit_tersisa),
      // label: separatorHarga(nilai_limit_tersisa.toString(), "Rp. "),
      center: true,
      sortable: true,
      minWidth: "12%",
    }];
  }
}

import React, { useEffect, useState, useMemo } from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Fields } from "../../../components/fields/Fields";
import { Typography } from "@material-ui/core";
import { dispatcher } from "../../../redux/dispatcher";
import { OutboundDetail } from "./outbound_detail";
import { PenjualanAPI } from "../../../api/penjualan";
import PenjualanModel from "../../../models/penjualan-model/PenjualanModel";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import { ButtonValidasi } from "./button_validasi";
import StokKeluarModel from "../../../models/stok-keluar-model/StokKeluarModel";

export default function Outbound({ history }) {
  const [initialData, setInitialData] = useState(dispatcher.penjualan.get());
  const [fields, setFields] = useState([]);
  const [yup, setYup] = useState(Yup.object().shape({}));
  const invoice = initialData.master.kode === "" ? false : true;

  const loadFields = async () => {
    let fieldsLoaded = [];
    let yup = {};
    await initialData
      .getEditable({ pageName: "", isForm: true })
      .then((values) => {
        values.forEach((item, i) => {
          fieldsLoaded = [...fieldsLoaded, item];
          yup[item.dataField] = item.validation;
        });
      });
    setFields(fieldsLoaded);
    setYup(Yup.object().shape(yup));
  };

  const saveTransaksi = async (values) => {
    let send = {
      id: initialData.master.id,
      kode: values.kode,
      tanggal_invoice: initialData.master.tanggal,
      status_invoice: 2,
    };
    await new PenjualanAPI().edit(send).then((val) => {
      if (val.status)
        dispatcher.snackbar.show(
          "Berhasil",
          "No invoice berhasil disimpan",
          "primary"
        );
      else
        dispatcher.snackbar.show(
          "Gagal",
          "No invoice gagal disimpan",
          "danger"
        );
    });
  };

  const getList = useMemo(() => {
    return dispatcher.penjualan.get();
  }, [dispatcher.penjualan.get()]);

  useEffect(() => {
    setInitialData(getList);
    loadFields();
  }, [getList]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialData.master}
        validationSchema={yup}
      >
        {({ handleSubmit, setFieldValue, validateForm, values }) => (
          <AnimatedCard>
            <CardHeader title="Outbound">
              <CardHeaderToolbar>
                <button
                  type="button"
                  className="btn btn-secondary mx-1"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-arrow-left"></i>
                  Kembali
                </button>
                <ButtonValidasi dataModel={initialData} />
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              <Form className="form form-label-right">
                <div className="row">
                  {fields.length > 0 &&
                    fields.map((field, i) => (
                      <div key={i} className="form-group col-lg-6">
                        <Fields {...field} setFieldValue={setFieldValue} />
                      </div>
                    ))}
                </div>
              </Form>
              <span className="d-flex align-items-center justify-content-between mt-4">
                <Typography variant="h6">Detail Item</Typography>
              </span>
              <OutboundDetail
                data={initialData}
                validateForm={validateForm}
                handleSubmit={handleSubmit}
              />
            </CardBody>
          </AnimatedCard>
        )}
      </Formik>
    </>
  );
}

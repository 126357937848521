import React from "react";
import {
  getAllRoles,
  getSingleRole,
  delRole,
  getAllMenu,
  getAllMenuSingleRole,
} from "../../../api/roles";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import { Button } from "react-bootstrap";
import { ModalDialog } from "./ModalDialog";
import { ModalDialogEditMenu } from "./ModalDialogEditMenu";
import SimpleAlert from "../../../components/alert/alert";

const columns = [
  {
    dataField: "no",
    text: "No",
    sort: true,
  },
  {
    dataField: "nama",
    text: "Nama Role",
    sort: true,
  },
  {
    dataField: "action",
    text: "Actions",
    classes: "text-right pr-0",
    headerClasses: "text-right pr-3",
    style: {
      minWidth: "100px",
    },
  },
];

export default class Role extends React.Component {
  constructor(props) {
    super(props);

    this.initialData = {
      nama: "",
    };

    this.state = {
      data: [],
      modalPropsRole: {
        title: "",
        show: false,
        onHide: () => this.handleModalRole.hide(),
        initialData: this.initialData,
        isEdit: false,
      },
      modalPropsMenu: {
        title: "",
        show: false,
        onHide: () => this.handleModalMenu.hide(),
        onChange: (newData, newParent, childs) =>
          this.handleModalMenu.onChange(newData, newParent, childs),
        onChangeParent: (newData, childs) =>
          this.handleModalMenu.onChangeParent(newData, childs),
        initialData: {
          idRole: null,
          checked: [],
        },
        menus: [],
        isEdit: false,
      },
      activeId: null,
      alert: {
        title: "Hapus Data",
        content: "Anda yakin hapus data ini?",
        show: false,
        onHide: () => this.handleAlert.hide(),
        onConfirmed: () => this.handleAlert.confirmed(),
      },
    };

    this.handleModalRole.tambah = this.handleModalRole.tambah.bind(this);
    this.handleModalRole.edit = this.handleModalRole.edit.bind(this);
    this.handleModalRole.hide = this.handleModalRole.hide.bind(this);

    this.handleModalMenu.edit = this.handleModalMenu.edit.bind(this);
    this.handleModalMenu.hide = this.handleModalMenu.hide.bind(this);
    this.handleModalMenu.onChange = this.handleModalMenu.onChange.bind(this);
    this.handleModalMenu.onChangeParent = this.handleModalMenu.onChangeParent.bind(
      this
    );

    this.handleAlert.show = this.handleAlert.show.bind(this);
    this.handleAlert.hide = this.handleAlert.hide.bind(this);
    this.handleAlert.confirmed = this.handleAlert.confirmed.bind(this);
  }

  async componentDidMount() {
    await this.loadData();
  }

  async loadData() {
    let allRoles = await getAllRoles();
    let data = [];
    allRoles.data.map(
      (role, i) =>
        (data = [
          ...data,
          {
            id: role.id,
            no: i + 1,
            nama: role.nama,
            action: (
              <span>
                <Button
                  size="sm"
                  className="mx-1"
                  variant="outline-success"
                  onClick={() => {
                    this.handleModalMenu.edit(role.id);
                  }}
                >
                  Pilih Menu
                </Button>
                <Button
                  size="sm"
                  className="mx-1"
                  variant="outline-primary"
                  onClick={() => {
                    this.handleModalRole.edit(role.id);
                  }}
                >
                  Edit
                </Button>
                <Button
                  size="sm"
                  className="mx-1"
                  variant="outline-danger"
                  onClick={() => {
                    this.handleAlert.show(role.id);
                    this.loadData();
                  }}
                >
                  Hapus
                </Button>
              </span>
            ),
          },
        ])
    );
    this.setState({
      data: data,
    });
  }

  handleModalRole = {
    tambah: () => {
      let modalPropsRole = { ...this.state.modalPropsRole };
      modalPropsRole.show = true;
      modalPropsRole.title = "Role Baru";
      modalPropsRole.isEdit = false;
      this.loadData();
      this.setState({ modalPropsRole });
    },
    edit: async (idRole) => {
      let modalPropsRole = { ...this.state.modalPropsRole };
      modalPropsRole.initialData = (await getSingleRole(idRole)).data;
      modalPropsRole.show = true;
      modalPropsRole.title = "Edit Role";
      modalPropsRole.isEdit = true;
      this.loadData();
      this.setState({ modalPropsRole });
    },
    hide: () => {
      let modalPropsRole = { ...this.state.modalPropsRole };
      modalPropsRole.show = false;
      modalPropsRole.initialData = this.initialData;
      this.loadData();
      this.setState({ modalPropsRole });
    },
  };

  handleModalMenu = {
    edit: async (idRole) => {
      let menus = [];
      await getAllMenu().then((val) => {
        menus = val.data;
      });
      let modalPropsMenu = { ...this.state.modalPropsMenu };
      await getAllMenuSingleRole(idRole).then((checked) => {
        modalPropsMenu.initialData.checked = checked.data;
      });
      modalPropsMenu.initialData.idRole = idRole;
      modalPropsMenu.show = true;
      modalPropsMenu.title = "Pilih Menu";
      modalPropsMenu.menus = menus;
      modalPropsMenu.isEdit = true;
      this.loadData();
      this.setState({ modalPropsMenu });
    },
    hide: () => {
      let modalPropsMenu = { ...this.state.modalPropsMenu };
      modalPropsMenu.show = false;
      modalPropsMenu.editIdMenu = null;
      this.loadData();
      this.setState({ modalPropsMenu });
    },
    onChange: async (newData, newParent, childs) => {
      let modalPropsMenu = { ...this.state.modalPropsMenu };
      let checkeds = modalPropsMenu.initialData.checked;
      if (checkeds.includes(newData)) {
        checkeds.splice(checkeds.indexOf(newData), 1);
        let activeParent = false;
        await childs.forEach((child) => {
          if (checkeds.includes(child.id) && child.id !== newData) {
            activeParent = true;
          }
        });
        if (!activeParent) {
          checkeds.splice(checkeds.indexOf(newParent), 1);
        }
      } else {
        if (checkeds.includes(newParent)) {
          checkeds = [...checkeds, newData];
        } else {
          checkeds = [...checkeds, newData, newParent];
        }
      }

      // this.handleModalMenu.onChangeParent(newParent, childs);
      modalPropsMenu.initialData.checked = checkeds;
      this.setState({ modalPropsMenu });
    },
    onChangeParent: (newData, childs) => {
      let modalPropsMenu = { ...this.state.modalPropsMenu };
      let checkeds = modalPropsMenu.initialData.checked;
      if (checkeds.includes(newData)) {
        checkeds.splice(checkeds.indexOf(newData), 1);
        childs.forEach((child) =>
          checkeds.splice(checkeds.indexOf(child.id), 1)
        );
      } else {
        let checked_childs = [];
        childs.forEach(
          (child) => (checked_childs = [...checked_childs, child.id])
        );
        checkeds = [...checkeds, ...checked_childs, newData];
      }
      modalPropsMenu.initialData.checked = checkeds;
      this.setState({ modalPropsMenu });
    },
  };

  handleAlert = {
    show: (idMenu) => {
      let alertProps = { ...this.state.alert };
      alertProps.show = true;
      this.setState({ alert: alertProps, activeId: idMenu });
    },
    hide: () => {
      let alertProps = { ...this.state.alert };
      alertProps.show = false;
      this.setState({ alert: alertProps, activeId: null });
    },
    confirmed: async () => {
      await delRole(this.state.activeId);
      this.handleAlert.hide();
      this.loadData();
    },
  };

  render() {
    return (
      <>
        <Card>
          <ModalDialog {...this.state.modalPropsRole} />
          <ModalDialogEditMenu {...this.state.modalPropsMenu} />
          <SimpleAlert {...this.state.alert} />
          <CardHeader title="Role">
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.handleModalRole.tambah}
              >
                Tambah Role Baru
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <SimpleTable data={this.state.data} columns={columns} />
          </CardBody>
        </Card>
      </>
    );
  }
}

import React from 'react'
import { CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import { AnimatedCard } from '../../../components/animated-card/animatedCard'
import { useState } from 'react';
import Select2 from "react-select";
import GrafikPembelianCabang from './cabang-kpi';
import GrafikPenjualanSupplier from './supplier-kpi';
import GrafikPembelianQtyProduk from './qtyProduk-kpi';
import { ErrorPage1 } from '../../../modules/ErrorsExamples/ErrorPage1';
import GrafikPembelianNilaiProduk from './nilaiProduk-kpi';

function GrafikPembelian() {
  const [kategori] = useState([{
    value: 1,
    label: "Cabang"
  }, {
    value: 2,
    label: "Supplier"
  },
  {
    value: 3,
    label: "QTY Produk"
  },
  {
    value: 4,
    label: "Nilai Produk"
  }
])

  const [sKategori, setSkategori] = useState(1)

  return (
    <>
      <AnimatedCard>
        <CardHeader title={'Laporan & Grafik Pembelian'}>
          <CardHeaderToolbar >
            <div className="form-group">
              <label style={{ width: "250px" }}>Kategori</label>
              <Select2
                name={"Kategori"}
                size="lg"
                options={kategori}
                isClearable={false}
                value={
                  kategori
                    ? kategori.find(
                      (option) => option.value === sKategori
                    )
                    : ""
                }
                onChange={(e) => {
                  setSkategori(e ? e.value : []);
                }}
              >
              </Select2>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {sKategori === 1 ? 
          <GrafikPembelianCabang/> : 
          sKategori === 2 ?
          <GrafikPenjualanSupplier/> : 
          sKategori === 3 ? 
          <GrafikPembelianQtyProduk/> :
          <GrafikPembelianNilaiProduk/>
        }
        </CardBody>
      </AnimatedCard>
    </>
  )
}



export default GrafikPembelian
import React, { useEffect, useMemo, useState } from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../../components/table/Table";
import SimpleTableModel from "../../../../models/simpletable-model/SimpleTableModel";
import Model from "../../../../models/laporan-pembelian-model/LaporanPembelianModel";
import { Link } from "react-router-dom";
import { dispatcher } from "../../../../redux/dispatcher";
import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import moment from "moment";
import { PenerimaanAPI } from "../../../../api/penerimaan";
import Select2 from "react-select";
import store from "../../../../redux/store";
import { Button, Form, Modal } from "react-bootstrap";
import { API } from "../../../../api/api";
import { SupplierAPI } from "../../../../api/supplier";

export default function List(params) {

  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const pageName = "Laporan Pembelian";
  const [suppOpt, setsuppOpt] = useState([]);
  const [slcSuppl, setSuppSlc] = useState(0);
  const [startDate, setStartDate] = useState(
    moment(new Date())
      .startOf("month")
      .format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [search, setSearch] = useState("");
  const [option, setOptions] = useState([]);
  const [cabang, setCabang] = useState([0]);
  let options = [];

  const ksa = [20, 46, 52, 69];
  const kpp = [68, 28, 70, 71];
  const ksb = [108, 105, 110, 109];

  const [pt] = useState([{
    value: ksa,
    label: "KSA"
  }, {
    value: kpp,
    label: "KPP"
  }, {
    value: ksb,
    label: "KSB"
  }])

  const [selectedPT, setSelectedPT] = useState(ksa.includes(store.getState().auth.id_perusahaan) ? ksa : kpp.includes(store.getState().auth.id_perusahaan) ? kpp : ksb);
  const [idPerusahaan, setIdPerusahaan] = useState([selectedPT]);

  const coverage = async () => {
    await new PenerimaanAPI().getCoverage().then((res) => {
      let filter = res.data.filter((data) => selectedPT.includes(data.id))
      options = filter.map((data) => (
        {
          label: data.text,
          text: data.text,
          value: data.id,
        }));
    });
    setOptions(options);
  };

  const supplier = async () => {
    await new SupplierAPI().getSemua().then((res) => {
      let filter = res.data.filter((data) => selectedPT.includes(data.id_perusahaan))
      console.log("Supplier", filter);
      options = filter.map((data) => (
        {
          label: data.nama,
          text: data.nama,
          value: data.id,
        }));
    });
    setsuppOpt(options);
  };


  // useEffect(() => {
  //   loadData()
  // },[options])

  async function loadData(vals) {
    let datas = await new PenerimaanAPI().getAllLaporan(vals).then((dta) => dta.data);
    let dataMaster = datas;
    let simpleTableModel = new SimpleTableModel();
    let searchParams = ["no_transaksi", "nama_supplier", "kode",];
    let filtered = dataMaster.filter((filter) => {
      console.log("Data", filter);
      let validasi = filter.master.status_validasi === 2;
      let dateFormat = moment(filter.master.tanggal_invoice).format("YYYY-MM-DD");
      let dateFilter = dateFormat >= startDate && dateFormat <= endDate;
      let show = slcSuppl !== 0 ? filter.master.id_pemasok === slcSuppl : filter
      return searchParams.some((item) => {
        return (
          show &&
          validasi &&
          dateFilter &&
          filter.master[item]
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())
        );
      });
    });
    const actions = (dataModel) => {
      return (
        <>
          <Link
            className="mx-1 btn btn-outline-success btn-sm"
            to="/laporan-pembelian/detail"
            onClick={() => {
              dispatcher.penerimaan.set(dataModel);
            }}
          >
            Detail
          </Link>
        </>
      );
    };

    filtered.map((item, i) => {
      let dataModel = new Model(item);
      if (item.master.status === 1)
        simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: actions(dataModel),
        });
    });

    setColumntable(await simpleTableModel.getColumnHeaders());
    setDatatable(await simpleTableModel.getDatatables());
  }

  useEffect(() => {
    if (cabang.includes(0)) {
      loadData(selectedPT)
      setIdPerusahaan(selectedPT)
    } else {
      loadData(cabang)
      setIdPerusahaan(cabang)
    }
    coverage();
    supplier();
  }, [selectedPT, cabang, startDate, endDate, search, slcSuppl]);

  return (
    <>
      <AnimatedCard>
        <CardHeader title={pageName}>
          <CardHeaderToolbar >
            <div className="d-flex justify-content-lg-end">
              <div>
                <input
                  type={"text"}
                  className={"form-control"}
                  placeholder="Cari Data ..."
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
              &emsp;
              &emsp;
              <div >
                <Button
                  type="download"
                  onClick={async () => {
                    let params = [];
                    if (idPerusahaan.length !== 1) {
                      params += idPerusahaan.join("&id_perusahaan=");
                    } else {
                      params += idPerusahaan;
                    }
                    window.open(
                      API.host +
                      "retail/laporan_pembelian/download?id_perusahaan=" +
                      params + `&idPemasok=${slcSuppl}&start_date=${startDate}&end_date=${endDate}`,
                      "_self",
                      "noopener,noreferrer"
                    );
                  }}
                >
                  <i className={"far fa-file-excel"}></i> {" Export .xls"}
                </Button>
              </div>
            </div>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {/* <div className="d-flex justify-content-start">
          </div> */}
          <div className="d-flex justify-content-start">
            <div className="col-lg-3" style={{ marginTop: "0px" }}>
              <label> Tanggal Mulai </label>
              <input
                name={"tanggal_awal"}
                type={"date"}
                value={startDate}
                className={"form-control"}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3" style={{ marginTop: "0px" }}>
              <label> Tanggal Akhir </label>
              <input
                name={"tanggal_akhir"}
                type={"date"}
                value={endDate}
                className={"form-control"}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3" style={{ marginTop: "0px" }}>
              <label> Pilih PT </label>
              <Select2
                name={"PT"}
                size="sm"
                options={pt}
                isClearable={false}
                value={
                  pt
                    ? pt.find(
                      (option) => option.value === selectedPT
                    )
                    : ""
                }
                onChange={(e) => {
                  console.log('Opsi', pt);
                  setSelectedPT(e ? e.value : []);
                  console.log('Selected', selectedPT);
                  // setFieldValue("cabang", e ? e.value : "");
                }}
              />
            </div>
            <div className="col-lg-3" style={{ marginTop: "0px" }}>
              <label> Pilih Perusahaan </label>
              <Select2
                name={"Cabang"}
                placeholder={option.text}
                size="sm"
                options={option}
                isClearable={true}
                value={
                  option
                    ? option.find(
                      (option) => option.value === cabang
                    )
                    : ""
                }
                onChange={(e) => {
                  setCabang(e ? [e.value] : [0]);
                  dispatcher.penerimaan.list();
                  // setFieldValue("cabang", e ? e.value : "");
                }}
              />
            </div>
          </div>
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <label> Pilih Supplier </label>
              <Select2
                name={"Supplier"}
                size="sm"
                options={suppOpt}
                isClearable={true}
                value={
                  suppOpt
                    ? suppOpt.find(
                      (option) => option.value === slcSuppl
                    )
                    : ""
                }
                onChange={(e) => {
                  setSuppSlc(e ? e.value : 0);
                  dispatcher.penerimaan.list();
                  // setFieldValue("cabang", e ? e.value : "");
                }}
              />
            </div>
          <div className="d-flex justify-content-center">

          </div>
          <SimpleTable data={datatable} columns={columntable} />
        </CardBody>
      </AnimatedCard>
    </>
  );
}

import XlsxPopulate from "xlsx-populate";
import moment from "moment";
import { saveAs } from "file-saver";

// Get Sheet Data Export To Excel
function getSheetData(data, header) {
  var fields = Object.keys(data[0]);
  var sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
}

// Export To Excell
function saveAsExcel(data, title) {
  console.log('Data For Excel', data);
  let currDate = moment().format('YYYY-MM-DD')
  let dataList = [];
  data.map((data, i) => {
    dataList.push({
      tanggal: data.tanggal,
      noTransaksi: data.no_transaksi,
      qty: data.qty,
      lokasi: data.lokasi,
      status: data.aksi,
    })
    return data;
  });
  let header = [
    'Tanggal',
    'No Transaksi',
    'QTY',
    'Lokasi',
    'Status',
  ];
  let dataLength = data.length + 3;
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);
    const sheetData = getSheetData(dataList, header);
    const range1 = workbook.sheet(0).range("A1:E2");
    range1.value(`Laporan Stok Keluar ${title}`);
    range1.style({bold: "true", horizontalAlignment: "center", verticalAlignment: "center", border:true})
    range1.merged(true);
    sheet1.cell("A3").value(sheetData);
    const range = sheet1.usedRange();
    sheet1.row(3).style("bold", true).height(25);
    sheet1.range("A3:E3").style({
      fill: "BFBFBF",
      horizontalAlignment: "center",
      verticalAlignment: "center",
    });

    // Styls Center Align
    let centerAlign = ["A", "B", "C", "D", "E"];
    for (let i = 0; i < centerAlign.length; i++) {
      sheet1.range(centerAlign[i] + "2:" + centerAlign[i] + dataLength).style({
        horizontalAlignment: "center"
      });

    }
    // let valFormat = ["D"];
    // Styls wrapText
    // valFormat.forEach((data) => {
    //   sheet1.range(`${data}2:${data}` + dataLength).style({
    //     numberFormat: "Rp#,###",
    //     // horizontalAlignment: "right",
    //   });
    // })
    range.style("border", true);
    sheet1.column("A").width(23);
    sheet1.column("B").width(30);
    sheet1.column("C").width(40);
    sheet1.column("D").width(30);
    sheet1.column("E").width(45);
    return workbook.outputAsync().then((res) => {
      saveAs(res, "Laporan (" + title + " " + currDate + ").xlsx");
    });
  });
}

export { saveAsExcel, getSheetData }
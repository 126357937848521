import React from 'react'
import { AnimatedCard } from '../../../../components/animated-card/animatedCard';
import { CardBody, CardHeader} from '../../../../../_metronic/_partials/controls';
import Select2 from "react-select";
import { hapusSeparator, separatorHarga } from '../../../../services/separator-harga';
import { Button } from 'react-bootstrap';
import { PenerimaanAPI } from '../../../../api/penerimaan';
import { useState } from 'react';
import store from '../../../../redux/store';
import { useEffect } from 'react';
import { ReactDatatableFooter } from '../../../../components/react-datatable-footer/reactDatatableFooter';
import { saveAsExcel } from './helperExportExcel';

function AgingScheduleHutang() {
    const title = 'Laporan Aging Schedule Hutang';
    const api = new PenerimaanAPI();
    const [cabang, setCabang] = useState(store.getState().auth.id_perusahaan);
    const [options, setOptions] = useState([]);
    const [master, setMaster] = useState([]);
    let od1 = master.reduce((a, b) => {
        let n = parseInt(hapusSeparator(b.od1))
        return a + n
    }, 0)
    let od2 = master.reduce((a, b) => {
        let n = parseInt(hapusSeparator(b.od2))
        return a + n
    }, 0)
    let od3 = master.reduce((a, b) => {
        let n = parseInt(hapusSeparator(b.od3))
        return a + n
    }, 0)
    let od4 = master.reduce((a, b) => {
        let n = parseInt(hapusSeparator(b.od4))
        return a + n
    }, 0)
    const columns = [
        {
            name: 'Tanggal',
            selector: row => row.tglInv,
            sortable: true,
            minWidth: '10%'
        },
        {
            name: 'No Invoice',
            selector: row => row.noInvoice,
            sortable: true,
            minWidth: '15%'
        },
        {
            name: 'No Transaksi',
            selector: row => row.noTransaksi,
            sortable: true,
            minWidth: '20%'
        },
        {
            name: 'Nama',
            selector: row => row.nama,
            sortable: true,
            minWidth: '20%'
        },
        {
            name: 'Jatuh Tempo',
            selector: "jTempo",
            sortable: true,
            minWidth: '12%'
        },
        {
            name: 'Total',
            selector: "total",
            cell: props => <div>{separatorHarga(parseInt(props.total).toString(), "Rp. ")}</div>,
            sortable: true,
            minWidth: '12%'
        },
        {
            name: 'Terbayar',
            selector: row => row.terbayar,
            cell: props => <div>{separatorHarga(parseInt(`${props.terbayar}`).toString(), "Rp. ")}</div>,
            sortable: true,
            minWidth: '12%'
        },
        {
            name: '1-30 HR',
            selector: "od1",
            // cell: props => `Rp. ${separatorHarga(parseInt(props.od1).toString())}`,
            sortable: true,
            minWidth: '17%'
        },
        {
            name: '31-60 HR',
            selector: "od2",
            sortable: true,
            minWidth: '17%'
        },
        {
            name: '61-90 HR',
            selector: "od3",
            sortable: true,
            minWidth: '17%'
        },
        {
            name: '90 HR >',
            selector: "od4",
            sortable: true,
            minWidth: '17%'
        },
    ];


    const footer = {
        od1: separatorHarga(od1.toString(), "Rp. "),
        od2: separatorHarga(od2.toString(), "Rp. "),
        od3: separatorHarga(od3.toString(), "Rp. "),
        od4: separatorHarga(od4.toString(), "Rp. "),
    };

    const coverage = async () => {
        let opsi = [];
        await new PenerimaanAPI().getCoverage().then((res) => {
            opsi = res.data.map((data) => ({
                label: data.text,
                text: data.text,
                value: data.id,
            }));
        });
        // options.push(options)
        setOptions(opsi);
    };

    async function init(sendData) {
        let datas = await api.getAllHutang(sendData);
        let data = []
        datas.map((v) => {
            let date = Math.floor(Math.abs(new Date() - new Date(v.master.jatuh_tempo)) / (1000 * 3600 * 24))
            //  = new Date(this.master.jatuh_tempo) < new Date().setHours(0, 0, 0, 0) ?
            let newData = {
                nama: v.master.nama_supplier,
                noTransaksi: v.master.no_transaksi,
                noInvoice: v.master.kode,
                tglInv: v.master.tanggal_invoice,
                jTempo: v.master.jatuh_tempo,
                total: v.master.total,
                terbayar: v.master.terbayar,
                tersisa: v.master.tersisa,
                od1: date <= 30 ? separatorHarga(v.master.tersisa.toString(), "Rp. ") : `Rp. 0`,
                od2: date > 30 && date <= 60 ? separatorHarga(v.master.tersisa.toString(), "Rp. ") : `Rp. 0`,
                od3: date > 60 && date <= 90 ? separatorHarga(v.master.tersisa.toString(), "Rp. ") : `Rp. 0`,
                od4: date > 90 ? separatorHarga(v.master.tersisa.toString(), "Rp. ") : `Rp. 0`,
            }
            return data = [...data, newData]
        })
        setMaster(data)
        // let date = Math.floor(Math.abs(new Date() - new Date(this.master.jatuh_tempo)) / (1000 * 3600 * 24))
    }

    useEffect(() => {
        coverage()
        let sendData = ({ cabang })
        init(sendData)
    }, [cabang])

    return (
        <>
            <AnimatedCard>
                <CardHeader title={title}>
                </CardHeader>
                <CardBody>
                    <div className="d-flex justify-content-start">
                        <div className="col-lg-4" style={{ marginTop: "20px", zIndex: "5" }}>
                            <label> Pilih Perusahaan </label>
                            <Select2
                                name={"Cabang"}
                                placeholder={" --- Pilih Perusahaan --- "}
                                size="sm"
                                options={options}
                                isClearable={false}
                                value={
                                    options
                                        ? options.find(
                                            (data) => data.value === cabang
                                        )
                                        : ""
                                }
                                onChange={(e) => {
                                    setCabang(e ? e.value : []);
                                    // setFieldValue("cabang", e ? e.value : "");
                                }}
                            />
                        </div>
                        <div className="col-lg-4" style={{ marginTop: "45px", marginBottom: "0px" }}>
                            <Button
                                type="button"
                                onClick={async () => {
                                    saveAsExcel(master, title)
                                }}
                            >
                                <i className={"far fa-file-excel"}></i> {" Export .xls"}
                            </Button>
                        </div>
                    </div>
                    <ReactDatatableFooter
                        title={""}
                        data={master}
                        columns={columns}
                        searchParameter={['nama', 'noInvoice']}
                        // actions={actionsMemo}
                        footer={footer}
                    />
                </CardBody>
            </AnimatedCard>
        </>
    )
}

export default AgingScheduleHutang
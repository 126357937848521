import moment from "moment";

import { FormDataFetch, PrintDataFetch, getCoverageArea, getIdPerusahaan, simpleFetch, simpleFetchGetData } from "./api";

export class kasirKasAPI
{
  //** Start: Penerimaan
  // Get List Data */
  async getAllListPenerimaanKas ( form )
  {
    const { begin_date, end_date, id_perusahaan, no_transaksi, diterima } = form;
    let cabang = ( id_perusahaan === "" ) ? getCoverageArea() : id_perusahaan;
    let get = `&begin_date=${ moment( begin_date ).format( 'YYYY-MM-DD' ) }&end_date=${ moment( end_date ).format( 'YYYY-MM-DD' ) }&id_perusahaan=${ cabang }&no_transaksi=${ no_transaksi }&kontak_ref=${ diterima }`;
    let res = await simpleFetchGetData( {
      url: "retail/kasir/penerimaan_kas?tipe=init" + get,
      method: "GET",
    } );
    return res;
  }
  // Save
  async setPenerimaanKas ( values )
  {
    let res = await simpleFetch( {
      url: "retail/kasir/penerimaan_kas",
      method: "POST",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values,
      },
    } );
    return res;
  }
  // End: Penerimaan Kas
  //* ----------------------------------------------------------------------------- */
  //** Start: Pengeluaran
  // Get List Data */
  async getAllListPengeluaranKas ( form )
  {
    const { begin_date, end_date, id_perusahaan, no_transaksi, kepada } = form;
    let cabang = ( id_perusahaan === "" ) ? getCoverageArea() : id_perusahaan;
    let get = `&begin_date=${ begin_date }&end_date=${ moment( end_date ).format( 'YYYY-MM-DD' ) }&id_perusahaan=${ cabang }&no_transaksi=${ no_transaksi }&kontak_ref=${ kepada }`;
    let res = await simpleFetchGetData( {
      url: "retail/kasir/pengeluaran_kas?tipe=init" + get,
      method: "GET",
    } );
    return res;
  }
  // Save
  async setPengeluaranKas ( values )
  {
    // Codition Detail
    let fd = new FormData();
    if ( values[ 'data_detail' ] )
    {
      let array_detail = [];
      values[ 'data_detail' ].forEach( element =>
      {
        let detail = {
          id: element[ 'id' ],
          id_akun: element[ 'id_akun' ],
          kode_akun: element[ 'kode_akun' ],
          nama_akun: element[ 'nama_akun' ],
          deskripsi: element[ 'deskripsi' ],
          jumlah: element[ 'jumlah' ],
          ceklist: element[ 'ceklist' ],
          nama_images: element[ 'upload_gambar_bop_kas' ] ? `${ element[ 'upload_gambar_bop_kas' ][ 'name' ] }` : null,
          // nama_images: element['upload_gambar_bop_kas'] ? `${element['id']}-${element['upload_gambar_bop_kas']['name']}` : null,
        };
        array_detail.push( detail );
        fd.append( 'upload_gambar_bop_kas', element[ 'upload_gambar_bop_kas' ] );
      } );
      fd.append( 'detail', JSON.stringify( array_detail ) );
    }
    for ( const key in values )
    {
      fd.append( key, values[ key ] );
    }
    let res = await FormDataFetch( {
      url: "retail/kasir/pengeluaran_kas",
      method: "POST",
      body: fd,
    } );
    return res;
  }
  // Delete
  async delete ( id )
  {
    let res = await simpleFetch( {
      url: "retail/kasir/pengeluaran_kas",
      method: "DELETE",
      body: {
        id: id,
      },
    } );
    return res;
  }
  // Approval
  async approval ( values )
  {
    let res = await simpleFetch( {
      url: "retail/kasir/pengeluaran_kas/approval",
      method: "POST",
      body: {
        ...values,
      },
    } );
    return res;
  }

  async approvalAwal ( values )
  {
    let res = await simpleFetch( {
      url: "retail/kasir/pengeluaran_kas/approve_awal",
      method: "POST",
      body: {
        ...values,
      },
    } );
    return res;
  }
  //** End: Pengeluaran Kas */
  //* ----------------------------------------------------------------------------- */
  //** Start: Helper Select */
  // Get Select Jenis Transaksi Penerimaan/Pengeluaran
  async getJenisTransaksi ( alias )
  {
    let res = await simpleFetch( {
      url: "retail/select/transaksi_jenis?alias=" + alias,
      method: "GET",
    } );
    return res;
  }

  // Get Select Transaksi Ref
  async getTransaksiRefPenerimaan ( id )
  {
    let res = await simpleFetch( {
      url: "retail/select/transaksi_ref?alias=penerimaan-kas&id_transaksi_jenis=" + id,
      method: "GET",
    } );
    return res;
  }

  // GetSelect Coverage Area
  async getCoverage ()
  {
    let res = await simpleFetch( {
      url: "retail/select/perusahaan?id_coverage=" + getCoverageArea(),
      method: "GET",
    } );
    return res;
  }
  
  // GetSelect Kolektor
  async getKolektor ()
  {
    let res = await simpleFetch( {
      url: "retail/select/kolektor",
      method: "GET",
    } );
    return res;
  }

  // Get Select No Transaksi DP Penjualan (Customer)
  async getNoTransaksiDPPenjualan ( id )
  {
    let get = `&id_transaksi_ref=${ id }&id_perusahaan=${ getIdPerusahaan() }`;
    let res = await simpleFetch( {
      url: "retail/select/pembelian_penjualan?tipe=penjualan" + get,
      method: "GET",
    } );
    return res;
  }

  // Get Select No Transaksi AR Penjualan (Customer)
  async getNoTransaksiARPejualan ( id )
  {
    let get = `&id_transaksi_ref=${ id }&id_perusahaan=${ getIdPerusahaan() }`;
    let res = await simpleFetch( {
      url:
        "retail/select/pembelian_penjualan?tipe=penjualan" + get,
      method: "GET",
    } );
    return res;
  }

  // Get Select Akun Detail Penerimaan
  async getAkunPenerimaan ()
  {
    let get = `&id_perusahaan=${ getIdPerusahaan() }`;
    let res = await simpleFetch( {
      url: "retail/select/akun_akses?alias=penerimaan-kas" + get,
      method: "GET",
    } );
    return res;
  }

  // Get Select Akun Detail Pengeluaran
  async getAkunPengeluaran ()
  {
    let get = `&id_perusahaan=${ getIdPerusahaan() }`;
    let res = await simpleFetch( {
      url: "retail/select/akun_akses?alias=pengeluaran-kas" + get,
      method: "GET",
    } );
    return res;
  }

  //** End: Helper Select */
  //* ----------------------------------------------------------------------------- */
  //** Start: Cetak PDF
  // Tanda Terima */
  async printPdfTandaTerima ( values )
  {
    let res = await PrintDataFetch( {
      url: "retail/kasir/penerimaan_kas/tanda_terima?no_transaksi=" + values,
      method: "GET",
    } );
    return res;
  }

  // Bukti Penerimaan
  async printPdfBuktiPenerimaan ( values )
  {
    let res = await PrintDataFetch( {
      url: "retail/kasir/penerimaan_kas/cetak_bukti_penerimaan?no_transaksi=" + values,
      method: "GET",
    } );
    return res;
  }

  async printPdfBuktiPengeluaran ( values )
  {
    let res = await PrintDataFetch( {
      url: "retail/kasir/pengeluaran_kas/cetak_bukti_pengeluaran?no_transaksi=" + values,
      method: "GET",
    } );
    return res;
  }
  //** End: Cetak PDF */
}

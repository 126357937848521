import { getIdPerusahaan, simpleFetch, getCoverageArea} from "./api";
import store from "../redux/store";
import PesananPembelianModel from "../models/pesanan-pembelian-model/PesananPembelianModel";
import { pembelianAction } from "../redux/pembelian/reducer";

export class PesananPembelianAPI {
  async getAll() {
    let coverage = store.getState().auth.coverage;
    let params = "id_perusahaan=";
    if (coverage.length > 0) {
      params += coverage.join("&id_perusahaan=");
    } else {
      params += getIdPerusahaan();
    }
    let res = await simpleFetch({
      url: "retail/pembelian?" + params,
      method: "GET",
    });
    return res;
  }

  async getForInvoice(val) {
    let params = "id_perusahaan=";
      params += val;
    let res = await simpleFetch({
      url: "retail/pembelian?" + params,
      method: "GET",
    });
    return res;
  }

  async getBatal() {
    let coverage = store.getState().auth.coverage;
    let params = "id_perusahaan=";
    if (coverage.length > 0) {
      params += coverage.join("&id_perusahaan=");
    } else {
      params += getIdPerusahaan();
    }
    let res = await simpleFetch({
      url: "retail/batal/pembelian?" + params,
      method: "GET",
    });
    return res;
  }

  async getSingle(id) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/pembelian?id=" + id,
        method: "GET",
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async set(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/pembelian",
        method: "POST",
        body: {
          id_perusahaan: getIdPerusahaan(),
          ...values,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async edit(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/pembelian",
        method: "PUT",
        body: {
          id_perusahaan: getIdPerusahaan(),
          ...values,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async getCoverage() {
    let res = await simpleFetch({
      url: "retail/select/perusahaan?id_coverage=" + getCoverageArea(),
      method: "GET",
    });
    return res;
  }

}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { CardBody} from '../../../../_metronic/_partials/controls'
import { AnimatedCard } from '../../../components/animated-card/animatedCard'
import { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import store from '../../../redux/store';
import Select2 from "react-select";
import { LaporanGrafik } from '../../../api/laporan-grafik';
import { ReactDatatable } from '../../../components/react-datatable/reactDatatable';
import { saveAsExcel } from '../helperExportExcel';



function GrafikPembelianQtyProduk() {
  const [bulan] = useState(['January', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'])
  const columns = [
    {
      name: 'Nama',
      selector: row => row.name,
      sortable: true,
      minWidth: '20%'
    }
  ];

  bulan.map((data, i) => {
    return columns.push(
      {
        name: data,
        selector: row => row.qty[i],
        sortable: true,
        minWidth: '12%'
      })
  })
  
  const ksa = [20, 46, 52, 69];
  const kpp = [68, 28, 70, 71];
  const ksb = [108, 105, 110, 109];

  const [pt] = useState([{
    value: ksa,
    label: "KSA"
  }, {
    value: kpp,
    label: "KPP"
  }, {
    value: ksb,
    label: "KSB"
  }])

  const [selectedPT, setSelectedPT] = useState(ksa.includes(store.getState().auth.id_perusahaan) ? ksa : kpp.includes(store.getState().auth.id_perusahaan) ? kpp : ksb);

  const [cabang, setCabang] = useState([0]);

  const title = 'LaporanPembelian-by-QTYproduk';
  const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>
    <i className={"far fa-file-excel"}></i> {" Export .xls"}
  </Button>
  const actionsMemo = <Export onExport={() => saveAsExcel(pembelian, title)} />

  
  const [opsi, setOptions] = useState([]); 

  let opsiCabang = []

  const coverage = async () => {
    await new LaporanGrafik().getCoverage().then((res) => {
      let filter = res.data.filter((data) => selectedPT.includes(data.id))
      opsiCabang = filter.map((data) => (
        {
          label: data.text,
          text: data.text,
          value: data.id,
        }));
    });
    setOptions(opsiCabang);
  };


  const [pembelian, setPembelian] = useState([])

  const fetchData = async (data) => {
    try {
      await new LaporanGrafik().pembelianQTYProduk(data).then((res) => res.data.map((val) => {
        const map = new Map(val.map(({ nama, count, total_qty, id_produk }) => [id_produk, { id_produk,nama, count: [], total_qty: [],}]));
        for (let { nama, count, total_qty, id_produk } of val) {
          map.get(nama)
          map.get(id_produk).count.push(...[count].flat())
          map.get(id_produk).total_qty.push(...[total_qty].flat())
        }
        let temp = []
        temp.push([...map.values()])
        let datas = []
        temp.map((val) => {
          for (const item of val) {
            let newData = {
              name: item.nama,
              id: item.id_produk,
              qty: item.total_qty
            }
            datas = [...datas, newData]
          }
          setPembelian(datas)
        })
      }));
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    // if (dataSeries.length === 0)
    // {
      if (cabang.includes(0)) {
        fetchData(selectedPT);
        // setIdPerusahaan(selectedPT)
      } else {
        fetchData(cabang);
        // setIdPerusahaan(cabang)
      }
      coverage();
    // }
  }, [cabang, selectedPT])
  return (
    <>
      <AnimatedCard>
        <CardBody>
          <div className="d-flex justify-content-start">
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <label> Pilih PT </label>
              <Select2
                name={"PT"}
                size="sm"
                options={pt}
                isClearable={false}
                value={
                  pt
                    ? pt.find(
                      (option) => option.value === selectedPT
                    )
                    : ""
                }
                onChange={(e) => {
                  // console.log('Opsi', pt);
                  setSelectedPT(e ? e.value : []);
                  setCabang([0])
                  setPembelian([]);
                }}
              />
            </div>
            <div className="col-lg-3" style={{ marginTop: "20px" , zIndex: "5"}}>
              <label> Pilih Perusahaan </label>
              <Select2
                name={"Cabang"}
                placeholder={opsi.text}
                size="sm"
                options={opsi}
                isClearable={true}
                value={
                  opsi
                    ? opsi.find(
                      (option) => option.value === cabang
                    )
                    : ""
                }
                onChange={(e) => {
                  setCabang(e ? [e.value] : [0]);
                  setPembelian([]);
                }}
              />
            </div>
          </div>
          <ReactDatatable
            columns={columns}
            data={pembelian}
            dense
            pagination
            searchParameter={['name']}
            actions={actionsMemo}
          />
        </CardBody>
      </AnimatedCard>
    </>
  )
}



export default GrafikPembelianQtyProduk
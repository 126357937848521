import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import { Button } from "react-bootstrap";
import SimpleAlert from "../../../components/alert/alert";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Fields } from "../../../components/fields/Fields";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import transaksiTipeModel from "../../../models/transaksi-tipe-model/transaksiTipeModel";
import { TransaksiTipeAPI } from "../../../api/transaksi-tipe";

export default class transaksiTipe extends React.Component {
  constructor(props) {
    super(props);
    this.initialData = new transaksiTipeModel();
    this.api = new TransaksiTipeAPI();
    this.simpleTableModel = new SimpleTableModel();
    this.pageName = "Transaksi Tipe";
    this.state = {
      modal: {
        show: false,
        initialData: this.initialData,
        isEdit: false,
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
    this.validation = Yup.object().shape({});
    this.columntable = [{ dataField: "", text: "" }];
    this.datatable = [{ id: 0, nama: "" }];
    this.fields = [];
  }

  async componentDidMount() {
    await this.loadData();
    let yup = {};
    await this.initialData.getEditable().then((values) =>
      values.map((item) => {
        yup[item.dataField] = item.validation;
      })
    );
    this.validation = Yup.object().shape(yup);
  }

  async loadData() {
    let datas = await this.api.getAll();
    this.simpleTableModel = new SimpleTableModel();
    await datas.data.map((item, i) => {
      let dataModel = new transaksiTipeModel(item);
      this.simpleTableModel.add({
        id: item.id,
        no: i + 1,
        dataModel: dataModel,
        actions: (
          <span>
            <Button
              size="sm"
              className="mx-1"
              variant="outline-primary"
              onClick={() => {
                this.handleModal.edit(dataModel);
              }}
            >
              Edit
            </Button>
            <Button
              size="sm"
              className="mx-1"
              variant="outline-danger"
              onClick={() => {
                this.handleAlert.show(item.id);
                this.loadData();
              }}
            >
              Hapus
            </Button>
          </span>
        ),
      });
    });

    this.datatable = await this.simpleTableModel.getDatatables();
    this.columntable = await this.simpleTableModel.getColumnHeaders();
    this.setState({ ...this.state });
  }

  handleModal = {
    loadFields: async () => {
      this.fields = [];
      await this.initialData
        .getEditable({ pageName: this.pageName, isForm: true })
        .then((values) => {
          values.map((item, i) => {
            return (this.fields = [...this.fields, item]);
          });
        });
    },
    tambah: async () => {
      let modal = { ...this.state.modal };
      modal.show = true;
      modal.title = `${this.pageName} Baru`;
      modal.isEdit = false;
      await this.handleModal.loadFields();
      this.setState({ modal });
    },
    edit: async (dataFromModel) => {
      let modal = { ...this.state.modal };
      modal.show = true;
      modal.title = `Edit ${this.pageName}`;
      modal.initialData = dataFromModel;
      modal.isEdit = true;
      await this.handleModal.loadFields();
      this.setState({ modal });
    },
    hide: () => {
      let modal = { ...this.state.modal };
      modal.show = false;
      modal.initialData = this.initialData;
      this.setState({ modal });
    },
  };

  handleAlert = {
    show: (id) => {
      let alert = { ...this.state.alert };
      alert.show = true;
      alert.activeId = id;
      this.setState({ alert: alert });
    },
    hide: () => {
      let alert = { ...this.state.alert };
      alert.show = false;
      alert.activeId = null;
      this.setState({ alert: alert });
    },
    confirmed: async () => {
      await this.api.delete(this.state.alert.activeId);
      this.handleAlert.hide();
      this.loadData();
    },
  };

  render() {
    return (
      <>
        <AnimatedCard>
          <SimpleAlert
            title="Hapus Data"
            content="Anda yakin hapus data ini?"
            show={this.state.alert.show}
            onConfirmed={this.handleAlert.confirmed}
            onHide={this.handleAlert.hide}
          />
          <CardHeader title={this.pageName}>
            <CardHeaderToolbar>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.handleModal.tambah()}
              >
                <i className="fa fa-plus"></i>
                Tambah {this.pageName}
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <SimpleTable data={this.datatable} columns={this.columntable} />
          </CardBody>
        </AnimatedCard>
        <Formik
          enableReinitialize={true}
          initialValues={this.state.modal.initialData}
          validationSchema={this.validation}
          onSubmit={async (values, { resetForm }) => {
            if (this.state.modal.isEdit) {
              await this.api.edit(values);
            } else if (!this.state.modal.isEdit) {
              await this.api.set(values);
            }
            this.handleModal.hide();
            resetForm();
            this.loadData();
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Modal
              show={this.state.modal.show}
              onHide={this.handleModal.hide}
              size="md"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modal.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <div className="row">
                    {this.fields.map((field, i) => (
                      <div key={i} className="form-group col-lg-12">
                        <Fields {...field} setFieldValue={setFieldValue} />
                      </div>
                    ))}
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={this.handleModal.hide}
                  className="btn btn-light btn-elevate"
                >
                  Batal
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  Simpan
                </button>
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      </>
    );
  }
}

import { CardBody, CardFooter, CardHeader } from "../../../../../../_metronic/_partials/controls";
import React, { Component } from "react";
import { AnimatedCard } from "../../../../../components/animated-card/animatedCard";
import DataTableModel from "../../../../../models/datatable-model/DataTableModel";
import { Formik } from "formik";
import { FormsFields } from "../../../../../components/forms-fields/FormsFields";
import { ReactDatatable } from "../../../../../components/react-datatable/reactDatatable";
import { dispatcher } from "../../../../../redux/dispatcher";
import { export_excel } from "../../../../../components/export-excel/export_excel";
import { getIdPerusahaan } from "../../../../../api/api";
import { kasirKasAPI } from "../../../../../api/kasir-kas";
import penerimaanKasModel from "../../../../../models/kasir/kas/penerimaanKasModel";
import ButtonActions from "../../../../../components/button-actions/buttonActions";
import ButtonLoad from "../../../../../components/button-loading/buttonLoad";
import moment from "moment";

export default class ListPenerimaanKas extends Component
{
  constructor( props )
  {
    super( props );
    this[ 'pageName' ] = "List Penerimaan Kas";
    this[ 'searchParameter' ] = [ "no_transaksi", "id_perusahaan", "kontak_ref", "tanggal", "akun", "total", "deskripsi" ];
    this[ 'apiPenerimaanKas' ] = new kasirKasAPI();
    this[ 'export_excel' ] = new export_excel();
    this[ 'newDateMonth' ] = ( new Date().getMonth() + 1 ).toString();
    this[ 'month' ] = this[ 'newDateMonth' ].length > 1 ? this[ 'newDateMonth' ] : "0" + this[ 'newDateMonth' ];
    this[ 'options_cabang' ] = [];
    this[ 'state' ] = {
      pending: true,
      initialValues: {
        begin_date: new Date( this[ 'month' ] + "-01-" + new Date().getFullYear() ),
        end_date: new Date(),
        id_perusahaan: getIdPerusahaan(),
        no_transaksi: "",
        diterima: "",
      },
      data: []
    };
    this[ 'datatable' ] = [ { id: "", no: "", name: "" } ];
    this[ 'columntable' ] = [ { id: "", title: "" } ];
  }
  //* ----------------------------------------------------------------------------- */
  //** Start: Component */
  async componentDidMount ()
  {
    await this.LoadData( this[ 'state' ][ 'initialValues' ] );
    // Get Cabang Coverage
    this[ 'apiPenerimaanKas' ].getCoverage().then( ( values ) =>
    {
      this[ 'options_cabang' ] = values[ 'data' ].map( ( data ) => ( {
        label: data[ 'text' ],
        value: data[ 'id' ],
      } ) );
    } );
  }
  //** End: Component */
  //* ----------------------------------------------------------------------------- */
  //** Start: Load Data List */
  async LoadData ( form )
  {
    this.setState( { pending: true } );
    await dispatcher[ 'kasir_kas' ].listPenerimaanKas( form );
    let data = dispatcher[ 'kasir_kas' ].getListPenerimaanKas();
    this.setState( { data: data } );
    this[ 'DataTableModel' ] = new DataTableModel();
    data.map( ( item, i ) =>
    {
      let dataModel = new penerimaanKasModel( item );
      if ( item[ 'transaksi' ][ 'status' ] === 1 )
      {
        this[ 'DataTableModel' ].add( {
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: (
            <>
              <ButtonActions
                label={ "detailsLink" }
                linkTo={ "/kasir/daftar-penerimaan-kas/detail" }
                onClick={ () =>
                {
                  dispatcher[ 'kasir_kas' ].setPenerimaanKas( dataModel );
                } }
              />
            </>
          ),
        } );
      }
      return item;
    } );
    this[ 'columntable' ] = await this[ 'DataTableModel' ].getColumn();
    this[ 'datatable' ] = await this[ 'DataTableModel' ].getDatas();
    this.setState( { ...this[ 'state' ], pending: false } );
  }
  //** End: Load Data List */
  render ()
  {
    const { pending, initialValues, data } = this[ 'state' ];
    return (
      <>
        {/* Start: Formik List */ }
        <Formik
          initialValues={ initialValues }
          onSubmit={ ( values, actions ) =>
          {
            this.LoadData( values );
          } }
        >
          { ( { setFieldValue, setFieldTouched, touched, errors, values, handleSubmit } ) => (
            <AnimatedCard>
              <CardHeader title={ this[ 'pageName' ] }></CardHeader>
              <CardBody style={ { marginTop: "-20px" } }>
                <div className={ "form-group row" }>
                  {/* Forms Start Date  */ }
                  <FormsFields
                    id={ "begin_date" }
                    type={ "DatePicker" }
                    label={ "Tanggal Mulai" }
                    className={ "col-lg-4" }
                    style={ { marginTop: "20px" } }
                    paramsFormik={ {
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    } }
                  />
                  {/* Forms End Date  */ }
                  <FormsFields
                    id={ "end_date" }
                    type={ "DatePicker" }
                    label={ "Tanggal Akhir" }
                    className={ "col-lg-4" }
                    style={ { marginTop: "20px" } }
                    paramsFormik={ {
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    } }
                  />
                  {/* Forms Select Cabang */ }
                  <FormsFields
                    id={ "id_perusahaan" }
                    type={ "select2" }
                    label={ "Cabang" }
                    style={ { marginTop: "20px" } }
                    className={ "col-lg-4" }
                    isClearable={ true }
                    isSearchable={ true }
                    options={ this[ 'options_cabang' ] }
                    paramsFormik={ {
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    } }
                  />
                  {/* No. Transaksi */ }
                  <FormsFields
                    id={ "no_transaksi" }
                    type={ "text" }
                    label={ "No. Transaksi" }
                    className={ "col-lg-4" }
                    style={ { marginTop: "20px" } }
                    paramsFormik={ {
                      setFieldValue,
                      values
                    } }
                  />
                  {/* Diterima Dari */ }
                  <FormsFields
                    id={ "diterima" }
                    type={ "text" }
                    label={ "Diterima Dari" }
                    className={ "col-lg-4" }
                    style={ { marginTop: "20px" } }
                    paramsFormik={ {
                      setFieldValue,
                      values
                    } }
                  />
                </div>
                <div style={ { display: "flex", justifyContent: "center" } } >
                  <div>
                    {/* Button Lihat Data */ }
                    <ButtonLoad
                      label={ "Lihat Data" }
                      pending={ pending }
                      classNameIcon={ "fa fa-filter" }
                      className={ "btn btn-outline-success" }
                      onClick={ async () => handleSubmit() }
                    />
                    {/* Button Export Excel */ }
                    <ButtonLoad
                      label={ "Export .xls" }
                      pending={ pending }
                      classNameIcon={ "far fa-file-excel" }
                      className={ "btn btn-outline-primary" }
                      disabled={ data.length === 0 }
                      onClick={ async () =>
                      {
                        let startDate = moment( values[ 'begin_date' ] ).format( "L" );
                        let endDate = moment( values[ 'end_date' ] ).format( "L" );
                        let times = moment().format( 'h.mm.ss a' );
                        const dateFilter = ( startDate === endDate ) ? startDate : startDate + " - " + endDate;

                        let record = [];
                        data.map( ( val, i ) =>
                        {
                          const { no_transaksi, tanggal, cabang, kontak_ref, akun, total, deskripsi } = val[ 'transaksi' ];
                          record.push( {
                            no_transaksi: no_transaksi,
                            tanggal: tanggal ? moment( tanggal ).format( "DD-MM-YYYY" ) : "",
                            cabang: cabang,
                            kontak_ref: kontak_ref,
                            akun: akun,
                            total: total,
                            deskripsi: deskripsi,
                          } );
                          return val;
                        } );

                        let datas = {
                          headerTitle: "PENERIMAAN KAS",
                          title1: "",
                          title2: "",
                          headerField: [ "NO. TRANSAKSI", "TANGGAL", "CABANG", "DITERIMA DARI", "AKUN", "TOTAL", "KETERANGAN" ],
                          headerField2: [],
                          recordData: record,
                          recordData2: [],
                          totalSaldoAwal: false,
                          totalSaldoAkhir: false,
                          footerSubTotal: [],
                          footerSubTotal1: [],
                          columnWidth: [ 35, 17, 23, 40, 18, 20, 80 ],
                          centerAlign: [ "A", "B", "C", "E" ],
                          wrapText: [ "D", "G" ],
                          numberFormatRp: [ "F" ],
                          fileName: `Kasir Penerimaan Kas (${ dateFilter } - ${ times })`
                        }
                        this[ 'export_excel' ].saveAsExcel( datas );
                      } }
                    />
                  </div>
                </div>
              </CardBody>
              <CardFooter>
                <ReactDatatable
                  title={ "" }
                  columns={ this[ 'columntable' ] }
                  data={ this[ 'datatable' ] }
                  searchParameter={ this[ 'searchParameter' ] }
                  progressPending={ pending }
                />
              </CardFooter>
            </AnimatedCard>
          ) }
        </Formik>
        {/* End: Formik List */ }
      </>
    );
  }
}

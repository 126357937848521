import { CardBody, CardFooter, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls';
import React, { Component } from 'react';
import { AnimatedCard } from '../../../components/animated-card/animatedCard';
import { Formik } from 'formik';
import { Paper, Typography, makeStyles, Input } from '@material-ui/core';
import { FormsFields } from '../../../components/forms-fields/FormsFields';
import { akuntansiAPI } from '../../../api/akuntansi';
import { hapusSeparator, separatorHarga } from '../../../services/separator-harga';
import ButtonLoad from '../../../components/button-loading/buttonLoad';
import * as Yup from "yup";
import store from '../../../redux/store';
import moment from "moment/moment";
import Select2 from "react-select";
import { ReactDatatableDetails } from '../../../components/react-datatable/reactDatatable';
import { dispatcher } from '../../../redux/dispatcher';
import SimpleAlert from '../../../components/alert/alert';
export default class UpdateTransaksi extends Component {
   constructor(props) {
      super(props);
      this['pageName'] = "Update Transaksi";
      this['apiUpdateTransaksi'] = new akuntansiAPI();
      this['options_akun'] = [];
      this['options_bank'] = [];
      this['options_cabang'] = [];
      this['state'] = {
         isLoadingSave: false,
         pending: false,
         no_transaksi: "",
         user_update: "",
         bank: false,
         dataTable: [],
         saldoDebet: 0,
         saldoKredit: 0,
         debetKredit: 0,
         latest_update: "",
         formValues: {
            no_transaksi: "",
         },
         initialValues: {
            tanggal: "",
            id_user: store.getState().auth['id'],
         },
         alert: {
            show: false,
            activeData: {
               data: null
            }
         },
      }
   }
   //* ----------------------------------------------------------------------------- */
   //** Start: Component */
   async componentDidMount() {
      await this.LoadData(this['state']['formValues']);
      // Load Cabang
      this['apiUpdateTransaksi'].getCoverage().then((values) => {
         this['options_cabang'] = values['data'].map((data) => ({
            value: data['id'],
            label: data['text'],
         }));
      });
      // Load Bank
      this['apiUpdateTransaksi'].getBankJurnalUmum().then((res) => {
         this['options_bank'] = res['data'].map((val) => {
            return {
               value: val['id'],
               label: `${val['text']} - ${val['no_rekening']}`,
            };
         });
      });
      this['apiUpdateTransaksi'].getakunJurnalUmum().then((res) => {
         // Load Data option Akun
         this['options_akun'] = res['data'].map((val) => {
            return {
               value: val['id'],
               label: val['text'],
               kode_akun: val['kode_akun'],
            };
         });
      });
   }
   //** End: Component */
   //* ----------------------------------------------------------------------------- */
   //** Start: Load Data Buku Besar */
   async LoadData(form) {
      this.setState({
         pending: true,
      });
      let data = await this['apiUpdateTransaksi'].getAllUpdateTransaksi(form);
      // Total Debet
      const totalDebet = data['data']['BukuBesar'].reduce((total, val) => {
         let debet = typeof val['debet'] === "string" ? parseInt(hapusSeparator(val['debet'])) : val['debet'];
         return (total + debet);
      }, 0);
      // Total Kredit
      const totalKredit = data['data']['BukuBesar'].reduce((total, val) => {
         let kredit = typeof val['kredit'] === "string" ? parseInt(hapusSeparator(val['kredit'])) : val['kredit'];
         return (total + kredit);
      }, 0);
      this.setState({
         no_transaksi: data['data']['no_transaksi'],
         latest_update: moment(data['data']['latest_update']).format('DD MMMM YYYY, H:mm:ss'),
         user_update: data['data']['user_update'],
         bank: data['data']['bank'] ? true : false,
         dataTable: data['data']['BukuBesar'],
         saldoDebet: totalDebet,
         saldoKredit: totalKredit,
         debetKredit: totalDebet - totalKredit,
         pending: false,
      });
   }
   //** End: Handle Modal */
   //* ----------------------------------------------------------------------------- */
   //** Start: Handle Alert */
   handleAlert = {
      // Show Alert
      show: (data) => {
         this.setState({
            isSubmitting: true,
            alert: {
               activeData: data,
               show: true
            }
         });
      },
      // Hide Alert
      hide: () => {
         this.setState({
            isSubmitting: false,
            alert: {
               activeData: null,
               show: false
            }
         });
      },
      // Confirmed Alert
      confirmed: async () => {
         await this['apiUpdateTransaksi'].setUpdateTransaksi(this['state']['alert']['activeData']).then((res) => {
            this.setState({ formValues: res['data'] });
            if (res['status']) {
               this['handleAlert'].hide();
               dispatcher['snackbar'].show("Berhasil", res['message'], "primary");
               this.LoadData(this['state']['formValues']);
            } else {
               this.setState({ isSubmitting: false });
               return dispatcher['snackbar'].show("Gagal", res['message'], "danger");
            }
         });
      },
   };
   //** End: Handle Alert */
   render() {
      // Style
      const useStyles = makeStyles(theme => ({
         root: {
            textTransform: "uppercase",
            textAlign: "right",
            padding: theme.spacing(1, 2),
            backgroundColor: "#f3f6f9",
         },
      }));
      const { pending, isSubmitting, formValues, no_transaksi, user_update, latest_update, bank, dataTable, saldoDebet, saldoKredit, debetKredit, initialValues, alert } = this['state'];
      const handleInputChange = (rowData, name, value) => {
         const tmpData = [...dataTable];
         const tmpRow = tmpData.find((person) => person.id === rowData.id);
         tmpRow[name] = value;
         // Total Debet
         const totalDebet = tmpData.reduce((total, val) => {
            let debet = typeof val['debet'] === "string" ? parseInt(hapusSeparator(val['debet'])) : val['debet'];
            return (total + debet);
         }, 0);
         // Total Kredit
         const totalKredit = tmpData.reduce((total, val) => {
            let kredit = typeof val['kredit'] === "string" ? parseInt(hapusSeparator(val['kredit'])) : val['kredit'];
            return (total + kredit);
         }, 0);
         this.setState({
            dataTable: tmpData,
            saldoDebet: totalDebet,
            saldoKredit: totalKredit,
            debetKredit: totalDebet - totalKredit,
         });
      };
      const columns = [
         {
            name: "ID",
            selector: (row) => row.id,
            center: true,
            minWidth: "10%",
            omit: true
         }, {
            name: "No.",
            selector: row => row['no'],
            center: true,
            defaultSortAsc: true,
            sortable: false,
            maxWidth: '5px'
         }, {
            name: "Tanggal",
            selector: row => moment(row['tanggal']).format("DD-MM-YYYY"),
            center: true,
            sortable: false,
            minWidth: "12%",
         }, {
            name: "No. Transaksi",
            selector: row => row['no_transaksi'],
            center: true,
            sortable: false,
            minWidth: "22%",
         }, {
            name: "Cabang",
            selector: row => row['id_perusahaan'],
            cell: (row) => (
               <>
                  <div className="col-lg-12">
                     <Select2
                        isSearchable={true}
                        isClearable={true}
                        placeholder={"--- Select Cabang ---"}
                        styles={{
                           menu: (provided, state) => ({
                              ...provided,
                              borderBottom: '1px dotted pink',
                              paddingRight: 4,
                              paddingLeft: 4,
                              zIndex: '99999'
                           })
                        }}
                        options={this['options_cabang']}
                        value={(this['options_cabang'] ? this['options_cabang'].find((val) => val['value'] === row['id_perusahaan']) : "")}
                        onChange={(e) => {
                           handleInputChange(row, "id_perusahaan", e ? e['value'] : "")
                        }}
                     />
                  </div>
               </>
            ),
            center: true,
            sortable: false,
            minWidth: "30%",
         }, {
            name: "Nama User",
            selector: row => row['user'],
            sortable: false,
            minWidth: "25%",
         }, {
            name: "Bank",
            selector: row => row['id_bank'],
            cell: (row) => (
               <>
                  <div className="col-lg-12">
                     <Select2
                        isSearchable={true}
                        isClearable={true}
                        placeholder={"--- Select Bank ---"}
                        styles={{
                           menu: (provided, state) => ({
                              ...provided,
                              borderBottom: '1px dotted pink',
                              paddingRight: 4,
                              paddingLeft: 4,
                              zIndex: '99999'
                           })
                        }}
                        options={this['options_bank']}
                        value={(this['options_bank'] ? this['options_bank'].find((val) => val['value'] === row['id_bank']) : "")}
                        onChange={(e) => handleInputChange(row, "id_bank", e ? e['value'] : "")}
                     />
                  </div>
               </>
            ),
            center: true,
            sortable: false,
            minWidth: "30%",
            omit: !bank
         }, {
            name: "Keterangan",
            selector: row => row['deskripsi'],
            cell: (row) => (
               <>
                  <div className={"col-lg-12"} style={{ marginTop: "5px", marginBottom: "5px" }}>
                     <textarea
                        placeholder={"Keterangan..."}
                        rows={3}
                        style={{ resize: "none" }}
                        value={row['deskripsi']}
                        className="form-control"
                        onChange={(e) => handleInputChange(row, "deskripsi", e['target']['value'])}
                     />
                  </div>
               </>
            ),
            sortable: false,
            minWidth: "40%",
         }, {
            name: "Akun",
            selector: row => row['id_akun'],
            cell: (row) => (
               <>
                  <div className="col-lg-12">
                     <Select2
                        isSearchable={true}
                        isClearable={true}
                        placeholder={"--- Select Akun ---"}
                        styles={{
                           menu: (provided, state) => ({
                              ...provided,
                              borderBottom: '1px dotted pink',
                              paddingRight: 4,
                              paddingLeft: 4,
                              zIndex: '99999'
                           })
                        }}
                        options={this['options_akun']}
                        value={(this['options_akun'] ? this['options_akun'].find((val) => val['value'] === row['id_akun']) : "")}
                        onChange={(e) => {
                           handleInputChange(row, "id_akun", e ? e['value'] : "")
                           handleInputChange(row, "kode_akun", e ? e['kode_akun'] : "")
                        }}
                     />
                  </div>
               </>
            ),
            center: true,
            sortable: false,
            minWidth: "30%",
         }, {
            name: "Debet",
            selector: row => row['debet'],
            cell: (row) => (
               <>
                  {row['posisi'] === 'debet' ? (
                     <div className={"col-lg-12"} style={{ marginTop: "5px", marginBottom: "5px" }}>
                        <Input
                           placeholder={"Rp. 0"}
                           component={Input}
                           style={{ resize: "none" }}
                           value={(row['debet'] < 0)
                              ? "Rp. -" + separatorHarga(row['debet'].toString())
                              : separatorHarga(row['debet'].toString(), 'Rp. ')}
                           className="form-control"
                           onChange={(e) => handleInputChange(row, "debet", e['target']['value'])}
                        />
                     </div>
                  ) : null}
               </>
            ),
            center: true,
            sortable: false,
            minWidth: "20%",
         }, {
            name: "Kredit",
            selector: row => row['kredit'],
            cell: (row) => (
               <>
                  {row['posisi'] === 'kredit' ? (
                     <div className={"col-lg-12"} style={{ marginTop: "5px", marginBottom: "5px" }}>
                        <Input
                           placeholder={"Rp. 0"}
                           component={Input}
                           style={{ resize: "none" }}
                           disabled={row['kredit'] === 0 ? true : false}
                           value={(row['kredit'] < 0)
                              ? "Rp. -" + separatorHarga(row['kredit'].toString())
                              : separatorHarga(row['kredit'].toString(), 'Rp. ')}
                           className="form-control"
                           onChange={(e) => handleInputChange(row, "kredit", e['target']['value'])}
                        />
                     </div>
                  ) : null}
               </>
            ),
            center: true,
            sortable: false,
            minWidth: "20%",
         }
      ];
      return (
         <>
            {/* Start: Formik Buku Besar List */}
            <Formik
               enableReinitialize={true}
               initialValues={initialValues}
               validationSchema={Yup.object().shape({
                  tanggal: Yup.string().nullable().required(),
               })}
               onSubmit={(values, { setSubmitting, resetForm }) => {
                  let dataSend = {};
                  if (saldoDebet !== saldoKredit) {
                     dispatcher['snackbar'].show("Warning", "Jumlah Saldo Debet dan Kredit tidak Balance!", "warning");
                     this.setState({ isSubmitting: false });
                     return true;
                  } else {
                     dataSend = {
                        ...values,
                        tanggal: moment(values['tanggal']).format("YYYY-MM-DD"),
                        total_debet: saldoDebet,
                        total_kredit: saldoKredit,
                        dataTable,
                     }
                  }
                  // * ----------------------------------------------------------------------------- */
                  this['handleAlert'].show(dataSend);
               }}
            >
               {({ setFieldValue, setFieldTouched, touched, errors, values, handleSubmit }) => (
                  <AnimatedCard>
                     <CardHeader title={this['pageName']}>
                        {dataTable.length > 0 ? (
                           <CardHeaderToolbar>
                              {/* Simpan  */}
                              <ButtonLoad
                                 label={"Simpan"}
                                 pending={isSubmitting}
                                 disabled={isSubmitting}
                                 classNameIcon={"fas fa-save"}
                                 className={"btn btn-primary mx-1"}
                                 onClick={async () => handleSubmit()}
                              />
                           </CardHeaderToolbar>
                        ) : null}
                     </CardHeader>
                     <CardBody style={{ marginTop: "-20px" }}>
                        <div className={"form-group row"}>
                           {/* No. Transaksi  */}
                           <FormsFields
                              id={"no_transaksi"}
                              type={"searchBarFilter"}
                              label={"No. Transaksi"}
                              className={"col-lg-4"}
                              style={{ marginTop: "20px" }}
                              value={formValues['no_transaksi']}
                              onRequestSearch={async (val) => {
                                 await this.LoadData({ no_transaksi: val })
                              }}
                              onCancelSearch={async () => {
                                 this.setState({
                                    formValues: {
                                       no_transaksi: ""
                                    }
                                 });
                                 await this.LoadData({ no_transaksi: "" })
                              }}
                              onChange={async (val) => {
                                 this.setState({
                                    formValues: {
                                       no_transaksi: val
                                    }
                                 });
                                 if (val === null) {
                                    await this.LoadData({ no_transaksi: "" })
                                 }
                              }}
                              paramsFormik={{
                                 setFieldValue,
                                 values
                              }}
                           />
                        </div>
                        {/* Button */}
                        <div style={{ display: "flex", justifyContent: "center" }} >
                           {/* Button Lihat Data */}
                           <ButtonLoad
                              label={"Lihat Data"}
                              pending={pending}
                              classNameIcon={"fa fa-filter"}
                              className={"btn btn-outline-success"}
                              onClick={async () => await this.LoadData(formValues)}
                           />
                        </div>
                     </CardBody>
                     <CardFooter>
                        {user_update && latest_update ? (
                           <>
                              {/* Admin Update */}
                              <div className={"tab-content mt-5"}>
                                 <div className={"table-responsive"}>
                                    <Paper>
                                       <Typography variant={"h5"} component={"h3"}>
                                          {`Latest Update `}<b>{`${latest_update}`}</b>{` By `}<b>{`${user_update}`}</b>
                                       </Typography>
                                    </Paper>
                                 </div>
                              </div>
                              <hr />
                           </>
                        ) : null}
                        {dataTable.length > 0 ? (
                           <div className={"form-group row"}>
                              {/* Forms Date  */}
                              <FormsFields
                                 id={"tanggal"}
                                 type={"DatePicker"}
                                 label={"Tanggal"}
                                 className={"col-lg-4"}
                                 paramsFormik={{
                                    setFieldValue,
                                    setFieldTouched,
                                    touched,
                                    errors,
                                    values
                                 }}
                              />
                           </div>
                        ) : null}
                        <ReactDatatableDetails
                           title={`No. Transaksi: ${no_transaksi}`}
                           fixedHeaderScrollHeight={"550px"}
                           columns={columns}
                           data={dataTable}
                        />
                        <div style={{ float: "right" }}>
                           <FormsFields
                              id={'debet'}
                              type={"disabled-currency-group"}
                              labelGroup={"Total Debet :"}
                              className={"col-lg-12"}
                              style={{ marginBottom: "15px" }}
                              value={saldoDebet}
                           />
                           <FormsFields
                              id={'kredit'}
                              type={"disabled-currency-group"}
                              labelGroup={"Total Kredit :"}
                              className={"col-lg-12"}
                              style={{ marginTop: "-15px", marginBottom: "15px" }}
                              value={saldoKredit}
                           />
                        </div>
                        {/* Debet/Kredit */}
                        <div className={"tab-content mt-5"}>
                           <div className={"table-responsive"}>
                              <Paper className={useStyles().root}>
                                 <Typography variant={"h5"} component={"h3"}>
                                    Debet/Kredit : {(debetKredit < 0) ? "Rp. -" + separatorHarga(debetKredit.toString()) :
                                       separatorHarga(debetKredit.toString(), "Rp. ")}
                                 </Typography>
                              </Paper>
                           </div>
                        </div>
                     </CardFooter>
                  </AnimatedCard>
               )}
            </Formik>
            {/* ----------------------------------------------------------------------------- */}
            {/* Start: Alert Approved */}
            <SimpleAlert
               title={"Peringatan!"}
               content={"Anda yakin ingin update transaksi ini...?"}
               show={alert['show']}
               onHide={() => this['handleAlert'].hide()}
               variant={"primary"}
               label={"Ya"}
               labelHide={"Tidak"}
               onConfirmed={this['handleAlert']['confirmed']}
            />
            {/* End: Alert Approved */}
         </>
      );
   }
}
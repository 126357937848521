import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import ApprovalManagerPesananPenjualanList from "./list";
import ApprovalManagerPesananPenjualan from "./approve";
import React from "react";
import { dispatcher } from "../../../../redux/dispatcher";

export default function ApprovalManagerPesananPenjualanIndex() {
   const history = useHistory();
   const active = dispatcher['notification'].getApprovalPesananPenjualan();
   return (
      <Switch>
         <Route path={"/notifications/approval-manager-pesanan-penjualan/list"}>
            <ApprovalManagerPesananPenjualanList />
         </Route>
         {/* <Route path={"/notifications/approval-manager-pesanan-penjualan/add-form"}>
            <ApprovalManagerPesananPenjualanForms history={history} isEdit={"add"} />
         </Route> */}
         {active['master']['id'] === 0 && (
            <Redirect to={"/notifications/approval-manager-pesanan-penjualan/list"} />
         )}
         <Route path={"/notifications/approval-manager-pesanan-penjualan/approval"}>
            <ApprovalManagerPesananPenjualan history={history} isEdit={"approval"} />
         </Route>
         {/* <Route path={"/notifications/approval-manager-pesanan-penjualan/edit-form"}>
            <ApprovalManagerPesananPenjualanForms history={history} isEdit={"edit"} />
         </Route> */}
         <Redirect to="/notifications/approval-manager-pesanan-penjualan/list" />
      </Switch>
   );
}

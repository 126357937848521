import { CardBody, CardFooter, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls';
import React, { Component } from 'react';
import { AnimatedCard } from '../../../../components/animated-card/animatedCard';
import DataTableModel from '../../../../models/datatable-model/DataTableModel';
import { Formik } from 'formik';
import { FormsFields } from '../../../../components/forms-fields/FormsFields';
import { ReactDatatableFooterDetails } from '../../../../components/react-datatable-footer/reactDatatableFooter';
import { akuntansiAPI } from '../../../../api/akuntansi';
import { separatorHarga } from '../../../../services/separator-harga';
import ButtonLoad from '../../../../components/button-loading/buttonLoad';
import jurnalBalikModel from '../../../../models/akuntansi/jurnal-balik/JurnalBalikModel';
import { dispatcher } from '../../../../redux/dispatcher';
import SimpleAlert from '../../../../components/alert/alert';
import * as Yup from "yup";
import store from '../../../../redux/store';
import { ReactDatatable } from '../../../../components/react-datatable/reactDatatable';
import historyJurnalBalikModel from '../../../../models/akuntansi/jurnal-balik/HistoryJurnalBalikModel';

export default class JurnalBalik extends Component {
   constructor(props) {
      super(props);
      this['pageName'] = "Jurnal Balik";
      this['searchParameter'] = ["tanggal", "cabang", "no_transaksi", "deskripsi"];
      this['apiJurnal'] = new akuntansiAPI();
      this['state'] = {
         isLoadingSave: false,
         pending: false,
         pendingHistory: false,
         data: 0,
         no_transaksi: "",
         dataLenghtHistory: 0,
         initialValues: {
            no_transaksi: "",
            deskripsi: "",
            id_user: store.getState().auth['id'],
         },
         formValues: {
            no_transaksi: "",
         },
         footerJurnalBalikOld: {
            kode_akun: "Total",
            debet: 0,
            kredit: 0,
         },
         footerJurnalBalikNow: {
            kode_akun: "Total",
            debet: 0,
            kredit: 0,
         },
         alert: {
            dataAlert: {
               no_transaksi: "",
               deskripsi: "",
            },
            show: false,
         }
      }
      this['columnJurnalBalikOld'] = [{ id: "", title: "" }];
      this['datatableJurnalBalikOld'] = [{ id: "", no: "", name: "" }];
      this['columnJurnalBalikNow'] = [{ id: "", title: "" }];
      this['datatableJurnalBalikNow'] = [{ id: "", no: "", name: "" }];
      this['datatable'] = [{ id: "", no: "", name: "" }];
      this['columntable'] = [{ id: "", title: "" }];
   }
   //* ----------------------------------------------------------------------------- */
   //** Start: Component */
   async componentDidMount() {
      await this.LoadData(this['state']['formValues']);
   }
   //** End: Component */
   //* ----------------------------------------------------------------------------- */
   //** Start: Load Data Buku Besar */
   async LoadData(form) {
      this.setState({ pending: true });
      let data = await this['apiJurnal'].getAllJurnalBalik(form);
      // Jurnal Balik Old
      this['DataTableModelOld'] = new DataTableModel({ readOnly: true });
      data['data']['JurnalBalikOld'].map((item, i) => {
         let dataModel = new jurnalBalikModel(item);
         if (item['status'] === 1) {
            this['DataTableModelOld'].add({
               id: item['id'],
               no: i + 1,
               dataModel: dataModel,
               actions: null,
            });
         }
         return item;
      });
      const totalJurnalBalikOld = (val) => {
         if (val === 'debet') {
            return data['data']['JurnalBalikOld'].reduce((total, value) => {
               const debet = parseInt(value['debet']);
               return parseFloat(total + debet);
            }, 0);
         } else {
            return data['data']['JurnalBalikOld'].reduce((total, value) => {
               const kredit = parseInt(value['kredit']);
               return parseFloat(total + kredit);
            }, 0);
         }
      };
      //* ----------------------------------------------------------------------------- */
      // Jurnal Balik Now
      this['DataTableModelNow'] = new DataTableModel({ readOnly: true });
      data['data']['JurnalBalikNow'].map((item, i) => {
         let dataModel = new jurnalBalikModel(item);
         if (item['status'] === 1) {
            this['DataTableModelNow'].add({
               id: item['id'],
               no: i + 1,
               dataModel: dataModel,
               actions: null,
            });
         }
         return item;
      });
      const totalJurnalBalikNow = (val) => {
         if (val === 'debet') {
            return data['data']['JurnalBalikNow'].reduce((total, value) => {
               const debet = parseInt(value['debet']);
               return parseFloat(total + debet);
            }, 0);
         } else {
            return data['data']['JurnalBalikNow'].reduce((total, value) => {
               const kredit = parseInt(value['kredit']);
               return parseFloat(total + kredit);
            }, 0);
         }
      };
      this.setState({
         dataLenghtHistory: 0,
         no_transaksi: data['data']['no_transaksi'],
         data: data['data']['JurnalBalikNow'].length,
         footerJurnalBalikOld: {
            kode_akun: "Total",
            debet: totalJurnalBalikOld("debet") < 0 ? `Rp. -${separatorHarga(totalJurnalBalikOld("debet").toString())}` : separatorHarga(totalJurnalBalikOld("debet").toString(), "Rp. "),
            kredit: totalJurnalBalikOld("kredit") < 0 ? `Rp. -${separatorHarga(totalJurnalBalikOld("kredit").toString())}` : separatorHarga(totalJurnalBalikOld("kredit").toString(), "Rp. "),
         },
         footerJurnalBalikNow: {
            kode_akun: "Total",
            debet: totalJurnalBalikNow("debet") < 0 ? `Rp. -${separatorHarga(totalJurnalBalikNow("debet").toString())}` : separatorHarga(totalJurnalBalikNow("debet").toString(), "Rp. "),
            kredit: totalJurnalBalikNow("kredit") < 0 ? `Rp. -${separatorHarga(totalJurnalBalikNow("kredit").toString())}` : separatorHarga(totalJurnalBalikNow("kredit").toString(), "Rp. "),
         }
      });
      this['datatableJurnalBalikOld'] = await this['DataTableModelOld'].getDatas();
      this['columnJurnalBalikOld'] = await this['DataTableModelOld'].getColumn();
      this['datatableJurnalBalikNow'] = await this['DataTableModelNow'].getDatas();
      this['columnJurnalBalikNow'] = await this['DataTableModelNow'].getColumn();
      this.setState({ ...this.state, pending: false });
   }
   //** End: Load Data List */
   //* ----------------------------------------------------------------------------- */
   //** Start: Load Data Buku Besar */
   async LoadDataHistory() {
      this.setState({ pendingHistory: true });
      let data = await this['apiJurnal'].getAllHistoryJurnalBalik();
      if (data['data'].length === 0) {
         dispatcher['snackbar'].show("Information", "History Jurnal Balik masih kosong.", "danger");
         this.setState({ pendingHistory: false });
         return true;
      }
      this.setState({
         dataLenghtHistory: data['data'].length,
         no_transaksi: "",
         data: 0,
      });
      // Jurnal Balik Old
      this['DataTableModel'] = new DataTableModel({ readOnly: true });
      data['data'].map((item, i) => {
         let dataModel = new historyJurnalBalikModel(item);
         if (item['status'] === 1) {
            this['DataTableModel'].add({
               id: item['id'],
               no: i + 1,
               dataModel: dataModel,
               actions: null,
            });
         }
         return item;
      });
      this['datatable'] = await this['DataTableModel'].getDatas();
      this['columntable'] = await this['DataTableModel'].getColumn();
      this.setState({ ...this.state, pendingHistory: false });
   }
   //** End: Load Data List */
   //* ----------------------------------------------------------------------------- */
   //** Start: Handle Alert */
   handleAlert = {
      show: (data) => {
         this.setState({
            alert: {
               show: true,
               dataAlert: data
            }
         });
      },
      hide: () => {
         let alert = { ...this['state'] };
         alert = {
            alert: {
               show: false,
               dataAlert: null
            }
         }
         this.setState({ ...alert });
      },
      confirmed: async () => {
         await this['apiJurnal'].setJurnalBalik(this['state']['alert']['dataAlert']).then((res) => {
            if (res['status']) {
               dispatcher['snackbar'].show("Information", res.message, "primary");
               this['handleAlert'].hide();
               this.LoadData({
                  no_transaksi: "",
                  formValues: {
                     no_transaksi: ""
                  }
               });
            } else {
               return dispatcher['snackbar'].show("Warning", res.message, "danger");
            }
         });
      },
   };
   //** End: Handle Alert */
   //* ----------------------------------------------------------------------------- */
   render() {
      const { pending, pendingHistory, initialValues, formValues, no_transaksi, data, isLoadingSave, dataLenghtHistory, alert } = this['state'];
      return (
         <>
            {/* Start: Formik Buku Besar List */}
            <Formik
               enableReinitialize={true}
               initialValues={initialValues}
               validationSchema={Yup.object().shape({
                  deskripsi: Yup.string()
                     .max(150, "Maximum 150 String")
                     .required("Keterangan harus diisi")
               })}
               onSubmit={(values, resetForm) => {
                  this['handleAlert'].show(values);
               }}
            >
               {({ setFieldValue, setFieldTouched, touched, errors, values, handleSubmit }) => (
                  <AnimatedCard>
                     <CardHeader title={this.pageName}>
                        {data > 0 ? (
                           <CardHeaderToolbar>
                              {/* Simpan  */}
                              <ButtonLoad
                                 label={"Simpan"}
                                 pending={isLoadingSave}
                                 disabled={isLoadingSave}
                                 classNameIcon={"fas fa-save"}
                                 className={"btn btn-primary mx-1"}
                                 onClick={async () => handleSubmit()}
                              />
                           </CardHeaderToolbar>
                        ) : null}
                     </CardHeader>
                     <CardBody style={{ marginTop: "-20px" }}>
                        <div className={"form-group row"}>
                           {/* No. Transaksi  */}
                           <FormsFields
                              id={"no_transaksi"}
                              type={"searchBarFilter"}
                              label={"No. Transaksi"}
                              className={"col-lg-4"}
                              style={{ marginTop: "20px" }}
                              value={no_transaksi}
                              onRequestSearch={async (val) => {
                                 await this.LoadData(formValues)
                                 setFieldValue("no_transaksi", val)
                              }}
                              onCancelSearch={async () => {
                                 this.setState({
                                    no_transaksi: "",
                                    formValues: {
                                       no_transaksi: ""
                                    }
                                 });
                                 await this.LoadData({
                                    no_transaksi: "",
                                    formValues: {
                                       no_transaksi: ""
                                    }
                                 });
                                 setFieldValue("no_transaksi", "");
                              }}
                              onChange={(val) => {
                                 this.setState({
                                    formValues: {
                                       no_transaksi: val
                                    }
                                 });
                                 setFieldValue("no_transaksi", val)
                                 if (val === null) {
                                    this.LoadData({
                                       formValues: {
                                          no_transaksi: ""
                                       }
                                    });
                                 }
                              }}
                              paramsFormik={{
                                 setFieldValue,
                                 values
                              }}
                           />
                        </div>
                        {/* Button */}
                        <div style={{ display: "flex", justifyContent: "center" }} >
                           {/* Button Lihat Data */}
                           <ButtonLoad
                              label={"Lihat Data"}
                              pending={pending}
                              classNameIcon={"fa fa-filter"}
                              className={"btn btn-outline-success"}
                              onClick={async () => {
                                 await this.LoadData(formValues)
                              }}
                           />
                           {/* Button View History */}
                           <ButtonLoad
                              label={"View History"}
                              pending={pendingHistory}
                              classNameIcon={"fas fa-align-justify"}
                              className={"btn btn-outline-info"}
                              onClick={async () => await this.LoadDataHistory()}
                           />
                        </div>
                     </CardBody>
                     <CardFooter>
                        {dataLenghtHistory > 0 ? (
                           <>
                              <ReactDatatable
                                 title={""}
                                 columns={this['columntable']}
                                 data={this['datatable']}
                                 searchParameter={this['searchParameter']}
                                 progressPending={pending}
                              />
                           </>
                        ) :
                           (
                              <>
                                 {data > 0 ? (
                                    <div className={"form-group row"}>
                                       {/* Deskripsi/Keterangan  */}
                                       <FormsFields
                                          id={"deskripsi"}
                                          type={"textarea"}
                                          label={"Keterangan"}
                                          className={"col-lg-6"}
                                          style={{ marginTop: "20px", marginBottom: "20px" }}
                                          rows={2}
                                          paramsFormik={{
                                             setFieldValue,
                                             setFieldTouched,
                                             touched,
                                             errors,
                                             values
                                          }}
                                       />
                                    </div>
                                 ) : null}
                                 <div>
                                    {/* Jurnal Balik Old */}
                                    <ReactDatatableFooterDetails
                                       title={`Sebelum, No. Transaksi: ${no_transaksi}`}
                                       data={this['datatableJurnalBalikOld']}
                                       columns={this['columnJurnalBalikOld']}
                                       footer={this.state['footerJurnalBalikOld']}
                                    />
                                 </div>
                                 <div style={{ marginTop: "40px" }}>
                                    {/* Jurnal Balik Now */}
                                    <ReactDatatableFooterDetails
                                       title={`Setelah, No. Transaksi: R-${no_transaksi}`}
                                       data={this['datatableJurnalBalikNow']}
                                       columns={this['columnJurnalBalikNow']}
                                       footer={this.state['footerJurnalBalikNow']}
                                    />
                                 </div>
                              </>
                           )
                        }
                     </CardFooter>
                  </AnimatedCard>
               )}
            </Formik>
            {/* End: Formik Buku Besar List */}
            {/* start: Alert Hapus */}
            <SimpleAlert
               title={"Confirmation"}
               content={"Anda yakin simpan data ini...?"}
               show={alert['show']}
               label={"Ya"}
               labelHide={"Tidak"}
               variant={"primary"}
               onHide={() => this['handleAlert'].hide()}
               onConfirmed={this['handleAlert']['confirmed']}
            />
            {/* End: Alert Hapus */}
         </>
      );
   }
}
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { setRoleMenu } from "../../../api/roles";
import { menuAction } from "../../../redux/menu/actions";
import store from "../../../redux/store";
import { getAllMenuSidebar } from "../../../api/menu";
import { Card } from "react-bootstrap";

export function ModalDialogEditMenu(params) {
  const DataEditSchema = Yup.object().shape({});

  const [checked, setChecked] = useState([]);
  useEffect(() => {
    setChecked(params.initialData.checked);
  }, [params.initialData.checked]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={params.initialData}
        validationSchema={DataEditSchema}
        onSubmit={async ({ resetForm }) => {
          await setRoleMenu(params.initialData);
          let menus = await getAllMenuSidebar(store.getState().auth.role).then(
            (val) => val.data
          );
          store.dispatch({ type: menuAction.setMenus, payload: menus });
          params.onHide();
          resetForm();
        }}
      >
        {({ handleSubmit }) => (
          <Modal show={params.show} onHide={params.onHide}>
            <Modal.Header closeButton>
              <Modal.Title>{params.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="overlay overlay-block cursor-default">
              <Form className="form form-label-right">
                {params.menus.map((menu, i) => (
                  <div key={menu.id} className="form-group row">
                    <div className="col-lg-12">
                      <Card border="primary" className="p-4">
                        <div className="checkbox-inline">
                          <label className="checkbox">
                            <Field
                              key={menu.id}
                              id={menu.id}
                              type="checkbox"
                              name="checked"
                              value={`${menu.id}`}
                              checked={checked.includes(menu.id)}
                              onClick={() =>
                                params.onChangeParent(menu.id, menu.child)
                              }
                            />
                            <span></span>
                            {menu.nama}
                          </label>
                        </div>
                        {menu.child.map((child) => (
                          <div key={child.id} className="ml-8">
                            <div className="checkbox-inline">
                              <label className="checkbox">
                                <Field
                                  key={child.id}
                                  id={child.id}
                                  type="checkbox"
                                  name="checked"
                                  value={`${child.id}`}
                                  checked={checked.includes(child.id)}
                                  onClick={() =>
                                    params.onChange(
                                      child.id,
                                      menu.id,
                                      menu.child
                                    )
                                  }
                                />
                                <span></span>
                                {child.nama}
                              </label>
                            </div>
                          </div>
                        ))}
                      </Card>
                    </div>
                  </div>
                ))}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={params.onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
}

// export class ModalDialogEditMenu extends React.Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       checked: [],
//     };
//   }

//   componentDidMount() {
//     this.setState({
//       checked: this.props.initialData.checked,
//     });
//   }

//   render() {
//     return (
//       <>
//         <Formik
//           enableReinitialize={true}
//           initialValues={this.props.initialData}
//           validationSchema={Yup.object().shape({})}
//           onSubmit={async (values, { resetForm }) => {
//             await setRoleMenu(values);
//             this.props.onHide();
//             resetForm();
//           }}
//         >
//           {({ handleSubmit }) => (
//             <Modal show={this.props.show} onHide={this.props.onHide}>
//               <Modal.Header closeButton>
//                 <Modal.Title>{this.props.title}</Modal.Title>
//               </Modal.Header>
//               <Modal.Body className="overlay overlay-block cursor-default">
//                 <Form className="form form-label-right">
//                   <div className="form-group row">
//                     <div className="col-lg-12">
//                       {this.props.menus.map((menu, i) => (
//                         <div key={menu.id}>
//                           <label>
//                             <span></span>
//                             {menu.nama}
//                           </label>
//                           {menu.child.map((child) => (
//                             <div key={child.id} className="ml-5">
//                               <div className="checkbox-inline">
//                                 <label className="checkbox">
//                                   <Field
//                                     key={child.id}
//                                     id={child.id}
//                                     type="checkbox"
//                                     name="checked"
//                                     value={`${child.id}`}
//                                     checked={this.state.checked.includes(
//                                       child.id
//                                     )}
//                                     onClick={() => {
//                                       if (
//                                         this.state.checked.includes(child.id)
//                                       ) {
//                                         let checkeds = this.state.checked;
//                                         checkeds.splice(
//                                           checkeds.indexOf(child.id),
//                                           1
//                                         );
//                                         this.setState({
//                                           checked: checkeds,
//                                         });
//                                       } else if (
//                                         !this.state.checked.includes(child.id)
//                                       ) {
//                                         this.setState({
//                                           checked: [
//                                             ...this.state.checked,
//                                             child.id,
//                                           ],
//                                         });
//                                       }
//                                     }}
//                                   />
//                                   <span></span>
//                                   {child.nama}
//                                 </label>
//                               </div>
//                             </div>
//                           ))}
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                 </Form>
//               </Modal.Body>
//               <Modal.Footer>
//                 <button
//                   type="button"
//                   onClick={this.props.onHide}
//                   className="btn btn-light btn-elevate"
//                 >
//                   Cancel
//                 </button>
//                 <> </>
//                 <button
//                   type="submit"
//                   onClick={() => handleSubmit()}
//                   className="btn btn-primary btn-elevate"
//                 >
//                   Save
//                 </button>
//               </Modal.Footer>
//             </Modal>
//           )}
//         </Formik>
//       </>
//     );
//   }
// }

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { dispatcher } from '../../../../../app/redux/dispatcher';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../../../_helpers';
export default class QuickNotification extends Component {
  render() {
    const data = dispatcher['panelNotification'].get();
    return (
      <>
        {/* begin::Notifications Panel */}
        <div id="kt_quick_notifications" className="offcanvas offcanvas-left p-10">
          {/* begin::Header */}
          <div className="offcanvas-header d-flex align-items-center justify-content-between mb-10">
            <h3 className="font-weight-bold m-0">
              Notifications
              <small className="text-muted font-size-sm ml-2">{data['allCount']} New</small>
            </h3>
            <a
              href="#"
              className="btn btn-xs btn-icon btn-light btn-hover-primary"
              id="kt_quick_notifications_close"
            >
              <i className="ki ki-close icon-xs text-muted"></i>
            </a>
          </div>
          {/* end::Header */}

          {/* begin::Content */}
          <div className="offcanvas-content pr-5 mr-n5">
            {/* begin::Nav */}
            <div className="navi navi-icon-circle navi-spacer-x-0">
              {/* begin::Item */}
              {data['subMenus'] ?
                (data['subMenus'].map((val, i) => {
                  return (
                    <Link to={val['path']} className="navi-item" key={i}>
                      <div className="navi-link">
                        <div className="symbol symbol-40 bg-light mr-3">
                          <div className="symbol-label">
                            <span className="svg-icon svg-icon-md svg-icon-success">
                              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Mail-notification.svg")}></SVG>
                            </span>
                          </div>
                        </div>
                        <div className="navi-text">
                          <div className="font-weight-bold">{`${val['title']}`}</div>
                          <div className="text-muted">
                            {`${val['subTitle']}`}
                            {/* <span className="label label-light-danger label-inline font-weight-bold">
                              update
                            </span> */}
                          </div>
                        </div>
                      </div>
                    </Link>)
                }))
                : (<div className="navi-text">
                  <div className="text-muted">Notification is Empty...</div>
                </div>)
              }
            </div>
            {/* end::Nav */}
          </div>
          {/* end::Content */}
        </div >
        {/* end::Notifications Panel */}
      </>
    )
  }
}

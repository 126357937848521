/* eslint-disable eqeqeq */
import * as Yup from "yup";
import { MarginHargaJualAPI } from "../../api/margin-harga-jual";
import { ProdukAPI } from "../../api/produk";
import { ProdukMasterAPI } from "../../api/produk-master";
import { separatorHarga, hapusSeparator } from "../../services/separator-harga";
import store from "../../redux/store";
export default class ProdukDetailModel {
  constructor(
    data = {
      id: 0,
      id_produk_master: 0,
      id_supplier: 0,
      id_perusahaan_login: store.getState().auth.id_perusahaan,
      id_perusahaan: store.getState().auth.id_perusahaan,
      toSupplier: { nama: "" },
      id_produk_kategori: 0,
      toProdukKategori: { nama: "" },
      id_produk_satuan: 0,
      toProdukSatuan: { nama: "" },
      id_merek: 0,
      toMerek: { nama: "" },
      toPerusahaan: { singkat: "", kode_perusahaan: "" },
      nama: "",
      kode: "",
      harga_beli: "",
      harga_jual: "",
      link: "",
      barcode: "",
      persediaan_total: 0,
    }
  ) {
    this.id = data.id;
    this.id_produk_master = data.id_produk_master;
    this.id_supplier = data.id_supplier;
    this.id_perusahaan = data.id_perusahaan;
    this.id_perusahaan_login = data.id_perusahaan_login;
    this.nama_perusahaan = data.toPerusahaan.singkat;
    this.kode_perusahaan = data.toPerusahaan.kode_perusahaan;
    this.nama_supplier = data.toSupplier.nama;
    this.id_produk_kategori = data.id_produk_kategori;
    this.nama_produk_kategori = data.toProdukKategori.nama;
    this.id_produk_satuan = data.id_produk_satuan;
    this.nama_produk_satuan = data.toProdukSatuan.nama;
    this.nama = data.nama;
    this.kode = data.kode;
    this.id_merek = data.id_merek;
    this.nama_merek = data.toMerek.nama;
    this.harga_beli = separatorHarga(data.harga_beli.toString());
    this.harga_jual = separatorHarga(data.harga_jual.toString());
    this.link = data.link;
    this.barcode = data.barcode;
    this.persediaan_total = data.persediaan_total;
  }

  removeSeparator() {
    this.id_perusahaan = parseInt(this.id_perusahaan)
    this.harga_jual = parseInt(hapusSeparator(this.harga_jual));
    this.harga_beli = parseInt(hapusSeparator(this.harga_beli));
  }

  hargaJualMargin = async (dt) => {
    return await new MarginHargaJualAPI().getAll().then((res) => {
      let datas = res.data;
      console.log('data',datas);
      console.log('id_kategori',this.id_produk_kategori);
      let sama = datas.filter((val) => val.id_produk_kategori == dt)
      let beda = datas.filter((val) => val.toProdukKategori === null)
      let master;
      if (sama.length > 0) {
         master = sama
      } 
      if (sama.length === 0) {
        master = beda
      }
      let arr = master.map((data) => {
        return data.margin
      })
      const max = Math.max(...arr)
      return max
    }
    )
  }

  getProdukMaster = async (id) => {
    return await new ProdukMasterAPI().getSingle(id).then((res) => {
      let datas = res.data;
      return datas
    })
  }

  async getEditable(params = { pageName: "", isForm: false }) {
    let options_perusahaan = [];
    let options_produk = [];
    if (params.isForm) {
      let ksa = [20,46,52,69]
      let kpp = [68,28,70,71]
      let ksb = [105,108,109,110]

      let dataProduk = await new ProdukMasterAPI().getAll()
      let filter = dataProduk.data.filter((fil) => 
      ksa.includes(this.id_perusahaan_login) ? (fil.id_pt === 2) : 
      kpp.includes(this.id_perusahaan_login) ? (fil.id_pt === 1) :
      ksb.includes(this.id_perusahaan_login) ? (fil.id_pt === 3) 
      : "");

        filter.map((data) => {
         return options_produk = [
            ...options_produk,
            { id: data.id, label: data.nama, value: data.id },
          ]
        })

      await new ProdukAPI()
        .getCoverage()
        .then((response) =>
          response.data.map(
            (data, i) =>
            (options_perusahaan = [
              ...options_perusahaan,
              { id: data.id, text: data.text, value: data.id },
            ])
          )
        );
    }
    return [
      {
        validation: Yup.string().required("Nama harus diisi"),
        label: "Nama " + params.pageName,
        dataField : "nama",
        type: "select2",
        options: options_produk,
        value: this.nama,
        defaultValue: {label: this.nama},
        onChange: (e, setFieldValue) => {
          this.id_produk_master = e ? e.value : ""
          this.getProdukMaster(e.value).then((res) => {
            // console.log(res);
            this.id_produk_kategori = res.id_produk_kategori;
            let nama_produk_kategori = res.toProdukKategori.nama;
            this.id_supplier = res.id_supplier;
            let nama_supplier = res.toSupplier.nama;
            this.id_produk_satuan = res.id_produk_satuan;
            let nama_produk_satuan = res.toProdukSatuan.nama;
            this.kode = res.kode;
            this.nama = res.nama;
            this.id_merek = res.id_merek;
            this.nama_merek = res.toMerek.nama;
            setFieldValue("id_produk_kategori",this.id_produk_kategori)
            setFieldValue("nama_produk_kategori",nama_produk_kategori)
            setFieldValue("id_supplier",this.id_supplier)
            setFieldValue("nama_supplier",nama_supplier)
            setFieldValue("id_produk_satuan",this.id_produk_satuan)
            setFieldValue("nama_produk_satuan",nama_produk_satuan)
            setFieldValue("kode",this.kode)
            setFieldValue("nama",this.nama)
            setFieldValue("id_merek",this.id_merek)
            setFieldValue("nama_merek",this.nama_merek)
          })
        },
      },
      {
        dataField: "id_perusahaan",
        label: "Cabang",
        type: "select",
        options: options_perusahaan,
        value: `${this.nama_perusahaan} - ${this.kode_perusahaan}`,
        onChange: (e) => {
          this.id_perusahaan = parseInt(e.target.value)
          console.log('id_perusahaan',this.id_perusahaan);
        },
      },
      {
        validation: Yup.string().required("Supplier harus diisi"),
        dataField: "nama_supplier",
        label: "Supplier",
        type: "disabled-text",
        onChange: (e) => {
          this.nama_supplier = e.target.value
          // console.log('cek id_supplier', this.nama_supplier);
        },
        // options: options_supplier,
        value: this.nama_supplier,
      },
      {
        validation: Yup.string().required("Produk kategori harus diisi"),
        dataField: "nama_produk_kategori",
        label: "Produk Kategori",
        type: "disabled-text",
        onChange: () => { },
        // options: options_produk_kategori,
        value: this.nama_produk_kategori,
      },
      {
        validation: Yup.string().required("Produk satuan harus diisi"),
        dataField: "nama_produk_satuan",
        label: "Produk Satuan",
        type: "disabled-text",
        onChange: (val) => { },
        // options: options_produk_satuan,
        value: this.nama_produk_satuan,
      },
      {
        validation: Yup.string().required("Kode harus diisi"),
        dataField: "kode",
        label: "Kode " + params.pageName,
        type: "disabled-text",
        value: this.kode,
      },
      {
        validation: Yup.string().required("Merek harus diisi"),
        dataField: "nama_merek",
        label: "Merek",
        type: "disabled-text",
        // options: options_produk_kategori,
        value: this.nama_merek,
      },
      {
        validation: Yup.string().required("Harga beli harus diisi"),
        dataField: "harga_beli",
        label: "Harga Beli " + params.pageName,
        type: "currency",
        value: this.harga_beli,
        onChange: (e, setFieldValue) => {
          this.harga_beli = e.target.value;
          this.hargaJualMargin(this.id_produk_kategori).then((val) => {
            let hargaBeli = parseInt(hapusSeparator(this.harga_beli))
            let margin = parseInt(val)/100
            let hargaMargin =
            Math.ceil(hargaBeli * margin)
            let hargaJual = hargaBeli + hargaMargin
            this.harga_jual = separatorHarga((hargaJual).toString())
            console.table(hargaBeli, hargaMargin, hargaJual);
            setFieldValue("harga_jual", this.harga_jual)
          })
        }
      },
      {
        dataField: "harga_jual",
        label: "Harga Jual",
        type: "currency",
        value: this.harga_jual,
        onChange: (e) => {
          this.harga_jual = e.target.value
        }
      },
      {
        validation: Yup.string(),
        dataField: "link",
        label: "Tokopedia Link",
        type: "text",
        value: this.link,
        invisibleOnTable: true,
      },
      {
        validation: Yup.string(),
        dataField: "barcode",
        label: "Barcode",
        type: "text",
        value: this.barcode,
        invisibleOnTable: true,
      },
    ];
  }
}

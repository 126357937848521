const initialValues = {
    active: null,
    list: [],
  };
  
  export const userLoginAction = {
    list: "LIST_USER_LOGIN",
    active: "ACTIVE_USER_LOGIN",
    resetActive: "RESET_ACTIVE_USER_LOGIN",
  };
  
  const reducer = (state = initialValues, action) => {
    let states;
    switch (action.type) {
      case userLoginAction.list:
        states = state;
        states.list = action.payload;
        return (state = states);
      case userLoginAction.active:
        states = state;
        states.active = action.payload;
        return (state = states);
      case userLoginAction.resetActive:
        states = state;
        states.active = null;
        return (state = states);
      default:
        return state;
    }
  };
  
  export default reducer;
  
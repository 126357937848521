import React from "react";
import { Alert, Modal } from "react-bootstrap";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import { useSelector } from "react-redux";
import store from "../../redux/store";
import { messageAction } from "../../redux/message/actions";

export function SnackBarMessage(params) {
  const modal = useSelector((state) => state.message);
  const handleClose = () => {
    store.dispatch({ type: messageAction.hideMessage });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={modal.show}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert
          className="m-0"
          variant={modal.variant}
          onClose={handleClose}
          style={{ minWidth: "300px" }}
          dismissible
        >
          <Typography variant="subtitle1">{modal.title}</Typography>
          <Typography variant="subtitle1">{modal.message}</Typography>
        </Alert>
      </Snackbar>
      <>{params.children}</>
    </>
  );
}

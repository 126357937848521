import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from "yup";
import { AnimatedCard } from '../../../../components/animated-card/animatedCard';
import { CardBody, CardFooter, CardHeader } from '../../../../../_metronic/_partials/controls';
import { FormsFields } from '../../../../components/forms-fields/FormsFields';
import { kasirLaporanAPI } from '../../../../api/kasir-laporan';
import { ReactDatatableFooter } from '../../../../components/react-datatable-footer/reactDatatableFooter';
import listTransaksiBankOperasionalModel from '../../../../models/kasir/laporan/laporan-bank-operasional/ListTransaksiBankOperasionalModel';
import outstandingVoucherBankOperasionalModel from '../../../../models/kasir/laporan/laporan-bank-operasional/OutstandingVoucherBankOperasionalModel';
import DataTableModel from '../../../../models/datatable-model/DataTableModel';
import { Paper, Typography, makeStyles } from '@material-ui/core';
import { separatorHarga } from '../../../../services/separator-harga';
import { export_excel } from "../../../../components/export-excel/export_excel";
import { getIdPerusahaan } from "../../../../api/api";
import ButtonLoad from '../../../../components/button-loading/buttonLoad';
import moment from 'moment';

function simulateNetworkRequest ()
{
   return new Promise( resolve => setTimeout( resolve, 1000 ) );
}
export default class LaporanBankOperasional extends Component
{
   constructor( props )
   {
      super( props );
      this[ 'pageName' ] = "Laporan Bank Operasional";
      this[ 'pageNameTable1' ] = "List Transaksi";
      this[ 'pageNameTable2' ] = "Outstanding Voucher";
      this[ 'searchParameter' ] = [ "no_voucher", "admin", "bank", "tanggal", "keterangan" ];
      this[ 'apiLaporanBankOperasional' ] = new kasirLaporanAPI();
      this[ 'export_excel' ] = new export_excel();
      this[ 'initialModelListTransaksi' ] = new listTransaksiBankOperasionalModel();
      this[ 'initialModelOutstandingVoucher' ] = new outstandingVoucherBankOperasionalModel();
      this[ 'newDateMonth' ] = ( new Date().getMonth() + 1 ).toString();
      this[ 'month' ] = this[ 'newDateMonth' ].length > 1 ? this[ 'newDateMonth' ] : "0" + this[ 'newDateMonth' ];
      this[ 'options_cabang' ] = [];
      this[ 'options_bank' ] = [];
      this[ 'state' ] = {
         pending: true,
         isLoading: false,
         initialValues: {
            start_date: new Date( this[ 'month' ] + "-01-" + new Date().getFullYear() ),
            end_date: new Date(),
            id_perusahaan: getIdPerusahaan(),
            id_bank: "",
         },
         dataList: [],
         dataOutVou: [],
         saldo_awal: 0,
         saldo_akhir: 0,
         footerTransaksiList: {
            keterangan: "Sub Total",
            debet: 0,
            kredit: 0,
         },
         footerOutstandingVoucher: {
            keterangan: "Sub Total",
            debet: 0,
            kredit: 0,
         }
      }
      this[ 'columntableList' ] = [ { id: "", title: "" } ];
      this[ 'datatableList' ] = [ { id: "", no: "", name: "" } ];
      this[ 'columntableOutVou' ] = [ { id: "", title: "" } ];
      this[ 'datatableOutVou' ] = [ { id: "", no: "", name: "" } ];
   }
   //* ----------------------------------------------------------------------------- */
   //** Start: Component */
   async componentDidMount ()
   {
      await this.LoadOptionBank( this[ 'state' ][ 'initialValues' ][ 'id_perusahaan' ] );
      await this.LoadData( this[ 'state' ][ 'initialValues' ] );
      // Get Cabang Coverage
      this[ 'apiLaporanBankOperasional' ].getCoverage().then( ( values ) =>
      {
         this[ 'options_cabang' ] = values[ 'data' ].map( ( data ) => ( {
            label: data[ 'text' ],
            value: data[ 'id' ],
         } ) );
      } );
   }
   //** End: Component */
   //* ----------------------------------------------------------------------------- */
   //** Start: Load Data List (Transaksi & Outstanding Voucher) */
   // LoadData
   async LoadData ( form )
   {
      this.setState( { pending: true } );
      let data = await this[ 'apiLaporanBankOperasional' ].getAllBankOperasional( form );
      this.setState( { dataList: data[ 'data' ][ 'transaksi_list' ] } );
      this.setState( { dataOutVou: data[ 'data' ][ 'outstanding_voucher' ] } );
      this.setState( { saldo_awal: data[ 'data' ][ 'saldo_awal' ] } );
      // Table 1 : List Transaksi
      this[ 'DataTableModelList' ] = new DataTableModel( { readOnly: true } );
      data[ 'data' ][ 'transaksi_list' ].map( ( item, i ) =>
      {
         let dataModel = new listTransaksiBankOperasionalModel( item );
         this[ 'DataTableModelList' ].add( {
            id: item[ 'id' ],
            no: i + 1,
            dataModel: dataModel,
            actions: null,
         } );
         return item;
      } );
      const totalList = ( val ) =>
      {
         if ( val === 'debet' )
         {
            return data[ 'data' ][ 'transaksi_list' ].reduce( ( total, value ) =>
            {
               if ( value[ 'debet' ] < 0 )
               {
                  return ( total - value[ 'debet' ] );
               } else
               {
                  return ( total + value[ 'debet' ] );
               }
            }, 0 );
         } else
         {
            return data[ 'data' ][ 'transaksi_list' ].reduce( ( total, value ) =>
            {
               if ( value[ 'kredit' ] < 0 )
               {
                  return ( total - value[ 'kredit' ] );
               } else
               {
                  return ( total + value[ 'kredit' ] );
               }
            }, 0 );
         }
      };
      this.setState( {
         footerTransaksiList: {
            keterangan: "Sub Total",
            debet: totalList( "debet" ) < 0 ? `Rp. -${ separatorHarga( totalList( "debet" ).toString() ) }` : separatorHarga( totalList( "debet" ).toString(), "Rp. " ),
            kredit: totalList( "kredit" ) < 0 ? `Rp. -${ separatorHarga( totalList( "kredit" ).toString() ) }` : separatorHarga( totalList( "kredit" ).toString(), "Rp. " ),
         }
      } );
      // Table 2 : Outstanding Voucher
      this[ 'DataTableModelOutVou' ] = new DataTableModel( { readOnly: true } );
      data[ 'data' ][ 'outstanding_voucher' ].map( ( item, i ) =>
      {
         let dataModel = new outstandingVoucherBankOperasionalModel( item );
         this[ 'DataTableModelOutVou' ].add( {
            id: item[ 'id' ],
            no: i + 1,
            dataModel: dataModel,
            actions: null,
         } );
         return item;
      } );
      const totalOutVou = ( val ) =>
      {
         if ( val === 'debet' )
         {
            return data[ 'data' ][ 'outstanding_voucher' ].reduce( ( total, value ) =>
            {
               return ( total + value[ 'debet' ] );
            }, 0 );
         } else
         {
            return data[ 'data' ][ 'outstanding_voucher' ].reduce( ( total, value ) =>
            {
               return ( total + value[ 'kredit' ] );
            }, 0 );
         }
      };
      this.setState( {
         footerOutstandingVoucher: {
            keterangan: "Sub Total",
            debet: totalOutVou( "debet" ) < 0 ? `Rp. -${ separatorHarga( totalOutVou( "debet" ).toString() ) }` : separatorHarga( totalOutVou( "debet" ).toString(), "Rp. " ),
            kredit: totalOutVou( "kredit" ) < 0 ? `Rp. -${ separatorHarga( totalOutVou( "kredit" ).toString() ) }` : separatorHarga( totalOutVou( "kredit" ).toString(), "Rp. " ),
         }
      } );
      this.setState( { saldo_akhir: data[ 'data' ][ 'saldo_akhir' ] } );
      this[ 'columntableList' ] = await this[ 'DataTableModelList' ].getColumn();
      this[ 'datatableList' ] = await this[ 'DataTableModelList' ].getDatas();
      this[ 'columntableOutVou' ] = await this[ 'DataTableModelOutVou' ].getColumn();
      this[ 'datatableOutVou' ] = await this[ 'DataTableModelOutVou' ].getDatas();
      this.setState( { ...this.state, pending: false } );
   }
   //** End: Load Data List */
   //* ----------------------------------------------------------------------------- */
   //** Start: Load Akun */
   async LoadOptionBank ( id )
   {
      // Get Bank
      this.setState( { isLoading: true }, () =>
      {
         simulateNetworkRequest().then( () =>
         {
            this[ 'apiLaporanBankOperasional' ].getBankOperasional( id ).then( ( values ) =>
            {
               this.options_bank = values.data.map( ( val ) => ( {
                  label: val[ 'text' ] + " (" + val[ 'no_rekening' ] + ")",
                  value: val[ 'id' ],
               } ) );
            } );
            this.setState( { isLoading: false } );
         } );
      } );
   }
   //** End: Load Akun */
   render ()
   {
      // Style
      const useStyles = makeStyles( theme => ( {
         root: {
            textTransform: "uppercase",
            textAlign: "right",
            padding: theme.spacing( 1, 2 ),
            backgroundColor: "#f3f6f9",
         },
      } ) );
      const { pending, isLoading, initialValues, dataList, dataOutVou, saldo_awal, saldo_akhir } = this[ 'state' ];
      return (
         <>
            <Formik
               enableReinitialize={ true }
               initialValues={ initialValues }
               validationSchema={ Yup.object().shape( {
                  start_date: Yup.string().nullable().required(),
                  // id_perusahaan: Yup.string().required("Cabang harus diisi"),
               } ) }
               onSubmit={ ( values ) =>
               {
                  this.LoadData( values );
               } }
            >
               { ( { setFieldValue, setFieldTouched, touched, errors, values, handleSubmit } ) => (
                  <AnimatedCard>
                     <CardHeader title={ this.pageName }></CardHeader>
                     <CardBody style={ { marginTop: "-20px" } }>
                        <div className="form-group row">
                           {/* Forms Start Date  */ }
                           <FormsFields
                              id={ "start_date" }
                              type={ "DatePicker" }
                              label={ "Tanggal Mulai" }
                              className={ "col-lg-4" }
                              style={ { marginTop: "20px" } }
                              paramsFormik={ {
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              } }
                           />
                           {/* Forms End Date  */ }
                           <FormsFields
                              id={ "end_date" }
                              type={ "DatePicker" }
                              label={ "Tanggal Akhir" }
                              className={ "col-lg-4" }
                              style={ { marginTop: "20px" } }
                              paramsFormik={ {
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              } }
                           />
                           {/* Forms Select Cabang */ }
                           <FormsFields
                              id={ "id_perusahaan" }
                              type={ "select2" }
                              label={ "Cabang" }
                              style={ { marginTop: "20px" } }
                              className={ "col-lg-4" }
                              isClearable={ true }
                              isSearchable={ true }
                              options={ this[ 'options_cabang' ] }
                              onChange={ ( val ) =>
                              {
                                 setFieldValue( "id_bank", "" )
                                 this.LoadOptionBank( val ? val[ 'value' ] : "" );
                              } }
                              paramsFormik={ {
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              } }
                           />
                           {/* Forms Select Bank */ }
                           <FormsFields
                              id={ "id_bank" }
                              type="select2"
                              label={ "Bank" }
                              style={ { marginTop: "20px" } }
                              className={ "col-lg-4" }
                              isLoading={ isLoading }
                              isClearable={ true }
                              isSearchable={ true }
                              options={ this[ 'options_bank' ] }
                              paramsFormik={ {
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              } }
                           />
                        </div>
                        <div style={ { display: "flex", justifyContent: "center" } } >
                           <div>
                              {/* Button Lihat Data */ }
                              <ButtonLoad
                                 label={ "Lihat Data" }
                                 pending={ pending }
                                 classNameIcon={ "fa fa-filter" }
                                 className={ "btn btn-outline-success" }
                                 onClick={ async () => handleSubmit() }
                              />
                              {/* Button Export PDF */ }
                              <ButtonLoad
                                 label={ "Export .xls" }
                                 pending={ pending }
                                 classNameIcon={ "far fa-file-excel" }
                                 className={ "btn btn-outline-primary" }
                                 disabled={ dataList.length === 0 && dataOutVou.length === 0 }
                                 onClick={ async () =>
                                 {
                                    let startDate = moment( values[ 'start_date' ] ).format( "L" );
                                    let endDate = moment( values[ 'end_date' ] ).format( "L" );
                                    let times = moment().format( 'h.mm.ss a' );
                                    const dateFilter = ( startDate === endDate ) ? startDate : startDate + " - " + endDate;
                                    // List Transaksi
                                    let record = [];
                                    dataList.map( ( val, i ) =>
                                    {
                                       const { no_voucher, admin, tanggal, keterangan, debet, kredit, saldo } = val;
                                       record.push( {
                                          no_voucher: no_voucher,
                                          tanggal: tanggal ? moment( tanggal ).format( "DD-MM-YYYY" ) : "",
                                          admin: admin,
                                          keterangan: keterangan,
                                          debet: debet,
                                          kredit: kredit,
                                          saldo: saldo,
                                       } );
                                       return val;
                                    } );
                                    let totalDebetList = dataList.reduce( ( total, value ) =>
                                    {
                                       return parseFloat( total + value[ 'debet' ] );
                                    }, 0 );
                                    let totalKreditList = dataList.reduce( ( total, value ) =>
                                    {
                                       return parseFloat( total + value[ 'kredit' ] );
                                    }, 0 );
                                    // Outstanding Voucher
                                    let record2 = [];
                                    dataOutVou.map( ( val, i ) =>
                                    {
                                       const { no_voucher, admin, tanggal, keterangan, debet, kredit } = val;
                                       record2.push( {
                                          no_voucher: no_voucher,
                                          tanggal: tanggal ? moment( tanggal ).format( "DD-MM-YYYY" ) : "",
                                          admin: admin,
                                          keterangan: keterangan,
                                          debet: debet,
                                          kredit: kredit
                                       } );
                                       return val;
                                    } );
                                    let totalDebetListOutVou = dataOutVou.reduce( ( total, value ) =>
                                    {
                                       return parseFloat( total + value[ 'debet' ] );
                                    }, 0 );
                                    let totalKreditListOutVou = dataOutVou.reduce( ( total, value ) =>
                                    {
                                       return parseFloat( total + value[ 'kredit' ] );
                                    }, 0 );

                                    let datas = {
                                       headerTitle: "LAPORAN BANK OPERASIONAL",
                                       title1: "List Transaksi",
                                       title2: "Outstanding Voucher",
                                       headerField: [ "NO. VOUCHER", "TANGGAL", "ADMIN", "KETERANGAN", "DEBET", "KREDIT", "SALDO" ],
                                       headerField2: [ "NO. VOUCHER", "TANGGAL", "ADMIN", "KETERANGAN", "DEBET", "KREDIT" ],
                                       recordData: record,
                                       recordData2: record2,
                                       totalSaldoAwal: saldo_awal,
                                       totalSaldoAkhir: saldo_akhir,
                                       footerSubTotal: [ totalDebetList, totalKreditList, "" ],
                                       footerSubTotal1: [ totalDebetListOutVou, totalKreditListOutVou ],
                                       columnWidth: [ 35, 17, 40, 80, 20, 20, 20 ],
                                       centerAlign: [ "A", "B" ],
                                       wrapText: [ "C", "D" ],
                                       numberFormatRp: [ "E", "F", "G" ],
                                       fileName: `Kasir Laporan Bank Operasional (${ dateFilter } - ${ times })`
                                    }
                                    this[ 'export_excel' ].saveAsExcel( datas );
                                 } }
                              />
                           </div>
                        </div>
                     </CardBody>
                     <CardFooter>
                        {/* List Transaksi */ }
                        {/* Saldo Awal */ }
                        <div className={ "tab-content mt-5" }>
                           <div className={ "table-responsive" }>
                              <Paper className={ useStyles().root }>
                                 <Typography variant={ "h5" } component={ "h3" }>
                                    Saldo Awal : { ( saldo_awal < 0 ) ? "Rp. -" + separatorHarga( saldo_awal.toString() ) :
                                       separatorHarga( saldo_awal.toString(), "Rp. " ) }
                                 </Typography>
                              </Paper>
                           </div>
                        </div>
                        <ReactDatatableFooter
                           title={ this[ 'pageNameTable1' ] }
                           columns={ this[ 'columntableList' ] }
                           data={ this[ 'datatableList' ] }
                           searchParameter={ this[ 'searchParameter' ] }
                           progressPending={ pending }
                           footer={ this.state[ 'footerTransaksiList' ] }
                        />
                        {/* Outstanding Voucher */ }
                        {/* Saldo Akhir */ }
                        <div className={ "tab-content mt-5" }>
                           <div className={ "table-responsive" }>
                              <Paper className={ useStyles().root }>
                                 <Typography variant={ "h5" } component={ "h3" }>
                                    Saldo Akhir : { ( saldo_akhir < 0 ) ? "Rp. -" + separatorHarga( saldo_akhir.toString() ) :
                                       separatorHarga( saldo_akhir.toString(), "Rp. " ) }
                                 </Typography>
                              </Paper>
                           </div>
                        </div>
                        <ReactDatatableFooter
                           title={ this[ 'pageNameTable2' ] }
                           columns={ this[ 'columntableOutVou' ] }
                           data={ this[ 'datatableOutVou' ] }
                           searchParameter={ this[ 'searchParameter' ] }
                           progressPending={ pending }
                           footer={ this.state[ 'footerOutstandingVoucher' ] }
                        />
                     </CardFooter>
                  </AnimatedCard>
               ) }
            </Formik>
         </>
      );
   }
}
import { separatorHarga } from "../../../services/separator-harga";
import store from "../../../redux/store";
import React from 'react';
import ModalImage from "react-modal-image";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export default class pengeluaranKasDetailModel {
  constructor(
    data = {
      id: 0,
      id_akun: "",
      kode_akun: "",
      nama_akun: "",
      deskripsi: "",
      jumlah: "",
      ceklist: false,
      upload_gambar_bop_kas: null,
      file_images: null,
      id_perusahaan: store.getState()['auth']['id_perusahaan'],
      status: 1
    }
  ) {
    const { id, id_akun, kode_akun, nama_akun, deskripsi, jumlah, ceklist, upload_gambar_bop_kas, file_images, id_perusahaan, status } = data;
    this['id'] = id;
    this['id_akun'] = id_akun;
    this['kode_akun'] = kode_akun;
    this['nama_akun'] = nama_akun;
    this['deskripsi'] = deskripsi;
    this['jumlah'] = jumlah;
    this['ceklist'] = ceklist;
    this['upload_gambar_bop_kas'] = upload_gambar_bop_kas;
    this['file_images'] = file_images;
    this['id_perusahaan'] = id_perusahaan;
    this['status'] = status;
  }

  async getdata() {
    const { kode_akun, nama_akun, deskripsi, jumlah, ceklist, upload_gambar_bop_kas, file_images } = this;
    return [
      {
        id: "kode_akun",
        title: "Kode Akun",
        label: kode_akun,
        center: true,
        minWidth: "15%",
      }, {
        id: "nama_akun",
        title: "Nama Akun",
        label: nama_akun,
        center: true,
        minWidth: "20%",
      }, {
        id: "deskripsi",
        title: "Keterangan",
        label: deskripsi,
        minWidth: "60%",
      }, {
        id: "upload_gambar_bop_kas",
        title: "Gambar",
        center: true,
        label: file_images !== null ? <ModalImage
          style={{ maxWidth: "50% !importan" }}
          className={"image-input-wrapper"}
          small={`${toAbsoluteUrl(file_images)}`}
          large={`${toAbsoluteUrl(file_images)}`}
          showRotate={true}
        /> : upload_gambar_bop_kas !== null ? <ModalImage
          style={{ maxWidth: "50% !importan" }}
          className={"image-input-wrapper"}
          small={`${toAbsoluteUrl(URL.createObjectURL(upload_gambar_bop_kas))}`}
          large={`${toAbsoluteUrl(URL.createObjectURL(upload_gambar_bop_kas))}`}
          showRotate={true}
        /> : "",
        minWidth: "25%",
      }, {
        id: "jumlah",
        title: "Jumlah",
        label: (ceklist || jumlah < 0) ? "Rp. -" + separatorHarga(jumlah.toString()) : separatorHarga(jumlah.toString(), "Rp. "),
        right: true,
        minWidth: "15%",
      }
    ];
  }
}

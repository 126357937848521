const initialValues = {
  active: null,
  list: [],
};

export const kontakAction = {
  list: "LIST_KONTAK",
  active: "ACTIVE_KONTAK",
  resetActive: "RESET_ACTIVE_KONTAK",
};

const reducerKontak = (state = initialValues, action) => {
  let states;
  switch (action.type) {
    case kontakAction.list:
      states = state;
      states.list = action.payload;
      return (state = states);
    case kontakAction.active:
      states = state;
      states.active = action.payload;
      return (state = states);
    case kontakAction.resetActive:
      states = state;
      states.active = null;
      return (state = states);
    default:
      return state;
  }
};

export default reducerKontak;

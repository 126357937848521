import React, { useState, useEffect } from "react";
import CustomerModel from "../../../models/customer-model/CustomerModel";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { CustomerAPI } from "../../../api/customer";
import { SimpleTable } from "../../../components/table/Table";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SimpleAlert from "../../../components/alert/alert";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import store from "../../../redux/store";

export default function List(params) {
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const history = useHistory();
  const simpleTableModel = new SimpleTableModel();

  async function init() {
    let datas = await new CustomerAPI().getAll().then((res) => 
      res.data
    );
    let sales = store.getState().auth;
    console.log("cek sales id", sales.id);

    let customerSales = datas.filter((data) => data.id_sales === sales.id);
    console.log("coba", customerSales);
    console.log("cek datas", datas);

    customerSales.map((item, i) => {
      console.log("cek item", item);
      let dataModel = new CustomerModel(item);
      simpleTableModel.add({
        id: item.id,
        no: i + 1,
        dataModel: dataModel,
        actions: (
          <span>
            {item.toOrganisasi === null ? (
              <Button size="sm" variant="light" disabled>
                <i
                  className="fas fa-flag"
                  aria-hidden="true"
                  style={{ color: "blue" }}
                ></i>
              </Button>
            ) : item.toOrganisasi.is_approved <= 2 ? (
              <Button size="sm" variant="light" disabled>
                <i
                  className="fas fa-flag"
                  aria-hidden="true"
                  style={{ color: "red" }}
                ></i>
              </Button>
            ) : (
              <Button size="sm" variant="light" disabled>
                <i
                  className="fas fa-flag"
                  aria-hidden="true"
                  style={{ color: "green" }}
                ></i>
              </Button>
            )}
            <Button
              size="sm"
              className="mx-1"
              variant="outline-primary"
              onClick={() => {
                params.setDataFromModel(dataModel);
                history.push("/customer-sales/edit");
              }}
            >
              Edit
            </Button>
            <Button
              size="sm"
              className="mx-1"
              variant="outline-danger"
              onClick={() => {
                setId4delete(item.id);
                handleAlert.show();
              }}
            >
              Hapus
            </Button>
          </span>
        ),
      });
      return null;
    });
    setColumntable(await simpleTableModel.getColumnHeaders());
      setDatatable(await simpleTableModel.getDatatables());
  }

  const [id4delete, setId4delete] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const handleAlert = {
    show: () => {
      setShowAlert(true);
    },
    hide: () => {
      setShowAlert(false);
    },
    confirmed: async () => {
      await new CustomerAPI().delete(id4delete);
      init();
      handleAlert.hide();
    },
  };

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ ]);
  return (
    <>
      <SimpleAlert
        title="Hapus Data"
        content="Anda yakin hapus data ini?"
        show={showAlert}
        onConfirmed={handleAlert.confirmed}
        onHide={handleAlert.hide}
      />
      <AnimatedCard>
        <CardHeader title="Daftar Customer">
          <CardHeaderToolbar>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                history.push("/customer-sales/new");
              }}
            >
              <i className="fa fa-plus"></i>
              Tambah
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <SimpleTable data={datatable} columns={columntable} />
        </CardBody>
      </AnimatedCard>
    </>
  );
}

import * as Yup from "yup";

import { CardBody, CardHeader, CardHeaderToolbar } from "../../../../../_metronic/_partials/controls";
import { Form, Formik } from "formik";
import React, { Component } from 'react';

import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import { FormsFields } from "../../../../components/forms-fields/FormsFields";
import { Spinner } from "react-bootstrap";
import { dispatcher } from '../../../../redux/dispatcher';
import { hapusSeparator } from "../../../../services/separator-harga";
import { kontakAPI } from '../../../../api/kontak';

class FormOrganisasi extends Component {
  constructor(props) {
    super(props);
    this['title'] = props['isEdit'] ? "Edit Form Organisasi" : "Add Form Organisasi";
    this['isEdit'] = props['isEdit'];
    this['apiOrganisasi'] = new kontakAPI();
    this['initialData'] = dispatcher['kontak'].getOrganisasi();
    this['options_nama_sales'] = [];
    this['options_organisasi'] = [];
  }
  render() {
    return (
      <>
        {/* Form */}
        <Formik
          enableReinitialize={true}
          initialValues={this['initialData']}
          validationSchema={Yup.object().shape({
            nama: Yup.string().required(),
            alamat: Yup.string().max(150, "Maximum 150 String").required("Keterangan harus diisi"),
            telepon: Yup.string().required(),
            nama_rekening: Yup.string().required(),
            no_rekening: Yup.string().required(),
            nama_bank: Yup.string().required(),
            npwp: Yup.string().max(20, "Maximum 20 String").required(),
            nilai_top: Yup.string().required(),
            file_ktp: Yup.string().nullable(true).required("Images KTP harus diisi"),
            file_situ_siup: Yup.string().nullable(true).required("Images SITU/SIUP harus diisi"),
            file_pks: Yup.string().nullable(true).required("Images PKS harus diisi"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            let dataSend = {
              ...values,
              nilai_limit: (typeof values['nilai_limit'] === 'string') ? parseInt(hapusSeparator(values['nilai_limit'])) : values['nilai_limit'],
              nilai_limit_tersisa: (typeof values['nilai_limit_tersisa'] === 'string') ? parseInt(hapusSeparator(values['nilai_limit_tersisa'])) : values['nilai_limit_tersisa']
            }
            if (!this['isEdit'] && values['id'] === 0) {
              // Insert
              this['apiOrganisasi'].setOrganisasi(dataSend).then((res) => {
                if (res['status']) {
                  dispatcher['snackbar'].show("Berhasil", res['message'], "primary");
                  this['props']['history'].goBack();
                } else {
                  return dispatcher['snackbar'].show("Gagal", res['message'], "danger");
                }
              });
            } else {
              // Update
              this['apiOrganisasi'].editOrganisasi(dataSend).then((res) => {
                if (res['status']) {
                  dispatcher['snackbar'].show("Berhasil", res['message'], "primary");
                  this['props']['history'].goBack();
                } else {
                  return dispatcher['snackbar'].show("Gagal", res['message'], "danger");
                }
              });
            }
            setSubmitting(false);
            // this.sendingData(values, setSubmitting);
          }}
        >
          {({ handleSubmit, isSubmitting, touched, errors, setFieldValue, setFieldTouched, values, }) => (
            <AnimatedCard>
              <CardHeader title={this['title']}>
                <CardHeaderToolbar>
                  {/* Button Back */}
                  <button
                    type={"button"}
                    className={"btn btn-secondary mx-1"}
                    onClick={() => this['props']['history'].goBack()}
                  >
                    <i className={"fa fa-arrow-left"}></i>
                    Kembali
                  </button>
                  {/* Button Simpan */}
                  <button
                    type={"button"}
                    disabled={isSubmitting}
                    className={"btn btn-primary mx-1"}
                    onClick={async () => handleSubmit()}
                  >
                    <span>
                      {(!isSubmitting) ?
                        (<>
                          <i className={"fa fa-plus"}></i> {" Simpan"}
                        </>) : (<>
                          <Spinner
                            as={"span"}
                            animation={"border"}
                            size={"sm"}
                            role={"status"}
                            aria-hidden={"true"}
                          /> {" Loading..."}
                        </>)
                      }
                    </span>
                  </button>
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <Form className={"form form-label-right"}>
                  <div className={"form-group row"}>
                    <div className={"col-lg-6"}>
                      <div className={"form-group row"}>
                        {/* Kode */}
                        <FormsFields
                          id={"kode"}
                          type={"text"}
                          label={"Kode"}
                          className={"col-lg-12"}
                          disabled={true}
                          paramsFormik={{
                            setFieldValue,
                            values
                          }}
                        />
                        {/* Nama */}
                        <FormsFields
                          id={"nama"}
                          type={"text"}
                          label={"Nama"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          paramsFormik={{
                            setFieldValue,
                            values
                          }}
                        />
                        {/* Alamat */}
                        <FormsFields
                          id={"alamat"}
                          type={"textarea"}
                          label={"Alamat"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          rows={2}
                          paramsFormik={{
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          }}
                        />
                        {/* No. HP */}
                        <FormsFields
                          id={"telepon"}
                          type={"text"}
                          label={"Telepone"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          paramsFormik={{
                            setFieldValue,
                            values
                          }}
                        />
                        {/* Nama Bank */}
                        <FormsFields
                          id={"nama_bank"}
                          type={"text"}
                          label={"Nama Bank"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          paramsFormik={{
                            setFieldValue,
                            values
                          }}
                        />
                      </div>
                    </div>
                    <div className={"col-lg-6"}>
                      <div className={"form-group row"}>
                        {/* Nama Rekening */}
                        <FormsFields
                          id={"nama_rekening"}
                          type={"text"}
                          label={"Nama Rekening"}
                          className={"col-lg-12"}
                          paramsFormik={{
                            setFieldValue,
                            values
                          }}
                        />
                        {/* No Rekening */}
                        <FormsFields
                          id={"no_rekening"}
                          type={"number"}
                          label={"No. Rekening"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          paramsFormik={{
                            setFieldValue,
                            values
                          }}
                        />
                        {/* NPWP */}
                        <FormsFields
                          id={"npwp"}
                          type={"npwp"}
                          label={"NPWP"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          paramsFormik={{
                            setFieldValue,
                            // setFieldTouched,
                            touched,
                            errors,
                            values
                          }}
                        />
                        {/* Nilai T-O-P */}
                        <FormsFields
                          id={"nilai_top"}
                          type={"number"}
                          label={"Nilai T-O-P"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          paramsFormik={{
                            setFieldValue,
                            values
                          }}
                        />
                        {/* Nilai Limit */}
                        <FormsFields
                          id={"nilai_limit"}
                          type={"currency"}
                          label={"Nilai Limit"}
                          className={"col-lg-12"}
                          style={{ marginTop: "20px" }}
                          onChange={(e) => {
                            setFieldValue('nilai_limit_tersisa', e['target']['value'])
                          }}
                          paramsFormik={{
                            setFieldValue,
                            values
                          }}
                        />
                      </div>
                    </div>
                    <div className={"col-lg-12"}>
                      <div className={"form-group row"}>
                        <div className={"col-lg-6"}>
                          {/* No. KTP */}
                          <FormsFields
                            id={"file_ktp"}
                            type={"image"}
                            label={"KTP"}
                            className={"col-lg-12"}
                            style={{ marginTop: "20px" }}
                            paramsFormik={{
                              setFieldValue,
                              setFieldTouched,
                              touched,
                              errors,
                              values
                            }}
                          />
                        </div>
                        <div className={"col-lg-6"}>
                          {/* File SITU/SIUP */}
                          <FormsFields
                            id={"file_situ_siup"}
                            type={"image"}
                            label={"SITU/SIUP"}
                            className={"col-lg-12"}
                            style={{ marginTop: "20px" }}
                            paramsFormik={{
                              setFieldValue,
                              setFieldTouched,
                              touched,
                              errors,
                              values
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={"col-lg-12"}>
                      <div className={"form-group row"}>
                        <div className={"col-lg-6"}>
                          {/* File NPWP */}
                          <FormsFields
                            id={"file_npwp"}
                            type={"image"}
                            label={"NPWP"}
                            className={"col-lg-12"}
                            style={{ marginTop: "20px" }}
                            paramsFormik={{
                              setFieldValue,
                              setFieldTouched,
                              touched,
                              errors,
                              values
                            }}
                          />
                        </div>
                        <div className={"col-lg-6"}>
                          {/* File PKS */}
                          <FormsFields
                            id={"file_pks"}
                            type={"image"}
                            label={"PKS(Perjanjian Kerja Sama)"}
                            className={"col-lg-12"}
                            style={{ marginTop: "20px" }}
                            paramsFormik={{
                              setFieldValue,
                              setFieldTouched,
                              touched,
                              errors,
                              values
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </CardBody>
            </AnimatedCard>
          )}
        </Formik>
      </>
    );
  }
}

export default FormOrganisasi;
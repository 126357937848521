import React from "react";
import {
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TableWithoutPagination } from "../../../components/table/Table";
import { Modal, Button } from "react-bootstrap";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Fields } from "../../../components/fields/Fields";
import {Typography} from "@material-ui/core";
import PesananPenjualanDetailModel from "../../../models/pesanan-penjualan-detail-model/PesananPenjualanDetailModel";
import { dispatcher } from "../../../redux/dispatcher";
// import { ApproveButtonPesananPenjualan } from "../../../components/approve-button/approveButtonPesananPenjualan";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import store from "../../../redux/store";
import HistoryPembelian from "../../pembelian/history-pembelian/historyPembelian";
import { PesananPembelianAPI } from "../../../api/pesanan-pembelian";
import PesananPembelianDetailModel from "../../../models/pesanan-pembelian-detail-model/PesananPembelianDetailModel";
import { FormsFields } from "../../../components/forms-fields/FormsFields";

export default class Detail extends React.Component {
  constructor(props) {
    super(props);
    this.initialData = dispatcher.pesanan_pembelian.get();
    this.initialDetailData = new PesananPembelianDetailModel();
    this.api = new PesananPembelianAPI();
    this.simpleTableModel = new SimpleTableModel({ readOnly: true });
    this.pageName = "Approval Pesanan Pembelian";
    this.isEdit = props.isEdit;
    this.state = {
      modal: {
        show: false,
        initialDetailData: this.initialDetailData,
        isEdit: true,
      },
      alert: {
        activeId: null,
        show: false,
      },
    };
    this.columntable = [{ dataField: "", text: "" }];
    this.datatable = [{ id: 0, nama: "" }];
    this.fields = [];
    this.validation = Yup.object().shape({});
    this.detailFields = [];
    this.detailValidation = Yup.object().shape({});
  }

  async componentDidMount() {
    await this.loadFields();
    await this.loadDetail();
    let yup = {};
    await this.initialData.getEditable().then((values) => {
      values.forEach((item) => {
        yup[item.dataField] = item.validation;
      });
    });
    this.validation = Yup.object().shape(yup);

    yup = {};
    await this.state.modal.initialDetailData.getEditable().then((values) => {
      values.forEach((item) => {
        yup[item.dataField] = item.validation;
      });
    });
    this.detailValidation = Yup.object().shape(yup);
    this.setState({ ...this.state });
  }

  async loadFields() {
    this.fields = [];
    await this.initialData
      .getEditable({ pageName: this.pageName, isForm: false })
      .then((values) => {
        values.map((item, i) => {
          return (this.fields = [...this.fields, item]);
        });
      });
    this.setState({ ...this.state });
  }

  async loadDetail() {
    let datas = this.initialData.detail;
    this.simpleTableModel = new SimpleTableModel();
    datas.map((item, i) => 
    item.status !== 0 ?
        this.simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: item,
          actions: (
            <span>
              <Button 
              size="sm" 
              className="mx-1 btn-info" 
              variant="primary" 
              title="History"
              onClick={() => {
                this.handleModal.edit(item)
              }}
              >
                <i className="fa fa-history" aria-hidden="true"></i>
              </Button>
            </span>
          ),
        }) : ""
    );

    this.datatable = await this.simpleTableModel.getDatatables();
    this.columntable = await this.simpleTableModel.getColumnHeaders();
    this.setState({ ...this.state });
  }

  handleModal = {
    loadDetailFields: async () => {
      this.detailFields = [];
      await this.state.modal.initialDetailData
        .getEditable({ pageName: this.pageName, isForm: true })
        .then((values) => {
          values.map((item, i) => {
            return (this.detailFields = [...this.detailFields, { ...item }]);
          });
        });
    },
    tambah: async () => {
      this.state.modal.initialDetailData = new PesananPenjualanDetailModel();
      let modal = { ...this.state.modal };
      modal.show = true;
      modal.title = `Item Baru`;
      modal.isEdit = false;
      await this.handleModal.loadDetailFields();
      this.setState({ modal });
    },
    edit: async (dataFromModel) => {
      let state = { ...this.state };
      state.modal.show = true;
      state.modal.title = `History Pembelian`;
      state.modal.data = dataFromModel;
      this.setState({ ...state });
    },
    hide: () => {
      let modal = { ...this.state.modal };
      modal.show = false;
      this.setState({ modal });
    },
  };

  handleAlert = {
    show: (id) => {
      let alert = { ...this.state.alert };
      alert.show = true;
      alert.activeId = id;
      this.setState({ alert: alert });
    },
    hide: () => {
      let alert = { ...this.state.alert };
      alert.show = false;
      alert.activeId = null;
      this.setState({ alert: alert });
    },
    confirmed: async () => {
      await this.initialData.handleDetail.del(this.state.alert.activeId);
      this.handleAlert.hide();
      this.loadDetail();
    },
  };

  render() {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={this.initialData.master}
          validationSchema={this.validation}
          onSubmit={async (values, { resetForm }) => {
            this.initialData.removeSeparator();
            this.initialData.removeAllDetailSeparator();
            this.initialData.master.approved_manager_by = store.getState().auth.id;
            this.initialData.master.is_approved = 2;
            await this.api.edit(this.initialData);
            this.props.history.goBack();
          }}
        >
          {({ handleSubmit, setFieldValue, resetForm, touched,errors, values, setFieldTouched }) => (
            <AnimatedCard>
              <CardHeader title={this.pageName}>
                <CardHeaderToolbar>
                  <button
                    type="button"
                    className="btn btn-secondary mx-1"
                    onClick={() => this.props.history.goBack()}
                  >
                    <i className="fa fa-arrow-left"></i>
                    Kembali
                  </button>
                  {/* {console.warn(this.initialData.master)} */}
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <Form className="form form-label-right">
                  <div className="row">
                  {this.fields.map((field, i) => (
                    // console.log(field)
                    ['nama_supplier', 'tanggal','jatuh_tempo','no_transaksi'].includes(field.dataField)
                      ?
                      <div key={i} className="form-group col-lg-6">
                        <Fields
                          {...field}
                          type = "disabled-text"
                          setFieldValue={setFieldValue}
                        />
                      </div> : ""
                    ))}
                  </div>
                <span className="d-flex align-items-center justify-content-between mt-4">
                  <Typography variant="h6">Detail Item</Typography>
                </span>
                <TableWithoutPagination
                  data={this.datatable}
                  columns={this.columntable}
                />
                <div className="d-flex row" style={{ marginLeft: "40%" , marginTop: "20px"}}>
                    {this.fields.map((field, i) => (
                      !['nama_supplier', 'tanggal','jatuh_tempo','no_transaksi'].includes(field.dataField)
                      ?
                      <div key={i} className="form-group col-md-4 justify-content-around">
                        <Fields
                          {...field}
                          type="disabled-text"
                          setFieldValue={setFieldValue}
                          />
                      </div> : ""
                    ))}
                    </div>
                    <FormsFields
                    id = {'deskripsi'}
                    type = {'textarea'}
                    rows = {8}
                    label = {'Keterangan'}
                    setFieldValue={setFieldValue}
                    disabled={true}
                    paramsFormik={{touched,errors,values,setFieldTouched}}
                    />
                </Form>
              </CardBody>
              <CardFooter>
                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-primary mx-1"
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    <i className="fa fa-save"></i>
                    Approve & Simpan
                  </button>
                </div>
              </CardFooter>
            </AnimatedCard>
          )}
        </Formik>
        <Formik
          enableReinitialize={true}
          initialValues={this.state.modal.initialDetailData}
          validationSchema={this.detailValidation}
          onSubmit={async (values, { resetForm }) => {
            await this.initialData.handleDetail.edit(
              this.state.modal.initialDetailData
            );
            this.loadDetail();
            resetForm();
            this.handleModal.hide();
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Modal
              show={this.state.modal.show}
              onHide={() => this.handleModal.hide()}
              aria-labelledby="contained-modal-title-vcenter"
              size="lg"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modal.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <HistoryPembelian
                data = {this.state.modal.data}
              />
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={this.handleModal.hide}
                  className="btn btn-light btn-elevate"
                >
                  Tutup
                </button>
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      </>
    );
  }
}

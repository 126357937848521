import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import MutasiPengeluaranBankCollection from "./mutasi";
import DetailPengeluaranBankCollection from "../list/detail";
import { dispatcher } from "../../../../../redux/dispatcher";
export default function IndexMutasiPengeluaranBankCollection ()
{
  const history = useHistory();
  const active = dispatcher[ 'kasir_bank_collection' ].getPengeluaranBankCollection();
  return (
    <Switch>
      <Route path="/kasir/mutasi-pengeluaran-bank-collection/mutasi">
        <MutasiPengeluaranBankCollection />
      </Route>
      { active[ 'transaksi' ][ 'id' ] === 0 && (
        <Redirect to="/kasir/mutasi-pengeluaran-bank-collection/mutasi" />
      ) }
      <Route path="/kasir/mutasi-pengeluaran-bank-collection/detail">
        <DetailPengeluaranBankCollection history={ history } />
      </Route>
      <Redirect to="/kasir/mutasi-pengeluaran-bank-collection/mutasi" />
    </Switch>
  );
}

import { getIdPerusahaan, simpleFetch, simpleFetchGetData } from "./api";

export class TransaksiRefAPI {
  // Get List Table
  async getList() {
    let res = await simpleFetchGetData({
      url: "retail/transaksi/referensi?tipe=getList&id_perusahaan=" + getIdPerusahaan(),
      method: "GET",
    });
    return res;
  }
  // Get Akun
  async getAkun() {
    let res = await simpleFetch({
      url: "retail/select/akun",
      method: "GET",
    });
    return res;
  }
  // Get Transaksi Jenis
  async getTransaksiJenis() {
    let res = await simpleFetch({
      url: "retail/select/transaksi_jenis",
      method: "GET",
    });
    return res;
  }
  // Get Transaksi Tipe
  async getTransaksiTipe() {
    let res = await simpleFetch({
      url: "retail/select/transaksi_tipe",
      method: "GET",
    });
    return res;
  }
  // Insert
  async set(values) {
    let res = await simpleFetch({
      url: "retail/transaksi/referensi",
      method: "POST",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values,
      },
    });
    return res;
  }
  // Update
  async edit(values) {
    let res = await simpleFetch({
      url: "retail/transaksi/referensi",
      method: "PUT",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values,
      },
    });
    return res;
  }
  // Delete
  async delete(id) {
    let res = await simpleFetch({
      url: "retail/transaksi/referensi",
      method: "DELETE",
      body: {
        id: id,
      },
    });
    return res;
  }



























  async getSingle(id) {
    let res = await simpleFetch({
      url: "retail/transaksi/referensi?id=" + id,
      method: "GET",
    });
    return res;
  }
}

import React, { useState, useEffect } from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Button } from "react-bootstrap";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import { StokTransferAPI } from "../../../api/stok-transfer";
import { useHistory } from "react-router-dom";
import StokRequestModel from "../../../models/stok-req-model/StokRequestModel";

export default function List(params) {
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const history = useHistory();

  async function init() {
    let datas = await new StokTransferAPI().getAll().then((res) =>
      res.data
    );;
    let simpleTableModel = new SimpleTableModel();
    datas.forEach((item, i) => {
      const actions = (dataModel) => {
        return (
          <>
            <Button
              className="mx-1 btn btn-outline-success btn-sm"
              onClick={() => {
                params.setDataFromModel(dataModel);
                history.push("/terima-transfer-stok/detail");
              }}
            >
              Detail
            </Button>
            {item['master']['status_jurnal'] === "1" ?
              (
                <Button
                  className="mx-1 btn btn-outline-primary btn-sm"
                  onClick={() => {
                    params.setDataFromModel(dataModel);
                    history.push("/terima-transfer-stok/inbound");
                  }}
                >
                  Validasi
                </Button>) : (
                ""
              )
            }
          </>
        )
      }

      let dataModel = new StokRequestModel(item);
      simpleTableModel.add({
        id: i,
        no: i + 1,
        dataModel: dataModel,
        actions: actions(dataModel),
      });
    })
    setColumntable(await simpleTableModel.getColumnHeaders());
    setDatatable(await simpleTableModel.getDatatables());
  }

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <AnimatedCard>
        <CardHeader title="Terima Transfer Stok">
          <CardHeaderToolbar>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <SimpleTable data={datatable} columns={columntable} />
        </CardBody>
      </AnimatedCard>
    </>
  );
}

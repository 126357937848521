/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import {
  CardBody,
} from "../../../../../_metronic/_partials/controls";
import { ReactDatatable } from "../../../../components/react-datatable/reactDatatable";
import { Button } from "react-bootstrap";
import { useState } from "react";
import store from "../../../../redux/store";
import { LaporanGrafik } from "../../../../api/laporan-grafik";
import { useEffect } from "react";
import Select2 from "react-select";
import moment from "moment";
import { saveAsExcel } from "./exportExcelARhari";

function PembayaranARhari() {
  const rupiah = (number) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    }).format(number);
  };

  const columns = [
    {
      name: "Tanggal",
      selector: (row) => row.tanggal,
      sortable: true,
      minWidth: "10%",
    },
    {
      name: "No Penerimaan",
      selector: (row) => row.noPenerimaan,
      sortable: true,
      minWidth: "20%",
    },
    {
      name: "No Transaksi",
      selector: (row) => row.noTransaksi,
      sortable: true,
      minWidth: "22%",
    },
    {
      name: "Jumlah",
      selector: (row) => row.terbayar,
      sortable: true,
      minWidth: "12%",
    },
    {
      name: "Deskripsi",
      selector: (row) => row.deskripsi,
      sortable: true,
      minWidth: "35%",
    },
  ];

  const ksa = [20, 46, 52, 69];
  const kpp = [68, 28, 70, 71];
  const ksb = [108, 105, 110, 109];

  const [pt] = useState([{
    value: ksa,
    label: "KSA"
  }, {
    value: kpp,
    label: "KPP"
  }, {
    value: ksb,
    label: "KSB"
  }])

  const [selectedPT, setSelectedPT] = useState(ksa.includes(store.getState().auth.id_perusahaan) ? ksa : kpp.includes(store.getState().auth.id_perusahaan) ? kpp : ksb);

  const [startDate, setStartDate] = useState(
    moment(new Date())
      // .startOf("month")
      .format("YYYY-MM-DD")
  );

  const [cabang, setCabang] = useState([0]);

  const title = "LaporanPembayaranAR";
  const Export = ({ onExport }) => (
    <Button onClick={(e) => onExport(e.target.value)}>
      <i className={"far fa-file-excel"}></i> {" Export .xls"}
    </Button>
  );
  const actionsMemo = <Export onExport={() => saveAsExcel(data, title)} />;

  const [opsi, setOptions] = useState([]);

  let opsiCabang = [];

  const coverage = async () => {
    await new LaporanGrafik().getCoverage().then((res) => {
      let filter = res.data.filter((data) => selectedPT.includes(data.id));
      opsiCabang = filter.map((data) => ({
        label: data.text,
        text: data.text,
        value: data.id,
      }));
    });
    setOptions(opsiCabang);
  };

  const [data, setData] = useState([]);

  const fetchData = async (data) => {
    try {
      await new LaporanGrafik().getARhari(data).then((res) => {
        let datas = [];
        res.data.map((v) => {
          let newData = {
            tanggal: moment(v.created_at).format("YYYY-MM-DD"),
            noPenerimaan: v.no_transaksi,
            terbayar: rupiah(v.terbayar),
            deskripsi: v.deskripsi,
            noTransaksi: v.toPenjualan.no_transaksi,
          };
          return (datas = [...datas, newData]);
        });
        setData(datas);
        // dataSeries.push(val)
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // if (dataSeries.length === 0)
    // {
    if (cabang.includes(0)) {
      fetchData({ selectedPT, startDate });
      // setIdPerusahaan(selectedPT)
    } else {
      fetchData({ cabang, startDate });
      // setIdPerusahaan(cabang)
    }
    coverage();
    // }
  }, []);
  return (
    <>
      <AnimatedCard>
        <CardBody>
          <div className="d-flex justify-content-start form-group row">
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <label> Tanggal </label>
              <input
                name={"tanggal_awal"}
                type={"date"}
                value={startDate}
                className={"form-control"}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <label> Pilih PT </label>
              <Select2
                name={"PT"}
                size="sm"
                options={pt}
                isClearable={false}
                value={
                  pt ? pt.find((option) => option.value === selectedPT) : ""
                }
                onChange={(e) => {
                  setSelectedPT(e ? e.value : []);
                  setCabang([0]);
                }}
              />
            </div>
            <div
              className="col-lg-3"
              style={{ marginTop: "20px", zIndex: "4" }}
            >
              <label> Pilih Perusahaan </label>
              <Select2
                name={"Cabang"}
                placeholder={opsi.text}
                size="sm"
                options={opsi}
                isClearable={true}
                value={
                  opsi ? opsi.find((option) => option.value === cabang) : ""
                }
                onChange={(e) => {
                  setCabang(e ? [e.value] : [0]);
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-start">
            <div style={{ marginRight: "2%" }}>
              <Button
                variant="info"
                onClick={(e) => {
                  e.preventDefault();
                  if (cabang.includes(0)) {
                    fetchData({ selectedPT, startDate });
                    // setIdPerusahaan(selectedPT)
                  } else {
                    fetchData({ cabang, startDate });
                    // setIdPerusahaan(cabang)
                  }
                }}
              >
                <i className="fas fa-search"></i>
                Cari Data
              </Button>
            </div>
            <div>{actionsMemo}</div>
          </div>
          <ReactDatatable
            columns={columns}
            data={data}
            dense
            pagination
            searchParameter={["noPenerimaan", "noTransaksi"]}
          />
        </CardBody>
      </AnimatedCard>
    </>
  );
}

export default PembayaranARhari;

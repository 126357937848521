/* eslint-disable array-callback-return */
import React from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import { Button } from "react-bootstrap";
import SimpleAlert from "../../../components/alert/alert";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { Modal } from "react-bootstrap";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import store from "../../../redux/store";
import Select2 from "react-select";
import Model from "../../../models/pesanan-penjualan-model/PesananPenjualanModel";
import { Link } from "react-router-dom";
import { dispatcher } from "../../../redux/dispatcher";
import PesananPenjualanDetailModel from "../../../models/pesanan-penjualan-detail-model/PesananPenjualanDetailModel";
import { PesananPenjualanAPI } from "../../../api/pesanan-penjualan";
import PesananPenjualanModel from "../../../models/pesanan-penjualan-model/PesananPenjualanModel";
import moment from "moment";

export default class ApprovalPesananPenjualanAkhir extends React.Component {
  constructor(props) {
    super(props);
    this.initialData = new PesananPenjualanModel();
    this.initiaDetaillData = new PesananPenjualanDetailModel();
    this.master = this.initialData.master;
    this.detail = this.initialData.detail;
    this.api = new PesananPenjualanAPI();
    this.simpleTableModel = new SimpleTableModel();
    this.pageName = "Approval admin Pesanan Penjualan";
    this.newDateMonth = (new Date().getMonth()).toString();
    this.month =
      this.newDateMonth.length > 1
        ? this.newDateMonth
        : "0" + this.newDateMonth;
    this.state = {
      startDate: moment(new Date()).startOf('month').format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),
      search: "",
      cabang: store.getState().auth.id_perusahaan,
      value: 1,
      modal: {
        show: false,
        initialData: this.initialData,
        isEdit: true,
      },
      alert: {
        activeId: null,
        show: false,
      },

      approve: {
        initialData: this.initialData,
        activeId: null,
        show: false,
      },
    };
    this.validation = Yup.object().shape({});
    this.columntable = [{ dataField: "", text: "" }];
    this.datatable = [{ id: 0, nama: "" }];
    this.fields = [];
    this.user = store.getState().auth.alias_role;
    // this.role = store.getState().auth.alias_role;
    this.options = [
      { value: 1, label: "Sudah Approve Awal" },
      { value: 3, label: "Sudah Approve Admin" },
      { value: 2, label: "Sudah Approve Akhir" },
    ];
  }

  async componentDidMount() {
    this.api.getCoverage().then((res) => {
      this.opsi_cabang = res.data.map((data) => ({
        label: data.text,
        text: data.text,
        value: data.id,
      }));
    });
    await this.loadData();
    let yup = {};
    await this.initialData.getEditable().then((values) =>
      values.map((item) => {
        yup[item.dataField] = item.validation;
      })
    );
    this.validation = Yup.object().shape(yup);
  }

  async loadData() {
    let datas = await this.api.getForInvoice(this.state.cabang);
    this.simpleTableModel = new SimpleTableModel();
    let searchParams = ["no_transaksi", "nama_sales", "nama_pelanggan", "nama_organisasi"];
    let filtered = datas.data.filter(
      (filter) => {
        let selectFilter = this.state.value === 3 ? (filter.master.approved_admin_by !== 0 && filter.master.is_approved !== 2) 
        : this.state.value === 1 ? (filter.master.approved_admin_by === 0 && filter.master.is_approved !== 2)
        : filter.master.is_approved === this.state.value;
        let dateFormat = moment(filter.master.created_at).format("YYYY-MM-DD");
        let dateFilter = dateFormat >= this.state.startDate
          && dateFormat <= this.state.endDate
        // let cabangSelected = filter.master.id_perusahaan === this.state.cabang;
        return searchParams.some((item) => {
          return (
            dateFilter &&
            selectFilter &&
            // cabangSelected &&
            filter.master[item]
              .toString()
              .toLowerCase()
              .includes(this.state.search.toString().toLowerCase())
          );
        });

      }
    );
    // Tampilkan data yang sudah difilter
    filtered.map((item, i) => {
      // Tampilkan Semua Data
      // datas.data.map((item, i) => {
      let dataModel = new Model(item);
      this.simpleTableModel.add({
        id: (i += 1),
        no: i++,
        dataModel: dataModel,
        actions: (
          <span>
            {item.master.is_approved >= 2 ? (
              <Link
              className="mx-1 btn btn-primary btn-sm"
              to="/penjualan-pesanan-penjualan/detail"
              onClick={() => {
                dispatcher.pesanan_penjualan.set(dataModel);
              }}
            >
              <i className="fas fa-user-check"></i>
              Sudah Approve
            </Link>
            ) : item.master.is_approved === 1 && item.master.approved_admin_by === 0 ? (
              <span>
                <Link
                  className="mx-1 btn btn-warning btn-sm"
                  to="/approval-admin-pesanan-penjualan/detail"
                  onClick={() => {
                    dispatcher.pesanan_penjualan.set(dataModel);
                  }}
                >
                  <i className="fas fa-user-lock"></i>
                  Belum Approve
                </Link>
              </span>
            ) : item.master.is_approved === 1 && item.master.approved_admin_by !== 0 ? (
            <Link
              className="mx-1 btn btn-primary btn-sm"
              to="/penjualan-pesanan-penjualan/detail"
              onClick={() => {
                dispatcher.pesanan_penjualan.set(dataModel);
              }}
            >
              <i className="fas fa-user-check"></i>
              Sudah Approve Admin
            </Link>
            ) : ""}
          </span>
        ),
      });
    });

    this.datatable = await this.simpleTableModel.getDatatables();
    this.columntable = await this.simpleTableModel.getColumnHeaders();
    this.setState({ ...this.state });
  }

  handleModal = {
    loadFields: async () => {
      this.fields = [];
      await this.initialData
        .getEditable({ pageName: this.pageName, isForm: true })
        .then((values) => {
          values.map((item, i) => {
            return (this.fields = [...this.fields, item]);
          });
        });
    },
    tambah: async () => {
      let modal = { ...this.state.modal };
      modal.show = true;
      modal.title = `${this.pageName} Baru`;
      modal.isEdit = false;
      await this.handleModal.loadFields();
      this.setState({ modal });
    },
    edit: async (dataModel) => {
      let modal = { ...this.state.modal };
      modal.show = true;
      modal.title = `${this.pageName}`;
      modal.initialData = dataModel;
      modal.isEdit = true;
      this.setState({ modal });
    },
    hide: () => {
      let modal = { ...this.state.modal };
      modal.show = false;
      modal.initialData = this.initialData;
      this.setState({ modal });
    },
  };

  handleAlert = {
    show: (id) => {
      let alert = { ...this.state.alert };
      alert.show = true;
      alert.activeId = id;
      this.setState({ alert: alert });
    },
    hide: () => {
      let alert = { ...this.state.alert };
      alert.show = false;
      alert.activeId = null;
      this.setState({ alert: alert });
    },
    confirmed: async () => {
      await this.api.delete(this.state.alert.activeId);
      this.handleAlert.hide();
      this.loadData();
    },
  };

  render() {
    return (
      <>
        <AnimatedCard>
          <SimpleAlert
            title="Hapus Data"
            content="Anda yakin hapus data ini?"
            show={this.state.alert.show}
            onConfirmed={this.handleAlert.confirmed}
            onHide={this.handleAlert.hide}
          />

          <CardHeader title={this.pageName}>
            <CardHeaderToolbar></CardHeaderToolbar>
          </CardHeader>
          <CardBody>
          <div className="form-group row">
              <div className="col-lg-4" style={{ marginTop: "20px" }}>
                <label> Tanggal Mulai </label>
                <input
                  name={"tanggal_awal"}
                  type={"date"}
                  value={this.state.startDate}
                  className={"form-control"}
                  onChange={(e) => {
                    this.setState({ startDate: e.target.value });
                    this.loadData();
                  }}
                />
              </div>
              <div className="col-lg-4" style={{ marginTop: "20px" }}>
                <label> Tanggal Akhir </label>
                <input
                  name={"tanggal_akhir"}
                  type={"date"}
                  value={this.state.endDate}
                  className={"form-control"}
                  onChange={(e) => {
                    this.setState({ endDate: e.target.value });
                    this.loadData();
                  }}
                />
              </div>
              <div className="col-xl-4" style={{ marginTop: "20px" }}>
                <label > Pilih Perusahaan </label>
                <Select2
                  name={"Filter"}
                  placeholder={"--- Pilih Perusahaan ---"}
                  size="sm"
                  options={this.opsi_cabang}
                  isClearable={true}
                  value={
                    this.opsi_cabang
                      ? this.opsi_cabang.find(
                        (option) => option.value === this.state.cabang
                      )
                      : ""
                  }
                  onChange={(e) => {
                    this.setState({
                      cabang: e ? e.value : [],
                    },() => {
                      this.loadData();
                    });
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="col-xl-3" style={{ marginTop: "20px" }}>
                <Select2
                  name={"Filter"}
                  placeholder={"--- Status Approval ---"}
                  size="sm"
                  options={this.options}
                  isClearable={true}
                  value={
                    this.options
                      ? this.options.find(
                        (option) => option.value === this.state.value
                      )
                      : ""
                  }
                  onChange={(e) => {
                    this.setState({
                      value: e ? e.value : "",
                    });
                    this.loadData();
                  }}
                />
              </div>
              <div className="col-lg-3" style={{ marginTop: "20px" }}>
                <input
                  type={"text"}
                  className={"form-control"}
                  placeholder="Cari Data ..."
                  value={this.state.search}
                  onChange={(e) => {
                    this.setState({ search: e.target.value });
                    this.loadData();
                  }}
                />
              </div>
            </div>
            <SimpleTable data={this.datatable} columns={this.columntable} />
          </CardBody>
        </AnimatedCard>
        <Formik
          enableReinitialize={true}
          initialValues={this.state.modal.initialData}
          onSubmit={async (values, { resetForm }) => {
            this.handleModal.hide();
            this.loadData();
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <Modal
              show={this.state.modal.show}
              onHide={this.handleModal.hide}
              size="sm"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>{this.state.modal.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="overlay overlay-block cursor-default">
                <Form className="form form-label-right">
                  <div>Anda Yakin ingin approve data ini ?</div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="button"
                  onClick={this.handleModal.hide}
                  className="btn btn-light btn-elevate"
                >
                  Batal
                </Button>
                <Button
                  type="submit"
                  onClick={() => {
                    handleSubmit();
                    // console.log('click',sendData);
                  }}
                  className="btn btn-primary btn-elevate"
                >
                  Approve & Simpan
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      </>
    );
  }
}

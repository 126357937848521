import React, { useEffect, useState } from "react";
import {
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Link } from "react-router-dom";
import { dispatcher } from "../../../redux/dispatcher";
import SimpleAlert2 from "../../../components/alert/alert2";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import StokKeluarModel from "../../../models/stok-keluar-model/StokKeluarModel";
import moment from "moment";
import { PenjualanAPI } from "../../../api/penjualan";
import Select2 from "react-select";
import store from "../../../redux/store";

export default function List() {
  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const pageName = "Stok Keluar Penjualan";
  const [startDate, setStartDate] = useState(
    moment(new Date())
      .startOf("month")
      .format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [search, setSearch] = useState("");
  const [option, setOptions] = useState({
    value: store.getState().auth.id_perusahaan || 0,
    text: "--- Pilih Cabang ---",
  });
  const [cabang, setCabang] = useState([]);
  let options = [];

  const coverage = async () => {
    await new PenjualanAPI().getCoverage().then((res) => {
      options = res.data.map((data) => ({
        label: data.text,
        text: data.text,
        value: data.id,
      }));
    });
    setCabang(options);
  };

  async function loadData() {
    let datas = dispatcher.penjualan.getList();
    let dataMaster = datas;
    let simpleTableModel = new SimpleTableModel();
    let searchParams = ["no_transaksi", "nama_pelanggan","nama_sales", "nama_organisasi"];
    let filtered = dataMaster.filter((filter) => {
      let dateFormat = moment(filter.master.created_at).format("YYYY-MM-DD");
      let dateFilter = dateFormat >= startDate && dateFormat <= endDate;
      let cabangSelected = filter.master.id_perusahaan === option.value;
      return searchParams.some((item) => {
        return (
          dateFilter &&
          cabangSelected &&
          // console.log(filter.master.toSupplier.nama)
          filter.master[item]
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())
        );
      });
    });
    const actions = (dataModel) => {
      return (
        <>
          <Link
            className="mx-1 btn btn-outline-success btn-sm"
            to="/stok-keluar-penjualan/detail"
            onClick={() => {
              dispatcher.penjualan.set(dataModel);
            }}
          >
            Detail
          </Link>
          {dataModel.master.status_validasi !== 2 || dataModel.master.status_invoice === 1 ?  (
            <Link
              className="mx-1 btn btn-outline-primary btn-sm"
              to="/stok-keluar-penjualan/outbound"
              onClick={() => {
                dispatcher.penjualan.set(dataModel);
              }}
            >
              Validasi
            </Link>
          ) : ""}
        </>
      );
    };
    console.log('cek filtered',filtered);
    filtered.map((item, i) => {
      let dataModel = new StokKeluarModel(item);
      if (item.master.status === 1)
        simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: actions(dataModel),
        });
    });

    setColumntable(await simpleTableModel.getColumnHeaders());
    setDatatable(await simpleTableModel.getDatatables());
  }

  useEffect(() => {
    dispatcher.penjualan.list();
  }, [startDate, endDate]);

  useEffect(() => {
    loadData();
    coverage();
  }, [dispatcher.penjualan.getList()]);

  return (
    <>
      <SimpleAlert2
        title="Hapus Data Ini?"
        content="Anda yakin ingin menghapus data ini?"
        variant="danger"
        onConfirm={() => {}}
      />
      <AnimatedCard>
        <CardHeader title={pageName}></CardHeader>
        <CardBody>
        <div className="d-flex justify-content-start form-group row">
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Mulai </label>
              <input
                name={"tanggal_awal"}
                type={"date"}
                value={startDate}
                className={"form-control"}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  loadData();
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Akhir </label>
              <input
                name={"tanggal_akhir"}
                type={"date"}
                value={endDate}
                className={"form-control"}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  loadData();
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Pilih Perusahaan </label>
              <Select2
                name={"Cabang"}
                placeholder={option.text}
                size="sm"
                options={cabang}
                isClearable={true}
                onChange={(e) => {
                  setOptions({ value: e ? e.value : [] });
                  dispatcher.penjualan.list();
                  // setFieldValue("cabang", e ? e.value : "");
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <input
                type={"text"}
                className={"form-control"}
                placeholder="Cari Data ..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  dispatcher.penjualan.list();
                }}
              />
            </div>
          </div>
          <SimpleTable data={datatable} columns={columntable} />
        </CardBody>
      </AnimatedCard>
    </>
  );
}

import React from 'react'
import { CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import { AnimatedCard } from '../../../components/animated-card/animatedCard'
import { Button, Modal } from 'react-bootstrap'
import { useState } from 'react'
import { Form, Formik } from 'formik'
import _ from 'lodash'
import store from '../../../redux/store'
import { simpleFetch } from '../../../api/api'

function Pajak() {
    const [show, setShow] = useState(false);
    const id_user = store.getState().auth.id;
    const id_perusahaan = store.getState().auth.id_perusahaan;
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <AnimatedCard>
                <CardHeader title={'Master No.E-faktur'}>
                    <CardHeaderToolbar >
                        <Button
                            onClick={() => {
                                handleShow()
                            }}
                        >
                            <i className={"fa fa-plus"}></i> Tambah
                        </Button>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <h1> HI </h1>
                </CardBody>
            </AnimatedCard>
            <Modal
                show={show}
                onHide={handleClose}
            >
                <Formik
                    initialValues={{
                        head: "",
                        dari: 0,
                        hingga: 0
                    }}
                    // validate={(values) => {
                    //     const errors = {};
                    //     if (!values.fullname) {
                    //         errors.fullname = "Required";
                    //     }

                    //     if (!values.email) {
                    //         errors.email = "Required";
                    //     } else if (
                    //         !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                    //     ) {
                    //         errors.email = "Invalid email address";
                    //     }
                    //     if (!values.password) {
                    //         errors.password = "Required";
                    //     }
                    //     return errors;
                    // }}
                    onSubmit={async (values) => {
                        // const array = Array(values.hingga).fill(values.dari).map((n, i) => (n + i))
                        const array = _.range(values.dari, values.hingga + 1).map((val) => values.head + val)
                        console.log(values, array);
                        let data = {
                            efaktur: array,
                            id_perusahaan: id_perusahaan,
                            id_user: id_user
                        }
                        console.log("Data", data);
                        try {
                            await simpleFetch({
                                url: `retail/efaktur/post`,
                                method: "POST",
                                body:
                                {
                                    efaktur: array,
                                    id_perusahaan: id_perusahaan,
                                    id_user: id_user
                                },
                            }).then((res) => {
                                console.log("cek customer", res);
                            })
                        } catch (error) {
                            console.error(error);
                        }
                    }}
                >
                    {({ values, handleSubmit, handleChange }) => (
                        <Form>
                            <Modal.Header closeButton>
                                <Modal.Title>Modal heading</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <label htmlFor="head">Head : </label>
                                <div className="input-group my-2">
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="head"
                                        placeholder=""
                                        onChange={handleChange}
                                        value={values.head}
                                    />
                                </div>

                                <label htmlFor="dari">Dari : </label>
                                <div className="input-group my-2">
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="dari"
                                        placeholder=""
                                        onChange={handleChange}
                                        value={values.dari}
                                    />
                                </div>

                                <label htmlFor="hingga">Hingga : </label>
                                <div className="input-group my-2">
                                    <input
                                        className="form-control"
                                        type="number"
                                        name="hingga"
                                        placeholder=""
                                        onChange={handleChange}
                                        value={values.hingga}
                                    />
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={() => {
                                    handleSubmit()
                                    handleClose()
                                }}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal >
        </>
    )
}

export default Pajak
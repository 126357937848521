import { Form, Formik } from "formik";
import React, { Component } from "react";
import { CardHeader, CardBody, CardHeaderToolbar, CardFooter } from "../../../../../_metronic/_partials/controls";
import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import * as Yup from "yup";
import { hapusSeparator, separatorHarga } from "../../../../services/separator-harga";
import { dispatcher } from "../../../../redux/dispatcher";
import { Paper, Typography, makeStyles } from "@material-ui/core";
import DataTableModel from "../../../../models/datatable-model/DataTableModel";
import { ModalForms } from "../../../../components/modal-forms/modalForms";
import { FormsFields } from "../../../../components/forms-fields/FormsFields";
import SimpleAlert from "../../../../components/alert/alert";
import moment from "moment";
import ButtonLoad from "../../../../components/button-loading/buttonLoad";
import { ReactDatatableFooterDetails } from "../../../../components/react-datatable-footer/reactDatatableFooter";
import { akuntansiAPI } from "../../../../api/akuntansi";
import jurnalUmumDetailModel from "../../../../models/akuntansi/jurnal-umum/JurnalUmumDetailModel ";
import { getIdPerusahaan } from "../../../../api/api";
import ButtonActions from "../../../../components/button-actions/buttonActions";

export default class FormJurnalUmum extends Component {
  constructor(props) {
    super(props);
    this['pageName'] = "Form Jurnal Umum";
    this['initialData'] = dispatcher['akuntansi'].getJurnalUmum();
    this['apiJurnalUmum'] = new akuntansiAPI();
    this['initialDetailData'] = new jurnalUmumDetailModel();
    this['options_akun'] = [];
    this['options_bank'] = [];
    this['options_cabang'] = [];
    this['state'] = {
      submitForm: false,
      modal: {
        show: false,
        initialDetailData: this['initialDetailData'],
        isEdit: true,
      },
      alert: {
        activeId: null,
        show: false,
      },
      total_out_off_balance: 0,
      footerJurnalUmum: {
        deskripsi: "Total",
        debet: 0,
        kredit: 0,
        tax: 0,
      },
    };
    this['datatable'] = [{ id: "", no: "", name: "" }];
    this['columntable'] = [{ id: "", title: "" }];
  }
  //* ----------------------------------------------------------------------------- */
  //** Start: Component */
  async componentDidMount() {
    await this.LoadDetail();
  }
  //* ----------------------------------------------------------------------------- */
  //** Start: Sending Data */
  async sendingData(values, setSubmitting) {
    let dataSend = {};
    let data = {
      tanggal: moment(values['tanggal']).format("YYYY-MM-DD"),
      deskripsi: values['deskripsi'],
      id_user: parseInt(values['id_user']),
      id_perusahaan: getIdPerusahaan(),
    };
    let data_details = this['initialData']['detail'];
    if (data_details.length > 0) {
      const { footerJurnalUmum, total_out_off_balance } = this['state'];
      if (total_out_off_balance !== 0) {
        dispatcher['snackbar'].show("Gagal", "Total Debit dan Total Kredit harus Seimbang!", "danger");
        this.setState({ submitForm: false });
        setSubmitting(false);
        return true;
      } else {
        dataSend = {
          ...data,
          total_debet: parseInt(hapusSeparator(footerJurnalUmum['debet'])),
          total_kredit: parseInt(hapusSeparator(footerJurnalUmum['kredit'])),
          total_tax: parseInt(hapusSeparator(footerJurnalUmum['tax'])),
          total_ob: total_out_off_balance,
          data_detail: data_details,
        }
      }
    } else {
      dispatcher['snackbar'].show("Gagal", "Data detail tidak boleh kosong!", "danger");
      this.setState({ submitForm: false });
      setSubmitting(false);
      return true;
    }
    await this['apiJurnalUmum'].setJurnalUmum(dataSend).then((res) => {
      if (res['status']) {
        dispatcher['snackbar'].show("Berhasil", res.message, "primary");
        this.setState({ submitForm: true });
        setSubmitting(true);
        this.LoadDetail();
      } else {
        dispatcher['snackbar'].show("Gagal", res.message, "danger");
        this.setState({ submitForm: false });
        setSubmitting(false);
      }
    });
  }
  //** End: Sending Data */
  //* ----------------------------------------------------------------------------- */
  //** Start: Load Detail Table */
  async LoadDetail() {
    let data = this['initialData']['detail'];
    this['DataTableModel'] = new DataTableModel({ readOnly: this['state']['submitForm'] });
    data.map((item, i) => {
      if (item['status'] === 1) {
        this['DataTableModel'].add({
          id: i,
          no: i + 1,
          dataModel: item,
          actions:
            (!this['state']['submitForm']) ? (
              <>
                <ButtonActions
                  label={"edit"}
                  onClick={async () =>
                    await this['handleModal'].edit(item)
                  }
                />
                <ButtonActions
                  label={"delete"}
                  onClick={async () =>
                    this['handleAlert'].show(item)
                  }
                />
              </>
            ) : (null),
        });
      }
      return item;
    });
    const total = (val) => {
      if (val === 'debet') {
        return data.reduce((total, value) => {
          let debet = 0;
          if (value['posisi'] === 'debet') {
            debet = (typeof value['jumlah'] === "string") ? parseInt(hapusSeparator(value['jumlah'])) : parseInt(value['jumlah']);
          }
          return parseFloat(total + debet);
        }, 0);
      } else if (val === 'kredit') {
        return data.reduce((total, value) => {
          let kredit = 0;
          if (value['posisi'] === 'kredit') {
            kredit = (typeof value['jumlah'] === "string") ? parseInt(hapusSeparator(value['jumlah'])) : parseInt(value['jumlah']);
          }
          return parseFloat(total + kredit);
        }, 0);
      } else if (val === 'tax') {
        return data.reduce((total, value) => {
          let tax = (typeof value['tax'] === "string") ? parseInt(hapusSeparator(value['tax'])) : parseInt(value['tax']);
          return parseFloat(total + tax);
        }, 0);
      }
    };
    let total_out_off_balance = (total("debet") - total("kredit"))
    this['columntable'] = await this['DataTableModel'].getColumn();
    this['datatable'] = await this['DataTableModel'].getDatas();
    this.setState({
      ...this['state'],
      total_out_off_balance: total_out_off_balance,
      footerJurnalUmum: {
        deskripsi: "Total",
        debet: separatorHarga(total("debet").toString(), "Rp. "),
        kredit: separatorHarga(total("kredit").toString(), "Rp. "),
        tax: separatorHarga(total("tax").toString(), "Rp. "),
      }
    });
  }
  //** End: Load Detail Table */
  //* ----------------------------------------------------------------------------- */
  //** Start: Handle Modal/Alert */
  handleModal = {
    // Load Akun
    loadOptionAkunDetails: async () => {
      this['apiJurnalUmum'].getakunJurnalUmum().then((res) => {
        // Load Data option Akun
        this['options_akun'] = res['data'].map((val) => {
          return {
            value: val['id'],
            label: val['text'],
            kode_akun: val['kode_akun'],
            nama_akun: val['nama_akun'],
          };
        });
      });
    },
    // Load Bank
    loadOptionBankDetails: async () => {
      this['apiJurnalUmum'].getBankJurnalUmum().then((res) => {
        this['options_bank'] = res['data'].map((val) => {
          return {
            value: val['id'],
            label: `${val['text']} - ${val['no_rekening']}`,
          };
        });
      });
    },
    // Load Cabang
    loadOptionCabangDetails: async () => {
      this['apiJurnalUmum'].getCoverage().then((values) => {
        this['options_cabang'] = values['data'].map((data) => ({
          value: data['id'],
          label: data['text'],
        }));
      });
    },
    tambah: async () => {
      await this['handleModal'].loadOptionAkunDetails();
      await this['handleModal'].loadOptionBankDetails();
      await this['handleModal'].loadOptionCabangDetails();
      this.setState({
        modal: {
          show: true,
          title: "Tambah Akun",
          isEdit: false,
          initialDetailData: new jurnalUmumDetailModel()
        }
      });
    },
    edit: async (dataFromModel) => {
      await this['handleModal'].loadOptionAkunDetails();
      await this['handleModal'].loadOptionBankDetails();
      await this['handleModal'].loadOptionCabangDetails();
      this.setState({
        modal: {
          show: true,
          title: "Edit Dana Akun",
          isEdit: true,
          initialDetailData: new jurnalUmumDetailModel({
            ...dataFromModel,
          })
        }
      });
    },
    hide: () => {
      this.setState({
        modal: {
          show: false,
          title: "",
          isEdit: false,
          initialDetailData: new jurnalUmumDetailModel()
        }
      });
    },
  };
  handleAlert = {
    // Show Alert
    show: (dataModel) => {
      this.setState({
        alert: {
          activeId: dataModel,
          show: true
        }
      });
    },
    // Hide Alert
    hide: () => {
      this.setState({
        alert: {
          activeId: null,
          show: false
        }
      });
    },
    // Confirmed Alert
    confirmed: async () => {
      await this['initialData']['handleDetail'].del(this['state']['alert']['activeId']);
      this['handleAlert'].hide();
      await this.LoadDetail();
    },
  };
  //** End: Handle Modal/Alert */
  //* ----------------------------------------------------------------------------- */
  render() {
    // Style
    const useStyles = makeStyles(theme => ({
      root: {
        textTransform: "uppercase",
        textAlign: "right",
        padding: theme.spacing(1, 2),
        backgroundColor: "#f3f6f9",
      },
    }));
    const { modal, alert, footerJurnalUmum, total_out_off_balance, submitForm } = this['state'];
    return (
      <>
        {/* Start: Formik Form */}
        <Formik
          enableReinitialize={true}
          initialValues={this['initialData']['jurnal']}
          validationSchema={Yup.object().shape({
            tanggal: Yup.string().nullable().required(),
            deskripsi: Yup.string()
              .max(150, "Maximum 150 String")
              .required("Keterangan harus diisi"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            this.sendingData(values, setSubmitting);
          }}
        >
          {({ handleSubmit, isSubmitting, touched, errors, setFieldValue, setFieldTouched, values }) => (
            <AnimatedCard>
              <CardHeader title={this['pageName']}>
                <CardHeaderToolbar>
                  <>
                    {/* Button Back */}
                    <button
                      type={"button"}
                      className={"btn btn-secondary mx-1 btn-sm"}
                      onClick={() => this['props']['history'].goBack()}
                    ><i className={"fa fa-arrow-left"}></i>
                      Kembali
                    </button>
                    {/* Button Simpan */}
                    {!submitForm ?
                      (<ButtonLoad
                        label={"Simpan"}
                        pending={isSubmitting}
                        disabled={isSubmitting}
                        classNameIcon={"fas fa-save"}
                        className={"btn btn-primary mx-1"}
                        onClick={async () => handleSubmit()}
                      />) : null}
                  </>
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <Form className={"form form-label-right"}>
                  <div className={"form-group row"}>
                    <div className={"col-lg-6"}>
                      <div className={"form-group row"}>
                        {/* Tanggal */}
                        <FormsFields
                          id={"tanggal"}
                          type={"DatePicker"}
                          label={"Tanggal"}
                          className={"col-lg-12"}
                          disabled={true}
                          paramsFormik={{
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          }}
                        />
                      </div>
                    </div>
                    <div className={"col-lg-6"}>
                      <div className={"form-group row"}>
                        {/* Keterangan */}
                        <FormsFields
                          id={"deskripsi"}
                          type={"textarea"}
                          label={"Keterangan"}
                          className={"col-lg-12"}
                          disabled={submitForm}
                          rows={3}
                          paramsFormik={{
                            setFieldValue,
                            setFieldTouched,
                            touched,
                            errors,
                            values
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              </CardBody>
              <CardFooter>
                {/* Start: Detail Dana */}
                <span className={"d-flex align-items-center justify-content-between mt-4 mb-4"}>
                  <Typography variant={"h6"}>Detail</Typography>
                  {!submitForm ? (
                    <>
                      <button
                        type={"button"}
                        className={"btn btn-outline-primary btn-sm"}
                        onClick={async () => {
                          await this['handleModal'].tambah();
                        }}
                      > <i className={"fa fa-plus"}></i> Tambah
                      </button>
                    </>
                  ) : null}
                </span>
                <ReactDatatableFooterDetails
                  title={""}
                  data={this['datatable']}
                  columns={this['columntable']}
                  footer={footerJurnalUmum}
                />
                {/* End: Detail Dana */}
                {/* Total Out Off Balance */}
                <div className={"tab-content mt-5"}>
                  <div className={"table-responsive"}>
                    <Paper className={useStyles().root}>
                      <Typography variant={"h5"} component={"h3"}>
                        Total Out Off Balance : {(total_out_off_balance < 0) ? "Rp. -" + separatorHarga(total_out_off_balance.toString()) :
                          separatorHarga(total_out_off_balance.toString(), "Rp. ")}
                      </Typography>
                    </Paper>
                  </div>
                </div>
              </CardFooter>
            </AnimatedCard>
          )}
        </Formik>
        {/* End: Formik Form */}
        {/* ----------------------------------------------------------------------------- */}
        {/* Start: Modal Detail Form */}
        <Formik
          enableReinitialize={true}
          initialValues={modal['initialDetailData']}
          validationSchema={Yup.object().shape({
            id_akun: Yup.string().ensure().required("Akun harus diisi"),
            posisi: Yup.string().required("Debet/Kredit harus diisi"),
            jumlah: Yup.string().required(),
            // id_bank: Yup.string().ensure().required("Akun harus diisi"),
            id_perusahaan: Yup.string().ensure().required("Cabang harus diisi"),
            // tax: Yup.string().required(),
            deskripsi: Yup.string()
              .max(150, "Maximum 150 String")
              .required("Keterangan harus diisi"),
          })}
          onSubmit={async (values, { resetForm }) => {
            if (modal['isEdit']) {
              await this['initialData']['handleDetail'].edit(values);
            } else {
              await this['initialData']['handleDetail'].set(values);
            }
            await this.LoadDetail();
            resetForm();
            this['handleModal'].hide();
          }}
        >
          {({ handleSubmit, setFieldValue, setFieldTouched, touched, errors, values }) => (
            <ModalForms
              show={modal['show']}
              title={modal['title']}
              onHide={this['handleModal']['hide']}
              onConfirm={handleSubmit}
              size={"xl"}
              content={
                <>
                  <div className={"form-group row"}>
                    <div className={"col-lg-6"}>
                      {/* Forms: Akun */}
                      <FormsFields
                        id={"id_akun"}
                        type={"select2"}
                        label={"Akun"}
                        className={"col-lg-12"}
                        isClearable={false}
                        isSearchable={true}
                        options={this['options_akun']}
                        onChange={(e) => {
                          setFieldValue("kode_akun", e ? e['kode_akun'] : "");
                          setFieldValue("nama_akun", e ? e['nama_akun'] : "");
                        }}
                        paramsFormik={{
                          setFieldValue,
                          setFieldTouched,
                          touched,
                          errors,
                          values
                        }}
                      />
                      {/* Forms: Debet/Kredit */}
                      <FormsFields
                        id={"posisi"}
                        type={"radio"}
                        className={"col-lg-12"}
                        options={
                          ["Debet", "Kredit"]
                        }
                        paramsFormik={{
                          setFieldValue,
                          errors,
                          values
                        }}
                      />
                      {/* Forms: Jumlah */}
                      <FormsFields
                        id={"jumlah"}
                        type={"currency"}
                        label={"Jumlah"}
                        className={"col-lg-12"}
                        style={{ marginTop: "10px" }}
                        value={values['jumlah']}
                        paramsFormik={{
                          setFieldValue
                        }}
                      />
                      {/* Forms: Tax */}
                      <FormsFields
                        id={"tax"}
                        type={"currency"}
                        label={"Tax"}
                        className={"col-lg-12"}
                        style={{ marginTop: "20px" }}
                        value={values['tax']}
                        paramsFormik={{
                          setFieldValue
                        }}
                      />
                    </div>
                    <div className={"col-lg-6"}>
                      {/* Forms: Bank */}
                      <FormsFields
                        id={"id_bank"}
                        type={"select2"}
                        label={"Bank"}
                        className={"col-lg-12"}
                        isClearable={true}
                        isSearchable={true}
                        options={this['options_bank']}
                        onChange={(e) => {
                          setFieldValue("bank", e ? e['label'] : "");
                        }}
                        paramsFormik={{
                          setFieldValue,
                          setFieldTouched,
                          touched,
                          errors,
                          values
                        }}
                      />
                      {/* Forms: Cabang */}
                      <FormsFields
                        id={"id_perusahaan"}
                        type={"select2"}
                        label={"Cabang"}
                        style={{ marginTop: "20px" }}
                        className={"col-lg-12"}
                        isClearable={true}
                        isSearchable={true}
                        options={this['options_cabang']}
                        onChange={(e) => {
                          setFieldValue("cabang", e ? e['label'] : "");
                        }}
                        paramsFormik={{
                          setFieldValue,
                          setFieldTouched,
                          touched,
                          errors,
                          values
                        }}
                      />
                      {/* Forms: Keterangan */}
                      <FormsFields
                        id={"deskripsi"}
                        type={"textarea"}
                        label={"Keterangan"}
                        className={"col-lg-12"}
                        style={{ marginTop: "20px" }}
                        rows={2}
                        paramsFormik={{
                          setFieldValue,
                          setFieldTouched,
                          touched,
                          errors,
                          values
                        }}
                      />
                    </div>
                  </div>
                </>
              }
            />
          )}
        </Formik>
        {/* End: Modal Detail Form */}
        {/* ----------------------------------------------------------------------------- */}
        {/* Start: Alert */}
        <SimpleAlert
          title={"Hapus Data"}
          content={"Anda yakin hapus data ini...?"}
          show={alert['show']}
          onHide={() => this['handleAlert'].hide()}
          onConfirmed={this['handleAlert']['confirmed']}
        />
        {/* End: Alert */}
      </>
    );
  }
}

import React from "react";
import { Link } from "react-router-dom";

export default function ButtonActions ( {
  label,
  linkTo,
  onClick,
} )
{
  return (
    <>
      <span style={ { overflow: "visible", position: "relative" } }>
        { label === "details" ?
          <>
            <button
              size={ "sm" }
              className={ "btn btn-icon w-auto btn-clean d-inline-flex align-items-center px-2 mx-1" }
              title={ "View records" }
              onClick={ onClick }
            ><i className={ "flaticon2-document mr-2" }></i> Details
            </button>
          </>
          : label === "detailsLink" ?
            <>
              <Link
                size={ "sm" }
                className={ "btn btn-icon w-auto btn-clean d-inline-flex align-items-center px-2 mx-1" }
                to={ linkTo }
                title={ "View records" }
                onClick={ onClick }
              ><i className={ "flaticon2-document mr-2" }></i> Details
              </Link>
            </>
            : label === "history" ?
              <>
                <button
                  size={ "sm" }
                  className={ "btn btn-icon w-auto btn-clean d-inline-flex align-items-center px-2 mx-1" }
                  title={ "View records" }
                  onClick={ onClick }
                ><i className={ "fa fa-history mr-2" }></i> History
                </button>
              </>
              : label === "recordLink" ?
                <>
                  <Link
                    size={ "sm" }
                    className={ "btn btn-icon w-auto btn-clean d-inline-flex align-items-center px-2 mx-1" }
                    to={ linkTo }
                    title={ "Records" }
                    onClick={ onClick }
                  ><i className={ "flaticon-file-1 mr-2" }></i> Record
                  </Link>
                </>
                : label === "mutasi" ?
                  <>
                    <button
                      size={ "sm" }
                      className={ "btn btn-icon w-auto btn-clean d-inline-flex align-items-center px-2 mx-1" }
                      title={ "Mutasi records" }
                      onClick={ onClick }
                    ><i className={ "flaticon-file-1 mr-2" }></i> Mutasi
                    </button>
                  </>
                  : label === "mutasiLink" ?
                    <>
                      <Link
                        size={ "sm" }
                        className={ "btn btn-icon w-auto btn-clean d-inline-flex align-items-center px-2 mx-1" }
                        to={ linkTo }
                        title={ "Mutasi records" }
                        onClick={ onClick }
                      ><i className={ "flaticon-file-1 mr-2" }></i> Mutasi
                      </Link>
                    </>
                    : label === "edit" ?
                      <>
                        <button
                          size={ "sm" }
                          className={ "btn btn-icon w-auto btn-clean d-inline-flex align-items-center px-2 mx-1" }
                          title={ "Edit records" }
                          onClick={ onClick }
                        ><i className={ "flaticon-edit-1" }></i>
                        </button>
                      </>
                      : label === "approve" ?
                        <>
                          <button
                            size={ "sm" }
                            className={ "btn btn-icon w-auto btn-clean d-inline-flex align-items-center px-2 mx-1" }
                            title={ "Approved records" }
                            onClick={ onClick }
                          ><i className={ "flaticon-like mr-2" }></i> Approved
                          </button>
                        </> : label === "approveLink" ?
                          <>
                            <Link
                              size={ "sm" }
                              className={ "btn btn-icon w-auto btn-clean d-inline-flex align-items-center px-2 mx-1" }
                              to={ linkTo }
                              title={ "Approved records" }
                              onClick={ onClick }
                            ><i className={ "flaticon2-document mr-2" }></i> Approved
                            </Link>
                          </>
                          : label === "delete" ?
                            <>
                              <button
                                size={ "sm" }
                                className={ "btn btn-icon w-auto btn-clean d-inline-flex align-items-center px-2 mx-1" }
                                title={ "Delete records" }
                                onClick={ onClick }
                              ><i className={ "flaticon2-rubbish-bin-delete-button" }></i>
                              </button>
                            </>
                            : label === 'lock' ?
                              <button
                                size={ "sm" }
                                style={ { cursor: 'default' } }
                                variant={ "light" }
                                disabled
                                className={ "btn btn-icon w-auto btn-clean d-inline-flex align-items-center px-2 mx-1" }
                                title={ "Delete records" }
                                onClick={ onClick }
                              ><i className={ "fas fa-lock" } aria-hidden={ "true" }></i>
                              </button>
                              : null
        }
      </span>
    </>
  );
}

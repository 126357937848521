import { Redirect, Route, Switch } from "react-router-dom";

import FormPengeluaranKas from "./form";
import React from "react";
import { dispatcher } from "../../../../../redux/dispatcher";

export default function IndexFormPengeluaranKas() {
  dispatcher['kasir_kas'].resetActivePengeluaranKas();
  const active = dispatcher['kasir_kas'].getPengeluaranKas();
  return (
    <Switch>
      <Route path="/kasir/tambah-pengeluaran-kas/form">
        <FormPengeluaranKas />
      </Route>
      {active['transaksi']['id'] === 0 && (
        <Redirect to="/kasir/tambah-pengeluaran-kas/form" />
      )}
      <Redirect to="/kasir/tambah-pengeluaran-kas/form" />
    </Switch>
  );
}

import { getIdPerusahaan, simpleFetch } from "./api";

export class TransaksiJenisAPI {
  async getAll() {
    let res = await simpleFetch({
      url: "retail/transaksi/jenis?id_perusahaan=" + getIdPerusahaan(),
      method: "GET",
    });
    return res;
  }

  async getSingle(id) {
    let res = await simpleFetch({
      url: "retail/transaksi/jenis?id=" + id,
      method: "GET",
    });
    return res;
  }

  async set(values) {
    let res = await simpleFetch({
      url: "retail/transaksi/jenis",
      method: "POST",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values,
      },
    });
    return res;
  }

  async edit(values) {
    let res = await simpleFetch({
      url: "retail/transaksi/jenis",
      method: "PUT",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values,
      },
    });
    return res;
  }

  async delete(id) {
    let res = await simpleFetch({
      url: "retail/transaksi/jenis",
      method: "DELETE",
      body: {
        id: id,
      },
    });
    return res;
  }
}

import moment from "moment";

import { PrintDataFetch, getCoverageArea, getIdPerusahaan, simpleFetch, simpleFetchGetData, FormDataFetch } from "./api";
import { hapusSeparator } from "../services/separator-harga";

export class kasirBankOperasionalAPI {
  //** Start: Penerimaan
  // Get List Data */
  async getAllListPenerimaanBankOperasional(form) {
    const { begin_date, end_date, id_perusahaan, no_transaksi, diterima } = form;
    let cabang = (id_perusahaan === "") ? getCoverageArea() : id_perusahaan;
    let get = `&begin_date=${moment(begin_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&id_perusahaan=${cabang}&no_transaksi=${no_transaksi}&kontak_ref=${diterima}`;
    let res = await simpleFetchGetData({
      url: "retail/kasir/penerimaan_bank_operasional?tipe=init" + get,
      method: "GET",
    });
    return res;
  }
  // Save
  async setPenerimaanBankOperasional(values) {
    let res = await simpleFetch({
      url: "retail/kasir/penerimaan_bank_operasional",
      method: "POST",
      body: {
        id_perusahaan: getIdPerusahaan(),
        ...values,
      },
    });
    return res;
  }
  // End: Penerimaan Kas
  //* ----------------------------------------------------------------------------- */
  //** Start: Pengeluaran
  // Get List Data */
  async getAllListPengeluaranBankOperasional(form) {
    const { begin_date, end_date, id_perusahaan, no_transaksi, kepada } = form;
    let cabang = (id_perusahaan === "") ? getCoverageArea() : id_perusahaan;
    let get = `&begin_date=${moment(begin_date).format('YYYY-MM-DD')}&end_date=${moment(end_date).format('YYYY-MM-DD')}&id_perusahaan=${cabang}&no_transaksi=${no_transaksi}&kontak_ref=${kepada}`;
    let res = await simpleFetchGetData({
      url: "retail/kasir/pengeluaran_bank_operasional?tipe=init" + get,
      method: "GET",
    });
    return res;
  }

  // Get List Data */
  async getDataSupplier(form) {
    const { is_supplier } = form;
    let get = `&id_perusahaan=${getIdPerusahaan()}&id=${is_supplier}`;
    let res = await simpleFetchGetData({
      url: "retail/kasir/pengeluaran_bank_operasional?tipe=hutang_supplier" + get,
      method: "GET",
    });
    return res;
  }
  // Save
  // Details
  async setPengeluaranBankOperasional(values) {
    // Codition Detail
    let fd = new FormData();
    if (values['data_detail']) {
      let array_detail = [];
      values['data_detail'].forEach(element => {
        let detail = {
          id: element['id'],
          id_akun: element['id_akun'],
          kode_akun: element['kode_akun'],
          nama_akun: element['nama_akun'],
          deskripsi: element['deskripsi'],
          jumlah: element['jumlah'],
          ceklist: element['ceklist'],
          nama_images: element['upload_gambar_bop_bank_operasional'] ? `${element['upload_gambar_bop_bank_operasional']['name']}` : null,
        };
        array_detail.push(detail);
        fd.append('upload_gambar_bop_bank_operasional', element['upload_gambar_bop_bank_operasional']);
      });
      fd.append('detail', JSON.stringify(array_detail));
    }
    if (values['detail_invoice']) {
      let datas = [];
      values['detail_invoice'].forEach(element => {
        let data = {
          id: element['id'],
          kode: element['kode'],
          tersisa: hapusSeparator(element['tersisa']),
          terbayar: hapusSeparator(element['terbayar']),
          total: hapusSeparator(element['total']),
          jumlah: hapusSeparator(element['jumlah']),
        };
        datas.push(data);
      });
      fd.append('detail_data_invoice', JSON.stringify(datas));
    }
    for (const key in values) {
      fd.append(key, values[key]);
    }
    let res = await FormDataFetch({
      url: "retail/kasir/pengeluaran_bank_operasional",
      method: "POST",
      body: fd,
    });
    return res;
  }
  // Form
  // async setPengeluaranBankOperasional(values) {
  //   let res = await simpleFetch({
  //     url: "retail/kasir/pengeluaran_bank_operasional",
  //     method: "POST",
  //     body: {
  //       ...values,
  //     },
  //   });
  //   return res;
  // }
  // Delete
  async delete(id) {
    let res = await simpleFetch({
      url: "retail/kasir/pengeluaran_bank_operasional",
      method: "DELETE",
      body: {
        id: id,
      },
    });
    return res;
  }
  // Approval
  async approval(values) {
    let res = await simpleFetch({
      url: "retail/kasir/pengeluaran_bank_operasional/approval",
      method: "POST",
      body: {
        ...values,
      },
    });
    return res;
  }

  async approvalAwal(values) {
    let res = await simpleFetch({
      url: "retail/kasir/pengeluaran_bank_operasional/approve_awal",
      method: "POST",
      body: {
        ...values,
      },
    });
    return res;
  }
  //** End: Pengeluaran Kas */
  //* ----------------------------------------------------------------------------- */
  //** Start: Helper Select */
  // Get Select Jenis Transaksi Penerimaan/Pengeluaran
  async getJenisTransaksiBankOperasional() {
    let res = await simpleFetch({
      url: "retail/select/transaksi_jenis?alias=bank-operasional",
      method: "GET",
    });
    return res;
  }
  // Get Select Transaksi Ref Penerimaan
  async getTransaksiRefPenerimaan(id) {
    let res = await simpleFetch({
      url: "retail/select/transaksi_ref?alias=penerimaan-bank-operasional&id_transaksi_jenis=" + id,
      method: "GET",
    });
    return res;
  }
  // Get Select Transaksi Ref Pengeluaran
  async getTransaksiRefPengeluaran(id) {
    let res = await simpleFetch({
      url: "retail/select/transaksi_ref?alias=pengeluaran-bank-operasional&id_transaksi_jenis=" + id,
      method: "GET",
    });
    return res;
  }
  // Get Select Bank
  async getBank() {
    let res = await simpleFetch({
      url: "retail/select/bank?alias=bank-operasional&id_perusahaan=" + getIdPerusahaan(),
      method: "GET",
    });
    return res;
  }
  // GetSelect Coverage Area
  async getCoverage() {
    let res = await simpleFetch({
      url: "retail/select/perusahaan?id_coverage=" + getCoverageArea(),
      method: "GET",
    });
    return res;
  }
  // GetSelect Kolektor
  async getKolektor() {
    let res = await simpleFetch({
      url: "retail/select/kolektor",
      method: "GET",
    });
    return res;
  }
  // Get Select No Transaksi Penjualan (Customer)
  async getNoTransaksiPenjualan(id) {
    let get = `&id_transaksi_ref=${id}&id_perusahaan=${getIdPerusahaan()}`;
    let res = await simpleFetch({
      url:
        "retail/select/pembelian_penjualan?tipe=penjualan" + get,
      method: "GET",
    });
    return res;
  }
  // Get Select No Transaksi Pembelian (Supplier)
  async getNoTransaksiPembelian(id) {
    let get = `&id_transaksi_ref=${id}&id_perusahaan=${getIdPerusahaan()}`;
    let res = await simpleFetch({
      url:
        "retail/select/pembelian_penjualan?tipe=pembelian" + get,
      method: "GET",
    });
    return res;
  }
  // Get Select Nama Supplier
  async getNamaSupplier(id) {
    let get = `&id_transaksi_ref=${id}&id_perusahaan=${getIdPerusahaan()}`;
    let res = await simpleFetch({
      url:
        "retail/select/pembelian_penjualan?tipe=nama_supplier" + get,
      method: "GET",
    });
    return res;
  }
  // Get Select Akun Detail Penerimaan
  async getAkunPenerimaan() {
    let get = `&id_perusahaan=${getIdPerusahaan()}`;
    let res = await simpleFetch({
      url: "retail/select/akun_akses?alias=penerimaan-bank-operasional" + get,
      method: "GET",
    });
    return res;
  }
  // Get Select Akun Detail Pengeluaran
  async getAkunPengeluaran() {
    let get = `&id_perusahaan=${getIdPerusahaan()}`;
    let res = await simpleFetch({
      url: "retail/select/akun_akses?alias=pengeluaran-bank-operasional" + get,
      method: "GET",
    });
    return res;
  }
  //** End: Helper Select */
  //* ----------------------------------------------------------------------------- */
  //** Start: Cetak PDF
  // Tanda Terima */
  async printPdfTandaTerima(values) {
    let res = await PrintDataFetch({
      url: "retail/kasir/penerimaan_bank_operasional/tanda_terima?no_transaksi=" + values,
      method: "GET",
    });
    return res;
  }
  // Bukti Penerimaan
  async printPdfBuktiPenerimaan(values) {
    let res = await PrintDataFetch({
      url: "retail/kasir/penerimaan_bank_operasional/cetak_bukti_penerimaan?no_transaksi=" + values,
      method: "GET",
    });
    return res;
  }
  // Bukti Pengeluaran
  async printPdfBuktiPengeluaran(values) {
    let res = await PrintDataFetch({
      url: "retail/kasir/pengeluaran_bank_operasional/cetak_bukti_pengeluaran?no_transaksi=" + values,
      method: "GET",
    });
    return res;
  }
  //** End: Cetak PDF */
}

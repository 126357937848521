import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import DetailPengeluaranKas from "./detail";
import ListPengeluaranKas from "./list";
import React from "react";
import { dispatcher } from "../../../../../redux/dispatcher";
export default function IndexListPengeluaranKas() {
  const history = useHistory();
  const active = dispatcher['kasir_kas'].getPengeluaranKas();
  return (
    <Switch>
      <Route path="/kasir/daftar-pengeluaran-kas/list">
        <ListPengeluaranKas />
      </Route>
      {active['transaksi']['id'] === 0 && (
        <Redirect to="/kasir/daftar-pengeluaran-kas/list" />
      )}
      <Route path="/kasir/daftar-pengeluaran-kas/detail">
        <DetailPengeluaranKas history={history} />
      </Route>
      <Redirect to="/kasir/daftar-pengeluaran-kas/list" />
    </Switch>
  );
}

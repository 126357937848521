import React, { useRef, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import store from "../../../redux/store";
import { usersAction } from "../../../redux/users/actions";
import { bindActionCreators } from "redux";
import { UserAPI } from "../../../api/user";

function AuthInit ( props )
{
  const auth = store.getState().auth;
  const didRequest = useRef( false );
  const dispatch = useDispatch();

  const updateReducer = {
    users: ( val ) =>
    {
      return ( dispatch ) =>
      {
        dispatch( { type: usersAction.setUsers, payload: val[ 0 ] } );
      };
    }
  };

  const setUser = bindActionCreators( updateReducer, dispatch );
  const [ showSplashScreen, setShowSplashScreen ] = useState( true );
  // We should request user by authToken before rendering the application
  useEffect( () =>
  {
    const requestUser = async ( id ) =>
    {
      try
      {
        await new UserAPI().getUser( id ).then( ( data ) =>
        {
          setUser.users( data.data );
        } );
      } catch ( error )
      {
        console.error( error );
        if ( !didRequest.current )
        {
          store.dispatch( { type: usersAction.reset } );
        }
      } finally
      {
        setShowSplashScreen( false );
      }

      return () => ( didRequest.current = true );
    };

    if ( auth && auth[ 'token' ] && auth[ 'id' ] )
    {
      requestUser( auth[ 'id' ] );
    } else
    {
      dispatch( { type: usersAction.setUsers, payload: {} } );
      setShowSplashScreen( false );
    }
    // eslint-disable-next-line
  }, [] );

  return showSplashScreen ? <LayoutSplashScreen /> : <>{ props.children }</>;
}

export default ( AuthInit );

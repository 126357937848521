import DataTable, { createTheme, defaultThemes } from 'react-data-table-component'
import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { Checkbox } from '@material-ui/core';
import SearchBar from 'material-ui-search-bar';

const sortIcon = <ArrowDownward />;
const paginationComponentOptions = {
  rowsPerPageText: 'Rows per page',
  rangeSeparatorText: 'of',
  selectAllRowsItem: true,
  selectAllRowsItemText: 'All',
}
const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }`;
const Spinner = styled.div`
	margin: 16px;
	animation: ${rotate360} 1s linear infinite;
	transform: translateZ(0);
	border-top: 2px solid grey;
	border-right: 2px solid grey;
	border-bottom: 2px solid grey;
	border-left: 4px solid black;
	background: transparent;
	width: 80px;
	height: 80px;
	border-radius: 50%;`;
const CustomLoader = () => (
  <div style={{ padding: '24px' }}>
    <Spinner />
    <div>Processing data...</div>
  </div>
);

createTheme(
  'solarized',
  {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198',
    },
    background: {
      default: '#002b36',
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF',
    },
    divider: {
      default: '#073642',
    },
    button: {
      default: '#2aa198',
      hover: 'rgba(0,0,0,.08)',
      focus: 'rgba(255,255,255,.12)',
      disabled: 'rgba(255, 255, 255, .34)',
    },
    sortFocus: {
      default: '#2aa198',
    },
  },
  'dark',
);

const customStyles = {
  headCells: {
    style: {
      '&:nth-of-type(n)': {
        backgroundColor: '#f3f6f9',
        borderLeftStyle: 'solid',
        borderLeftWidth: '0px',
        borderLeftColor: defaultThemes.default.divider.default,
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: '#2aa198',
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: '#2aa198',
      },
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
      }
    },
  },
  cells: {
    style: {
      '&:nth-of-type(n)': {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: defaultThemes.default.divider.default,
      },
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
      }
    },
  },
};

const customStylesDetails = {
  headCells: {
    style: {
      '&:nth-of-type(n)': {
        backgroundColor: '#f3f6f9',
        borderLeftStyle: 'solid',
        borderLeftWidth: '0px',
        borderLeftColor: defaultThemes.default.divider.default,
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: defaultThemes.default.divider.default,
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: defaultThemes.default.divider.default,
      },
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
      }
    },
  },
  cells: {
    style: {
      '&:nth-of-type(n)': {
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: defaultThemes.default.divider.default,
      },
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
      }
    },
  },
};

export function ReactDatatable(params) {
  const [search, setSearch] = useState("");
  return (
    <DataTable
      title={params['title'] ?? ""}
      data={
        params['data'].filter((filter) => {
          if (search === "") {
            return filter;
          } else if (
            params['searchParameter'].some((item) => {
              return (
                filter[item]
                  .toString()
                  .toLowerCase()
                  .includes(search.toString().toLowerCase())
              );
            })
          ) {
            return filter;
          }
          return false;
        })
      }
      columns={params.columns}
      fixedHeader={true}
      fixedHeaderScrollHeight={"550px"}
      subHeader={true}
      subHeaderComponent={
        <SearchBar
          name={"searchText"}
          style={{
            border: "1px solid #e4e6ef",
            borderRadius: "0.42rem",
          }}
          value={search}
          cancelOnEscape={true}
          placeholder={"Searching..."}
          onCancelSearch={async () => {
            setSearch("")
          }}
          onChange={(e) => setSearch(e)}
        />
      }
      selectableRows={params['selectableRows']}
      selectableRowsComponent={Checkbox}
      selectableRowsComponentProps={params['selectableRowsComponentProps']}
      onSelectedRowsChange={params['onSelectedRowsChange']}
      selectableRowSelected={params['selectableRowSelected']}
      selectableRowsNoSelectAll={params['selectableRowsNoSelectAll']}
      clearSelectedRows={params['clearSelectedRows']}
      actions={params['actions']}
      contextActions={params['contextActions']}
      defaultSortField={params.defaultSortField}
      expandableRows={params.expandableRows}
      sortIcon={sortIcon}
      progressPending={params.progressPending}
      progressComponent={< CustomLoader />}
      customStyles={customStyles}
      pagination
      responsive
      paginationComponentOptions={paginationComponentOptions}
      noDataComponent="Tidak ada data untuk ditampilkan..."
      keyField={params['keyField']}
    />
  )
}
export function ReactDatatableDetails({ title, data, columns, keyField, fixedHeaderScrollHeight }) {
  // const { title, data, columns } = params;
  return (
    <DataTable
      title={title ?? ""}
      data={data}
      columns={columns}
      fixedHeader={true}
      fixedHeaderScrollHeight={fixedHeaderScrollHeight ?? "350px"}
      sortIcon={sortIcon}
      customStyles={customStylesDetails}
      responsive={true}
      keyField={keyField}
      noDataComponent="Tidak ada detail data untuk ditampilkan..."
    />
  )
}

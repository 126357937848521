import { CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls';
import React, { Component } from 'react';
import { AnimatedCard } from '../../../../components/animated-card/animatedCard';
import DataTableModel from '../../../../models/datatable-model/DataTableModel';
import { akuntansiAPI } from '../../../../api/akuntansi';
import { dispatcher } from '../../../../redux/dispatcher';
import { ReactDatatable } from '../../../../components/react-datatable/reactDatatable';
import jurnalUmumDetailModel from '../../../../models/akuntansi/jurnal-umum/JurnalUmumDetailModel ';
import jurnalUmumModel from '../../../../models/akuntansi/jurnal-umum/JurnalUmumModel';
import { Link } from 'react-router-dom';
import SimpleAlert from '../../../../components/alert/alert';
import ButtonActions from '../../../../components/button-actions/buttonActions';

export default class ListJurnalUmum extends Component {
   constructor(props) {
      super(props);
      this['pageName'] = "Jurnal Umum";
      this['searchParameter'] = ["tanggal", "no_transaksi", "debet", "kredit", "total_tax", "total_ob", "deskripsi"];
      this['apiJurnalUmum'] = new akuntansiAPI();
      this['initialDetailData'] = new jurnalUmumDetailModel();
      this['state'] = {
         pending: false,
         alert: {
            activeId: null,
            show: false,
         },
      };
      this['columntable'] = [{ id: "", title: "" }];
      this['datatable'] = [{ id: "", no: "", name: "" }];
   }
   //* ----------------------------------------------------------------------------- */
   //** Start: Component */
   async componentDidMount() {
      await this.LoadData();
   }
   //** End: Component */
   //* ----------------------------------------------------------------------------- */
   //** Start: Load Data Buku Besar */
   async LoadData() {
      this.setState({ pending: true });
      await dispatcher['akuntansi'].listJurnalUmum();
      let data = dispatcher['akuntansi'].getListJurnalUmum();
      this['DataTableModel'] = new DataTableModel();
      data.map((item, i) => {
         let dataModel = new jurnalUmumModel(item);
         if (item['jurnal']['status'] === 1) {
            this['DataTableModel'].add({
               id: item['jurnal']['id'],
               no: i + 1,
               dataModel: dataModel,
               actions: (item['jurnal']['posting'] !== 0 ? (
                  <>
                     <ButtonActions
                        label={"detailsLink"}
                        linkTo={"/akuntansi/jurnal-umum/detail"}
                        onClick={() => {
                           dispatcher['akuntansi'].setJurnalUmum(dataModel);
                        }}
                     />
                  </>
               ) : (
                  <>
                     <ButtonActions
                        label={"recordLink"}
                        linkTo={"/akuntansi/jurnal-umum/record"}
                        onClick={() => {
                           dispatcher['akuntansi'].setJurnalUmum(dataModel);
                        }}
                     />
                     <ButtonActions
                        label={"delete"}
                        onClick={async () => {
                           this['handleAlert'].show(item['jurnal']['id']);
                        }}
                     />
                  </>
               )),
            });
         }
         return item;
      });
      this['columntable'] = await this['DataTableModel'].getColumn();
      this['datatable'] = await this['DataTableModel'].getDatas();
      this.setState({
         ...this.state,
         pending: false,
      });
   }
   //** End: Load Data List */
   //* ----------------------------------------------------------------------------- */
   //** Start: Handle Alert */
   handleAlert = {
      show: (id) => {
         this.setState({
            ...this['state'],
            alert: {
               show: true,
               activeId: id
            }
         });
      },
      hide: () => {
         this.setState({
            ...this['state'],
            alert: {
               show: false,
               activeId: null
            }
         });
      },
      confirmed: async () => {
         await this['apiJurnalUmum'].deleteJurnalUmum(this['state']['alert']['activeId']).then((res) => {
            if (res['status']) {
               dispatcher['snackbar'].show("Berhasil", res['message'], "primary");
               this['handleAlert'].hide();
               this.LoadData();
            } else {
               return dispatcher['snackbar'].show("Gagal", res['message'], "danger");
            }
         });
      },
   };
   //** End: Handle Alert */
   render() {
      const { pending, alert } = this['state'];
      return (
         <>
            {/* Start: Jurnal Umum Table */}
            <AnimatedCard>
               <CardHeader title={this.pageName}>
                  <CardHeaderToolbar>
                     {/* Button Tambah */}
                     <Link
                        className={"btn btn-primary btn-sm"}
                        to={"/akuntansi/jurnal-umum/form"}
                        onClick={() => {
                           dispatcher['akuntansi'].resetActiveJurnalUmum();
                        }}>
                        <i className={"fa fa-plus"}></i> Tambah
                     </Link>
                  </CardHeaderToolbar>
               </CardHeader>
               <CardBody style={{ marginTop: "-20px" }}>
                  <ReactDatatable
                     columns={this['columntable']}
                     data={this['datatable']}
                     searchParameter={this['searchParameter']}
                     progressPending={pending}
                  />
               </CardBody>
            </AnimatedCard>
            {/* End: Jurnal Umum Table */}
            {/* ----------------------------------------------------------------------------- */}
            {/* Start: Alert Hapus */}
            <SimpleAlert
               title={"Hapus Data"}
               content={"Anda yakin hapus data ini...?"}
               show={alert['show']}
               onHide={() => this['handleAlert'].hide()}
               onConfirmed={this['handleAlert']['confirmed']}
            />
            {/* End: Alert Hapus */}
         </>
      );
   }
}
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Select2 from "react-select";
import store from '../../../../redux/store';
import { CardBody } from '../../../../../_metronic/_partials/controls';
import { LaporanGrafik } from '../../../../api/laporan-grafik';
import { AnimatedCard } from '../../../../components/animated-card/animatedCard';
import { separatorHarga } from '../../../../services/separator-harga';
import { saveAsExcel } from './helperExportExcel';
import { ReactDatatableFooter } from '../../../../components/react-datatable-footer/reactDatatableFooter';

function LaporanTargetPenjualan() {
    const [penjualan, setPenjualan] = useState([])
    const [footPenjualan, setFooter] = useState({})
    let total =  (arr) => arr.reduce((a, b) => {
        return a + parseInt(b)
    }, 0)
    const columns = [
        {
            name: 'Nama',
            selector: 'name',
            sortable: true,
            minWidth: '10%'
        },
        {
            name: 'January',
            selector: 'bulan1',
            sortable: true,
            minWidth: '10%'
        },
        {
            name: 'Februari',
            selector: 'bulan2',
            sortable: true,
            minWidth: '10%'
        },
        {
            name: 'Maret',
            selector: 'bulan3',
            sortable: true,
            minWidth: '10%'
        },
        {
            name: 'April',
            selector: 'bulan4',
            sortable: true,
            minWidth: '10%'
        },
        {
            name: 'Mei',
            selector: 'bulan5',
            sortable: true,
            minWidth: '10%'
        },
        {
            name: 'Juni',
            selector: 'bulan6',
            sortable: true,
            minWidth: '10%'
        },
        {
            name: 'Juli',
            selector: 'bulan7',
            sortable: true,
            minWidth: '10%'
        },
        {
            name: 'Agustus',
            selector: 'bulan8',
            sortable: true,
            minWidth: '10%'
        },
        {
            name: 'September',
            selector: 'bulan9',
            sortable: true,
            minWidth: '10%'
        },
        {
            name: 'Oktober',
            selector: 'bulan10',
            sortable: true,
            minWidth: '10%'
        },
        {
            name: 'November',
            selector: 'bulan11',
            sortable: true,
            minWidth: '10%'
        },
        {
            name: 'Desember',
            selector: 'bulan12',
            sortable: true,
            minWidth: '10%'
        },
        {
            name: 'Total',
            selector: 'total',
            sortable: true,
            minWidth: '10%'
        },
    ];
    
    const ksa = [20, 46, 52, 69];
    const kpp = [68, 28, 70, 71];
    const ksb = [108, 105, 110, 109];
  
    const [pt] = useState([{
      value: ksa,
      label: "KSA"
    }, {
      value: kpp,
      label: "KPP"
    }, {
      value: ksb,
      label: "KSB"
    }])
  
    const [selectedPT, setSelectedPT] = useState(ksa.includes(store.getState().auth.id_perusahaan) ? ksa : kpp.includes(store.getState().auth.id_perusahaan) ? kpp : ksb);

    const [cabang, setCabang] = useState([0]);

    const title = 'LaporanPenjualanCabang';
    const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>
        <i className={"far fa-file-excel"}></i> {" Export .xls"}
    </Button>
    const actionsMemo = <Export onExport={() => saveAsExcel(penjualan, title)} />

    const [opsi, setOptions] = useState([
    ]);

    let opsiCabang = []

    const coverage = async () => {
        await new LaporanGrafik().getCoverage().then((res) => {
            let filter = res.data.filter((data) => selectedPT.includes(data.id))
            opsiCabang = filter.map((data) => (
                {
                    label: data.text,
                    text: data.text,
                    value: data.id,
                }));
        });
        setOptions(opsiCabang);
    };

    const fetchData = async (data) => {
        try {
            await Promise.all([
                await new LaporanGrafik().laporanTarget(data),
            ]).then(([dt_penjualan]) => {
                let temp = []
                dt_penjualan.data.map((val) => {
                    console.log('Valll', val);
                    const map = new Map(val.map(({ nama_perusahaan, id_perusahaan, nilai_target, count, total_penjualan, total_target }) => [nama_perusahaan, { nama_perusahaan, id_perusahaan, total_target, nilai_target: [], count: [], total_penjualan: [], }]));
                    for (let { nama_perusahaan, id_perusahaan, nilai_target, count, total_penjualan, total_target } of val) {
                        map.get(id_perusahaan)
                        map.get(total_target)
                        map.get(nama_perusahaan).count.push(...[count].flat())
                        map.get(nama_perusahaan).total_penjualan.push(...[total_penjualan].flat())
                        map.get(nama_perusahaan).nilai_target.push(...[nilai_target].flat())
                    }
                    temp.push([...map.values()])
                    let arr1 = []
                    let arr2 = []
                    let arr3 = []
                    let arr4 = []
                    let arr5 = []
                    let arr6 = []
                    let arr7 = []
                    let arr8 = []
                    let arr9 = []
                    let arr10 = []
                    let arr11 = []
                    let arr12 = []
                    let arrttl = []
                    let tarr1 = []
                    let tarr2 = []
                    let tarr3 = []
                    let tarr4 = []
                    let tarr5 = []
                    let tarr6 = []
                    let tarr7 = []
                    let tarr8 = []
                    let tarr9 = []
                    let tarr10 = []
                    let tarr11 = []
                    let tarr12 = []
                    let tarrttl = []
                    temp.map((val) => {
                        for (const item of val) {
                            arr1.push(item.total_penjualan[0])
                            arr2.push(item.total_penjualan[1])
                            arr3.push(item.total_penjualan[2])
                            arr4.push(item.total_penjualan[3])
                            arr5.push(item.total_penjualan[4])
                            arr6.push(item.total_penjualan[5])
                            arr7.push(item.total_penjualan[6])
                            arr8.push(item.total_penjualan[7])
                            arr9.push(item.total_penjualan[8])
                            arr10.push(item.total_penjualan[9])
                            arr11.push(item.total_penjualan[10])
                            arr12.push(item.total_penjualan[11])
                            arrttl.push(item.total_penjualan)
                            tarr1.push(item.nilai_target[0])
                            tarr2.push(item.nilai_target[1])
                            tarr3.push(item.nilai_target[2])
                            tarr4.push(item.nilai_target[3])
                            tarr5.push(item.nilai_target[4])
                            tarr6.push(item.nilai_target[5])
                            tarr7.push(item.nilai_target[6])
                            tarr8.push(item.nilai_target[7])
                            tarr9.push(item.nilai_target[8])
                            tarr10.push(item.nilai_target[9])
                            tarr11.push(item.nilai_target[10])
                            tarr12.push(item.nilai_target[11])
                            tarrttl.push(item.total_target)
                            setPenjualan(penjualan => [...penjualan, {
                                name: item.nama_perusahaan,
                                penjualan: item.total_penjualan,
                                nilai_target: item.nilai_target || 0,
                                total_target: item.total_target || 0,
                                total: `${separatorHarga(total(item.total_penjualan).toString(), 'Rp.')} / ${separatorHarga(item.total_target.toString(), 'Rp.')}` || 0,
                                bulan1: `${separatorHarga(item.total_penjualan[0].toString(), 'Rp.')} / ${separatorHarga(item.nilai_target[0].toString(), 'Rp.')}` || 0,
                                bulan2: `${separatorHarga(item.total_penjualan[1].toString(), 'Rp.')} / ${separatorHarga(item.nilai_target[1].toString(), 'Rp.')}` || 0,
                                bulan3: `${separatorHarga(item.total_penjualan[2].toString(), 'Rp.')} / ${separatorHarga(item.nilai_target[2].toString(), 'Rp.')}` || 0,
                                bulan4: `${separatorHarga(item.total_penjualan[3].toString(), 'Rp.')} / ${separatorHarga(item.nilai_target[3].toString(), 'Rp.')}` || 0,
                                bulan5: `${separatorHarga(item.total_penjualan[4].toString(), 'Rp.')} / ${separatorHarga(item.nilai_target[4].toString(), 'Rp.')}` || 0,
                                bulan6: `${separatorHarga(item.total_penjualan[5].toString(), 'Rp.')} / ${separatorHarga(item.nilai_target[5].toString(), 'Rp.')}` || 0,
                                bulan7: `${separatorHarga(item.total_penjualan[6].toString(), 'Rp.')} / ${separatorHarga(item.nilai_target[6].toString(), 'Rp.')}` || 0,
                                bulan8: `${separatorHarga(item.total_penjualan[7].toString(), 'Rp.')} / ${separatorHarga(item.nilai_target[7].toString(), 'Rp.')}` || 0,
                                bulan9: `${separatorHarga(item.total_penjualan[8].toString(), 'Rp.')} / ${separatorHarga(item.nilai_target[8].toString(), 'Rp.')}` || 0,
                                bulan10: `${separatorHarga(item.total_penjualan[9].toString(), 'Rp.')} / ${separatorHarga(item.nilai_target[9].toString(), 'Rp.')}` || 0,
                                bulan11: `${separatorHarga(item.total_penjualan[10].toString(), 'Rp.')} / ${separatorHarga(item.nilai_target[10].toString(), 'Rp.')}` || 0,
                                bulan12: `${separatorHarga(item.total_penjualan[11].toString(), 'Rp.')} / ${separatorHarga(item.nilai_target[11].toString(), 'Rp.')}` || 0,
                            }])
                        }
                        let footerDt = {
                            bulan1: `${separatorHarga(total(arr1).toString(),'Rp.')} / ${separatorHarga(total(tarr1).toString(),'Rp.')}`,
                            bulan2: `${separatorHarga(total(arr2).toString(),'Rp.')} / ${separatorHarga(total(tarr2).toString(),'Rp.')}`,
                            bulan3: `${separatorHarga(total(arr3).toString(),'Rp.')} / ${separatorHarga(total(tarr3).toString(),'Rp.')}`,
                            bulan4: `${separatorHarga(total(arr4).toString(),'Rp.')} / ${separatorHarga(total(tarr4).toString(),'Rp.')}`,
                            bulan5: `${separatorHarga(total(arr5).toString(),'Rp.')} / ${separatorHarga(total(tarr5).toString(),'Rp.')}`,
                            bulan6: `${separatorHarga(total(arr6).toString(),'Rp.')} / ${separatorHarga(total(tarr6).toString(),'Rp.')}`,
                            bulan7: `${separatorHarga(total(arr7).toString(),'Rp.')} / ${separatorHarga(total(tarr7).toString(),'Rp.')}`,
                            bulan8: `${separatorHarga(total(arr8).toString(),'Rp.')} / ${separatorHarga(total(tarr8).toString(),'Rp.')}`,
                            bulan9: `${separatorHarga(total(arr9).toString(),'Rp.')} / ${separatorHarga(total(tarr9).toString(),'Rp.')}`,
                            bulan10: `${separatorHarga(total(arr10).toString(),'Rp.')} / ${separatorHarga(total(tarr10).toString(),'Rp.')}`,
                            bulan11: `${separatorHarga(total(arr11).toString(),'Rp.')} / ${separatorHarga(total(tarr11).toString(),'Rp.')}`,
                            bulan12: `${separatorHarga(total(arr12).toString(),'Rp.')} / ${separatorHarga(total(tarr12).toString(),'Rp.')}`,
                            total: `${separatorHarga(total(arrttl.flat(1)).toString(),'Rp.')} / ${separatorHarga(total(tarrttl).toString(),'Rp.')}`,
                        }
                        setFooter(footerDt)
                    })
                })
            })
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        let abortController = new AbortController();

        // if (dataSeries.length === 0)
        // {
        if (cabang.includes(0)) {
            fetchData(selectedPT);
            // setIdPerusahaan(selectedPT)
        } else {
            fetchData(cabang);
            // setIdPerusahaan(cabang)
        }
        coverage();
        return () => {
            abortController.abort()
        }
        // }
    }, [cabang, selectedPT])
    return (
        <>
            <AnimatedCard>
                <CardBody>
                    <div className="d-flex justify-content-start">
                        <div className="col-lg-3" style={{ marginTop: "20px" }}>
                            <label> Pilih PT </label>
                            <Select2
                                name={"PT"}
                                size="sm"
                                options={pt}
                                isClearable={false}
                                value={
                                    pt
                                        ? pt.find(
                                            (option) => option.value === selectedPT
                                        )
                                        : ""
                                }
                                onChange={(e) => {
                                    setSelectedPT(e ? e.value : []);
                                    setCabang([0])
                                    setPenjualan([]);
                                }}
                            />
                        </div>
                        <div className="col-lg-3" style={{ marginTop: "20px", zIndex: "5" }}>
                            <label> Pilih Perusahaan </label>
                            <Select2
                                name={"Cabang"}
                                placeholder={opsi.text}
                                size="sm"
                                options={opsi}
                                isClearable={true}
                                value={
                                    opsi
                                        ? opsi.find(
                                            (option) => option.value === cabang
                                        )
                                        : ""
                                }
                                onChange={(e) => {
                                    setCabang(e ? [e.value] : [0]);
                                    setPenjualan([]);
                                }}
                            />
                        </div>
                    </div>
                    <ReactDatatableFooter
                        columns={columns}
                        data={penjualan}
                        dense
                        pagination
                        searchParameter={['name']}
                        footer={footPenjualan}
                        actions={actionsMemo}
                    />
                </CardBody>
            </AnimatedCard>
        </>
    )
}


export default LaporanTargetPenjualan
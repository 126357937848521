import * as Yup from "yup";
import { ProdukAPI } from "../../api/produk";
import { SupplierAPI } from "../../api/supplier";
import { separatorHarga, hapusSeparator } from "../../services/separator-harga";
import store from "../../redux/store";

export default class StokMasukDetailModel {
  constructor(
    data = {
      id: 0,
      id_penerimaan_pembelian: 0,
      id_perusahaan: store.getState().auth.id_perusahaan,
      id_produk: 0,
      kode: "",
      tanggal: "",
      qty: 0,
      qty_inbound:0,
      hpp: 0,
      hpp_total: 0,
      dpp: 0,
      dpp_total: 0,
      diskon: 0,
      diskon_persen: 0,
      subtotal: 0,
      pajak: 0,
      pajak_persen: 11,
      total: 0,
      status_validasi: 0,
      id_gudang: 0,
      id_user: store.getState().auth.id,
      id_pemasok: 0,
      id_kontak: 0,
      deskripsi: "",
      status: 1,
      created_at: "",
      update_at: "",
      toProduk: {
        nama: "",
        kode:"",
      },
    }
  ) {
    this.id = data.id;
    this.id_penerimaan_pembelian = data.id_penerimaan_pembelian;
    this.id_perusahaan = data.id_perusahaan;
    this.id_produk = data.id_produk;
    this.kode = data.kode;
    this.qty = data.qty;
    this.qty_inbound = data.qty_inbound || 0;
    this.hpp = separatorHarga(data.hpp.toString());
    this.hpp_total = separatorHarga(data.hpp_total.toString());
    this.dpp = separatorHarga(data.dpp.toString());
    this.dpp_total = separatorHarga(data.dpp_total.toString());
    this.diskon = separatorHarga(data.diskon.toString());
    this.diskon_persen = data.diskon_persen;
    this.subtotal = separatorHarga(data.subtotal.toString());
    this.pajak = separatorHarga(data.pajak.toString());
    this.pajak_persen = data.pajak_persen;
    this.total = separatorHarga(data.total.toString());
    this.status_validasi = data.status_validasi;
    this.id_user = data.id_user;
    this.id_pemasok = data.id_pemasok;
    this.deskripsi = data.deskripsi;
    this.status = data.status;
    this.created_at = data.created_at;
    this.update_at = data.update_at;
    this.nama_produk = data.toProduk.nama;
    this.kode_barang = data.toProduk.kode;
  }

  removeSeparator() {
    this.hpp = parseInt(hapusSeparator(this.hpp));
    this.dpp = parseInt(hapusSeparator(this.dpp));
    this.hpp_total = parseInt(hapusSeparator(this.hpp_total));
    this.dpp_total = parseInt(hapusSeparator(this.dpp_total));
    this.diskon = parseInt(hapusSeparator(this.diskon));
    this.pajak = parseInt(hapusSeparator(this.pajak));
    this.subtotal = parseInt(hapusSeparator(this.subtotal));
    this.total = parseInt(hapusSeparator(this.total));
  }

  hitungTotalan = {
    all: (setFieldValue) => {
      this.hitungTotalan.hpp_total();
      this.hitungTotalan.subtotal();
      this.hitungTotalan.diskon();
      this.hitungTotalan.pajak();
      this.hitungTotalan.total();
      setFieldValue("hpp_total", this.hpp_total);
      setFieldValue("dpp_total", this.dpp_total);
      setFieldValue("diskon", this.diskon);
      setFieldValue("subtotal", this.subtotal);
      setFieldValue("pajak", this.pajak);
      setFieldValue("total", this.total);
    },
    hpp_total: () => {
      let hpp = parseInt(hapusSeparator(this.hpp));
      let dpp = parseInt(hapusSeparator(this.dpp));
      this.hpp_total = separatorHarga((this.qty * hpp).toString());
      this.dpp_total = separatorHarga((this.qty * dpp).toString());
    },
    diskon: () => {
      let hpp_total = parseInt(hapusSeparator(this.hpp_total));
      this.diskon = separatorHarga(
        ((hpp_total * this.diskon_persen) / 100).toString()
      );
    },
    subtotal: () => {
      let hpp_total = parseInt(hapusSeparator(this.hpp_total));
      this.subtotal = separatorHarga(
        (hpp_total - parseInt(hapusSeparator(this.diskon))).toString()
      );
    },
    pajak: () => {
      let subtotal = parseInt(hapusSeparator(this.subtotal));
      this.pajak = separatorHarga(
        ((subtotal * this.pajak_persen) / 100).toString()
      );
    },
    total: () => {
      let subtotal = parseInt(hapusSeparator(this.subtotal));
      this.total = separatorHarga(
        (subtotal + parseInt(hapusSeparator(this.pajak))).toString()
      );
    },
  };

  async getEditable(params = { pageName: "", isForm: false }) {
    let produk_options = [];
    if (params.isForm) {
      await new ProdukAPI()
        .getAll()
        .then((response) =>
          response.data.map(
            (data, i) =>
              (produk_options = [
                ...produk_options,
                { id: data.id, text: data.nama, value: parseInt(data.id) },
              ])
          )
        );
    }

    const getProdukDetail = async (id) => {
      return await new ProdukAPI()
        .getSingle(id)
        .then((res) => res.data.harga_beli);
    };

    return [
      {
        dataField: "kode_barang",
        label: "Kode Produk",
        type: "disabled-text",
        onChange: (e, setFieldValue) => {},
        value: this.kode_barang,
      },
      {
        validation: Yup.number()
          .typeError("")
          .required("Produk harus diisi"),
        dataField: "id_produk",
        label: "Produk",
        type: "disabled-text",
        onChange: (e, setFieldValue) => {},
        value: this.nama_produk,
      },
      {
        validation: Yup.number()
          .integer()
          .positive()
          .typeError("QTY tidak valid")
          .required("QTY harus diisi"),
        dataField: "qty",
        label: "QTY",
        type: "number",
        value: this.qty,
        onChange: (e, setFieldValue) => {
          this.qty = parseInt(e.target.value);
          this.hitungTotalan.all(setFieldValue);
        },
      },
      {
        validation: Yup.number()
          .integer()
          .positive()
          .typeError("QTY tidak valid")
          .required("QTY harus diisi"),
        dataField: "qty_inbound",
        label: "QTY Inbound",
        type: "number",
        value: this.qty_inbound,
        onChange: (e, setFieldValue) => {
          this.qty_inbound = parseInt(e.target.value);
        },
      },
      {
        validation: Yup.string(),
        dataField: "deskripsi",
        label: "Deskripsi",
        type: "textarea",
        value: this.deskripsi,
        onChange: (e) => (this.deskripsi = e.target.value),
        invisibleOnTable: true,
      },
    ];
  }
}

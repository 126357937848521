import { ResponseException } from "../../exeptions/ResponseException";
import ResponseModel from "../models/response-model/ResponseModel";
import { SimpleException } from "../../exeptions/SimpleException";
import { dispatcher } from "../redux/dispatcher";
import fetch from "cross-fetch";
import store from "../redux/store";
// production with port
export const API = {
  host: process.env.REACT_APP_API_URL,
};

export async function simpleFetch({
  url,
  body = {},
  method = "GET"
}) {
  dispatcher.loading.show();
  const token = store.getState().auth.token;
  let options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    method: method,
  };

  if (method === "POST" || method === "DELETE" || method === "PUT") {
    options = {
      ...options,
      body: JSON.stringify(body)
    };
  }

  let outp = new ResponseModel();

  await fetch(`${API.host}${url}`, options).then(async (res) => {
    if (res.status === 200) {
      outp = await ResponseException(new ResponseModel(await res.json()));
      outp.checkToken();
    } else {
      SimpleException(res);
    }
    setTimeout(() => {
      dispatcher.loading.hide();
    }, 1000);
  });
  return outp;
}

export async function simpleFetchGetData({
  url,
  body = {},
  method = "GET"
}) {
  const token = store.getState().auth.token;
  let options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    method: method,
  };

  if (method === "POST" || method === "DELETE" || method === "PUT") {
    options = {
      ...options,
      body: JSON.stringify(body)
    };
  }
  let outp = new ResponseModel();
  await fetch(`${API.host}${url}`, options).then(async (res) => {
    if (res.status === 200) {
      outp = await ResponseException(new ResponseModel(await res.json()));
      outp.checkToken();
    } else {
      SimpleException(res);
    }
  });
  return outp;
}

export async function FormDataFetch({
  url,
  body = {},
  method = "POST"
}) {
  dispatcher.loading.show();
  const token = store.getState().auth.token;
  let options = {
    mode: 'no-cors',
    headers: {
      Accept: "application/json",
      'Access-Control-Allow-Origin': '*',
      // "Content-Type": 'multipart/form-data',
      // "Content-Type": "application/json",
      Authorization: "Bearer " + token,
      // "type": "formData"
    },
    method: method,
  };

  options = {
    ...options,
    body: body
  };

  let outp = new ResponseModel();

  await fetch(`${API.host}${url}`, options).then(async (res) => {
    if (res.status === 200) {
      outp = await ResponseException(new ResponseModel(await res.json()));
      outp.checkToken();
    } else {
      SimpleException(res);
    }
    setTimeout(() => {
      dispatcher.loading.hide();
    }, 1000);
  });
  return outp;
}

export async function PrintDataFetch({
  url,
  body = {},
  method = "GET"
}) {
  let options = {
    method: method,
  };

  if (method === "POST" || method === "DELETE" || method === "PUT") {
    options = {
      ...options,
      body: JSON.stringify(body)
    };
  }
  await fetch(`${API.host}${url}`, options).then(async (res) => {
    return (window.open(res['url'],
      "_blank",
      "noopener,noreferrer"
    ));
  });
}

export const getIdPerusahaan = () => {
  return store.getState().auth.id_perusahaan;
};

export const getCoverageArea = () => {

  if (store.getState().auth.coverage.length === 0) {
    return store.getState().auth.id_perusahaan;
  }

  return store.getState().auth.coverage
};

export const getIdRole = () => {
  return store.getState()['auth']['role'];
};
export const getIdUser = () => {
  return store.getState()['auth']['id'];
};
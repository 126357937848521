import { CardBody, CardFooter, CardHeader } from '../../../../_metronic/_partials/controls';
import React, { Component } from 'react';
import { AnimatedCard } from '../../../components/animated-card/animatedCard';
import DataTableModel from '../../../models/datatable-model/DataTableModel';
import { Formik } from 'formik';
import { FormsFields } from '../../../components/forms-fields/FormsFields';
import { ReactDatatableFooterDetails } from '../../../components/react-datatable-footer/reactDatatableFooter';
import { akuntansiAPI } from '../../../api/akuntansi';
import { separatorHarga } from '../../../services/separator-harga';
import * as Yup from "yup";
import ButtonLoad from '../../../components/button-loading/buttonLoad';
import { getIdPerusahaan } from '../../../api/api';
import labaRugiModel from '../../../models/akuntansi/laba-rugi/LabaRugi';
import { Paper, Typography, makeStyles } from '@material-ui/core';
import store from '../../../redux/store';
import { ProgressBar } from 'react-bootstrap';
import moment from 'moment';

function simulateNetworkRequest ()
{
   return new Promise( resolve => setTimeout( resolve, 1000 ) );
}
export default class LabaRugi extends Component
{
   constructor( props )
   {
      super( props );
      this[ 'pageName' ] = "Laba Rugi";
      this[ 'tableTitleName' ] = "Nama Akun : ";
      this[ 'searchParameter' ] = [ "tanggal", "tanggal_insert", "no_transaksi", "debet", "kredit" ];
      this[ 'apiLabaRugi' ] = new akuntansiAPI();
      this[ 'options_cabang' ] = [];
      this[ 'state' ] = {
         pending: false,
         isLoading: true,
         percent: 0,
         initialValues: {
            start_date: new Date( moment().startOf( 'month' ) ),
            end_date: new Date(),
            id_region: "",
            id_perusahaan: getIdPerusahaan(),
            id_user: store.getState().auth[ 'id' ],
            ceklist_nilai: false,
            empty_table: true
         },
         footerPenjualan: {
            nama_akun: "Total",
            saldo: 0,
         },
         footerReturnPenjualan: {
            nama_akun: "Total",
            saldo: 0,
         },
         footerDiscount: {
            nama_akun: "Total",
            saldo: 0,
         },
         penjualan_bersih: 0,
         footerHargaPokok: {
            nama_akun: "Total",
            saldo: 0,
         },
         gross_margin: 0,
         footerBiayaBiaya: {
            nama_akun: "Total",
            saldo: 0,
         },
         laba_operasional: 0,
         footerPendapatanLainLain: {
            nama_akun: "Total",
            saldo: 0,
         },
         footerBiayaLainLain: {
            nama_akun: "Total",
            saldo: 0,
         },
         laba_rugi_bersih_sebelum_pajak: 0,
         pajak_penghasilan: 0,
         laba_rugi_bersih_setelah_pajak: 0
      }
      this[ 'increase' ] = this[ 'increase' ].bind( this );
      this[ 'increase' ] = this[ 'increase' ].bind( this );
      // Penjualan
      this[ 'columntablePenjualan' ] = [ { id: "", title: "" } ];
      this[ 'datatablePenjualan' ] = [ { id: "", no: "", name: "" } ];
      // Return Penjualan
      this[ 'columntableReturnPenjualan' ] = [ { id: "", title: "" } ];
      this[ 'datatableReturnPenjualan' ] = [ { id: "", no: "", name: "" } ];
      // Discount
      this[ 'columntableDiscount' ] = [ { id: "", title: "" } ];
      this[ 'datatableDiscount' ] = [ { id: "", no: "", name: "" } ];
      // Harga Pokok
      this[ 'columntableHargaPokok' ] = [ { id: "", title: "" } ];
      this[ 'datatableHargaPokok' ] = [ { id: "", no: "", name: "" } ];
      // Biaya Biaya
      this[ 'columntableBiayaBiaya' ] = [ { id: "", title: "" } ];
      this[ 'datatableBiayaBiaya' ] = [ { id: "", no: "", name: "" } ];
      // Pendapatan Lain-Lain
      this[ 'columntablePendapatanLainLain' ] = [ { id: "", title: "" } ];
      this[ 'datatablePendapatanLainLain' ] = [ { id: "", no: "", name: "" } ];
      // Biaya Lain-Lain
      this[ 'columntableBiayaLainLain' ] = [ { id: "", title: "" } ];
      this[ 'datatableBiayaLainLain' ] = [ { id: "", no: "", name: "" } ];
   }
   //* ----------------------------------------------------------------------------- */
   //** Begin: Component */
   async componentDidMount ()
   {
      await this.LoadDataCabang( this[ 'state' ][ 'initialValues' ][ 'id_region' ] );
      await this.LoadData( this[ 'state' ][ 'initialValues' ] );
   }
   //** End: Component */
   //* ----------------------------------------------------------------------------- */
   // Begin: Cabang
   async LoadDataCabang ( id_region )
   {
      this.setState( { isLoading: true }, () =>
      {
         simulateNetworkRequest().then( () =>
         {
            this[ 'apiLabaRugi' ].getPerusahaan( id_region ).then( ( values ) =>
            {
               this[ 'options_cabang' ] = values[ 'data' ].map( ( data ) => ( {
                  label: data[ 'text' ],
                  value: data[ 'id' ],
               } ) );
            } );
            this.setState( { isLoading: false } );
         } );
      } );

   }
   // End: Cabang
   //* ----------------------------------------------------------------------------- */
   //** Begin: Progress Bar Loading */
   increase ( values )
   {
      const { percent } = this.state;
      const newPercent = percent + 1;
      if ( newPercent >= 100 )
      {
         clearTimeout( this.tm );
         return;
      }
      this.setState( { percent: newPercent } );
      this.tm = setTimeout(
         this.LoadData( {
            ...values,
            empty_table: false
         } ), 10 );
   }
   // End: Progress Bar Loading
   //* ----------------------------------------------------------------------------- */
   //** Begin: Load Data */
   async LoadData ( form )
   {
      this.setState( { pending: true } );
      let data = await this[ 'apiLabaRugi' ].getAllLabaRugi( form );
      // Penjualan
      this[ 'DataTableModelPenjualan' ] = new DataTableModel( { readOnly: true } );
      data[ 'data' ][ 'penjualan' ].map( ( item, i ) =>
      {
         let dataModel = new labaRugiModel( item );
         this[ 'DataTableModelPenjualan' ].add( {
            id: item[ 'id' ],
            no: i + 1,
            dataModel: dataModel,
            actions: null,
         } );
         return item;
      } );
      var sumPenjualan = data[ 'data' ][ 'penjualan' ].reduce( function ( pv, cv ) { return pv + cv[ 'saldo' ]; }, 0 );
      this[ 'columntablePenjualan' ] = await this[ 'DataTableModelPenjualan' ].getColumn();
      this[ 'datatablePenjualan' ] = await this[ 'DataTableModelPenjualan' ].getDatas();
      // Return Penjualan
      this[ 'DataTableModelRPenjualan' ] = new DataTableModel( { readOnly: true } );
      data[ 'data' ][ 'returnPenjualan' ].map( ( item, i ) =>
      {
         let dataModel = new labaRugiModel( item );
         this[ 'DataTableModelRPenjualan' ].add( {
            id: item[ 'id' ],
            no: i + 1,
            dataModel: dataModel,
            actions: null,
         } );
         return item;
      } );
      var sumRPenjualan = data[ 'data' ][ 'returnPenjualan' ].reduce( function ( pv, cv ) { return pv + cv[ 'saldo' ]; }, 0 );
      this[ 'columntableReturnPenjualan' ] = await this[ 'DataTableModelRPenjualan' ].getColumn();
      this[ 'datatableReturnPenjualan' ] = await this[ 'DataTableModelRPenjualan' ].getDatas();
      // Discount
      this[ 'DataTableModelDiscount' ] = new DataTableModel( { readOnly: true } );
      data[ 'data' ][ 'discount' ].map( ( item, i ) =>
      {
         let dataModel = new labaRugiModel( item );
         this[ 'DataTableModelDiscount' ].add( {
            id: item[ 'id' ],
            no: i + 1,
            dataModel: dataModel,
            actions: null,
         } );
         return item;
      } );
      var sumDiscount = data[ 'data' ][ 'discount' ].reduce( function ( pv, cv ) { return pv + cv[ 'saldo' ]; }, 0 );
      this[ 'columntableDiscount' ] = await this[ 'DataTableModelDiscount' ].getColumn();
      this[ 'datatableDiscount' ] = await this[ 'DataTableModelDiscount' ].getDatas();
      // Harga Pokok
      this[ 'DataTableModelHargaPokok' ] = new DataTableModel( { readOnly: true } );
      data[ 'data' ][ 'hargaPokok' ].map( ( item, i ) =>
      {
         let dataModel = new labaRugiModel( item );
         this[ 'DataTableModelHargaPokok' ].add( {
            id: item[ 'id' ],
            no: i + 1,
            dataModel: dataModel,
            actions: null,
         } );
         return item;
      } );
      var sumHargaPokok = data[ 'data' ][ 'hargaPokok' ].reduce( function ( pv, cv ) { return pv + cv[ 'saldo' ]; }, 0 );
      this[ 'columntableHargaPokok' ] = await this[ 'DataTableModelHargaPokok' ].getColumn();
      this[ 'datatableHargaPokok' ] = await this[ 'DataTableModelHargaPokok' ].getDatas();
      // Biaya Biaya
      this[ 'DataTableModelBiayaBiaya' ] = new DataTableModel( { readOnly: true } );
      data[ 'data' ][ 'biayaBiaya' ].map( ( item, i ) =>
      {
         let dataModel = new labaRugiModel( item );
         this[ 'DataTableModelBiayaBiaya' ].add( {
            id: item[ 'id' ],
            no: i + 1,
            dataModel: dataModel,
            actions: null,
         } );
         return item;
      } );
      var sumBiayaBiaya = data[ 'data' ][ 'biayaBiaya' ].reduce( function ( pv, cv ) { return pv + cv[ 'saldo' ]; }, 0 );
      this[ 'columntableBiayaBiaya' ] = await this[ 'DataTableModelBiayaBiaya' ].getColumn();
      this[ 'datatableBiayaBiaya' ] = await this[ 'DataTableModelBiayaBiaya' ].getDatas();
      // Pendapatan Lain-Lain
      this[ 'DataTableModelPendapatanLainLain' ] = new DataTableModel( { readOnly: true } );
      data[ 'data' ][ 'pendapatanLainLain' ].map( ( item, i ) =>
      {
         let dataModel = new labaRugiModel( item );
         this[ 'DataTableModelPendapatanLainLain' ].add( {
            id: item[ 'id' ],
            no: i + 1,
            dataModel: dataModel,
            actions: null,
         } );
         return item;
      } );
      var sumPendapatanLainLain = data[ 'data' ][ 'pendapatanLainLain' ].reduce( function ( pv, cv ) { return pv + cv[ 'saldo' ]; }, 0 );
      this[ 'columntablePendapatanLainLain' ] = await this[ 'DataTableModelPendapatanLainLain' ].getColumn();
      this[ 'datatablePendapatanLainLain' ] = await this[ 'DataTableModelPendapatanLainLain' ].getDatas();
      // Biaya Lain-Lain
      this[ 'DataTableModelBiayaLainLain' ] = new DataTableModel( { readOnly: true } );
      data[ 'data' ][ 'biayaLainLain' ].map( ( item, i ) =>
      {
         let dataModel = new labaRugiModel( item );
         this[ 'DataTableModelBiayaLainLain' ].add( {
            id: item[ 'id' ],
            no: i + 1,
            dataModel: dataModel,
            actions: null,
         } );
         return item;
      } );
      var sumBiayaLainLain = data[ 'data' ][ 'biayaLainLain' ].reduce( function ( pv, cv ) { return pv + cv[ 'saldo' ]; }, 0 );
      this[ 'columntableBiayaLainLain' ] = await this[ 'DataTableModelBiayaLainLain' ].getColumn();
      this[ 'datatableBiayaLainLain' ] = await this[ 'DataTableModelBiayaLainLain' ].getDatas();
      var penjualanBersih = [ sumPenjualan, sumRPenjualan, sumDiscount ].reduce( function ( pv, cv ) { return pv + cv; }, 0 );
      var grossMargin = [ penjualanBersih, sumHargaPokok ].reduce( function ( pv, cv ) { return pv + cv; }, 0 );
      var labaOperasional = [ grossMargin, sumBiayaBiaya ].reduce( function ( pv, cv ) { return pv + cv; }, 0 );
      var labaRugiSebelumPajak = [ labaOperasional, sumPendapatanLainLain, sumBiayaLainLain ].reduce( function ( pv, cv ) { return pv + cv; }, 0 );
      var labaRugiBersih = labaRugiSebelumPajak - data[ 'data' ][ 'pajakPenghasilan' ];
      this.setState( {
         ...this[ 'state' ],
         pending: false,
         percent: 0,
         footerPenjualan: {
            nama_akun: "Total",
            saldo: sumPenjualan < 0 ? `Rp. -${ separatorHarga( sumPenjualan.toString() ) }` : separatorHarga( sumPenjualan.toString(), "Rp. " ),
         },
         footerReturnPenjualan: {
            nama_akun: "Total",
            saldo: sumRPenjualan < 0 ? `Rp. -${ separatorHarga( sumRPenjualan.toString() ) }` : separatorHarga( sumRPenjualan.toString(), "Rp. " ),
         },
         footerDiscount: {
            nama_akun: "Total",
            saldo: sumDiscount < 0 ? `Rp. -${ separatorHarga( sumDiscount.toString() ) }` : separatorHarga( sumDiscount.toString(), "Rp. " ),
         },
         penjualan_bersih: penjualanBersih,
         footerHargaPokok: {
            nama_akun: "Total",
            saldo: sumHargaPokok < 0 ? `Rp. -${ separatorHarga( sumHargaPokok.toString() ) }` : separatorHarga( sumHargaPokok.toString(), "Rp. " ),
         },
         gross_margin: grossMargin,
         footerBiayaBiaya: {
            nama_akun: "Total",
            saldo: sumBiayaBiaya < 0 ? `Rp. -${ separatorHarga( sumBiayaBiaya.toString() ) }` : separatorHarga( sumBiayaBiaya.toString(), "Rp. " ),
         },
         laba_operasional: labaOperasional,
         footerPendapatanLainLain: {
            nama_akun: "Total",
            saldo: sumPendapatanLainLain < 0 ? `Rp. -${ separatorHarga( sumPendapatanLainLain.toString() ) }` : separatorHarga( sumPendapatanLainLain.toString(), "Rp. " ),
         },
         footerBiayaLainLain: {
            nama_akun: "Total",
            saldo: sumBiayaLainLain < 0 ? `Rp. -${ separatorHarga( sumBiayaLainLain.toString() ) }` : separatorHarga( sumBiayaLainLain.toString(), "Rp. " ),
         },
         laba_rugi_bersih_sebelum_pajak: labaRugiSebelumPajak,
         pajak_penghasilan: data[ 'data' ][ 'pajakPenghasilan' ],
         laba_rugi_bersih_setelah_pajak: labaRugiBersih
      } );
   }
   //** End: Load Data */
   render ()
   {
      const useStyles = makeStyles( theme => ( {
         root: {
            textTransform: "uppercase",
            textAlign: "right",
            padding: theme.spacing( 1, 2 ),
            backgroundColor: "#f3f6f9",
         },
      } ) );
      const { percent, isLoading, pending, initialValues, footerPenjualan, footerReturnPenjualan, footerDiscount, penjualan_bersih, footerHargaPokok, gross_margin, footerBiayaBiaya, laba_operasional, footerPendapatanLainLain, footerBiayaLainLain, laba_rugi_bersih_sebelum_pajak, pajak_penghasilan, laba_rugi_bersih_setelah_pajak } = this[ 'state' ];
      return (
         <>
            {/* Start: Formik List */ }
            <Formik
               enableReinitialize={ true }
               initialValues={ initialValues }
               validationSchema={ Yup.object().shape( {
                  start_date: Yup.string().nullable().required(),
                  // id_perusahaan: Yup.string().required("Cabang harus diisi"),
               } ) }
               onSubmit={ ( values ) =>
               {
                  this.LoadData( {
                     ...values,
                     empty_table: false
                  } )
                  // this.increase(values);
               } }
            >
               { ( { setFieldValue, setFieldTouched, touched, errors, values, handleSubmit } ) => (
                  <AnimatedCard>
                     <CardHeader title={ this.pageName }></CardHeader>
                     <CardBody style={ { marginTop: "-20px" } }>
                        <div className={ "form-group row" }>
                           {/* Forms Start Date  */ }
                           <FormsFields
                              id={ "start_date" }
                              type={ "DatePicker" }
                              label={ "Tanggal Mulai" }
                              className={ "col-lg-4" }
                              style={ { marginTop: "20px" } }
                              paramsFormik={ {
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              } }
                           />
                           {/* Forms End Date  */ }
                           <FormsFields
                              id={ "end_date" }
                              type={ "DatePicker" }
                              label={ "Tanggal Akhir" }
                              className={ "col-lg-4" }
                              style={ { marginTop: "20px" } }
                              paramsFormik={ {
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              } }
                           />
                           {/* Forms Select Region */ }
                           <FormsFields
                              id={ "id_region" }
                              type={ "select2" }
                              label={ "Region" }
                              style={ { marginTop: "20px" } }
                              className={ "col-lg-4" }
                              isClearable={ true }
                              isSearchable={ true }
                              options={ [ {
                                 label: "PT. KUMALA SUKSES ABADI",
                                 value: 8,
                              }, {
                                 label: "PT. KUMALA PUTRA PRIMA",
                                 value: 16,
                              }, {
                                 label: "PT. KUMALA SUKSES BERSAMA",
                                 value: 30,
                              } ] }
                              onChange={ ( e ) =>
                              {
                                 this.LoadDataCabang( e ? e[ 'value' ] : "" );
                                 setFieldValue( 'id_perusahaan', "" );
                                 // setFieldValue('kode_akun', "");
                              } }
                              paramsFormik={ {
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              } }
                           />
                           {/* Forms Select Cabang */ }
                           <FormsFields
                              id={ "id_perusahaan" }
                              type={ "select2" }
                              label={ "Cabang" }
                              style={ { marginTop: "20px" } }
                              className={ "col-lg-4" }
                              isClearable={ true }
                              isSearchable={ true }
                              isLoading={ isLoading }
                              options={ this[ 'options_cabang' ] }
                              paramsFormik={ {
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              } }
                           />
                        </div>
                        <div className={ "form-group row" }>
                           {/* Tampilkan Akun dengan nilai 0 */ }
                           <FormsFields
                              id={ "ceklist_nilai" }
                              type={ "checkbox" }
                              label={ "Tampilkan Akun dengan nilai 0" }
                              style={ { marginTop: "-10px" } }
                              className={ "col-lg-4" }
                              paramsFormik={ {
                                 setFieldValue,
                                 values
                              } }
                           />
                        </div>
                        {/* {pending ?
                           <div className="mb-5">
                              <ProgressBar animated={true} now={percent} label={`${percent}%`} min={0} max={100} />
                           </div> : null} */}
                        {/* Button */ }
                        <div style={ { display: "flex", justifyContent: "center" } } >
                           {/* Button Lihat Data */ }
                           <ButtonLoad
                              label={ "Lihat Data" }
                              pending={ pending }
                              classNameIcon={ "fa fa-filter" }
                              className={ "btn btn-outline-success" }
                              onClick={ async () => handleSubmit() }
                           />
                        </div>
                     </CardBody>
                     <CardFooter>
                        {/* Penjualan */ }
                        <ReactDatatableFooterDetails
                           title={ "PENJUALAN" }
                           data={ this[ 'datatablePenjualan' ] }
                           columns={ this[ 'columntablePenjualan' ] }
                           footer={ footerPenjualan }
                           progressPending={ pending }
                           dense={ true }
                        />
                        {/* Return Penjualan */ }
                        <ReactDatatableFooterDetails
                           title={ "RETURN PENJUALAN" }
                           data={ this[ 'datatableReturnPenjualan' ] }
                           columns={ this[ 'columntableReturnPenjualan' ] }
                           footer={ footerReturnPenjualan }
                           progressPending={ pending }
                           dense={ true }
                        />
                        {/* Return Penjualan */ }
                        <ReactDatatableFooterDetails
                           title={ "DISCOUNT" }
                           data={ this[ 'datatableDiscount' ] }
                           columns={ this[ 'columntableDiscount' ] }
                           footer={ footerDiscount }
                           progressPending={ pending }
                           dense={ true }
                        />
                        {/* Total Penjualan Bersih */ }
                        <div className={ "tab-content mt-5" }>
                           <div className={ "table-responsive" }>
                              <Paper className={ useStyles().root }>
                                 <Typography variant={ "h5" } component={ "h3" }>
                                    PENJUALAN BERSIH : { ( penjualan_bersih < 0 ) ? "Rp. -" + separatorHarga( penjualan_bersih.toString() ) :
                                       separatorHarga( penjualan_bersih.toString(), "Rp. " ) }
                                 </Typography>
                              </Paper>
                           </div>
                        </div>
                        {/* Harga Pokok */ }
                        <ReactDatatableFooterDetails
                           title={ "HARGA POKOK" }
                           data={ this[ 'datatableHargaPokok' ] }
                           columns={ this[ 'columntableHargaPokok' ] }
                           footer={ footerHargaPokok }
                           progressPending={ pending }
                           dense={ true }
                        />
                        {/* Total Groos Marin */ }
                        <div className={ "tab-content mt-5" }>
                           <div className={ "table-responsive" }>
                              <Paper className={ useStyles().root }>
                                 <Typography variant={ "h5" } component={ "h3" }>
                                    GROSS MARGIN : { ( gross_margin < 0 ) ? "Rp. -" + separatorHarga( gross_margin.toString() ) :
                                       separatorHarga( gross_margin.toString(), "Rp. " ) }
                                 </Typography>
                              </Paper>
                           </div>
                        </div>
                        {/* Biaya Biaya */ }
                        <ReactDatatableFooterDetails
                           title={ "BIAYA BIAYA" }
                           data={ this[ 'datatableBiayaBiaya' ] }
                           columns={ this[ 'columntableBiayaBiaya' ] }
                           footer={ footerBiayaBiaya }
                           progressPending={ pending }
                           dense={ true }
                        />
                        {/* Total Laba Operasional */ }
                        <div className={ "tab-content mt-5" }>
                           <div className={ "table-responsive" }>
                              <Paper className={ useStyles().root }>
                                 <Typography variant={ "h5" } component={ "h3" }>
                                    LABA OPERASIONAL : { ( laba_operasional < 0 ) ? "Rp. -" + separatorHarga( laba_operasional.toString() ) :
                                       separatorHarga( laba_operasional.toString(), "Rp. " ) }
                                 </Typography>
                              </Paper>
                           </div>
                        </div>
                        {/* Pendapatan Lain-Lain */ }
                        <ReactDatatableFooterDetails
                           title={ "PENDAPATAN LAIN-LAIN" }
                           data={ this[ 'datatablePendapatanLainLain' ] }
                           columns={ this[ 'columntablePendapatanLainLain' ] }
                           footer={ footerPendapatanLainLain }
                           progressPending={ pending }
                           dense={ true }
                        />
                        {/* Biaya Lain-Lain */ }
                        <ReactDatatableFooterDetails
                           title={ "BIAYA LAIN-LAIN" }
                           data={ this[ 'datatableBiayaLainLain' ] }
                           columns={ this[ 'columntableBiayaLainLain' ] }
                           footer={ footerBiayaLainLain }
                           progressPending={ pending }
                           dense={ true }
                        />
                        {/* Total Laba Rugi Bersih Sebelum Pajak */ }
                        <div className={ "tab-content mt-5" }>
                           <div className={ "table-responsive" }>
                              <Paper className={ useStyles().root }>
                                 <Typography variant={ "h5" } component={ "h3" }>
                                    LABA RUGI BERSIH SEBELUM PAJAK : { ( laba_rugi_bersih_sebelum_pajak < 0 ) ? "Rp. -" + separatorHarga( laba_rugi_bersih_sebelum_pajak.toString() ) :
                                       separatorHarga( laba_rugi_bersih_sebelum_pajak.toString(), "Rp. " ) }
                                 </Typography>
                              </Paper>
                           </div>
                        </div>
                        {/* Pendapatan Lain-Lain */ }
                        <div className={ "tab-content mt-5" }>
                           <div className={ "table-responsive" }>
                              <Paper className={ useStyles().root }>
                                 <Typography variant={ "h5" } component={ "h3" }>
                                    PAJAK PENGHASILAN : { ( pajak_penghasilan < 0 ) ? "Rp. -" + separatorHarga( pajak_penghasilan.toString() ) :
                                       separatorHarga( pajak_penghasilan ? pajak_penghasilan.toString() : "0", "Rp. " ) }
                                 </Typography>
                              </Paper>
                           </div>
                        </div>
                        {/* Total Laba Rugi Setelah Pajak */ }
                        <div className={ "tab-content mt-5" }>
                           <div className={ "table-responsive" }>
                              <Paper className={ useStyles().root }>
                                 <Typography variant={ "h5" } component={ "h3" }>
                                    LABA RUGI BERSIH SETELAH PAJAK : { ( laba_rugi_bersih_setelah_pajak < 0 ) ? "Rp. -" + separatorHarga( laba_rugi_bersih_setelah_pajak.toString() ) :
                                       separatorHarga( laba_rugi_bersih_setelah_pajak.toString(), "Rp. " ) }
                                 </Typography>
                              </Paper>
                           </div>
                        </div>
                     </CardFooter>
                  </AnimatedCard>
               ) }
            </Formik>
            {/* End: Formik List */ }
         </>
      );
   }
}
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls'
import { AnimatedCard } from '../../../components/animated-card/animatedCard'
import { Button } from 'react-bootstrap'
import { useState } from 'react'
import { Form, Formik } from 'formik'
import _ from 'lodash'
import store from '../../../redux/store'
import { simpleFetch } from '../../../api/api'
import Select2 from "react-select";
import { useEffect } from 'react'
import { FormsFields } from '../../../components/forms-fields/FormsFields'
import { hapusSeparator } from '../../../services/separator-harga'
import { PenerimaanAPI } from '../../../api/penerimaan'

function TargetPenjualan() {
    const [slcYear, setSlcYear] = useState(new Date().getFullYear())
    // const [slcMth, setSlcMth] = useState(new Date().getMonth())
    const id_user = store.getState().auth.id;
    const [cabang, setCabang] = useState(store.getState().auth.id_perusahaan)
    const [option, setOptions] = useState([])
    const currYear = new Date().getFullYear();
    const arrTahun = []
    const coverage = async () => {
        let options = [];
        await new PenerimaanAPI().getCoverage().then((res) => {
            options = res.data.map((data) => ({
                label: data.text,
                text: data.text,
                value: data.id,
            }));
        });
        setOptions(options);
    };
    Array.from(new Array(10), (v, i) => {
        return arrTahun.push({
            value: currYear + i,
            label: currYear + i,
        })
    });
    const defData = [{
        id: 1,
        value: 0,
        name: 'Januari',
        nilai_target: "0",
    },
    {
        id: 2,
        value: 1,
        name: 'Februari',
        nilai_target: "0",
    },
    {
        id: 3,
        value: 2,
        name: 'Maret',
        nilai_target: "0",
    },
    {
        id: 4,
        value: 3,
        name: 'April',
        nilai_target: "0",
    },
    {
        id: 5,
        value: 4,
        name: 'Mei',
        nilai_target: "0",
    },
    {
        id: 6,
        value: 5,
        name: 'Juni',
        nilai_target: "0",
    },
    {
        id: 7,
        value: 6,
        name: 'Juli',
        nilai_target: "0",
    },
    {
        id: 8,
        value: 7,
        name: 'Agustus',
        nilai_target: "0",
    },
    {
        id: 9,
        value: 8,
        name: 'September',
        nilai_target: "0",
    },
    {
        id: 10,
        value: 9,
        name: 'Oktober',
        nilai_target: "0",
    },
    {
        id: 11,
        value: 10,
        name: 'November',
        nilai_target: "0",
    },
    {
        id: 12,
        value: 11,
        name: 'Desember',
        nilai_target: "0",
    },
    ];
    const [employeeData, setEmployeeData] = useState(defData)

    const onChangeInput = (e, id) => {
        const { name, value } = e.target

        const editData = employeeData.map((item) =>
            item.id === id && name ? { ...item, [name]: value } : item
        )

        setEmployeeData(editData)
    }

    const loadData = async (data) => {
        try {
            await simpleFetch({
                url: `retail/target/index?id_perusahaan=${data}&year=${slcYear}`,
                method: "GET",
            }).then((res) => {
                if (res.data.length > 0) {
                    let dataModify = []
                    res.data.map((val) => {
                        dataModify.push({
                            id_perusahaan: val.id_perusahaan,
                            nilai_target: (val.nilai_target.toString()),
                            tahun: val.tahun,
                            bulan: val.bulan
                        })
                    })
                    // console.log("Dataaaaa",data);
                    let merged = _.merge(_.keyBy(defData, 'value'), _.keyBy(dataModify, 'bulan'));
                    let values = _.values(merged);
                    console.log("Values", values);
                    setEmployeeData(values)
                }
                console.log("cek customer", res.data);
            })
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        loadData(cabang)
        coverage()
        console.log("Dataaaa", employeeData);
    }, [slcYear, cabang])

    return (
        <>
            <AnimatedCard>
                <CardHeader title={'Master Target Penjualan'}>
                    <CardHeaderToolbar >
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <Formik
                        initialValues={{
                            year: currYear,
                            nilai_target: 0
                        }}
                        onSubmit={async (values) => {
                            console.log("Values", values);
                            try {
                                await simpleFetch({
                                    url: `retail/target/post`,
                                    method: "POST",
                                    body:
                                    {
                                        year: values.year,
                                        month: employeeData.map((val) => val.value),
                                        id_perusahaan: cabang,
                                        data: employeeData.map((val) => parseInt(hapusSeparator(val.nilai_target))),
                                        id_user: id_user
                                    },
                                }).then((res) => {
                                    console.log("cek customer", res);
                                })
                            } catch (error) {
                                console.error(error);
                            }
                        }}
                    >
                        {({ values, handleSubmit, handleChange, setFieldValue, }) => (
                            <Form>
                                <div className='d-flex justify-content-center'>
                                    <div className="col-md-6" style={{}}>
                                        <label> Pilih Tahun </label>
                                        <Select2
                                            name="tahun"
                                            placeholder={" --- Pilih Tahun --- "}
                                            size="sm"
                                            options={arrTahun}
                                            isClearable={false}
                                            value={
                                                arrTahun
                                                    ? arrTahun.find(
                                                        (data) => data.value === slcYear
                                                    )
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                values.year = e.value
                                                setEmployeeData(defData)
                                                setSlcYear(e ? e.value : []);
                                                // setFieldValue("cabang", e ? e.value : "");
                                            }}
                                        />
                                    </div>
                                    <div className="col-md-6" style={{}}>
                                        <label> Pilih Perusahaan </label>
                                        <Select2
                                            name="cabang"
                                            placeholder={" --- Pilih Bulan --- "}
                                            size="sm"
                                            options={option}
                                            isClearable={false}
                                            value={
                                                option
                                                    ? option.find(
                                                        (data) => data.value === cabang
                                                    )
                                                    : ""
                                            }
                                            onChange={(e) => {
                                                // values.month = e.value
                                                console.log("Value Select", e.value);
                                                setEmployeeData(defData)
                                                setCabang(e ? e.value : []);
                                                loadData(e.value)
                                                // setFieldValue("cabang", e ? e.value : "");
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="container">
                                    <table className='table table-sm' style={{ marginTop: '10%' }}>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th className='text-center'>Bulan</th>
                                                <th className='text-center'>Nilai Target</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {employeeData.map(({ id, name, nilai_target, }) => (
                                                <tr key={id}>
                                                    <td>
                                                        <input
                                                            className='form-control'
                                                            name="name"
                                                            value={name}
                                                            type="text"
                                                            onChange={(e) => {
                                                                onChangeInput(e, id)
                                                            }}
                                                            placeholder="Type Name"
                                                            disabled
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormsFields
                                                            id={"nilai_target"}
                                                            type={"currency"}
                                                            // label={"Jumlah"}
                                                            // className={"form-control"}
                                                            // style={{ marginBottom: "20px" }}
                                                            value={nilai_target}
                                                            paramsFormik={{
                                                                setFieldValue,
                                                                values
                                                            }}
                                                            onChange={(e) => {
                                                                values.nilai_target = e.target.value
                                                                onChangeInput(e, id)
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className='d-flex flex-row-reverse'>
                                        <Button
                                            onClick={() => {
                                                handleSubmit()
                                            }}
                                        >
                                            <i className={"fa fa-save"}></i> Simpan
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>

                </CardBody>
            </AnimatedCard >
        </>
    )
}

export default TargetPenjualan
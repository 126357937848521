import React, { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import List from "./list";
import Detail from "./detail"
import Validasi from "./validasi"
import DaftarRequestModel from "../../../models/stok-req-model/DaftarRequestModel";

export default function KirimTransferReq() {
  const [dataFromModel, setDataFromModel] = useState(new DaftarRequestModel());
  const history = useHistory();

  return (
    <Switch>
      <Route path="/kirim-transfer-stok/list">
        <List setDataFromModel={setDataFromModel} />
      </Route>
      <Route path="/kirim-transfer-stok/detail">
        <Detail history={history} dataFromModel={dataFromModel} />
      </Route>
      <Route path="/kirim-transfer-stok/validasi">
        <Validasi history={history} dataFromModel={dataFromModel} />
      </Route>
      <Redirect from="/kirim-transfer-stok/" to="/kirim-transfer-stok/list"/>
    </Switch>
  )
  }
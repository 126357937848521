import { CardBody, CardHeader } from "../../../../../_metronic/_partials/controls";
import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import DataTableModel from "../../../../models/datatable-model/DataTableModel";
import React from "react";
import { ReactDatatable } from "../../../../components/react-datatable/reactDatatable";
import { dispatcher } from "../../../../redux/dispatcher";
import ButtonActions from "../../../../components/button-actions/buttonActions";
import { notificationAPI } from "../../../../api/notifications";
import { separatorHarga } from "../../../../services/separator-harga";
import OrganisasiModel from "../../../../models/kontak/organisasi/OrganisasiModel";

export default class ApprovalCustomerAwalList extends React.Component {
  constructor(props) {
    super(props);
    this['pageName'] = "Approval Customer Awal";
    this['searchParameter'] = ["kode", "nama", "alamat", "telepon", "nama_bank", "nama_rekening", "no_rekening", "npwp", "nilai_top"];
    this['apiNotification'] = new notificationAPI();
    this['initialData'] = new OrganisasiModel();
    this['state'] = {
      pending: true,
      modal: {
        show: false,
        initialData: this['initialData'],
        isEdit: false,
      },
      footerDetail: {
        total_harga: "Total",
        qty: 0,
        diskon: 0,
        subtotal: 0,
        pajak: 0,
        total: 0,
      },
    };
    this['datatable'] = [{ id: "", no: "", name: "" }];
    this['columntable'] = [{ id: "", title: "" }];
    this['datatableDetail'] = [{ id: "", no: "", name: "" }];
    this['columntableDetail'] = [{ id: "", title: "" }];
  }
  async componentDidMount() {
    await this.LoadData();
  }
  async LoadData() {
    this.setState({ pending: true });
    await dispatcher['notification'].listApprovalCustomerAwal();
    let data = dispatcher['notification'].getListApprovalCustomerAwal();
    this['DataTableModel'] = new DataTableModel();
    data.map((item, i) => {
      let dataModel = new OrganisasiModel(item);
      this['DataTableModel'].add({
        id: i,
        no: i + 1,
        dataModel: dataModel,
        actions: (
          <>
            <ButtonActions
              label={"approveLink"}
              linkTo={"/notifications/approval-customer-awal/edit-form"}
              onClick={async () => dispatcher['notification'].setApprovalCustomerAwal(dataModel)}
            />
          </>
        ),
      });
      return item;
    });
    this['columntable'] = await this['DataTableModel'].getColumn();
    this['datatable'] = await this['DataTableModel'].getDatas();
    this.setState({ ...this['state'], pending: false });
  }
  // Begin: Handle Modal/Alert
  handleModal = {
    LoadDataDetail: async (detail) => {
      this['DataTableModel'] = new DataTableModel({ readOnly: true });
      detail['detail'].map((item, i) => {
        this['DataTableModel'].add({
          id: item['id'],
          no: i + 1,
          dataModel: item,
          actions: null,
        });
        return item;
      });
      const totalList = (val) => {
        if (val === 'qty') {
          return detail['detail'].reduce((total, value) => {
            return parseFloat(total + value['qty']);
          }, 0);
        } else if (val === 'diskon') {
          return detail['detail'].reduce((total, value) => {
            return parseFloat(total + value['diskon']);
          }, 0);
        } else if (val === 'subtotal') {
          return detail['detail'].reduce((total, value) => {
            return parseFloat(total + value['subtotal']);
          }, 0);
        } else if (val === 'pajak') {
          return detail['detail'].reduce((total, value) => {
            return parseFloat(total + value['pajak']);
          }, 0);
        } else if (val === 'total') {
          return detail['detail'].reduce((total, value) => {
            return parseFloat(total + value['total']);
          }, 0);
        }
      };
      this['columntableDetail'] = await this['DataTableModel'].getColumn();
      this['datatableDetail'] = await this['DataTableModel'].getDatas();
      this.setState({
        ...this['state'],
        footerDetail: {
          total_harga: "Total",
          qty: totalList("qty"),
          diskon: totalList("diskon") < 0 ? `Rp. -${separatorHarga(totalList("diskon").toString())}` : separatorHarga(totalList("diskon").toString(), "Rp. "),
          subtotal: totalList("subtotal") < 0 ? `Rp. -${separatorHarga(totalList("subtotal").toString())}` : separatorHarga(totalList("subtotal").toString(), "Rp. "),
          pajak: totalList("pajak") < 0 ? `Rp. -${separatorHarga(totalList("pajak").toString())}` : separatorHarga(totalList("pajak").toString(), "Rp. "),
          total: totalList("total") < 0 ? `Rp. -${separatorHarga(totalList("total").toString())}` : separatorHarga(totalList("total").toString(), "Rp. "),
        }
      });
    },
    show: async (detail) => {
      await this['handleModal'].LoadDataDetail(detail);
      this.setState({
        modal: {
          show: true,
          title: "Approval Pesanan Pembelian",
          initialData: new OrganisasiModel({ ...detail })
        }
      });
    },
    hide: () => {
      let state = { ...this['state'] };
      state = {
        modal: {
          show: false,
          initialData: this['initialData']
        }
      }
      this.setState({ ...state });
    },
  };
  // End: Handle Modal/Alert
  render() {
    const { pending } = this['state'];
    return (
      <>
        {/* Views Table */}
        <AnimatedCard>
          <CardHeader title={this['pageName']}></CardHeader>
          <CardBody>
            <ReactDatatable
              title={""}
              columns={this['columntable']}
              data={this['datatable']}
              searchParameter={this['searchParameter']}
              progressPending={pending}
            />
          </CardBody>
        </AnimatedCard>
        {/* ----------------------------------------------------------------------------- */}
      </>
    );
  }
}

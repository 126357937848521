import * as Yup from "yup";
import { MarginHargaJualAPI } from "../../api/margin-harga-jual";
import { ProdukAPI } from "../../api/produk";
import { ProdukKategoriAPI } from "../../api/produk-kategori";
import { ProdukSatuanAPI } from "../../api/produk-satuan";
import { SupplierAPI } from "../../api/supplier";
import { separatorHarga, hapusSeparator } from "../../services/separator-harga";

export default class ProdukModel {
  constructor(
    data = {
      id: 0,
      id_supplier: 0,
      id_perusahaan: 0,
      toSupplier: { nama: "" },
      id_produk_kategori: 0,
      toProdukKategori: { nama: "" },
      id_produk_satuan: 0,
      toProdukSatuan: { nama: "" },
      toPerusahaan: { singkat: "", kode_perusahaan: "" },
      nama: "",
      kode: "",
      harga_beli: "",
      harga_jual: "",
      link: "",
      barcode: "",
      persediaan_total: 0,
    }
  ) {
    this.id = data.id;
    this.id_supplier = data.id_supplier;
    this.id_perusahaan = data.id_perusahaan;
    this.nama_perusahaan = data.toPerusahaan.singkat;
    this.kode_perusahaan = data.toPerusahaan.kode_perusahaan;
    this.nama_supplier = data.toSupplier.nama;
    this.id_produk_kategori = data.id_produk_kategori;
    this.nama_produk_kategori = data.toProdukKategori.nama;
    this.id_produk_satuan = data.id_produk_satuan;
    this.nama_produk_satuan = data.toProdukSatuan.nama;
    this.nama = data.nama;
    this.kode = data.kode;
    this.harga_beli = separatorHarga(data.harga_beli.toString());
    this.harga_jual = separatorHarga(data.harga_jual.toString());
    this.link = data.link;
    this.barcode = data.barcode;
    this.persediaan_total = data.persediaan_total;
  }

  removeSeparator() {
    this.harga_jual = parseInt(hapusSeparator(this.harga_jual));
    this.harga_beli = parseInt(hapusSeparator(this.harga_beli));
  }

  hargaJualMargin = async () => {
    return await new MarginHargaJualAPI().getAll().then((res) => {
      let datas = res.data;
      let arr = datas.map((data) => {
        return data.margin
      })
      console.log(arr);
      const max = Math.max(...arr)
      return max
    }
    )
  }

  async getEditable(params = { pageName: "", isForm: false }) {
    let options_produk_kategori = [];
    let options_produk_satuan = [];
    let options_perusahaan = [];
    let options_supplier = [];
    if (params.isForm) {
      await new ProdukKategoriAPI()
        .getAll()
        .then((response) =>
          response.data.map(
            (data, i) =>
            (options_produk_kategori = [
              ...options_produk_kategori,
              { id: data.id, text: data.nama, value: data.id },
            ])
          )
        );

      await new SupplierAPI()
        .getAll()
        .then((response) =>
          response.data.map(
            (data) =>
            (options_supplier = [
              ...options_supplier,
              { id: data.id, text: data.nama, value: data.id },
            ])
          )
        );

      await new ProdukSatuanAPI()
        .getAll()
        .then((response) =>
          response.data.map(
            (data, i) =>
            // console.log(data)
            (options_produk_satuan = [
              ...options_produk_satuan,
              { id: data.id, text: data.nama, value: data.id },
            ])
          )
        );

      await new ProdukAPI()
        .getCoverage()
        .then((response) =>
          response.data.map(
            (data, i) =>
            // console.log('cek api',data)
            (options_perusahaan = [
              ...options_perusahaan,
              { id: data.id, text: data.text, value: data.id },
            ])
          )
        );
    }
    return [
      {
        validation: Yup.string().required("Supplier harus diisi"),
        dataField: "id_supplier",
        label: "Supplier",
        type: "select",
        onChange: (e) => {
          this.nama_supplier = e.target.value
          console.log('cek id_supplier', this.nama_supplier);
        },
        options: options_supplier,
        value: this.nama_supplier,
      },
      {
        validation: Yup.string().required("Cabang harus diisi"),
        dataField: "id_perusahaan",
        label: "Cabang",
        type: "select",
        onChange: (e) => {
          this.id_perusahaan = e.target.value
        },
        options: options_perusahaan,
        value: `${this.nama_perusahaan} - ${this.kode_perusahaan}`
      },
      {
        validation: Yup.string().required("Produk kategori harus diisi"),
        dataField: "id_produk_kategori",
        label: "Produk Kategori",
        type: "select",
        onChange: () => { },
        options: options_produk_kategori,
        value: this.nama_produk_kategori,
      },
      {
        validation: Yup.string().required("Produk satuan harus diisi"),
        dataField: "id_produk_satuan",
        label: "Produk Satuan",
        type: "select",
        onChange: (val) => { },
        options: options_produk_satuan,
        value: this.nama_produk_satuan,
      },
      {
        validation: Yup.string().required("Nama harus diisi"),
        dataField: "nama",
        label: "Nama " + params.pageName,
        type: "text",
        value: this.nama,
      },
      {
        validation: Yup.string().required("Kode harus diisi"),
        dataField: "kode",
        label: "Kode " + params.pageName,
        type: "text",
        value: this.kode,
      },
      {
        validation: Yup.string().required("Harga beli harus diisi"),
        dataField: "harga_beli",
        label: "Harga Beli " + params.pageName,
        type: "currency",
        value: this.harga_beli,
        onChange: (e, setFieldValue) => {
          this.harga_beli = e.target.value;
          this.hargaJualMargin().then((val) => {
            let hargaBeli = parseInt(hapusSeparator(this.harga_beli))
            let hargaMargin =
              separatorHarga(
                ((hargaBeli * parseInt(val)) / 100).toString()
              )
            let hargaJual = hargaBeli + parseInt(hapusSeparator(hargaMargin))
            this.harga_jual = separatorHarga((hargaJual).toString())
            console.table(hargaBeli, hargaMargin, hargaJual);
            setFieldValue("harga_jual", this.harga_jual)
          })
        }
      },
      {
        validation: Yup.string().required("Harga jual harus diisi"),
        dataField: "harga_jual",
        label: "Harga Jual",
        type: "disabled-text",
        value: this.harga_jual,
        onChange: (e) => {
        }
      },
      {
        validation: Yup.string(),
        dataField: "link",
        label: "Tokopedia Link",
        type: "text",
        value: this.link,
        invisibleOnTable: true,
      },
      {
        validation: Yup.string(),
        dataField: "barcode",
        label: "Barcode",
        type: "text",
        value: this.barcode,
        invisibleOnTable: true,
      },
    ];
  }
}

import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import FormDataCustomer from "../data-customer/form";
import ListDataCustomer from "../data-customer/list";
import React from "react";
import { dispatcher } from "../../../../redux/dispatcher";

export default function DataCustomer() {
  const history = useHistory();
  const active = dispatcher['kontak'].getDataCustomer();
  return (
    <Switch>
      <Route path={"/master-data-customer/list"}>
        <ListDataCustomer />
      </Route>
      <Route path={"/master-data-customer/add-form"}>
        <FormDataCustomer history={history} isEdit={false} />
      </Route>
      {active['id'] === 0 && (
        <Redirect to={"/master-data-customer/list"} />
      )}
      <Route path={"/master-data-customer/edit-form"}>
        <FormDataCustomer history={history} isEdit={true} />
      </Route>
      <Redirect to="/master-data-customer/list" />
    </Switch>
  );
}

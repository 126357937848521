import { separatorHarga } from "../../services/separator-harga";

export default class KartuHutangModel {
  constructor(
    data = {
      id: 0,
      kode: "",
      id_perusahaan: 0,
      tanggal_invoice: "",
      no_transaksi: "",
      nama_supplier: "",
      jatuh_tempo: "",
      total: "",
      terbayar: "",
      tersisa: "",
      sisa_jTempo: "",
    }
  ) {
    this.master = {
      id: data.id,
      id_perusahaan: data.id_perusahaan,
      no_invoice: data.kode,
      no_transaksi: data.no_transaksi,
      nama_supplier: data.nama_supplier,
      tanggal_invoice: data.tanggal_invoice,
      jatuh_tempo: data.jatuh_tempo,
      total: data.total,
      terbayar: data.terbayar,
      tersisa: data.tersisa,
      sisa_jTempo: data.sisa_jTempo,
    }
  }

  jatuhTempo() {
    let date = Math.floor(Math.abs(new Date() - new Date(this.master.jatuh_tempo)) / (1000 * 3600 * 24))
    this.master.sisa_jTempo = new Date(this.master.jatuh_tempo) < new Date().setHours(0, 0, 0, 0) ?
      `${date} hari yang lalu`
      : `${date} hari lagi`
  }

  async getEditable() {
    this.jatuhTempo()
    const { no_invoice, no_transaksi, nama_supplier, tanggal_invoice, jatuh_tempo, total, terbayar, tersisa, sisa_jTempo } = this.master;
    return [
      {
        dataField: "no_invoice",
        label: "No Invoice",
        value: no_invoice,
        type: 'disabled-text'
      },
      {
        dataField: "no_transaksi",
        label: "No Transaksi",
        value: no_transaksi,
      },
      {
        dataField: "tanggal_invoice",
        label: "Tanggal Invoice",
        value: tanggal_invoice,
      },
      {
        dataField: "nama_supplier",
        label: "Nama Supplier",
        value: nama_supplier,
      },
      {
        dataField: "jatuh_tempo",
        label: "Jatuh Tempo",
        value: jatuh_tempo,
      },
      {
        dataField: "total",
        label: "Total",
        value: separatorHarga(total.toString(), "Rp. "),
      },
      {
        dataField: "terbayar",
        label: "Terbayar",
        value: separatorHarga(terbayar.toString(), "Rp. "),
      },
      {
        dataField: "tersisa",
        label: "Tersisa",
        value: separatorHarga(tersisa.toString(), "Rp. "),
      },
      {
        dataField: "sisa_jTempo",
        label: "Sisa Jatuh Tempo",
        value: sisa_jTempo,
      },
    ];
  }
}

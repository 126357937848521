import moment from "moment";
import { getIdPerusahaan, getCoverageArea, simpleFetch, simpleFetchGetData } from "./api";
export class akuntansiAPI
{
  //** Start: Jurnal Umum */
  //** Get List */
  async getAllListJurnalUmum ()
  {
    let get = `&id_perusahaan=${ getCoverageArea() }`;
    let res = await simpleFetchGetData( {
      url: "retail/akuntansi/jurnal_umum?tipe=record-jurnal-entry" + get,
      method: "GET",
    } );
    return res;
  }
  //** Save */
  async setJurnalUmum ( values )
  {
    let res = await simpleFetch( {
      url: "retail/akuntansi/jurnal_umum",
      method: "POST",
      body: {
        ...values,
      },
    } );
    return res;
  }
  //** Record/Approve */
  async recordJurnalUmum ( id )
  {
    let res = await simpleFetch( {
      url: "retail/akuntansi/jurnal_umum/record",
      method: "POST",
      body: {
        id: id,
      },
    } );
    return res;
  }
  //** Delete */
  async deleteJurnalUmum ( id )
  {
    let res = await simpleFetch( {
      url: "retail/akuntansi/jurnal_umum",
      method: "DELETE",
      body: {
        id: id,
      },
    } );
    return res;
  }
  //** Edit */
  //** End: Jurnal Umum */
  //* ------------------------------------------------------------------------------------------- */
  //** Start: Buku Besar */
  //** Get List */
  async getAllBukuBesar ( form )
  {
    const { start_date, end_date, id_region, id_perusahaan, id_akun, kode_akun, id_bank, ceklist_nilai, ceklist_clearing } = form;
    let get = `&start_date=${ moment( start_date ).format( 'YYYY-MM-DD' ) }&end_date=${ moment( end_date ).format( 'YYYY-MM-DD' ) }&id_region=${ id_region }&id_perusahaan=${ id_perusahaan }&id_akun=${ id_akun }&kode_akun=${ kode_akun }&id_bank=${ id_bank }&ceklist_nilai=${ ceklist_nilai }&ceklist_clearing=${ ceklist_clearing }`;
    let res = await simpleFetchGetData( {
      url: "retail/akuntansi/buku_besar?tipe=getList" + get,
      method: "GET",
    } );
    return res;
  }
  //** Save */
  async setBukuBesarClearing ( values )
  {
    let res = await simpleFetch( {
      url: "retail/akuntansi/buku_besar",
      method: "POST",
      body: {
        ...values,
      },
    } );
    return res;
  }
  //** Edit */
  //** End: Buku Besar */
  //* ------------------------------------------------------------------------------------------- */
  //** Start: Jurnal Balik */
  //** Get List */
  async getAllJurnalBalik ( form )
  {
    const { no_transaksi } = form;
    let get = `&no_transaksi=${ no_transaksi }`;
    let res = await simpleFetchGetData( {
      url: "retail/akuntansi/jurnal_balik?tipe=jurnal-balik" + get,
      method: "GET",
    } );
    return res;
  }
  // Get List History
  async getAllHistoryJurnalBalik ()
  {
    let res = await simpleFetchGetData( {
      url: "retail/akuntansi/jurnal_balik?tipe=history-jurnal-balik",
      method: "GET",
    } );
    return res;
  }
  //** Save */
  async setJurnalBalik ( values )
  {
    let res = await simpleFetch( {
      url: "retail/akuntansi/jurnal_balik",
      method: "POST",
      body: {
        ...values,
      },
    } );
    return res;
  }
  //** Edit */
  //** Upprove */
  //** End: Jurnal Balik */
  //* ------------------------------------------------------------------------------------------- */
  //** Start: Cek Transaksi */
  //** Get List */
  //** Save */
  //** Edit */
  //** End: Cek Transaksi */
  //* ------------------------------------------------------------------------------------------- */
  //** Start: Cek Selisih */
  //** Get List */
  async getAllCekSelisih ( form )
  {
    const { start_date, end_date, id_perusahaan } = form;
    let cabang = ( id_perusahaan === "" ) ? getCoverageArea() : id_perusahaan;
    let get = `&start_date=${ moment( start_date ).format( 'YYYY-MM-DD' ) }&end_date=${ moment( end_date ).format( 'YYYY-MM-DD' ) }&id_perusahaan=${ cabang }`;
    let res = await simpleFetchGetData( {
      url: "retail/akuntansi/control?tipe=cek-selisih" + get,
      method: "GET",
    } );
    return res;
  }
  //** End: Cek Selisih */
  //* ------------------------------------------------------------------------------------------- */
  //** Start: Cek saldo Akun */
  //** Get List */
  async getAllCekSaldo ( form )
  {
    const { start_date, end_date, id_perusahaan, ceklist_nilai } = form;
    let cabang = ( id_perusahaan === "" ) ? getCoverageArea() : id_perusahaan;
    let get = `&start_date=${ moment( start_date ).format( 'YYYY-MM-DD' ) }&end_date=${ moment( end_date ).format( 'YYYY-MM-DD' ) }&id_perusahaan=${ cabang }&ceklist_nilai=${ ceklist_nilai }`;
    let res = await simpleFetchGetData( {
      url: "retail/akuntansi/control?tipe=cek-saldo" + get,
      method: "GET",
    } );
    return res;
  }
  //** End: Cek saldo Akun */
  //* ------------------------------------------------------------------------------------------- */
  //** Start: Laba Rugi */
  //** Get List */
  async getAllLabaRugi ( form )
  {
    const { start_date, end_date, id_region, id_perusahaan, id_user, ceklist_nilai, empty_table } = form;
    // let cabang = (id_perusahaan === "") ? getCoverageArea() : id_perusahaan;
    let get = `&start_date=${ moment( start_date ).format( 'YYYY-MM-DD' ) }&end_date=${ moment( end_date ).format( 'YYYY-MM-DD' ) }&id_region=${ id_region }&id_perusahaan=${ id_perusahaan }&id_user=${ id_user }&ceklist_nilai=${ ceklist_nilai }&empty_table=${ empty_table }`;
    let res = await simpleFetchGetData( {
      url: "retail/akuntansi/control?tipe=laba-rugi" + get,
      method: "GET",
    } );
    return res;
  }
  //** End: Laba Rugi */
  //* ------------------------------------------------------------------------------------------- */
  //** Start: Neraca Saldo */
  //** Get List */
  async getAllNeracaSAldo ( form )
  {
    const { month_year, id_region, id_perusahaan, id_user, ceklist_nilai, empty_table } = form;
    // let cabang = (id_perusahaan === "") ? getCoverageArea() : id_perusahaan;
    let get = `&month_year=${ month_year }&id_region=${ id_region }&id_perusahaan=${ id_perusahaan }&id_user=${ id_user }&ceklist_nilai=${ ceklist_nilai }&empty_table=${ empty_table }`;
    let res = await simpleFetchGetData( {
      url: "retail/akuntansi/control?tipe=neraca-saldo" + get,
      method: "GET",
    } );
    return res;
  }
  //** End: Neraca Saldo */
  //* ------------------------------------------------------------------------------------------- */
  //** Start: Clearing */
  //** Get List */
  async getAllClearing ( form )
  {
    const { start_date, end_date, id_perusahaan, kode_akun } = form;
    let get = `&start_date=${ moment( start_date ).format( 'YYYY-MM-DD' ) }&end_date=${ moment( end_date ).format( 'YYYY-MM-DD' ) }&id_perusahaan=${ id_perusahaan }&kode_akun=${ kode_akun }`;
    let res = await simpleFetchGetData( {
      url: "retail/akuntansi/clearing?" + get,
      method: "GET",
    } );
    return res;
  }
  //** Save */
  async setClearing ( values )
  {
    let res = await simpleFetch( {
      url: "retail/akuntansi/clearing",
      method: "POST",
      body: {
        ...values,
      },
    } );
    return res;
  }
  //** Edit */
  //** End: Clearing */
  //* ------------------------------------------------------------------------------------------- */
  //** Start: Update Transaksi */
  //** Get List */
  async getAllUpdateTransaksi ( form )
  {
    const { no_transaksi } = form;
    let get = `&no_transaksi=${ no_transaksi }`;
    let res = await simpleFetchGetData( {
      url: "retail/akuntansi/control?tipe=update-transaksi" + get,
      method: "GET",
    } );
    return res;
  }
  //** Save */
  async setUpdateTransaksi ( values )
  {
    let res = await simpleFetch( {
      url: "retail/akuntansi/update_transaksi",
      method: "POST",
      body: {
        ...values,
      },
    } );
    return res;
  }
  //** Edit */
  //** End: Update Transaksi */
  //* ------------------------------------------------------------------------------------------- */
  //** Start: Cek Transaksi */
  //** Get List */
  async getAllCekTransaksi ( form )
  {
    const { jenis_pencarian, no_transaksi } = form;
    let jenisPencarian;
    if ( no_transaksi !== "" )
    {
      jenisPencarian = jenis_pencarian;
    } else
    {
      jenisPencarian = "";
    }
    let get = `&jenis_pencarian=${ jenisPencarian }&no_transaksi=${ no_transaksi }`;
    let res = await simpleFetchGetData( {
      url: "retail/akuntansi/control?tipe=cek-transaksi" + get,
      method: "GET",
    } );
    return res;
  }
  //** Save */
  //** Edit */
  //** End: Cek Transaksi */
  //* ------------------------------------------------------------------------------------------- */
  //** Start: Saldo Awal Akun */
  //** Get List */
  async getAllSaldoAwalAkun ( form )
  {
    const { id_perusahaan } = form;
    let get = `&id_perusahaan=${ id_perusahaan }`;
    let res = await simpleFetchGetData( {
      url: "retail/akuntansi/saldo_awal?tipe=saldo-awal-akun" + get,
      method: "GET",
    } );
    return res;
  }
  //** Set/Edit */
  async editSaldoAwalAkun ( values )
  {
    let res = await simpleFetch( {
      url: "retail/akuntansi/saldo_awal",
      method: "PUT",
      body: {
        tipe: "saldo-awal-akun",
        ...values,
      },
    } );
    return res;
  }
  //** End: Saldo Awal Akun */
  //* ------------------------------------------------------------------------------------------- */
  //** Start: Saldo Awal Bank */
  //** Get List */
  async getAllSaldoAwalBank ( form )
  {
    const { id_perusahaan } = form;
    let get = `&id_perusahaan=${ id_perusahaan }`;
    let res = await simpleFetchGetData( {
      url: "retail/akuntansi/saldo_awal?tipe=saldo-awal-bank" + get,
      method: "GET",
    } );
    return res;
  }
  //** Set/Edit */
  async editSaldoAwalBank ( values )
  {
    let res = await simpleFetch( {
      url: "retail/akuntansi/saldo_awal",
      method: "PUT",
      body: {
        tipe: "saldo-awal-bank",
        ...values,
      },
    } );
    return res;
  }
  //** End: Saldo Awal Bank */
  //* ------------------------------------------------------------------------------------------- */
  //** Start: Get Select Option */
  // Perusahaan
  async getPerusahaan ( id_region )
  {
    let id_brand = ( id_region === "" ) ? "8,16" : id_region;
    let get = `id_brand=${ id_brand }`;
    let res = await simpleFetch( {
      url: "retail/select/perusahaan?" + get,
      method: "GET",
    } );
    return res;
  }
  // Coverage Cabang
  async getCoverage ()
  {
    let res = await simpleFetch( {
      url: "retail/select/perusahaan?id_coverage=" + getCoverageArea(),
      method: "GET",
    } );
    return res;
  }
  // Akun Buku Besar
  async getakun ()
  {
    let get = `&id_perusahaan=${ getIdPerusahaan() }`;
    let res = await simpleFetch( {
      url: "retail/select/akun_akses?alias=buku-besar" + get,
      method: "GET",
    } );
    return res;
  }
  async getakunJurnalUmum ()
  {
    let get = `&id_perusahaan=${ getIdPerusahaan() }`;
    let res = await simpleFetch( {
      url: "retail/select/akun_akses?alias=jurnal-umum" + get,
      method: "GET",
    } );
    return res;
  }
  // Bank Buku Besar
  async getBank ( form )
  {
    let get = `&id_brand=${ form[ 'id_region' ] }&id_perusahaan=${ form[ 'id_perusahaan' ] }&kode_akun=${ form[ 'kode_akun' ] }`;
    let res = await simpleFetch( {
      url: "retail/select/bank?alias=buku-besar" + get,
      method: "GET",
    } );
    return res;
  }
  // Bank Jurnal Umum
  async getBankJurnalUmum ()
  {
    let get = `&id_perusahaan=${ getCoverageArea() }`;
    let res = await simpleFetch( {
      url: "retail/select/bank?alias=jurnal-umum" + get,
      method: "GET",
    } );
    return res;
  }
  //** End: Get Select Option */
  //* ------------------------------------------------------------------------------------------- */
}

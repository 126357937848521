/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import {
  CardBody,
} from "../../../../../_metronic/_partials/controls";
import { ReactDatatable } from "../../../../components/react-datatable/reactDatatable";
import { Button } from "react-bootstrap";
import store from "../../../../redux/store";
import { LaporanGrafik } from "../../../../api/laporan-grafik";
import Select2 from "react-select";
import moment from "moment";
import { StokAPI } from "../../../../api/stok";
import { excelAllStok } from "./excelAllStok";

function AllStok() {
  const columns = [
    {
      name: "Tanggal",
      selector: (row) => row.tanggal,
      sortable: true,
      minWidth: "10%",
    },
    {
      name: "Nama Barang",
      selector: (row) => row.nama,
      sortable: true,
      minWidth: "25%",
    },
    {
      name: "Kode Barang",
      selector: (row) => row.kode,
      sortable: true,
      minWidth: "20%",
    },
    {
      name: "No Transaksi",
      selector: (row) => row.no_transaksi,
      sortable: true,
      minWidth: "20%",
    },
    {
      name: <div style={{ textAlign: "center" }}>Qty</div>,
      selector: (row) => row.qty,
      sortable: true,
      minWidth: "10%",
      // width: "fit-content",
    },
    {
      name: "Satuan",
      selector: (row) => row.satuan,
      sortable: true,
      minWidth: "10%",
    },
    {
      name: <div style={{ textAlign: "center" }}>Status</div>,
      selector: (row) => row.status,
      sortable: true,
      minWidth: "15%",
      // width: "fit-content",
    },
  ];

  const ksa = [20, 46, 52, 69];
  const kpp = [68, 28, 70, 71];
  const ksb = [108, 105, 110, 109];

  const [pt] = useState([{
    value: ksa,
    label: "KSA"
  }, {
    value: kpp,
    label: "KPP"
  }, {
    value: ksb,
    label: "KSB"
  }])

  const [selectedPT, setSelectedPT] = useState(ksa.includes(store.getState().auth.id_perusahaan) ? ksa : kpp.includes(store.getState().auth.id_perusahaan) ? kpp : ksb);

  const [startDate, setStartDate] = useState(
    moment(new Date())
      // .startOf("month")
      .format("YYYY-MM-DD")
  );

  const [cabang, setCabang] = useState([0]);
  const title = `Laporan Detail Stok ${startDate}`;
  const [opsi, setOptions] = useState([]);
  let opsiCabang = [];

  const coverage = async () => {
    await new LaporanGrafik().getCoverage().then((res) => {
      let filter = res.data.filter((data) => selectedPT.includes(data.id));
      opsiCabang = filter.map((data) => ({
        label: data.text,
        text: data.text,
        value: data.id,
      }));
    });
    setOptions(opsiCabang);
  };

  const [data, setData] = useState([]);

  const fetchData = async (data) => {
    try {
      await new StokAPI().laporanInventori(data).then((res) => {
        setData(res.data);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (cabang.includes(0)) {
      fetchData({ selectedPT, startDate });
    } else {
      fetchData({ cabang, startDate });
    }
    coverage();
  }, [selectedPT]);
  return (
    <>
      <AnimatedCard>
        <CardBody>
          <div className="d-flex justify-content-start form-group row">
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <label> Tanggal </label>
              <input
                name={"tanggal_awal"}
                type={"date"}
                value={startDate}
                className={"form-control"}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <label> Pilih PT </label>
              <Select2
                name={"PT"}
                size="sm"
                options={pt}
                isClearable={false}
                value={
                  pt ? pt.find((option) => option.value === selectedPT) : ""
                }
                onChange={(e) => {
                  setSelectedPT(e ? e.value : []);
                  setCabang([0]);
                }}
              />
            </div>
            <div
              className="col-lg-3"
              style={{ marginTop: "20px", zIndex: "4" }}
            >
              <label> Pilih Perusahaan </label>
              <Select2
                name={"Cabang"}
                placeholder={opsi.text}
                size="sm"
                options={opsi}
                isClearable={true}
                value={
                  opsi ? opsi.find((option) => option.value === cabang) : ""
                }
                onChange={(e) => {
                  setCabang(e ? [e.value] : [0]);
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-start">
            <div style={{ marginRight: "2%" }}>
              <Button
                variant="info"
                onClick={(e) => {
                  e.preventDefault();
                  if (cabang.includes(0)) {
                    fetchData({ selectedPT, startDate });
                    // setIdPerusahaan(selectedPT)
                  } else {
                    fetchData({ cabang, startDate });
                    // setIdPerusahaan(cabang)
                  }
                }}
              >
                <i className="fas fa-search"></i>
                Cari Data
              </Button>
            </div>
            <div>
              <Button onClick={(e) => excelAllStok(data, title)}>
                <i className={"far fa-file-excel"}></i> {" Export .xls"}
              </Button>
            </div>
            {/* <div>{actionsMemo}</div> */}
          </div>
          <ReactDatatable
            columns={columns}
            data={data}
            dense
            pagination
            searchParameter={["nama", "kode", "no_transaksi"]}
          // actions={actionsMemo}
          />
        </CardBody>
      </AnimatedCard>
    </>
  );
}

export default AllStok
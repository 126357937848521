import { separatorHarga } from "../../../../services/separator-harga";

export default class SaldoAwalBankModel {
  constructor(
    data = {
      id: 0,
      nama_bank: "",
      no_rekening: "",
      jenis_bank: "",
      cabang: "",
      saldo_awal: "",
    }
  ) {
    const { id, nama_bank, no_rekening, jenis_bank, cabang, saldo_awal } = data;
    this['id'] = id;
    this['nama_bank'] = nama_bank;
    this['no_rekening'] = no_rekening;
    this['jenis_bank'] = jenis_bank;
    this['cabang'] = cabang;
    this['saldo_awal'] = saldo_awal;
  }

  async getdata() {
    const { nama_bank, no_rekening, jenis_bank, cabang, saldo_awal } = this;
    return [
      {
        id: "nama_bank",
        title: "Nama Bank",
        label: nama_bank,
        sortable: true,
        minWidth: "23%",
      },
      {
        id: "no_rekening",
        title: "No. Rekening",
        label: no_rekening,
        // center: true,
        sortable: true,
        minWidth: "10%",
      },
      {
        id: "jenis_bank",
        title: "Jenis Bank",
        label: jenis_bank,
        center: true,
        sortable: true,
        minWidth: "12%",
      },
      {
        id: "cabang",
        title: "Cabang",
        label: cabang,
        center: true,
        sortable: true,
        minWidth: "15%",
      },
      {
        id: "saldo_awal",
        title: "Saldo Awal",
        label: separatorHarga(saldo_awal.toString(), "Rp. "),
        right: true,
        minWidth: "10%",
      }
    ];
  }
}

import moment from "moment";
import { separatorHarga } from "../../../services/separator-harga";
export default class clearingDetailModel {
  constructor(
    data = {
      id: 0,
      no_transaksi: "",
      cabang: "",
      tanggal: "",
      deskripsi: "",
      debet: 0,
      kredit: 0,
    }
  ) {
    const { id, no_transaksi, cabang, tanggal, deskripsi, debet, kredit } = data;
    this['id'] = id;
    this['no_transaksi'] = no_transaksi;
    this['cabang'] = cabang;
    this['tanggal'] = tanggal;
    this['deskripsi'] = deskripsi;
    this['debet'] = debet;
    this['kredit'] = kredit;
  }
  async getdata() {
    const { tanggal, no_transaksi, deskripsi, cabang, debet, kredit } = this;
    return [{
      id: "no_transaksi",
      title: "No. Transaksi Reff",
      label: no_transaksi,
      center: true,
      sortable: true,
      minWidth: "26%",
    }, {
      id: "cabang",
      title: "Cabang",
      label: cabang,
      center: true,
      sortable: true,
      minWidth: "20%",
    }, {
      id: "tanggal",
      title: "Tanggal",
      label: moment(tanggal).format("DD-MM-YYYY"),
      center: true,
      sortable: true,
      minWidth: "12%",
    }, {
      id: "deskripsi",
      title: "Keterangan",
      label: deskripsi,
      sortable: true,
      minWidth: "60%",
    }, {
      id: "debet",
      title: "Debet",
      label: debet < 0 ? `Rp. -${separatorHarga(debet.toString())}` : separatorHarga(debet.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "kredit",
      title: "Kredit",
      label: kredit < 0 ? `Rp. -${separatorHarga(kredit.toString())}` : separatorHarga(kredit.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    },
    ];
  }
}

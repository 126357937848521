import React from "react";
import {
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    CardFooter
} from "../../../../_metronic/_partials/controls";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TableWithoutPagination } from "../../../components/table/Table";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import { Typography } from "@material-ui/core";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import StokRequestDetailModel from "../../../models/stok-req-model/StokRequestDetailModel";
import { StokTransferAPI } from "../../../api/stok-transfer";

export default class DetailTransferRequest extends React.Component {
    constructor(props) {
        super(props);
        this.initialData = props.dataFromModel;
        this.initialDetailData = new StokRequestDetailModel();
        this.api = new StokTransferAPI();
        this.simpleTableModel = new SimpleTableModel({ readOnly: true });
        this.pageName = "Detail Request Transfer Stok";
        this.isEdit = props.isEdit;
        this.state = {
            modal: {
                show: false,
                initialDetailData: this.initialDetailData,
                isEdit: true,
            },
            alert: {
                activeId: null,
                show: false,
            },
        };
        this.columntable = [{ dataField: "", text: "" }];
        this.datatable = [{ id: 0, nama: "" }];
        this.fields = [];
        this.validation = Yup.object().shape({});
        this.detailFields = [];
        this.detailValidation = Yup.object().shape({});
    }

    async componentDidMount() {
        await this.loadFields();
        await this.loadDetail();
        let yup = {};
        await this.initialData.getEditable().then((values) => {
            values.map((item) => {
                yup[item.dataField] = item.validation;
            });
        });
        this.validation = Yup.object().shape(yup);

        yup = {};
        await this.state.modal.initialDetailData.getEditable().then((values) => {
            values.map((item) => {
                yup[item.dataField] = item.validation;
            });
        });
        this.detailValidation = Yup.object().shape(yup);
        this.setState({ ...this.state });
    }

    async loadFields() {
        this.fields = [];
        this.yup = {};
        await this.initialData
            .getEditable({ pageName: this.pageName, isForm: false })
            .then((values) => {
                values.map((item, i) => {
                    this.yup[item.dataField] = item.validation;
                    return (this.fields = [...this.fields, item]);
                });
            });
        this.setState({ ...this.state });
    }

    async loadDetail() {
        let datas = this.initialData.detail;
        this.simpleTableModel = new SimpleTableModel({ readOnly: true });
        datas.map((item, i) => {
            if (item.status != 0)
                this.simpleTableModel.add({
                    id: i,
                    no: i + 1,
                    dataModel: item,
                    actions: null,
                });
        });

        this.datatable = await this.simpleTableModel.getDatatables();
        this.columntable = await this.simpleTableModel.getColumnHeaders();
        this.setState({ ...this.state });
    }

    handleModal = {
        loadDetailFields: async () => {
            this.detailFields = [];
            await this.state.modal.initialDetailData
                .getEditable({ pageName: this.pageName, isForm: true })
                .then((values) => {
                    values.map((item, i) => {
                        return (this.detailFields = [...this.detailFields, { ...item }]);
                    });
                });
        },
        tambah: async () => {
            let id = 0;
            this.state.modal.initialDetailData = new StokRequestDetailModel();
            let modal = { ...this.state.modal };
            modal.show = true;
            modal.title = `Item Baru`;
            modal.isEdit = false;
            await this.handleModal.loadDetailFields();
            this.setState({ modal });
        },
        edit: async (dataFromModel) => {
            let state = { ...this.state };
            state.modal.initialDetailData = dataFromModel;
            state.modal.show = true;
            state.modal.title = `Edit Item`;
            state.modal.isEdit = true;
            await this.handleModal.loadDetailFields();
            this.setState({ ...state });
        },
        hide: () => {
            let modal = { ...this.state.modal };
            modal.show = false;
            this.setState({ modal });
        },
    };

    handleAlert = {
        show: (id) => {
            let alert = { ...this.state.alert };
            alert.show = true;
            alert.activeId = id;
            this.setState({ alert: alert });
        },
        hide: () => {
            let alert = { ...this.state.alert };
            alert.show = false;
            alert.activeId = null;
            this.setState({ alert: alert });
        },
        confirmed: async () => {
            await this.initialData.handleDetail.del(this.state.alert.activeId);
            this.handleAlert.hide();
            this.loadDetail();
        },
    };

    render() {
        return (
            <>
                <Formik
                    enableReinitialize={true}
                    initialValues={this.initialData}
                    validator={this.validation}
                    onSubmit={async (values, { resetForm }) => {
                        await this.api.approve(values.master)
                    }}
                >
                    {({ handleSubmit, setFieldValue, resetForm }) => (
                        <AnimatedCard>
                            <CardHeader title={this.pageName}>
                                <CardHeaderToolbar>
                                    <button
                                        type="button"
                                        className="btn btn-secondary mx-1"
                                        onClick={() => this.props.history.goBack()}
                                    >
                                        <i className="fa fa-arrow-left"></i>
                                        Kembali
                                    </button>
                                </CardHeaderToolbar>
                            </CardHeader>
                            <CardBody>
                                <Form className="form form-label-right">
                                    <div className="row">
                                        {this.fields.map((field, i) => (
                                            <div key={i} className="form-group col-lg-6">
                                                <label>{field.label}</label>
                                                <input type="text" className="form-control" disabled value={field.value}></input>
                                            </div>
                                        ))}
                                    </div>
                                    <span className="d-flex align-items-center justify-content-between mt-4">
                                        <Typography variant="h6">Detail Item</Typography>
                                    </span>
                                    <TableWithoutPagination
                                        data={this.datatable}
                                        columns={this.columntable}
                                    />
                                </Form>
                            </CardBody>
                            <CardFooter>
                            <div className="d-flex justify-content-end">
                                <button
                                    type="button"
                                    onClick={() => {
                                        handleSubmit()
                                    }}
                                    className="btn btn-primary btn-elevate"
                                >
                                    Approve & Simpan
                                </button>
                                </div>
                            </CardFooter>
                        </AnimatedCard>
                    )}
                </Formik>
            </>
        );
    }
}

import { Form, Formik } from "formik";
import React, { Component } from "react";
import { CardBody, CardHeader, CardHeaderToolbar } from "../../../../../../_metronic/_partials/controls";
import { AnimatedCard } from "../../../../../components/animated-card/animatedCard";
import { dispatcher } from "../../../../../redux/dispatcher";
import { Typography } from "@material-ui/core";
import { FormsFields } from "../../../../../components/forms-fields/FormsFields";
import DataTableModel from "../../../../../models/datatable-model/DataTableModel";
import { ReactDatatableDetails } from "../../../../../components/react-datatable/reactDatatable";
import { export_excel } from "../../../../../components/export-excel/export_excel";
import ButtonLoad from "../../../../../components/button-loading/buttonLoad";
import moment from "moment";

export default class DetailPengeluaranBankCollection extends Component
{
  constructor( props )
  {
    super( props );
    this[ 'pageName' ] = "Detail Pengeluaran Bank Collection";
    this[ 'initialData' ] = dispatcher[ 'kasir_bank_collection' ].getPengeluaranBankCollection();
    this[ 'columntable' ] = [ { dataField: "", text: "" } ];
    this[ 'datatable' ] = [ { id: 0, nama: "" } ];
    this['export_excel'] = new export_excel();
  }
  //* ----------------------------------------------------------------------------- */
  //** Start: Component */
  componentDidMount ()
  {
    this.LoadDetail();
  }
  //** End: Component */
  //* ----------------------------------------------------------------------------- */
  //** Start: Load Data List Details */
  async LoadDetail ()
  {
    let data = this[ 'initialData' ][ 'detail' ];
    this[ 'DataTableModel' ] = new DataTableModel( { readOnly: true } );
    data.map( ( item, i ) =>
    {
      this[ 'DataTableModel' ].add( {
        id: i,
        no: i + 1,
        dataModel: item,
        actions: null,
      } );
      return item;
    } );
    this[ 'columntable' ] = await this[ 'DataTableModel' ].getColumn();
    this[ 'datatable' ] = await this[ 'DataTableModel' ].getDatas();
    this.setState( { ...this[ 'state' ] } );
  }
  //** End: Load Detail Table */
  //* ----------------------------------------------------------------------------- */
  render ()
  {
    return (
      <>
        {/* Start: Formik Form Details */ }
        <Formik
          enableReinitialize={ true }
          initialValues={ this[ 'initialData' ][ 'transaksi' ] }
          validationSchema={ false }
        >
          { ( { values } ) => (
            <AnimatedCard>
              <CardHeader title={ this[ 'pageName' ] }>
                <CardHeaderToolbar>
                  {/* Button Kembali */ }
                  <button
                    type={ "button" }
                    className={ "btn btn-outline-secondary mx-1 btn-sm" }
                    onClick={ () => this[ 'props' ][ 'history' ].goBack() }
                  ><i className={ "fa fa-arrow-left" }></i> Kembali
                  </button>
                  {/* Button Cetak Bukti Pengeluaran */ }
                  <button
                    type={ "button" }
                    className={ "btn btn-outline-success mx-1 btn-sm" }
                    onClick={ async () =>
                    {
                      dispatcher[ 'kasir_bank_collection' ].cetakBuktiPengeluaran( values[ 'no_transaksi' ] );
                    } }
                  ><i className={ "fa fa-envelope-open-text" }></i> Bukti Pengeluaran
                  </button>
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <Form className={ "form form-label-right" }>
                  <div className={ "form-group row" }>
                    <div className={ "col-lg-6" }>
                      <div className={ "form-group row" }>
                        {/* Jenis Pengeluaran */ }
                        <FormsFields
                          id={ 'akun' }
                          type={ "disabled-text" }
                          label={ "Jenis Pengeluaran" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          value={ values[ 'akun' ] }
                        />
                        {/* No. Transaksi */ }
                        <FormsFields
                          id={ 'no_transaksi' }
                          type={ "disabled-text" }
                          label={ "No. Transaksi" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          value={ values[ 'no_transaksi' ] }
                        />
                        {/* No. Bukti BKU */ }
                        <FormsFields
                          id={ 'no_transaksi_bku' }
                          type={ "disabled-text" }
                          label={ "No. Bukti BKU" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          value={ values[ 'no_transaksi_bku' ] }
                        />
                        {/* Tanggal Approve */ }
                        <FormsFields
                          id={ 'tanggal_approved' }
                          type={ "disabled-DatePicker" }
                          label={ "Tanggal Approve" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          value={ values[ 'tanggal_approved' ] }
                        />
                        {/* Nama Bank */ }
                        <FormsFields
                          id={ 'nama_bank' }
                          type={ "disabled-text" }
                          label={ "Bank" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          value={ values[ 'nama_bank' ] }
                        />
                        {/* No. Rekening */ }
                        <FormsFields
                          id={ 'no_rekening' }
                          type={ "disabled-text" }
                          label={ "No. Rekening" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          value={ values[ 'no_rekening' ] }
                        />
                      </div>
                    </div>
                    <div className={ "col-lg-6" }>
                      <div className={ "form-group row" }>
                        {/* Tanggal Transaksi */ }
                        <FormsFields
                          id={ 'tanggal' }
                          type={ "disabled-DatePicker" }
                          label={ "Tanggal Transaksi" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          value={ values[ 'tanggal' ] }
                        />
                        {/* Kepada */ }
                        <FormsFields
                          id={ 'kontak_ref' }
                          type={ "disabled-text" }
                          label={ "Kepada" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          value={ values[ 'kontak_ref' ] }
                        />
                        {/* Keterangan */ }
                        <FormsFields
                          id={ 'deskripsi' }
                          type={ "disabled-textarea" }
                          label={ "Keterangan" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          rows={ 3 }
                          value={ values[ 'deskripsi' ] }
                        />
                        {/* Total */ }
                        <FormsFields
                          id={ 'total' }
                          type={ "disabled-currency" }
                          label={ "Total" }
                          className={ "col-lg-12" }
                          style={ { marginTop: "20px" } }
                          value={ values[ 'total' ] }
                        />
                      </div>
                    </div>
                  </div>
                </Form>
                {/* Detail Dana */ }
                <>
                <div className="d-flex justify-content-start">
                    <div>
                      <span className={"d-flex align-items-center justify-content-between mt-4 mb-4"}>
                        <Typography variant={"h6"}>Detail</Typography>
                      </span>
                    </div>
                    <div className="ml-4">
                    <ButtonLoad
                      label={"Export .xls"}
                      pending={false}
                      classNameIcon={"far fa-file-excel"}
                      className={"btn btn-outline-primary"}
                      disabled={this['datatable'].length === 0}
                      onClick={async () => {
                        let startDate = moment(values['begin_date']).format("L");
                        let endDate = moment(values['end_date']).format("L");
                        let times = moment().format('h.mm.ss a');
                        const dateFilter = (startDate === endDate) ? startDate : startDate + " - " + endDate;

                        let record = [];
                        this['datatable'].map((val, i) => {
                          console.log(val, this['initialData']);
                          // const { no_transaksi, no_transaksi_bku, tanggal_approved, tanggal, cabang, kontak_ref, akun, total, deskripsi } = val['transaksi'];
                          record.push({
                            kode_akun: val.kode_akun,
                            nama_akun: val.nama_akun,
                            keterangan: val.deskripsi,
                            jumlah: val.jumlah,
                          });
                          return val;
                        });

                        let datas = {
                          headerTitle: `PENGELUARAN BANK COLLECTION ${this['initialData']['transaksi']['no_transaksi']}`,
                          title1: "",
                          title2: "",
                          headerField: ["KODE AKUN", "NAMA AKUN", "KETERANGAN", "JUMLAH"],
                          headerField2: [],
                          recordData: record,
                          recordData2: [],
                          totalSaldoAwal: false,
                          totalSaldoAkhir: false,
                          footerSubTotal: [],
                          footerSubTotal1: [],
                          columnWidth: [35, 17, 85, 23],
                          centerAlign: ["A", "B", "C", "D"],
                          wrapText: [],
                          numberFormatRp: ["D"],
                          fileName: `Kasir Detail Pengeluaran Bank Collection (${dateFilter} - ${times})`
                        }
                        this['export_excel'].saveAsExcel(datas);
                      }}
                    />
                    </div>
                  </div>
                  <ReactDatatableDetails
                    title={ "" }
                    data={ this[ 'datatable' ] }
                    columns={ this[ 'columntable' ] }
                  />
                </>
              </CardBody>
            </AnimatedCard>
          ) }
        </Formik>
        {/* End: Formik Form Details */ }
      </>
    );
  }
}

import { separatorHarga } from "../../../services/separator-harga";

export default class PesananPembelianDetailModel {
  constructor(
    data = {
      id: 0,
      nama_produk: "",
      nilai_satuan: "",
      hpp: 0,
      qty: 0,
      diskon: 0,
      subtotal: 0,
      pajak: 0,
      total: 0,
    }
  ) {
    const { id, nama_produk, nilai_satuan, hpp, qty, diskon, subtotal, pajak, total } = data;
    this['id'] = id;
    this['nama_produk'] = nama_produk;
    this['nilai_satuan'] = nilai_satuan;
    this['hpp'] = hpp;
    this['qty'] = qty;
    this['diskon'] = diskon;
    this['subtotal'] = subtotal;
    this['pajak'] = pajak;
    this['total'] = total;
  }

  async getdata() {
    const { nama_produk, nilai_satuan, hpp, qty, diskon, subtotal, pajak, total } = this;
    return [{
      id: "nama_produk",
      title: "Nama Produk",
      label: nama_produk,
      sortable: true,
      minWidth: "25%",
    }, {
      id: "nilai_satuan",
      title: "Nilai Satuan",
      label: nilai_satuan,
      center: true,
      sortable: true,
      minWidth: "15%",
    }, {
      id: "total_harga",
      title: "Harga",
      label: separatorHarga(hpp.toString(), "Rp."),
      right: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "qty",
      title: "QTY",
      label: qty,
      center: true,
      sortable: true,
      minWidth: "15%",
    }, {
      id: "diskon",
      title: "Diskon",
      label: separatorHarga(diskon.toString(), "Rp."),
      center: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "subtotal",
      title: "Sub Total",
      label: separatorHarga(subtotal.toString(), "Rp."),
      center: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "pajak",
      title: "Pajak",
      label: separatorHarga(pajak.toString(), "Rp."),
      center: true,
      sortable: false,
      minWidth: "15%",
    }, {
      id: "total",
      title: "Total",
      label: separatorHarga(total.toString(), "Rp."),
      center: true,
      sortable: false,
      minWidth: "15%",
    }];
  }
}

import moment from "moment/moment";
export default class historyJurnalBalikModel {
  constructor(
    data = {
      id: 0,
      tanggal: "",
      cabang: "",
      no_transaksi: "",
      deskripsi: "",
      status: 0,
    }
  ) {
    const { id, tanggal, cabang, no_transaksi, deskripsi, status } = data;
    this['id'] = id;
    this['tanggal'] = tanggal;
    this['cabang'] = cabang;
    this['no_transaksi'] = no_transaksi;
    this['deskripsi'] = deskripsi;
    this['status'] = status;
  }
  async getdata() {
    const { tanggal, cabang, no_transaksi, deskripsi } = this;
    return [{
      id: "tanggal",
      title: "Tanggal",
      label: moment(tanggal).format("DD-MM-YYYY"),
      center: true,
      sortable: true,
      minWidth: "12%",
    }, {
      id: "cabang",
      title: "Cabang",
      label: cabang,
      center: true,
      sortable: true,
      minWidth: "20%",
    }, {
      id: "no_transaksi",
      title: "No. Transaksi",
      label: no_transaksi,
      center: true,
      sortable: true,
      minWidth: "22%",
    }, {
      id: "deskripsi",
      title: "Keterangan",
      label: deskripsi,
      sortable: true,
      minWidth: "30%",
    }];
  }
}

import React from 'react'
import { AnimatedCard } from '../../../../components/animated-card/animatedCard'
import { CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls';
import { ReactDatatable } from '../../../../components/react-datatable/reactDatatable';
import { separatorHarga } from '../../../../services/separator-harga';
import { Button } from 'react-bootstrap';
import { useState } from 'react';
import { saveAsExcel } from '../../../grafik/helperExportExcel';
import store from '../../../../redux/store';
import { LaporanGrafik } from '../../../../api/laporan-grafik';
import { useEffect } from 'react';
import Select2 from "react-select";

function PembayaranARcabang() {
  const [bulan] = useState(['January', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'])
  const columns = [
    {
      name: 'Nama',
      selector: row => row.name,
      sortable: true,
      minWidth: '12%'
    }
  ];

  bulan.map((data, i) => {
    return columns.push(
      {
        name: data,
        selector: row => separatorHarga(row.penjualan[i].toString(), 'Rp.'),
        sortable: true,
        minWidth: '12%'
      })
  })

  const ksa = [20, 46, 52, 69];
  const kpp = [68, 28, 70, 71];
  const ksb = [108, 105, 110, 109];

  const [pt] = useState([{
    value: ksa,
    label: "KSA"
  }, {
    value: kpp,
    label: "KPP"
  }, {
    value: ksb,
    label: "KSB"
  }])

  const [selectedPT, setSelectedPT] = useState(ksa.includes(store.getState().auth.id_perusahaan) ? ksa : kpp.includes(store.getState().auth.id_perusahaan) ? kpp : ksb);

  const [cabang, setCabang] = useState([0]);

  const title = 'LaporanPembayaranARCabang';
  const Export = ({ onExport }) => <Button onClick={e => onExport(e.target.value)}>
    <i className={"far fa-file-excel"}></i> {" Export .xls"}
  </Button>
  const actionsMemo = <Export onExport={() => saveAsExcel(penjualan, title)} />

  const [opsi, setOptions] = useState([
  ]);

  let opsiCabang = []

  const coverage = async () => {
    await new LaporanGrafik().getCoverage().then((res) => {
      let filter = res.data.filter((data) => selectedPT.includes(data.id))
      opsiCabang = filter.map((data) => (
        {
          label: data.text,
          text: data.text,
          value: data.id,
        }));
    });
    setOptions(opsiCabang);
  };

  const [penjualan, setPenjualan] = useState([])

  const fetchData = async (data) => {
    try {
      await new LaporanGrafik().getARallCabang(data).then((res) => res.data.map((val) => {
        // console.log('Valll',val);
        // dataSeries.push(val)
        const map = new Map(val.map(({ nama_perusahaan, count, total_terbayar }) => [nama_perusahaan, { nama_perusahaan, count: [], total_terbayar: [], }]));
        for (let { nama_perusahaan, count, total_terbayar } of val) {
          map.get(nama_perusahaan).count.push(...[count].flat())
          map.get(nama_perusahaan).total_terbayar.push(...[total_terbayar].flat())
        }
        let temp = []
        temp.push([...map.values()])
        temp.map((val) => {
          for (const item of val) {
            setPenjualan(penjualan => [...penjualan, {
              name: item.nama_perusahaan,
              penjualan: item.total_terbayar
            }])
          }
        })
      }));
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    // if (dataSeries.length === 0)
    // {
    if (cabang.includes(0)) {
      fetchData(selectedPT);
      // setIdPerusahaan(selectedPT)
    } else {
      fetchData(cabang);
      // setIdPerusahaan(cabang)
    }
    coverage();
    // }
  }, [cabang, selectedPT])
  return (
    <>
      {/* {console.log('Selected', sKategori)} */}
      <AnimatedCard>
        <CardBody>
          <div className="d-flex justify-content-start form-group row">
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <label> Pilih PT </label>
              <Select2
                name={"PT"}
                size="sm"
                options={pt}
                isClearable={false}
                value={
                  pt
                    ? pt.find(
                      (option) => option.value === selectedPT
                    )
                    : ""
                }
                onChange={(e) => {
                  setSelectedPT(e ? e.value : []);
                  setCabang([0])
                  setPenjualan([]);
                }}
              />
            </div>
            <div className="col-lg-3" style={{ marginTop: "20px",zIndex:"4" }}>
              <label> Pilih Perusahaan </label>
              <Select2
                name={"Cabang"}
                placeholder={opsi.text}
                size="sm"
                options={opsi}
                isClearable={true}
                value={
                  opsi
                    ? opsi.find(
                      (option) => option.value === cabang
                    )
                    : ""
                }
                onChange={(e) => {
                  setCabang(e ? [e.value] : [0]);
                  setPenjualan([]);
                }}
              />
            </div>
          </div>
          <ReactDatatable
            columns={columns}
            data={penjualan}
            dense
            pagination
            searchParameter={['name']}
            actions={actionsMemo}
          />
        </CardBody>
      </AnimatedCard>
    </>
  )
}

export default PembayaranARcabang
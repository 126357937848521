import React, { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Detail from "./formDetail"
import BagKeluar from "./index";

export default function BagKeluarRoute() {
    const history = useHistory();

    return (
        <Switch>
            <Route path="/bag-keluar/list">
                <BagKeluar />
            </Route>
            <Route path="/bag-keluar/detail">
                <Detail history={history}/>
            </Route>
            <Redirect from="/bag-keluar/" to="/bag-keluar/list" />
        </Switch>
    )
}
import * as Yup from "yup";
import { ProdukAPI } from "../../api/produk";
import { separatorHarga, hapusSeparator } from "../../services/separator-harga";
import store from "../../redux/store";
export default class InvoicePembelianDetailModel {
  constructor(
    data = {
      id: 0,
      id_pembelian: 0,
      id_perusahaan: store.getState().auth.id_perusahaan,
      id_produk: 0,
      // nama_produk: "",
      qty: 0,
      hpp: 0,
      hpp_total: 0,
      dpp: 0,
      dpp_total: 0,
      diskon: 0,
      diskon_persen: 0,
      subtotal: 0,
      pajak: 0,
      pajak_persen: 11,
      total: 0,
      id_user: store.getState().auth.id,
      id_pemasok: 0,
      deskripsi: "",
      status: 1,
      diskon_opsi: 0,
      created_at: "",
      update_at: "",
      typeDiskon: "number",
      toProduk: {
        nama: "",
        toProdukSatuan: {
          nama: "",
        }
      },
    }
  ) {
    this.id = data.id;
    this.id_pembelian = data.id_pembelian;
    this.id_perusahaan = data.id_perusahaan;
    this.id_produk = data.id_produk;
    this.nama_produk = data.toProduk.nama;
    this.produk_satuan = data.toProduk.toProdukSatuan.nama;
    this.qty = data.qty;
    this.hpp = separatorHarga(data.hpp.toString());
    this.hpp_total = separatorHarga(data.hpp_total.toString());
    this.dpp = separatorHarga(data.dpp.toString());
    this.dpp_total = separatorHarga(data.dpp_total.toString());
    this.diskon = separatorHarga(data.diskon.toString());
    this.diskon_persen = data.diskon_persen;
    this.subtotal = separatorHarga(data.subtotal.toString());
    this.pajak = separatorHarga(data.pajak.toString());
    this.pajak_persen = data.pajak_persen;
    this.total = separatorHarga(data.total.toString());
    this.id_user = data.id_user;
    this.id_pemasok = data.id_pemasok;
    this.deskripsi = data.deskripsi;
    this.status = data.status;
    this.created_at = data.created_at;
    this.update_at = data.update_at;
    this.diskon_opsi = data.diskon_opsi;
    this.options = [
      { value: 1, text: "Persen", id: 1 },
      { value: 2, text: "Rupiah", id: 2 },
    ];
    this.typeDiskon = data.typeDiskon;
  }

  nulldata = {
    id: 0,
    id_pembelian: 0,
    id_perusahaan: store.getState().auth.id_perusahaan,
    id_produk: 0,
    nama_produk: "",
    qty: 0,
    hpp: 0,
    hpp_total: 0,
    dpp: 0,
    dpp_total: 0,
    diskon: 0,
    diskon_persen: 0,
    subtotal: 0,
    pajak: 0,
    pajak_persen: 11,
    total: 0,
    id_user: store.getState().auth.id,
    id_pemasok: 0,
    deskripsi: "",
    status: 1,
    created_at: "",
    update_at: "",
    toProduk: {
      nama: "",
    },
  };

  removeSeparator() {
    this.hpp = parseInt(hapusSeparator(this.hpp));
    this.dpp = parseInt(hapusSeparator(this.dpp));
    this.hpp_total = parseInt(hapusSeparator(this.hpp_total));
    this.dpp_total = parseInt(hapusSeparator(this.dpp_total));
    this.diskon = parseInt(hapusSeparator(this.diskon));
    this.pajak = parseInt(hapusSeparator(this.pajak));
    this.subtotal = parseInt(hapusSeparator(this.subtotal));
    this.total = parseInt(hapusSeparator(this.total));
  }

  hitungTotalan = {
    all: (setFieldValue) => {
      this.hitungTotalan.hpp_total();
      this.hitungTotalan.diskon();
      this.hitungTotalan.subtotal();
      this.hitungTotalan.pajak();
      this.hitungTotalan.total();
      setFieldValue("hpp_total", this.hpp_total);
      setFieldValue("dpp_total", this.dpp_total);
      setFieldValue("diskon", this.diskon);
      setFieldValue("subtotal", this.subtotal);
      setFieldValue("pajak", this.pajak);
      setFieldValue("total", this.total);
    },
    hpp_total: () => {
      let hpp = parseInt(hapusSeparator(this.hpp));
      let dpp = parseInt(hapusSeparator(this.dpp));
      this.hpp_total = separatorHarga((parseInt(this.qty) * hpp).toString());
      this.dpp_total = separatorHarga((parseInt(this.qty) * dpp).toString());
    },
    diskon: () => {
      let hpp_total = parseInt(hapusSeparator(this.hpp_total));
      let diskonPersen = parseInt(hapusSeparator(this.diskon));
      let persen = diskonPersen/100;
      let hasilPersen = Math.ceil(hpp_total * persen)
      let diskon =
        this.diskon_opsi === 2
          ? this.diskon
          : separatorHarga(
              ((hasilPersen)).toString()
            );

      this.diskon = diskon
    },
    subtotal: () => {
      let hpp_total = parseInt(hapusSeparator(this.hpp_total));
      this.subtotal = separatorHarga(
        (hpp_total - parseInt(hapusSeparator(this.diskon))).toString()
      );
    },
    pajak: () => {
      let subtotal = parseInt(hapusSeparator(this.subtotal));
      let pajak = Math.ceil(subtotal * (this.pajak_persen/100))
      this.pajak = separatorHarga(parseInt(pajak).toString());
    },
    total: () => {
      let subtotal = parseInt(hapusSeparator(this.subtotal));
      this.total = separatorHarga(
        (subtotal + parseInt(hapusSeparator(this.pajak))).toString()
      );
    },
  };

  async getEditable(params = { pageName: "", isForm: true }) {
    let produk_options = [];
    if (params.isForm) {
      let datas = await new ProdukAPI().getAll()
      let filtered = datas.data.filter((val) => val.id_supplier === this.id_pemasok)
      console.log('isi id pemasok',this.id_pemasok);

       filtered.map((data, i) => 
       (produk_options = [
         ...produk_options,
         {id: data.id, text: data.nama, value: parseInt(data.id)}
       ]))
    }

    const getProdukDetail = async (id) => {
      return await new ProdukAPI()
        .getSingle(id)
        .then((res) => res.data.harga_beli);
    };

    return [
      {
        validation: Yup.number()
          .typeError("")
          .required("Produk harus diisi"),
        dataField: "id_produk",
        label: "Produk",
        type: "select",
        options: produk_options,
        onChange: (e, setFieldValue) => {
          setFieldValue("hpp", "Memuat...");
          if (e.target.value != 0)
            getProdukDetail(e.target.value).then((val) => {
              this.hpp = separatorHarga(val.toString());
              this.dpp = separatorHarga(val.toString());
              this.produk_satuan = val.toProdukSatuan.nama;
              this.nama_produk =
                e.target.options[e.target.options.selectedIndex].text;
              setFieldValue("hpp", this.hpp);
              setFieldValue("dpp", this.dpp);
            });
          this.hitungTotalan.all(setFieldValue);
          return (this.id_produk = parseInt(e.target.value));
        },
        value: `${this.nama_produk} ${this.produk_satuan}`,
      },
      {
        validation: Yup.string().required("Harga harus diisi"),
        dataField: "hpp",
        label: "Harga",
        type: "currency",
        onChange: (e, setFieldValue) => {
          this.hpp = e.target.value;
          this.dpp = e.target.value;
          this.hitungTotalan.all(setFieldValue);
        },
        value: this.hpp,
        // invisibleOnTable: true,
      },
      {
        validation: Yup.number()
          .integer()
          .positive()
          .typeError("QTY tidak valid")
          .required("QTY harus diisi"),
        dataField: "qty",
        label: "QTY",
        type: "number",
        value: this.qty,
        onChange: (e, setFieldValue) => {
          this.qty = parseInt(e.target.value);
          this.hitungTotalan.all(setFieldValue);
        },
      },

      {
        // validation: Yup.string().required("Total harga harus diisi"),
        dataField: "hpp_total",
        label: "Harga Total",
        type: "disabled-text",
        value: this.hpp_total,
        invisibleOnTable: true,
      },
      {
        dataField: "diskon_opsi",
        label: "Diskon By",
        onChange: (e, setFieldValue) => {
          this.diskon_opsi = parseInt(e.target.value);
          console.log("cek data type onChange", this.typeDiskon);
          console.log("cek kondisi onChange", this.diskon_opsi === 1);
          this.hitungTotalan.diskon();
        },
        type: "select",
        options: this.options,
        value: this.diskon_opsi,
        invisibleOnTable: true,
      },
      {
        // validation: Yup.string(),
        dataField: "diskon",
        label: "Diskon",
        onChange: (e, setFieldValue) => {
          this.diskon = e.target.value;
          this.hitungTotalan.all(setFieldValue);
        },
        type: "number",
        value: this.diskon,
      },
      {
        // validation: Yup.number()
        //   .integer()
        //   .positive()
        //   .max(100)
        //   .typeError("Pajak tidak valid")
        //   .required("Pajak harus diisi"),
        dataField: "pajak_persen",
        label: "Pajak",
        type: "disabled-text",
        onChange: (e, setFieldValue) => {
          this.pajak_persen = parseInt(e.target.value);
          this.hitungTotalan.all(setFieldValue);
        },
        value: this.pajak_persen,
        invisibleOnTable: true,
      },
      {
        // validation: Yup.string().required("Subtotal harus diisi"),
        dataField: "subtotal",
        label: "Sub Total",
        type: "disabled-text",
        value: this.subtotal,
      },
      {
        dataField: "dummy",
        type: "hidden",
        invisibleOnTable: true,
      },
      {
        // validation: Yup.string(),
        dataField: "pajak",
        label: "Pajak Rupiah",
        onChange: (e, setFieldValue) => {
          // this.pajak = e.target.value;
          // this.hitungTotalan.all(setFieldValue);
        },
        type: "disabled-text",
        value: this.pajak,
      },
      {
        dataField: "dummy",
        type: "hidden",
        invisibleOnTable: true,
      },
      {
        // validation: Yup.string().required("Total harus diisi"),
        dataField: "total",
        label: "Total",
        type: "disabled-text",
        value: this.total,
      },
      {
        dataField: "dummy",
        type: "hidden",
        invisibleOnTable: true,
      },
      {
        // validation: Yup.string(),
        dataField: "deskripsi",
        label: "Deskripsi",
        type: "textarea",
        value: this.deskripsi,
        onChange: (e) => (this.deskripsi = e.target.value),
        invisibleOnTable: true,
      },
    ];
  }
}

import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { dispatcher } from "../../../redux/dispatcher";
import store from "../../../redux/store";
import { Button } from "react-bootstrap";

export function ButtonValidasi({ dataModel }) {
  const history = useHistory();
  console.log('DataModel',dataModel);
  if (
    // dataModel.master.status_invoice === 1 &&
    dataModel.master.status_validasi === 1
  ) {
    return (
      <>
        <Button
          onClick={async () => {
            let send = {
              id: dataModel.master.id,
              id_user: store.getState().auth.id,
            };
            await dispatcher.penjualan.invoice(send);
            history.goBack();
          }}
        >
          <i className="fa fa-check-circle"></i>
          Validasi
        </Button>
      </>
    );
  } else {
    return <></>;
  }
}

import React, { useState } from "react";
import { Field, useField } from "formik";
import { Select, Input } from "../../../_metronic/_partials/controls";
import { separatorHarga } from "../../services/separator-harga";
import { ImageUploader } from "../image-uploader/imageUploader";
import Select2 from "react-select";

export function Fields(params) {
  params = {
    ...{ onChange: () => {} },
    ...{ info: "" },
    ...params,
    ...{ name: params.dataField },
  };
  const [field] = useField(params);
  const [checked, setChecked] = useState(params.checked);
  switch (params.type) {
    case "text":
      return (
        <Field
          id={params.id}
          name={params.dataField}
          component={Input}
          type="text"
          placeholder={params.label}
          label={params.label}
          autoComplete="off"
          onChange={(val) => {
            params.onChange(val, params.setFieldValue);
            field.onChange(val);
          }}
        />
      );
    case "hidden":
      return (
        <Field
          id={params.id}
          name={params.dataField}
          component={Input}
          type="hidden"
          placeholder={params.label}
          autoComplete="off"
          onChange={(val) => {
            params.onChange(val, params.setFieldValue);
            field.onChange(val);
          }}
        />
      );
    case "textarea":
      return (
        <Field
          id={params.id}
          name={params.dataField}
          component={Input}
          type="text"
          as="textarea"
          placeholder={params.label}
          label={params.label}
          autoComplete="off"
          onChange={(val) => {
            params.onChange(val, params.setFieldValue);
            field.onChange(val);
          }}
        />
      );
    case "number":
      return (
        <Field
          id={params.id}
          name={params.dataField}
          component={Input}
          type="number"
          min = {0}
          placeholder={params.label}
          label={params.label}
          autoComplete="off"
          onChange={(val) => {
            params.onChange(val, params.setFieldValue);
            field.onChange(val);
          }}
        />
      );
    case "date":
      return (
        <Field
          id={params.id}
          name={params.dataField}
          component={Input}
          type="date"
          placeholder={params.label}
          label={params.label}
          autoComplete="off"
          onChange={(val) => {
            params.onChange(val, params.setFieldValue);
            field.onChange(val);
          }}
        />
      );
    case "currency":
      return (
        <Field
          id={params.id}
          name={params.dataField}
          component={Input}
          type="text"
          placeholder={params.label}
          label={params.label}
          autoComplete="off"
          onChange={(val) => {
            val.target.value = separatorHarga(val.target.value);
            params.onChange(val, params.setFieldValue);
            field.onChange(val);
          }}
        />
      );
    case "readonly-text":
      return (
        <Field
          id={params.id}
          name={params.dataField}
          component={Input}
          type="text"
          placeholder={params.label}
          label={params.label}
          autoComplete="off"
          readOnly
        />
      );
    case "disabled-text":
      return (
        <Field
          id={params.id}
          name={params.dataField}
          component={Input}
          type="text"
          placeholder={params.label}
          label={params.label}
          autoComplete="off"
          disabled
        />
      );
    case "disabled-number":
      return (
        <Field
          id={params.id}
          name={params.dataField}
          component={Input}
          type="number"
          placeholder={params.label}
          label={params.label}
          autoComplete="off"
          disabled
        />
      );
    case "password":
      return (
        <Field
          id={params.id}
          name={params.dataField}
          component={Input}
          type="password"
          placeholder={params.label}
          label={params.label}
          autoComplete="off"
        />
      );
    case "checked":
      return (
        <>
          <label>{params.label}</label>
          <div className="mx-2">
            {params.list_check.map((check) => (
              <div key={check.id} className="checkbox-inline">
                <label className="checkbox">
                  <Field
                    id={check.id}
                    type="checkbox"
                    name="checked"
                    value={`${check.id}`}
                    checked={checked.includes(check.id)}
                    onClick={() => {
                      let checkeds = checked;
                      if (checkeds.includes(check.id)) {
                        checkeds.splice(checkeds.indexOf(check.id), 1);
                      } else {
                        checkeds = [...checkeds, check.id];
                      }
                      setChecked(checkeds);
                      params.onClick(checkeds);
                    }}
                  />
                  <span></span>
                  {check.nama}
                </label>
              </div>
            ))}
          </div>
        </>
      );
    case "select":
      return (
        <Select
          name={params.dataField}
          label={params.label}
          info={params.info}
          disabled={params.disabled}
          onChange={(val) => {
            params.onChange(val, params.setFieldValue);
            field.onChange(val);
          }}
        >
          <option value="0">Tidak ada terpilih</option>
          {params.options.map((option, i) => (
            <option key={i} id={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </Select>
      );
    case "select2":
      return (
        <div>
          <label>{params.label}</label>
          <Select2
            name={params.dataField}
            label={params.label}
            placeholder={params.placeholder}
            isClearable={params.isClearable}
            isLoading={params.isLoading}
            isDisabled={params.isDisabled}
            isSearchable={params.isSearchable}
            isMulti={params.isMulti}
            info={params.info}
            defaultValue={params.defaultValue}
            options={params.options}
            onChange={(val) => {
              params.onChange(val, params.setFieldValue);
              field.onChange(params.dataField);
              // field.onChange(params.dataField, val);
            }}
          />
        </div>
      );
    case "image":
      return <ImageUploader {...params} />;
    default:
      break;
  }
}

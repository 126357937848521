import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Form from "./form";
import List from "./list";
import CustomerModel from "../../../models/customer-model/CustomerModel";
import SalesCustomerModel from "../../../models/customer-model/SalesCustomerModel";

export default function SalesCustomer() {
  const [dataFromModel, setDataFromModel] = useState(new SalesCustomerModel());

  return (
    <Switch>
      <Route path="/customer-sales/new">
        <Form dataFromModel={new SalesCustomerModel()} />
      </Route>
      <Route path="/customer-sales/edit">
        <Form dataFromModel={dataFromModel} />
      </Route>
      <Route path="/customer-sales/list">
        <List setDataFromModel={setDataFromModel} />
      </Route>
      <Redirect from="/customer-sales/" to="/customer-sales/list" />
    </Switch>
  )
  }
import moment from "moment";
import { separatorHarga } from "../../../services/separator-harga";

export default class DetailHistoryPembelianCustomerModel {
  constructor(
    data = {
      nama_produk: 0,
      tanggal: "",
      qty: "",
      hpp: 0,
    }
  ) {
    const { nama_produk, tanggal, qty, hpp } = data;
    this['nama_produk'] = nama_produk;
    this['tanggal'] = tanggal;
    this['qty'] = qty;
    this['hpp'] = hpp;
  }

  async getdata() {
    const { nama_produk, tanggal, qty, hpp } = this;
    return [{
      id: "nama_produk",
      title: "Nama Produk",
      label: nama_produk,
      center: true,
      sortable: true,
      minWidth: "25%",
    }, {
      id: "tanggal",
      title: "Tanggal Penjualan",
      label: moment(tanggal).format("DD-MM-YYYY"),
      center: true,
      sortable: true,
      minWidth: "15%",
    }, {
      id: "qty",
      title: "QTY",
      label: qty,
      center: true,
      sortable: true,
      minWidth: "15%",
    }, {
      id: "hpp",
      title: "Harga Jual",
      label: hpp < 0 ? `Rp. -${separatorHarga(hpp.toString())}` : separatorHarga(hpp.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }];
  }
}

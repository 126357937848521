import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import FormCustomerSales from "../customer-sales/form";
import ListCustomerSales from "../customer-sales/list";
import React from "react";
import { dispatcher } from "../../../../redux/dispatcher";

export default function CustomerSales() {
  const history = useHistory();
  const active = dispatcher['kontak'].getCustomerSales();
  return (
    <Switch>
      <Route path={"/master-customer-sales/list"}>
        <ListCustomerSales />
      </Route>
      <Route path={"/master-customer-sales/add-form"}>
        <FormCustomerSales history={history} isEdit={false} />
      </Route>
      {active['id'] === 0 && (
        <Redirect to={"/master-customer-sales/list"} />
      )}
      <Route path={"/master-customer-sales/edit-form"}>
        <FormCustomerSales history={history} isEdit={true} />
      </Route>
      <Redirect to="/master-customer-sales/list" />
    </Switch>
  );
}

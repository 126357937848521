import { getCoverageArea, simpleFetchGetData, getIdRole, FormDataFetch, simpleFetch } from "./api";
export class notificationAPI {
  //** Begin: Pesanan Pembelian */
  //** Get List */
  async getAllPesananPembelian() {
    let get = `&id_perusahaan=${getCoverageArea()}`;
    let res = await simpleFetchGetData({
      url: "retail/notifications?tipe=pesanan-pembelian" + get,
      method: "GET",
    });
    return res;
  }
  //** Save */
  //** Record/Approve */
  async approvedPesananPembelian(values) {
    const fd = new FormData();
    for (const key in values) {
      fd.append(key, values[key]);
    }
    fd.append("tipe", "approved-pesanan-pembelian");
    let res = await FormDataFetch({
      url: "retail/notifications/approved",
      method: "POST",
      body: fd,
    });
    return res;
  }
  //** Delete */
  //** Edit */
  //** End: Pesanan Pembelian */
  //* ------------------------------------------------------------------------------------------- */
  //** Begin: Approval Customer (Awal/Akhir) */
  //** Get List */
  async getAllApprovalCustomer(value) {
    let get = `&aksi=${value}&id_perusahaan=${getCoverageArea()}`;
    let res = await simpleFetchGetData({
      url: "retail/notifications?tipe=approval-customer" + get,
      method: "GET",
    });
    return res;
  }
  //** Save */
  //** Record/Approve */
  async approvedCustomer(values) {
    const fd = new FormData();
    for (const key in values) {
      fd.append(key, values[key]);
    }
    fd.append("tipe", "approval-customer");
    let res = await FormDataFetch({
      url: "retail/notifications/approved",
      method: "POST",
      body: fd,
    });
    return res;
  }
  //** Delete */
  //** Edit */
  //** End: Approval Customer (Awal/Akhir) */
  //* ------------------------------------------------------------------------------------------- */
  //** Begin: Approval Pesanan Penjualan (Awal/Admin/Akhir) */
  //** Get List */
  async getAllApprovalPesananPenjualan(value) {
    let get = `&aksi=${value}&id_perusahaan=${getCoverageArea()}`;
    let res = await simpleFetchGetData({
      url: "retail/notifications?tipe=approval-pesanan-penjualan" + get,
      method: "GET",
    });
    return res;
  }
  //** Save */
  //** Record/Approve */
  async approvedPesananPenjualan(values) {
    const fd = new FormData();
    for (const key in values) {
      fd.append(key, values[key]);
    }
    fd.append("tipe", "approval-pesanan-penjualan");
    let res = await FormDataFetch({
      url: "retail/notifications/approved",
      method: "POST",
      body: fd,
    });
    return res;
  }
  //** Reject */
  async rejectApprovalPesananPenjualan(values) {
    let res = await simpleFetch({
      url: "retail/notifications/reject",
      method: "POST",
      body: {
        ...values,
      },
    });
    return res;
  }
  //** Delete */
  //** Edit */
  //** End: Approval Pesanan Penjualan (Awal/Admin/Akhir) */
  //* ------------------------------------------------------------------------------------------- */
  async getCountNotif() {
    let get = `&id_perusahaan=${getCoverageArea()}`;
    let res = await simpleFetchGetData({
      url: "retail/notifications/countAll?id_role=" + getIdRole() + get,
      method: "GET",
    });
    return res;
  }
}

import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import FormPengeluaranBankCollection from "./form";
import { dispatcher } from "../../../../../redux/dispatcher";
export default function IndexFormPengeluaranBankCollection() {
  dispatcher['kasir_bank_collection'].resetActivePengeluaranBankCollection();
  const active = dispatcher['kasir_bank_collection'].getPengeluaranBankCollection();
  return (
    <Switch>
      <Route path="/kasir/tambah-pengeluaran-bank-collection/form">
        <FormPengeluaranBankCollection />
      </Route>
      {active['transaksi']['id'] === 0 && (
        <Redirect to="/kasir/tambah-pengeluaran-bank-collection/form" />
      )}
      <Redirect to="/kasir/tambah-pengeluaran-bank-collection/form" />
    </Switch>
  );
}

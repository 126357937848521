import * as Yup from "yup";
import { ProdukAPI } from "../../api/produk";
import { SupplierAPI } from "../../api/supplier";
import { separatorHarga, hapusSeparator } from "../../services/separator-harga";
import store from "../../redux/store";

export default class PenjualanDetailModel {
  constructor(
    data = {
      id: 0,
      id_penjualan: 0,
      id_perusahaan: store.getState().auth.id_perusahaan,
      id_produk: 0,
      kode: "",
      tanggal: "",
      qty: 0,
      hpp: 0,
      hpp_total: 0,
      dpp: 0,
      dpp_total: 0,
      diskon: 0,
      diskon_persen: 0,
      diskon_opsi: 0,
      diskon_item: 0,
      subtotal: 0,
      pajak: 0,
      pajak_persen: 11,
      total: 0,
      id_user: store.getState().auth.id,
      id_customer: 0,
      id_sales: 0,
      id_kontak: 0,
      id_gudang: 0,
      deskripsi: "",
      status: 1,
      created_at: "",
      update_at: "",
      toProduk: {
        nama: "",
        kode: "",
        persediaan_total: 0,
      },
    }
  ) {
    this.id = data.id;
    this.id_penjualan = data.id_penjualan;
    this.id_perusahaan = data.id_perusahaan;
    this.id_produk = data.id_produk;
    this.nama_produk = data.toProduk.nama;
    this.kode = data.kode;
    this.tanggal = data.tanggal;
    this.qty = data.qty;
    this.hpp = separatorHarga(data.hpp.toString());
    this.hpp_total = separatorHarga(data.hpp_total.toString());
    this.dpp = separatorHarga(data.dpp.toString());
    this.dpp_total = separatorHarga(data.dpp_total.toString());
    this.diskon = separatorHarga(data.diskon.toString());
    this.diskon_item = data.diskon_item;
    this.diskon_persen = data.diskon_persen;
    this.subtotal = separatorHarga(data.subtotal.toString());
    this.pajak = separatorHarga(data.pajak.toString());
    this.pajak_persen = data.pajak_persen;
    this.total = separatorHarga(data.total.toString());
    this.persediaan_total = data.toProduk.persediaan_total;
    this.id_user = data.id_user;
    this.id_customer = data.id_customer;
    this.id_sales = data.id_sales;
    this.id_kontak = data.id_kontak;
    this.id_gudang = data.id_gudang;
    this.deskripsi = data.deskripsi;
    this.status = data.status;
    this.created_at = data.created_at;
    this.update_at = data.update_at;
    this.diskon_opsi = data.diskon_opsi;
    this.options = [
      { value: 1, text: "Persen", id: 1 },
      { value: 2, text: "Rupiah", id: 2 },
    ];
  }

  nulldata = {
    id: 0,
    id_perusahaan: store.getState().auth.id_perusahaan,
    id_produk: 0,
    nama_produk: "",
    kode: "",
    tanggal: "",
    qty: 0,
    hpp: 0,
    hpp_total: 0,
    dpp: 0,
    dpp_total: 0,
    diskon: 0,
    diskon_persen: 0,
    subtotal: 0,
    pajak: 0,
    pajak_persen: 11,
    total: 0,
    id_user: store.getState().auth.id,
    id_customer: 0,
    id_sales: "",
    id_kontak: 0,
    id_gudang: 0,
    deskripsi: "",
    status: 1,
    created_at: "",
    update_at: "",
    toProduk: {
      nama: "",
      persediaan_total: 0,
    },
  };

  removeSeparator() {
    this.hpp = parseInt(hapusSeparator(this.hpp));
    this.dpp = parseInt(hapusSeparator(this.dpp));
    this.hpp_total = parseInt(hapusSeparator(this.hpp_total));
    this.dpp_total = parseInt(hapusSeparator(this.dpp_total));
    this.diskon = parseInt(hapusSeparator(this.diskon));
    this.pajak = parseInt(hapusSeparator(this.pajak));
    this.subtotal = parseInt(hapusSeparator(this.subtotal));
    this.total = parseInt(hapusSeparator(this.total));
  }

  hitungTotalan = {
    all: (setFieldValue) => {
      this.hitungTotalan.hpp_total();
      this.hitungTotalan.diskon();
      this.hitungTotalan.subtotal();
      this.hitungTotalan.pajak();
      this.hitungTotalan.total();
      setFieldValue("hpp_total", this.hpp_total);
      setFieldValue("dpp_total", this.dpp_total);
      setFieldValue("diskon", this.diskon);
      setFieldValue("subtotal", this.subtotal);
      setFieldValue("pajak", this.pajak);
      setFieldValue("total", this.total);
    },
    hpp_total: () => {
      let hpp = parseInt(hapusSeparator(this.hpp));
      let dpp = parseInt(hapusSeparator(this.dpp));
      this.hpp_total = separatorHarga((parseInt(this.qty) * hpp).toString());
      this.dpp_total = separatorHarga((parseInt(this.qty) * dpp).toString());
    },
    diskon: () => {
      let hpp_total = parseInt(hapusSeparator(this.hpp_total));
      this.diskon =
        this.diskon_opsi === 2
          ? parseInt(this.diskon).toString()
          : separatorHarga(
            ((hpp_total * parseInt(this.diskon)) / 100).toString()
          );
    },
    subtotal: () => {
      let hpp_total = parseInt(hapusSeparator(this.hpp_total));
      this.subtotal = separatorHarga(
        (hpp_total - parseInt(hapusSeparator(this.diskon))).toString()
      );
    },
    pajak: () => {
      let subtotal = parseInt(hapusSeparator(this.subtotal));
      this.pajak = separatorHarga(
        ((subtotal * parseInt(this.pajak_persen)) / 100).toString()
      );
    },
    total: () => {
      let subtotal = parseInt(hapusSeparator(this.subtotal));
      this.total = separatorHarga(
        (subtotal + parseInt(hapusSeparator(this.pajak))).toString()
      );
    },
  };

  async getEditable(params = { pageName: "", isForm: false }) {
    let produk_options = [];
    if (params.isForm) {
      await new ProdukAPI()
        .getAll()
        .then((response) =>
          response.data.map(
            (data, i) =>
            (produk_options = [
              ...produk_options,
              { id: data.id, text: data.nama, value: parseInt(data.id) },
            ])
          )
        );
    }

    const getProdukDetail = async (id) => {
      return await new ProdukAPI().getSingle(id).then((res) => {
        return res.data.harga_jual;
      });
    };

    return [
      {
        validation: Yup.number()
          .typeError("")
          .required("Produk harus diisi"),
        dataField: "id_produk",
        label: "Produk",
        type: "select",
        options: produk_options,
        onChange: (e, setFieldValue) => {
          setFieldValue("dpp", "Memuat...");
          setFieldValue("persediaan_total", "Memuat...");
          if (e.target.value != 0)
            getProdukDetail(e.target.value).then((val) => {
              this.hpp = separatorHarga(val.toString());
              this.dpp = separatorHarga(val.toString());
              this.nama_produk =
                e.target.options[e.target.options.selectedIndex].text;
              this.persediaan_total = val.persediaan_total;
              setFieldValue("persediaan_total", this.persediaan_total);
              setFieldValue("hpp", this.hpp);
              setFieldValue("dpp", this.dpp);
            });
          this.hitungTotalan.all(setFieldValue);
          return (this.id_produk = parseInt(e.target.value));
        },
        value: this.nama_produk,
      },
      {
        validation: Yup.string().required("Harga harus diisi"),
        dataField: "dpp",
        label: "Harga",
        type: "disabled-text",
        onChange: (e, setFieldValue) => {
          this.hpp = e.target.value;
          this.dpp = e.target.value;
          this.hitungTotalan.all(setFieldValue);
        },
        value: this.dpp,
        // invisibleOnTable: true,
      },
      {
        validation: Yup.string(),
        dataField: "persediaan_total",
        label: "Stok Tersedia",
        type: "disabled-text",
        onChange: (e, setFieldValue) => {
          this.persediaan_total = e.target.value
        },
        value: this.persediaan_total,
        invisibleOnTable: true,
      },
      {
        validation: Yup.number()
          .integer()
          .positive()
          .typeError("QTY tidak valid")
          .required("QTY harus diisi"),
        dataField: "qty",
        label: "QTY",
        type: "number",
        value: this.qty,
        onChange: (e, setFieldValue) => {
          this.qty = parseInt(e.target.value);
          this.hitungTotalan.all(setFieldValue);
        },
      },

      {
        validation: Yup.string().required("Total harga harus diisi"),
        dataField: "hpp_total",
        label: "Harga Total",
        type: "disabled-text",
        value: this.hpp_total,
        invisibleOnTable: true,
      },
      // {
      //   validation: Yup.number()
      //     .integer()
      //     .positive()
      //     .max(100)
      //     .typeError("Diskon tidak valid")
      //     .required("Diskon harus diisi"),
      //   dataField: "diskon_persen",
      //   label: "Diskon",
      //   onChange: (e, setFieldValue) => {
      //     this.diskon_persen = e.target.value;
      //     this.hitungTotalan.all(setFieldValue);
      //   },
      //   type: "number",
      //   value: this.diskon_persen,
      //   invisibleOnTable: true,
      // },
      {
        dataField: "diskon_opsi",
        label: "Diskon By",
        onChange: (e, setFieldValue) => {
          this.diskon_opsi = parseInt(e.target.value);
          console.log("cek kondisi onChange", this.diskon_opsi === 1);
          this.hitungTotalan.diskon();
        },
        type: "select",
        options: this.options,
        value: this.diskon_opsi,
        invisibleOnTable: true,
      },
      {
        // validation: Yup.string(),
        dataField: "diskon_item",
        label: "Diskon / Item",
        type: "hidden",
        value: this.diskon_item
        // invisibleOnTable: true,
      },
      {
        validation: Yup.string(),
        dataField: "diskon",
        label: "Diskon",
        onChange: (e, setFieldValue) => {
          this.diskon = e.target.value;
          this.hitungTotalan.all(setFieldValue);
          // this.diskon = e.target.value;
          // this.hitungTotalan.all(setFieldValue);
        },
        type: "number",
        value: this.diskon,
      },
      {
        // validation: Yup.string(),
        dataField: "diskon_persen",
        label: "Diskon %",
        type: "hidden",
        value: this.diskon_persen,
        onChange: (e) => {
        },
        // invisibleOnTable: true,
      },
      {
        validation: Yup.number()
          .integer()
          .positive()
          .max(100)
          .typeError("Pajak tidak valid")
          .required("Pajak harus diisi"),
        dataField: "pajak_persen",
        label: "Pajak",
        type: "disabled-text",
        onChange: (e, setFieldValue) => {
          this.pajak_persen = parseInt(e.target.value);
          this.hitungTotalan.all(setFieldValue);
        },
        value: this.pajak_persen,
        invisibleOnTable: true,
      },
      {
        validation: Yup.string(),
        dataField: "pajak",
        label: "Pajak Rupiah",
        onChange: (e, setFieldValue) => {
          // this.pajak = e.target.value;
          // this.hitungTotalan.all(setFieldValue);
        },
        type: "disabled-text",
        value: this.pajak,
      },
      {
        validation: Yup.string().required("Subtotal harus diisi"),
        dataField: "subtotal",
        label: "Sub Total",
        type: "disabled-text",
        value: this.subtotal,
      },
      {
        validation: Yup.string().required("Total harus diisi"),
        dataField: "total",
        label: "Total",
        type: "disabled-text",
        value: this.total,
      },
      {
        validation: Yup.string(),
        dataField: "deskripsi",
        label: "Deskripsi",
        type: "textarea",
        value: this.deskripsi,
        onChange: (e) => (this.deskripsi = e.target.value),
        invisibleOnTable: true,
      },
    ];
  }
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import Model from "../../../models/pesanan-pembelian-model/PesananPembelianModel";
import { Link } from "react-router-dom";
import { dispatcher } from "../../../redux/dispatcher";
import { ButtonEditable } from "./button_editable";
import store from "../../../redux/store";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import Select2 from "react-select";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import { PesananPembelianAPI } from "../../../api/pesanan-pembelian";

export default function List() {
  // const options = [
  //   { value: '0', label: 'Belum Approve' },
  //   { value: '2', label: 'Sudah Approve' }
  // ]
  // const [filter, setFilter] = useState({ value: 0, label: 'Belum Approve'});
  // useEffect(() => {
  //   loadData()
  // }, [filter]);
  const [refreshData, setRefresh] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([])
  const api = new PesananPembelianAPI();
  const handleClose = () => {
    setShow(false)
  };
  const handleSimpan = () => {
    data.removeSeparator()
    data.removeAllDetailSeparator()
    data.master.status_batal = 1
    data.master.is_approved = 0
    api.edit(data)
  };
  const handleShow = (data) => {
    setData(data)
    setShow(true)
  }

  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const pageName = "Purchase Order";
  const [startDate, setStartDate] = useState(
    moment(new Date())
      .startOf("month")
      .format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [search, setSearch] = useState("");
  const [option, setOptions] = useState({
    value: store.getState().auth.id_perusahaan,
    text: "--- Pilih Cabang ---",
  });
  const [cabang, setCabang] = useState([]);
  let options = [];

  const coverage = async () => {
    await api.getCoverage().then((res) => {
      options = res.data.map((data) => ({
        label: data.text,
        text: data.text,
        value: data.id,
      }));
    });
    setCabang(options);
  };

  async function loadData() {
    let datas = dispatcher.pesanan_pembelian.getList();
    let dataMaster = datas;
    let simpleTableModel = new SimpleTableModel();
    let searchParams = ["no_transaksi", "total", "kode"];
    let filtered = dataMaster.filter((filter) => {
      let dateFormat = moment(filter.master.tanggal).format("YYYY-MM-DD");
      let dateFilter = dateFormat >= startDate && dateFormat <= endDate;
      let cabangSelected = filter.master.id_perusahaan === option.value;
      return searchParams.some((item) => {
        return (
          dateFilter &&
          cabangSelected &&
          filter.master[item]
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())
        );
      });
    });
    filtered.forEach((item, i) => {
      let dataModel = new Model(item);
      // if (item.master.status === 1)
        simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: (
            <>
              <ButtonEditable dataModel={dataModel} />
              {item.master.is_approved >= 2 ? (
                <Link
                  className="mx-1 btn btn-primary btn-sm"
                  to="/pembelian-pesanan-pembelian/detail"
                  onClick={() => {
                    dispatcher.pesanan_pembelian.set(dataModel);
                  }}
                >
                  <i className="fas fa-user-check"></i>
                </Link>
              ) : (
                <span>
                  <Link
                    className="mx-1 btn btn-warning btn-sm"
                    to="/pembelian-pesanan-pembelian/detail"
                    onClick={() => {
                      dispatcher.pesanan_pembelian.set(dataModel);
                    }}
                  >
                    <i className="fas fa-user-lock"></i>
                  </Link>
                </span>
              )}
              {item.master.status_invoice !== 1 ?
                (
                  <span>
                    <Link
                      className="mx-1 btn btn-outline-success btn-sm"
                      to="/pembelian-pesanan-pembelian/edit"
                      onClick={() => {
                        dispatcher.pesanan_pembelian.set(dataModel);
                      }}
                    >
                      Edit
                    </Link>
                    <Button
                      className="mx-1 btn-outline-danger btn-sm"
                      onClick=
                      {() => {
                        handleShow(dataModel)
                      }}
                    >
                      Batal
                    </Button>
                  </span>
                ) : (
                  <span>
                    <Button
                      className="mx-1 btn btn-secondary btn-sm"
                      disabled
                    >
                      Edit
                    </Button>
                    <Button
                      className="mx-1 btn btn-secondary btn-sm"
                      disabled
                    >
                      Batal
                    </Button>
                  </span>
                )
              }
            </>
          ),
        });
    });

    setColumntable(await simpleTableModel.getColumnHeaders());
    setDatatable(await simpleTableModel.getDatatables());
  }

  useEffect(() => {
    dispatcher.pesanan_pembelian.list();
  }, [startDate, endDate, refreshData]);

  useEffect(() => {
    loadData();
    coverage();
  }, [dispatcher.pesanan_pembelian.getList()]);

  return (
    <>
      <AnimatedCard>
        <CardHeader title={pageName}>
          {/* <CardHeaderToolbar>
            <Link
              className="btn btn-primary"
              to="/pembelian-pesanan-pembelian/new"
              onClick={() => dispatcher.pesanan_pembelian.resetActive()}
            >
              <i className="fa fa-plus"></i>
              Tambah {pageName}
            </Link>
          </CardHeaderToolbar> */}
        </CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Mulai </label>
              <input
                name={"tanggal_awal"}
                type={"date"}
                value={startDate}
                className={"form-control"}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  loadData();
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Akhir </label>
              <input
                name={"tanggal_akhir"}
                type={"date"}
                value={endDate}
                className={"form-control"}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  loadData();
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Pilih Perusahaan </label>
              <Select2
                name={"Cabang"}
                placeholder={option.label}
                size="sm"
                options={cabang}
                isClearable={true}
                onChange={(e) => {
                  setOptions({ value: e ? e.value : [] });
                  dispatcher.pesanan_pembelian.list()
                  // setFieldValue("cabang", e ? e.value : "");
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <input
                type={"text"}
                className={"form-control"}
                placeholder="Cari Data ..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  dispatcher.pesanan_pembelian.list()
                }}
              />
            </div>
          </div>
          <SimpleTable data={datatable} columns={columntable} />
        </CardBody>
        {/* Modal */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Batalkan Pesanan Barang</Modal.Title>
          </Modal.Header>
          <Modal.Body>Yakin mau batalkan transaksi ini ?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Tutup
            </Button>
            <Button variant="primary"
              type="submit"
              onClick={() => {
                handleSimpan()
                handleClose()
                setRefresh(!refreshData)
              }}
            >
              Batalkan Pesanan Pembelian
            </Button>
          </Modal.Footer>
        </Modal>
      </AnimatedCard>
    </>
  );
}

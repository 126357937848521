import * as Yup from "yup";
import { hapusSeparator, separatorHarga } from "../../services/separator-harga";

export default class ReturPenjualanModel {
  constructor(
    data = {
      id: 0,
      id_perusahaan: 0,
      id_penjualan: 0,
      jenis_pembayaran: 0,
      tanggal: "",
      no_retur: "",
      no_transaksi: "",
      deskripsi: "",
      is_approved:0,
      approved_manager_by:0,
    },
  ) {
    this.id = data.id
      this.id_perusahaan =  data.id_perusahaan
      this.id_penjualan = data.id_penjualan
      this.jenis_pembayaran = data.jenis_pembayaran
      this.tanggal = data.tanggal
      this.no_retur = data.no_retur
      this.no_transaksi = data.no_transaksi
      this.deskripsi = data.deskripsi
      this.is_approved = data.is_approved
      this.approved_manager_by = data.approved_manager_by
  }

  async getEditable(params = { pageName: "", isForm: false }) {
    return [
      {
        dataField: "tanggal",
        label: "Tanggal",
        type: "date",
        value: this.tanggal,
      },
      {
        dataField: "jenis_pembayaran",
        columnHeaderText: "Jenis Pembayaran",
        label: "Jenis Pembayaran",
        type: "disabled-text",
        value: this.jenis_pembayaran === 1 ? "Cash" : "Kredit",
      },
      {
        dataField: "no_retur",
        label: "No Retur",
        type: "text",
        value: this.no_retur,
      },
      {
        dataField: "no_transaksi",
        label: "No Transaksi",
        type: "disabled-text",
        value: this.no_transaksi,
      },
      {
        dataField: "deskripsi",
        label: "Alasan Retur",
        type: "disabled-text",
        value: this.deskripsi,
      },
    ];
  }
}

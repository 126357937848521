export default class AkunModel {
  constructor(
    data = {
      id: 0,
      id_parent: "",
      id_perusahaan: "",
      id_akun_jenis: "",
      id_akun_akses: "",
      kode_parent: "",
      kode_akun: "",
      nama_akun: "",
      nama_perusahaan: "",
      jenis_akun: "",
      nama_akun_akses: "",
      control_parent: null,
      status: 0,
    }
  ) {
    const { id, id_parent, id_perusahaan, id_akun_jenis, id_akun_akses, kode_parent, kode_akun, nama_akun, nama_perusahaan, jenis_akun, nama_akun_akses, control_parent, status } = data;
    this['id'] = id;
    this['id_parent'] = id_parent;
    this['id_perusahaan'] = id_perusahaan;
    this['id_akun_jenis'] = id_akun_jenis;
    this['id_akun_akses'] = id_akun_akses;
    this['kode_parent'] = kode_parent;
    this['kode_akun'] = kode_akun;
    this['nama_akun'] = nama_akun;
    this['nama_perusahaan'] = nama_perusahaan;
    this['jenis_akun'] = jenis_akun;
    this['nama_akun_akses'] = nama_akun_akses;
    this['control_parent'] = control_parent;
    this['status'] = status;
  }

  async getdata() {
    const { kode_parent, kode_akun, nama_akun, nama_perusahaan, jenis_akun, nama_akun_akses } = this;
    return [{
      id: "id_kode_parent",
      title: "Kode Parent",
      label: kode_parent,
      center: true,
      sortable: true,
      minWidth: "12%",
    }, {
      id: "nama_perusahaan",
      title: "Nama Perusahaan",
      label: nama_perusahaan,
      sortable: true,
      minWidth: "25%",
    }, {
      id: "kode_akun",
      title: "Kode Akun",
      label: kode_akun,
      center: true,
      sortable: true,
      minWidth: "12%",
    }, {
      id: "nama_akun",
      title: "Nama Akun",
      label: nama_akun,
      sortable: true,
      minWidth: "19%",
    }, {
      id: "jenis_akun",
      title: "Jenis Akun",
      label: jenis_akun,
      center: true,
      sortable: true,
      minWidth: "13%",
    }, {
      id: "nama_akun_akses",
      title: "Akun Akses",
      label: nama_akun_akses,
      center: true,
      sortable: true,
      minWidth: "25%",
    }];
  }
}

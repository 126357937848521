import { Redirect, Route, Switch, useHistory } from "react-router-dom";

import ApprovalCustomerAwalList from "./list";
import ApprovalCustomerAwalForms from "./forms";
import React from "react";
import { dispatcher } from "../../../../redux/dispatcher";

export default function ApprovalCustomerAwalIndex() {
   const history = useHistory();
   const active = dispatcher['notification'].getApprovalCustomerAwal();
   return (
      <Switch>
         <Route path={"/notifications/approval-customer-awal/list"}>
            <ApprovalCustomerAwalList />
         </Route>
         <Route path={"/notifications/approval-customer-awal/add-form"}>
            <ApprovalCustomerAwalForms history={history} isEdit={false} />
         </Route>
         {active['id'] === 0 && (
            <Redirect to={"/notifications/approval-customer-awal/list"} />
         )}
         <Route path={"/notifications/approval-customer-awal/edit-form"}>
            <ApprovalCustomerAwalForms history={history} isEdit={true} />
         </Route>
         <Redirect to="/notifications/approval-customer-awal/list" />
      </Switch>
   );
}

import { CardBody, CardHeader } from "../../../../../_metronic/_partials/controls";
import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import DataTableModel from "../../../../models/datatable-model/DataTableModel";
import React from "react";
import { ReactDatatable } from "../../../../components/react-datatable/reactDatatable";
import { dispatcher } from "../../../../redux/dispatcher";
import ButtonActions from "../../../../components/button-actions/buttonActions";
import { notificationAPI } from "../../../../api/notifications";
import OrganisasiModel from "../../../../models/kontak/organisasi/OrganisasiModel";

export default class ApprovalCustomerAkhirList extends React.Component {
  constructor(props) {
    super(props);
    this['pageName'] = "Approval Customer Akhir";
    this['searchParameter'] = ["kode", "nama", "alamat", "telepon", "nama_bank", "nama_rekening", "no_rekening", "npwp", "nilai_top"];
    this['apiNotification'] = new notificationAPI();
    this['initialData'] = new OrganisasiModel();
    this['state'] = {
      pending: true,
      modal: {
        show: false,
        initialData: this['initialData'],
        isEdit: false,
      },
    };
    this['datatable'] = [{ id: "", no: "", name: "" }];
    this['columntable'] = [{ id: "", title: "" }];
    this['datatableDetail'] = [{ id: "", no: "", name: "" }];
    this['columntableDetail'] = [{ id: "", title: "" }];
  }
  async componentDidMount() {
    await this.LoadData();
  }
  async LoadData() {
    this.setState({ pending: true });
    await dispatcher['notification'].listApprovalCustomerAkhir();
    let data = dispatcher['notification'].getListApprovalCustomerAkhir();
    this['DataTableModel'] = new DataTableModel();
    data.map((item, i) => {
      let dataModel = new OrganisasiModel(item);
      this['DataTableModel'].add({
        id: i,
        no: i + 1,
        dataModel: dataModel,
        actions: (
          <>
            <ButtonActions
              label={"approveLink"}
              linkTo={"/notifications/approval-customer-akhir/edit-form"}
              onClick={async () => dispatcher['notification'].setApprovalCustomerAkhir(dataModel)}
            />
          </>
        ),
      });
      return item;
    });
    this['columntable'] = await this['DataTableModel'].getColumn();
    this['datatable'] = await this['DataTableModel'].getDatas();
    this.setState({ ...this['state'], pending: false });
  }
  render() {
    const { pending } = this['state'];
    return (
      <>
        {/* Views Table */}
        <AnimatedCard>
          <CardHeader title={this['pageName']}></CardHeader>
          <CardBody>
            <ReactDatatable
              title={""}
              columns={this['columntable']}
              data={this['datatable']}
              searchParameter={this['searchParameter']}
              progressPending={pending}
            />
          </CardBody>
        </AnimatedCard>
        {/* ----------------------------------------------------------------------------- */}
      </>
    );
  }
}

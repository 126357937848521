import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import ApprovalAdministrasiPengeluaranKas from "./approvalAdministrasi";
import DetailPengeluaranKas from "../list/detail";
import React from "react";
import { dispatcher } from "../../../../../redux/dispatcher";
export default function IndexApprovalAdministrasiPengeluaranKas() {
  const history = useHistory();
  const active = dispatcher['kasir_kas'].getPengeluaranKas();
  return (
    <Switch>
      <Route path="/kasir/approval-administrasi-kas/approval-administrasi">
        <ApprovalAdministrasiPengeluaranKas />
      </Route>
      {active['transaksi']['id'] === 0 && (
        <Redirect to="/kasir/approval-administrasi-kas/approval-administrasi" />
      )}
      <Route path="/kasir/approval-administrasi-kas/detail">
        <DetailPengeluaranKas history={history} />
      </Route>
      <Redirect to="/kasir/approval-administrasi-kas/approval-administrasi" />
    </Switch>
  );
}

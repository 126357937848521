import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select2 from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
// import * as auth from "../_redux/authRedux";
// import { login } from "../_redux/authCrud";
import { login } from "../../../api/login";
import { authAction } from "../../../redux/auth/actions";
import { bindActionCreators } from "redux";
import { menuAction } from "../../../redux/menu/actions";
import { getAllMenuSidebar } from "../../../api/menu";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers/AssetsHelpers";
import { Image } from "react-bootstrap";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

// const initialValues = {
//   username: "Lia",
//   password: "0401066",
// };
const initialValues = {
  username: "",
  password: "",
  perusahaan: "",
};

function Login ()
{
  const [ loading, setLoading ] = useState( false );
  const [ LoadOption, setLoadOption ] = useState( false );
  const [ optionsPerusahaan, setoptionsPerusahaan ] = useState( [] );
  const LoginSchema = Yup.object().shape( {
    username: Yup.string().required( "Fill Username Field" ),
    password: Yup.string()
      .min( 3, "Minimum 3 symbols" )
      .max( 50, "Maximum 50 symbols" )
      .required( "Fill Password Field" ),
    perusahaan: Yup.string().required( "Fill Perusahaan Field" ),
  } );

  const dispatch = useDispatch();
  const updateReducer = {
    auth: ( val ) =>
    {
      return ( dispatch ) =>
      {
        dispatch( { type: authAction.setUser, payload: val } );
      };
    },
    menus: ( val ) =>
    {
      return ( dispatch ) =>
      {
        dispatch( { type: menuAction.setMenus, payload: val } );
      };
    },
  };

  const setUser = bindActionCreators( updateReducer, dispatch );

  const enableLoading = () =>
  {
    setLoading( true );
  };

  const disableLoading = () =>
  {
    setLoading( false );
  };

  const getInputClasses = ( fieldname ) =>
  {
    if ( formik.touched[ fieldname ] && formik.errors[ fieldname ] )
    {
      return "is-invalid";
    }

    if ( formik.touched[ fieldname ] && !formik.errors[ fieldname ] )
    {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik( {
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async ( values, { setStatus, setSubmitting } ) =>
    {
      enableLoading();
      await new login().getLogin( values.username, values.password, values.perusahaan ).then( ( logindata ) =>
      {
        setUser.auth( logindata.data );
        new getAllMenuSidebar( logindata.data.id_role ).then( ( val ) =>
        {
          setUser.menus( val.data );
        } );
      } );
      disableLoading();
      setSubmitting( false );
    },
  } );

  const loadPerusahaan = async ( val ) =>
  {
    setLoadOption( true );
    setTimeout( () =>
    {
      new login().getAllPerusahaan( val ).then( ( values ) =>
      {
        const data = values.data.map( ( data ) => ( {
          label: data.text,
          value: data.id,
        } ) );
        setoptionsPerusahaan( data );
      } );
      setLoadOption( false );
    }, 2000 );
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <Image
        src={ `${ toAbsoluteUrl( "/assets/undraw-login.png" ) }` }
        thumbnail
        style={ { border: "0", boxShadow: "0px 0px" } }
      />

      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h2">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your username and password
        </p>
      </div>

      <form
        onSubmit={ formik.handleSubmit }
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {/* {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
            <div className="alert-text ">
              Use account <strong>admin@demo.com</strong> and password{" "}
              <strong>demo</strong> to continue.
            </div>
          </div>
        )} */}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Username"
            type="text"
            className={ `form-control form-control-solid h-auto py-5 px-6 ${ getInputClasses(
              "username"
            ) }` }
            name="username"
            onBlur={ ( e ) =>
            {
              loadPerusahaan( e.target.value );
              formik.setFieldValue( "username", e.target.value );
            } }
          // {...formik.getFieldProps("username")}
          />
          { formik.touched.username && formik.errors.username ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{ formik.errors.username }</div>
            </div>
          ) : null }
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type="password"
            className={ `form-control form-control-solid h-auto py-5 px-6 ${ getInputClasses(
              "password"
            ) }` }
            name="password"
            { ...formik.getFieldProps( "password" ) }
          />
          { formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{ formik.errors.password }</div>
            </div>
          ) : null }
        </div>
        <div className="form-group fv-plugins-icon-container">
          <Select2
            name={ "perusahaan" }
            placeholder={ "--- Select Perusahaan ---" }
            isClearable={ true }
            isLoading={ LoadOption }
            isSearchable={ true }
            onBlur={ () => formik.setFieldTouched( "perusahaan", true ) }
            className={ "is-invalid" }
            options={ optionsPerusahaan }
            onChange={ ( e ) =>
            {
              formik.setFieldValue( "perusahaan", e ? e.value : [] );
            } }
          />
          { formik.touched.perusahaan && formik.errors.perusahaan && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{ formik.errors.perusahaan }</div>
            </div>
          ) }
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={ formik.isSubmitting }
            className={ `btn btn-primary font-weight-bold px-9 py-4 my-3` }
          >
            <span>Sign In</span>
            { loading && <span className="ml-3 spinner spinner-white"></span> }
          </button>
        </div>
      </form>
    </div>
  );
}

export default Login;

import * as Yup from "yup";

import { hapusSeparator, separatorHarga } from "../../services/separator-harga";

export default class OrganisasiModel_ {
  constructor(
    data = {
      id: 0,
      nama: "",
      kode: "",
      alamat: "",
      telepon: "",
      no_rekening: "",
      nama_rekening: "",
      nama_bank: "",
      npwp: "",
      nilai_top:0,
      nilai_limit:"",
      nilai_limit_tersisa:"",
      is_approved:0,
      file_ktp: "",
      file_npwp: "",
      file_pks: "",
      file_situ_siup: "",
      approved_spv_by:0,
      approved_manager_by:0,
    }
  ) {
    this.id = data.id;
    this.nama = data.nama;
    this.kode = data.kode;
    this.alamat = data.alamat;
    this.telepon = data.telepon;
    this.no_rekening = data.no_rekening;
    this.nama_rekening = data.nama_rekening;
    this.nama_bank = data.nama_bank;
    this.npwp = data.npwp;
    this.file_situ_siup = data.file_situ_siup;
    this.file_ktp = data.file_ktp;
    this.file_npwp = data.file_npwp;
    this.file_pks = data.file_pks;
    this.nilai_limit = separatorHarga(data.nilai_limit.toString(), "Rp. ");
    this.nilai_limit_tersisa = data.nilai_limit_tersisa;
    this.nilai_top = data.nilai_top;
    this.is_approved = data.is_approved;
  }

  async getEditable(params = { pageName: "", isForm: false }) {
    return [
      {
        validation: Yup.string().required("Nama harus diisi"),
        dataField: "nama",
        columnHeaderText: "Nama Kategori Produk",
        label: "Nama ",
        type: "disabled-text",
        value: this.nama,
      },
      {
        dataField: "kode",
        columnHeaderText: "Kode Supplier",
        label: "Kode ",
        type: "disabled-text",
        value: this.kode,
      },
      {
        validation: Yup.string().required("Alamat harus diisi"),
        dataField: "alamat",
        columnHeaderText: "Alamat",
        label: "Alamat ",
        type: "disabled-text",
        value: this.alamat,
      },
      {
        validation: Yup.string().required("No. HP harus diisi"),
        dataField: "telepon",
        label: "Telepon ",
        type: "disabled-text",
        value: this.telepon,
      },
      {
        validation: Yup.string(),
        dataField: "no_rekening",
        columnHeaderText: "No. Rekening",
        label: "No. Rekening ",
        type: "disabled-text",
        value: this.no_rekening,
      },
      {
        validation: Yup.string(),
        dataField: "nama_rekening",
        columnHeaderText: "Nama Rekening",
        label: "Nama Rekening ",
        type: "disabled-text",
        value: this.nama_rekening,
      },
      {
        validation: Yup.string(),
        dataField: "nama_bank",
        columnHeaderText: "Nama Bank",
        label: "Nama Bank ",
        type: "disabled-text",
        value: this.nama_bank,
      },
      {
        validation: Yup.string(),
        dataField: "npwp",
        columnHeaderText: "NPWP",
        label: "NPWP ",
        type: "disabled-text",
        value: this.npwp,
      },
      {
        validation: Yup.string(),
        dataField: "nilai_top",
        columnHeaderText: "Nilai TOP",
        label: "Nilai TOP",
        type: "disabled-text",
        value: `${this.nilai_top} Hari`,
      },
      {
        dataField: "nilai_limit",
        label: "Nilai Limit",
        type: "disabled-text",
        value: this.nilai_limit,
        onChange: (e, setFieldValue) => {
          this.nilai_limit =  e.target.value
          this.nilai_limit_tersisa = parseInt(hapusSeparator(e.target.value))
          setFieldValue("nilai_limit_tersisa", this.nilai_limit_tersisa)
        },
      },
    ];
  }
}

import React, { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Detail from "./detail";
import ListApprovalPesananPembelian from "./approval_pembelian";
import PesananPembelianModel from "../../../models/pesanan-pembelian-model/PesananPembelianModel";

export default function ApprovalPesananPembelian() {
  const [dataFromModel, setDataFromModel] = useState(new PesananPembelianModel());
  const history = useHistory();

  return (
    <Switch>
      <Route path="/approval-pesanan-pembelian/list">
        <ListApprovalPesananPembelian setDataFromModel={setDataFromModel} />
      </Route>
      <Route path="/approval-pesanan-pembelian/detail">
        <Detail history={history} dataFromModel={dataFromModel} isEdit={true} />
      </Route>
      <Redirect to="/approval-pesanan-pembelian/list" />
    </Switch>
  )
  }
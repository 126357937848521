import * as Yup from "yup";

export default class ProdukSatuanModel {
  constructor(data = { id: 0, nama: "" }) {
    this.id = data.id;
    this.nama = data.nama;
  }

  async getEditable(params = { pageName: "", isForm: false }) {
    return [
      {
        validation: Yup.string().required("Nama harus diisi"),
        dataField: "nama",
        columnHeaderText: "Nama Satuan Produk",
        label: "Nama " + params.pageName,
        type: "text",
        value: this.nama,
      },
    ];
  }
}

import { hapusSeparator, separatorHarga } from "../../../services/separator-harga";
import moment from "moment";
import pengeluaranKasDetailModel from "./pengeluaranKasDetailModel";
import store from "../../../redux/store";

export default class pengeluaranKasModel {
  constructor(
    data = {
      transaksi: {
        id: 0,
        no_transaksi: "",
        no_transaksi_bku: "",
        id_transaksi_jenis: 1,
        cabang: "",
        kontak_ref: "",
        tanggal: "",
        tanggal_approved: "",
        akun: "",
        total: "",
        deskripsi: "",
        id_user: store.getState().auth['id'],
        is_approved: 0,
        status: 0,
      },
      detail: [],
    }
  ) {
    const { id, no_transaksi, tanggal, no_transaksi_bku, id_transaksi_jenis, tanggal_approved, cabang, kontak_ref, akun, total, deskripsi, id_user, is_approved, status } = data['transaksi'];
    this['transaksi'] = {
      id: id,
      no_transaksi: no_transaksi,
      no_transaksi_bku: no_transaksi_bku,
      id_transaksi_jenis: id_transaksi_jenis,
      cabang: cabang,
      kontak_ref: kontak_ref,
      tanggal: tanggal,
      tanggal_approved: tanggal_approved,
      akun: akun,
      total: total,
      deskripsi: deskripsi,
      id_user: id_user,
      is_approved: is_approved,
      status: status,
    };
    this.detail = [];
    this.initDetails(data['detail']);
  }
  initDetails(detail) {
    if (detail.length > 0) {
      detail.forEach((item) => {
        this.detail = [
          ...this['detail'],
          new pengeluaranKasDetailModel({
            ...item,
          }),
        ];
      });
    }
  }
  // Handle Detail
  handleDetail = {
    // Set Data Detail Modal
    set: async (item) => {
      const setSetails = this['detail'].filter((params) => { return params['status'] !== 0 });
      item['id'] = setSetails.length + 1;
      item['jumlah'] = parseInt(hapusSeparator(item['jumlah']));
      this['detail'] = [...setSetails, item];
    },
    // Delete Detail Modal
    del: async (dataModel) => {
      this['detail'].map((val, i) => {
        if (val['id'] === dataModel['id']) {
          this['detail'][i]['status'] = 0;
        }
        return val;
      });
      const delDetails = this['detail'].filter((params) => { return params['status'] !== 0 });
      this['detail'] = [...delDetails];
    },
    // Reset Detail Data
    reset: async () => {
      this['detail'].map((val, i) => {
        if (val['status'] === 1) {
          this['detail'][i]['status'] = 0;
        }
        return val;
      });
      const resetdetails = this['detail'].filter((params) => { return params['status'] !== 0 });
      this['detail'] = [...resetdetails];
    },
    // Edit Details Modal
    edit: async (item) => {
      this['detail'][this['detail'].map((e) => e['id']).indexOf(item['id'])] = item;
    },
  };

  async getdata() {
    const { no_transaksi, no_transaksi_bku, cabang, kontak_ref, tanggal, tanggal_approved, akun, total, deskripsi } = this['transaksi'];
    return [
      {
        id: "no_transaksi",
        title: "No. Transaksi",
        label: no_transaksi,
        center: true,
        sortable: true,
        minWidth: "25%",
      }, {
        id: "tanggal",
        title: "Tanggal",
        label: moment(tanggal).format("DD-MM-YYYY"),
        center: true,
        sortable: true,
        minWidth: "17%",
      }, {
        id: "no_bku",
        title: "No. Bukti BKU",
        label: no_transaksi_bku,
        center: true,
        sortable: true,
        minWidth: "25%",
      }, {
        id: "tanggal_approved",
        title: "Tanggal Approved",
        label: tanggal_approved !== "" ? moment(tanggal_approved).format("DD-MM-YYYY") : "",
        center: true,
        sortable: true,
        minWidth: "17%",
      }, {
        id: "id_perusahaan",
        title: "Cabang",
        label: cabang,
        center: true,
        sortable: true,
        minWidth: "20%",
      }, {
        id: "kontak_ref",
        title: "Kepada",
        label: kontak_ref,
        // center: true,
        sortable: true,
        minWidth: "30%",
      }, {
        id: "akun",
        title: "Akun",
        label: akun,
        center: true,
        sortable: true,
        minWidth: "15%",
      }, {
        id: "total",
        title: "Total",
        label: total < 0 ? `Rp. -${separatorHarga(total.toString())}` : separatorHarga(total.toString(), "Rp. "),
        right: true,
        sortable: false,
        minWidth: "15%",
      }, {
        id: "deskripsi",
        title: "Keterangan",
        label: deskripsi,
        sortable: true,
        minWidth: "60%",
      },
    ];
  }
}

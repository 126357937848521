import { Modal } from "react-bootstrap";
import React, { useState } from "react";

import { Form } from "formik";
import { Button } from "react-bootstrap";
import ButtonLoad from "../button-loading/buttonLoad";

export function ModalForms({
  show,
  title,
  size,
  onHide,
  confirmNameButton,
  onConfirm,
  variant,
  content,
}) {
  const [loading, setloading] = useState(false);
  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={() => { if (onHide != null) onHide(); }}
        aria-labelledby={"contained-modal-title-vcenter"}
        size={size ?? undefined}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className={"form form-label-right"}>
            <div className={"form-group row"}>
              <div className={"col-lg-12"} style={{ marginBottom: "-36px" }} >
                {content}
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <button type={"button"}
            onClick={onHide}
            className={"btn btn-light-danger font-weight-bold mx-1 btn-sm"}
          > Batal
          </button>
          <ButtonLoad
            label={"Simpan"}
            disabled={loading}
            pending={loading}
            classNameIcon={"fas fa-save"}
            className={"btn btn-light-primary font-weight-bold mr-2 mx-1 btn-sm"}
            onClick={() => {
              setloading(true);
              setTimeout(() => {
                if (onConfirm !== null) onConfirm();
                setloading(false);
              }, 150);
            }}
          />
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export function ModalFormsDetailTable({ show, title, onHide, content, onConfirm }) {
  const [loading, setloading] = useState(false);
  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={() => { if (onHide != null) onHide(); }}
        aria-labelledby={"contained-modal-title-vcenter"}
        size={"xl"}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={"form-group row"}>
            <div className={"col-lg-12"} style={{ marginBottom: "-36px" }} >
              {content}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant={"danger"} className={"btn btn-light-danger font-weight-bold btn-sm"} onClick={onHide}>
            {"Close"}
          </Button>
          {onConfirm ?? null ? (
            <ButtonLoad
              label={"Simpan"}
              disabled={loading}
              pending={loading}
              classNameIcon={"fas fa-save"}
              className={"btn btn-light-primary font-weight-bold mr-2 mx-1 btn-sm"}
              onClick={() => {
                setloading(true);
                setTimeout(() => {
                  if (onConfirm !== null) onConfirm();
                  setloading(false);
                }, 150);
              }}
            />
          ) :
            null}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

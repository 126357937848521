import { separatorHarga } from "../../services/separator-harga";

export default class KartuPiutangModel {
  constructor(
    data = {
      id: 0,
      id_perusahaan: 0,
      tanggal_invoice: "",
      no_transaksi: "",
      nama_customer: "",
      jatuh_tempo: "",
      total: "",
      terbayar: "",
      tersisa: "",
      sisa_jTempo: "",
    }
  ) {
    this.master = {
      id: data.id,
      id_perusahaan: data.id_perusahaan,
      no_transaksi: data.no_transaksi,
      nama_customer: data.nama_customer,
      tanggal_invoice: data.tanggal_invoice,
      jatuh_tempo: data.jatuh_tempo,
      total: data.total,
      terbayar: data.terbayar,
      tersisa: data.tersisa,
      sisa_jTempo: data.sisa_jTempo,
    }
  }

  jatuhTempo() {
    let date = Math.floor(Math.abs(new Date() - new Date(this.master.jatuh_tempo)) / (1000 * 3600 * 24))
    this.master.sisa_jTempo = new Date(this.master.jatuh_tempo) < new Date().setHours(0, 0, 0, 0) ?
      `${date} hari yang lalu`
      : `${date} hari lagi`
  }

  async getEditable() {
    this.jatuhTempo()
    const { no_transaksi, nama_customer, tanggal_invoice, jatuh_tempo, total, terbayar, tersisa, sisa_jTempo } = this.master;
    return [
      {
        dataField: "tanggal_invoice",
        label: "Tanggal Invoice",
        value: tanggal_invoice,
      },
      {
        dataField: "no_transaksi",
        label: "No Transaksi",
        value: no_transaksi,
      },
      {
        dataField: "nama_customer",
        label: "Nama customer",
        value: nama_customer,
      },
      {
        dataField: "jatuh_tempo",
        label: "Jatuh Tempo",
        value: jatuh_tempo,
      },
      {
        dataField: "total",
        label: "Total",
        value: separatorHarga(total.toString(), "Rp. "),
      },
      {
        dataField: "terbayar",
        label: "Terbayar",
        value: separatorHarga(terbayar.toString(), "Rp. "),
      },
      {
        dataField: "tersisa",
        label: "Tersisa",
        value: separatorHarga(tersisa.toString(), "Rp. "),
      },
      {
        dataField: "sisa_jTempo",
        label: "Sisa Jatuh Tempo",
        value: sisa_jTempo,
      },
    ];
  }
}

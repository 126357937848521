import PesananPembelianDetailModel from "./pesananPenjualanDetailModel";
import moment from "moment";
import { separatorHarga } from '../../../services/separator-harga/index';
import store from "../../../redux/store";
import DetailInvoiceBelumTerbayarModel from "./detailInvoiceBelumTerbayarModel";

export default class PesananPenjualanModel {
  constructor(
    data = {
      master: {
        id: 0,
        id_perusahaan: store.getState().auth.id_perusahaan,
        tanggal: "",
        no_transaksi: "",
        nama_customer: "",
        nama_organisasi: "",
        nama_sales: "",
        jenis_pembayaran: "",
        jatuh_tempo: "",
        qty_total: "",
        diskon_invoice: "",
        hpp_total: "",
        pajak_total: "",
        diskon_total: "",
        subtotal: "",
        total: "",
        limit_tersisa: "",
        deskripsi: "",
        status: "",
      },
      detail: [],
      detailItem: [],
    }
  ) {
    const { id, id_perusahaan, tanggal, no_transaksi, nama_customer, nama_organisasi, nama_sales, jenis_pembayaran, qty_total, diskon_invoice, hpp_total, pajak_total, diskon_total, subtotal, total, jatuh_tempo, limit_tersisa, deskripsi, status } = data['master'];
    this['master'] = {
      id: id,
      id_perusahaan: id_perusahaan,
      tanggal: tanggal,
      no_transaksi: no_transaksi,
      nama_customer: nama_customer,
      nama_organisasi: nama_organisasi,
      nama_sales: nama_sales,
      jenis_pembayaran: jenis_pembayaran,
      qty_total: qty_total,
      diskon_invoice: diskon_invoice,
      hpp_total: hpp_total,
      pajak_total: pajak_total,
      total: total,
      diskon_total: diskon_total,
      subtotal: subtotal,
      jatuh_tempo: jatuh_tempo,
      limit_tersisa: limit_tersisa,
      deskripsi: deskripsi,
      status: status,
    };
    this['detail'] = [];
    this.initDetails(data['detail']);
    this['detailItem'] = [];
    this.initDetailItem(data['detailItem']);
  }

  initDetails(detail) {
    if (detail.length > 0)
      detail.forEach((item) => {
        this['detail'] = [
          ...this['detail'],
          new PesananPembelianDetailModel({
            ...item,
            id_pesanan_penjualan: this['master']['id'],
          }),
        ];
      });
  }
  initDetailItem(detailItem) {
    if (detailItem.length > 0)
      detailItem.forEach((item) => {
        this['detailItem'] = [
          ...this['detailItem'],
          new DetailInvoiceBelumTerbayarModel({
            ...item
          }),
        ];
      });
  }

  async getdata() {
    const { tanggal, no_transaksi, nama_customer, nama_organisasi, nama_sales, jenis_pembayaran, qty_total, total } = this['master'];
    return [{
      id: "tanggal",
      title: "Tanggal",
      label: moment(tanggal).format("DD-MM-YYYY"),
      center: true,
      sortable: true,
      minWidth: "10%",
    }, {
      id: "no_transaksi",
      title: "No. Transaksi",
      label: no_transaksi,
      center: true,
      sortable: true,
      minWidth: "20%",
    }, {
      id: "nama_customer",
      title: "Nama Customer",
      label: nama_customer,
      sortable: true,
      minWidth: "25%",
    }, {
      id: "nama_organisasi",
      title: "Nama Organisasi",
      label: nama_organisasi,
      sortable: true,
      minWidth: "25%",
    }, {
      id: "nama_sales",
      title: "Nama Sales",
      label: nama_sales,
      sortable: true,
      minWidth: "25%",
    }, {
      id: "jenis_pembayaran",
      title: "Jenis Pembayaran",
      label: jenis_pembayaran,
      center: true,
      sortable: true,
      minWidth: "15%",
    }, {
      id: "qty_total",
      title: "QTY Total",
      label: qty_total,
      center: true,
      sortable: true,
      minWidth: "15%",
    }, {
      id: "total",
      title: "Total",
      label: total < 0 ? `Rp. -${separatorHarga(total.toString())}` : separatorHarga(total.toString(), "Rp. "),
      right: true,
      sortable: false,
      minWidth: "15%",
    }];
  }
}

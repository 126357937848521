import XlsxPopulate from "xlsx-populate";
import moment from "moment";
import { saveAs } from "file-saver";
import { separatorHarga } from "../../../../services/separator-harga";

// Get Sheet Data Export To Excel
function getSheetData(data, header) {
  var fields = Object.keys(data[0]);
  var sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
}

// Export To Excell
function saveAsExcel(data, title) {
  console.log('Data For Excel', data);
  let currDate = moment().format('YYYY-MM-DD')
  let dataList = [];
  console.log("Data",data);
  data.map((data, i) => {
    let op = data.penjualan;
    let target = data.nilai_target;
    dataList.push({
      Nama: data.name,
      January: `${separatorHarga(op[0].toString(), 'Rp.')} / ${separatorHarga(target[0].toString(), 'Rp.')}` || "0",
      February: `${separatorHarga(op[1].toString(), 'Rp.')} / ${separatorHarga(target[1].toString(), 'Rp.')}` || "0",
      Maret: `${separatorHarga(op[2].toString(), 'Rp.')} / ${separatorHarga(target[2].toString(), 'Rp.')}` || "0",
      April: `${separatorHarga(op[3].toString(), 'Rp.')} / ${separatorHarga(target[3].toString(), 'Rp.')}` || "0",
      Mei: `${separatorHarga(op[4].toString(), 'Rp.')} / ${separatorHarga(target[4].toString(), 'Rp.')}` || "0",
      Juni: `${separatorHarga(op[5].toString(), 'Rp.')} / ${separatorHarga(target[5].toString(), 'Rp.')}` || "0",
      Juli: `${separatorHarga(op[6].toString(), 'Rp.')} / ${separatorHarga(target[6].toString(), 'Rp.')}` || "0",
      Agustus: `${separatorHarga(op[7].toString(), 'Rp.')} / ${separatorHarga(target[7].toString(), 'Rp.')}` || "0",
      September: `${separatorHarga(op[8].toString(), 'Rp.')} / ${separatorHarga(target[8].toString(), 'Rp.')}` || "0",
      Oktober: `${separatorHarga(op[9].toString(), 'Rp.')} / ${separatorHarga(target[9].toString(), 'Rp.')}` || "0",
      November: `${separatorHarga(op[10].toString(), 'Rp.')} / ${separatorHarga(target[10].toString(), 'Rp.')}` || "0",
      Desember: `${separatorHarga(op[11].toString(), 'Rp.')} / ${separatorHarga(target[11].toString(), 'Rp.')}` || "0",
    });
    return data;
  });
  let header = [
    'Nama',
    'January',
    'Februari',
    'Maret',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Agustus',
    'September',
    'Oktober',
    'November',
    'Desember'];
  let dataLength = data.length + 1;
  XlsxPopulate.fromBlankAsync().then(async (workbook) => {
    const sheet1 = workbook.sheet(0);
    const sheetData = getSheetData(dataList, header);
    const totalColumns = sheetData[0].length;
    sheet1.cell("A1").value(sheetData);
    const range = sheet1.usedRange();
    const endColumn = String.fromCharCode(64 + totalColumns);
    sheet1.row(1).style("bold", true);
    sheet1.range("A1:" + endColumn + "1").style({
      fill: "BFBFBF",
      horizontalAlignment: "center",
    });
    // Styls Center Align
    let centerAlign = ["A", "B", "C", "D", "E", "F","G", "H", "I", "J", "K", "L", "M"];
    for (let i = 0; i < centerAlign.length; i++) {
      sheet1.range(centerAlign[i] + "2:" + centerAlign[i] + dataLength).style({
        horizontalAlignment: "center"
      });
      
    }
    range.style("border", true);
    sheet1.column("A").width(40);
    sheet1.column("B").width(23);
    sheet1.column("C").width(23);
    sheet1.column("D").width(23);
    sheet1.column("E").width(23);
    sheet1.column("F").width(23);
    sheet1.column("G").width(23);
    sheet1.column("H").width(23);
    sheet1.column("I").width(23);
    sheet1.column("J").width(23);
    sheet1.column("K").width(23);
    sheet1.column("L").width(23);
    sheet1.column("M").width(23);
    return workbook.outputAsync().then((res) => {
      saveAs(res, "Laporan (" + title + " " + currDate + ").xlsx");
    });
  });
}

export { saveAsExcel, getSheetData }
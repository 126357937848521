import { getCoverageArea, getIdPerusahaan, simpleFetch } from "./api";
export class StokOpnameAPI {
  async getOpname(values) {
    console.log('VALUES API', values);
    let params = "id_perusahaan=";
    // if (values.length !== 1) {
    // params += values.join("&id_perusahaan=");
    // } else {
    params += values;
    // }
    let res = await simpleFetch({
      url: "retail/stok/opname?" + params,
      method: "GET",
    });
    return res;
  }

  async set(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/stok/opname",
        method: "POST",
        body: {
          master: {
            ...values,
          }
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async setDetail(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/stok/opname-detail",
        method: "POST",
        body: {
          master: {
            ...values.master
          },
          detail: values.data
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async getDetail(values) {
    let res;
    try {
      res = await simpleFetch({
        url: `retail/stok/opname-detail?id_opname=${values}`,
        method: "GET",
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async getAllDetail(val) {
    let res;
    try {
      res = await simpleFetch({
        url: `retail/stok/all-opname-detail?id_perusahaan=${val}`,
        method: "GET",
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async closeOpname(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/stok/close-opname",
        method: "POST",
        body: {
          master: {
            ...values.master
          },
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async validasiDetailOpname(values) {
    console.log("Api Val",values);
    let res;
    try {
      res = await simpleFetch({
        url: "retail/stok/validasi-opname-detail",
        method: "POST",
        body: {
          master: {
            ...values
          },
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }
  async getCoverage() {
    let res = await simpleFetch({
      url: "retail/select/perusahaan?id_coverage=" + getCoverageArea(),
      method: "GET",
    });
    return res;
  }
}

import { CardBody, CardFooter, CardHeader } from "../../../../../_metronic/_partials/controls";
import React, { Component } from "react";
import * as Yup from "yup";
import { AnimatedCard } from "../../../../components/animated-card/animatedCard";
import DataTableModel from "../../../../models/datatable-model/DataTableModel";
import { Formik } from "formik";
import { FormsFields } from "../../../../components/forms-fields/FormsFields";
import { ModalForms } from "../../../../components/modal-forms/modalForms";
import { ReactDatatable } from "../../../../components/react-datatable/reactDatatable";
import { dispatcher } from "../../../../redux/dispatcher";
import { getIdPerusahaan } from "../../../../api/api";
import SaldoAwalBankModel from "../../../../models/akuntansi/saldo-awal/saldo-awal-bank/saldoAwalBankModel";
import { akuntansiAPI } from "../../../../api/akuntansi";
import ButtonActions from "../../../../components/button-actions/buttonActions";
import ButtonLoad from "../../../../components/button-loading/buttonLoad";
import { export_excel } from "../../../../components/export-excel/export_excel";
import store from "../../../../redux/store";

export default class SaldoAwalBank extends Component
{
  constructor( props )
  {
    super( props );
    this[ 'pageName' ] = "Saldo Awal Bank";
    this[ 'searchParameter' ] = [ "nama_bank", "no_rekening", "jenis_bank", "cabang", "saldo_awal" ];
    this[ 'apiSaldoAwalBank' ] = new akuntansiAPI();
    this[ 'initialData' ] = new SaldoAwalBankModel();
    this[ 'export_excel' ] = new export_excel();
    this[ 'options_cabang' ] = [];
    this[ 'state' ] = {
      pending: true,
      initialValues: {
        id_perusahaan: getIdPerusahaan(),
        nama_cabang: `${ store.getState().auth[ 'toPerusahaan' ][ 'singkat' ] } - ${ store.getState().auth[ 'toPerusahaan' ][ 'lokasi' ] }`,
      },
      data: [],
      modal: {
        show: false,
        initialData: this[ 'initialData' ],
        isEdit: false,
      }
    };
    this[ 'datatable' ] = [ { id: "", no: "", name: "" } ];
    this[ 'columntable' ] = [ { id: "", title: "" } ];
  }
  //** Start: componentDidMount */
  async componentDidMount ()
  {
    // Get Cabang
    this[ 'apiSaldoAwalBank' ].getCoverage().then( ( values ) =>
    {
      this[ 'options_cabang' ] = values[ 'data' ].map( ( data ) => ( {
        label: data[ 'text' ],
        value: data[ 'id' ],
      } ) );
    } );
    await this.LoadData( this[ 'state' ][ 'initialValues' ] );
  }
  //** End: componentDidMount */
  //** ----------------------------------------------------------------------------------- */
  //** Start: Load data */
  async LoadData ( form )
  {
    this.setState( { pending: true } );
    let data = await this[ 'apiSaldoAwalBank' ].getAllSaldoAwalBank( form );
    this.setState( { data: data[ 'data' ] } );
    this[ 'DataTableModel' ] = new DataTableModel();
    data[ 'data' ].map( ( item, i ) =>
    {
      let dataModel = new SaldoAwalBankModel( item );
      if ( item[ 'status' ] === 1 )
      {
        this[ 'DataTableModel' ].add( {
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: (
            <>
              <ButtonActions
                label={ "edit" }
                onClick={ async () =>
                  await this[ 'handleModal' ].edit( dataModel ) }
              />
            </>
          ),
        } );
      }
      return item;
    } );
    this[ 'columntable' ] = await this[ 'DataTableModel' ].getColumn();
    this[ 'datatable' ] = await this[ 'DataTableModel' ].getDatas();
    this.setState( { ...this[ 'state' ], pending: false } );
  }
  //** End: Load data */
  //** ----------------------------------------------------------------------------------- */
  //** Start: handleModal */
  handleModal = {
    edit: async ( dataFromModel ) =>
    {
      let state = { ...this[ 'state' ] };
      state = {
        modal: {
          show: true,
          title: `Saldo Awal Bank : ${ dataFromModel[ 'nama_bank' ] }`,
          isEdit: true,
          initialData: dataFromModel
        }
      }
      this.setState( { ...state } );
    },
    // Hide Modal
    hide: () =>
    {
      let modal = { ...this[ 'state' ].modal };
      modal[ 'show' ] = false;
      this.setState( { modal } );
    }
  };
  //** End: handleModal */
  //** ----------------------------------------------------------------------------------- */
  render ()
  {
    const { pending, initialValues, data, modal } = this[ 'state' ];
    return (
      <>
        {/* Start: Form List */ }
        <Formik
          enableReinitialize={ true }
          initialValues={ initialValues }
          validationSchema={ Yup.object().shape( {
            id_perusahaan: Yup.string().required( "Cabang harus diisi" ),
          } ) }
          onSubmit={ ( values ) =>
          {
            this.LoadData( values );
          } }
        >
          { ( { setFieldValue, setFieldTouched, touched, errors, values, handleSubmit } ) => (
            <AnimatedCard>
              <CardHeader title={ this[ 'pageName' ] }></CardHeader>
              <CardBody style={ { marginTop: "-20px" } }>
                <div className={ "form-group row" }>
                  {/* Forms Select Cabang */ }
                  <FormsFields
                    id={ "id_perusahaan" }
                    type={ "select2" }
                    label={ "Cabang" }
                    style={ { marginTop: "20px" } }
                    className={ "col-lg-4" }
                    isClearable={ true }
                    isSearchable={ true }
                    options={ this[ 'options_cabang' ] }
                    onChange={ ( e ) =>
                    {
                      setFieldValue( "nama_cabang", e ? e[ 'label' ] : "" );
                    } }
                    paramsFormik={ {
                      setFieldValue,
                      setFieldTouched,
                      touched,
                      errors,
                      values
                    } }
                  />
                </div>
                <div style={ { display: "flex", justifyContent: "center" } } >
                  {/* Button Lihat Data */ }
                  <ButtonLoad
                    label={ "Lihat Data" }
                    pending={ pending }
                    classNameIcon={ "fa fa-filter" }
                    className={ "btn btn-outline-success" }
                    onClick={ async () => handleSubmit() }
                  />
                  <ButtonLoad
                    label={ "Export .xls" }
                    pending={ pending }
                    classNameIcon={ "far fa-file-excel" }
                    className={ "btn btn-outline-primary" }
                    disabled={ data.length === 0 }
                    onClick={ async () =>
                    {
                      let record = [];
                      data.map( ( val, i ) =>
                      {
                        const { nama_bank, no_rekening, jenis_bank, cabang, saldo_awal } = val;
                        record.push( {
                          nama_bank: nama_bank,
                          no_rekening: no_rekening,
                          jenis_bank: jenis_bank,
                          cabang: cabang,
                          saldo_awal: saldo_awal,
                        } );
                        return val;
                      } );

                      let datas = {
                        headerTitle: "SALDO AWAL BANK",
                        title1: "",
                        title2: "",
                        headerField: [ "NAMA BANK", "NO REKENING", "JENIS BANK", "CABANG", "SALDO AWAL" ],
                        headerField2: [],
                        recordData: record,
                        recordData2: [],
                        totalSaldoAwal: false,
                        totalSaldoAkhir: false,
                        footerSubTotal: [],
                        footerSubTotal1: [],
                        columnWidth: [ 35, 25, 35, 23, 20 ],
                        centerAlign: [ "B", "C", "D" ],
                        wrapText: [],
                        numberFormatRp: [ "E" ],
                        fileName: `Saldo Awal Bank`
                      }
                      this[ 'export_excel' ].saveAsExcel( datas );
                    } }
                  />
                </div>
              </CardBody>
              <CardFooter>
                <ReactDatatable
                  title={ null }
                  columns={ this[ 'columntable' ] }
                  data={ this[ 'datatable' ] }
                  searchParameter={ this[ 'searchParameter' ] }
                  progressPending={ pending }
                />
              </CardFooter>
            </AnimatedCard>
          ) }
        </Formik>
        {/* End: Form List */ }
        {/* ---------------------------------------------------------------------------- */ }
        {/* Start: Modal */ }
        <Formik
          enableReinitialize={ true }
          initialValues={ modal[ 'initialData' ] }
          onSubmit={ async ( values ) =>
          {
            const { id, saldo_awal } = values;
            let dataSend = {
              id: id,
              saldo_awal: saldo_awal,
              id_perusahaan: getIdPerusahaan(),
            };
            await this[ 'apiSaldoAwalBank' ].editSaldoAwalBank( dataSend ).then( ( res ) =>
            {
              if ( res[ 'status' ] )
              {
                dispatcher[ 'snackbar' ].show( "Berhasil", res.message, "primary" );
                this[ 'handleModal' ].hide();
                this.LoadData( initialValues );
              } else
              {
                dispatcher[ 'snackbar' ].show( "Gagal", res.message, "danger" );
              }
            } );
          } }
        >
          { ( { handleSubmit, setFieldValue, values } ) => (
            <ModalForms
              show={ modal[ 'show' ] }
              title={ modal[ 'title' ] }
              onHide={ this[ 'handleModal' ][ 'hide' ] }
              onConfirm={ handleSubmit }
              content={
                <div className={ "form-group row" }>
                  {/* Saldo Awal */ }
                  <FormsFields
                    id={ "saldo_awal" }
                    type={ "currency" }
                    label={ "Saldo Awal" }
                    className={ "col-lg-12" }
                    paramsFormik={ {
                      setFieldValue,
                      values
                    } }
                  />
                </div>
              }
            />
          ) }
        </Formik>
        {/* End: Modal */ }
      </>
    );
  }
}

import * as Yup from "yup";
import store from "../../redux/store";
import { OrganisasiAPI } from "../../api/organisasi";
import { separatorHarga } from "../../services/separator-harga";
import { UserAPI } from "../../api/user";

export default class CustomerModel {
  constructor(
    data = {
      id: 0,
      id_perusahaan: store.getState().auth.id_perusahaan,
      id_kontak_jenis: 0,
      id_organisasi: 0,
      kode: "",
      nama: "",
      alias: "",
      ktp: "",
      npwp: "",
      tempat_lahir: "",
      tanggal_lahir: "0000-00-00",
      id_jenis_kelamin: "",
      alamat: "",
      hp: "",
      telepon: "",
      email: "",
      no_rekening: "",
      nama_rekening: "",
      nama_bank: "",
      nilai_limit: 0,
      nilai_top: 0,
      id_agama: 0,
      id_provinsi: 0,
      id_kab_kota: 0,
      id_kelurahan_desa: 0,
      id_sales: 0,
      nama_sales: "",
      is_approved: 0,
      approved_spv_by: 0,
      approved_manager_by: 0,
      status: 0,
      is_member: 0,
      ktp_image: "",
      kk: "",
      toOrganisasi: {
        nama: "",
        nilai_limit: 0,
        nilai_top: 0,
      },
    }
  ) {
    this.id = data.id;
    this.id_perusahaan = data.id_perusahaan;
    this.id_kontak_jenis = data.id_kontak_jenis;
    this.id_organisasi = data.id_organisasi;
    this.kode = data.kode;
    this.nama = data.nama;
    this.alias = data.alias;
    this.ktp = data.ktp;
    this.npwp = data.npwp;
    this.tempat_lahir = data.tempat_lahir;
    this.tanggal_lahir = data.tanggal_lahir;
    this.id_jenis_kelamin = data.id_jenis_kelamin;
    this.alamat = data.alamat;
    this.hp = data.hp;
    this.telepon = data.telepon;
    this.email = data.email;
    this.no_rekening = data.no_rekening;
    this.nama_rekening = data.nama_rekening;
    this.nama_bank = data.nama_bank;
    this.id_organisasi = data.id_organisasi;
    this.id_agama = data.id_agama;
    this.id_provinsi = data.id_provinsi;
    this.id_kab_kota = data.id_kab_kota;
    this.id_kelurahan_desa = data.id_kelurahan_desa;
    this.id_sales = data.id_sales;
    this.nama_sales = data.nama_sales;
    this.is_approved = data.is_approved;
    this.approved_spv_by = data.approved_spv_by;
    this.approved_manager_by = data.approved_manager_by;
    this.status = data.status;
    this.is_member = data.is_member;
    this.ktp_image = data.ktp_image;
    this.kk = data.kk;
    this.toOrganisasi = data.toOrganisasi;
    this.nilai_top = data.nilai_top;
    this.nilai_limit = separatorHarga(data.nilai_limit.toString());
    this.nama_organisasi =
      data.toOrganisasi === null ? " - " : data.toOrganisasi.nama;
  }

  async getEditable(params = { pageName: "", isForm: false }) {
    let options_jenis_kelamin = [
      { id: 1, text: "Laki-laki", value: 1 },
      { id: 2, text: "Perempuan", value: 2 },
    ];
    let options_sales = [];
    let options_organisasi = [];

    if (params.isForm) {
      await new OrganisasiAPI().getAll().then((res) =>
        res.data.map(
          (data) =>
          (options_organisasi = [
            ...options_organisasi,
            {
              id: data.id,
              text: data.nama,
              value: data.id,
            },
          ])
        )
      );

      await new UserAPI().getAll().then((res) =>
        res.data.map(
          (data) =>
          (options_sales = [
            ...options_sales,
            {
              id: data.id,
              text: data.nama,
              label: data.nama,
              value: data.id,
            }
          ])
        )
      )

    }
    const getSalesName = async (id) => {
     return await new UserAPI().getSingle(id).then((res) => {
      return res.data
    })
     }
    return [
      {
        dataField: "kode",
        label: "Kode",
        type: "disabled-text",
        value: this.kode,
      },
      {
        validation: Yup.string().required("Nama harus diisi"),
        dataField: "nama",
        label: "Nama",
        type: "text",
        value: this.nama,
        onChange: (e) => (this.nama = e.target.value),
      },
      {
        validation: Yup.string().required("Jenis kelamin harus diisi"),
        dataField: "id_jenis_kelamin",
        label: "Jenis Kelamin",
        type: "select",
        options: options_jenis_kelamin,
        value: this.id_jenis_kelamin,
        onChange: (e) => (this.id_jenis_kelamin = parseInt(e.target.value)),
        invisibleOnTable: true,
      },
      {
        validation: Yup.string(),
        dataField: "tempat_lahir",
        label: "Tempat Lahir",
        type: "text",
        value: this.tempat_lahir,
        invisibleOnTable: true,
        onChange: (e) => (this.tempat_lahir = e.target.value),
      },
      {
        validation: Yup.string().required("Tanggal lahir harus diisi"),
        dataField: "tanggal_lahir",
        label: "Tanggal Lahir",
        type: "date",
        value: this.tanggal_lahir,
        invisibleOnTable: true,
        onChange: (e) => (this.tanggal_lahir = e.target.value),
      },
      {
        validation: Yup.string().required("Alamat harus diisi"),
        dataField: "alamat",
        label: "Alamat",
        type: "text",
        value: this.alamat,
        onChange: (e) => (this.alamat = e.target.value),
      },
      {
        dataField: params.isForm ? "id_sales" : "nama_sales",
        label: "Sales",
        type: "select2",
        value: params.isForm ? this.id_sales : this.nama_sales,
        options: options_sales,
        onChange: (e) => {
          this.id_sales = e ? e.value : ""
          console.log('selected id',this.id_sales);
          getSalesName(e.value).then((val) => {
            this.nama_sales = val.nama
            console.log(this.nama_sales);
          })
          // this.nama_sales = e.target.value
        },
      },
      {
        validation: Yup.string().required("Nama Rekining harus diisi"),
        dataField: "nama_rekening",
        label: "Nama Rekening Pemilik",
        type: "text",
        value: this.nama_rekening,
        onChange: (e) => (this.nama_rekening = e.target.value),
      },
      {
        validation: Yup.string().required("Nomor Rekining harus diisi"),
        dataField: "no_rekening",
        label: "Nomor Rekening",
        type: "text",
        value: this.no_rekening,
        onChange: (e) => (this.no_rekening = e.target.value),
      },
      {
        validation: Yup.string().required("Bank harus diisi"),
        dataField: "nama_bank",
        label: "Bank",
        type: "text",
        value: this.nama_bank,
        onChange: (e) => (this.nama_bank = e.target.value),
      },
      {
        validation: Yup.string().required("KTP/SIM harus diisi"),
        dataField: "ktp",
        label: "KTP/SIM",
        type: "text",
        value: this.ktp,
        invisibleOnTable: true,
        onChange: (e) => (this.ktp = e.target.value),
      },
      {
        validation: Yup.string().required("No. HP harus diisi"),
        dataField: "hp",
        label: "No. HP",
        type: "text",
        value: this.hp,
        invisibleOnTable: true,
        onChange: (e) => (this.hp = e.target.value),
      },
      {
        validation: null,
        dataField: "id_organisasi",
        label: "Organisasi",
        type: "select",
        options: options_organisasi,
        value: this.nama_organisasi,
        onChange: (e) => {
          console.log('cek organisi', this.toOrganisasi);
          this.id_organisasi = parseInt(e.target.value)
        },
      },
      {
        validation: null,
        dataField: "nilai_top",
        label: "Limit T-O-P",
        type: "hidden",
        value: `${this.toOrganisasi === null ? 'No Limit' : this.toOrganisasi.nilai_top + 'hari'}`,
        onChange: (e) => {
          this.nilai_top = e.target.value
        },
      },
      {
        validation: null,
        dataField: "is_member",
        label: "Status Member",
        type: "hidden",
        value: this.is_member,
        invisibleOnTable: true,
        onChange: (e) => {

        },
      },
      {
        validation: null,
        dataField: "nilai_limit",
        label: "Limit Order",
        type: "hidden",
        value: `${this.toOrganisasi === null ? 'No Limit' : separatorHarga(this.toOrganisasi.nilai_limit.toString())}`,
        onChange: (e) => (this.nilai_limit = e.target.value),
      },
      {
        validation: null,
        dataField: "ktp_image",
        label: "KTP",
        type: "image",
        value: this.ktp_image,
        onChange: (e) => (this.ktp_image = e.target.files[0]),
        invisibleOnTable: true,
      },
      {
        validation: null,
        dataField: "kk",
        label: "Kartu Keluarga",
        type: "image",
        value: this.kk,
        onChange: (e) => (this.kk = e.currentTarget.files[0]),
        invisibleOnTable: true,
      },

    ];
  }
}

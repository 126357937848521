import React, { useEffect, useState } from "react";
import {
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import Model from "../../../models/retur-penjualan-model/ReturPenjualanModel";
import { useHistory, Link } from "react-router-dom";
import { dispatcher } from "../../../redux/dispatcher";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import moment from "moment";
import { PenerimaanAPI } from "../../../api/penerimaan";
import Select2 from "react-select";
import store from "../../../redux/store";
import { Button } from "react-bootstrap";
import { PenjualanAPI } from "../../../api/penjualan";

export default function List(params) {
  const history = useHistory();
  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const pageName = "Approval Retur Penjualan";
  const [startDate, setStartDate] = useState(
    moment(new Date())
      .startOf("month")
      .format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [search, setSearch] = useState("");
  const [option, setOptions] = useState({
    value: store.getState().auth.id_perusahaan,
    text: "--- Pilih Cabang ---",
  });
  const [cabang, setCabang] = useState([]);
  let options = [];

  const coverage = async () => {
    await new PenerimaanAPI().getCoverage().then((res) => {
      options = res.data.map((data) => ({
        label: data.text,
        text: data.text,
        value: data.id,
      }));
    });
    setCabang(options);
  };


  // useEffect(() => {
  //   loadData()
  // },[options])

  // console.log('cek options',option_cabang);
  // console.log(coverage());

  async function loadData() {
    let detail = await new PenjualanAPI().getAllRetur();
    let datas = await new PenjualanAPI().showRetur();
    let dataMaster = datas.data;
    let simpleTableModel = new SimpleTableModel();
    let searchParams = ["no_transaksi", "total", "kode"];
    let filtered = dataMaster.filter((filter) => {
      let dateFormat = moment(filter.created_at).format("YYYY-MM-DD");
      let dateFilter = dateFormat >= startDate && dateFormat <= endDate;
      let cabangSelected = filter.id_perusahaan === option.value;
      return searchParams.some((item) => {
        return (
          dateFilter &&
          cabangSelected &&
          // console.log(filter.master.toSupplier.nama)
          filter[item]
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())
        );
      });
    });
    const actions = (dataModel) => {
      return (
        <>
         {dataModel.is_approved >= 2 ? (
            <Button size="sm" className="mx-1" variant="primary"
            onClick={async () => {
              let filter = detail.data.filter((val) => val.master.id === dataModel.id_penjualan)
              params.setDataDetail(filter)
              params.setDataFromModel(dataModel);
              history.push("/approval-retur-penjualan/detail");
            }}
            >
              <i className="fas fa-user-check"></i>
              Sudah Approve
            </Button>
          ) : (
            <span>
              <Link
                className="mx-1 btn btn-warning btn-sm"
                to="/approval-retur-penjualan/detail"
                onClick={() => {
                  let filter = detail.data.filter((val) => val.master.id === dataModel.id_penjualan)
                  params.setDataFromModel(dataModel);
                  params.setDataDetail(filter)
                }}
              >
                <i className="fas fa-user-lock"></i>
                Belum Approve
              </Link>
            </span>
          )}
        </>
      );
    };

    filtered.map((item, i) => {
      let dataModel = new Model(item);
      console.log(dataModel);
      // if (item.status === 1)
        simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: actions(dataModel),
        });
    });

    setColumntable(await simpleTableModel.getColumnHeaders());
    setDatatable(await simpleTableModel.getDatatables());
  }

  useEffect(() => {
  var sesDate = sessionStorage.getItem("sesDate");
  if (sesDate == null) {
    // Initialize page views count
    sesDate = startDate;
  } 
  if (sesDate !== null ) {
    setStartDate(sesDate)
  }
  
  // Update session storage
    loadData();
    coverage();
  }, [option, startDate, endDate]);

  return (
    <>
      <AnimatedCard>
        <CardHeader title={pageName}></CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Mulai </label>
              <input
                name={"tanggal_awal"}
                type={"date"}
                value={startDate}
                className={"form-control"}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  sessionStorage.setItem("sesDate", e.target.value);
                  loadData();
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Akhir </label>
              <input
                name={"tanggal_akhir"}
                type={"date"}
                value={endDate}
                className={"form-control"}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  loadData();
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Pilih Perusahaan </label>
              <Select2
                name={"Cabang"}
                placeholder={option.text}
                size="sm"
                options={cabang}
                isClearable={true}
                value={
                  options
                    ? options.find(
                      (option) => option.value === option
                    )
                    : ""
                }
                onChange={(e) => {
                  console.log('cek e', e);
                  setOptions({ value: e ? e.value : [] });
                  dispatcher.penerimaan.list();
                  // setFieldValue("cabang", e ? e.value : "");
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="col-lg-3" style={{ marginTop: "20px" }}>
              <input
                type={"text"}
                className={"form-control"}
                placeholder="Cari Data ..."
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  dispatcher.penerimaan.list();
                }}
              />
            </div>
          </div>
          <SimpleTable data={datatable} columns={columntable} />
        </CardBody>
      </AnimatedCard>
    </>
  );
}

import { getCoverageArea, getIdPerusahaan, simpleFetch } from "./api";

import store from "../redux/store";

export class PenerimaanAPI {
  async getAll() {
    let coverage = store.getState().auth.coverage;
    let params = "id_perusahaan=";
    if (coverage.length > 0) {
      params += coverage.join("&id_perusahaan=");
    } else {
      params += getIdPerusahaan();
    }
    let res = await simpleFetch({
      url: "retail/pembelian/inbound?" + params,
      method: "GET",
    });
    return res;
  }

  async getAllLaporan(values) {
    let params = "id_perusahaan=";
    if (values.length !== 1) {
      params += values.join("&id_perusahaan=");
    } else {
      params += values;
    }
    let res = await simpleFetch({
      url: "retail/pembelian/inbound?" + params,
      method: "GET",
    });
    return res;
  }

  async getAllHutang(data) {
    let params = "id_perusahaan=";
    params += data.cabang;
    let res = await simpleFetch({
      url: `retail/kartu-hutang?status_bayar=${data.status}&` + params,
      method: "GET",
    });
    return res.data;
  }

  async getAllRetur() {
    let coverage = store.getState().auth.coverage;
    let params = "id_perusahaan=";
    if (coverage.length > 0) {
      params += coverage.join("&id_perusahaan=");
    } else {
      params += getIdPerusahaan();
    }
    let res = await simpleFetch({
      url: "retail/pembelian/inbound/retur?" + params,
      method: "GET",
    });
    return res;
  }

  async getSingle(id) {
    let coverage = store.getState().auth.coverage;
    let params = "id_perusahaan=";
    if (coverage.length > 0) {
      params += coverage.join("&id_perusahaan=");
    } else {
      params += getIdPerusahaan();
    }
    let res;
    try {
      res = await simpleFetch({
        url: "retail/pembelian/inbound?" + params + "&id=" + id,
        method: "GET",
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async set(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/pembelian/inbound",
        method: "POST",
        body: {
          id_perusahaan: getIdPerusahaan(),
          ...values,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async showRetur(data) {
    // let coverage = store.getState().auth.coverage;
    let params = "id_perusahaan=";
    // if (coverage.length > 0) {
    //   params += coverage.join("&id_perusahaan=");
    // } else {
    //   params += getIdPerusahaan();
    // }
    params += data;
    let res = await simpleFetch({
      url: "retail/pembelian/retur?" + params,
      method: "GET",
    });
    return res;
  }

  async approveRetur(values) {
    let res = await simpleFetch({
      url: "retail/pembelian/approve-retur",
      method: "POST",
      body: {
        id: values.id,
        id_pembelian: values.id_pembelian,
        id_detail: values.id_detail
      }
    })
    return res;
  }

  async retur(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/pembelian/retur",
        method: "POST",
        body: {
          id_user: store.getState().auth.id,
          ...values,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      console.log('cek res', values)
      return res;
    }
  }

  async edit(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/pembelian/inbound",
        method: "PUT",
        body: {
          id_perusahaan: getIdPerusahaan(),
          ...values,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async validate(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/pembelian/inbound/validasi",
        method: "POST",
        body: {
          id: values.id,
          id_gudang: values.id_gudang,
          qty_inbound: values.qty_inbound
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async invoice(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/pembelian/inbound/invoice",
        method: "POST",
        body: {
          ...values,
          id_perusahaan: getIdPerusahaan(),
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async validasiInvoice(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/pembelian/inbound/validasiInbound",
        method: "POST",
        body: {
          ...values,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async delete(id) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/pembelian/inbound",
        method: "DELETE",
        body: {
          id: id,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async getCoverage() {
    let res = await simpleFetch({
      url: "retail/select/perusahaan?id_coverage=" + getCoverageArea(),
      method: "GET",
    });
    return res;
  }
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { SimpleTable } from "../../../components/table/Table";
import SimpleTableModel from "../../../models/simpletable-model/SimpleTableModel";
import Model from "../../../models/pesanan-penjualan-model/PesananPenjualanModel";
import { Link } from "react-router-dom";
import { dispatcher } from "../../../redux/dispatcher";
import { ButtonEditable } from "./button_editable";
import { AnimatedCard } from "../../../components/animated-card/animatedCard";
import Select2 from "react-select";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";
import { PesananPenjualanAPI } from "../../../api/pesanan-penjualan";
import store from "../../../redux/store";

export default function List() {
  // const options = [
  //   { value: '0', label: 'Belum Approve' },
  //   { value: '2', label: 'Sudah Approve' }
  // ]

  // const [filter, setFilter] = useState({ value: 0, label: 'Belum Approve'});
  // useEffect(() => {
  //   loadData()
  // }, [filter]);
  const [refreshData, setRefresh] = useState(false);
  const [cabang, setCabang] = useState(store.getState().auth.id_perusahaan);
  const [options, setOptions] = useState([]);
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const handleClose = () => {
    setData()
    setShow(false)
  };
  const handleSimpan = () => {
    data.removeSeparator()
    data.removeAllDetailSeparator()
    // console.log(data.master.status_batal)
    data.master.status_batal = 1
    data.master.is_approved = 0
    let api = new PesananPenjualanAPI()
    if (data.master.approved_manager_by > 0){
      api.batalAlokasi(data)
    }
    api.edit(data)
  };
  const handleShow = (data) => {
    setData(data)
    setShow(true)
  }

  const coverage = async () => {
    let opsi = [];
    await new PesananPenjualanAPI().getCoverage().then((res) => {
      opsi = res.data.map((data) => ({
        label: data.text,
        text: data.text,
        value: data.id,
      }));
    });
    // options.push(options)
    setOptions(opsi);
  };

  const [columntable, setColumntable] = useState([{ dataField: "", text: "" }]);
  const [datatable, setDatatable] = useState([{ id: 0, nama: "" }]);
  const pageName = "Pesanan Penjualan";
  const [startDate, setStartDate] = useState(
    moment(new Date())
      .startOf("month")
      .format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [search, setSearch] = useState("");

  async function loadData() {
    let datas = await new PesananPenjualanAPI().getForInvoice(cabang);
    let dataMaster = datas.data;
    console.log("cek data", dataMaster);
    let simpleTableModel = new SimpleTableModel();
    let searchParams = ["no_transaksi", "nama_sales", "nama_pelanggan", "nama_organisasi"];
    let filtered = dataMaster.filter((filter) => {
      let dateFormat = moment(filter.master.tanggal).format("YYYY-MM-DD");
      let dateFilter = dateFormat >= startDate && dateFormat <= endDate;
      // let cabangSelected = filter.master.id_perusahaan === cabang;
      return searchParams.some((item) => {
        return (
          dateFilter &&
          // cabangSelected &&
          filter.master[item]
            .toString()
            .toLowerCase()
            .includes(search.toString().toLowerCase())
        );
      });
    });
    filtered.forEach((item, i) => {
      let appStatus = (item.master.is_approved === 1 && item.master.approved_admin_by !== 0) ? 2 
      : item.master.is_approved === 1 ? 1 : 0;
      console.log(appStatus);
      let dataModel = new Model(item);
      if (item.master.status === 1)
        simpleTableModel.add({
          id: i,
          no: i + 1,
          dataModel: dataModel,
          actions: (
            <>
              {/* <ButtonEditable dataModel={dataModel} /> */}
              {item.master.is_approved >= 2 ? (
                <Link
                  className="mx-1 btn btn-primary btn-sm"
                  title="Sudah Diapprove"
                  to="/penjualan-pesanan-penjualan/detail"
                  onClick={() => {
                    dispatcher.pesanan_penjualan.set(dataModel);
                  }}
                >
                  <i className="fas fa-user-check"></i>
                </Link>
                // <Button size="sm" className="mx-1" variant="primary" title="Sudah Diapprove">
                //   <i className="fas fa-user-check"></i>
                // </Button>
              ) : item.master.status_reject === 1
                ? (
                  <Link
                    className="mx-1 btn btn-danger btn-sm"
                    title="Direject"
                    to="/penjualan-pesanan-penjualan/detail"
                    onClick={() => {
                      dispatcher.pesanan_penjualan.set(dataModel);
                    }}
                  >
                    <i className="fas fa-user-lock"></i>
                  </Link>
                )
                : (
                  <Link
                    className="mx-1 btn btn-warning btn-md position-relative"
                    title="Belum Diapprove"
                    to="/penjualan-pesanan-penjualan/detail"
                    onClick={() => {
                      dispatcher.pesanan_penjualan.set(dataModel);
                    }}
                  >
                    <span className="fas fa-user-lock"></span>
                    <span className="label label-sm label-light-danger label-rounded font-weight-bolder position-absolute top-0 right-0 mt-1 mr-1">{appStatus}</span>
                  </Link>
                )
              }
              {item.master.status_invoice === 0 ? (
                <span>
                  <Link
                    className="mx-1 btn btn-outline-success btn-sm"
                    to="/penjualan-pesanan-penjualan/edit"
                    onClick={() => {
                      dispatcher.pesanan_penjualan.set(dataModel);
                    }}
                  >
                    Edit
                  </Link>
                  <Button
                    className="mx-1 btn-outline-danger btn-sm"
                    onClick=
                    {() => {
                      handleShow(dataModel)
                    }}
                  >
                    Batal
                  </Button>
                </span>
              ) : (
                <span>
                  <Button
                    className="mx-1 btn btn-secondary btn-sm"
                    disabled
                  >
                    Edit
                  </Button>
                  <Button
                    className="mx-1 btn btn-secondary btn-sm"
                    disabled
                  >
                    Batal
                  </Button>
                </span>
              )}
            </>
          ),
        });
    });

    setColumntable(await simpleTableModel.getColumnHeaders());
    setDatatable(await simpleTableModel.getDatatables());
  }

  useEffect(() => {
    dispatcher.pesanan_penjualan.list();
  }, [startDate, endDate, cabang, refreshData]);

  useEffect(() => {
    coverage();
    loadData();
  }, [dispatcher.pesanan_penjualan.getList()]);

  return (
    <>
      <AnimatedCard>
        <CardHeader title={pageName}>
          <CardHeaderToolbar>
            {/* {store.getState().auth.alias_role == "admin" && ( */}
            {/* <Link
                className="btn btn-primary"
                to="/penjualan-pesanan-penjualan/new"
                onClick={() => dispatcher.pesanan_penjualan.resetActive()}
              >
                <i className="fa fa-plus"></i>
                Tambah {pageName}
              </Link> */}
            {/* )} */}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Mulai </label>
              <input
                name={"tanggal_awal"}
                type={"date"}
                value={startDate}
                className={"form-control"}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  loadData()
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Tanggal Akhir </label>
              <input
                name={"tanggal_akhir"}
                type={"date"}
                value={endDate}
                className={"form-control"}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  loadData()
                }}
              />
            </div>
            <div className="col-lg-4" style={{ marginTop: "20px" }}>
              <label> Pilih Perusahaan </label>
              <Select2
                name={"Cabang"}
                placeholder={" --- Pilih Perusahaan --- "}
                size="sm"
                options={options}
                isClearable={false}
                value={
                  options
                    ? options.find(
                      (data) => data.value === cabang
                    )
                    : ""
                }
                onChange={(e) => {
                  setCabang(e ? e.value : []);
                  // setFieldValue("cabang", e ? e.value : "");
                }}
              />
            </div>
          </div>
          <div className="col-lg-3" style={{ marginTop: "20px" }}>
            <input
              type={"text"}
              className={"form-control"}
              placeholder="Cari Data ..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                dispatcher.pesanan_penjualan.list();
              }}
            />
          </div>
          <SimpleTable data={datatable} columns={columntable} />
        </CardBody>
        {/* Modal */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Batalkan Pesanan Barang</Modal.Title>
          </Modal.Header>
          <Modal.Body>Yakin mau batalkan transaksi ini ?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Tutup
            </Button>
            <Button variant="primary"
              type="submit"
              onClick={() => {
                handleSimpan()
                handleClose()
                setRefresh(!refreshData)
              }}
            >
              Batalkan SPB
            </Button>
          </Modal.Footer>
        </Modal>
      </AnimatedCard>
    </>
  );
}

import * as Yup from "yup";
import { CustomerAPI } from "../../api/customer";
import { separatorHarga, hapusSeparator } from "../../services/separator-harga";
import store from "../../redux/store";
import { PenjualanAPI } from "../../api/penjualan";
import moment from "moment";
import InvoicePenjualanDetailModel from "./InvoicePenjualanDetailModel";
import { CustomerPembelianAPI } from "../../api/cust-pembelian";

export default class InvoicePenjualanModel {
  constructor(
    data = {
      master: {
        id: 0,
        id_pesanan_penjualan: 0,
        id_perusahaan: 0,
        kode: "",
        no_transaksi: "",
        tanggal: "",
        tanggal_invoice: new Date(),
        jatuh_tempo: "",
        qty_total: 0,
        hpp_total: 0,
        dpp_total: 0,
        diskon_total: 0,
        subtotal: 0,
        pajak_total: 0,
        total: 0,
        dp: 0,
        status_bayar: 0,
        status_invoice: 0,
        status_validasi: 0,
        id_user: store.getState().auth.id,
        id_sales: store.getState().auth.id,
        id_customer: 0,
        id_kontak: 0,
        deskripsi: "",
        status: 0,
        created_at: "",
        update_at: "",
        nama_organisasi:"",
        jenis_pembayaran: 0,
        nama_sales: "",
        diskon_invoice: 0,
        toCustomer: {
          toOrganisasi: {
            nama: "",
          },
          nama: "",
        },
      },
      detail: [],
    }
  ) {
    this.master = {
      id: data.master.id,
      id_pesanan_penjualan: data.master.id,
      id_perusahaan: data.master.id_perusahaan,
      kode: data.master.kode,
      nama_organisasi: data.master.nama_organisasi,
      no_transaksi: data.master.no_transaksi,
      tanggal: data.master.tanggal,
      tanggal_invoice: data.master.tanggal_invoice,
      jatuh_tempo: data.master.jatuh_tempo,
      qty_total: data.master.qty_total,
      hpp_total: separatorHarga(data.master.hpp_total.toString()),
      dpp_total: separatorHarga(data.master.dpp_total.toString()),
      diskon_total: separatorHarga(data.master.diskon_total.toString()),
      subtotal: separatorHarga(data.master.subtotal.toString()),
      pajak_total: separatorHarga(data.master.pajak_total.toString()),
      dp: data.master.dp ? data.master.dp : 0,
      total: separatorHarga(data.master.total.toString()),
      status_bayar: data.master.status_bayar,
      status_invoice: data.master.status_invoice,
      status_validasi: data.master.status_validasi,
      jenis_pembayaran: data.master.jenis_pembayaran,
      id_user: data.master.id_user,
      id_sales: data.master.id_sales,
      nama_sales: data.master.nama_sales,
      id_customer: data.master.id_customer,
      nama_customer: data.master.toCustomer.nama,
      id_kontak: data.master.id_kontak,
      deskripsi: data.master.deskripsi,
      status: data.master.status,
      created_at: data.master.created_at,
      update_at: data.master.update_at,
      diskon_invoice: data.master.diskon_invoice,
    };
    this.detail = [];
    this.initDetails(data.detail);
  }

  initDetails(detail) {
    if (detail.length > 0)
      detail.forEach((item) => {
        let diskon_persen = (item.diskon / item.hpp_total) * 100;
        let pajak_persen = (item.pajak / item.subtotal) * 100;
        this.detail = [
          ...this.detail,
          new InvoicePenjualanDetailModel({
            ...item,
            id_penjualan: this.master.id,
            diskon_persen: diskon_persen,
            pajak_persen: pajak_persen,
          }),
        ];
      });
  }

  api = {
    set: async (callback) => {
      this.removeSeparator();
      this.removeAllDetailSeparator();
      await new PenjualanAPI().set(this).then((val) => callback(val));
    },
    edit: async (callback) => {
      this.removeSeparator();
      this.removeAllDetailSeparator();
      await new PenjualanAPI().edit(this).then((val) => callback(val));
    },
  };

  nulldata = {
    master: {
      id: 0,
      id_pesanan_penjualan: 0,
      id_perusahaan: store.getState().auth.id_perusahaan,
      kode: "",
      no_transaksi: "",
      tanggal: "",
      tanggal_invoice: "",
      jatuh_tempo: "",
      qty_total: 0,
      hpp_total: 0,
      dpp_total: 0,
      diskon_total: 0,
      subtotal: 0,
      pajak_total: 0,
      total: 0,
      status_bayar: 0,
      status_invoice: 0,
      status_validasi: 0,
      id_user: store.getState().auth.id,
      id_sales: 0,
      id_customer: 0,
      id_kontak: 0,
      deskripsi: "",
      status: 0,
      created_at: "",
      update_at: "",
      toCustomer: {
        nama: "",
      },
    },
    detail: [],
  };

  reset() {
    this(this.nulldata);
  }

  hitungTotalan() {
    let qty_total = 0;
    let hpp_total = 0;
    let dpp_total = 0;
    let diskon_total = 0;
    let subtotal = 0;
    let pajak_total = 0;
    let total = 0;
    if (this.detail.length > 0) {
      this.detail.forEach((item) => {
        if (item.status !== 0) {
          qty_total = parseInt(qty_total) + parseInt(item.qty);
          hpp_total =
            parseInt(hpp_total) + parseInt(hapusSeparator(item.hpp_total));
          dpp_total =
            parseInt(dpp_total) + parseInt(hapusSeparator(item.dpp_total));
          diskon_total =
            parseInt(diskon_total) + parseInt(hapusSeparator(item.diskon));
          subtotal =
            parseInt(subtotal) + parseInt(hapusSeparator(item.subtotal));
          pajak_total =
            parseInt(pajak_total) + parseInt(hapusSeparator(item.pajak));
          total = parseInt(total) + parseInt(hapusSeparator(item.total));
        }
      });
    }
    this.master.qty_total = qty_total;
    this.master.hpp_total = separatorHarga(hpp_total.toString());
    this.master.dpp_total = separatorHarga(dpp_total.toString());
    this.master.diskon_total = separatorHarga(diskon_total.toString());
    this.master.subtotal = separatorHarga(subtotal.toString());
    this.master.pajak_total = separatorHarga(pajak_total.toString());
    this.master.total = separatorHarga(total.toString());
  }

  removeSeparator() {
    this.master.hpp_total = parseInt(hapusSeparator(this.master.hpp_total));
    this.master.dpp_total = parseInt(hapusSeparator(this.master.dpp_total));
    this.master.diskon_total = parseInt(
      hapusSeparator(this.master.diskon_total)
    );
    this.master.pajak_total = parseInt(hapusSeparator(this.master.pajak_total));
    this.master.subtotal = parseInt(hapusSeparator(this.master.subtotal));
    this.master.total = parseInt(hapusSeparator(this.master.total));
  }

  removeAllDetailSeparator() {
    this.detail.forEach((item) => item.removeSeparator());
  }

  getOrgTop = async (id) => {
    return await new new CustomerPembelianAPI()
      .getSingle(id)
      .then((res) => {
       return this.valueD = res.data.toOrganisasi ? res.data.toOrganisasi.nilai_top : 0
      })
  };

  jatuh_tempo = async () => {
    await this.getOrgTop(this.master.id_customer)
    let target = new Date(this.master.tanggal_invoice);
    let val = this.valueD;
    this.master.jatuh_tempo = moment(target)
      .add(val, "days")
      .format("YYYY-MM-DD");
  }
  
  handleDetail = {
    set: async (item) => {
      item.id_penjualan = this.master.id;
      item.id_customer = this.master.id_customer;
      item.id_sales = this.master.id_sales;
      this.detail = [...this.detail, item];
      this.hitungTotalan();
    },
    del: async (id) => {
      this.detail[this.detail.map((e) => e.id).indexOf(id)].status = 0;
      this.hitungTotalan();
    },
    reset: async () => {
      this.detail = [];
      this.hitungTotalan();
    },
    edit: async (item) => {
      this.detail[this.detail.map((e) => e.id).indexOf(item.id)] = item;
      this.hitungTotalan();
    },
    getSingle: async (id) => this.detail.find((e) => e.id === id),
  };

  async getEditable(params = { pageName: "", isForm: false }) {
    let customer_options = [];
    let pembayaran_opsi = [
      { id: 1, text: "Cash", label: "Cash", value: 1 },
      { id: 2, text: "Kredit", label: "Kredit", value: 2 },
    ];
    if (params.isForm) {
      // this.getOrgTop(this.master.id_customer).then((val) => {
      //   this.valueD = val
      //   console.log(this.valueD);
      // })
      this.jatuh_tempo()
      // console.log(this.master.id_customer);
      await new CustomerAPI()
        .getAll()
        .then((response) =>
          response.data.map(
            (data, i) =>
              (customer_options = [
                ...customer_options,
                { id: data.id, text: data.nama, value: data.id },
              ])
          )
        );
    }

    const getCustTop = async (id) => {
      return await new new CustomerPembelianAPI()
        .getSingle(id)
        .then((res) => res.data.toOrganisasi === null ? 0 : res.data.topOrganisasi.nilai_top);
    };
    return [
      {
        validation: Yup.string(),
        dataField: "nama_customer",
        label: "Customer",
        type: "disabled-text",
        value: this.master.nama_customer,
      },
      {
        validation: Yup.string(),
        dataField: "nama_organisasi",
        label: "Organisasi",
        type: "disabled-text",
        value: this.master.nama_organisasi ? this.master.nama_organisasi : "Tanpa Organisasi",
      },
      {
        validation: Yup.string().required("Tanggal harus diisi"),
        dataField: "tanggal_invoice",
        label: "Tanggal",
        type: "date",
        value: this.master.tanggal_invoice,
        invisibleOnTable: true,
        onChange: (e) => {
          this.master.tanggal_invoice = e.target.value
          getCustTop(this.master.id_pemasok).then((val) => {
            this.valDate = val;
            this.jatuh_tempo();
          })
        },
      },
      {
        validation: Yup.string(),
        dataField: "jatuh_tempo",
        label: "Jatuh Tempo",
        type: "disabled-text",
        value: this.master.jatuh_tempo,
        onChange: (e) => {},
        invisibleOnTable: true,
      },
      {
        validation: Yup.string(),
        dataField: "no_transaksi",
        label: "No Transaksi",
        type: "disabled-text",
        value: this.master.no_transaksi,
      },
      {
        validation: Yup.string(),
        dataField: "qty_total",
        label: "QTY Total",
        type: "disabled-text",
        value: this.master.qty_total,
        onChange: (e) => {},
      },
      {
        dataField: "jenis_pembayaran",
        label: "Jenis Pembayaran",
        type: "select",
        options: pembayaran_opsi,
        value: this.master.jenis_pembayaran === 1 ? "Cash" : "Kredit",
        onChange: (e) => {
          this.master.jenis_pembayaran = e.target.value;
        },
      },
      {
        validation: Yup.string(),
        dataField: "diskon_invoice",
        label: "Diskon",
        type: "currency",
        value: this.master.diskon_invoice,
        invisibleOnTable: true,
      },
      {
        validation: null,
        dataField: "hpp_total",
        label: "Total Harga",
        type: "disabled-text",
        value: this.master.hpp_total,
      },
      {
        validation: null,
        dataField: "diskon_total",
        label: "Total Diskon",
        type: "disabled-text",
        value: this.master.diskon_total,
        invisibleOnTable: true,
      },
      {
        validation: null,
        dataField: "subtotal",
        label: "Sub Total",
        type: "disabled-text",
        value: this.master.subtotal,
        invisibleOnTable: true,
      },
      {
        validation: null,
        dataField: "pajak_total",
        label: "Total Pajak",
        type: "disabled-text",
        value: this.master.pajak_total,
        invisibleOnTable: true,
      },
      {
        validation: null,
        dataField: "total",
        label: "Total",
        type: "disabled-text",
        value: this.master.total,
      },
      {
        validation: Yup.string(),
        dataField: "deskripsi",
        label: "Deskripsi",
        type: "disabled-text",
        value: this.master.deskripsi,
        invisibleOnTable: true,
      },
      {
        dataField: "nama_sales",
        label: "Nama Sales",
        type: "disabled-text",
        value: this.master.nama_sales,
        onChange: (e) => {
          this.master.nama_sales = e.target.value;
        },
      },
    ];
  }
}

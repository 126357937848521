import { getCoverageArea, getIdPerusahaan, simpleFetch } from "./api";
import store from "../redux/store";

export class PesananPenjualanAPI {
  async getAll() {
    let coverage = store.getState().auth.coverage;
    let params = "id_perusahaan=";
    // if (coverage.length > 0) {
    //   params += coverage.join("&id_perusahaan=");
    // } else {
      params += coverage;
    // }
    let res = await simpleFetch({
      url: "retail/penjualan/pesanan_penjualan?" + params,
      method: "GET",
    });
    return res;
  }

  async getForInvoice(val) {
    let params = "id_perusahaan=";
      params += val;
    let res = await simpleFetch({
      url: "retail/penjualan/pesanan_penjualan?" + params,
      method: "GET",
    });
    return res;
  }

  async indexForInvoice(val) {
    let params = "id_perusahaan=";
      params += val;
    let res = await simpleFetch({
      url: "retail/pesanan_penjualan/invoice?" + params,
      method: "GET",
    });
    return res;
  }

  async getBatal() {
    let params = "id_perusahaan=";
      params += getIdPerusahaan();
    let res = await simpleFetch({
      url: "retail/penjualan/batal_pesanan_penjualan?" + params,
      method: "GET",
    });
    return res;
  }

  async getSingle(id) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/penjualan/pesanan_penjualan?id=" + id,
        method: "GET",
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async set(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/penjualan/pesanan_penjualan",
        method: "POST",
        body: {
          id_perusahaan: getIdPerusahaan(),
          ...values,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async edit(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/penjualan/pesanan_penjualan",
        method: "PUT",
        body: {
          id_perusahaan: getIdPerusahaan(),
          ...values,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async alokasi(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/penjualan/alokasi_pesanan_penjualan",
        method: "POST",
        body: {
          id_perusahaan: getIdPerusahaan(),
          ...values,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async batalAlokasi(values) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/penjualan/alokasi_pesanan_penjualan",
        method: "PUT",
        body: {
          id_perusahaan: getIdPerusahaan(),
          ...values,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async delete(id) {
    let res;
    try {
      res = await simpleFetch({
        url: "retail/penjualan/pesanan_penjualan",
        method: "DELETE",
        body: {
          id: id,
        },
      });
    } catch (e) {
      console.log(e);
    } finally {
      return res;
    }
  }

  async getCoverage() {
    let res = await simpleFetch({
      url: "retail/select/perusahaan?id_coverage=" + getCoverageArea(),
      method: "GET",
    });
    return res;
  }
}

import { FormDataFetch, getIdPerusahaan, getIdRole, simpleFetch, simpleFetchGetData } from "./api";

export class kontakAPI {
  // Data Customer
  // Get All Data List
  async getDataCustomerAll() {
    let res = await simpleFetchGetData({
      url: "retail/kontak?tipe=data-customer&id_perusahaan=" + getIdPerusahaan(),
      method: "GET",
    });
    return res;
  }
  // Insert Data
  async setDataCustomer(values) {
    const fd = new FormData();
    for (const key in values) {
      fd.append(key, values[key]);
    }
    fd.append("id_perusahaan", getIdPerusahaan());
    let res = await FormDataFetch({
      url: "retail/kontak/data_customer",
      method: "POST",
      body: fd,
    });
    return res;
  }
  // Update Data
  async editDataCustomer(values) {
    const fd = new FormData();
    for (const key in values) {
      fd.append(key, values[key]);
    }
    fd.append("id_perusahaan", getIdPerusahaan());
    let res = await FormDataFetch({
      url: "retail/kontak/data_customer",
      method: "PUT",
      body: fd,
    });
    return res;
  }
  async deleteDataCustomer(id) {
    let res = await simpleFetch({
      url: "retail/kontak/data_customer",
      method: "DELETE",
      body: {
        id: id,
      },
    });
    return res;
  }

  // Organisasi
  // Get All Data List
  async getOrganisasiAll(form) {
    const { status_approved_filter } = form;
    let get = `&id_perusahaan=${getIdPerusahaan()}&is_approved=${status_approved_filter}`;
    let res = await simpleFetchGetData({
      url: "retail/kontak?tipe=organisasi" + get,
      method: "GET",
    });
    return res;
  }
  // Insert Data
  async setOrganisasi(values) {
    const fd = new FormData();
    for (const key in values) {
      fd.append(key, values[key]);
    }
    fd.append("id_perusahaan", getIdPerusahaan());
    let res = await FormDataFetch({
      url: "retail/kontak/organisasi",
      method: "POST",
      body: fd,
    });
    return res;
  }
  // Update Data
  async editOrganisasi(values) {
    const fd = new FormData();
    for (const key in values) {
      fd.append(key, values[key]);
    }
    fd.append("id_perusahaan", getIdPerusahaan());
    let res = await FormDataFetch({
      url: "retail/kontak/organisasi",
      method: "PUT",
      body: fd,
    });
    return res;
  }
  async deleteOrganisasi(id) {
    let res = await simpleFetch({
      url: "retail/kontak/organisasi",
      method: "DELETE",
      body: {
        id: id,
      },
    });
    return res;
  }
  // Get Nama Sales
  async getNamaSales() {
    let res = await simpleFetch({
      url: "retail/select/nama_sales?id_role=" + getIdRole(),
      method: "GET",
    });
    return res;
  }
  // Get Organisasi
  async getOrganisasi() {
    let res = await simpleFetch({
      url: "retail/select/kontak?tipe=organisasi&id_perusahaan=" + getIdPerusahaan(),
      method: "GET",
    });
    return res;
  }
  // Start: Customer Sales
  // Get All Data List
  async getCustomerSalesAll(id_sales) {
    let get = `&id_perusahaan=${getIdPerusahaan()}&id_sales=${id_sales}`;
    let res = await simpleFetchGetData({
      url: "retail/kontak?tipe=customer-sales" + get,
      method: "GET",
    });
    return res;
  }
  // Insert Data
  async setCustomerSales(values) {
    const fd = new FormData();
    for (const key in values) {
      fd.append(key, values[key]);
    }
    fd.append("id_perusahaan", getIdPerusahaan());
    let res = await FormDataFetch({
      url: "retail/kontak/data_customer",
      method: "POST",
      body: fd,
    });
    return res;
  }
  // Update Data
  async editCustomerSales(values) {
    const fd = new FormData();
    for (const key in values) {
      fd.append(key, values[key]);
    }
    fd.append("id_perusahaan", getIdPerusahaan());
    let res = await FormDataFetch({
      url: "retail/kontak/data_customer",
      method: "PUT",
      body: fd,
    });
    return res;
  }
  async deleteCustomerSales(id) {
    let res = await simpleFetch({
      url: "retail/kontak/data_customer",
      method: "DELETE",
      body: {
        id: id,
      },
    });
    return res;
  }

  async getAllKaryawan() {
    let res = await simpleFetch({
      url: "retail/user/karyawan",
      method: "GET",
    });
    return res;
  }
}

import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { simpleFetch } from "../../../api/api";
import { separatorHarga } from "../../../services/separator-harga";

function DetailCust({ data }) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [customer, setCustomer] = useState([]);
    const [invoice, setInvoice] = useState([]);

    const fetchDataHistory = async () => {
        try {
            await simpleFetch({
                url: `retail/kontak/get/customer?id=${data.id_customer}`,
                method: "GET",
            }).then((res) => setCustomer(res.data))
        } catch (error) {
            console.error(error);
        }
    }

    const fetchInvoice = async () => {
        try {
            await simpleFetch({
                url: `retail/penjualan-customer?id_perusahaan=${data.id_perusahaan}&id_customer=${data.id_customer}`,
                method: "GET",
            }).then((res) => setInvoice(res.data))
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchDataHistory()
        fetchInvoice()
    }, [data])

    return (
        <>
            <div className="form-group col-lg-6">
                {customer.toOrganisasi?.nilai_limit_tersisa <= 0 ?
                    <label>Over Limit</label>
                    :
                    <label>Sisa Limit</label>
                }
                <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fa fa-credit-card"></i></div>
                    </div>
                    {customer.toOrganisasi ? (customer.toOrganisasi.nilai_limit_tersisa <= 0 ?
                        <input type="text" className="form-control bg-warning" disabled value={separatorHarga(customer.toOrganisasi.nilai_limit_tersisa.toString(), "Rp.")} />
                        :
                        <input type="text" className="form-control" disabled value={separatorHarga(customer.toOrganisasi.nilai_limit_tersisa.toString(), "Rp.")} />
                    ) : <input type="text" className="form-control" disabled value={"Customer Umum"} />
                    }
                </div>
            </div>
            <div className="form-group col-md-2">
                <label>Invoice Belum Terbayar</label>
                <div className="input-group mb-2">
                    <div className="input-group-prepend">
                        <Button variant="primary" onClick={() => {
                            handleShow()
                        }}>
                            <i className="fa fa-file"></i>
                        </Button>
                    </div>
                    <input type="text" className="form-control" disabled value={(invoice.filter((val) => val.master.status_bayar === 0).length)} />
                </div>
            </div>
            <Modal size="xl" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Daftar Invoice Belum Terbayar</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="table-responsive-lg d-flex justify-content-center">
                        <Table responsive="xl" className="table table-hover w-auto">
                            <thead className="table-primary">
                                <tr className="text-center">
                                    <th scope="col">No</th>
                                    <th scope="col">No Transaksi</th>
                                    <th scope="col">Tanggal Invoice</th>
                                    <th scope="col">Tanggal Jatuh Tempo</th>
                                    <th scope="col">Total Dibayar</th>
                                    <th scope="col">Tersisa</th>
                                    <th scope="col">Jatuh Tempo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoice && invoice.length > 0 ? invoice.map((data, i) => (
                                    <tr key={i} className="text-center">
                                        <td>{i + 1}</td>
                                        <td>{data.master.no_transaksi}</td>
                                        <td>{data.master.tanggal_invoice}</td>
                                        <td>{data.master.jatuh_tempo}</td>
                                        <td>{separatorHarga(data.master.terbayar.toString())}</td>
                                        <td>{separatorHarga(data.master.tersisa.toString())}</td>
                                        <td>{
                                            new Date(data.master.jatuh_tempo) < new Date().setHours(0, 0, 0, 0) ?
                                                `${Math.floor(Math.abs(new Date() - new Date(data.master.jatuh_tempo)) / (1000 * 3600 * 24))} hari yang lalu`
                                                : `${Math.floor(Math.abs(new Date() - new Date(data.master.jatuh_tempo)) / (1000 * 3600 * 24))} hari lagi`
                                        }</td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <th scope="row"></th>
                                        <td colSpan="4" className="text-center">Belum Ada Data Pembelian</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Tutup
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DetailCust;
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import DetailPenerimaanKas from "./detail";
import ListPenerimaanKas from "./list";
import React from "react";
import { dispatcher } from "../../../../../redux/dispatcher";

export default function IndexListPenerimaanKas ()
{
  const history = useHistory();
  const active = dispatcher[ 'kasir_kas' ].getPenerimaanKas();
  return (
    <Switch>
      <Route path="/kasir/daftar-penerimaan-kas/list">
        <ListPenerimaanKas />
      </Route>
      { active[ 'transaksi' ][ 'id' ] === 0 && (
        <Redirect to="/kasir/daftar-penerimaan-kas/list" /> )
      }
      <Route path="/kasir/daftar-penerimaan-kas/detail">
        <DetailPenerimaanKas history={ history } />
      </Route>
      <Redirect to="/kasir/daftar-penerimaan-kas/list" />
    </Switch>
  );
}
import React from 'react'
import { CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls';
import { AnimatedCard } from '../../../../components/animated-card/animatedCard'
import { useState } from 'react';
import Select2 from "react-select";
import LaporanAllStok from './stok';
import AllStok from './allStok';
import StokSummary from './stokSummary';
import SaldoPersediaan from './saldoPersediaan';

function LaporanStok() {
    const [kategori] = useState([{
        value: 1,
        label: "Stok"
    }, {
        value: 2,
        label: "All Stok"
    },
    {
        value: 3,
        label: "Summary"
    },
    {
        value: 4,
        label: "Saldo Persediaan"
    }
    ])

    const [sKategori, setSkategori] = useState(1)

    return (
        <>
            <AnimatedCard>
                <CardHeader title={'Laporan Stok'}>
                    <CardHeaderToolbar >
                        <div className="form-group">
                            <label style={{ width: "250px" }}>Kategori</label>
                            <Select2
                                name={"Kategori"}
                                size="lg"
                                options={kategori}
                                isClearable={false}
                                value={
                                    kategori
                                        ? kategori.find(
                                            (option) => option.value === sKategori
                                        )
                                        : ""
                                }
                                onChange={(e) => {
                                    setSkategori(e ? e.value : []);
                                }}
                            >
                            </Select2>
                        </div>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    {sKategori === 1 ?
                        <LaporanAllStok/> : 
                        sKategori === 2 ?
                        <AllStok/> :
                        sKategori === 3 ? <StokSummary/> : <SaldoPersediaan/>
                    }
                </CardBody>
            </AnimatedCard>
        </>
    )
}



export default LaporanStok
import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Detail from "./formDetail"
import BagMasuk from "./index";

export default function StokBagMasuk() {
    const history = useHistory();

    return (
        <Switch>
            <Route path="/bag-masuk/list">
                <BagMasuk />
            </Route>
            <Route path="/bag-masuk/detail">
                <Detail history={history}/>
            </Route>
            <Redirect from="/bag-masuk/" to="/bag-masuk/list" />
        </Switch>
    )
}
import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { AnimatedCard } from '../../../components/animated-card/animatedCard';
import { CardBody, CardFooter, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls';
import { hapusSeparator, separatorHarga } from '../../../services/separator-harga';
import { CustomerLimitAPI } from '../../../api/customer-limit';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import DetailCust from '../../penjualan/cust-detail/detailCust';

function Forms({ data }) {
    const [state] = useState({ id_perusahaan: data.data.id_perusahaan, id_customer: data.data.id_kontak })
    const history = useHistory();
    const [limitReq, setLimitReq] = useState(data.data.limit_request)

    useEffect(() => {
    }, [])
    return (
        <>
            <Formik
                initialValues={{ data }}
                validationSchema={Yup.object({
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    let sendData = {
                        limit_request: typeof limitReq === 'number' ? limitReq : parseInt(hapusSeparator(limitReq)),
                        id: data.data.id,
                        id_organisasi: data.data.id_organisasi
                    }
                    await new CustomerLimitAPI().approvalPengajuanLimit(sendData)
                    history.goBack()
                    // setTimeout(() => {
                    //     alert(JSON.stringify(values, null, 2));
                    //     setSubmitting(false);
                    // }, 400);
                }}
            >
                {formik => (
                    <AnimatedCard>
                        <CardHeader title="Tambah Pengajuan Limit">
                            <CardHeaderToolbar>
                                <Button
                                    type="button"
                                    className="btn btn-secondary mx-1"
                                    onClick={() => history.goBack()}
                                >
                                    <i className="fa fa-arrow-left"></i>
                                    Kembali
                                </Button>
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <div className="row">
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="kode">Nama Customer</label>
                                        <input
                                            id="kode"
                                            className='form-control'
                                            type="text"
                                            disabled
                                            value={`${data.data.nama} || ${data.data.nama_organisasi}`}
                                        />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="kode">No Pengajuan</label>
                                        <input
                                            id="kode"
                                            className='form-control'
                                            type="text"
                                            disabled
                                            value={data.data.no_pengajuan}
                                        />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="nilaiLimit">Nilai Limit</label>
                                        <input
                                            id="nilaiLimit"
                                            className='form-control'
                                            type="text"
                                            disabled
                                            value={`Rp. ${separatorHarga(data.data.nilai_limit.toString())}`}
                                        />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="limitReq">Limit Diajukan</label>
                                        <input
                                            id="limitReq"
                                            className='form-control'
                                            value={`Rp. ${separatorHarga(limitReq.toString())}`}
                                            type="text"
                                            // disabled
                                            onChange={(e) => {
                                                setLimitReq(e.target.value)
                                                console.log(e.target.value);
                                            }}
                                        />
                                    </div>
                                    <DetailCust data={state} />
                                    <div className="form-group col-lg-6">
                                        <label htmlFor="Keterangan">Keterangan</label>
                                        <textarea
                                            id="keterangan"
                                            className='form-control'
                                            value={data.data.keterangan}
                                            rows="6"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </Form>
                        </CardBody>
                        <CardFooter>
                            <div className="d-flex justify-content-end">
                                <button type="submit"
                                    className="btn btn-primary mx-1"
                                    onClick={() => {
                                        console.log("sendData",typeof limitReq);
                                        formik.handleSubmit()
                                    }}
                                >
                                    <i className="fa fa-save"></i>Approve & Simpan</button>
                            </div>
                        </CardFooter>
                    </AnimatedCard>
                )}
            </Formik>
        </>
    )
}

export default Forms
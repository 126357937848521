import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import List from "./list";
import Detail from "./detail";
import InvoicePembelianModel from "../../../models/invoice-pembelian-model/InvoicePembelianModel";

export default function InvoicePembelian() {
  const [dataFromModel, setDataFromModel] = useState(new InvoicePembelianModel());

  return (
    <Switch>
      {/* <Route path="/tambah-invoice-pembelian/new">
        <Form dataFromModel={new InvoicePembelianModel()} />
      </Route> */}
      <Route path="/tambah-invoice-pembelian/tambah">
        <Detail dataFromModel={dataFromModel} isEdit={true} />
      </Route>
      <Route path="/tambah-invoice-pembelian/list">
        <List setDataFromModel={setDataFromModel} />
      </Route>
      <Redirect from="/tambah-invoice-pembelian/" to="/tambah-invoice-pembelian/list" />
    </Switch>
  );
}

import React, { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import StokGudang from "./StokGudang";
import Detail from "./detail"
import stokGudangModel from "../../../models/stok-gudang-model/StokGudangModel";

export default function DaftarStokGudang() {
    const [dataFromModel, setDataFromModel] = useState(new stokGudangModel());
    const history = useHistory();

    return (
        <Switch>
            <Route path="/stok-gudang/list">
                <StokGudang setDataFromModel={setDataFromModel} />
            </Route>
            <Route path="/stok-gudang/detail">
                <Detail history={history} data={dataFromModel} />
            </Route>
            <Redirect from="/stok-gudang/" to="/stok-gudang/list" />
        </Switch>
    )
}
import { CardBody, CardFooter, CardHeader } from '../../../../_metronic/_partials/controls';
import React, { Component } from 'react';
import { AnimatedCard } from '../../../components/animated-card/animatedCard';
import DataTableModel from '../../../models/datatable-model/DataTableModel';
import { Formik } from 'formik';
import { FormsFields } from '../../../components/forms-fields/FormsFields';
import { ReactDatatableFooterDetails } from '../../../components/react-datatable-footer/reactDatatableFooter';
import { akuntansiAPI } from '../../../api/akuntansi';
import { separatorHarga } from '../../../services/separator-harga';
import ButtonLoad from '../../../components/button-loading/buttonLoad';
import cariTransaksi from '../../../models/akuntansi/cari-transaksi/CariTransaksi';
import { makeStyles, Paper, Typography } from '@material-ui/core';

export default class CekTransaksi extends Component {
   constructor(props) {
      super(props);
      this['pageName'] = "Cek Transaksi";
      this['searchParameter'] = ["tanggal", "no_transaksi", "cabang", "user", "bank", "deskripsi", "kode_akun", "nama_akun", "user_clearing", "debet", "kredit"];
      this['apiCekTransaksi'] = new akuntansiAPI();
      this['state'] = {
         pending: false,
         no_transaksi: "",
         debetKredit: 0,
         initialValues: {
            jenis_pencarian: "all",
            no_transaksi: "",
         },
         footerCekTransaksi: {
            user_clearing: "Sub Total",
            debet: 0,
            kredit: 0,
         },
      }
      this['datatable'] = [{ id: "", no: "", name: "" }];
      this['columntable'] = [{ id: "", title: "" }];
   }
   //* ----------------------------------------------------------------------------- */
   //** Start: Component */
   async componentDidMount() {
      await this.LoadData(this['state']['initialValues']);
   }
   //** End: Component */
   //** Start: Load Data Cek Transaksi */
   async LoadData(form) {
      this.setState({ pending: true });
      let data = await this['apiCekTransaksi'].getAllCekTransaksi(form);
      this['DataTableModel'] = new DataTableModel({ readOnly: true });
      data['data']['BukuBesar'].map((item, i) => {
         let dataModel = new cariTransaksi(item);
         this['DataTableModel'].add({
            id: item['id'],
            no: i + 1,
            dataModel: dataModel,
            actions: null,
         });
         return item;
      });
      const totalFooters = (val) => {
         if (val === 'debet') {
            return data['data']['BukuBesar'].reduce((total, value) => {
               const debet = parseInt(value['debet']);
               return parseFloat(total + debet);
            }, 0);
         } else {
            return data['data']['BukuBesar'].reduce((total, value) => {
               const kredit = parseInt(value['kredit']);
               return parseFloat(total + kredit);
            }, 0);
         }
      };
      this.setState({
         no_transaksi: data['data']['no_transaksi'],
         debetKredit: data['data']['debetKredit'],
         footerCekTransaksi: {
            user_clearing: "Sub Total",
            debet: totalFooters("debet") < 0 ? `Rp. -${separatorHarga(totalFooters("debet").toString())}` : separatorHarga(totalFooters("debet").toString(), "Rp. "),
            kredit: totalFooters("kredit") < 0 ? `Rp. -${separatorHarga(totalFooters("kredit").toString())}` : separatorHarga(totalFooters("kredit").toString(), "Rp. "),
         },
      });
      this['datatable'] = await this['DataTableModel'].getDatas();
      this['columntable'] = await this['DataTableModel'].getColumn();
      this.setState({ ...this.state, pending: false });
   }
   //** End: Load Data List */
   //* ----------------------------------------------------------------------------- */
   render() {
      const useStyles = makeStyles(theme => ({
         root: {
            textTransform: "uppercase",
            textAlign: "right",
            padding: theme.spacing(1, 2),
            backgroundColor: "#f3f6f9",
         },
      }));
      const { pending, no_transaksi, initialValues, footerCekTransaksi, debetKredit } = this['state'];
      return (
         <>
            {/* Start: Formik List */}
            <Formik
               enableReinitialize={true}
               initialValues={initialValues}
               onSubmit={async (values) => {
                  await this.LoadData(values);
               }}
            >
               {({ setFieldValue, setFieldTouched, touched, errors, values, handleSubmit }) => (
                  <AnimatedCard>
                     <CardHeader title={this['pageName']}>
                     </CardHeader>
                     <CardBody style={{ marginTop: "-20px" }}>
                        <div className={"form-group row"}>
                           {/* Forms Select Jenis Pencarian */}
                           <FormsFields
                              id={"jenis_pencarian"}
                              type={"select2"}
                              label={"Jenis Pencarian"}
                              style={{ marginTop: "20px" }}
                              className={"col-lg-4"}
                              isClearable={false}
                              isSearchable={false}
                              options={[{
                                 label: "Semua",
                                 value: "all"
                              }, {
                                 label: "Spesifik",
                                 value: "spesifik"
                              }]}
                              paramsFormik={{
                                 setFieldValue,
                                 setFieldTouched,
                                 touched,
                                 errors,
                                 values
                              }}
                           />
                           {/* No. Transaksi  */}
                           <FormsFields
                              id={"no_transaksi"}
                              type={"searchBarFilter"}
                              label={"No. Transaksi"}
                              className={"col-lg-4"}
                              style={{ marginTop: "20px" }}
                              onRequestSearch={async () => handleSubmit()}
                              onCancelSearch={async () => handleSubmit()}
                              paramsFormik={{
                                 setFieldValue,
                                 values
                              }}
                           />
                        </div>
                        {/* Button */}
                        <div style={{ display: "flex", justifyContent: "center" }} >
                           {/* Button Lihat Data */}
                           <ButtonLoad
                              label={"Lihat Data"}
                              pending={pending}
                              classNameIcon={"fa fa-filter"}
                              className={"btn btn-outline-success"}
                              onClick={async () => handleSubmit()}
                           />
                        </div>
                     </CardBody>
                     <CardFooter>
                        {/* Jurnal Balik Old */}
                        <ReactDatatableFooterDetails
                           title={`No. Transaksi: ${no_transaksi}`}
                           data={this['datatable']}
                           columns={this['columntable']}
                           footer={footerCekTransaksi}
                        />
                        {/* Debet/Kredit */}
                        <div className={"tab-content mt-5"}>
                           <div className={"table-responsive"}>
                              <Paper className={useStyles().root}>
                                 <Typography variant={"h5"} component={"h3"}>
                                    Debet/Kredit : {(debetKredit < 0) ? "Rp. -" + separatorHarga(debetKredit.toString()) :
                                       separatorHarga(debetKredit.toString(), "Rp. ")}
                                 </Typography>
                              </Paper>
                           </div>
                        </div>
                     </CardFooter>
                  </AnimatedCard>
               )}
            </Formik>
            {/* End: Formik List */}
         </>
      );
   }
}
import React, { useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import StokOpname from "./index";
import Detail from "./formDetail"

export default function StokOpnameRoute() {
    const history = useHistory();

    return (
        <Switch>
            <Route path="/stok-opname/list">
                <StokOpname />
            </Route>
            <Route path="/stok-opname/detail">
                <Detail history={history}/>
            </Route>
            <Redirect from="/stok-opname/" to="/stok-opname/list" />
        </Switch>
    )
}